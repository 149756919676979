import React, { FC, Fragment, useState, MouseEvent, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as Calculator } from './calculator.svg';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
      fontWeight: 600,
    },
  }),
);

interface CountType {
  query: any;
  filter?: any;
}

const Count: FC<CountType> = ({ query, filter }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [fetch, { data }] = query();

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'count-popover' : undefined;

  useEffect(() => {
    if (open) fetch({ variables: { filter } });
  }, [open, fetch, filter]);

  return (
    <Fragment>
      <Tooltip title="Показать количество" placement="top">
        <IconButton onClick={handleOpen} color="primary">
          <Calculator style={{ width: 25, height: 25 }} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography className={classes.typography} color="primary">
          {data?.count}
        </Typography>
      </Popover>
    </Fragment>
  );
};

export default Count;
