import {
  CreatePersonCitizenshipMutationHookResult,
  PersonByIdDocument,
  RemovePersonCitizenshipMutationHookResult,
  useCreatePersonCitizenshipMutation,
  usePersonByIdQuery,
  useRemovePersonCitizenshipMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC } from 'react';
import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Countries } from '../../../module';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';

const Citizenship: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = usePersonByIdQuery({ variables: { id } });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { citizenship: null },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [create]: CreatePersonCitizenshipMutationHookResult = useCreatePersonCitizenshipMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: PersonByIdDocument,
        data: {
          ...data?.updateDirPerson,
        },
      });
    },
  });

  const [remove]: RemovePersonCitizenshipMutationHookResult = useRemovePersonCitizenshipMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: PersonByIdDocument,
        data: {
          ...data?.updateDirPerson,
        },
      });
    },
  });

  const onRemove = async (value: string, label: string) => {
    try {
      await remove({
        variables: {
          id,
          idItem: value,
        },
      });
      enqueueSnackbar(`Гражданство "${label}" успешно удалено`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values?: { citizenship?: { label: string; value: any } | null }) => {
    if (!values?.citizenship) return;
    try {
      await create({
        variables: {
          id,
          idItem: values?.citizenship?.value,
        },
      });
      reset({ citizenship: null });
      enqueueSnackbar(`Гражданство "${values?.citizenship?.label}" успешно добавлено`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (readonly) {
  }
  return (
    <Grid container>
      <Grid item md={4}>
        <List>
          <form onSubmit={onSubmit}>
            <ListItem key="add_new">
              <Countries label="Название страны" error={!!errors['citizenship']} name="citizenship" control={control} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="add" type="submit">
                  <AddIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </form>
          {data?.dirPerson?.dirCitizenships?.map((item) => (
            <ListItem key={item.value}>
              <ListItemText primary={item.label} />
              {!readonly && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemove(item.value, item.label)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default Citizenship;
