import React, { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input } from '../Inputs';
import { Count } from '../Count';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { RefereesFilterAdditional } from './index';
import { makeStyles } from '@material-ui/core/styles';
import { useRefereesCountLazyQuery } from '../../api';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Referees: FC<{ onSubmit?: (T: any) => void; value?: any }> = ({ onSubmit = () => {}, value }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, watch, formState, setValue, getValues } = useForm({
    defaultValues: value,
  });
  const [additional, setAdditional] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });

  useEffect(() => {
    reset(value);
  }, [value]);

  const handleSearchBox = (value: any) => {
    // if (reset) {
    //   setFilter(queryAccess.permanentFilter);
    //   return;
    // }

    const filter: any = {
      dirPerson: {},
      sports_some: {
        organizations_some: {},
        categories_some: {},
      },
      OR: {},
    };

    for (let item in value) {
      if (item === 'birthday_gte' && value[item]) {
        filter.dirPerson[item] = value[item];
      }
      if (item === 'birthday_lte' && value[item]) {
        filter.dirPerson[item] = value[item];
      }

      if (item === 'registryNumber' && value[item]) {
        filter.dirPerson[item] = Number(value[item]);
      }
      if (
        (item === 'lastname' ||
          item === 'firstname' ||
          item === 'patronymic' ||
          item === 'previousLastname' ||
          item === 'email') &&
        value[item]
      ) {
        filter.dirPerson[item + '_contains'] = value[item];
      }
      if (item === 'clsStatusPerson' && value?.clsStatusPerson?.value) {
        filter.dirPerson.confirmStatus = {
          id: Number(value?.clsStatusPerson?.value),
        };
      }
      if (item === 'dirRegion' && value?.dirRegion?.value) {
        filter.dirPerson[item] = {
          id: value?.dirRegion?.value,
        };
      }
      if (item === 'dirCountry' && value?.dirCountry?.value) {
        filter.dirPerson[item] = {
          id: value?.dirCountry?.value,
        };
      }
      if (item === 'dirForeignCity' && value?.dirForeignCity?.value) {
        filter.dirPerson[item] = {
          id: value?.dirForeignCity?.value,
        };
      }
      if (item === 'dirSport' && value?.dirSport?.value) {
        filter.sports_some = {
          ...filter.sports_some,
          dirSport: {
            id: value?.dirSport?.value,
          },
        };
      }

      if (item === 'clsRefereeCategory' && value?.clsRefereeCategory?.value) {
        filter.sports_some = {
          ...filter.sports_some,
          categories_some: {
            clsRefereeCategory: {
              id: value?.clsRefereeCategory?.value,
            },
          },
        };
      }
      if (item === 'dirOrganization' && value?.dirOrganization?.value) {
        filter.sports_some.organizations_some = {
          ...filter.sports_some.organizations_some,
          dirOrganization: {
            id: value?.dirOrganization?.value,
          },
        };
      }

      if (item === 'isSelfTraining' && value?.isSelfTraining) {
        if (value?.isSelfTraining?.value === false) {
          filter.sports_some.organizations_some = {
            ...filter.sports_some.organizations_some,
            isSelfTraining_not: true,
          };
        }
        if (value?.isSelfTraining?.value === true) {
          filter.sports_some.organizations_some = {
            ...filter.sports_some.organizations_some,
            isSelfTraining: true,
          };
        }
      }
      if (item === 'isMale' && value?.isMale?.value) {
        filter.dirPerson[item] = value?.isMale?.value;
      }

      if (item === 'archive' && value?.archive) {
        if (value?.archive?.value === false) {
          filter[item] = null;
        }
        if (value?.archive?.value === true) {
          filter.NOT = {
            archive: null,
          };
        }
      }
      if (item === 'isDuplicated' && value?.isDuplicated?.value) {
        if (value[item] === false) {
          filter.dirPerson.primary = null;
        }
        if (value[item] === true) {
          filter.dirPerson = {
            ...filter.dirPerson,
            NOT: {
              primary: null,
            },
          };
        }
      }
      if (item === 'isRussia' && value?.isRussia) {
        filter.dirPerson[item] = value?.isRussia?.value;
      }
    }
    if (Object.keys(filter.dirPerson).length === 0) delete filter.dirPerson;
    if (Object.keys(filter.OR).length === 0) delete filter.OR;
    if (Object.keys(filter.sports_some.organizations_some).length === 0) delete filter.sports_some.organizations_some;

    if (Object.keys(filter.sports_some).length === 0) delete filter.sports_some;

    setFilter(filter);
    onSubmit(filter);
  };

  return (
    <ContentWrapper>
      <form
        onSubmit={handleSubmit(handleSearchBox)}
        onReset={(e?) => {
          setValue('registryNumber', '');
          reset();
          setFilter({
            archive: null,
          });
          onSubmit({
            archive: null,
          });
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item container spacing={2} md={11} xs={12}>
            <Grid item xs={3}>
              <Input
                label="Реестровый номер"
                control={control}
                error={!!errors['registryNumber']}
                name="registryNumber"
              />
            </Grid>
            <Grid item xs={3}>
              <Input label="Фамилия" control={control} error={!!errors['lastname']} name="lastname" />
            </Grid>
            <Grid item xs={3}>
              <Input label="Имя" control={control} error={!!errors['firstname']} name="firstname" />
            </Grid>
            <Grid item xs={3}>
              <Input label="Отчество" control={control} error={!!errors['patronymic']} name="patronymic" />
            </Grid>
            {additional && <RefereesFilterAdditional watch={watch} control={control} errors={errors} />}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="reset"
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
              <Button
                onClick={() => setAdditional(!additional)}
                type="button"
                className={style.btn}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<TuneIcon />}
              >
                Фильтр
              </Button>
            </Grid>
          </Grid>
          <Grid item container justify="center" md={1} xs={12}>
            <Count query={useRefereesCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Referees;
