import { Controller } from 'react-hook-form';
import { FC } from 'react';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Chip, FormControl, Input, InputLabel, TextField } from '@material-ui/core';
import _omit from 'lodash/omit';
import { ControllerProps } from 'react-hook-form/dist/types/props';

export interface ReactHookFormSelect extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  data: any[];
  error: boolean | undefined;
  defaultValue?: any;
  multiple?: boolean;
  loading?: boolean;
  enablePortal?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChangeInput?: (value: string) => void;
}

const Select: FC<ReactHookFormSelect> = ({
  loading,
  onChangeInput,
  multiple,
  data,
  defaultValue,
  label,
  error,
  control,
  name,
  rules,
  enablePortal,
  disabled,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => {
        return (
          <Autocomplete
            {...props}
            loading={loading}
            multiple={multiple}
            fullWidth
            id={name}
            disabled={disabled}
            options={data}
            disablePortal={!enablePortal}
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
            renderTags={(value: { value: any; label: string }[], getTagProps) =>
              value.map((option: { value: any; label: string }, index: number) => (
                <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
              ))
            }
            getOptionLabel={(option: { value: any; label: string }) => {
              return option.label || '';
            }}
            onChange={(event, data) => {
              onChange(data);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  label={label}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  onChange={(event) => {
                    onChangeInput?.(event.target.value);
                  }}
                />
              );
              return (
                <FormControl style={{ minWidth: '100%' }} error={error}>
                  <InputLabel>{label}</InputLabel>
                  <Input
                    {..._omit(params, ['InputProps', 'InputLabelProps'])}
                    {...{ ...params.inputProps, ...params.InputProps }}
                    onChange={(event) => {
                      onChangeInput?.(event.target.value);
                    }}
                    autoComplete="off"
                  />
                </FormControl>
              );
            }}
          />
        );
      }}
      rules={rules}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : multiple ? [] : null}
    />
  );
};

export default Select;
