import {
  CreateUserMutationHookResult,
  CreateUserMutationVariables,
  DirFederalDistrict,
  DirOrganization,
  DirRegion,
  Maybe,
  RegionalMinsportProfile,
  RegionalSchoolProfile,
  RegularUser,
  UpdateUserMutationHookResult,
  UpdateUserMutationVariables,
  useCreateUserMutation,
  UserRole,
  useUpdateUserMutation,
  useUserLazyQuery,
  useUsersQuery,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { DatePicker, Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import UserRoles from '../../../module/UserRoles';
import { FederalDistricts } from '../../../module';
import Regions from '../../../module/Regions';
import Organizations from '../../../module/Organizations';
import Radios from '../../Inputs/Radio';

interface MainInfoFormProps {
  userRole:
    | (Pick<UserRole, 'description'> & {
        value: UserRole['id'];
        label: UserRole['name'];
      })
    | null;
  name?: string | null;
  email: string;
  birthday?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  patronymic?: string | null;
  isMale?: boolean | string | null;
  regular?: Maybe<
    { __typename?: 'RegularUser' } & Pick<
      RegularUser,
      'address' | 'birthday' | 'firstname' | 'lastname' | 'patronymic' | 'isMale' | 'position' | 'phone'
    >
  >;
  regionalMinsportProfile?: Maybe<
    { __typename?: 'RegionalMinsportProfile' } & Pick<RegionalMinsportProfile, 'id'> & {
        dirRegion?: Maybe<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
        dirFederalDistrict?: Maybe<
          { __typename?: 'DirFederalDistrict' } & {
            value: DirFederalDistrict['id'];
            label: DirFederalDistrict['fullName'];
          }
        >;
      }
  >;
  regionalSchoolProfile?: Maybe<
    { __typename?: 'RegionalSchoolProfile' } & Pick<RegionalSchoolProfile, 'id'> & {
        dirOrganization: { __typename?: 'DirOrganization' } & {
          value: DirOrganization['id'];
          label: DirOrganization['name'];
        };
      }
  >;
  dirRegion?: Maybe<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
  dirFederalDistrict?: Maybe<
    { __typename?: 'DirFederalDistrict' } & {
      value: DirFederalDistrict['id'];
      label: DirFederalDistrict['fullName'];
    }
  >;
  dirOrganization: { __typename?: 'DirOrganization' } & {
    value: DirOrganization['id'];
    label: DirOrganization['name'];
  };
}

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const [getUser, { data }] = useUserLazyQuery();
  const { refetch: refetchListUsers } = useUsersQuery({ skip: true });
  const { handleSubmit, control, errors, reset, formState, watch } = useForm({
    defaultValues: {
      userRole: null,
      name: '',
      email: '',
    } as MainInfoFormProps,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: CreateUserMutationHookResult = useCreateUserMutation();
  const [update]: UpdateUserMutationHookResult = useUpdateUserMutation();
  const watchUserRole = watch('userRole');
  const onSubmit = async (values: MainInfoFormProps) => {
    try {
      const variables: UpdateUserMutationVariables | CreateUserMutationVariables = {
        role: values.userRole?.label as string,
        name: values.name,
        email: values?.email,
      };

      const regular = {
        birthday: values?.birthday,
        firstname: values?.firstname,
        lastname: values?.lastname,
        patronymic: values?.patronymic,
        isMale: (values?.isMale === 'true') as boolean,
        dirOrganization: values?.dirOrganization?.value ? { connect: { id: values.dirOrganization?.value } } : null,
      };
      if (values.userRole?.label === 'REGIONAL_MINSPORT') {
        variables.regional = {
          [values.regionalMinsportProfile && values.regionalMinsportProfile.id ? 'update' : 'create']: {
            dirRegion: values.dirRegion?.value ? { connect: { id: values.dirRegion?.value } } : null,
            dirFederalDistrict: values.dirFederalDistrict?.value
              ? { connect: { id: values.dirFederalDistrict?.value } }
              : null,
          },
        };
      }

      if (values.userRole?.label === 'REGIONAL_SCHOOL') {
        variables.school = {
          [values.regionalSchoolProfile && values.regionalSchoolProfile.id ? 'update' : 'create']: {
            dirOrganization: values.regionalSchoolProfile?.dirOrganization
              ? { connect: { id: values.regionalSchoolProfile?.dirOrganization.value } }
              : null,
          },
        };
      }
      if (id !== 'new') {
        await update({
          variables: {
            ...variables,
            id,
            userID: id as string,
            regular: {
              [data?.user?.regular === null ? 'create' : 'update']: {
                ...regular,
              },
            },
          },
        });
        enqueueSnackbar(`Пользователь "${values?.email}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data: created } = await create({
          variables,
        });
        await update({
          variables: {
            ...variables,
            userID: created?.signup.user.id as string,
            id: created?.signup.user.id,
            regular: {
              create: {
                ...regular,
              },
            },
          },
        });
        if (created?.signup.token) {
          enqueueSnackbar(`Пользователь "${values?.email}" успешно добавлен`, { variant: 'success' });
          push(`/user/${created?.signup?.user.id}`);
        }
      }
      refetchListUsers();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      getUser({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    if (data?.user) {
      reset({
        ...data?.user,
        ...data?.user.regular,
        isMale: data?.user.regular?.isMale ? 'true' : 'false',
        userRole: data?.user.role,
        dirRegion: data?.user.regionalMinsportProfile?.dirRegion,
        dirFederalDistrict: data?.user.regionalMinsportProfile?.dirFederalDistrict,
        dirOrganization: data?.user.regionalSchoolProfile?.dirOrganization || data?.user.regular?.dirOrganization || {},
      });
    }
  }, [data]);

  return (
    <>
      <Grid container>
        <Grid item lg={9} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <UserRoles label="Роль" control={control} error={!!errors['userRole']} name="userRole" />
                </Grid>
                <Grid item md={6}>
                  <Input label="Email" control={control} error={!!errors['email']} name="email" />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Фамилия"
                    control={control}
                    error={!!errors['lastname']}
                    name="lastname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Имя"
                    control={control}
                    error={!!errors['firstname']}
                    name="firstname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input label="Отчество" control={control} error={!!errors['patronymic']} name="patronymic" />
                </Grid>
                <Grid item md={6}>
                  <Radios
                    label="Пол"
                    data={[
                      {
                        value: 'true',
                        label: 'Муж',
                      },
                      {
                        value: 'false',
                        label: 'Жен',
                      },
                    ]}
                    control={control}
                    error={!!errors['isMale']}
                    name="isMale"
                    rules={{ required: true }}
                  />
                </Grid>
                {watchUserRole?.label === 'REGIONAL_MINSPORT' && (
                  <>
                    <Grid item md={6}>
                      <FederalDistricts
                        label="Федеральный округ"
                        control={control}
                        error={!!errors['dirFederalDistrict']}
                        name="dirFederalDistrict"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Regions
                        label="Регион"
                        control={control}
                        error={!!errors['dirRegion']}
                        name="dirRegion"
                        rules={{ required: true }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={6}>
                  <Organizations
                    label="Организация"
                    control={control}
                    error={!!errors['dirOrganization']}
                    name="dirOrganization"
                    rules={{ required: watchUserRole?.label === 'REGIONAL_SCHOOL' }}
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    label="Дата рождения"
                    control={control}
                    error={!!errors['birthday']}
                    name="birthday"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default MainInfo;
