import React, { FC, useEffect } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import TypeSportsMultiSelect from '../../../../module/TypeSportsMultiSelect';
import DisciplinesMultiSelect from '../../../../module/DisciplinesMultiSelect';
import _ from 'lodash';
import {
  DirSportingEventSport,
  Scalars,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
} from '../../../../api';

interface EventInfo {
  classes?: any;
  SportUpdated: Function;
  onError: Function;
  dirSportingEvent?: any;
  readOnly?: boolean;
}

interface dirSportInterface {
  value: Scalars['UUID'];
  label: string;
}
interface disciplineInterface {
  value: Scalars['UUID'];
  label: string;
  typeSport: {
    id: Scalars['UUID'];
  };
}

interface formInterface {
  dirSports: dirSportInterface[];
  disciplines: disciplineInterface[];
}

const Sports: FC<EventInfo> = ({ dirSportingEvent, classes, SportUpdated, onError, readOnly }) => {
  const { id } = dirSportingEvent;

  const dirSports: dirSportInterface[] = dirSportingEvent?.sports.map((s: DirSportingEventSport) => s.dirSport);
  const disciplines: disciplineInterface[] = _.flattenDeep(
    dirSportingEvent?.sports.map((s: DirSportingEventSport) => s.disciplines),
  );

  const { handleSubmit, control, errors, watch } = useForm({
    defaultValues: {
      dirSports,
      disciplines,
    } as formInterface,
  });

  let watchSportTypes = watch('dirSports', dirSports);

  let watchDisciplines = watch('disciplines', disciplines);

  const [upDateSportingEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const onSubmit = async (values: formInterface) => {
    const newSports = _.difference(
      values.dirSports,
      _.flattenDeep(dirSportingEvent?.sports.map((s: DirSportingEventSport) => s.dirSport)),
    );
    const deleSports = _.difference(
      _.flattenDeep(dirSportingEvent?.sports.map((s: DirSportingEventSport) => s.dirSport)),
      values.dirSports,
    );
    const newDisciplines = _.difference(
      values.disciplines,
      _.flattenDeep(dirSportingEvent?.sports.map((s: DirSportingEventSport) => s.disciplines)),
    );

    try {
      await upDateSportingEvent({
        variables: {
          data: {
            sports: {
              create: _.map(newSports, (s) => ({
                dirSport: {
                  connect: { id: s.value },
                },
                disciplines: {
                  connect: newDisciplines.filter((d) => d.typeSport.id === s.value).map((d) => ({ id: d.value })),
                },
              })),
              update: dirSportingEvent?.sports.map((s: DirSportingEventSport) => ({
                where: { id: s.id },
                data: {
                  disciplines: {
                    set: newDisciplines
                      .filter((d) => d.typeSport.id === _.get(s, 'dirSport.value'))
                      .map((d) => ({ id: d.value })),
                  },
                },
              })),
              delete: dirSportingEvent?.sports
                .filter((s: DirSportingEventSport) => deleSports.includes(s.dirSport))
                .map((s: DirSportingEventSport) => ({ id: s.id })),
            },
          },
          id,
        },
      });
      SportUpdated();
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    if (!_.isEqual(dirSports, watchSportTypes) || !_.isEqual(disciplines, watchDisciplines)) {
      onSubmit({ dirSports: watchSportTypes, disciplines: watchDisciplines });
    }
  }, [watchSportTypes, watchDisciplines]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container className={classes.verticalSpaceMarge}>
          <TypeSportsMultiSelect
            label="Вид спорта"
            control={control}
            error={!!errors['dirSports']}
            name="dirSports"
            rules={{ required: true }}
          />
        </Grid>
        {dirSportingEvent?.sports[0]?.dirSport && (
          <React.Fragment>
            <Grid container className={classes.verticalSpaceMarge}>
              <DisciplinesMultiSelect
                id={_.flatMapDeep(
                  dirSportingEvent?.sports?.map((s: DirSportingEventSport) => _.get(s, 'dirSport.value')),
                )}
                label="Дициплины"
                control={control}
                error={!!errors['disciplines']}
                name="disciplines"
                rules={{ required: true }}
              />
            </Grid>
          </React.Fragment>
        )}
      </form>
      <Grid container>
        <Divider className={classes.divider} />
      </Grid>
    </React.Fragment>
  );
};

export default Sports;
