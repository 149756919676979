import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsMoment from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
}

const TimePicker: FC<ReactHookFormInput> = ({ name, error, label, errorMessage = '', control, rules }) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsMoment} locale="ru">
      <Controller
        render={({ onChange, ...props }) => {
          return (
            <KeyboardTimePicker
              variant="inline"
              ampm={false}
              invalidDateMessage="Неверный формат"
              format="HH:MM"
              label={label}
              value={props.value}
              onChange={onChange}
              error={error}
            />
          );
        }}
        control={control}
        defaultValue={null}
        name={name}
        rules={rules}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
