import {
  AddTypeSportsToTrainerMutationHookResult,
  TrainerByIdDocument,
  TypeSportTrainerArchiveMutationHookResult,
  TypeSportTrainerUnArchiveMutationHookResult,
  useAddTypeSportsToTrainerMutation,
  useTrainerByIdLazyQuery,
  useTypeSportTrainerArchiveMutation,
  useTypeSportTrainerUnArchiveMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import ArchiveComponent from '../../Dialogs/Archive/Archive';
import TypeSportModule from '../../../module/TypeSports';

const TypeSports: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const [fetch, { data, refetch }] = useTrainerByIdLazyQuery({ variables: { id } });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { typesport: null },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [create]: AddTypeSportsToTrainerMutationHookResult = useAddTypeSportsToTrainerMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: TrainerByIdDocument,
        data: {
          ...data?.updateDirTrainer,
        },
      });
    },
  });

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetch({ variables: { id } });
    }
  }, [id]);

  const [archive]: TypeSportTrainerArchiveMutationHookResult = useTypeSportTrainerArchiveMutation();
  const [unarchive]: TypeSportTrainerUnArchiveMutationHookResult = useTypeSportTrainerUnArchiveMutation();

  const onSubmit = handleSubmit(async (values?: { typesport?: { label: string; value: any } | null }) => {
    if (!values?.typesport) return;
    try {
      await create({
        variables: {
          id,
          sportId: values?.typesport?.value,
        },
      });
      reset({ typesport: null });
      enqueueSnackbar(`Вид спорта "${values?.typesport?.label}" успешно добавлен`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (readonly) {
  }
  return (
    <Grid container>
      <Grid item md={4}>
        <List>
          <form onSubmit={onSubmit}>
            <ListItem key="add_new">
              <TypeSportModule
                usedValue={data?.dirTrainer?.sports?.map((sport) => sport.dirSport.value)}
                label="Вид спорта"
                error={!!errors['typesport']}
                name="typesport"
                control={control}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="add" type="submit">
                  <AddIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </form>
          {data?.dirTrainer?.sports?.map((item) => (
            <ListItem key={item.id}>
              <ListItemText primary={item.dirSport.label} />
              {!readonly && (
                <ListItemSecondaryAction>
                  <ArchiveComponent
                    id={item.id}
                    archiveStatus={item.archive}
                    refetch={refetch}
                    archive={archive}
                    unarchive={unarchive}
                    canUnArchive
                  />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default TypeSports;
