import {
  SportingEventArchiveMutationHookResult,
  SportingEventUnArchiveMutationHookResult,
  useSportingEventArchiveMutation,
  useSportingEventQuery,
  useSportingEventUnArchiveMutation,
  Scalars,
} from '../../../api';
import React, { FC } from 'react';
import { Grid, LinearProgress, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info, Sports, Structure, Status } from './components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';
import getMessage from '../../../messages';
import moment from 'moment';

const useStyles = makeStyles({
  statusBox: {
    maxHeight: 150,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  title: {
    fontWeight: 500,
    marginRight: '0.5rem',
  },
  link: {
    cursor: 'pointer',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();
  const { loading, data, refetch } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });

  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [archive]: SportingEventArchiveMutationHookResult = useSportingEventArchiveMutation();
  const [unarchive]: SportingEventUnArchiveMutationHookResult = useSportingEventUnArchiveMutation();

  if (readonly) {
  }
  if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          {data?.dirSportingEvent && (
            <React.Fragment>
              <Status
                dirSportingEvent={data?.dirSportingEvent}
                classes={classes}
                statusUpdated={() => {
                  handleSnackBar('success', 'статус успешно обновлена');
                  refetch();
                }}
                onError={(error: any) => {
                  handleSnackBar('error', getMessage(error.message));
                }}
              />

              <Grid container className={classes.statusBox}>
                <Grid item container className={classes.verticalSpace}>
                  <span className={classes.title}>История изменения статуса:&nbsp;</span>
                  {data?.dirSportingEvent?.statusHistory?.map((s) => (
                    <Grid key={s.id} item container justify="space-between">
                      <Grid item xs={3}>
                        {s.status && s.status.name}
                      </Grid>
                      <Grid item xs={3}>
                        {s.date && moment(s.date).format('DD.MM.YY HH:mm')}
                      </Grid>
                      <Grid item xs={3}>
                        {s.user && s.user.name}
                      </Grid>
                      <Grid item xs={3}>
                        <Link className={classes.link}> {s.user && s.user.email}</Link>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          <Info
            dirSportingEvent={data?.dirSportingEvent}
            classes={classes}
            infoUpdated={(value: boolean) => {
              if (value) {
                refetch();
                handleSnackBar('success', 'Информация успешно обновлена');
              }
            }}
            newCreated={(value: Scalars['UUID']) => {
              if (value) {
                handleSnackBar('success', 'новая мероприятия успешно создано');
                push(`/sporting_event/${value}`);
              }
            }}
            onError={(error: any) => {
              handleSnackBar('error', getMessage(error.message));
            }}
          />
          {data?.dirSportingEvent && (
            <React.Fragment>
              <Sports
                dirSportingEvent={data?.dirSportingEvent}
                classes={classes}
                SportUpdated={() => {
                  handleSnackBar('success', 'вид спорта и дисциплин успешно обновлена');
                  refetch();
                }}
                onError={(error: any) => {
                  handleSnackBar('error', getMessage(error.message));
                }}
              />
              <Structure
                classes={classes}
                dirSportingEvent={data?.dirSportingEvent}
                onError={(error: any) => {
                  handleSnackBar('error', getMessage(error.message));
                }}
                commentUpdated={() => {
                  handleSnackBar('success', 'комментарии успешно обновлена');
                  refetch();
                }}
                parentAdded={() => {
                  handleSnackBar('success', 'Основное мероприятие успешно обновлено');
                  refetch();
                }}
                parentRemoved={() => {
                  handleSnackBar('success', 'Основное мероприятие успешно удалено');
                  refetch();
                }}
                childAdded={() => {
                  handleSnackBar('success', 'Зависимое мероприятие успешно добавлено');
                  refetch();
                }}
                childRemoved={() => {
                  handleSnackBar('success', 'Зависимое мероприятие успешно удалено');
                  refetch();
                }}
              />
            </React.Fragment>
          )}
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={6} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirSportingEvent?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
