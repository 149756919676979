import React, { FC, useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import { groupProgramTypesAndNationalTeams } from '../../../utils//groupProgramTypesAndNationalTeams';
import { useForm } from 'react-hook-form';
import { Input } from '../../Inputs';
import AthletesInTeam from '../../../module/AthletesInTeam';
import getMessage from '../../../messages';
import {
  Scalars,
  DirAthlete,
  DirNationalTeam,
  DirNationalTeamAthlete,
  DirNationalTeamAthleteGroup,
  useSportingEventQuery,
  UpdateDirAthleteMutationHookResult,
  useUpdateDirAthleteMutation,
} from '../../../api';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  row: {
    display: 'flex',
  },
});

interface teamGroup extends DirNationalTeamAthleteGroup {
  athletesArray?: DirNationalTeamAthlete[];
}
interface teamArray {
  dirRegion: {
    label: string;
    value: Scalars['UUID'];
    shorthand: string;
    __typename?: 'DirRegion';
  };
  athleteGroups: teamGroup[];
}
interface formState {
  point?: string | number;
  result?: string;
  dirAthlete?: DirAthlete;
}

interface rowProps {
  row: any;
  id: Scalars['UUID'];
  updated: Function;
}

function Row(props: rowProps) {
  const { row, id, updated } = props;
  const defaultState: formState = {
    dirAthlete: undefined,
    point: undefined,
    result: undefined,
  };

  const [deniedAthletes, setDeniedAthletes] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [programTypeId, setProgramTypeId] = useState<Scalars['UUID']>('');
  const [competitionId, setCompetitionId] = useState<Scalars['UUID']>('');
  const [athleteToUpdateId, setAthleteToUpdateId] = useState<Scalars['UUID']>('');
  const classes = useRowStyles();
  const [updateDirAthlete]: UpdateDirAthleteMutationHookResult = useUpdateDirAthleteMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  const onSubmit = async (values: formState) => {
    const { dirAthlete, point, result } = values;

    try {
      if (currentRequest === 'update') {
        await updateDirAthlete({
          variables: {
            id: athleteToUpdateId,
            data: {
              competetionResult: {
                update: [
                  {
                    where: {
                      id: competitionId,
                    },
                    data: {
                      point: Number(point),
                      result,
                      sportingEventProgramType: {
                        connect: {
                          id: programTypeId,
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        });

        setOpenDialog(false);
        setCurrentRequest('');
        reset(defaultState);
        setProgramTypeId('');
        setAthleteToUpdateId('');
        setCompetitionId('');
        handleSnackBar('success', 'результат успешно обновлено');
        updated();
        return;
      }
      await updateDirAthlete({
        variables: {
          id: dirAthlete?.id,
          data: {
            competetionResult: {
              create: [
                {
                  point: Number(point),
                  result: result || '',
                  sportingEventProgramType: {
                    connect: {
                      id: programTypeId,
                    },
                  },
                },
              ],
            },
          },
        },
      });

      setOpenDialog(false);
      setDeniedAthletes([]);
      reset(defaultState);
      handleSnackBar('success', 'результат успешно добавлено');
      updated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteResult = async ({ athleteId, competId }: { athleteId: Scalars['UUID']; competId: Scalars['UUID'] }) => {
    try {
      await updateDirAthlete({
        variables: {
          id: athleteId,
          data: {
            competetionResult: {
              delete: [
                {
                  id: competId,
                },
              ],
            },
          },
        },
      });

      handleSnackBar('success', 'результат успешно удалено');
      updated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.clsAgeGroups && row.clsAgeGroups[0].label}
        </TableCell>
        <TableCell>{row.minAge}</TableCell>
        <TableCell>{row.maxAge}</TableCell>
        <TableCell>{row.discipline?.label}</TableCell>
        <TableCell>{row.start}</TableCell>
        <TableCell>
          <Tooltip title="добавить" placement="top-start">
            <IconButton
              color="primary"
              onClick={() => {
                setDeniedAthletes(
                  _.flattenDeep(
                    row.teamsArray.map((team: DirNationalTeam) =>
                      team.athleteGroups?.map((group: teamGroup) =>
                        group.athletesArray?.map((athlete: DirNationalTeamAthlete) => athlete.dirAthlete.id),
                      ),
                    ),
                  ),
                );
                reset(defaultState);
                setProgramTypeId(row.id);
                setOpenDialog(true);
              }}
            >
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.teamsArray.length > 0 && (
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Место</TableCell>
                      <TableCell>Реестровый номер</TableCell>
                      <TableCell>ФИО</TableCell>
                      <TableCell>ДР</TableCell>
                      <TableCell>Регион</TableCell>
                      <TableCell>Результат</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.teamsArray.map((team: teamArray) =>
                      team.athleteGroups?.map((group: teamGroup) =>
                        group.athletesArray?.map((athlete: DirNationalTeamAthlete) => (
                          <TableRow key={athlete.id}>
                            <TableCell component="th" scope="row">
                              {athlete?.dirAthlete?.competetionResult &&
                                athlete?.dirAthlete?.competetionResult[0].point}
                            </TableCell>
                            <TableCell>{athlete?.dirAthlete?.dirPerson.registryNumber}</TableCell>
                            <TableCell>
                              {`${athlete?.dirAthlete?.dirPerson.lastname} ${
                                athlete?.dirAthlete?.dirPerson.firstname
                              } ${athlete?.dirAthlete?.dirPerson.patronymic || ''}`}
                            </TableCell>
                            <TableCell>{moment(athlete?.dirAthlete?.dirPerson.birthday).format('DD.MM.YY')}</TableCell>
                            <TableCell>{team.dirRegion?.label}</TableCell>
                            <TableCell>
                              {athlete?.dirAthlete?.competetionResult &&
                                athlete?.dirAthlete?.competetionResult[0].result}
                            </TableCell>
                            <TableCell>
                              <div className={classes.row}>
                                <Tooltip title="изменить" placement="top-start">
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      setCurrentRequest('update');
                                      athlete.dirAthlete?.competetionResult &&
                                        reset({
                                          ...athlete.dirAthlete?.competetionResult[0],
                                        });
                                      setAthleteToUpdateId(athlete.dirAthlete?.id);
                                      setCompetitionId(
                                        athlete?.dirAthlete?.competetionResult &&
                                          athlete?.dirAthlete?.competetionResult[0]?.id,
                                      );
                                      setProgramTypeId(row.id);
                                      setOpenDialog(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="подробности" placement="top-start">
                                  <IconButton
                                    color="secondary"
                                    onClick={() =>
                                      deleteResult({
                                        athleteId: athlete.dirAthlete.id,
                                        competId:
                                          athlete?.dirAthlete.competetionResult &&
                                          athlete?.dirAthlete.competetionResult[0].id,
                                      })
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )),
                      ),
                    )}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          setCurrentRequest('');
          reset(defaultState);
        }}
        aria-labelledby="add-calendar-dialog"
        open={openDialog}
        maxWidth="xs"
      >
        <DialogTitle id="add-calendar-dialog">Результат</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!currentRequest && (
                  <AthletesInTeam
                    eventId={id}
                    deniedAthletes={deniedAthletes}
                    label="Спорстмен"
                    control={control}
                    error={!!errors['dirAthlete']}
                    name="dirAthlete"
                    rules={{ required: true }}
                  />
                )}
              </Grid>

              <Grid item container justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  <Input
                    label="Место"
                    control={control}
                    error={!!errors['point']}
                    name="point"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Результат"
                    control={control}
                    error={!!errors['result']}
                    name="result"
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>

              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  {(currentRequest === 'update' && 'Сохранить') || 'Добавить'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

const CompetitionResults: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const [groupedArray, setGroupedArray] = useState<any>([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const { loading, refetch, data } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });

  const [programTypes, dirNationalTeams] = [
    data?.dirSportingEvent?.programTypes,
    data?.dirSportingEvent?.dirNationalTeams,
  ];

  const handleUpdate = async () => {
    refetch().then((resp) => setShouldUpdate(true));
  };

  useEffect(() => {
    if (programTypes && dirNationalTeams) {
      setGroupedArray(groupProgramTypesAndNationalTeams(programTypes, dirNationalTeams));
    }

    setShouldUpdate(false);
  }, [shouldUpdate, programTypes, dirNationalTeams]);

  useEffect(() => {
    if (programTypes && dirNationalTeams) {
      setGroupedArray(groupProgramTypesAndNationalTeams(programTypes, dirNationalTeams));
    }
  }, [loading, programTypes, dirNationalTeams]);

  if (loading) return <LinearProgress />;

  return (
    (groupedArray.length > 0 && (
      <TableContainer component={Paper} style={{ marginTop: 15 }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Возрастные группы</TableCell>
              <TableCell>Возраст с</TableCell>
              <TableCell>Возраст по&nbsp;</TableCell>
              <TableCell>Дисциплина&nbsp;</TableCell>
              <TableCell>Старт&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedArray.map((program: any) => (
              <Row key={program?.id} row={program} id={id} updated={handleUpdate} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )) ||
    null
  );
};

export default CompetitionResults;
