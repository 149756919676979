import {
  Archive,
  DirSportDiscipline,
  DirSportDisciplineGroup,
  GroupTypesSportAndDisciplinesDocument,
  Maybe,
  TypeSportGroupDisciplinesArchiveMutationHookResult,
  TypeSportGroupDisciplinesAssignDisciplineMutationHookResult,
  TypeSportGroupDisciplinesCreateMutationHookResult,
  TypeSportGroupDisciplinesRenameMutationHookResult,
  TypeSportGroupDisciplinesUnAssignDisciplineMutationHookResult,
  useTypeSportGroupDisciplinesArchiveMutation,
  useTypeSportGroupDisciplinesAssignDisciplineMutation,
  useTypeSportGroupDisciplinesCreateMutation,
  useTypeSportGroupDisciplinesRenameMutation,
  useTypeSportGroupDisciplinesUnAssignDisciplineMutation,
  useTypeSportGroupsDisciplinesQuery,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import ArchiveComponent from '../../Dialogs/Archive/Archive';
import TypeSportDisciplines from '../../../module/TypeSportDisciplines';
import _differenceBy from 'lodash/differenceBy';

type Discipline = { __typename?: 'DirSportDisciplineGroup' } & Pick<DirSportDisciplineGroup, 'id' | 'name'> & {
    disciplines?: Maybe<
      Array<
        { __typename?: 'DirSportDiscipline' } & {
          value: DirSportDiscipline['id'];
          label: DirSportDiscipline['name'];
        }
      >
    >;
    archive?: Maybe<{ __typename?: 'Archive' } & Pick<Archive, 'id' | 'reason' | 'comment'>>;
  };

const GroupsDisciplines: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data, refetch } = useTypeSportGroupsDisciplinesQuery({ variables: { id } });
  const { handleSubmit, register, control, errors, reset, formState } = useForm({
    defaultValues: { disciplines: [], name: '', id: null } as Discipline,
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | Discipline>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [create]: TypeSportGroupDisciplinesCreateMutationHookResult = useTypeSportGroupDisciplinesCreateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });
  const [rename]: TypeSportGroupDisciplinesRenameMutationHookResult = useTypeSportGroupDisciplinesRenameMutation();
  const [archive]: TypeSportGroupDisciplinesArchiveMutationHookResult = useTypeSportGroupDisciplinesArchiveMutation();
  const [
    assign,
  ]: TypeSportGroupDisciplinesAssignDisciplineMutationHookResult = useTypeSportGroupDisciplinesAssignDisciplineMutation();
  const [
    unassign,
  ]: TypeSportGroupDisciplinesUnAssignDisciplineMutationHookResult = useTypeSportGroupDisciplinesUnAssignDisciplineMutation();

  const onSubmit = handleSubmit(async (values: Discipline) => {
    try {
      if (edit) {
        const disciplinesRemoved = _differenceBy(edit.disciplines, values.disciplines || [], 'value');
        const disciplinesAdded = _differenceBy(values.disciplines, edit.disciplines || [], 'value');
        if (values?.name !== edit.name) {
          await rename({
            variables: {
              id,
              name: values?.name,
              idGroup: edit.id,
            },
          });
        }
        if (disciplinesAdded.length > 0) {
          for (const value of disciplinesAdded) {
            await assign({
              variables: {
                id,
                discipline: value.value,
                idGroup: edit.id,
              },
            });
          }
        }
        if (disciplinesRemoved.length > 0) {
          for (const value of disciplinesAdded) {
            await unassign({
              variables: {
                id,
                discipline: value.value,
                idGroup: edit.id,
              },
            });
          }
        }
        setEdit(null);
        enqueueSnackbar(`Группа "${values?.name}" успешно обновлена`, { variant: 'success' });
      } else {
        await create({
          variables: {
            id,
            name: values?.name,
          },
        });
        enqueueSnackbar(`Группа "${values?.name}" успешно добавлена`, { variant: 'success' });
      }
      reset({ disciplines: [], name: '' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              reset({ disciplines: [], name: '' });
              setMode('form');
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={4}>
              <Input label="Название дисциплины" control={control} error={!!errors['name']} name="name" />
            </Grid>
            {edit && (
              <Grid item md={12}>
                <TypeSportDisciplines
                  label="Дисциплины"
                  error={!!errors['disciplines']}
                  name="disciplines"
                  control={control}
                  filter={{ dirSport: { id }, archive: null }}
                />
              </Grid>
            )}
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={8}>
          <List>
            {data?.dirSport?.disciplineGroups?.map((item) => (
              <ListItem key={item.id} alignItems="flex-start">
                <ListItemText
                  primary={item.name}
                  secondary={item.disciplines?.reduce(
                    (accumulator, value) =>
                      accumulator.length > 0 ? `${accumulator}, ${value.label}` : `${value.label}`,
                    '',
                  )}
                />
                {!readonly && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => {
                        setEdit(item);
                        reset(item);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    <ArchiveComponent id={item.id} archiveStatus={item.archive} refetch={refetch} archive={archive} />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default GroupsDisciplines;
