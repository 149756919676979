import {
  ClassifierByIdDocument,
  ClassifierByIdQuery,
  ClassifierByIdQueryHookResult,
  ClassifierByIdQueryResult,
  ClassifierValue,
  ClassifierValueArchiveMutationHookResult,
  ClassifierValueUnArchiveMutationHookResult,
  CreateClassifierValueDocument,
  CreateClassifierValueMutationHookResult,
  UpdateClassifierValueMutationHookResult,
  useClassifierByIdQuery,
  useClassifierValueArchiveMutation,
  useClassifierValueUnArchiveMutation,
  useCreateClassifierValueMutation,
  useUniqueValidationClassifierValueLazyQuery,
  useUniqueValidationClassifierValueQuery,
  useUpdateClassifierValueMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import ArchiveComponent from '../../Dialogs/Archive/Archive';

type ClassifierValueProps = Pick<ClassifierValue, 'fullName' | 'shortName' | 'id' | 'order'>;

const ClassifierValuesWidget: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data, refetch } = useClassifierByIdQuery({ variables: { id } });
  const { data: exist, refetch: existFetch } = useUniqueValidationClassifierValueQuery({ skip: true });
  const { handleSubmit, register, control, errors, reset, formState } = useForm({
    defaultValues: { order: null, fullName: '', shortName: '', id: undefined } as ClassifierValueProps,
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | ClassifierValueProps>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [create]: CreateClassifierValueMutationHookResult = useCreateClassifierValueMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: ClassifierByIdDocument,
        data: {
          ...data?.createClassifierValue,
        },
      });
    },
  });

  const [archive]: ClassifierValueArchiveMutationHookResult = useClassifierValueArchiveMutation();
  const [unarchive]: ClassifierValueUnArchiveMutationHookResult = useClassifierValueUnArchiveMutation();

  const [update]: UpdateClassifierValueMutationHookResult = useUpdateClassifierValueMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: ClassifierByIdDocument,
        data: {
          ...data?.updateClassifierValue,
        },
      });
    },
  });

  const onSubmit = handleSubmit(async (values: ClassifierValueProps) => {
    const variables = {
      id,
      fullName: values.fullName || '',
      shortName: values.shortName || '',
      order: (values.order && +values.order) || 0,
    };
    try {
      if (edit) {
        await update({
          variables: { ...variables, id: values.id },
        });
        setEdit(null);
        enqueueSnackbar(`Значение классификатора "${values?.fullName}" успешно обновлена`, {
          variant: 'success',
        });
      } else {
        await create({
          variables,
        });
        enqueueSnackbar(`Значение классификатора "${values?.fullName}" успешно добавлена`, {
          variant: 'success',
        });
      }
      reset({ order: null, fullName: '', shortName: '', id: undefined });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  useEffect(() => {
    if (data?.classifier) {
      reset(data?.classifier);
    }
  }, [reset, data]);

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({ order: null, fullName: '', shortName: '', id: undefined });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={5}>
              <Input
                label="Полное название"
                error={!!errors['fullName']}
                errorMessage={errors['fullName']?.message}
                name="fullName"
                control={control}
                rules={{
                  required: true,
                  validate: async (data1) => {
                    const isExist = await existFetch({ fullName: data1 });
                    return isExist?.data?.classifierValues && isExist?.data?.classifierValues.length === 0
                      ? undefined
                      : 'Значение классификатора уже существует';
                  },
                }}
              />
            </Grid>
            <Grid item md={2}>
              <Input
                label="Краткое название"
                control={control}
                error={!!errors['shortName']}
                name="shortName"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={2}>
              <Input label="Порядковый номер" type="number" control={control} error={!!errors['order']} name="order" />
            </Grid>
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={6}>
          <List>
            {data?.classifier?.values?.map((item) => (
              <ListItem key={item.id} alignItems="flex-start">
                <ListItemText primary={item.fullName} />
                {!readonly && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => {
                        reset(item);
                        setEdit(item);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    <ArchiveComponent
                      id={item.id}
                      archiveStatus={item.archive}
                      refetch={refetch}
                      archive={archive}
                      unarchive={unarchive}
                      canUnArchive
                    />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default ClassifierValuesWidget;
