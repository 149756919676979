import React, { FC } from 'react';
import { DirSport, useTypeSportsListQuery } from '../api';
import { Select } from '../components/Inputs';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface TypeSports
  extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'disabled' | 'label' | 'error' | 'errorMessage'> {
  data?: {
    value: DirSport['id'];
    label: DirSport['fullName'];
  }[];
  usedValue?: DirSport['id'][];
}

const TypeSports: FC<TypeSports> = ({ disabled, usedValue, label, error, control, name, rules, data }) => {
  const { data: dirSports } = useTypeSportsListQuery();
  return (
    <Select
      label={label}
      data={
        data ||
        (usedValue && dirSports?.dirSports?.filter((sport) => usedValue?.indexOf(sport.value) === -1)) ||
        dirSports?.dirSports ||
        []
      }
      control={control}
      error={error}
      disabled={disabled}
      name={name}
      rules={rules}
    />
  );
};

export default TypeSports;
