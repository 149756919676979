import React, { FC, useState, useEffect } from 'react';

import TableBody from '@material-ui/core/TableBody';
import {
  IconButton,
  Table,
  TableCell,
  Tooltip,
  Button,
  Grid,
  Link,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { sortReferees } from '../../../utils/sortRefereesInSportingEvents';
import { useForm } from 'react-hook-form';
import Persons from '../../../module/Persons';
import RefereesPositions from '../../../module/RefereesPositions';
import getMessage from '../../../messages';
import { Input } from '../../Inputs';
import _ from 'lodash';
import moment from 'moment';
import {
  Scalars,
  DirPersonPhone,
  useSportingEventLazyQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
} from '../../../api';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  row: {
    display: 'flex',
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  referee?: {
    label: string;
    value: Scalars['UUID'];
    personRoleId: Scalars['UUID'];
  };
  position?: { label: string; value: Scalars['UUID']; __typename?: string };
}

const Referees: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const defaultRefereeState: formState = {
    referee: undefined,
    position: undefined,
  };

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND?.replace('/graphql', '');

  const [isUpdatingReferee, setIsUpdatingReferee] = useState('');
  const [isAddingNewReferee, setIsAddingNewReferee] = useState(false);
  const [refereeId, setRefereeId] = useState<Scalars['UUID']>('');
  const [positionId, setPositionId] = useState<Scalars['UUID']>('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const classes = useStyles();

  const [fetch, { data, refetch }] = useSportingEventLazyQuery({ fetchPolicy: 'no-cache', variables: { id } });

  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: primaryRefereeFormHandleSubmit,
    control: primaryRefereeFormControl,
    errors: primaryRefereeFormErrors,
    reset: primaryRefereeFormReset,
    formState: primaryRefereeFormState,
  } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: primarySecretaryRefereeFormHandleSubmit,
    control: primarySecretaryRefereeFormControl,
    errors: primarySecretaryRefereeFormErrors,
    reset: primarySecretaryRefereeFormReset,
    formState: primarySecretaryRefereeFormState,
  } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: vicePrimaryRefereesFormHandleSubmit,
    control: vicePrimaryRefereesFormControl,
    errors: vicePrimaryRefereesFormErrors,
    reset: vicePrimaryRefereesFormReset,
    formState: vicePrimaryRefereesFormState,
  } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: vicePrimarySecretaryRefereesFormHandleSubmit,
    control: vicePrimarySecretaryRefereesFormControl,
    errors: vicePrimarySecretaryRefereesFormErrors,
    reset: vicePrimarySecretaryRefereesFormReset,
    formState: vicePrimarySecretaryRefereesFormState,
  } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: sportRefereesFormHandleSubmit,
    control: sportRefereesFormControl,
    errors: sportRefereesFormErrors,
    reset: sportRefereesFormReset,
    formState: sportRefereesFormState,
  } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: kVotFormHandleSubmit,
    control: kVotFormControl,
    errors: kVotFormErrors,
    reset: kVotFormReset,
    formState: kVotFormState,
  } = useForm({
    defaultValues: {
      refereesQuota: data?.dirSportingEvent?.refereesQuota,
      nonresidentRefereesQuota: data?.dirSportingEvent?.nonresidentRefereesQuota,
    },
  });

  const [refereesQuota, nonresidentRefereesQuota, referees] = [
    data?.dirSportingEvent?.refereesQuota,
    data?.dirSportingEvent?.nonresidentRefereesQuota,
    data?.dirSportingEvent?.referees,
  ];

  const sports: any = data?.dirSportingEvent?.sports;

  const {
    primaryReferee,
    primarySecretaryReferee,
    vicePrimaryReferees,
    vicePrimarySecretaryReferees,
    sportReferees,
  } = sortReferees(referees, sports);

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetch({ variables: { id } });
    }
  }, [id, fetch]);

  const deleteReferee = async (refereeId: Scalars['UUID']) => {
    if (refereeId) {
      try {
        await updateEvent({
          variables: {
            data: {
              referees: {
                delete: [
                  {
                    id: refereeId,
                  },
                ],
              },
            },
            id,
          },
        });
        handleSnackBar('success', 'Судь успешно удалена');
        fetch({ variables: { id } });
      } catch (error) {
        handleSnackBar('error', getMessage(error.message));
      }
    }
  };

  const onSubmit = async (values: formState, role: string) => {
    const { referee, position } = values;

    try {
      if (refereesQuota || nonresidentRefereesQuota) {
        if (isUpdatingReferee && refereeId) {
          await updateEvent({
            variables: {
              data: {
                referees: {
                  update: [
                    {
                      where: {
                        id: refereeId,
                      },
                      data: {
                        dirReferee: {
                          connect: { id: referee?.personRoleId },
                        },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          handleSnackBar('success', 'Судь успешно обновлена');
          fetch({ variables: { id } });
          setIsUpdatingReferee('');

          switch (role) {
            case 'primaryReferee':
              primaryRefereeFormReset(defaultRefereeState);
              break;
            case 'primarySecretaryReferee':
              primarySecretaryRefereeFormReset(defaultRefereeState);
              break;
            case 'vicePrimaryReferees':
              vicePrimaryRefereesFormReset(defaultRefereeState);
              break;
            case 'vicePrimarySecretaryReferees':
              vicePrimarySecretaryRefereesFormReset(defaultRefereeState);
              break;
            case 'sportReferees':
              sportRefereesFormReset(defaultRefereeState);
              break;

            default:
              break;
          }
          reset(defaultRefereeState);

          return;
        }
        await updateEvent({
          variables: {
            data: {
              referees: {
                create: [
                  {
                    dirReferee: {
                      connect: {
                        id: referee?.personRoleId,
                      },
                    },
                    clsRefereePosition: {
                      connect: {
                        id: position?.value || positionId,
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });

        handleSnackBar('success', 'Судь успешно добавлена');

        setIsUpdatingReferee('');
        setPositionId('');
        switch (role) {
          case 'primaryReferee':
            primaryRefereeFormReset(defaultRefereeState);
            break;
          case 'primarySecretaryReferee':
            primarySecretaryRefereeFormReset(defaultRefereeState);
            break;
          case 'vicePrimaryReferees':
            vicePrimaryRefereesFormReset(defaultRefereeState);
            break;
          case 'vicePrimarySecretaryReferees':
            vicePrimarySecretaryRefereesFormReset(defaultRefereeState);
            break;
          case 'sportReferees':
            sportRefereesFormReset(defaultRefereeState);
            break;

          default:
            break;
        }
        reset(defaultRefereeState);
        fetch({ variables: { id } });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onUpdateKvots = async (values: any) => {
    const { refereesQuota, nonresidentRefereesQuota } = values;

    try {
      await updateEvent({
        variables: {
          data: {
            refereesQuota: parseInt(refereesQuota, 10) || 0,
            nonresidentRefereesQuota: parseInt(nonresidentRefereesQuota, 10) || 0,
          },
          id,
        },
      });
      handleSnackBar('success', 'квоты успешно обновлена');
      fetch({ variables: { id } });
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <div style={{ marginTop: 15 }}>
      <Grid container>
        <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-referees/${id}`}>
          <Button size="small" variant="outlined" color="primary">
            Выгрузить список судей
          </Button>
        </Link>
      </Grid>
      <form onSubmit={kVotFormHandleSubmit(onUpdateKvots)} style={{ width: '100%' }}>
        <Grid container spacing={2} className={classes.verticalSpaceMarge}>
          <Grid item container>
            <Grid item xs={4}></Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}>
              <span className={classes.title}>Факт</span>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={4}>
              <Input
                label="Квота на судей"
                control={kVotFormControl}
                error={!!kVotFormErrors['refereesQuota']}
                name="refereesQuota"
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}>
              <span className={classes.title}>
                {_.filter(referees, (referee) => referee?.dirReferee?.dirPerson.isRussia).length}
              </span>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={4}>
              <Input
                label="Квота на иногородний судей"
                control={kVotFormControl}
                error={!!kVotFormErrors['nonresidentRefereesQuota']}
                name="nonresidentRefereesQuota"
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}>
              <span className={classes.title}>
                {_.filter(referees, (referee) => referee?.dirReferee?.dirPerson.isRussia === false).length}
              </span>
            </Grid>
          </Grid>
          <Grid item container>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!kVotFormState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </form>
      <TableContainer component={Paper}>
        <Table aria-label="referee table">
          <TableHead>
            <TableRow>
              <TableCell>ФИО</TableCell>
              <TableCell>Должность</TableCell>
              <TableCell>Тел.</TableCell>
              <TableCell>Email </TableCell>
              <TableCell>Судейская категория</TableCell>
              <TableCell>Действ.до&nbsp;</TableCell>
              <TableCell>Регион&nbsp;</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" key="0e0e7095-c750-5f97-9c0a-db2f5e855e3c">
                {isUpdatingReferee === '0e0e7095-c750-5f97-9c0a-db2f5e855e3c' ? (
                  <form
                    onSubmit={primaryRefereeFormHandleSubmit((values) => onSubmit(values, 'primaryReferee'))}
                    style={{ width: '100%' }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <Persons
                          label=""
                          control={primaryRefereeFormControl}
                          error={!!primaryRefereeFormErrors['referee']}
                          filter={{
                            refereeProfile: {
                              sports_some: {
                                dirSport: {
                                  id_in: sports.map((s: any) => s?.dirSport.value),
                                },
                              },
                            },
                          }}
                          profile="refereeProfile"
                          name="referee"
                          rules={{ required: true }}
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={!primaryRefereeFormState.isDirty}
                        >
                          Сохранить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <span>
                    {primaryReferee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {primaryReferee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {primaryReferee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </span>
                )}
              </TableCell>
              <TableCell>Гл. спортивный судья</TableCell>
              <TableCell>
                {primaryReferee?.dirReferee?.dirPerson.phones?.map((p: DirPersonPhone) => p.number).join(' , ') || ''}
              </TableCell>
              <TableCell>
                {primaryReferee?.dirReferee?.dirPerson.email || ''}
                &nbsp;
              </TableCell>
              <TableCell>{primaryReferee?.category?.clsRefereeCategory?.fullName}</TableCell>
              <TableCell>
                {(primaryReferee?.category?.isEndless && 'Бессрочно') ||
                  (primaryReferee?.category?.validUntil &&
                    moment(primaryReferee?.category?.validUntil).format('DD.MM.YY'))}
              </TableCell>
              <TableCell>
                {(primaryReferee?.dirReferee?.dirPerson.isRussia &&
                  primaryReferee?.dirReferee?.dirPerson.dirRegion.fullName) ||
                  primaryReferee?.dirReferee?.dirPerson.dirCountry.fullName ||
                  primaryReferee?.dirReferee?.dirPerson.dirForeignCity.fullName ||
                  ''}
              </TableCell>
              <TableCell>
                <Link
                  href={
                    (primaryReferee?.category?.dirDocument &&
                      `${DOWNLOAD_LINK}${primaryReferee?.category?.dirDocument.file.path}`) ||
                    '#'
                  }
                >
                  <Button
                    color={(primaryReferee?.category?.dirDocument && 'primary') || 'default'}
                    size="small"
                    variant="contained"
                  >
                    Удостоверение
                  </Button>
                </Link>
              </TableCell>
              <TableCell>
                {!readonly && (
                  <div className={classes.row}>
                    {!(isUpdatingReferee === '0e0e7095-c750-5f97-9c0a-db2f5e855e3c') && (
                      <Tooltip title="изменить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          onClick={() => {
                            if (refereesQuota || nonresidentRefereesQuota) {
                              setIsUpdatingReferee('0e0e7095-c750-5f97-9c0a-db2f5e855e3c');
                              setRefereeId(primaryReferee?.id);
                              setPositionId('4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb5');
                              primaryRefereeFormReset(defaultRefereeState);
                            }
                          }}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="удалить" placement="top-start">
                      <IconButton
                        className={classes.btn}
                        onClick={() => deleteReferee(primaryReferee?.id)}
                        color="secondary"
                      >
                        <DeleteIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" key="7f694a09-1315-5d80-8be0-b4662a054edc">
                {isUpdatingReferee === '7f694a09-1315-5d80-8be0-b4662a054edc' ? (
                  <form
                    onSubmit={primarySecretaryRefereeFormHandleSubmit((values) =>
                      onSubmit(values, 'primarySecretaryReferee'),
                    )}
                    style={{ width: '100%' }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <Persons
                          label=""
                          control={primarySecretaryRefereeFormControl}
                          error={!!primarySecretaryRefereeFormErrors['referee']}
                          filter={{
                            refereeProfile: {
                              sports_some: {
                                dirSport: {
                                  id_in: sports.map((s: any) => s?.dirSport.value),
                                },
                              },
                            },
                          }}
                          profile="refereeProfile"
                          name="referee"
                          rules={{ required: true }}
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={!primarySecretaryRefereeFormState.isDirty}
                        >
                          Сохранить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <span>
                    {primarySecretaryReferee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {primarySecretaryReferee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {primarySecretaryReferee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </span>
                )}
              </TableCell>
              <TableCell>Гл. спортивный судья - секретарь </TableCell>
              <TableCell>
                {primarySecretaryReferee?.dirReferee?.dirPerson.phones
                  .map((p: DirPersonPhone) => p.number)
                  .join(' , ') || ''}
              </TableCell>
              <TableCell>
                {primarySecretaryReferee?.dirReferee?.dirPerson.email || ''}
                &nbsp;
              </TableCell>
              <TableCell>{primarySecretaryReferee?.category?.clsRefereeCategory?.fullName}</TableCell>
              <TableCell>
                {(primarySecretaryReferee?.category?.isEndless && 'Бессрочно') ||
                  (primarySecretaryReferee?.category?.validUntil &&
                    moment(primarySecretaryReferee?.category?.validUntil).format('DD.MM.YY'))}
              </TableCell>
              <TableCell>
                {(primarySecretaryReferee?.dirReferee?.dirPerson.isRussia &&
                  primarySecretaryReferee?.dirReferee?.dirPerson.dirRegion.fullName) ||
                  primarySecretaryReferee?.dirReferee?.dirPerson.dirCountry.fullName ||
                  primarySecretaryReferee?.dirReferee?.dirPerson.dirForeignCity.fullName ||
                  ''}
              </TableCell>
              <TableCell>
                <Link
                  href={
                    (primarySecretaryReferee?.category?.dirDocument &&
                      `${DOWNLOAD_LINK}${primarySecretaryReferee?.category?.dirDocument.file.path}`) ||
                    '#'
                  }
                >
                  <Button
                    color={(primarySecretaryReferee?.category?.dirDocument && 'primary') || 'default'}
                    size="small"
                    variant="contained"
                  >
                    Удостоверение
                  </Button>
                </Link>
              </TableCell>
              <TableCell>
                {!readonly && (
                  <div className={classes.row}>
                    {!(isUpdatingReferee === '7f694a09-1315-5d80-8be0-b4662a054edc') && (
                      <Tooltip title="изменить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          onClick={() => {
                            if (refereesQuota || nonresidentRefereesQuota) {
                              setIsUpdatingReferee('7f694a09-1315-5d80-8be0-b4662a054edc');
                              setRefereeId(primarySecretaryReferee?.id);
                              setPositionId('7026b40a-365d-4422-a8f8-12c9da114a43');
                              primarySecretaryRefereeFormReset(defaultRefereeState);
                            }
                          }}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="удалить" placement="top-start">
                      <IconButton
                        className={classes.btn}
                        onClick={() => deleteReferee(primarySecretaryReferee?.id)}
                        color="secondary"
                      >
                        <DeleteIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                key="9b9bd8eb-a98d-5ef4-abb7-b3829e041dee"
                colSpan={2}
                style={{ borderBottom: 'none' }}
              >
                <span className={classes.title}>Судьи:&nbsp;</span>
                {!isAddingNewReferee && !readonly && (
                  <Tooltip title="добавить" placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                      onClick={() => (refereesQuota || nonresidentRefereesQuota) && setIsAddingNewReferee(true)}
                    >
                      <AddBoxIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
              {isAddingNewReferee && (
                <TableCell colSpan={6}>
                  <form onSubmit={handleSubmit((values) => onSubmit(values, ''))} style={{ width: '100%' }}>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item md={5} xs={12}>
                        <Persons
                          label=""
                          control={control}
                          error={!!errors['referee']}
                          filter={{
                            refereeProfile: {
                              sports_some: {
                                dirSport: {
                                  id_in: sports?.map((s: any) => s?.dirSport.value),
                                },
                              },
                            },
                          }}
                          profile="refereeProfile"
                          name="referee"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <RefereesPositions
                          label="Должность"
                          control={control}
                          error={!!errors['position']}
                          name="position"
                          rules={{ required: true }}
                        />
                      </Grid>
                      {!readonly && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<SaveIcon />}
                            type="submit"
                            disabled={!formState.isDirty}
                          >
                            Сохранить
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </TableCell>
              )}
            </TableRow>
            {vicePrimaryReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  {isUpdatingReferee === referee.id ? (
                    <form
                      onSubmit={vicePrimaryRefereesFormHandleSubmit((values) =>
                        onSubmit(values, 'vicePrimaryReferees'),
                      )}
                      style={{ width: '100%' }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Persons
                            label=""
                            control={vicePrimaryRefereesFormControl}
                            error={!!vicePrimaryRefereesFormErrors['referee']}
                            filter={{
                              refereeProfile: {
                                sports_some: {
                                  dirSport: {
                                    id_in: sports.map((s: any) => s?.dirSport.value),
                                  },
                                },
                              },
                            }}
                            profile="refereeProfile"
                            name="referee"
                            rules={{ required: true }}
                          />
                        </Grid>
                        {!readonly && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<SaveIcon />}
                              type="submit"
                              disabled={!vicePrimaryRefereesFormState.isDirty}
                            >
                              Сохранить
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  ) : (
                    <span>
                      {referee?.dirReferee?.dirPerson.lastname || ''}
                      &nbsp;
                      {referee?.dirReferee?.dirPerson.firstname || ''}
                      &nbsp;
                      {referee?.dirReferee?.dirPerson.patronymic || ''}
                      &nbsp;
                    </span>
                  )}
                </TableCell>
                <TableCell>Зам. гл. спортивного судьи</TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.phones.map((p: DirPersonPhone) => p.number).join(' , ') || ''}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee?.dirPerson.isRussia && referee?.dirReferee?.dirPerson.dirRegion.fullName) ||
                    referee?.dirReferee?.dirPerson.dirCountry.fullName ||
                    referee?.dirReferee?.dirPerson.dirForeignCity.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  <Link
                    href={
                      (referee?.category.dirDocument &&
                        `${DOWNLOAD_LINK}${referee?.category?.dirDocument.file.path}`) ||
                      '#'
                    }
                  >
                    <Button
                      color={(referee?.category.dirDocument && 'primary') || 'default'}
                      size="small"
                      variant="contained"
                    >
                      Удостоверение
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId('fc81c851-24f8-44be-b0c3-7b626e28aba7');
                                vicePrimaryRefereesFormReset(defaultRefereeState);
                              }
                            }}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton className={classes.btn} color="secondary" onClick={() => deleteReferee(referee)}>
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {vicePrimarySecretaryReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  {isUpdatingReferee === referee.id ? (
                    <form
                      onSubmit={vicePrimarySecretaryRefereesFormHandleSubmit((values) =>
                        onSubmit(values, 'vicePrimarySecretaryReferees'),
                      )}
                      style={{ width: '100%' }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Persons
                            label=""
                            control={vicePrimarySecretaryRefereesFormControl}
                            error={!!vicePrimarySecretaryRefereesFormErrors['referee']}
                            filter={{
                              refereeProfile: {
                                sports_some: {
                                  dirSport: {
                                    id_in: sports.map((s: any) => s?.dirSport.value),
                                  },
                                },
                              },
                            }}
                            profile="refereeProfile"
                            name="referee"
                            rules={{ required: true }}
                          />
                        </Grid>
                        {!readonly && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<SaveIcon />}
                              type="submit"
                              disabled={!vicePrimarySecretaryRefereesFormState.isDirty}
                            >
                              Сохранить
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  ) : (
                    <span>
                      {referee?.dirReferee.dirPerson.lastname || ''}
                      &nbsp;
                      {referee?.dirReferee.dirPerson.firstname || ''}
                      &nbsp;
                      {referee?.dirReferee.dirPerson.patronymic || ''}
                      &nbsp;
                    </span>
                  )}
                </TableCell>
                <TableCell>Зам. гл. секретаря</TableCell>
                <TableCell>
                  {referee?.dirReferee.dirPerson.phones.map((p: DirPersonPhone) => p.number).join(' , ') || ''}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee.dirPerson.isRussia && referee?.dirReferee.dirPerson.dirRegion.fullName) ||
                    referee?.dirReferee.dirPerson.dirCountry.fullName ||
                    referee?.dirReferee.dirPerson.dirForeignCity.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  <Link
                    href={
                      (referee?.category?.dirDocument &&
                        `${DOWNLOAD_LINK}${referee?.category?.dirDocument.file.path}`) ||
                      '#'
                    }
                  >
                    <Button
                      color={(referee?.category?.dirDocument && 'primary') || 'default'}
                      size="small"
                      variant="contained"
                    >
                      Удостоверение
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId('fc81c851-24f8-44be-b0c3-7b626e28aba7');
                                vicePrimarySecretaryRefereesFormReset(defaultRefereeState);
                              }
                            }}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton className={classes.btn} onClick={() => deleteReferee(referee)} color="secondary">
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}

            {sportReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  {isUpdatingReferee === referee.id ? (
                    <form
                      onSubmit={sportRefereesFormHandleSubmit((values) => onSubmit(values, 'sportReferees'))}
                      style={{ width: '100%' }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Persons
                            label=""
                            control={sportRefereesFormControl}
                            error={!!sportRefereesFormErrors['referee']}
                            filter={{
                              refereeProfile: {
                                sports_some: {
                                  dirSport: {
                                    id_in: sports.map((s: any) => s?.dirSport.value),
                                  },
                                },
                              },
                            }}
                            profile="refereeProfile"
                            name="referee"
                            rules={{ required: true }}
                          />
                        </Grid>
                        {!readonly && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              startIcon={<SaveIcon />}
                              type="submit"
                              disabled={!sportRefereesFormState.isDirty}
                            >
                              Сохранить
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  ) : (
                    <span>
                      {referee?.dirReferee?.dirPerson.lastname || ''}
                      &nbsp;
                      {referee?.dirReferee?.dirPerson.firstname || ''}
                      &nbsp;
                      {referee?.dirReferee?.dirPerson.patronymic || ''}
                      &nbsp;
                    </span>
                  )}
                </TableCell>
                <TableCell> Спортивный судья.</TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.phones?.map((p: DirPersonPhone) => p.number).join(' , ') || ''}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee?.dirPerson.isRussia && referee?.dirReferee?.dirPerson.dirRegion.fullName) ||
                    referee?.dirReferee?.dirPerson.dirCountry.fullName ||
                    referee?.dirReferee?.dirPerson.dirForeignCity.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  <Link
                    href={
                      (referee?.category?.dirDocument &&
                        `${DOWNLOAD_LINK}${referee?.category?.dirDocument.file.path}`) ||
                      '#'
                    }
                  >
                    <Button
                      color={(referee?.category?.dirDocument && 'primary') || 'default'}
                      size="small"
                      variant="contained"
                    >
                      Удостоверение
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId('dd6483bb-7864-4251-b876-be2ffee0a856');
                                sportRefereesFormReset(defaultRefereeState);
                              }
                            }}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton className={classes.btn} color="secondary" onClick={() => deleteReferee(referee)}>
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Referees;
