import React, { FC, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input } from '../Inputs';
import { TypeSports } from '../../module';
import SportingEventsStatus from '../../module/SportingEventsStatus';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { Count } from '../Count';
import { makeStyles } from '@material-ui/core/styles';
import { useSportingEventsCountLazyQuery } from '../../api';

interface Filter {
  name_contains?: string;
  sports_some?: {
    dirSport?: {
      id: string;
    };
  };
  dirCalendar?: {
    fullName_contains: string;
  };
  status?: {
    id?: string;
  };
}

interface Value {
  status: {
    label: string;
    value: string;
  };
  sportType: {
    label: string;
    value: string;
  };
  name: string;
  dirCalendar: string;
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const SportingEvents: FC<{ onSubmit?: (T: any) => void }> = ({ onSubmit = () => {} }) => {
  const style = useStyle();

  const [filter, setFilter] = useState<any>({});

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      status: undefined,
      sportType: undefined,
      name: undefined,
      dirCalendar: undefined,
    },
  });

  const handleSearchBox = (value: Value) => {
    const filters: Filter = {};

    if (value.name) {
      filters.name_contains = value.name;
    }
    if (value.sportType) {
      filters.sports_some = {
        dirSport: {
          id: value.sportType.value,
        },
      };
    }
    if (value.dirCalendar) {
      filters.dirCalendar = {
        fullName_contains: value.dirCalendar,
      };
    }
    if (value.status) {
      filters.status = {
        id: value?.status.value,
      };
    }

    setFilter(filters);
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item container spacing={2} md={11} xs={12}>
            <Grid item xs={3}>
              <Input label="Название мероприятия" control={control} error={!!errors['name']} name="name" />
            </Grid>

            <Grid item xs={3}>
              <TypeSports label="Вид спорта" error={!!errors['sportType']} control={control} name="sportType" />
            </Grid>

            <Grid item xs={3}>
              <Input label="Календарь" control={control} error={!!errors['dirCalendar']} name="dirCalendar" />
            </Grid>
            <Grid item xs={3}>
              <SportingEventsStatus label="Статус" error={!!errors['status']} control={control} name="status" />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="button"
                onClick={() => {
                  onSubmit({
                    archive: null,
                  });
                  setFilter({
                    archive: null,
                  });
                  reset();
                }}
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
            </Grid>
          </Grid>
          <Grid item container justify="center" md={1} xs={12}>
            <Count query={useSportingEventsCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default SportingEvents;
