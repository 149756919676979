import React, { FC } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input } from '../Inputs';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Classifiers: FC<{ onSubmit?: (T: any) => void }> = ({ onSubmit = () => {} }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      name: undefined,
    },
  });

  const handleSearchBox = (value: any) => {
    const filters: any = {};
    for (let item in value) {
      if (item === 'name' && value[item] && value[item] !== '') {
        filters[item + '_contains'] = value[item] && value[item].length > 1 ? value[item].slice(1) : value[item];
      }
    }
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input label="Название" control={control} error={!!errors['name']} name="name" />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit({});
                reset();
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Classifiers;
