import React, { FC, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { DirForeignCity, useForeignCitiesQuery } from '../../api';
import { ForeignCitiesFilter } from '../../components/Filters';
import { Grid, Tooltip } from '@material-ui/core';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });
  const { push } = useHistory();
  const access = usePermission('directory', 'foreign_cities');
  const { data } = useForeignCitiesQuery({ variables: { skip: page, first: PER_PAGE, filter } });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'fullName',
      options: {
        display: false,
      },
    },
    {
      name: 'id',
      label: 'Название',
      options: {
        sort: false,
        customBodyRender: (value: Pick<DirForeignCity, 'id'>, meta) => {
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/foreign_city/${value}`}
                >
                  {meta.rowData[0]}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },

    {
      name: 'shortName',
      label: 'Краткое название',
      options: {
        sort: false,
      },
    },
    {
      name: 'dirCountry',
      label: 'Страна',
      options: {
        sort: false,
        customBodyRender: (value: any, meta) => {
          return value.label;
        },
      },
    },
  ];

  return (
    <MainTemplate pageName="Зарубежные города" url="">
      <ForeignCitiesFilter onSubmit={(filter: any) => setFilter(filter)} />
      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Зарубежные города</h2>
              <Grid container spacing={1}>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/foreign_city/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          data={(data && data.dirForeignCities) || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            rowsPerPage: PER_PAGE,
            count: data && data.count,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
