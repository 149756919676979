import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import Phones from './Phones';

const AdditionalInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <Phones id={id || ''} />
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalInfo;
