import React, { FC } from 'react';
import { Paper } from '@material-ui/core';

const DictionaryWrapper: FC<{ children: NonNullable<React.ReactNode> }> = ({ children }) => {
  return (
    <Paper square elevation={2}>
      {children}
    </Paper>
  );
};

export default DictionaryWrapper;
