import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import SportsHandballRoundedIcon from '@material-ui/icons/SportsHandballRounded';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import SportsGolfRoundedIcon from '@material-ui/icons/SportsGolfRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import MapIcon from '@material-ui/icons/Map';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import SportsKabaddiRoundedIcon from '@material-ui/icons/SportsKabaddiRounded';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import DescriptionIcon from '@material-ui/icons/Description';
import GavelIcon from '@material-ui/icons/Gavel';
import { MenuPoint } from '../../../../types/types';

const data: MenuPoint[] = [
  {
    icon: SportsGolfRoundedIcon,
    title: 'ЕВСК',
    id: 'type_sports',
    link: '/type_sports',
  },
  {
    icon: PersonAddRoundedIcon,
    title: 'Персоны',
    id: 'person',
    link: '/persons',
  },

  {
    icon: SportsHandballRoundedIcon,
    title: 'Спортсмены',
    id: 'athletes',
    link: '/athletes',
  },
  {
    icon: TimelineRoundedIcon,
    title: 'Тренеры',
    id: 'trainers',
    link: '/trainers',
  },
  {
    icon: GavelIcon,
    title: 'Судьи',
    id: 'referees',
    link: '/referees',
  },
  {
    icon: SportsKabaddiRoundedIcon,
    title: 'Сборные команды',
    id: 'teams',
    link: '/national_teams',
  },
  {
    icon: LocalActivityIcon,
    title: 'Спортивные мероприятия',
    id: 'sporting_events',
    link: '/sporting_events',
  },
  {
    icon: DescriptionIcon,
    title: 'Документы',
    id: 'documents',
    link: '/documents',
  },
  {
    icon: HomeWorkRoundedIcon,
    title: 'Спортивные организации',
    id: 'organizations',
    link: '/organizations',
  },
  {
    divider: true,
    icon: PeopleRoundedIcon,
    title: 'Администрирование пользователей',
    id: 'users',
    link: '/users',
  },
  {
    icon: TodayIcon,
    title: 'Календарь',
    id: 'сalendars',
    link: '/calendars',
  },
  {
    icon: AssignmentRoundedIcon,
    title: 'Классификаторы',
    id: 'classifiers',
    link: '/classifiers',
  },
  {
    icon: MapIcon,
    title: 'Регионы',
    id: 'region',
    link: '/regions',
  },
  {
    icon: RoomIcon,
    title: 'Федеральные округа',
    id: 'federaldistrict',
    link: '/federal_districts',
  },
  {
    icon: NotListedLocationIcon,
    title: 'Зарубежные города',
    id: 'foreigncities',
    link: '/foreign_cities',
  },
  {
    icon: NotListedLocationIcon,
    title: 'Календарь ЕКП',
    id: 'eventscalendar',
    link: '/events',
  },
  {
    icon: NotListedLocationIcon,
    title: 'ЕКП Минспорт',
    id: 'minsports',
    link: '/minsports',
  },
];

export default data;
