import React, { FC, useEffect, useState } from 'react';
import { useAthletesInTeamLazyQuery, Scalars } from '../api';
import Select from '../components/Inputs/SelectDeep';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import _ from 'lodash';

interface Organizations extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  eventId: Scalars['UUID'];
  deniedAthletes: Scalars['UUID'][];
}

const AthletesInTeam: FC<Organizations> = ({ label, error, control, name, rules, eventId, deniedAthletes }) => {
  const [fetch, { data, loading, refetch }] = useAthletesInTeamLazyQuery({
    variables: { search: '', eventId, deniedAthletes },
  });
  const [parsed, setParsed] = useState<any>([]);

  const refetchAthletes = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { search: value, eventId, deniedAthletes } });
    }
  };
  const useSearchAthletes = () => useDebouncedSearch((text) => refetchAthletes(text));

  const { setInputValue: setValue } = useSearchAthletes();

  useEffect(() => {
    if (!loading) {
      const prepare = _.flattenDeep(
        data?.dirNationalTeams?.map((team) =>
          team?.athleteGroups?.map((group) => group?.athletes?.map((athlete) => athlete?.dirAthlete)),
        ),
      );
      setParsed(prepare || []);
    }
  }, [data, loading]);

  return (
    <Select
      enablePortal
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading}
    />
  );
};

export default AthletesInTeam;
