import React, { FC, Fragment, useState, useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { DatePicker, Input, TimePicker } from '../../Inputs';
import Radios from '../../Inputs/Radio';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import OrganizationsSelect from '../../../module/Organizations';
import TrainingStages from '../../../module/TrainingStages';
import PersonsModule from '../../../module/Persons';
import {
  Scalars,
  useAthleteQuery,
  AddAthleteOrganizationMutationHookResult,
  useAddAthleteOrganizationMutation,
  UpdateAthleteOrganizationMutationHookResult,
  useUpdateAthleteOrganizationMutation,
  DeleteAthleteOrganizationMutationHookResult,
  useDeleteAthleteOrganizationMutation,
} from '../../../api';

import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface formState {
  organization?: { label: string; value: any };
  trainingStage?: { label: any; value: any };
  trainer?: { label: string; value: any; personRoleId: Scalars['UUID'] };
  trainingStart?: Date;
  exclusionDate?: Date;
  isSelfTraining?: string;
  orgId?: Scalars['UUID'];
}

const Organizations: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const defaultState: formState = {
    organization: undefined,
    trainingStage: undefined,
    trainingStart: undefined,
    exclusionDate: undefined,
    trainer: undefined,
    isSelfTraining: undefined,
  };

  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState('');
  const [orgId, setOrgId] = useState('');

  const { data, loading, refetch } = useAthleteQuery({
    skip: id === '' || id === 'new',
    variables: { filter: { id } },
  });

  const sports = data?.dirAthlete?.sports;

  const {
    handleSubmit: addFormHandleSubmit,
    control: addFormControl,
    errors: addFormErrors,
    watch: addFormWatch,
    reset: addFormReset,
    formState: addFormState,
  } = useForm({
    defaultValues: defaultState,
  });

  const {
    handleSubmit: editFormHandleSubmit,
    control: editFormControl,
    errors: editFormErrors,
    reset: editFormReset,
    watch: editFormWatch,
    formState: editFormState,
  } = useForm({
    defaultValues: defaultState,
  });

  const [add]: AddAthleteOrganizationMutationHookResult = useAddAthleteOrganizationMutation();
  const [update]: UpdateAthleteOrganizationMutationHookResult = useUpdateAthleteOrganizationMutation();
  const [deleteOrganization]: DeleteAthleteOrganizationMutationHookResult = useDeleteAthleteOrganizationMutation();

  let watchIsSelfTraining = addFormWatch('isSelfTraining');
  let watchIsEditSelfTraining = editFormWatch('isSelfTraining');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const onAdd = async (values: formState, idSport: Scalars['UUID']) => {
    const { organization, trainingStage, trainingStart, trainer, isSelfTraining } = values;

    try {
      await add({
        variables: {
          idSport,
          idDirOrganization: organization?.value,
          isSelfTraining: isSelfTraining === 'true' ? true : false,
          idDirTrainer: trainer ? { connect: { id: trainer?.personRoleId } } : null,
          clsTrainingStage: trainingStage?.value,
          trainingStart,
        },
      });
      handleSnackBar('success', 'организация успешно добавлено');
      setIsAdding(false);
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onEdit = async (values: formState) => {
    const { organization, trainingStage, trainingStart, trainer, isSelfTraining, exclusionDate } = values;
    try {
      await update({
        variables: {
          id: orgId,
          idDirOrganization: organization?.value,
          isSelfTraining: isSelfTraining === 'true' ? true : false,
          idDirTrainer: trainer ? { connect: { id: trainer?.personRoleId } } : { disconnect: true },
          clsTrainingStage: trainingStage?.value,
          exclusionDate,
          trainingStart,
        },
      });
      handleSnackBar('success', 'организация успешно обновлено');
      setOrgId('');
      setIsEditing('');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onDelete = async (values: formState) => {
    const { exclusionDate } = values;
    try {
      await deleteOrganization({
        variables: {
          id: orgId,
          exclusionDate: exclusionDate,
        },
      });
      handleSnackBar('success', 'организация успешно обновлено');
      setIsEditing('');
      setOrgId('');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%' }}>
      {sports?.map((sport) => (
        <Accordion key={sport.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${sport.dirSport?.label}-content`}
            id={`${sport.dirSport?.label}-header`}
          >
            <Typography className={classes.header} color="primary">
              {sport.dirSport.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {isAdding && (
              <form onSubmit={addFormHandleSubmit((values) => onAdd(values, sport.id))} style={{ width: '100%' }}>
                <Grid container style={{ marginBottom: 15 }} spacing={2}>
                  <Grid item md={3} xs={12}>
                    <OrganizationsSelect
                      filter={{ dirSports_some: { id: sport.dirSport.value } }}
                      label="Организация"
                      control={addFormControl}
                      error={!!addFormErrors['organization']}
                      name="organization"
                      rules={{ required: true }}
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <TrainingStages
                      label="Этап подготовки"
                      control={addFormControl}
                      error={!!addFormErrors['trainingStage']}
                      name="trainingStage"
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item md={2} xs={3}>
                    <Radios
                      label="Самоподготовка"
                      data={[
                        {
                          value: 'true',
                          label: 'Да',
                        },
                        {
                          value: 'false',
                          label: 'Нет',
                        },
                      ]}
                      control={addFormControl}
                      error={!!addFormErrors['isSelfTraining']}
                      name="isSelfTraining"
                      rules={{ required: true }}
                    />
                  </Grid>

                  {watchIsSelfTraining === 'false' && (
                    <Grid item md={3} xs={12}>
                      <PersonsModule
                        label="Тренер"
                        profile="trainerProfile"
                        filter={{
                          trainerProfile: {
                            id_not_in: sport?.organizations?.map((o) => o?.dirTrainer?.value).filter((n) => n),
                            sports_some: { dirSport: { id: sport.dirSport.value } },
                          },
                        }}
                        control={addFormControl}
                        error={!!addFormErrors['trainer']}
                        name="trainer"
                        rules={{ required: true }}
                      />
                    </Grid>
                  )}
                  <Grid item md={3} xs={12}>
                    <DatePicker
                      fullWidth
                      name="trainingStart"
                      label="Дата начала занятий"
                      control={addFormControl}
                      error={!!addFormErrors['trainingStart']}
                      rules={{ required: true }}
                    />
                  </Grid>

                  <Grid item container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<ClearIcon />}
                        type="button"
                        onClick={() => {
                          setIsAdding(false);
                          addFormReset({});
                        }}
                      >
                        Отменить
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<SaveIcon />}
                        type="submit"
                        disabled={!addFormState.isDirty}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
            {isEditing && (
              <form
                onSubmit={editFormHandleSubmit((values) => (isEditing === 'fire' ? onDelete(values) : onEdit(values)))}
                style={{ width: '100%' }}
              >
                <Grid container style={{ marginBottom: 15 }} spacing={2}>
                  {isEditing !== 'fire' && (
                    <Fragment>
                      <Grid item md={3} xs={12}>
                        <OrganizationsSelect
                          filter={{ dirSports_some: { id: sport.dirSport.value } }}
                          label="Организация"
                          control={editFormControl}
                          error={!!editFormErrors['organization']}
                          name="organization"
                          disabled
                          rules={{ required: true }}
                        />
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <TrainingStages
                          label="Этап подготовки"
                          control={editFormControl}
                          error={!!editFormErrors['trainingStage']}
                          name="trainingStage"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item md={2} xs={3}>
                        <Radios
                          label="Самоподготовка"
                          data={[
                            {
                              value: 'true',
                              label: 'Да',
                            },
                            {
                              value: 'false',
                              label: 'Нет',
                            },
                          ]}
                          control={editFormControl}
                          error={!!editFormErrors['isSelfTraining']}
                          name="isSelfTraining"
                          rules={{ required: true }}
                        />
                      </Grid>

                      {watchIsEditSelfTraining === 'false' && (
                        <Grid item md={3} xs={12}>
                          <PersonsModule
                            label="Тренер"
                            profile="trainerProfile"
                            filter={{
                              trainerProfile: {
                                id_not_in: sport?.organizations?.map((o) => o?.dirTrainer?.value).filter((n) => n),
                                sports_some: { dirSport: { id: sport.dirSport.value } },
                              },
                            }}
                            control={editFormControl}
                            error={!!editFormErrors['trainer']}
                            name="trainer"
                            rules={{ required: true }}
                          />
                        </Grid>
                      )}
                      <Grid item md={3} xs={12}>
                        <DatePicker
                          fullWidth
                          name="trainingStart"
                          label="Дата начала занятий"
                          control={editFormControl}
                          error={!!editFormErrors['trainingStart']}
                          rules={{ required: true }}
                        />
                      </Grid>
                    </Fragment>
                  )}

                  {isEditing === 'transfer' ||
                    (isEditing === 'fire' && (
                      <Grid item md={3} xs={12}>
                        <DatePicker
                          fullWidth
                          name="exclusionDate"
                          label="Дата отчисления"
                          control={editFormControl}
                          error={!!editFormErrors['exclusionDate']}
                          rules={{ required: true }}
                        />
                      </Grid>
                    ))}
                  <Grid item container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<ClearIcon />}
                        type="button"
                        onClick={() => {
                          setIsEditing('');
                          setOrgId('');
                          editFormReset({});
                        }}
                      >
                        Отменить
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<SaveIcon />}
                        type="submit"
                        disabled={!editFormState.isDirty}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
            <TableContainer component={Paper}>
              <Table aria-label={`${sport.dirSport?.label} table`}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      {!isAdding && (
                        <Tooltip title="добавить" placement="top-start">
                          <IconButton className={classes.btn} color="primary" onClick={() => setIsAdding(true)}>
                            <AddBoxIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableHead}>Этап подготовки</TableCell>
                    <TableCell className={classes.tableHead}>Тренер</TableCell>
                    <TableCell className={classes.tableHead}>Дата начала</TableCell>
                    <TableCell className={classes.tableHead}>Дата отчисления</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sport.organizations &&
                    sport.organizations.length > 0 &&
                    _.map(_.values(_.groupBy(sport.organizations, (o) => o.dirOrganization.label)), (v) =>
                      v.map((org, index) => (
                        <TableRow key={org.dirOrganization && org.dirOrganization.value + index}>
                          {index === 0 ? (
                            <TableCell component="th" scope="row">
                              <Link
                                style={{ fontWeight: 500, cursor: 'pointer' }}
                                target="_blank"
                                href={`/organization/${org.dirOrganization.value}`}
                              >
                                {org.dirOrganization.label}
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell component="th" scope="row"></TableCell>
                          )}
                          <TableCell>{org.clsTrainingStage && org.clsTrainingStage.label}</TableCell>
                          <TableCell>
                            {org.dirTrainer && (
                              <Link
                                style={{ fontWeight: 500, cursor: 'pointer' }}
                                target="_blank"
                                href={`/trainer/${org.dirTrainer.value}`}
                              >
                                {org.dirTrainer.dirPerson &&
                                  `${org.dirTrainer.dirPerson.lastname} ${org.dirTrainer.dirPerson.firstname} ${
                                    org.dirTrainer.dirPerson.patronymic || ''
                                  } `}
                                {org.dirTrainer &&
                                  org.dirTrainer.dirPerson &&
                                  org.dirTrainer.dirPerson.birthday &&
                                  moment(org.dirTrainer.dirPerson.birthday).format('DD.MM.YYYY')}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell>{org.trainingStart && moment(org.trainingStart).format('DD.MM.YYYY')}</TableCell>
                          <TableCell>{org.exclusionDate && moment(org.exclusionDate).format('DD.MM.YYYY')}</TableCell>

                          {
                            <TableCell>
                              <div className={classes.row}>
                                {!org.exclusionDate && !isEditing && (
                                  <Fragment>
                                    <Button
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                      style={{ marginRight: 10 }}
                                      onClick={() => {
                                        editFormReset({
                                          trainer: {
                                            value: org?.dirTrainer?.dirPerson.id,
                                            personRoleId: org?.dirTrainer?.value,
                                            label: `${org?.dirTrainer?.dirPerson.lastname} ${
                                              org?.dirTrainer?.dirPerson.firstname
                                            } ${
                                              org?.dirTrainer?.dirPerson.patronymic
                                                ? org?.dirTrainer?.dirPerson.patronymic
                                                : ''
                                            } ${moment(org?.dirTrainer?.dirPerson.birthday).format('DD.MM.YYYY')}`,
                                          },
                                          isSelfTraining:
                                            (org.isSelfTraining === true && 'true') ||
                                            (org.isSelfTraining === false && 'false') ||
                                            '',
                                          trainingStage: {
                                            ...org?.clsTrainingStage,
                                          },
                                          organization: {
                                            ...org?.dirOrganization,
                                          },
                                          trainingStart: org?.trainingStart,
                                          exclusionDate: org?.exclusionDate,
                                        });
                                        setOrgId(org.id);
                                        setIsEditing('transfer');
                                        setIsAdding(false);
                                      }}
                                    >
                                      Перевод
                                    </Button>
                                    <Tooltip title="удалить" placement="top-start">
                                      <Button
                                        className={classes.btn}
                                        color="secondary"
                                        variant="contained"
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                          setOrgId(org.id);
                                          setIsEditing('fire');
                                        }}
                                      >
                                        <ClearIcon className={classes.icon} />
                                      </Button>
                                    </Tooltip>
                                  </Fragment>
                                )}

                                {!isEditing && (
                                  <Tooltip title="изменить" placement="top-start">
                                    <Button
                                      className={classes.btn}
                                      color="primary"
                                      variant="contained"
                                      onClick={() => {
                                        editFormReset({
                                          trainer: {
                                            value: org?.dirTrainer?.dirPerson.id,
                                            personRoleId: org?.dirTrainer?.value,
                                            label: `${org?.dirTrainer?.dirPerson.lastname} ${
                                              org?.dirTrainer?.dirPerson.firstname
                                            } ${
                                              org?.dirTrainer?.dirPerson.patronymic
                                                ? org?.dirTrainer?.dirPerson.patronymic
                                                : ''
                                            } ${moment(org?.dirTrainer?.dirPerson.birthday).format('DD.MM.YYYY')}`,
                                          },
                                          isSelfTraining:
                                            (org.isSelfTraining === true && 'true') ||
                                            (org.isSelfTraining === false && 'false') ||
                                            '',
                                          trainingStage: {
                                            ...org?.clsTrainingStage,
                                          },
                                          organization: {
                                            ...org?.dirOrganization,
                                          },
                                          trainingStart: org?.trainingStart,
                                          exclusionDate: org?.exclusionDate,
                                        });
                                        setOrgId(org.id);
                                        setIsEditing('edit');
                                        setIsAdding(false);
                                      }}
                                    >
                                      <EditIcon className={classes.icon} />
                                    </Button>
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                          }
                        </TableRow>
                      )),
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Organizations;
