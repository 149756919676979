import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { CreateTrainerMutationHookResult, useCreateTrainerMutation, useTrainerByIdLazyQuery } from '../../api';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { blue } from '@material-ui/core/colors';
import AdditionalInfo from '../../components/widgets/Persons/AdditionalInfo';
import MainInfo from '../../components/widgets/Persons/MainInfo';
import moment from 'moment';
import _get from 'lodash/get';
import Duplicates from '../../components/widgets/Persons/Duplicates';
import TypeSports from '../../components/widgets/Trainers/TypeSports';
import Organizations from '../../components/widgets/Trainers/Organizations';
import { useHistory } from 'react-router';

type AccordionTypes = 'MainInfo' | 'Organizations' | 'TypeSports' | 'AdditionalInfo' | 'Duplicates' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const { push } = useHistory();

  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');
  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const [fetch, { data, loading }] = useTrainerByIdLazyQuery({ variables: { id } });
  const [create]: CreateTrainerMutationHookResult = useCreateTrainerMutation();
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  useEffect(() => {
    if (id !== 'new' && id !== '') {
      fetch({ variables: { id } });
    }
  }, [id]);

  return (
    <MainTemplate pageName="" url="">
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить нового тренера</h2>
        ) : (
          <h2>
            Тренер: {data?.dirTrainer?.dirPerson?.lastname} {data?.dirTrainer?.dirPerson?.firstname}{' '}
            {_get(data?.dirTrainer?.dirPerson, 'patronymic', '')}
            {moment(data?.dirTrainer?.dirPerson?.birthday).format('DD.MM.YYYY')}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MainInfo
              id={data?.dirTrainer?.dirPerson?.id || 'new'}
              onUpdate={async (idPerson) => {
                if (id === 'new') {
                  const trainer = await create({ variables: { idPerson } });
                  push(`/trainer/${trainer?.data?.createDirTrainer?.id}`);
                }
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'AdditionalInfo'}
          onChange={handleChangeAccordion('AdditionalInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дополнительная информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data?.dirTrainer?.dirPerson?.id && <AdditionalInfo id={data?.dirTrainer?.dirPerson.id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'TypeSports'}
          onChange={handleChangeAccordion('TypeSports')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Виды спорта</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirTrainer?.dirPerson?.id && <TypeSports id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Organizations'}
          onChange={handleChangeAccordion('Organizations')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Организации</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirTrainer?.dirPerson?.id && <Organizations id={id} />}</AccordionDetails>
        </Accordion>

        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Duplicates'}
          onChange={handleChangeAccordion('Duplicates')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дубликаты</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirTrainer?.dirPerson?.id && <Duplicates id={id} />}</AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
