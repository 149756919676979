import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: any;
  DateTime: any;
  Json: any;
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  users: Array<User>;
  userRole?: Maybe<UserRole>;
  userRoles: Array<UserRole>;
  regularUser?: Maybe<RegularUser>;
  regularUsers: Array<RegularUser>;
  systemUser?: Maybe<SystemUser>;
  systemUsers: Array<SystemUser>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  regionalMinsportProfiles: Array<RegionalMinsportProfile>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  regionalSchoolProfiles: Array<RegionalSchoolProfile>;
  fieldHistory?: Maybe<FieldHistory>;
  fieldHistories: Array<FieldHistory>;
  archive?: Maybe<Archive>;
  archives: Array<Archive>;
  classifier?: Maybe<Classifier>;
  classifiers: Array<Classifier>;
  classifierValue?: Maybe<ClassifierValue>;
  classifierValues: Array<ClassifierValue>;
  dirRegion?: Maybe<DirRegion>;
  dirRegions: Array<DirRegion>;
  dirFederalDistrict?: Maybe<DirFederalDistrict>;
  dirFederalDistricts: Array<DirFederalDistrict>;
  dirForeignCity?: Maybe<DirForeignCity>;
  dirForeignCities: Array<DirForeignCity>;
  dirCountry?: Maybe<DirCountry>;
  dirCountries: Array<DirCountry>;
  dirOrganization?: Maybe<DirOrganization>;
  dirOrganizations: Array<DirOrganization>;
  dirOrganizationSport?: Maybe<DirOrganizationSport>;
  dirOrganizationSports: Array<DirOrganizationSport>;
  dirOrganizationAddress?: Maybe<DirOrganizationAddress>;
  dirOrganizationAddresses: Array<DirOrganizationAddress>;
  dirOrganizationPhone?: Maybe<DirOrganizationPhone>;
  dirOrganizationPhones: Array<DirOrganizationPhone>;
  dirOrganizationEmail?: Maybe<DirOrganizationEmail>;
  dirOrganizationEmails: Array<DirOrganizationEmail>;
  dirPerson?: Maybe<DirPerson>;
  dirPersons: Array<DirPerson>;
  dirPersonPhone?: Maybe<DirPersonPhone>;
  dirPersonPhones: Array<DirPersonPhone>;
  dirPersonConfirmStatus?: Maybe<DirPersonConfirmStatus>;
  dirPersonConfirmStatuses: Array<DirPersonConfirmStatus>;
  dirPersonAdditionalRegion?: Maybe<DirPersonAdditionalRegion>;
  dirPersonAdditionalRegions: Array<DirPersonAdditionalRegion>;
  dirPersonMergeTask?: Maybe<DirPersonMergeTask>;
  dirPersonMergeTasks: Array<DirPersonMergeTask>;
  dirPersonAddress?: Maybe<DirPersonAddress>;
  dirPersonAddresses: Array<DirPersonAddress>;
  dirSport?: Maybe<DirSport>;
  dirSports: Array<DirSport>;
  dirSportDiscipline?: Maybe<DirSportDiscipline>;
  dirSportDisciplines: Array<DirSportDiscipline>;
  dirSportDisciplineGroup?: Maybe<DirSportDisciplineGroup>;
  dirSportDisciplineGroups: Array<DirSportDisciplineGroup>;
  dirSportAgeGroup?: Maybe<DirSportAgeGroup>;
  dirSportAgeGroups: Array<DirSportAgeGroup>;
  dirSportTrainingStage?: Maybe<DirSportTrainingStage>;
  dirSportTrainingStages: Array<DirSportTrainingStage>;
  dirSportBaseRegion?: Maybe<DirSportBaseRegion>;
  dirSportBaseRegions: Array<DirSportBaseRegion>;
  dirAthlete?: Maybe<DirAthlete>;
  dirAthletes: Array<DirAthlete>;
  dirAthleteSport?: Maybe<DirAthleteSport>;
  dirAthleteSports: Array<DirAthleteSport>;
  dirAthleteSportOrganization?: Maybe<DirAthleteSportOrganization>;
  dirAthleteSportOrganizations: Array<DirAthleteSportOrganization>;
  dirAthleteSportRankAndAward?: Maybe<DirAthleteSportRankAndAward>;
  dirAthleteSportRankAndAwards: Array<DirAthleteSportRankAndAward>;
  dirAthleteMedCert?: Maybe<DirAthleteMedCert>;
  dirAthleteMedCerts: Array<DirAthleteMedCert>;
  dirAthleteMedCertSport?: Maybe<DirAthleteMedCertSport>;
  dirAthleteMedCertSports: Array<DirAthleteMedCertSport>;
  dirAthleteCompetitionResult?: Maybe<DirAthleteCompetitionResult>;
  dirAthleteCompetitionResults: Array<DirAthleteCompetitionResult>;
  dirTrainer?: Maybe<DirTrainer>;
  dirTrainers: Array<DirTrainer>;
  dirTrainerSport?: Maybe<DirTrainerSport>;
  dirTrainerSports: Array<DirTrainerSport>;
  dirTrainerSportOrganization?: Maybe<DirTrainerSportOrganization>;
  dirTrainerSportOrganizations: Array<DirTrainerSportOrganization>;
  dirTrainerSportCategory?: Maybe<DirTrainerSportCategory>;
  dirTrainerSportCategories: Array<DirTrainerSportCategory>;
  dirReferee?: Maybe<DirReferee>;
  dirReferees: Array<DirReferee>;
  dirRefereeSport?: Maybe<DirRefereeSport>;
  dirRefereeSports: Array<DirRefereeSport>;
  dirRefereeSportCategory?: Maybe<DirRefereeSportCategory>;
  dirRefereeSportCategories: Array<DirRefereeSportCategory>;
  dirSportingEvent?: Maybe<DirSportingEvent>;
  dirSportingEvents: Array<DirSportingEvent>;
  dirSportingEventSport?: Maybe<DirSportingEventSport>;
  dirSportingEventSports: Array<DirSportingEventSport>;
  dirSportingEventAgeGroup?: Maybe<DirSportingEventAgeGroup>;
  dirSportingEventAgeGroups: Array<DirSportingEventAgeGroup>;
  dirSportingEventStatus?: Maybe<DirSportingEventStatus>;
  dirSportingEventStatuses: Array<DirSportingEventStatus>;
  dirSportingEventStatusHistory?: Maybe<DirSportingEventStatusHistory>;
  dirSportingEventStatusHistories: Array<DirSportingEventStatusHistory>;
  dirSportingEventProgramType?: Maybe<DirSportingEventProgramType>;
  dirSportingEventProgramTypes: Array<DirSportingEventProgramType>;
  dirSportingEventReferee?: Maybe<DirSportingEventReferee>;
  dirSportingEventReferees: Array<DirSportingEventReferee>;
  dirSportingEventCompetitionCalendar?: Maybe<DirSportingEventCompetitionCalendar>;
  dirSportingEventCompetitionCalendars: Array<DirSportingEventCompetitionCalendar>;
  dirNationalTeam?: Maybe<DirNationalTeam>;
  dirNationalTeams: Array<DirNationalTeam>;
  dirNationalTeamStatus?: Maybe<DirNationalTeamStatus>;
  dirNationalTeamStatuses: Array<DirNationalTeamStatus>;
  dirNationalTeamStatusHistory?: Maybe<DirNationalTeamStatusHistory>;
  dirNationalTeamStatusHistories: Array<DirNationalTeamStatusHistory>;
  dirNationalTeamTrainer?: Maybe<DirNationalTeamTrainer>;
  dirNationalTeamTrainers: Array<DirNationalTeamTrainer>;
  dirNationalTeamAthleteGroup?: Maybe<DirNationalTeamAthleteGroup>;
  dirNationalTeamAthleteGroups: Array<DirNationalTeamAthleteGroup>;
  dirNationalTeamAthlete?: Maybe<DirNationalTeamAthlete>;
  dirNationalTeamAthletes: Array<DirNationalTeamAthlete>;
  dirNationalTeamMember?: Maybe<DirNationalTeamMember>;
  dirNationalTeamMembers: Array<DirNationalTeamMember>;
  dirCalendar?: Maybe<DirCalendar>;
  dirCalendars: Array<DirCalendar>;
  dirDocument?: Maybe<DirDocument>;
  dirDocuments: Array<DirDocument>;
  addressType?: Maybe<AddressType>;
  addressTypes: Array<AddressType>;
  taskStatus?: Maybe<TaskStatus>;
  taskStatuses: Array<TaskStatus>;
  file?: Maybe<File>;
  files: Array<File>;
  usersCount: Scalars['Int'];
  userRolesCount: Scalars['Int'];
  regularUsersCount: Scalars['Int'];
  systemUsersCount: Scalars['Int'];
  regionalMinsportProfilesCount: Scalars['Int'];
  regionalSchoolProfilesCount: Scalars['Int'];
  fieldHistoriesCount: Scalars['Int'];
  archivesCount: Scalars['Int'];
  classifiersCount: Scalars['Int'];
  classifierValuesCount: Scalars['Int'];
  dirRegionsCount: Scalars['Int'];
  dirFederalDistrictsCount: Scalars['Int'];
  dirForeignCitiesCount: Scalars['Int'];
  dirCountriesCount: Scalars['Int'];
  dirOrganizationsCount: Scalars['Int'];
  dirOrganizationSportsCount: Scalars['Int'];
  dirOrganizationAddressesCount: Scalars['Int'];
  dirOrganizationPhonesCount: Scalars['Int'];
  dirOrganizationEmailsCount: Scalars['Int'];
  dirPersonsCount: Scalars['Int'];
  dirPersonPhonesCount: Scalars['Int'];
  dirPersonConfirmStatusesCount: Scalars['Int'];
  dirPersonAdditionalRegionsCount: Scalars['Int'];
  dirPersonMergeTasksCount: Scalars['Int'];
  dirPersonAddressesCount: Scalars['Int'];
  dirSportsCount: Scalars['Int'];
  dirSportDisciplinesCount: Scalars['Int'];
  dirSportDisciplineGroupsCount: Scalars['Int'];
  dirSportAgeGroupsCount: Scalars['Int'];
  dirSportTrainingStagesCount: Scalars['Int'];
  dirSportBaseRegionsCount: Scalars['Int'];
  dirAthletesCount: Scalars['Int'];
  dirAthleteSportsCount: Scalars['Int'];
  dirAthleteSportOrganizationsCount: Scalars['Int'];
  dirAthleteSportRankAndAwardsCount: Scalars['Int'];
  dirAthleteMedCertsCount: Scalars['Int'];
  dirAthleteMedCertSportsCount: Scalars['Int'];
  dirAthleteCompetitionResultsCount: Scalars['Int'];
  dirTrainersCount: Scalars['Int'];
  dirTrainerSportsCount: Scalars['Int'];
  dirTrainerSportOrganizationsCount: Scalars['Int'];
  dirTrainerSportCategoriesCount: Scalars['Int'];
  dirRefereesCount: Scalars['Int'];
  dirRefereeSportsCount: Scalars['Int'];
  dirRefereeSportCategoriesCount: Scalars['Int'];
  dirSportingEventsCount: Scalars['Int'];
  dirSportingEventSportsCount: Scalars['Int'];
  dirSportingEventAgeGroupsCount: Scalars['Int'];
  dirSportingEventStatusesCount: Scalars['Int'];
  dirSportingEventStatusHistoriesCount: Scalars['Int'];
  dirSportingEventProgramTypesCount: Scalars['Int'];
  dirSportingEventRefereesCount: Scalars['Int'];
  dirSportingEventCompetitionCalendarsCount: Scalars['Int'];
  dirNationalTeamsCount: Scalars['Int'];
  dirNationalTeamStatusesCount: Scalars['Int'];
  dirNationalTeamStatusHistoriesCount: Scalars['Int'];
  dirNationalTeamTrainersCount: Scalars['Int'];
  dirNationalTeamAthleteGroupsCount: Scalars['Int'];
  dirNationalTeamAthletesCount: Scalars['Int'];
  dirNationalTeamMembersCount: Scalars['Int'];
  dirCalendarsCount: Scalars['Int'];
  dirDocumentsCount: Scalars['Int'];
  addressTypesCount: Scalars['Int'];
  taskStatusesCount: Scalars['Int'];
  filesCount: Scalars['Int'];
  /** Полнотекстовый поиск */
  usersSimilar: Array<SimilarUser>;
  /** Полнотекстовый поиск */
  dirPersonsSimilar: Array<SimilarPerson>;
  /** Полнотекстовый поиск */
  dirOrganizationsSimilar: Array<SimilarOrganization>;
  /** Сервис получения информации об организации по ОГРН */
  suggestOrganizations: Array<OrganizationSearchResultType>;
  /** Сервис проверки Медицинской справки Спортсмена */
  publicAthleteMedCert?: Maybe<PublicAthleteMedCert>;
  /** Отчеты по Видам спорта */
  dirSportsReport: DirSportReport;
  /** Отчеты по Спортсменам */
  dirAthletesReport: DirAthleteReport;
  me: Me;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryUserRoleArgs = {
  where: UserRoleWhereUniqueInput;
};

export type QueryUserRolesArgs = {
  where?: Maybe<UserRoleWhereInput>;
  orderBy?: Maybe<UserRoleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryRegularUserArgs = {
  where: RegularUserWhereUniqueInput;
};

export type QueryRegularUsersArgs = {
  where?: Maybe<RegularUserWhereInput>;
  orderBy?: Maybe<RegularUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerySystemUserArgs = {
  where: SystemUserWhereUniqueInput;
};

export type QuerySystemUsersArgs = {
  where?: Maybe<SystemUserWhereInput>;
  orderBy?: Maybe<SystemUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryRegionalMinsportProfileArgs = {
  where: RegionalMinsportProfileWhereUniqueInput;
};

export type QueryRegionalMinsportProfilesArgs = {
  where?: Maybe<RegionalMinsportProfileWhereInput>;
  orderBy?: Maybe<RegionalMinsportProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryRegionalSchoolProfileArgs = {
  where: RegionalSchoolProfileWhereUniqueInput;
};

export type QueryRegionalSchoolProfilesArgs = {
  where?: Maybe<RegionalSchoolProfileWhereInput>;
  orderBy?: Maybe<RegionalSchoolProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryFieldHistoryArgs = {
  where: FieldHistoryWhereUniqueInput;
};

export type QueryFieldHistoriesArgs = {
  where?: Maybe<FieldHistoryWhereInput>;
  orderBy?: Maybe<FieldHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryArchiveArgs = {
  where: ArchiveWhereUniqueInput;
};

export type QueryArchivesArgs = {
  where?: Maybe<ArchiveWhereInput>;
  orderBy?: Maybe<ArchiveOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryClassifierArgs = {
  where: ClassifierWhereUniqueInput;
};

export type QueryClassifiersArgs = {
  where?: Maybe<ClassifierWhereInput>;
  orderBy?: Maybe<ClassifierOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryClassifierValueArgs = {
  where: ClassifierValueWhereUniqueInput;
};

export type QueryClassifierValuesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirRegionArgs = {
  where: DirRegionWhereUniqueInput;
};

export type QueryDirRegionsArgs = {
  where?: Maybe<DirRegionWhereInput>;
  orderBy?: Maybe<DirRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirFederalDistrictArgs = {
  where: DirFederalDistrictWhereUniqueInput;
};

export type QueryDirFederalDistrictsArgs = {
  where?: Maybe<DirFederalDistrictWhereInput>;
  orderBy?: Maybe<DirFederalDistrictOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirForeignCityArgs = {
  where: DirForeignCityWhereUniqueInput;
};

export type QueryDirForeignCitiesArgs = {
  where?: Maybe<DirForeignCityWhereInput>;
  orderBy?: Maybe<DirForeignCityOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirCountryArgs = {
  where: DirCountryWhereUniqueInput;
};

export type QueryDirCountriesArgs = {
  where?: Maybe<DirCountryWhereInput>;
  orderBy?: Maybe<DirCountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirOrganizationArgs = {
  where: DirOrganizationWhereUniqueInput;
};

export type QueryDirOrganizationsArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
  orderBy?: Maybe<DirOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirOrganizationSportArgs = {
  where: DirOrganizationSportWhereUniqueInput;
};

export type QueryDirOrganizationSportsArgs = {
  where?: Maybe<DirOrganizationSportWhereInput>;
  orderBy?: Maybe<DirOrganizationSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirOrganizationAddressArgs = {
  where: DirOrganizationAddressWhereUniqueInput;
};

export type QueryDirOrganizationAddressesArgs = {
  where?: Maybe<DirOrganizationAddressWhereInput>;
  orderBy?: Maybe<DirOrganizationAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirOrganizationPhoneArgs = {
  where: DirOrganizationPhoneWhereUniqueInput;
};

export type QueryDirOrganizationPhonesArgs = {
  where?: Maybe<DirOrganizationPhoneWhereInput>;
  orderBy?: Maybe<DirOrganizationPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirOrganizationEmailArgs = {
  where: DirOrganizationEmailWhereUniqueInput;
};

export type QueryDirOrganizationEmailsArgs = {
  where?: Maybe<DirOrganizationEmailWhereInput>;
  orderBy?: Maybe<DirOrganizationEmailOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirPersonArgs = {
  where: DirPersonWhereUniqueInput;
};

export type QueryDirPersonsArgs = {
  where?: Maybe<DirPersonWhereInput>;
  orderBy?: Maybe<DirPersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirPersonPhoneArgs = {
  where: DirPersonPhoneWhereUniqueInput;
};

export type QueryDirPersonPhonesArgs = {
  where?: Maybe<DirPersonPhoneWhereInput>;
  orderBy?: Maybe<DirPersonPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirPersonConfirmStatusArgs = {
  where: DirPersonConfirmStatusWhereUniqueInput;
};

export type QueryDirPersonConfirmStatusesArgs = {
  where?: Maybe<DirPersonConfirmStatusWhereInput>;
  orderBy?: Maybe<DirPersonConfirmStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirPersonAdditionalRegionArgs = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
};

export type QueryDirPersonAdditionalRegionsArgs = {
  where?: Maybe<DirPersonAdditionalRegionWhereInput>;
  orderBy?: Maybe<DirPersonAdditionalRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirPersonMergeTaskArgs = {
  where: DirPersonMergeTaskWhereUniqueInput;
};

export type QueryDirPersonMergeTasksArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
  orderBy?: Maybe<DirPersonMergeTaskOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirPersonAddressArgs = {
  where: DirPersonAddressWhereUniqueInput;
};

export type QueryDirPersonAddressesArgs = {
  where?: Maybe<DirPersonAddressWhereInput>;
  orderBy?: Maybe<DirPersonAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportArgs = {
  where: DirSportWhereUniqueInput;
};

export type QueryDirSportsArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportDisciplineArgs = {
  where: DirSportDisciplineWhereUniqueInput;
};

export type QueryDirSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportDisciplineGroupArgs = {
  where: DirSportDisciplineGroupWhereUniqueInput;
};

export type QueryDirSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportAgeGroupArgs = {
  where: DirSportAgeGroupWhereUniqueInput;
};

export type QueryDirSportAgeGroupsArgs = {
  where?: Maybe<DirSportAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportTrainingStageArgs = {
  where: DirSportTrainingStageWhereUniqueInput;
};

export type QueryDirSportTrainingStagesArgs = {
  where?: Maybe<DirSportTrainingStageWhereInput>;
  orderBy?: Maybe<DirSportTrainingStageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportBaseRegionArgs = {
  where: DirSportBaseRegionWhereUniqueInput;
};

export type QueryDirSportBaseRegionsArgs = {
  where?: Maybe<DirSportBaseRegionWhereInput>;
  orderBy?: Maybe<DirSportBaseRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirAthleteArgs = {
  where: DirAthleteWhereUniqueInput;
};

export type QueryDirAthletesArgs = {
  where?: Maybe<DirAthleteWhereInput>;
  orderBy?: Maybe<DirAthleteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirAthleteSportArgs = {
  where: DirAthleteSportWhereUniqueInput;
};

export type QueryDirAthleteSportsArgs = {
  where?: Maybe<DirAthleteSportWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirAthleteSportOrganizationArgs = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
};

export type QueryDirAthleteSportOrganizationsArgs = {
  where?: Maybe<DirAthleteSportOrganizationWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirAthleteSportRankAndAwardArgs = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
};

export type QueryDirAthleteSportRankAndAwardsArgs = {
  where?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  orderBy?: Maybe<DirAthleteSportRankAndAwardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirAthleteMedCertArgs = {
  where: DirAthleteMedCertWhereUniqueInput;
};

export type QueryDirAthleteMedCertsArgs = {
  where?: Maybe<DirAthleteMedCertWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirAthleteMedCertSportArgs = {
  where: DirAthleteMedCertSportWhereUniqueInput;
};

export type QueryDirAthleteMedCertSportsArgs = {
  where?: Maybe<DirAthleteMedCertSportWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirAthleteCompetitionResultArgs = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
};

export type QueryDirAthleteCompetitionResultsArgs = {
  where?: Maybe<DirAthleteCompetitionResultWhereInput>;
  orderBy?: Maybe<DirAthleteCompetitionResultOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirTrainerArgs = {
  where: DirTrainerWhereUniqueInput;
};

export type QueryDirTrainersArgs = {
  where?: Maybe<DirTrainerWhereInput>;
  orderBy?: Maybe<DirTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirTrainerSportArgs = {
  where: DirTrainerSportWhereUniqueInput;
};

export type QueryDirTrainerSportsArgs = {
  where?: Maybe<DirTrainerSportWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirTrainerSportOrganizationArgs = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
};

export type QueryDirTrainerSportOrganizationsArgs = {
  where?: Maybe<DirTrainerSportOrganizationWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirTrainerSportCategoryArgs = {
  where: DirTrainerSportCategoryWhereUniqueInput;
};

export type QueryDirTrainerSportCategoriesArgs = {
  where?: Maybe<DirTrainerSportCategoryWhereInput>;
  orderBy?: Maybe<DirTrainerSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirRefereeArgs = {
  where: DirRefereeWhereUniqueInput;
};

export type QueryDirRefereesArgs = {
  where?: Maybe<DirRefereeWhereInput>;
  orderBy?: Maybe<DirRefereeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirRefereeSportArgs = {
  where: DirRefereeSportWhereUniqueInput;
};

export type QueryDirRefereeSportsArgs = {
  where?: Maybe<DirRefereeSportWhereInput>;
  orderBy?: Maybe<DirRefereeSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirRefereeSportCategoryArgs = {
  where: DirRefereeSportCategoryWhereUniqueInput;
};

export type QueryDirRefereeSportCategoriesArgs = {
  where?: Maybe<DirRefereeSportCategoryWhereInput>;
  orderBy?: Maybe<DirRefereeSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventArgs = {
  where: DirSportingEventWhereUniqueInput;
};

export type QueryDirSportingEventsArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventSportArgs = {
  where: DirSportingEventSportWhereUniqueInput;
};

export type QueryDirSportingEventSportsArgs = {
  where?: Maybe<DirSportingEventSportWhereInput>;
  orderBy?: Maybe<DirSportingEventSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventAgeGroupArgs = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
};

export type QueryDirSportingEventAgeGroupsArgs = {
  where?: Maybe<DirSportingEventAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportingEventAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventStatusArgs = {
  where: DirSportingEventStatusWhereUniqueInput;
};

export type QueryDirSportingEventStatusesArgs = {
  where?: Maybe<DirSportingEventStatusWhereInput>;
  orderBy?: Maybe<DirSportingEventStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventStatusHistoryArgs = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
};

export type QueryDirSportingEventStatusHistoriesArgs = {
  where?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  orderBy?: Maybe<DirSportingEventStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventProgramTypeArgs = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
};

export type QueryDirSportingEventProgramTypesArgs = {
  where?: Maybe<DirSportingEventProgramTypeWhereInput>;
  orderBy?: Maybe<DirSportingEventProgramTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventRefereeArgs = {
  where: DirSportingEventRefereeWhereUniqueInput;
};

export type QueryDirSportingEventRefereesArgs = {
  where?: Maybe<DirSportingEventRefereeWhereInput>;
  orderBy?: Maybe<DirSportingEventRefereeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirSportingEventCompetitionCalendarArgs = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
};

export type QueryDirSportingEventCompetitionCalendarsArgs = {
  where?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  orderBy?: Maybe<DirSportingEventCompetitionCalendarOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirNationalTeamArgs = {
  where: DirNationalTeamWhereUniqueInput;
};

export type QueryDirNationalTeamsArgs = {
  where?: Maybe<DirNationalTeamWhereInput>;
  orderBy?: Maybe<DirNationalTeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirNationalTeamStatusArgs = {
  where: DirNationalTeamStatusWhereUniqueInput;
};

export type QueryDirNationalTeamStatusesArgs = {
  where?: Maybe<DirNationalTeamStatusWhereInput>;
  orderBy?: Maybe<DirNationalTeamStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirNationalTeamStatusHistoryArgs = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
};

export type QueryDirNationalTeamStatusHistoriesArgs = {
  where?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  orderBy?: Maybe<DirNationalTeamStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirNationalTeamTrainerArgs = {
  where: DirNationalTeamTrainerWhereUniqueInput;
};

export type QueryDirNationalTeamTrainersArgs = {
  where?: Maybe<DirNationalTeamTrainerWhereInput>;
  orderBy?: Maybe<DirNationalTeamTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirNationalTeamAthleteGroupArgs = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
};

export type QueryDirNationalTeamAthleteGroupsArgs = {
  where?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirNationalTeamAthleteArgs = {
  where: DirNationalTeamAthleteWhereUniqueInput;
};

export type QueryDirNationalTeamAthletesArgs = {
  where?: Maybe<DirNationalTeamAthleteWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirNationalTeamMemberArgs = {
  where: DirNationalTeamMemberWhereUniqueInput;
};

export type QueryDirNationalTeamMembersArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
  orderBy?: Maybe<DirNationalTeamMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};

export type QueryDirCalendarsArgs = {
  where?: Maybe<DirCalendarWhereInput>;
  orderBy?: Maybe<DirCalendarOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDirDocumentArgs = {
  where: DirDocumentWhereUniqueInput;
};

export type QueryDirDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAddressTypeArgs = {
  where: AddressTypeWhereUniqueInput;
};

export type QueryAddressTypesArgs = {
  where?: Maybe<AddressTypeWhereInput>;
  orderBy?: Maybe<AddressTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryTaskStatusArgs = {
  where: TaskStatusWhereUniqueInput;
};

export type QueryTaskStatusesArgs = {
  where?: Maybe<TaskStatusWhereInput>;
  orderBy?: Maybe<TaskStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryFileArgs = {
  where: FileWhereUniqueInput;
};

export type QueryFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryUsersCountArgs = {
  where?: Maybe<UserWhereInput>;
};

export type QueryUserRolesCountArgs = {
  where?: Maybe<UserRoleWhereInput>;
};

export type QueryRegularUsersCountArgs = {
  where?: Maybe<RegularUserWhereInput>;
};

export type QuerySystemUsersCountArgs = {
  where?: Maybe<SystemUserWhereInput>;
};

export type QueryRegionalMinsportProfilesCountArgs = {
  where?: Maybe<RegionalMinsportProfileWhereInput>;
};

export type QueryRegionalSchoolProfilesCountArgs = {
  where?: Maybe<RegionalSchoolProfileWhereInput>;
};

export type QueryFieldHistoriesCountArgs = {
  where?: Maybe<FieldHistoryWhereInput>;
};

export type QueryArchivesCountArgs = {
  where?: Maybe<ArchiveWhereInput>;
};

export type QueryClassifiersCountArgs = {
  where?: Maybe<ClassifierWhereInput>;
};

export type QueryClassifierValuesCountArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
};

export type QueryDirRegionsCountArgs = {
  where?: Maybe<DirRegionWhereInput>;
};

export type QueryDirFederalDistrictsCountArgs = {
  where?: Maybe<DirFederalDistrictWhereInput>;
};

export type QueryDirForeignCitiesCountArgs = {
  where?: Maybe<DirForeignCityWhereInput>;
};

export type QueryDirCountriesCountArgs = {
  where?: Maybe<DirCountryWhereInput>;
};

export type QueryDirOrganizationsCountArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
};

export type QueryDirOrganizationSportsCountArgs = {
  where?: Maybe<DirOrganizationSportWhereInput>;
};

export type QueryDirOrganizationAddressesCountArgs = {
  where?: Maybe<DirOrganizationAddressWhereInput>;
};

export type QueryDirOrganizationPhonesCountArgs = {
  where?: Maybe<DirOrganizationPhoneWhereInput>;
};

export type QueryDirOrganizationEmailsCountArgs = {
  where?: Maybe<DirOrganizationEmailWhereInput>;
};

export type QueryDirPersonsCountArgs = {
  where?: Maybe<DirPersonWhereInput>;
};

export type QueryDirPersonPhonesCountArgs = {
  where?: Maybe<DirPersonPhoneWhereInput>;
};

export type QueryDirPersonConfirmStatusesCountArgs = {
  where?: Maybe<DirPersonConfirmStatusWhereInput>;
};

export type QueryDirPersonAdditionalRegionsCountArgs = {
  where?: Maybe<DirPersonAdditionalRegionWhereInput>;
};

export type QueryDirPersonMergeTasksCountArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
};

export type QueryDirPersonAddressesCountArgs = {
  where?: Maybe<DirPersonAddressWhereInput>;
};

export type QueryDirSportsCountArgs = {
  where?: Maybe<DirSportWhereInput>;
};

export type QueryDirSportDisciplinesCountArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
};

export type QueryDirSportDisciplineGroupsCountArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
};

export type QueryDirSportAgeGroupsCountArgs = {
  where?: Maybe<DirSportAgeGroupWhereInput>;
};

export type QueryDirSportTrainingStagesCountArgs = {
  where?: Maybe<DirSportTrainingStageWhereInput>;
};

export type QueryDirSportBaseRegionsCountArgs = {
  where?: Maybe<DirSportBaseRegionWhereInput>;
};

export type QueryDirAthletesCountArgs = {
  where?: Maybe<DirAthleteWhereInput>;
};

export type QueryDirAthleteSportsCountArgs = {
  where?: Maybe<DirAthleteSportWhereInput>;
};

export type QueryDirAthleteSportOrganizationsCountArgs = {
  where?: Maybe<DirAthleteSportOrganizationWhereInput>;
};

export type QueryDirAthleteSportRankAndAwardsCountArgs = {
  where?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
};

export type QueryDirAthleteMedCertsCountArgs = {
  where?: Maybe<DirAthleteMedCertWhereInput>;
};

export type QueryDirAthleteMedCertSportsCountArgs = {
  where?: Maybe<DirAthleteMedCertSportWhereInput>;
};

export type QueryDirAthleteCompetitionResultsCountArgs = {
  where?: Maybe<DirAthleteCompetitionResultWhereInput>;
};

export type QueryDirTrainersCountArgs = {
  where?: Maybe<DirTrainerWhereInput>;
};

export type QueryDirTrainerSportsCountArgs = {
  where?: Maybe<DirTrainerSportWhereInput>;
};

export type QueryDirTrainerSportOrganizationsCountArgs = {
  where?: Maybe<DirTrainerSportOrganizationWhereInput>;
};

export type QueryDirTrainerSportCategoriesCountArgs = {
  where?: Maybe<DirTrainerSportCategoryWhereInput>;
};

export type QueryDirRefereesCountArgs = {
  where?: Maybe<DirRefereeWhereInput>;
};

export type QueryDirRefereeSportsCountArgs = {
  where?: Maybe<DirRefereeSportWhereInput>;
};

export type QueryDirRefereeSportCategoriesCountArgs = {
  where?: Maybe<DirRefereeSportCategoryWhereInput>;
};

export type QueryDirSportingEventsCountArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
};

export type QueryDirSportingEventSportsCountArgs = {
  where?: Maybe<DirSportingEventSportWhereInput>;
};

export type QueryDirSportingEventAgeGroupsCountArgs = {
  where?: Maybe<DirSportingEventAgeGroupWhereInput>;
};

export type QueryDirSportingEventStatusesCountArgs = {
  where?: Maybe<DirSportingEventStatusWhereInput>;
};

export type QueryDirSportingEventStatusHistoriesCountArgs = {
  where?: Maybe<DirSportingEventStatusHistoryWhereInput>;
};

export type QueryDirSportingEventProgramTypesCountArgs = {
  where?: Maybe<DirSportingEventProgramTypeWhereInput>;
};

export type QueryDirSportingEventRefereesCountArgs = {
  where?: Maybe<DirSportingEventRefereeWhereInput>;
};

export type QueryDirSportingEventCompetitionCalendarsCountArgs = {
  where?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
};

export type QueryDirNationalTeamsCountArgs = {
  where?: Maybe<DirNationalTeamWhereInput>;
};

export type QueryDirNationalTeamStatusesCountArgs = {
  where?: Maybe<DirNationalTeamStatusWhereInput>;
};

export type QueryDirNationalTeamStatusHistoriesCountArgs = {
  where?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
};

export type QueryDirNationalTeamTrainersCountArgs = {
  where?: Maybe<DirNationalTeamTrainerWhereInput>;
};

export type QueryDirNationalTeamAthleteGroupsCountArgs = {
  where?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
};

export type QueryDirNationalTeamAthletesCountArgs = {
  where?: Maybe<DirNationalTeamAthleteWhereInput>;
};

export type QueryDirNationalTeamMembersCountArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
};

export type QueryDirCalendarsCountArgs = {
  where?: Maybe<DirCalendarWhereInput>;
};

export type QueryDirDocumentsCountArgs = {
  where?: Maybe<DirDocumentWhereInput>;
};

export type QueryAddressTypesCountArgs = {
  where?: Maybe<AddressTypeWhereInput>;
};

export type QueryTaskStatusesCountArgs = {
  where?: Maybe<TaskStatusWhereInput>;
};

export type QueryFilesCountArgs = {
  where?: Maybe<FileWhereInput>;
};

export type QueryUsersSimilarArgs = {
  search: Scalars['String'];
  where?: Maybe<UserWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryDirPersonsSimilarArgs = {
  search: Scalars['String'];
  where?: Maybe<DirPersonWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryDirOrganizationsSimilarArgs = {
  search: Scalars['String'];
  where?: Maybe<DirOrganizationWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QuerySuggestOrganizationsArgs = {
  by: Scalars['String'];
};

export type QueryPublicAthleteMedCertArgs = {
  id: Scalars['UUID'];
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRole;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUser>;
  system?: Maybe<SystemUser>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPerson>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  /** Токены пользователя */
  tokens: Array<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type RegularUser = {
  __typename?: 'RegularUser';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganization>;
};

export type DirOrganization = {
  __typename?: 'DirOrganization';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganization>;
  children?: Maybe<Array<DirOrganization>>;
  dirRegion?: Maybe<DirRegion>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<Array<ClassifierValue>>;
  clsDepartmentalAffiliations?: Maybe<Array<ClassifierValue>>;
  isLegalEntity: Scalars['Boolean'];
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<Array<DirSport>>;
  sports?: Maybe<Array<DirOrganizationSport>>;
  addresses?: Maybe<Array<DirOrganizationAddress>>;
  phones?: Maybe<Array<DirOrganizationPhone>>;
  emails?: Maybe<Array<DirOrganizationEmail>>;
  websites: Array<Scalars['String']>;
};

export type DirOrganizationChildrenArgs = {
  where?: Maybe<DirOrganizationWhereInput>;
  orderBy?: Maybe<DirOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirOrganizationClsOrganizationCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirOrganizationClsDepartmentalAffiliationsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirOrganizationDirSportsArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirOrganizationSportsArgs = {
  where?: Maybe<DirOrganizationSportWhereInput>;
  orderBy?: Maybe<DirOrganizationSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirOrganizationAddressesArgs = {
  where?: Maybe<DirOrganizationAddressWhereInput>;
  orderBy?: Maybe<DirOrganizationAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirOrganizationPhonesArgs = {
  where?: Maybe<DirOrganizationPhoneWhereInput>;
  orderBy?: Maybe<DirOrganizationPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirOrganizationEmailsArgs = {
  where?: Maybe<DirOrganizationEmailWhereInput>;
  orderBy?: Maybe<DirOrganizationEmailOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Archive = {
  __typename?: 'Archive';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  reason: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type DirOrganizationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationWhereInput>;
  children_every?: Maybe<DirOrganizationWhereInput>;
  children_some?: Maybe<DirOrganizationWhereInput>;
  children_none?: Maybe<DirOrganizationWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  clsOrganizationCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsOrganizationCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsOrganizationCategories_none?: Maybe<ClassifierValueWhereInput>;
  clsDepartmentalAffiliations_every?: Maybe<ClassifierValueWhereInput>;
  clsDepartmentalAffiliations_some?: Maybe<ClassifierValueWhereInput>;
  clsDepartmentalAffiliations_none?: Maybe<ClassifierValueWhereInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  isLegalEntity_not?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  ogrn_not?: Maybe<Scalars['String']>;
  ogrn_in?: Maybe<Array<Scalars['String']>>;
  ogrn_not_in?: Maybe<Array<Scalars['String']>>;
  ogrn_lt?: Maybe<Scalars['String']>;
  ogrn_lte?: Maybe<Scalars['String']>;
  ogrn_gt?: Maybe<Scalars['String']>;
  ogrn_gte?: Maybe<Scalars['String']>;
  ogrn_contains?: Maybe<Scalars['String']>;
  ogrn_not_contains?: Maybe<Scalars['String']>;
  ogrn_starts_with?: Maybe<Scalars['String']>;
  ogrn_not_starts_with?: Maybe<Scalars['String']>;
  ogrn_ends_with?: Maybe<Scalars['String']>;
  ogrn_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  registrationDate_not?: Maybe<Scalars['DateTime']>;
  registrationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_lt?: Maybe<Scalars['DateTime']>;
  registrationDate_lte?: Maybe<Scalars['DateTime']>;
  registrationDate_gt?: Maybe<Scalars['DateTime']>;
  registrationDate_gte?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  inn_not?: Maybe<Scalars['String']>;
  inn_in?: Maybe<Array<Scalars['String']>>;
  inn_not_in?: Maybe<Array<Scalars['String']>>;
  inn_lt?: Maybe<Scalars['String']>;
  inn_lte?: Maybe<Scalars['String']>;
  inn_gt?: Maybe<Scalars['String']>;
  inn_gte?: Maybe<Scalars['String']>;
  inn_contains?: Maybe<Scalars['String']>;
  inn_not_contains?: Maybe<Scalars['String']>;
  inn_starts_with?: Maybe<Scalars['String']>;
  inn_not_starts_with?: Maybe<Scalars['String']>;
  inn_ends_with?: Maybe<Scalars['String']>;
  inn_not_ends_with?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  kpp_not?: Maybe<Scalars['String']>;
  kpp_in?: Maybe<Array<Scalars['String']>>;
  kpp_not_in?: Maybe<Array<Scalars['String']>>;
  kpp_lt?: Maybe<Scalars['String']>;
  kpp_lte?: Maybe<Scalars['String']>;
  kpp_gt?: Maybe<Scalars['String']>;
  kpp_gte?: Maybe<Scalars['String']>;
  kpp_contains?: Maybe<Scalars['String']>;
  kpp_not_contains?: Maybe<Scalars['String']>;
  kpp_starts_with?: Maybe<Scalars['String']>;
  kpp_not_starts_with?: Maybe<Scalars['String']>;
  kpp_ends_with?: Maybe<Scalars['String']>;
  kpp_not_ends_with?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  legalAddress_not?: Maybe<Scalars['String']>;
  legalAddress_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_not_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_lt?: Maybe<Scalars['String']>;
  legalAddress_lte?: Maybe<Scalars['String']>;
  legalAddress_gt?: Maybe<Scalars['String']>;
  legalAddress_gte?: Maybe<Scalars['String']>;
  legalAddress_contains?: Maybe<Scalars['String']>;
  legalAddress_not_contains?: Maybe<Scalars['String']>;
  legalAddress_starts_with?: Maybe<Scalars['String']>;
  legalAddress_not_starts_with?: Maybe<Scalars['String']>;
  legalAddress_ends_with?: Maybe<Scalars['String']>;
  legalAddress_not_ends_with?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  postcode_not?: Maybe<Scalars['String']>;
  postcode_in?: Maybe<Array<Scalars['String']>>;
  postcode_not_in?: Maybe<Array<Scalars['String']>>;
  postcode_lt?: Maybe<Scalars['String']>;
  postcode_lte?: Maybe<Scalars['String']>;
  postcode_gt?: Maybe<Scalars['String']>;
  postcode_gte?: Maybe<Scalars['String']>;
  postcode_contains?: Maybe<Scalars['String']>;
  postcode_not_contains?: Maybe<Scalars['String']>;
  postcode_starts_with?: Maybe<Scalars['String']>;
  postcode_not_starts_with?: Maybe<Scalars['String']>;
  postcode_ends_with?: Maybe<Scalars['String']>;
  postcode_not_ends_with?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headPosition_not?: Maybe<Scalars['String']>;
  headPosition_in?: Maybe<Array<Scalars['String']>>;
  headPosition_not_in?: Maybe<Array<Scalars['String']>>;
  headPosition_lt?: Maybe<Scalars['String']>;
  headPosition_lte?: Maybe<Scalars['String']>;
  headPosition_gt?: Maybe<Scalars['String']>;
  headPosition_gte?: Maybe<Scalars['String']>;
  headPosition_contains?: Maybe<Scalars['String']>;
  headPosition_not_contains?: Maybe<Scalars['String']>;
  headPosition_starts_with?: Maybe<Scalars['String']>;
  headPosition_not_starts_with?: Maybe<Scalars['String']>;
  headPosition_ends_with?: Maybe<Scalars['String']>;
  headPosition_not_ends_with?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  headFullName_not?: Maybe<Scalars['String']>;
  headFullName_in?: Maybe<Array<Scalars['String']>>;
  headFullName_not_in?: Maybe<Array<Scalars['String']>>;
  headFullName_lt?: Maybe<Scalars['String']>;
  headFullName_lte?: Maybe<Scalars['String']>;
  headFullName_gt?: Maybe<Scalars['String']>;
  headFullName_gte?: Maybe<Scalars['String']>;
  headFullName_contains?: Maybe<Scalars['String']>;
  headFullName_not_contains?: Maybe<Scalars['String']>;
  headFullName_starts_with?: Maybe<Scalars['String']>;
  headFullName_not_starts_with?: Maybe<Scalars['String']>;
  headFullName_ends_with?: Maybe<Scalars['String']>;
  headFullName_not_ends_with?: Maybe<Scalars['String']>;
  dirSports_every?: Maybe<DirSportWhereInput>;
  dirSports_some?: Maybe<DirSportWhereInput>;
  dirSports_none?: Maybe<DirSportWhereInput>;
  sports_every?: Maybe<DirOrganizationSportWhereInput>;
  sports_some?: Maybe<DirOrganizationSportWhereInput>;
  sports_none?: Maybe<DirOrganizationSportWhereInput>;
  addresses_every?: Maybe<DirOrganizationAddressWhereInput>;
  addresses_some?: Maybe<DirOrganizationAddressWhereInput>;
  addresses_none?: Maybe<DirOrganizationAddressWhereInput>;
  phones_every?: Maybe<DirOrganizationPhoneWhereInput>;
  phones_some?: Maybe<DirOrganizationPhoneWhereInput>;
  phones_none?: Maybe<DirOrganizationPhoneWhereInput>;
  emails_every?: Maybe<DirOrganizationEmailWhereInput>;
  emails_some?: Maybe<DirOrganizationEmailWhereInput>;
  emails_none?: Maybe<DirOrganizationEmailWhereInput>;
  AND?: Maybe<Array<DirOrganizationWhereInput>>;
  OR?: Maybe<Array<DirOrganizationWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationWhereInput>>;
};

export type UserWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_not?: Maybe<Scalars['String']>;
  password_in?: Maybe<Array<Scalars['String']>>;
  password_not_in?: Maybe<Array<Scalars['String']>>;
  password_lt?: Maybe<Scalars['String']>;
  password_lte?: Maybe<Scalars['String']>;
  password_gt?: Maybe<Scalars['String']>;
  password_gte?: Maybe<Scalars['String']>;
  password_contains?: Maybe<Scalars['String']>;
  password_not_contains?: Maybe<Scalars['String']>;
  password_starts_with?: Maybe<Scalars['String']>;
  password_not_starts_with?: Maybe<Scalars['String']>;
  password_ends_with?: Maybe<Scalars['String']>;
  password_not_ends_with?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleWhereInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserWhereInput>;
  system?: Maybe<SystemUserWhereInput>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Scalars['String']>>;
  position_not_in?: Maybe<Array<Scalars['String']>>;
  position_lt?: Maybe<Scalars['String']>;
  position_lte?: Maybe<Scalars['String']>;
  position_gt?: Maybe<Scalars['String']>;
  position_gte?: Maybe<Scalars['String']>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonWhereInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileWhereInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileWhereInput>;
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
};

export type UserRoleWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  canSelect?: Maybe<Scalars['Boolean']>;
  canSelect_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<UserRoleWhereInput>>;
  OR?: Maybe<Array<UserRoleWhereInput>>;
  NOT?: Maybe<Array<UserRoleWhereInput>>;
};

export type RegularUserWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  isMale_not?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Scalars['String']>>;
  phone_not_in?: Maybe<Array<Scalars['String']>>;
  phone_lt?: Maybe<Scalars['String']>;
  phone_lte?: Maybe<Scalars['String']>;
  phone_gt?: Maybe<Scalars['String']>;
  phone_gte?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Scalars['String']>>;
  position_not_in?: Maybe<Array<Scalars['String']>>;
  position_lt?: Maybe<Scalars['String']>;
  position_lte?: Maybe<Scalars['String']>;
  position_gt?: Maybe<Scalars['String']>;
  position_gte?: Maybe<Scalars['String']>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Scalars['String']>>;
  address_not_in?: Maybe<Array<Scalars['String']>>;
  address_lt?: Maybe<Scalars['String']>;
  address_lte?: Maybe<Scalars['String']>;
  address_gt?: Maybe<Scalars['String']>;
  address_gte?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  AND?: Maybe<Array<RegularUserWhereInput>>;
  OR?: Maybe<Array<RegularUserWhereInput>>;
  NOT?: Maybe<Array<RegularUserWhereInput>>;
};

export type SystemUserWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<SystemUserWhereInput>>;
  OR?: Maybe<Array<SystemUserWhereInput>>;
  NOT?: Maybe<Array<SystemUserWhereInput>>;
};

export type DirPersonWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  fieldHistory_every?: Maybe<FieldHistoryWhereInput>;
  fieldHistory_some?: Maybe<FieldHistoryWhereInput>;
  fieldHistory_none?: Maybe<FieldHistoryWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  mergedAt_not?: Maybe<Scalars['DateTime']>;
  mergedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_lt?: Maybe<Scalars['DateTime']>;
  mergedAt_lte?: Maybe<Scalars['DateTime']>;
  mergedAt_gt?: Maybe<Scalars['DateTime']>;
  mergedAt_gte?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonWhereInput>;
  duplicates_every?: Maybe<DirPersonWhereInput>;
  duplicates_some?: Maybe<DirPersonWhereInput>;
  duplicates_none?: Maybe<DirPersonWhereInput>;
  userProfile?: Maybe<UserWhereInput>;
  athleteProfile?: Maybe<DirAthleteWhereInput>;
  trainerProfile?: Maybe<DirTrainerWhereInput>;
  refereeProfile?: Maybe<DirRefereeWhereInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusWhereInput>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  previousLastname_not?: Maybe<Scalars['String']>;
  previousLastname_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_not_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_lt?: Maybe<Scalars['String']>;
  previousLastname_lte?: Maybe<Scalars['String']>;
  previousLastname_gt?: Maybe<Scalars['String']>;
  previousLastname_gte?: Maybe<Scalars['String']>;
  previousLastname_contains?: Maybe<Scalars['String']>;
  previousLastname_not_contains?: Maybe<Scalars['String']>;
  previousLastname_starts_with?: Maybe<Scalars['String']>;
  previousLastname_not_starts_with?: Maybe<Scalars['String']>;
  previousLastname_ends_with?: Maybe<Scalars['String']>;
  previousLastname_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  isMale_not?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  snils_not?: Maybe<Scalars['String']>;
  snils_in?: Maybe<Array<Scalars['String']>>;
  snils_not_in?: Maybe<Array<Scalars['String']>>;
  snils_lt?: Maybe<Scalars['String']>;
  snils_lte?: Maybe<Scalars['String']>;
  snils_gt?: Maybe<Scalars['String']>;
  snils_gte?: Maybe<Scalars['String']>;
  snils_contains?: Maybe<Scalars['String']>;
  snils_not_contains?: Maybe<Scalars['String']>;
  snils_starts_with?: Maybe<Scalars['String']>;
  snils_not_starts_with?: Maybe<Scalars['String']>;
  snils_ends_with?: Maybe<Scalars['String']>;
  snils_not_ends_with?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportSerial_not?: Maybe<Scalars['String']>;
  passportSerial_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_not_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_lt?: Maybe<Scalars['String']>;
  passportSerial_lte?: Maybe<Scalars['String']>;
  passportSerial_gt?: Maybe<Scalars['String']>;
  passportSerial_gte?: Maybe<Scalars['String']>;
  passportSerial_contains?: Maybe<Scalars['String']>;
  passportSerial_not_contains?: Maybe<Scalars['String']>;
  passportSerial_starts_with?: Maybe<Scalars['String']>;
  passportSerial_not_starts_with?: Maybe<Scalars['String']>;
  passportSerial_ends_with?: Maybe<Scalars['String']>;
  passportSerial_not_ends_with?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportNumber_not?: Maybe<Scalars['String']>;
  passportNumber_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_not_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_lt?: Maybe<Scalars['String']>;
  passportNumber_lte?: Maybe<Scalars['String']>;
  passportNumber_gt?: Maybe<Scalars['String']>;
  passportNumber_gte?: Maybe<Scalars['String']>;
  passportNumber_contains?: Maybe<Scalars['String']>;
  passportNumber_not_contains?: Maybe<Scalars['String']>;
  passportNumber_starts_with?: Maybe<Scalars['String']>;
  passportNumber_not_starts_with?: Maybe<Scalars['String']>;
  passportNumber_ends_with?: Maybe<Scalars['String']>;
  passportNumber_not_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssuedBy_not?: Maybe<Scalars['String']>;
  passportIssuedBy_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_not_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_lt?: Maybe<Scalars['String']>;
  passportIssuedBy_lte?: Maybe<Scalars['String']>;
  passportIssuedBy_gt?: Maybe<Scalars['String']>;
  passportIssuedBy_gte?: Maybe<Scalars['String']>;
  passportIssuedBy_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_not_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_ends_with?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  passportIssueDate_not?: Maybe<Scalars['DateTime']>;
  passportIssueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_lt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_lte?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gte?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueWhereInput>;
  dirCitizenships_every?: Maybe<DirCountryWhereInput>;
  dirCitizenships_some?: Maybe<DirCountryWhereInput>;
  dirCitizenships_none?: Maybe<DirCountryWhereInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirForeignCity?: Maybe<DirForeignCityWhereInput>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  phones_every?: Maybe<DirPersonPhoneWhereInput>;
  phones_some?: Maybe<DirPersonPhoneWhereInput>;
  phones_none?: Maybe<DirPersonPhoneWhereInput>;
  additionalRegions_every?: Maybe<DirPersonAdditionalRegionWhereInput>;
  additionalRegions_some?: Maybe<DirPersonAdditionalRegionWhereInput>;
  additionalRegions_none?: Maybe<DirPersonAdditionalRegionWhereInput>;
  mergeTasksAsPrimary_every?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsPrimary_some?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsPrimary_none?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsDuplicate_every?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsDuplicate_some?: Maybe<DirPersonMergeTaskWhereInput>;
  mergeTasksAsDuplicate_none?: Maybe<DirPersonMergeTaskWhereInput>;
  addresses_every?: Maybe<DirPersonAddressWhereInput>;
  addresses_some?: Maybe<DirPersonAddressWhereInput>;
  addresses_none?: Maybe<DirPersonAddressWhereInput>;
  AND?: Maybe<Array<DirPersonWhereInput>>;
  OR?: Maybe<Array<DirPersonWhereInput>>;
  NOT?: Maybe<Array<DirPersonWhereInput>>;
};

export type FieldHistoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  field_not?: Maybe<Scalars['String']>;
  field_in?: Maybe<Array<Scalars['String']>>;
  field_not_in?: Maybe<Array<Scalars['String']>>;
  field_lt?: Maybe<Scalars['String']>;
  field_lte?: Maybe<Scalars['String']>;
  field_gt?: Maybe<Scalars['String']>;
  field_gte?: Maybe<Scalars['String']>;
  field_contains?: Maybe<Scalars['String']>;
  field_not_contains?: Maybe<Scalars['String']>;
  field_starts_with?: Maybe<Scalars['String']>;
  field_not_starts_with?: Maybe<Scalars['String']>;
  field_ends_with?: Maybe<Scalars['String']>;
  field_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<FieldHistoryWhereInput>>;
  OR?: Maybe<Array<FieldHistoryWhereInput>>;
  NOT?: Maybe<Array<FieldHistoryWhereInput>>;
};

export type ArchiveWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  reason?: Maybe<Scalars['String']>;
  reason_not?: Maybe<Scalars['String']>;
  reason_in?: Maybe<Array<Scalars['String']>>;
  reason_not_in?: Maybe<Array<Scalars['String']>>;
  reason_lt?: Maybe<Scalars['String']>;
  reason_lte?: Maybe<Scalars['String']>;
  reason_gt?: Maybe<Scalars['String']>;
  reason_gte?: Maybe<Scalars['String']>;
  reason_contains?: Maybe<Scalars['String']>;
  reason_not_contains?: Maybe<Scalars['String']>;
  reason_starts_with?: Maybe<Scalars['String']>;
  reason_not_starts_with?: Maybe<Scalars['String']>;
  reason_ends_with?: Maybe<Scalars['String']>;
  reason_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ArchiveWhereInput>>;
  OR?: Maybe<Array<ArchiveWhereInput>>;
  NOT?: Maybe<Array<ArchiveWhereInput>>;
};

export type DirAthleteWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  dirPersonAgent?: Maybe<DirPersonWhereInput>;
  clsPersonAgent?: Maybe<ClassifierValueWhereInput>;
  sports_every?: Maybe<DirAthleteSportWhereInput>;
  sports_some?: Maybe<DirAthleteSportWhereInput>;
  sports_none?: Maybe<DirAthleteSportWhereInput>;
  medCerts_every?: Maybe<DirAthleteMedCertWhereInput>;
  medCerts_some?: Maybe<DirAthleteMedCertWhereInput>;
  medCerts_none?: Maybe<DirAthleteMedCertWhereInput>;
  competetionResult_every?: Maybe<DirAthleteCompetitionResultWhereInput>;
  competetionResult_some?: Maybe<DirAthleteCompetitionResultWhereInput>;
  competetionResult_none?: Maybe<DirAthleteCompetitionResultWhereInput>;
  AND?: Maybe<Array<DirAthleteWhereInput>>;
  OR?: Maybe<Array<DirAthleteWhereInput>>;
  NOT?: Maybe<Array<DirAthleteWhereInput>>;
};

export type ClassifierValueWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  classifier?: Maybe<ClassifierWhereInput>;
  AND?: Maybe<Array<ClassifierValueWhereInput>>;
  OR?: Maybe<Array<ClassifierValueWhereInput>>;
  NOT?: Maybe<Array<ClassifierValueWhereInput>>;
};

export type ClassifierWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  values_every?: Maybe<ClassifierValueWhereInput>;
  values_some?: Maybe<ClassifierValueWhereInput>;
  values_none?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<ClassifierWhereInput>>;
  OR?: Maybe<Array<ClassifierWhereInput>>;
  NOT?: Maybe<Array<ClassifierWhereInput>>;
};

export type DirAthleteSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  dirTrainers_every?: Maybe<DirTrainerWhereInput>;
  dirTrainers_some?: Maybe<DirTrainerWhereInput>;
  dirTrainers_none?: Maybe<DirTrainerWhereInput>;
  organizations_every?: Maybe<DirAthleteSportOrganizationWhereInput>;
  organizations_some?: Maybe<DirAthleteSportOrganizationWhereInput>;
  organizations_none?: Maybe<DirAthleteSportOrganizationWhereInput>;
  ranksAndAwards_every?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  ranksAndAwards_some?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  ranksAndAwards_none?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  role?: Maybe<Scalars['String']>;
  role_not?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Scalars['String']>>;
  role_not_in?: Maybe<Array<Scalars['String']>>;
  role_lt?: Maybe<Scalars['String']>;
  role_lte?: Maybe<Scalars['String']>;
  role_gt?: Maybe<Scalars['String']>;
  role_gte?: Maybe<Scalars['String']>;
  role_contains?: Maybe<Scalars['String']>;
  role_not_contains?: Maybe<Scalars['String']>;
  role_starts_with?: Maybe<Scalars['String']>;
  role_not_starts_with?: Maybe<Scalars['String']>;
  role_ends_with?: Maybe<Scalars['String']>;
  role_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteSportWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportWhereInput>>;
};

export type DirSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  evsk_not?: Maybe<Scalars['String']>;
  evsk_in?: Maybe<Array<Scalars['String']>>;
  evsk_not_in?: Maybe<Array<Scalars['String']>>;
  evsk_lt?: Maybe<Scalars['String']>;
  evsk_lte?: Maybe<Scalars['String']>;
  evsk_gt?: Maybe<Scalars['String']>;
  evsk_gte?: Maybe<Scalars['String']>;
  evsk_contains?: Maybe<Scalars['String']>;
  evsk_not_contains?: Maybe<Scalars['String']>;
  evsk_starts_with?: Maybe<Scalars['String']>;
  evsk_not_starts_with?: Maybe<Scalars['String']>;
  evsk_ends_with?: Maybe<Scalars['String']>;
  evsk_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups_every?: Maybe<ClassifierValueWhereInput>;
  clsSportAndDisciplineGroups_some?: Maybe<ClassifierValueWhereInput>;
  clsSportAndDisciplineGroups_none?: Maybe<ClassifierValueWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  ageGroups_every?: Maybe<DirSportAgeGroupWhereInput>;
  ageGroups_some?: Maybe<DirSportAgeGroupWhereInput>;
  ageGroups_none?: Maybe<DirSportAgeGroupWhereInput>;
  clsTrainingStages_every?: Maybe<ClassifierValueWhereInput>;
  clsTrainingStages_some?: Maybe<ClassifierValueWhereInput>;
  clsTrainingStages_none?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_every?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_some?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_none?: Maybe<ClassifierValueWhereInput>;
  clsCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsCategories_none?: Maybe<ClassifierValueWhereInput>;
  trainingStages_every?: Maybe<DirSportTrainingStageWhereInput>;
  trainingStages_some?: Maybe<DirSportTrainingStageWhereInput>;
  trainingStages_none?: Maybe<DirSportTrainingStageWhereInput>;
  baseRegions_every?: Maybe<DirSportBaseRegionWhereInput>;
  baseRegions_some?: Maybe<DirSportBaseRegionWhereInput>;
  baseRegions_none?: Maybe<DirSportBaseRegionWhereInput>;
  AND?: Maybe<Array<DirSportWhereInput>>;
  OR?: Maybe<Array<DirSportWhereInput>>;
  NOT?: Maybe<Array<DirSportWhereInput>>;
};

export type DirSportDisciplineWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  AND?: Maybe<Array<DirSportDisciplineWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineWhereInput>>;
};

export type DirSportDisciplineGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  AND?: Maybe<Array<DirSportDisciplineGroupWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineGroupWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineGroupWhereInput>>;
};

export type DirSportAgeGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportWhereInput>;
  AND?: Maybe<Array<DirSportAgeGroupWhereInput>>;
  OR?: Maybe<Array<DirSportAgeGroupWhereInput>>;
  NOT?: Maybe<Array<DirSportAgeGroupWhereInput>>;
};

export type DirSportTrainingStageWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportWhereInput>;
  clsTrainingStage?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_every?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_some?: Maybe<ClassifierValueWhereInput>;
  clsRanksAndAwards_none?: Maybe<ClassifierValueWhereInput>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  minGroupSize_not?: Maybe<Scalars['Int']>;
  minGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_lt?: Maybe<Scalars['Int']>;
  minGroupSize_lte?: Maybe<Scalars['Int']>;
  minGroupSize_gt?: Maybe<Scalars['Int']>;
  minGroupSize_gte?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize_not?: Maybe<Scalars['Int']>;
  maxGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_lt?: Maybe<Scalars['Int']>;
  maxGroupSize_lte?: Maybe<Scalars['Int']>;
  maxGroupSize_gt?: Maybe<Scalars['Int']>;
  maxGroupSize_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportTrainingStageWhereInput>>;
  OR?: Maybe<Array<DirSportTrainingStageWhereInput>>;
  NOT?: Maybe<Array<DirSportTrainingStageWhereInput>>;
};

export type DirSportBaseRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirSportWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportBaseRegionWhereInput>>;
  OR?: Maybe<Array<DirSportBaseRegionWhereInput>>;
  NOT?: Maybe<Array<DirSportBaseRegionWhereInput>>;
};

export type DirRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  dirFederalDistrict?: Maybe<DirFederalDistrictWhereInput>;
  AND?: Maybe<Array<DirRegionWhereInput>>;
  OR?: Maybe<Array<DirRegionWhereInput>>;
  NOT?: Maybe<Array<DirRegionWhereInput>>;
};

export type DirFederalDistrictWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  dirRegions_every?: Maybe<DirRegionWhereInput>;
  dirRegions_some?: Maybe<DirRegionWhereInput>;
  dirRegions_none?: Maybe<DirRegionWhereInput>;
  AND?: Maybe<Array<DirFederalDistrictWhereInput>>;
  OR?: Maybe<Array<DirFederalDistrictWhereInput>>;
  NOT?: Maybe<Array<DirFederalDistrictWhereInput>>;
};

export type DirTrainerWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  sports_every?: Maybe<DirTrainerSportWhereInput>;
  sports_some?: Maybe<DirTrainerSportWhereInput>;
  sports_none?: Maybe<DirTrainerSportWhereInput>;
  AND?: Maybe<Array<DirTrainerWhereInput>>;
  OR?: Maybe<Array<DirTrainerWhereInput>>;
  NOT?: Maybe<Array<DirTrainerWhereInput>>;
};

export type DirTrainerSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  organizations_every?: Maybe<DirTrainerSportOrganizationWhereInput>;
  organizations_some?: Maybe<DirTrainerSportOrganizationWhereInput>;
  organizations_none?: Maybe<DirTrainerSportOrganizationWhereInput>;
  categories_every?: Maybe<DirTrainerSportCategoryWhereInput>;
  categories_some?: Maybe<DirTrainerSportCategoryWhereInput>;
  categories_none?: Maybe<DirTrainerSportCategoryWhereInput>;
  AND?: Maybe<Array<DirTrainerSportWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportWhereInput>>;
};

export type DirTrainerSportOrganizationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  clsPosition?: Maybe<ClassifierValueWhereInput>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  employmentDate_not?: Maybe<Scalars['DateTime']>;
  employmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_lt?: Maybe<Scalars['DateTime']>;
  employmentDate_lte?: Maybe<Scalars['DateTime']>;
  employmentDate_gt?: Maybe<Scalars['DateTime']>;
  employmentDate_gte?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
  dismissalDate_not?: Maybe<Scalars['DateTime']>;
  dismissalDate_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_lt?: Maybe<Scalars['DateTime']>;
  dismissalDate_lte?: Maybe<Scalars['DateTime']>;
  dismissalDate_gt?: Maybe<Scalars['DateTime']>;
  dismissalDate_gte?: Maybe<Scalars['DateTime']>;
  sport?: Maybe<DirTrainerSportWhereInput>;
  AND?: Maybe<Array<DirTrainerSportOrganizationWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportOrganizationWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportOrganizationWhereInput>>;
};

export type DirTrainerSportCategoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  clsTrainerCategory?: Maybe<ClassifierValueWhereInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  sport?: Maybe<DirTrainerSportWhereInput>;
  AND?: Maybe<Array<DirTrainerSportCategoryWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportCategoryWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportCategoryWhereInput>>;
};

export type DirAthleteSportOrganizationWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  clsTrainingStage?: Maybe<ClassifierValueWhereInput>;
  dirTrainer?: Maybe<DirTrainerWhereInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  isSelfTraining_not?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  trainingStart_not?: Maybe<Scalars['DateTime']>;
  trainingStart_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_lt?: Maybe<Scalars['DateTime']>;
  trainingStart_lte?: Maybe<Scalars['DateTime']>;
  trainingStart_gt?: Maybe<Scalars['DateTime']>;
  trainingStart_gte?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
  exclusionDate_not?: Maybe<Scalars['DateTime']>;
  exclusionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_lt?: Maybe<Scalars['DateTime']>;
  exclusionDate_lte?: Maybe<Scalars['DateTime']>;
  exclusionDate_gt?: Maybe<Scalars['DateTime']>;
  exclusionDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirAthleteSportOrganizationWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportOrganizationWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportOrganizationWhereInput>>;
};

export type DirAthleteSportRankAndAwardWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirAthleteSportWhereInput>;
  clsRankAndAward?: Maybe<ClassifierValueWhereInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  confirmDate_not?: Maybe<Scalars['DateTime']>;
  confirmDate_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_lt?: Maybe<Scalars['DateTime']>;
  confirmDate_lte?: Maybe<Scalars['DateTime']>;
  confirmDate_gt?: Maybe<Scalars['DateTime']>;
  confirmDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentWhereInput>;
  AND?: Maybe<Array<DirAthleteSportRankAndAwardWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportRankAndAwardWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportRankAndAwardWhereInput>>;
};

export type DirDocumentWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  file?: Maybe<FileWhereInput>;
  AND?: Maybe<Array<DirDocumentWhereInput>>;
  OR?: Maybe<Array<DirDocumentWhereInput>>;
  NOT?: Maybe<Array<DirDocumentWhereInput>>;
};

export type FileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  path_not?: Maybe<Scalars['String']>;
  path_in?: Maybe<Array<Scalars['String']>>;
  path_not_in?: Maybe<Array<Scalars['String']>>;
  path_lt?: Maybe<Scalars['String']>;
  path_lte?: Maybe<Scalars['String']>;
  path_gt?: Maybe<Scalars['String']>;
  path_gte?: Maybe<Scalars['String']>;
  path_contains?: Maybe<Scalars['String']>;
  path_not_contains?: Maybe<Scalars['String']>;
  path_starts_with?: Maybe<Scalars['String']>;
  path_not_starts_with?: Maybe<Scalars['String']>;
  path_ends_with?: Maybe<Scalars['String']>;
  path_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
};

export type DirAthleteMedCertWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  displayNumber_not?: Maybe<Scalars['String']>;
  displayNumber_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_not_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_lt?: Maybe<Scalars['String']>;
  displayNumber_lte?: Maybe<Scalars['String']>;
  displayNumber_gt?: Maybe<Scalars['String']>;
  displayNumber_gte?: Maybe<Scalars['String']>;
  displayNumber_contains?: Maybe<Scalars['String']>;
  displayNumber_not_contains?: Maybe<Scalars['String']>;
  displayNumber_starts_with?: Maybe<Scalars['String']>;
  displayNumber_not_starts_with?: Maybe<Scalars['String']>;
  displayNumber_ends_with?: Maybe<Scalars['String']>;
  displayNumber_not_ends_with?: Maybe<Scalars['String']>;
  dirAthlete?: Maybe<DirAthleteWhereInput>;
  issueDate?: Maybe<Scalars['DateTime']>;
  issueDate_not?: Maybe<Scalars['DateTime']>;
  issueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_lt?: Maybe<Scalars['DateTime']>;
  issueDate_lte?: Maybe<Scalars['DateTime']>;
  issueDate_gt?: Maybe<Scalars['DateTime']>;
  issueDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationName_not?: Maybe<Scalars['String']>;
  organizationName_in?: Maybe<Array<Scalars['String']>>;
  organizationName_not_in?: Maybe<Array<Scalars['String']>>;
  organizationName_lt?: Maybe<Scalars['String']>;
  organizationName_lte?: Maybe<Scalars['String']>;
  organizationName_gt?: Maybe<Scalars['String']>;
  organizationName_gte?: Maybe<Scalars['String']>;
  organizationName_contains?: Maybe<Scalars['String']>;
  organizationName_not_contains?: Maybe<Scalars['String']>;
  organizationName_starts_with?: Maybe<Scalars['String']>;
  organizationName_not_starts_with?: Maybe<Scalars['String']>;
  organizationName_ends_with?: Maybe<Scalars['String']>;
  organizationName_not_ends_with?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationAddress_not?: Maybe<Scalars['String']>;
  organizationAddress_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_not_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_lt?: Maybe<Scalars['String']>;
  organizationAddress_lte?: Maybe<Scalars['String']>;
  organizationAddress_gt?: Maybe<Scalars['String']>;
  organizationAddress_gte?: Maybe<Scalars['String']>;
  organizationAddress_contains?: Maybe<Scalars['String']>;
  organizationAddress_not_contains?: Maybe<Scalars['String']>;
  organizationAddress_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_not_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_ends_with?: Maybe<Scalars['String']>;
  organizationAddress_not_ends_with?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationPhone_not?: Maybe<Scalars['String']>;
  organizationPhone_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_not_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_lt?: Maybe<Scalars['String']>;
  organizationPhone_lte?: Maybe<Scalars['String']>;
  organizationPhone_gt?: Maybe<Scalars['String']>;
  organizationPhone_gte?: Maybe<Scalars['String']>;
  organizationPhone_contains?: Maybe<Scalars['String']>;
  organizationPhone_not_contains?: Maybe<Scalars['String']>;
  organizationPhone_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_not_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_ends_with?: Maybe<Scalars['String']>;
  organizationPhone_not_ends_with?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  organizationEmail_not?: Maybe<Scalars['String']>;
  organizationEmail_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_not_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_lt?: Maybe<Scalars['String']>;
  organizationEmail_lte?: Maybe<Scalars['String']>;
  organizationEmail_gt?: Maybe<Scalars['String']>;
  organizationEmail_gte?: Maybe<Scalars['String']>;
  organizationEmail_contains?: Maybe<Scalars['String']>;
  organizationEmail_not_contains?: Maybe<Scalars['String']>;
  organizationEmail_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_not_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_ends_with?: Maybe<Scalars['String']>;
  organizationEmail_not_ends_with?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  isValid_not?: Maybe<Scalars['Boolean']>;
  sports_every?: Maybe<DirAthleteMedCertSportWhereInput>;
  sports_some?: Maybe<DirAthleteMedCertSportWhereInput>;
  sports_none?: Maybe<DirAthleteMedCertSportWhereInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_not?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_lt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_lte?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gte?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
  hasRestrictions_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirAthleteMedCertWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertWhereInput>>;
};

export type DirAthleteMedCertSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  medCert?: Maybe<DirAthleteMedCertWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  clsTrainingStage?: Maybe<ClassifierValueWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  AND?: Maybe<Array<DirAthleteMedCertSportWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertSportWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertSportWhereInput>>;
};

export type DirAthleteCompetitionResultWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirAthlete?: Maybe<DirAthleteWhereInput>;
  sportingEventProgramType?: Maybe<DirSportingEventProgramTypeWhereInput>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  result_not?: Maybe<Scalars['String']>;
  result_in?: Maybe<Array<Scalars['String']>>;
  result_not_in?: Maybe<Array<Scalars['String']>>;
  result_lt?: Maybe<Scalars['String']>;
  result_lte?: Maybe<Scalars['String']>;
  result_gt?: Maybe<Scalars['String']>;
  result_gte?: Maybe<Scalars['String']>;
  result_contains?: Maybe<Scalars['String']>;
  result_not_contains?: Maybe<Scalars['String']>;
  result_starts_with?: Maybe<Scalars['String']>;
  result_not_starts_with?: Maybe<Scalars['String']>;
  result_ends_with?: Maybe<Scalars['String']>;
  result_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteCompetitionResultWhereInput>>;
  OR?: Maybe<Array<DirAthleteCompetitionResultWhereInput>>;
  NOT?: Maybe<Array<DirAthleteCompetitionResultWhereInput>>;
};

export type DirSportingEventProgramTypeWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  start_not?: Maybe<Scalars['String']>;
  start_in?: Maybe<Array<Scalars['String']>>;
  start_not_in?: Maybe<Array<Scalars['String']>>;
  start_lt?: Maybe<Scalars['String']>;
  start_lte?: Maybe<Scalars['String']>;
  start_gt?: Maybe<Scalars['String']>;
  start_gte?: Maybe<Scalars['String']>;
  start_contains?: Maybe<Scalars['String']>;
  start_not_contains?: Maybe<Scalars['String']>;
  start_starts_with?: Maybe<Scalars['String']>;
  start_not_starts_with?: Maybe<Scalars['String']>;
  start_ends_with?: Maybe<Scalars['String']>;
  start_not_ends_with?: Maybe<Scalars['String']>;
  clsAgeGroups_every?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_some?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_none?: Maybe<ClassifierValueWhereInput>;
  discipline?: Maybe<DirSportDisciplineWhereInput>;
  AND?: Maybe<Array<DirSportingEventProgramTypeWhereInput>>;
  OR?: Maybe<Array<DirSportingEventProgramTypeWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventProgramTypeWhereInput>>;
};

export type DirSportingEventWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirNationalTeams_every?: Maybe<DirNationalTeamWhereInput>;
  dirNationalTeams_some?: Maybe<DirNationalTeamWhereInput>;
  dirNationalTeams_none?: Maybe<DirNationalTeamWhereInput>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  venue_not?: Maybe<Scalars['String']>;
  venue_in?: Maybe<Array<Scalars['String']>>;
  venue_not_in?: Maybe<Array<Scalars['String']>>;
  venue_lt?: Maybe<Scalars['String']>;
  venue_lte?: Maybe<Scalars['String']>;
  venue_gt?: Maybe<Scalars['String']>;
  venue_gte?: Maybe<Scalars['String']>;
  venue_contains?: Maybe<Scalars['String']>;
  venue_not_contains?: Maybe<Scalars['String']>;
  venue_starts_with?: Maybe<Scalars['String']>;
  venue_not_starts_with?: Maybe<Scalars['String']>;
  venue_ends_with?: Maybe<Scalars['String']>;
  venue_not_ends_with?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  object_not?: Maybe<Scalars['String']>;
  object_in?: Maybe<Array<Scalars['String']>>;
  object_not_in?: Maybe<Array<Scalars['String']>>;
  object_lt?: Maybe<Scalars['String']>;
  object_lte?: Maybe<Scalars['String']>;
  object_gt?: Maybe<Scalars['String']>;
  object_gte?: Maybe<Scalars['String']>;
  object_contains?: Maybe<Scalars['String']>;
  object_not_contains?: Maybe<Scalars['String']>;
  object_starts_with?: Maybe<Scalars['String']>;
  object_not_starts_with?: Maybe<Scalars['String']>;
  object_ends_with?: Maybe<Scalars['String']>;
  object_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  minManBirthYear_not?: Maybe<Scalars['Int']>;
  minManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_lt?: Maybe<Scalars['Int']>;
  minManBirthYear_lte?: Maybe<Scalars['Int']>;
  minManBirthYear_gt?: Maybe<Scalars['Int']>;
  minManBirthYear_gte?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear_not?: Maybe<Scalars['Int']>;
  maxManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_lt?: Maybe<Scalars['Int']>;
  maxManBirthYear_lte?: Maybe<Scalars['Int']>;
  maxManBirthYear_gt?: Maybe<Scalars['Int']>;
  maxManBirthYear_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  minWomanBirthYear_not?: Maybe<Scalars['Int']>;
  minWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_not?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  membersQuota_not?: Maybe<Scalars['Int']>;
  membersQuota_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_lt?: Maybe<Scalars['Int']>;
  membersQuota_lte?: Maybe<Scalars['Int']>;
  membersQuota_gt?: Maybe<Scalars['Int']>;
  membersQuota_gte?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  refereesQuota_not?: Maybe<Scalars['Int']>;
  refereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_lt?: Maybe<Scalars['Int']>;
  refereesQuota_lte?: Maybe<Scalars['Int']>;
  refereesQuota_gt?: Maybe<Scalars['Int']>;
  refereesQuota_gte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_not?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_lt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_lte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gte?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  ekp_not?: Maybe<Scalars['String']>;
  ekp_in?: Maybe<Array<Scalars['String']>>;
  ekp_not_in?: Maybe<Array<Scalars['String']>>;
  ekp_lt?: Maybe<Scalars['String']>;
  ekp_lte?: Maybe<Scalars['String']>;
  ekp_gt?: Maybe<Scalars['String']>;
  ekp_gte?: Maybe<Scalars['String']>;
  ekp_contains?: Maybe<Scalars['String']>;
  ekp_not_contains?: Maybe<Scalars['String']>;
  ekp_starts_with?: Maybe<Scalars['String']>;
  ekp_not_starts_with?: Maybe<Scalars['String']>;
  ekp_ends_with?: Maybe<Scalars['String']>;
  ekp_not_ends_with?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  dirForeignCity?: Maybe<DirForeignCityWhereInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUB_not?: Maybe<Scalars['Float']>;
  planRUB_in?: Maybe<Array<Scalars['Float']>>;
  planRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  planRUB_lt?: Maybe<Scalars['Float']>;
  planRUB_lte?: Maybe<Scalars['Float']>;
  planRUB_gt?: Maybe<Scalars['Float']>;
  planRUB_gte?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planRUBperPerson_not?: Maybe<Scalars['Float']>;
  planRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  planRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  planRUBperPerson_lt?: Maybe<Scalars['Float']>;
  planRUBperPerson_lte?: Maybe<Scalars['Float']>;
  planRUBperPerson_gt?: Maybe<Scalars['Float']>;
  planRUBperPerson_gte?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSD_not?: Maybe<Scalars['Float']>;
  planUSD_in?: Maybe<Array<Scalars['Float']>>;
  planUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  planUSD_lt?: Maybe<Scalars['Float']>;
  planUSD_lte?: Maybe<Scalars['Float']>;
  planUSD_gt?: Maybe<Scalars['Float']>;
  planUSD_gte?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planUSDperPerson_not?: Maybe<Scalars['Float']>;
  planUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  planUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  planUSDperPerson_lt?: Maybe<Scalars['Float']>;
  planUSDperPerson_lte?: Maybe<Scalars['Float']>;
  planUSDperPerson_gt?: Maybe<Scalars['Float']>;
  planUSDperPerson_gte?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  planDate_not?: Maybe<Scalars['DateTime']>;
  planDate_in?: Maybe<Array<Scalars['DateTime']>>;
  planDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  planDate_lt?: Maybe<Scalars['DateTime']>;
  planDate_lte?: Maybe<Scalars['DateTime']>;
  planDate_gt?: Maybe<Scalars['DateTime']>;
  planDate_gte?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUB_not?: Maybe<Scalars['Float']>;
  currentPlanRUB_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUB_lt?: Maybe<Scalars['Float']>;
  currentPlanRUB_lte?: Maybe<Scalars['Float']>;
  currentPlanRUB_gt?: Maybe<Scalars['Float']>;
  currentPlanRUB_gte?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_not?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUBperPerson_lt?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_lte?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_gt?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_gte?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSD_not?: Maybe<Scalars['Float']>;
  currentPlanUSD_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSD_lt?: Maybe<Scalars['Float']>;
  currentPlanUSD_lte?: Maybe<Scalars['Float']>;
  currentPlanUSD_gt?: Maybe<Scalars['Float']>;
  currentPlanUSD_gte?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_not?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSDperPerson_lt?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_lte?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_gt?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_gte?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  currentPlanDate_not?: Maybe<Scalars['DateTime']>;
  currentPlanDate_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPlanDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPlanDate_lt?: Maybe<Scalars['DateTime']>;
  currentPlanDate_lte?: Maybe<Scalars['DateTime']>;
  currentPlanDate_gt?: Maybe<Scalars['DateTime']>;
  currentPlanDate_gte?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUB_not?: Maybe<Scalars['Float']>;
  factRUB_in?: Maybe<Array<Scalars['Float']>>;
  factRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  factRUB_lt?: Maybe<Scalars['Float']>;
  factRUB_lte?: Maybe<Scalars['Float']>;
  factRUB_gt?: Maybe<Scalars['Float']>;
  factRUB_gte?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factRUBperPerson_not?: Maybe<Scalars['Float']>;
  factRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  factRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  factRUBperPerson_lt?: Maybe<Scalars['Float']>;
  factRUBperPerson_lte?: Maybe<Scalars['Float']>;
  factRUBperPerson_gt?: Maybe<Scalars['Float']>;
  factRUBperPerson_gte?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSD_not?: Maybe<Scalars['Float']>;
  factUSD_in?: Maybe<Array<Scalars['Float']>>;
  factUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  factUSD_lt?: Maybe<Scalars['Float']>;
  factUSD_lte?: Maybe<Scalars['Float']>;
  factUSD_gt?: Maybe<Scalars['Float']>;
  factUSD_gte?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factUSDperPerson_not?: Maybe<Scalars['Float']>;
  factUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  factUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  factUSDperPerson_lt?: Maybe<Scalars['Float']>;
  factUSDperPerson_lte?: Maybe<Scalars['Float']>;
  factUSDperPerson_gt?: Maybe<Scalars['Float']>;
  factUSDperPerson_gte?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  factDate_not?: Maybe<Scalars['DateTime']>;
  factDate_in?: Maybe<Array<Scalars['DateTime']>>;
  factDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  factDate_lt?: Maybe<Scalars['DateTime']>;
  factDate_lte?: Maybe<Scalars['DateTime']>;
  factDate_gt?: Maybe<Scalars['DateTime']>;
  factDate_gte?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesMan_not?: Maybe<Scalars['Int']>;
  quotaAthletesMan_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesMan_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesMan_lt?: Maybe<Scalars['Int']>;
  quotaAthletesMan_lte?: Maybe<Scalars['Int']>;
  quotaAthletesMan_gt?: Maybe<Scalars['Int']>;
  quotaAthletesMan_gte?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_not?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesWoman_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesWoman_lt?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_lte?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_gt?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_gte?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaTrainers_not?: Maybe<Scalars['Int']>;
  quotaTrainers_in?: Maybe<Array<Scalars['Int']>>;
  quotaTrainers_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaTrainers_lt?: Maybe<Scalars['Int']>;
  quotaTrainers_lte?: Maybe<Scalars['Int']>;
  quotaTrainers_gt?: Maybe<Scalars['Int']>;
  quotaTrainers_gte?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaReferees_not?: Maybe<Scalars['Int']>;
  quotaReferees_in?: Maybe<Array<Scalars['Int']>>;
  quotaReferees_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaReferees_lt?: Maybe<Scalars['Int']>;
  quotaReferees_lte?: Maybe<Scalars['Int']>;
  quotaReferees_gt?: Maybe<Scalars['Int']>;
  quotaReferees_gte?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaAdministration_not?: Maybe<Scalars['Int']>;
  quotaAdministration_in?: Maybe<Array<Scalars['Int']>>;
  quotaAdministration_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAdministration_lt?: Maybe<Scalars['Int']>;
  quotaAdministration_lte?: Maybe<Scalars['Int']>;
  quotaAdministration_gt?: Maybe<Scalars['Int']>;
  quotaAdministration_gte?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_not?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_in?: Maybe<Array<Scalars['Int']>>;
  quotaMedicalStaff_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaMedicalStaff_lt?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_lte?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_gt?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_gte?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaSpecialists_not?: Maybe<Scalars['Int']>;
  quotaSpecialists_in?: Maybe<Array<Scalars['Int']>>;
  quotaSpecialists_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaSpecialists_lt?: Maybe<Scalars['Int']>;
  quotaSpecialists_lte?: Maybe<Scalars['Int']>;
  quotaSpecialists_gt?: Maybe<Scalars['Int']>;
  quotaSpecialists_gte?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  quotaTeams_not?: Maybe<Scalars['Int']>;
  quotaTeams_in?: Maybe<Array<Scalars['Int']>>;
  quotaTeams_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaTeams_lt?: Maybe<Scalars['Int']>;
  quotaTeams_lte?: Maybe<Scalars['Int']>;
  quotaTeams_gt?: Maybe<Scalars['Int']>;
  quotaTeams_gte?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isImportant_not?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation_not?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant_not?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventWhereInput>;
  children_every?: Maybe<DirSportingEventWhereInput>;
  children_some?: Maybe<DirSportingEventWhereInput>;
  children_none?: Maybe<DirSportingEventWhereInput>;
  status?: Maybe<DirSportingEventStatusWhereInput>;
  statusHistory_every?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  statusHistory_some?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  statusHistory_none?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  dirCalendar?: Maybe<DirCalendarWhereInput>;
  clsEventCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsEventCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsEventCategories_none?: Maybe<ClassifierValueWhereInput>;
  sports_every?: Maybe<DirSportingEventSportWhereInput>;
  sports_some?: Maybe<DirSportingEventSportWhereInput>;
  sports_none?: Maybe<DirSportingEventSportWhereInput>;
  ageGroups_every?: Maybe<DirSportingEventAgeGroupWhereInput>;
  ageGroups_some?: Maybe<DirSportingEventAgeGroupWhereInput>;
  ageGroups_none?: Maybe<DirSportingEventAgeGroupWhereInput>;
  programTypes_every?: Maybe<DirSportingEventProgramTypeWhereInput>;
  programTypes_some?: Maybe<DirSportingEventProgramTypeWhereInput>;
  programTypes_none?: Maybe<DirSportingEventProgramTypeWhereInput>;
  referees_every?: Maybe<DirSportingEventRefereeWhereInput>;
  referees_some?: Maybe<DirSportingEventRefereeWhereInput>;
  referees_none?: Maybe<DirSportingEventRefereeWhereInput>;
  clsMinRang?: Maybe<ClassifierValueWhereInput>;
  competitionCalendar_every?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  competitionCalendar_some?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  competitionCalendar_none?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  competitionReglaments_every?: Maybe<DirDocumentWhereInput>;
  competitionReglaments_some?: Maybe<DirDocumentWhereInput>;
  competitionReglaments_none?: Maybe<DirDocumentWhereInput>;
  competitionProtocols_every?: Maybe<DirDocumentWhereInput>;
  competitionProtocols_some?: Maybe<DirDocumentWhereInput>;
  competitionProtocols_none?: Maybe<DirDocumentWhereInput>;
  organizer?: Maybe<DirOrganizationWhereInput>;
  clsDirectionality?: Maybe<ClassifierValueWhereInput>;
  refereeCollegiumDocuments_every?: Maybe<DirDocumentWhereInput>;
  refereeCollegiumDocuments_some?: Maybe<DirDocumentWhereInput>;
  refereeCollegiumDocuments_none?: Maybe<DirDocumentWhereInput>;
  clsSummarizingType?: Maybe<ClassifierValueWhereInput>;
  clsDivisions_every?: Maybe<ClassifierValueWhereInput>;
  clsDivisions_some?: Maybe<ClassifierValueWhereInput>;
  clsDivisions_none?: Maybe<ClassifierValueWhereInput>;
  clsEventStages_every?: Maybe<ClassifierValueWhereInput>;
  clsEventStages_some?: Maybe<ClassifierValueWhereInput>;
  clsEventStages_none?: Maybe<ClassifierValueWhereInput>;
  clsParticipatingOrgCategories_every?: Maybe<ClassifierValueWhereInput>;
  clsParticipatingOrgCategories_some?: Maybe<ClassifierValueWhereInput>;
  clsParticipatingOrgCategories_none?: Maybe<ClassifierValueWhereInput>;
  clsFinancingTypes_every?: Maybe<ClassifierValueWhereInput>;
  clsFinancingTypes_some?: Maybe<ClassifierValueWhereInput>;
  clsFinancingTypes_none?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirSportingEventWhereInput>>;
  OR?: Maybe<Array<DirSportingEventWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventWhereInput>>;
};

export type DirNationalTeamWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  manTeamQuota_not?: Maybe<Scalars['Int']>;
  manTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_lt?: Maybe<Scalars['Int']>;
  manTeamQuota_lte?: Maybe<Scalars['Int']>;
  manTeamQuota_gt?: Maybe<Scalars['Int']>;
  manTeamQuota_gte?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota_not?: Maybe<Scalars['Int']>;
  womanTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_lt?: Maybe<Scalars['Int']>;
  womanTeamQuota_lte?: Maybe<Scalars['Int']>;
  womanTeamQuota_gt?: Maybe<Scalars['Int']>;
  womanTeamQuota_gte?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota_not?: Maybe<Scalars['Int']>;
  supportTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_lt?: Maybe<Scalars['Int']>;
  supportTeamQuota_lte?: Maybe<Scalars['Int']>;
  supportTeamQuota_gt?: Maybe<Scalars['Int']>;
  supportTeamQuota_gte?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  athletesCall_not?: Maybe<Scalars['String']>;
  athletesCall_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_not_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_lt?: Maybe<Scalars['String']>;
  athletesCall_lte?: Maybe<Scalars['String']>;
  athletesCall_gt?: Maybe<Scalars['String']>;
  athletesCall_gte?: Maybe<Scalars['String']>;
  athletesCall_contains?: Maybe<Scalars['String']>;
  athletesCall_not_contains?: Maybe<Scalars['String']>;
  athletesCall_starts_with?: Maybe<Scalars['String']>;
  athletesCall_not_starts_with?: Maybe<Scalars['String']>;
  athletesCall_ends_with?: Maybe<Scalars['String']>;
  athletesCall_not_ends_with?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  score_not?: Maybe<Scalars['Int']>;
  score_in?: Maybe<Array<Scalars['Int']>>;
  score_not_in?: Maybe<Array<Scalars['Int']>>;
  score_lt?: Maybe<Scalars['Int']>;
  score_lte?: Maybe<Scalars['Int']>;
  score_gt?: Maybe<Scalars['Int']>;
  score_gte?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DirNationalTeamStatusWhereInput>;
  statusHistory_every?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  statusHistory_some?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  statusHistory_none?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  dirSportingEvent?: Maybe<DirSportingEventWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  trainers_every?: Maybe<DirNationalTeamTrainerWhereInput>;
  trainers_some?: Maybe<DirNationalTeamTrainerWhereInput>;
  trainers_none?: Maybe<DirNationalTeamTrainerWhereInput>;
  athleteGroups_every?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  athleteGroups_some?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  athleteGroups_none?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  specialists_every?: Maybe<DirNationalTeamMemberWhereInput>;
  specialists_some?: Maybe<DirNationalTeamMemberWhereInput>;
  specialists_none?: Maybe<DirNationalTeamMemberWhereInput>;
  medics_every?: Maybe<DirNationalTeamMemberWhereInput>;
  medics_some?: Maybe<DirNationalTeamMemberWhereInput>;
  medics_none?: Maybe<DirNationalTeamMemberWhereInput>;
  AND?: Maybe<Array<DirNationalTeamWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamWhereInput>>;
};

export type DirNationalTeamStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirNationalTeamStatusWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamStatusWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamStatusWhereInput>>;
};

export type DirNationalTeamStatusHistoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirNationalTeamStatusWhereInput>;
  user?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<DirNationalTeamStatusHistoryWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamStatusHistoryWhereInput>>;
};

export type DirNationalTeamTrainerWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamWhereInput>;
  dirTrainer?: Maybe<DirTrainerWhereInput>;
  clsPosition?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirNationalTeamTrainerWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamTrainerWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamTrainerWhereInput>>;
};

export type DirNationalTeamAthleteGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  clsAgeGroups_every?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_some?: Maybe<ClassifierValueWhereInput>;
  clsAgeGroups_none?: Maybe<ClassifierValueWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  athletes_every?: Maybe<DirNationalTeamAthleteWhereInput>;
  athletes_some?: Maybe<DirNationalTeamAthleteWhereInput>;
  athletes_none?: Maybe<DirNationalTeamAthleteWhereInput>;
  AND?: Maybe<Array<DirNationalTeamAthleteGroupWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteGroupWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteGroupWhereInput>>;
};

export type DirNationalTeamAthleteWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  group?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  dirAthlete?: Maybe<DirAthleteWhereInput>;
  bestResult?: Maybe<Scalars['String']>;
  bestResult_not?: Maybe<Scalars['String']>;
  bestResult_in?: Maybe<Array<Scalars['String']>>;
  bestResult_not_in?: Maybe<Array<Scalars['String']>>;
  bestResult_lt?: Maybe<Scalars['String']>;
  bestResult_lte?: Maybe<Scalars['String']>;
  bestResult_gt?: Maybe<Scalars['String']>;
  bestResult_gte?: Maybe<Scalars['String']>;
  bestResult_contains?: Maybe<Scalars['String']>;
  bestResult_not_contains?: Maybe<Scalars['String']>;
  bestResult_starts_with?: Maybe<Scalars['String']>;
  bestResult_not_starts_with?: Maybe<Scalars['String']>;
  bestResult_ends_with?: Maybe<Scalars['String']>;
  bestResult_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirNationalTeamAthleteWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteWhereInput>>;
};

export type DirNationalTeamMemberWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  clsPosition?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirNationalTeamMemberWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamMemberWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamMemberWhereInput>>;
};

export type DirCountryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  dirForeignCities_every?: Maybe<DirForeignCityWhereInput>;
  dirForeignCities_some?: Maybe<DirForeignCityWhereInput>;
  dirForeignCities_none?: Maybe<DirForeignCityWhereInput>;
  AND?: Maybe<Array<DirCountryWhereInput>>;
  OR?: Maybe<Array<DirCountryWhereInput>>;
  NOT?: Maybe<Array<DirCountryWhereInput>>;
};

export type DirForeignCityWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  AND?: Maybe<Array<DirForeignCityWhereInput>>;
  OR?: Maybe<Array<DirForeignCityWhereInput>>;
  NOT?: Maybe<Array<DirForeignCityWhereInput>>;
};

export type DirSportingEventStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventStatusWhereInput>>;
  OR?: Maybe<Array<DirSportingEventStatusWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventStatusWhereInput>>;
};

export type DirSportingEventStatusHistoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirSportingEventStatusWhereInput>;
  user?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<DirSportingEventStatusHistoryWhereInput>>;
  OR?: Maybe<Array<DirSportingEventStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventStatusHistoryWhereInput>>;
};

export type DirCalendarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirCalendarWhereInput>>;
  OR?: Maybe<Array<DirCalendarWhereInput>>;
  NOT?: Maybe<Array<DirCalendarWhereInput>>;
};

export type DirSportingEventSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  disciplines_every?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_some?: Maybe<DirSportDisciplineWhereInput>;
  disciplines_none?: Maybe<DirSportDisciplineWhereInput>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  AND?: Maybe<Array<DirSportingEventSportWhereInput>>;
  OR?: Maybe<Array<DirSportingEventSportWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventSportWhereInput>>;
};

export type DirSportingEventAgeGroupWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  clsAgeGroup?: Maybe<ClassifierValueWhereInput>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventAgeGroupWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAgeGroupWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAgeGroupWhereInput>>;
};

export type DirSportingEventRefereeWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  dirReferee?: Maybe<DirRefereeWhereInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPrimary_not?: Maybe<Scalars['Boolean']>;
  clsRefereePosition?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirSportingEventRefereeWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRefereeWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRefereeWhereInput>>;
};

export type DirRefereeWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  sports_every?: Maybe<DirRefereeSportWhereInput>;
  sports_some?: Maybe<DirRefereeSportWhereInput>;
  sports_none?: Maybe<DirRefereeSportWhereInput>;
  AND?: Maybe<Array<DirRefereeWhereInput>>;
  OR?: Maybe<Array<DirRefereeWhereInput>>;
  NOT?: Maybe<Array<DirRefereeWhereInput>>;
};

export type DirRefereeSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  workExperience?: Maybe<Scalars['Int']>;
  workExperience_not?: Maybe<Scalars['Int']>;
  workExperience_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_not_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_lt?: Maybe<Scalars['Int']>;
  workExperience_lte?: Maybe<Scalars['Int']>;
  workExperience_gt?: Maybe<Scalars['Int']>;
  workExperience_gte?: Maybe<Scalars['Int']>;
  categories_every?: Maybe<DirRefereeSportCategoryWhereInput>;
  categories_some?: Maybe<DirRefereeSportCategoryWhereInput>;
  categories_none?: Maybe<DirRefereeSportCategoryWhereInput>;
  AND?: Maybe<Array<DirRefereeSportWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportWhereInput>>;
};

export type DirRefereeSportCategoryWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirRefereeSportWhereInput>;
  clsRefereeCategory?: Maybe<ClassifierValueWhereInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  dirDocument?: Maybe<DirDocumentWhereInput>;
  AND?: Maybe<Array<DirRefereeSportCategoryWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportCategoryWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportCategoryWhereInput>>;
};

export type DirSportingEventCompetitionCalendarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventWhereInput>;
  day?: Maybe<Scalars['DateTime']>;
  day_not?: Maybe<Scalars['DateTime']>;
  day_in?: Maybe<Array<Scalars['DateTime']>>;
  day_not_in?: Maybe<Array<Scalars['DateTime']>>;
  day_lt?: Maybe<Scalars['DateTime']>;
  day_lte?: Maybe<Scalars['DateTime']>;
  day_gt?: Maybe<Scalars['DateTime']>;
  day_gte?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  time_not?: Maybe<Scalars['String']>;
  time_in?: Maybe<Array<Scalars['String']>>;
  time_not_in?: Maybe<Array<Scalars['String']>>;
  time_lt?: Maybe<Scalars['String']>;
  time_lte?: Maybe<Scalars['String']>;
  time_gt?: Maybe<Scalars['String']>;
  time_gte?: Maybe<Scalars['String']>;
  time_contains?: Maybe<Scalars['String']>;
  time_not_contains?: Maybe<Scalars['String']>;
  time_starts_with?: Maybe<Scalars['String']>;
  time_not_starts_with?: Maybe<Scalars['String']>;
  time_ends_with?: Maybe<Scalars['String']>;
  time_not_ends_with?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  members_not?: Maybe<Scalars['String']>;
  members_in?: Maybe<Array<Scalars['String']>>;
  members_not_in?: Maybe<Array<Scalars['String']>>;
  members_lt?: Maybe<Scalars['String']>;
  members_lte?: Maybe<Scalars['String']>;
  members_gt?: Maybe<Scalars['String']>;
  members_gte?: Maybe<Scalars['String']>;
  members_contains?: Maybe<Scalars['String']>;
  members_not_contains?: Maybe<Scalars['String']>;
  members_starts_with?: Maybe<Scalars['String']>;
  members_not_starts_with?: Maybe<Scalars['String']>;
  members_ends_with?: Maybe<Scalars['String']>;
  members_not_ends_with?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineWhereInput>;
  AND?: Maybe<Array<DirSportingEventCompetitionCalendarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventCompetitionCalendarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventCompetitionCalendarWhereInput>>;
};

export type DirPersonConfirmStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonConfirmStatusWhereInput>>;
  OR?: Maybe<Array<DirPersonConfirmStatusWhereInput>>;
  NOT?: Maybe<Array<DirPersonConfirmStatusWhereInput>>;
};

export type DirPersonPhoneWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueWhereInput>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  AND?: Maybe<Array<DirPersonPhoneWhereInput>>;
  OR?: Maybe<Array<DirPersonPhoneWhereInput>>;
  NOT?: Maybe<Array<DirPersonPhoneWhereInput>>;
};

export type DirPersonAdditionalRegionWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirForeignCity?: Maybe<DirForeignCityWhereInput>;
  dirCountry?: Maybe<DirCountryWhereInput>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  AND?: Maybe<Array<DirPersonAdditionalRegionWhereInput>>;
  OR?: Maybe<Array<DirPersonAdditionalRegionWhereInput>>;
  NOT?: Maybe<Array<DirPersonAdditionalRegionWhereInput>>;
};

export type DirPersonMergeTaskWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  status?: Maybe<TaskStatusWhereInput>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  primary?: Maybe<DirPersonWhereInput>;
  duplicate?: Maybe<DirPersonWhereInput>;
  AND?: Maybe<Array<DirPersonMergeTaskWhereInput>>;
  OR?: Maybe<Array<DirPersonMergeTaskWhereInput>>;
  NOT?: Maybe<Array<DirPersonMergeTaskWhereInput>>;
};

export type TaskStatusWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TaskStatusWhereInput>>;
  OR?: Maybe<Array<TaskStatusWhereInput>>;
  NOT?: Maybe<Array<TaskStatusWhereInput>>;
};

export type DirPersonAddressWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonWhereInput>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeWhereInput>;
  AND?: Maybe<Array<DirPersonAddressWhereInput>>;
  OR?: Maybe<Array<DirPersonAddressWhereInput>>;
  NOT?: Maybe<Array<DirPersonAddressWhereInput>>;
};

export type AddressTypeWhereInput = {
  id?: Maybe<Scalars['Int']>;
  id_not?: Maybe<Scalars['Int']>;
  id_in?: Maybe<Array<Scalars['Int']>>;
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  id_lt?: Maybe<Scalars['Int']>;
  id_lte?: Maybe<Scalars['Int']>;
  id_gt?: Maybe<Scalars['Int']>;
  id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<AddressTypeWhereInput>>;
  OR?: Maybe<Array<AddressTypeWhereInput>>;
  NOT?: Maybe<Array<AddressTypeWhereInput>>;
};

export type RegionalMinsportProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  dirRegion?: Maybe<DirRegionWhereInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictWhereInput>;
  AND?: Maybe<Array<RegionalMinsportProfileWhereInput>>;
  OR?: Maybe<Array<RegionalMinsportProfileWhereInput>>;
  NOT?: Maybe<Array<RegionalMinsportProfileWhereInput>>;
};

export type RegionalSchoolProfileWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  AND?: Maybe<Array<RegionalSchoolProfileWhereInput>>;
  OR?: Maybe<Array<RegionalSchoolProfileWhereInput>>;
  NOT?: Maybe<Array<RegionalSchoolProfileWhereInput>>;
};

export type DirOrganizationSportWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  archive?: Maybe<ArchiveWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  dirSport?: Maybe<DirSportWhereInput>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  trainingStartDate_not?: Maybe<Scalars['DateTime']>;
  trainingStartDate_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_lt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_lte?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gte?: Maybe<Scalars['DateTime']>;
  disciplineGroups_every?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_some?: Maybe<DirSportDisciplineGroupWhereInput>;
  disciplineGroups_none?: Maybe<DirSportDisciplineGroupWhereInput>;
  AND?: Maybe<Array<DirOrganizationSportWhereInput>>;
  OR?: Maybe<Array<DirOrganizationSportWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationSportWhereInput>>;
};

export type DirOrganizationAddressWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeWhereInput>;
  AND?: Maybe<Array<DirOrganizationAddressWhereInput>>;
  OR?: Maybe<Array<DirOrganizationAddressWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationAddressWhereInput>>;
};

export type DirOrganizationPhoneWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirOrganizationPhoneWhereInput>>;
  OR?: Maybe<Array<DirOrganizationPhoneWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationPhoneWhereInput>>;
};

export type DirOrganizationEmailWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  updatedBy?: Maybe<UserWhereInput>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationWhereInput>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueWhereInput>;
  AND?: Maybe<Array<DirOrganizationEmailWhereInput>>;
  OR?: Maybe<Array<DirOrganizationEmailWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationEmailWhereInput>>;
};

export enum DirOrganizationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsLegalEntityAsc = 'isLegalEntity_ASC',
  IsLegalEntityDesc = 'isLegalEntity_DESC',
  OgrnAsc = 'ogrn_ASC',
  OgrnDesc = 'ogrn_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  RegistrationDateAsc = 'registrationDate_ASC',
  RegistrationDateDesc = 'registrationDate_DESC',
  InnAsc = 'inn_ASC',
  InnDesc = 'inn_DESC',
  KppAsc = 'kpp_ASC',
  KppDesc = 'kpp_DESC',
  LegalAddressAsc = 'legalAddress_ASC',
  LegalAddressDesc = 'legalAddress_DESC',
  PostcodeAsc = 'postcode_ASC',
  PostcodeDesc = 'postcode_DESC',
  HeadPositionAsc = 'headPosition_ASC',
  HeadPositionDesc = 'headPosition_DESC',
  HeadFullNameAsc = 'headFullName_ASC',
  HeadFullNameDesc = 'headFullName_DESC',
}

export type DirRegion = {
  __typename?: 'DirRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  dirFederalDistrict: DirFederalDistrict;
};

export type DirFederalDistrict = {
  __typename?: 'DirFederalDistrict';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirRegions?: Maybe<Array<DirRegion>>;
};

export type DirFederalDistrictDirRegionsArgs = {
  where?: Maybe<DirRegionWhereInput>;
  orderBy?: Maybe<DirRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
}

export enum ClassifierValueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
}

export type ClassifierValue = {
  __typename?: 'ClassifierValue';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier: Classifier;
};

export type Classifier = {
  __typename?: 'Classifier';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  values?: Maybe<Array<ClassifierValue>>;
};

export type ClassifierValuesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  EvskAsc = 'evsk_ASC',
  EvskDesc = 'evsk_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
}

export type DirSport = {
  __typename?: 'DirSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  clsSportAndDisciplineGroups?: Maybe<Array<ClassifierValue>>;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  ageGroups?: Maybe<Array<DirSportAgeGroup>>;
  clsTrainingStages?: Maybe<Array<ClassifierValue>>;
  clsRanksAndAwards?: Maybe<Array<ClassifierValue>>;
  clsCategories?: Maybe<Array<ClassifierValue>>;
  trainingStages?: Maybe<Array<DirSportTrainingStage>>;
  baseRegions?: Maybe<Array<DirSportBaseRegion>>;
};

export type DirSportClsSportAndDisciplineGroupsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportAgeGroupsArgs = {
  where?: Maybe<DirSportAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportClsTrainingStagesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportClsRanksAndAwardsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportClsCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportTrainingStagesArgs = {
  where?: Maybe<DirSportTrainingStageWhereInput>;
  orderBy?: Maybe<DirSportTrainingStageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportBaseRegionsArgs = {
  where?: Maybe<DirSportBaseRegionWhereInput>;
  orderBy?: Maybe<DirSportBaseRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportDisciplineOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
}

export type DirSportDiscipline = {
  __typename?: 'DirSportDiscipline';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  dirSport?: Maybe<DirSport>;
};

export type DirSportDisciplineDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportDisciplineGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type DirSportDisciplineGroup = {
  __typename?: 'DirSportDisciplineGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  dirSport?: Maybe<DirSport>;
};

export type DirSportDisciplineGroupDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportAgeGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC',
}

export type DirSportAgeGroup = {
  __typename?: 'DirSportAgeGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup: ClassifierValue;
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSport>;
};

export enum DirSportTrainingStageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinManAgeAsc = 'minManAge_ASC',
  MinManAgeDesc = 'minManAge_DESC',
  MaxManAgeAsc = 'maxManAge_ASC',
  MaxManAgeDesc = 'maxManAge_DESC',
  MinWomanAgeAsc = 'minWomanAge_ASC',
  MinWomanAgeDesc = 'minWomanAge_DESC',
  MaxWomanAgeAsc = 'maxWomanAge_ASC',
  MaxWomanAgeDesc = 'maxWomanAge_DESC',
  MinGroupSizeAsc = 'minGroupSize_ASC',
  MinGroupSizeDesc = 'minGroupSize_DESC',
  MaxGroupSizeAsc = 'maxGroupSize_ASC',
  MaxGroupSizeDesc = 'maxGroupSize_DESC',
}

export type DirSportTrainingStage = {
  __typename?: 'DirSportTrainingStage';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSport>;
  clsTrainingStage: ClassifierValue;
  clsRanksAndAwards?: Maybe<Array<ClassifierValue>>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportTrainingStageClsRanksAndAwardsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportBaseRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
}

export type DirSportBaseRegion = {
  __typename?: 'DirSportBaseRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirSport;
  dirRegion: DirRegion;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export enum DirOrganizationSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  TrainingStartDateAsc = 'trainingStartDate_ASC',
  TrainingStartDateDesc = 'trainingStartDate_DESC',
}

export type DirOrganizationSport = {
  __typename?: 'DirOrganizationSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  dirSport: DirSport;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
};

export type DirOrganizationSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirOrganizationAddressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  StreetAsc = 'street_ASC',
  StreetDesc = 'street_DESC',
  HouseAsc = 'house_ASC',
  HouseDesc = 'house_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
}

export type DirOrganizationAddress = {
  __typename?: 'DirOrganizationAddress';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type AddressType = {
  __typename?: 'AddressType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirOrganizationPhoneOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  OperatorCodeAsc = 'operatorCode_ASC',
  OperatorCodeDesc = 'operatorCode_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  AdditionalNumberAsc = 'additionalNumber_ASC',
  AdditionalNumberDesc = 'additionalNumber_DESC',
}

export type DirOrganizationPhone = {
  __typename?: 'DirOrganizationPhone';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValue;
};

export enum DirOrganizationEmailOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
}

export type DirOrganizationEmail = {
  __typename?: 'DirOrganizationEmail';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  email: Scalars['String'];
  clsContactType: ClassifierValue;
};

export type SystemUser = {
  __typename?: 'SystemUser';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type DirPerson = {
  __typename?: 'DirPerson';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  fieldHistory?: Maybe<Array<FieldHistory>>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPerson>;
  duplicates?: Maybe<Array<DirPerson>>;
  userProfile?: Maybe<User>;
  athleteProfile?: Maybe<DirAthlete>;
  trainerProfile?: Maybe<DirTrainer>;
  refereeProfile?: Maybe<DirReferee>;
  confirmStatus: DirPersonConfirmStatus;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValue>;
  dirCitizenships?: Maybe<Array<DirCountry>>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegion>;
  dirForeignCity?: Maybe<DirForeignCity>;
  dirCountry?: Maybe<DirCountry>;
  phones?: Maybe<Array<DirPersonPhone>>;
  additionalRegions?: Maybe<Array<DirPersonAdditionalRegion>>;
  mergeTasksAsPrimary?: Maybe<Array<DirPersonMergeTask>>;
  mergeTasksAsDuplicate?: Maybe<Array<DirPersonMergeTask>>;
  addresses?: Maybe<Array<DirPersonAddress>>;
};

export type DirPersonFieldHistoryArgs = {
  where?: Maybe<FieldHistoryWhereInput>;
  orderBy?: Maybe<FieldHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirPersonDuplicatesArgs = {
  where?: Maybe<DirPersonWhereInput>;
  orderBy?: Maybe<DirPersonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirPersonDirCitizenshipsArgs = {
  where?: Maybe<DirCountryWhereInput>;
  orderBy?: Maybe<DirCountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirPersonPhonesArgs = {
  where?: Maybe<DirPersonPhoneWhereInput>;
  orderBy?: Maybe<DirPersonPhoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirPersonAdditionalRegionsArgs = {
  where?: Maybe<DirPersonAdditionalRegionWhereInput>;
  orderBy?: Maybe<DirPersonAdditionalRegionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirPersonMergeTasksAsPrimaryArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
  orderBy?: Maybe<DirPersonMergeTaskOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirPersonMergeTasksAsDuplicateArgs = {
  where?: Maybe<DirPersonMergeTaskWhereInput>;
  orderBy?: Maybe<DirPersonMergeTaskOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirPersonAddressesArgs = {
  where?: Maybe<DirPersonAddressWhereInput>;
  orderBy?: Maybe<DirPersonAddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum FieldHistoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FieldAsc = 'field_ASC',
  FieldDesc = 'field_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type FieldHistory = {
  __typename?: 'FieldHistory';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  field: Scalars['String'];
  value: Scalars['Json'];
};

export enum DirPersonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MergedAtAsc = 'mergedAt_ASC',
  MergedAtDesc = 'mergedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  LastnameAsc = 'lastname_ASC',
  LastnameDesc = 'lastname_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  PatronymicAsc = 'patronymic_ASC',
  PatronymicDesc = 'patronymic_DESC',
  PreviousLastnameAsc = 'previousLastname_ASC',
  PreviousLastnameDesc = 'previousLastname_DESC',
  BirthdayAsc = 'birthday_ASC',
  BirthdayDesc = 'birthday_DESC',
  IsMaleAsc = 'isMale_ASC',
  IsMaleDesc = 'isMale_DESC',
  SnilsAsc = 'snils_ASC',
  SnilsDesc = 'snils_DESC',
  PassportSerialAsc = 'passportSerial_ASC',
  PassportSerialDesc = 'passportSerial_DESC',
  PassportNumberAsc = 'passportNumber_ASC',
  PassportNumberDesc = 'passportNumber_DESC',
  PassportIssuedByAsc = 'passportIssuedBy_ASC',
  PassportIssuedByDesc = 'passportIssuedBy_DESC',
  PassportIssueDateAsc = 'passportIssueDate_ASC',
  PassportIssueDateDesc = 'passportIssueDate_DESC',
  IsRussiaAsc = 'isRussia_ASC',
  IsRussiaDesc = 'isRussia_DESC',
}

export type DirAthlete = {
  __typename?: 'DirAthlete';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  dirPersonAgent?: Maybe<DirPerson>;
  clsPersonAgent?: Maybe<ClassifierValue>;
  sports?: Maybe<Array<DirAthleteSport>>;
  medCerts?: Maybe<Array<DirAthleteMedCert>>;
  competetionResult?: Maybe<Array<DirAthleteCompetitionResult>>;
};

export type DirAthleteSportsArgs = {
  where?: Maybe<DirAthleteSportWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirAthleteMedCertsArgs = {
  where?: Maybe<DirAthleteMedCertWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirAthleteCompetetionResultArgs = {
  where?: Maybe<DirAthleteCompetitionResultWhereInput>;
  orderBy?: Maybe<DirAthleteCompetitionResultOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirAthleteSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
}

export type DirAthleteSport = {
  __typename?: 'DirAthleteSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSport;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  dirTrainers?: Maybe<Array<DirTrainer>>;
  organizations?: Maybe<Array<DirAthleteSportOrganization>>;
  ranksAndAwards?: Maybe<Array<DirAthleteSportRankAndAward>>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportDirTrainersArgs = {
  where?: Maybe<DirTrainerWhereInput>;
  orderBy?: Maybe<DirTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportOrganizationsArgs = {
  where?: Maybe<DirAthleteSportOrganizationWhereInput>;
  orderBy?: Maybe<DirAthleteSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportRanksAndAwardsArgs = {
  where?: Maybe<DirAthleteSportRankAndAwardWhereInput>;
  orderBy?: Maybe<DirAthleteSportRankAndAwardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirTrainerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
}

export type DirTrainer = {
  __typename?: 'DirTrainer';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  sports?: Maybe<Array<DirTrainerSport>>;
};

export type DirTrainerSportsArgs = {
  where?: Maybe<DirTrainerSportWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirTrainerSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
}

export type DirTrainerSport = {
  __typename?: 'DirTrainerSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainer;
  dirSport: DirSport;
  organizations?: Maybe<Array<DirTrainerSportOrganization>>;
  categories?: Maybe<Array<DirTrainerSportCategory>>;
};

export type DirTrainerSportOrganizationsArgs = {
  where?: Maybe<DirTrainerSportOrganizationWhereInput>;
  orderBy?: Maybe<DirTrainerSportOrganizationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirTrainerSportCategoriesArgs = {
  where?: Maybe<DirTrainerSportCategoryWhereInput>;
  orderBy?: Maybe<DirTrainerSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirTrainerSportOrganizationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  EmploymentDateAsc = 'employmentDate_ASC',
  EmploymentDateDesc = 'employmentDate_DESC',
  DismissalDateAsc = 'dismissalDate_ASC',
  DismissalDateDesc = 'dismissalDate_DESC',
}

export type DirTrainerSportOrganization = {
  __typename?: 'DirTrainerSportOrganization';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  clsPosition: ClassifierValue;
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
  sport: DirTrainerSport;
};

export enum DirTrainerSportCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  AssignmentDateAsc = 'assignmentDate_ASC',
  AssignmentDateDesc = 'assignmentDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC',
  IsEndlessAsc = 'isEndless_ASC',
  IsEndlessDesc = 'isEndless_DESC',
}

export type DirTrainerSportCategory = {
  __typename?: 'DirTrainerSportCategory';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory: ClassifierValue;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  sport: DirTrainerSport;
};

export enum DirAthleteSportOrganizationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  IsSelfTrainingAsc = 'isSelfTraining_ASC',
  IsSelfTrainingDesc = 'isSelfTraining_DESC',
  TrainingStartAsc = 'trainingStart_ASC',
  TrainingStartDesc = 'trainingStart_DESC',
  ExclusionDateAsc = 'exclusionDate_ASC',
  ExclusionDateDesc = 'exclusionDate_DESC',
}

export type DirAthleteSportOrganization = {
  __typename?: 'DirAthleteSportOrganization';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganization;
  clsTrainingStage: ClassifierValue;
  dirTrainer?: Maybe<DirTrainer>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart: Scalars['DateTime'];
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export enum DirAthleteSportRankAndAwardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  AssignmentDateAsc = 'assignmentDate_ASC',
  AssignmentDateDesc = 'assignmentDate_DESC',
  ConfirmDateAsc = 'confirmDate_ASC',
  ConfirmDateDesc = 'confirmDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC',
}

export type DirAthleteSportRankAndAward = {
  __typename?: 'DirAthleteSportRankAndAward';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirAthleteSport;
  clsRankAndAward: ClassifierValue;
  assignmentDate: Scalars['DateTime'];
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocument>;
};

export type DirDocument = {
  __typename?: 'DirDocument';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  date: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  file: File;
};

export type File = {
  __typename?: 'File';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['String'];
};

export enum DirAthleteMedCertOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  DisplayNumberAsc = 'displayNumber_ASC',
  DisplayNumberDesc = 'displayNumber_DESC',
  IssueDateAsc = 'issueDate_ASC',
  IssueDateDesc = 'issueDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC',
  OrganizationNameAsc = 'organizationName_ASC',
  OrganizationNameDesc = 'organizationName_DESC',
  OrganizationAddressAsc = 'organizationAddress_ASC',
  OrganizationAddressDesc = 'organizationAddress_DESC',
  OrganizationPhoneAsc = 'organizationPhone_ASC',
  OrganizationPhoneDesc = 'organizationPhone_DESC',
  OrganizationEmailAsc = 'organizationEmail_ASC',
  OrganizationEmailDesc = 'organizationEmail_DESC',
  IsValidAsc = 'isValid_ASC',
  IsValidDesc = 'isValid_DESC',
  PhysicalExaminationDateAsc = 'physicalExaminationDate_ASC',
  PhysicalExaminationDateDesc = 'physicalExaminationDate_DESC',
  HasRestrictionsAsc = 'hasRestrictions_ASC',
  HasRestrictionsDesc = 'hasRestrictions_DESC',
}

export type DirAthleteMedCert = {
  __typename?: 'DirAthleteMedCert';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete: DirAthlete;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  sports?: Maybe<Array<DirAthleteMedCertSport>>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertSportsArgs = {
  where?: Maybe<DirAthleteMedCertSportWhereInput>;
  orderBy?: Maybe<DirAthleteMedCertSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirAthleteMedCertSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
}

export type DirAthleteMedCertSport = {
  __typename?: 'DirAthleteMedCertSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  medCert: DirAthleteMedCert;
  dirSport: DirSport;
  clsTrainingStage?: Maybe<ClassifierValue>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
};

export type DirAthleteMedCertSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirAthleteCompetitionResultOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  PointAsc = 'point_ASC',
  PointDesc = 'point_DESC',
  ResultAsc = 'result_ASC',
  ResultDesc = 'result_DESC',
}

export type DirAthleteCompetitionResult = {
  __typename?: 'DirAthleteCompetitionResult';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirAthlete: DirAthlete;
  sportingEventProgramType: DirSportingEventProgramType;
  point: Scalars['Int'];
  result: Scalars['String'];
};

export type DirSportingEventProgramType = {
  __typename?: 'DirSportingEventProgramType';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  start?: Maybe<Scalars['String']>;
  clsAgeGroups?: Maybe<Array<ClassifierValue>>;
  discipline: DirSportDiscipline;
};

export type DirSportingEventProgramTypeClsAgeGroupsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEvent = {
  __typename?: 'DirSportingEvent';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<Array<DirNationalTeam>>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegion>;
  dirCountry?: Maybe<DirCountry>;
  dirForeignCity?: Maybe<DirForeignCity>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEvent>;
  children?: Maybe<Array<DirSportingEvent>>;
  status: DirSportingEventStatus;
  statusHistory?: Maybe<Array<DirSportingEventStatusHistory>>;
  dirCalendar: DirCalendar;
  clsEventCategories?: Maybe<Array<ClassifierValue>>;
  sports?: Maybe<Array<DirSportingEventSport>>;
  ageGroups?: Maybe<Array<DirSportingEventAgeGroup>>;
  programTypes?: Maybe<Array<DirSportingEventProgramType>>;
  referees?: Maybe<Array<DirSportingEventReferee>>;
  clsMinRang?: Maybe<ClassifierValue>;
  competitionCalendar?: Maybe<Array<DirSportingEventCompetitionCalendar>>;
  competitionReglaments?: Maybe<Array<DirDocument>>;
  competitionProtocols?: Maybe<Array<DirDocument>>;
  organizer?: Maybe<DirOrganization>;
  clsDirectionality?: Maybe<ClassifierValue>;
  refereeCollegiumDocuments?: Maybe<Array<DirDocument>>;
  clsSummarizingType?: Maybe<ClassifierValue>;
  clsDivisions?: Maybe<Array<ClassifierValue>>;
  clsEventStages?: Maybe<Array<ClassifierValue>>;
  clsParticipatingOrgCategories?: Maybe<Array<ClassifierValue>>;
  clsFinancingTypes?: Maybe<Array<ClassifierValue>>;
};

export type DirSportingEventDirNationalTeamsArgs = {
  where?: Maybe<DirNationalTeamWhereInput>;
  orderBy?: Maybe<DirNationalTeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventChildrenArgs = {
  where?: Maybe<DirSportingEventWhereInput>;
  orderBy?: Maybe<DirSportingEventOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventStatusHistoryArgs = {
  where?: Maybe<DirSportingEventStatusHistoryWhereInput>;
  orderBy?: Maybe<DirSportingEventStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventClsEventCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventSportsArgs = {
  where?: Maybe<DirSportingEventSportWhereInput>;
  orderBy?: Maybe<DirSportingEventSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventAgeGroupsArgs = {
  where?: Maybe<DirSportingEventAgeGroupWhereInput>;
  orderBy?: Maybe<DirSportingEventAgeGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventProgramTypesArgs = {
  where?: Maybe<DirSportingEventProgramTypeWhereInput>;
  orderBy?: Maybe<DirSportingEventProgramTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventRefereesArgs = {
  where?: Maybe<DirSportingEventRefereeWhereInput>;
  orderBy?: Maybe<DirSportingEventRefereeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCompetitionCalendarArgs = {
  where?: Maybe<DirSportingEventCompetitionCalendarWhereInput>;
  orderBy?: Maybe<DirSportingEventCompetitionCalendarOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCompetitionReglamentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCompetitionProtocolsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventRefereeCollegiumDocumentsArgs = {
  where?: Maybe<DirDocumentWhereInput>;
  orderBy?: Maybe<DirDocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventClsDivisionsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventClsEventStagesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventClsParticipatingOrgCategoriesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventClsFinancingTypesArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirNationalTeamOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  ManTeamQuotaAsc = 'manTeamQuota_ASC',
  ManTeamQuotaDesc = 'manTeamQuota_DESC',
  WomanTeamQuotaAsc = 'womanTeamQuota_ASC',
  WomanTeamQuotaDesc = 'womanTeamQuota_DESC',
  SupportTeamQuotaAsc = 'supportTeamQuota_ASC',
  SupportTeamQuotaDesc = 'supportTeamQuota_DESC',
  AthletesCallAsc = 'athletesCall_ASC',
  AthletesCallDesc = 'athletesCall_DESC',
  PointAsc = 'point_ASC',
  PointDesc = 'point_DESC',
  ScoreAsc = 'score_ASC',
  ScoreDesc = 'score_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IsNotifiedAsc = 'isNotified_ASC',
  IsNotifiedDesc = 'isNotified_DESC',
}

export type DirNationalTeam = {
  __typename?: 'DirNationalTeam';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified: Scalars['Boolean'];
  status: DirNationalTeamStatus;
  statusHistory?: Maybe<Array<DirNationalTeamStatusHistory>>;
  dirSportingEvent: DirSportingEvent;
  dirRegion: DirRegion;
  trainers?: Maybe<Array<DirNationalTeamTrainer>>;
  athleteGroups?: Maybe<Array<DirNationalTeamAthleteGroup>>;
  specialists?: Maybe<Array<DirNationalTeamMember>>;
  medics?: Maybe<Array<DirNationalTeamMember>>;
};

export type DirNationalTeamStatusHistoryArgs = {
  where?: Maybe<DirNationalTeamStatusHistoryWhereInput>;
  orderBy?: Maybe<DirNationalTeamStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamTrainersArgs = {
  where?: Maybe<DirNationalTeamTrainerWhereInput>;
  orderBy?: Maybe<DirNationalTeamTrainerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteGroupsArgs = {
  where?: Maybe<DirNationalTeamAthleteGroupWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamSpecialistsArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
  orderBy?: Maybe<DirNationalTeamMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamMedicsArgs = {
  where?: Maybe<DirNationalTeamMemberWhereInput>;
  orderBy?: Maybe<DirNationalTeamMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamStatus = {
  __typename?: 'DirNationalTeamStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirNationalTeamStatusHistoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DirNationalTeamStatusHistory = {
  __typename?: 'DirNationalTeamStatusHistory';
  id: Scalars['UUID'];
  date: Scalars['DateTime'];
  status: DirNationalTeamStatus;
  user: User;
};

export enum DirNationalTeamTrainerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
}

export type DirNationalTeamTrainer = {
  __typename?: 'DirNationalTeamTrainer';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  team: DirNationalTeam;
  dirTrainer: DirTrainer;
  clsPosition?: Maybe<ClassifierValue>;
};

export enum DirNationalTeamAthleteGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC',
}

export type DirNationalTeamAthleteGroup = {
  __typename?: 'DirNationalTeamAthleteGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team: DirNationalTeam;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<Array<ClassifierValue>>;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
  athletes?: Maybe<Array<DirNationalTeamAthlete>>;
};

export type DirNationalTeamAthleteGroupClsAgeGroupsArgs = {
  where?: Maybe<ClassifierValueWhereInput>;
  orderBy?: Maybe<ClassifierValueOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteGroupDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteGroupDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteGroupAthletesArgs = {
  where?: Maybe<DirNationalTeamAthleteWhereInput>;
  orderBy?: Maybe<DirNationalTeamAthleteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirNationalTeamAthleteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  BestResultAsc = 'bestResult_ASC',
  BestResultDesc = 'bestResult_DESC',
}

export type DirNationalTeamAthlete = {
  __typename?: 'DirNationalTeamAthlete';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  group: DirNationalTeamAthleteGroup;
  dirAthlete: DirAthlete;
  bestResult?: Maybe<Scalars['String']>;
};

export enum DirNationalTeamMemberOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  LastnameAsc = 'lastname_ASC',
  LastnameDesc = 'lastname_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  PatronymicAsc = 'patronymic_ASC',
  PatronymicDesc = 'patronymic_DESC',
  BirthdayAsc = 'birthday_ASC',
  BirthdayDesc = 'birthday_DESC',
}

export type DirNationalTeamMember = {
  __typename?: 'DirNationalTeamMember';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
  clsPosition?: Maybe<ClassifierValue>;
};

export type DirCountry = {
  __typename?: 'DirCountry';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirForeignCities?: Maybe<Array<DirForeignCity>>;
};

export type DirCountryDirForeignCitiesArgs = {
  where?: Maybe<DirForeignCityWhereInput>;
  orderBy?: Maybe<DirForeignCityOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirForeignCityOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
}

export type DirForeignCity = {
  __typename?: 'DirForeignCity';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirCountry: DirCountry;
};

export enum DirSportingEventOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  VenueAsc = 'venue_ASC',
  VenueDesc = 'venue_DESC',
  ObjectAsc = 'object_ASC',
  ObjectDesc = 'object_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  MinManAgeAsc = 'minManAge_ASC',
  MinManAgeDesc = 'minManAge_DESC',
  MaxManAgeAsc = 'maxManAge_ASC',
  MaxManAgeDesc = 'maxManAge_DESC',
  MinManBirthYearAsc = 'minManBirthYear_ASC',
  MinManBirthYearDesc = 'minManBirthYear_DESC',
  MaxManBirthYearAsc = 'maxManBirthYear_ASC',
  MaxManBirthYearDesc = 'maxManBirthYear_DESC',
  MinWomanAgeAsc = 'minWomanAge_ASC',
  MinWomanAgeDesc = 'minWomanAge_DESC',
  MaxWomanAgeAsc = 'maxWomanAge_ASC',
  MaxWomanAgeDesc = 'maxWomanAge_DESC',
  MinWomanBirthYearAsc = 'minWomanBirthYear_ASC',
  MinWomanBirthYearDesc = 'minWomanBirthYear_DESC',
  MaxWomanBirthYearAsc = 'maxWomanBirthYear_ASC',
  MaxWomanBirthYearDesc = 'maxWomanBirthYear_DESC',
  MembersQuotaAsc = 'membersQuota_ASC',
  MembersQuotaDesc = 'membersQuota_DESC',
  RefereesQuotaAsc = 'refereesQuota_ASC',
  RefereesQuotaDesc = 'refereesQuota_DESC',
  NonresidentRefereesQuotaAsc = 'nonresidentRefereesQuota_ASC',
  NonresidentRefereesQuotaDesc = 'nonresidentRefereesQuota_DESC',
  EkpAsc = 'ekp_ASC',
  EkpDesc = 'ekp_DESC',
  IsRussiaAsc = 'isRussia_ASC',
  IsRussiaDesc = 'isRussia_DESC',
  PlanRubAsc = 'planRUB_ASC',
  PlanRubDesc = 'planRUB_DESC',
  PlanRuBperPersonAsc = 'planRUBperPerson_ASC',
  PlanRuBperPersonDesc = 'planRUBperPerson_DESC',
  PlanUsdAsc = 'planUSD_ASC',
  PlanUsdDesc = 'planUSD_DESC',
  PlanUsDperPersonAsc = 'planUSDperPerson_ASC',
  PlanUsDperPersonDesc = 'planUSDperPerson_DESC',
  PlanDateAsc = 'planDate_ASC',
  PlanDateDesc = 'planDate_DESC',
  CurrentPlanRubAsc = 'currentPlanRUB_ASC',
  CurrentPlanRubDesc = 'currentPlanRUB_DESC',
  CurrentPlanRuBperPersonAsc = 'currentPlanRUBperPerson_ASC',
  CurrentPlanRuBperPersonDesc = 'currentPlanRUBperPerson_DESC',
  CurrentPlanUsdAsc = 'currentPlanUSD_ASC',
  CurrentPlanUsdDesc = 'currentPlanUSD_DESC',
  CurrentPlanUsDperPersonAsc = 'currentPlanUSDperPerson_ASC',
  CurrentPlanUsDperPersonDesc = 'currentPlanUSDperPerson_DESC',
  CurrentPlanDateAsc = 'currentPlanDate_ASC',
  CurrentPlanDateDesc = 'currentPlanDate_DESC',
  FactRubAsc = 'factRUB_ASC',
  FactRubDesc = 'factRUB_DESC',
  FactRuBperPersonAsc = 'factRUBperPerson_ASC',
  FactRuBperPersonDesc = 'factRUBperPerson_DESC',
  FactUsdAsc = 'factUSD_ASC',
  FactUsdDesc = 'factUSD_DESC',
  FactUsDperPersonAsc = 'factUSDperPerson_ASC',
  FactUsDperPersonDesc = 'factUSDperPerson_DESC',
  FactDateAsc = 'factDate_ASC',
  FactDateDesc = 'factDate_DESC',
  QuotaAthletesManAsc = 'quotaAthletesMan_ASC',
  QuotaAthletesManDesc = 'quotaAthletesMan_DESC',
  QuotaAthletesWomanAsc = 'quotaAthletesWoman_ASC',
  QuotaAthletesWomanDesc = 'quotaAthletesWoman_DESC',
  QuotaTrainersAsc = 'quotaTrainers_ASC',
  QuotaTrainersDesc = 'quotaTrainers_DESC',
  QuotaRefereesAsc = 'quotaReferees_ASC',
  QuotaRefereesDesc = 'quotaReferees_DESC',
  QuotaAdministrationAsc = 'quotaAdministration_ASC',
  QuotaAdministrationDesc = 'quotaAdministration_DESC',
  QuotaMedicalStaffAsc = 'quotaMedicalStaff_ASC',
  QuotaMedicalStaffDesc = 'quotaMedicalStaff_DESC',
  QuotaSpecialistsAsc = 'quotaSpecialists_ASC',
  QuotaSpecialistsDesc = 'quotaSpecialists_DESC',
  QuotaTeamsAsc = 'quotaTeams_ASC',
  QuotaTeamsDesc = 'quotaTeams_DESC',
  IsImportantAsc = 'isImportant_ASC',
  IsImportantDesc = 'isImportant_DESC',
  IsOlympicGamesPreparationAsc = 'isOlympicGamesPreparation_ASC',
  IsOlympicGamesPreparationDesc = 'isOlympicGamesPreparation_DESC',
  IsOrganizerOrParticipantAsc = 'isOrganizerOrParticipant_ASC',
  IsOrganizerOrParticipantDesc = 'isOrganizerOrParticipant_DESC',
}

export type DirSportingEventStatus = {
  __typename?: 'DirSportingEventStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum DirSportingEventStatusHistoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DirSportingEventStatusHistory = {
  __typename?: 'DirSportingEventStatusHistory';
  id: Scalars['UUID'];
  date: Scalars['DateTime'];
  status: DirSportingEventStatus;
  user: User;
};

export type DirCalendar = {
  __typename?: 'DirCalendar';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export enum DirSportingEventSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
}

export type DirSportingEventSport = {
  __typename?: 'DirSportingEventSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  dirSport: DirSport;
  disciplines?: Maybe<Array<DirSportDiscipline>>;
  disciplineGroups?: Maybe<Array<DirSportDisciplineGroup>>;
};

export type DirSportingEventSportDisciplinesArgs = {
  where?: Maybe<DirSportDisciplineWhereInput>;
  orderBy?: Maybe<DirSportDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirSportingEventSportDisciplineGroupsArgs = {
  where?: Maybe<DirSportDisciplineGroupWhereInput>;
  orderBy?: Maybe<DirSportDisciplineGroupOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventAgeGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC',
}

export type DirSportingEventAgeGroup = {
  __typename?: 'DirSportingEventAgeGroup';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  clsAgeGroup?: Maybe<ClassifierValue>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export enum DirSportingEventProgramTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  MinAgeAsc = 'minAge_ASC',
  MinAgeDesc = 'minAge_DESC',
  MaxAgeAsc = 'maxAge_ASC',
  MaxAgeDesc = 'maxAge_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
}

export enum DirSportingEventRefereeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  IsPrimaryAsc = 'isPrimary_ASC',
  IsPrimaryDesc = 'isPrimary_DESC',
}

export type DirSportingEventReferee = {
  __typename?: 'DirSportingEventReferee';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  dirReferee: DirReferee;
  isPrimary: Scalars['Boolean'];
  clsRefereePosition: ClassifierValue;
};

export type DirReferee = {
  __typename?: 'DirReferee';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  registryNumber: Scalars['Int'];
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  sports?: Maybe<Array<DirRefereeSport>>;
};

export type DirRefereeSportsArgs = {
  where?: Maybe<DirRefereeSportWhereInput>;
  orderBy?: Maybe<DirRefereeSportOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirRefereeSportOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  WorkExperienceAsc = 'workExperience_ASC',
  WorkExperienceDesc = 'workExperience_DESC',
}

export type DirRefereeSport = {
  __typename?: 'DirRefereeSport';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  archive?: Maybe<Archive>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirReferee;
  dirSport: DirSport;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<Array<DirRefereeSportCategory>>;
};

export type DirRefereeSportCategoriesArgs = {
  where?: Maybe<DirRefereeSportCategoryWhereInput>;
  orderBy?: Maybe<DirRefereeSportCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DirRefereeSportCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  AssignmentDateAsc = 'assignmentDate_ASC',
  AssignmentDateDesc = 'assignmentDate_DESC',
  ValidUntilAsc = 'validUntil_ASC',
  ValidUntilDesc = 'validUntil_DESC',
  IsEndlessAsc = 'isEndless_ASC',
  IsEndlessDesc = 'isEndless_DESC',
}

export type DirRefereeSportCategory = {
  __typename?: 'DirRefereeSportCategory';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirRefereeSport;
  clsRefereeCategory: ClassifierValue;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  dirDocument?: Maybe<DirDocument>;
};

export enum DirSportingEventCompetitionCalendarOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  DayAsc = 'day_ASC',
  DayDesc = 'day_DESC',
  TimeAsc = 'time_ASC',
  TimeDesc = 'time_DESC',
  MembersAsc = 'members_ASC',
  MembersDesc = 'members_DESC',
}

export type DirSportingEventCompetitionCalendar = {
  __typename?: 'DirSportingEventCompetitionCalendar';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEvent;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDiscipline>;
};

export enum DirDocumentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
}

export type DirPersonConfirmStatus = {
  __typename?: 'DirPersonConfirmStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum DirCountryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
}

export enum DirPersonPhoneOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  OperatorCodeAsc = 'operatorCode_ASC',
  OperatorCodeDesc = 'operatorCode_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  AdditionalNumberAsc = 'additionalNumber_ASC',
  AdditionalNumberDesc = 'additionalNumber_DESC',
}

export type DirPersonPhone = {
  __typename?: 'DirPersonPhone';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValue;
  dirPerson: DirPerson;
};

export enum DirPersonAdditionalRegionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  IsRussiaAsc = 'isRussia_ASC',
  IsRussiaDesc = 'isRussia_DESC',
}

export type DirPersonAdditionalRegion = {
  __typename?: 'DirPersonAdditionalRegion';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegion>;
  dirForeignCity?: Maybe<DirForeignCity>;
  dirCountry?: Maybe<DirCountry>;
  dirPerson: DirPerson;
};

export enum DirPersonMergeTaskOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
}

export type DirPersonMergeTask = {
  __typename?: 'DirPersonMergeTask';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  status: TaskStatus;
  comment?: Maybe<Scalars['String']>;
  primary: DirPerson;
  duplicate: DirPerson;
};

export type TaskStatus = {
  __typename?: 'TaskStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum DirPersonAddressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  StreetAsc = 'street_ASC',
  StreetDesc = 'street_DESC',
  HouseAsc = 'house_ASC',
  HouseDesc = 'house_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
}

export type DirPersonAddress = {
  __typename?: 'DirPersonAddress';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPerson;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type RegionalMinsportProfile = {
  __typename?: 'RegionalMinsportProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  dirRegion?: Maybe<DirRegion>;
  dirFederalDistrict?: Maybe<DirFederalDistrict>;
};

export type RegionalSchoolProfile = {
  __typename?: 'RegionalSchoolProfile';
  id: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization: DirOrganization;
};

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
}

export type UserRoleWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export enum UserRoleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CanSelectAsc = 'canSelect_ASC',
  CanSelectDesc = 'canSelect_DESC',
}

export type RegularUserWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  phone?: Maybe<Scalars['String']>;
};

export enum RegularUserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  LastnameAsc = 'lastname_ASC',
  LastnameDesc = 'lastname_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  PatronymicAsc = 'patronymic_ASC',
  PatronymicDesc = 'patronymic_DESC',
  BirthdayAsc = 'birthday_ASC',
  BirthdayDesc = 'birthday_DESC',
  IsMaleAsc = 'isMale_ASC',
  IsMaleDesc = 'isMale_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
}

export type SystemUserWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum SystemUserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RegionalMinsportProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum RegionalMinsportProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RegionalSchoolProfileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum RegionalSchoolProfileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
}

export type FieldHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type ArchiveWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export enum ArchiveOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
}

export type ClassifierWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum ClassifierOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type ClassifierValueWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
};

export enum DirFederalDistrictOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
}

export type DirForeignCityWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirCountryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
};

export type DirOrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  ogrn?: Maybe<Scalars['String']>;
};

export type DirOrganizationSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirOrganizationAddressWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirOrganizationPhoneWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirOrganizationEmailWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirPersonWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};

export type DirPersonPhoneWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirPersonConfirmStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirPersonConfirmStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DirPersonAdditionalRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirPersonMergeTaskWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirPersonAddressWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportDisciplineGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportAgeGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportTrainingStageWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportBaseRegionWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export enum DirAthleteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
}

export type DirAthleteSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportOrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportRankAndAwardWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteMedCertWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
};

export type DirAthleteMedCertSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteCompetitionResultWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerSportOrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerSportCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirRefereeWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export enum DirRefereeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
}

export type DirRefereeSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirRefereeSportCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventSportWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventAgeGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirSportingEventStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DirSportingEventStatusHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirSportingEventProgramTypeWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventRefereeWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCompetitionCalendarWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum DirNationalTeamStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DirNationalTeamStatusHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
};

export type DirNationalTeamTrainerWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteGroupWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamMemberWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirCalendarWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
};

export enum DirCalendarOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  RegistryNumberAsc = 'registryNumber_ASC',
  RegistryNumberDesc = 'registryNumber_DESC',
  IasKeyAsc = 'iasKey_ASC',
  IasKeyDesc = 'iasKey_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  ShortNameAsc = 'shortName_ASC',
  ShortNameDesc = 'shortName_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
}

export type DirDocumentWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type AddressTypeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum AddressTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TaskStatusWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum TaskStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['UUID']>;
  path?: Maybe<Scalars['String']>;
};

export enum FileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
}

export type SimilarUser = {
  __typename?: 'SimilarUser';
  user: User;
  similarity: Scalars['Float'];
};

export type SimilarPerson = {
  __typename?: 'SimilarPerson';
  person: DirPerson;
  similarity: Scalars['Float'];
};

export type SimilarOrganization = {
  __typename?: 'SimilarOrganization';
  organization: DirOrganization;
  similarity: Scalars['Float'];
};

export type OrganizationSearchResultType = {
  __typename?: 'OrganizationSearchResultType';
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
};

export type PublicAthleteMedCert = {
  __typename?: 'PublicAthleteMedCert';
  athlete: PublicAthleteInfo;
  medCert: PublicMedCertInfo;
};

export type PublicAthleteInfo = {
  __typename?: 'PublicAthleteInfo';
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  archive?: Maybe<Scalars['String']>;
};

export type PublicMedCertInfo = {
  __typename?: 'PublicMedCertInfo';
  issueDate: Scalars['DateTime'];
  validUntil: Scalars['DateTime'];
  organizationName: Scalars['String'];
  sports: Array<Scalars['String']>;
  trainingStage: Scalars['String'];
  isValid: Scalars['Boolean'];
};

export type DirSportReport = {
  __typename?: 'DirSportReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};

export type DirSportReportXlsxArgs = {
  where?: Maybe<DirSportWhereInput>;
  orderBy?: Maybe<DirSportOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  evskAlias: Scalars['String'];
  fullNameAlias: Scalars['String'];
  archiveAlias: Scalars['String'];
};

export type DirAthleteReport = {
  __typename?: 'DirAthleteReport';
  /** Документ для печати в формате "xlsx" */
  xlsx: Scalars['String'];
};

export type DirAthleteReportXlsxArgs = {
  where?: Maybe<DirAthleteWhereInput>;
  orderBy?: Maybe<DirAthleteOrderByInput>;
  first?: Maybe<Scalars['Int']>;
  filter: Scalars['String'];
  registryNumberAlias: Scalars['String'];
  fullNameAlias: Scalars['String'];
  birthdayAlias: Scalars['String'];
  genderAlias: Scalars['String'];
  regionAlias: Scalars['String'];
  sportAlias: Scalars['String'];
  organizationAlias: Scalars['String'];
  trainingStagesAlias: Scalars['String'];
  trainersAlias: Scalars['String'];
  archiveAlias: Scalars['String'];
};

export type Me = {
  __typename?: 'Me';
  id: Scalars['ID'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  role: UserRole;
  personProfile?: Maybe<DirPerson>;
  regionalMinsportProfile: RegionalMinsportProfile;
  regionalSchoolProfile: RegionalSchoolProfile;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser?: Maybe<User>;
  upsertUser: User;
  deleteUser?: Maybe<User>;
  createUserRole: UserRole;
  updateUserRole?: Maybe<UserRole>;
  upsertUserRole: UserRole;
  deleteUserRole?: Maybe<UserRole>;
  createRegularUser: RegularUser;
  updateRegularUser?: Maybe<RegularUser>;
  upsertRegularUser: RegularUser;
  deleteRegularUser?: Maybe<RegularUser>;
  createSystemUser: SystemUser;
  updateSystemUser?: Maybe<SystemUser>;
  upsertSystemUser: SystemUser;
  deleteSystemUser?: Maybe<SystemUser>;
  createRegionalMinsportProfile: RegionalMinsportProfile;
  updateRegionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  upsertRegionalMinsportProfile: RegionalMinsportProfile;
  deleteRegionalMinsportProfile?: Maybe<RegionalMinsportProfile>;
  createRegionalSchoolProfile: RegionalSchoolProfile;
  updateRegionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  upsertRegionalSchoolProfile: RegionalSchoolProfile;
  deleteRegionalSchoolProfile?: Maybe<RegionalSchoolProfile>;
  createFieldHistory: FieldHistory;
  updateFieldHistory?: Maybe<FieldHistory>;
  upsertFieldHistory: FieldHistory;
  deleteFieldHistory?: Maybe<FieldHistory>;
  createArchive: Archive;
  updateArchive?: Maybe<Archive>;
  upsertArchive: Archive;
  deleteArchive?: Maybe<Archive>;
  createClassifier: Classifier;
  updateClassifier?: Maybe<Classifier>;
  upsertClassifier: Classifier;
  deleteClassifier?: Maybe<Classifier>;
  createClassifierValue: ClassifierValue;
  updateClassifierValue?: Maybe<ClassifierValue>;
  upsertClassifierValue: ClassifierValue;
  deleteClassifierValue?: Maybe<ClassifierValue>;
  createDirRegion: DirRegion;
  updateDirRegion?: Maybe<DirRegion>;
  upsertDirRegion: DirRegion;
  deleteDirRegion?: Maybe<DirRegion>;
  createDirFederalDistrict: DirFederalDistrict;
  updateDirFederalDistrict?: Maybe<DirFederalDistrict>;
  upsertDirFederalDistrict: DirFederalDistrict;
  deleteDirFederalDistrict?: Maybe<DirFederalDistrict>;
  createDirForeignCity: DirForeignCity;
  updateDirForeignCity?: Maybe<DirForeignCity>;
  upsertDirForeignCity: DirForeignCity;
  deleteDirForeignCity?: Maybe<DirForeignCity>;
  createDirCountry: DirCountry;
  updateDirCountry?: Maybe<DirCountry>;
  upsertDirCountry: DirCountry;
  deleteDirCountry?: Maybe<DirCountry>;
  createDirOrganization: DirOrganization;
  updateDirOrganization?: Maybe<DirOrganization>;
  upsertDirOrganization: DirOrganization;
  deleteDirOrganization?: Maybe<DirOrganization>;
  createDirOrganizationSport: DirOrganizationSport;
  updateDirOrganizationSport?: Maybe<DirOrganizationSport>;
  upsertDirOrganizationSport: DirOrganizationSport;
  deleteDirOrganizationSport?: Maybe<DirOrganizationSport>;
  createDirOrganizationAddress: DirOrganizationAddress;
  updateDirOrganizationAddress?: Maybe<DirOrganizationAddress>;
  upsertDirOrganizationAddress: DirOrganizationAddress;
  deleteDirOrganizationAddress?: Maybe<DirOrganizationAddress>;
  createDirOrganizationPhone: DirOrganizationPhone;
  updateDirOrganizationPhone?: Maybe<DirOrganizationPhone>;
  upsertDirOrganizationPhone: DirOrganizationPhone;
  deleteDirOrganizationPhone?: Maybe<DirOrganizationPhone>;
  createDirOrganizationEmail: DirOrganizationEmail;
  updateDirOrganizationEmail?: Maybe<DirOrganizationEmail>;
  upsertDirOrganizationEmail: DirOrganizationEmail;
  deleteDirOrganizationEmail?: Maybe<DirOrganizationEmail>;
  createDirPerson: DirPerson;
  updateDirPerson?: Maybe<DirPerson>;
  upsertDirPerson: DirPerson;
  deleteDirPerson?: Maybe<DirPerson>;
  createDirPersonPhone: DirPersonPhone;
  updateDirPersonPhone?: Maybe<DirPersonPhone>;
  upsertDirPersonPhone: DirPersonPhone;
  deleteDirPersonPhone?: Maybe<DirPersonPhone>;
  createDirPersonConfirmStatus: DirPersonConfirmStatus;
  updateDirPersonConfirmStatus?: Maybe<DirPersonConfirmStatus>;
  upsertDirPersonConfirmStatus: DirPersonConfirmStatus;
  deleteDirPersonConfirmStatus?: Maybe<DirPersonConfirmStatus>;
  createDirPersonAdditionalRegion: DirPersonAdditionalRegion;
  updateDirPersonAdditionalRegion?: Maybe<DirPersonAdditionalRegion>;
  upsertDirPersonAdditionalRegion: DirPersonAdditionalRegion;
  deleteDirPersonAdditionalRegion?: Maybe<DirPersonAdditionalRegion>;
  createDirPersonMergeTask: DirPersonMergeTask;
  updateDirPersonMergeTask?: Maybe<DirPersonMergeTask>;
  upsertDirPersonMergeTask: DirPersonMergeTask;
  deleteDirPersonMergeTask?: Maybe<DirPersonMergeTask>;
  createDirPersonAddress: DirPersonAddress;
  updateDirPersonAddress?: Maybe<DirPersonAddress>;
  upsertDirPersonAddress: DirPersonAddress;
  deleteDirPersonAddress?: Maybe<DirPersonAddress>;
  createDirSport: DirSport;
  updateDirSport?: Maybe<DirSport>;
  upsertDirSport: DirSport;
  deleteDirSport?: Maybe<DirSport>;
  createDirSportDiscipline: DirSportDiscipline;
  updateDirSportDiscipline?: Maybe<DirSportDiscipline>;
  upsertDirSportDiscipline: DirSportDiscipline;
  deleteDirSportDiscipline?: Maybe<DirSportDiscipline>;
  createDirSportDisciplineGroup: DirSportDisciplineGroup;
  updateDirSportDisciplineGroup?: Maybe<DirSportDisciplineGroup>;
  upsertDirSportDisciplineGroup: DirSportDisciplineGroup;
  deleteDirSportDisciplineGroup?: Maybe<DirSportDisciplineGroup>;
  createDirSportAgeGroup: DirSportAgeGroup;
  updateDirSportAgeGroup?: Maybe<DirSportAgeGroup>;
  upsertDirSportAgeGroup: DirSportAgeGroup;
  deleteDirSportAgeGroup?: Maybe<DirSportAgeGroup>;
  createDirSportTrainingStage: DirSportTrainingStage;
  updateDirSportTrainingStage?: Maybe<DirSportTrainingStage>;
  upsertDirSportTrainingStage: DirSportTrainingStage;
  deleteDirSportTrainingStage?: Maybe<DirSportTrainingStage>;
  createDirSportBaseRegion: DirSportBaseRegion;
  updateDirSportBaseRegion?: Maybe<DirSportBaseRegion>;
  upsertDirSportBaseRegion: DirSportBaseRegion;
  deleteDirSportBaseRegion?: Maybe<DirSportBaseRegion>;
  createDirAthlete: DirAthlete;
  updateDirAthlete?: Maybe<DirAthlete>;
  upsertDirAthlete: DirAthlete;
  deleteDirAthlete?: Maybe<DirAthlete>;
  createDirAthleteSport: DirAthleteSport;
  updateDirAthleteSport?: Maybe<DirAthleteSport>;
  upsertDirAthleteSport: DirAthleteSport;
  deleteDirAthleteSport?: Maybe<DirAthleteSport>;
  createDirAthleteSportOrganization: DirAthleteSportOrganization;
  updateDirAthleteSportOrganization?: Maybe<DirAthleteSportOrganization>;
  upsertDirAthleteSportOrganization: DirAthleteSportOrganization;
  deleteDirAthleteSportOrganization?: Maybe<DirAthleteSportOrganization>;
  createDirAthleteSportRankAndAward: DirAthleteSportRankAndAward;
  updateDirAthleteSportRankAndAward?: Maybe<DirAthleteSportRankAndAward>;
  upsertDirAthleteSportRankAndAward: DirAthleteSportRankAndAward;
  deleteDirAthleteSportRankAndAward?: Maybe<DirAthleteSportRankAndAward>;
  createDirAthleteMedCert: DirAthleteMedCert;
  updateDirAthleteMedCert?: Maybe<DirAthleteMedCert>;
  upsertDirAthleteMedCert: DirAthleteMedCert;
  deleteDirAthleteMedCert?: Maybe<DirAthleteMedCert>;
  createDirAthleteMedCertSport: DirAthleteMedCertSport;
  updateDirAthleteMedCertSport?: Maybe<DirAthleteMedCertSport>;
  upsertDirAthleteMedCertSport: DirAthleteMedCertSport;
  deleteDirAthleteMedCertSport?: Maybe<DirAthleteMedCertSport>;
  createDirAthleteCompetitionResult: DirAthleteCompetitionResult;
  updateDirAthleteCompetitionResult?: Maybe<DirAthleteCompetitionResult>;
  upsertDirAthleteCompetitionResult: DirAthleteCompetitionResult;
  deleteDirAthleteCompetitionResult?: Maybe<DirAthleteCompetitionResult>;
  createDirTrainer: DirTrainer;
  updateDirTrainer?: Maybe<DirTrainer>;
  upsertDirTrainer: DirTrainer;
  deleteDirTrainer?: Maybe<DirTrainer>;
  createDirTrainerSport: DirTrainerSport;
  updateDirTrainerSport?: Maybe<DirTrainerSport>;
  upsertDirTrainerSport: DirTrainerSport;
  deleteDirTrainerSport?: Maybe<DirTrainerSport>;
  createDirTrainerSportOrganization: DirTrainerSportOrganization;
  updateDirTrainerSportOrganization?: Maybe<DirTrainerSportOrganization>;
  upsertDirTrainerSportOrganization: DirTrainerSportOrganization;
  deleteDirTrainerSportOrganization?: Maybe<DirTrainerSportOrganization>;
  createDirTrainerSportCategory: DirTrainerSportCategory;
  updateDirTrainerSportCategory?: Maybe<DirTrainerSportCategory>;
  upsertDirTrainerSportCategory: DirTrainerSportCategory;
  deleteDirTrainerSportCategory?: Maybe<DirTrainerSportCategory>;
  createDirReferee: DirReferee;
  updateDirReferee?: Maybe<DirReferee>;
  upsertDirReferee: DirReferee;
  deleteDirReferee?: Maybe<DirReferee>;
  createDirRefereeSport: DirRefereeSport;
  updateDirRefereeSport?: Maybe<DirRefereeSport>;
  upsertDirRefereeSport: DirRefereeSport;
  deleteDirRefereeSport?: Maybe<DirRefereeSport>;
  createDirRefereeSportCategory: DirRefereeSportCategory;
  updateDirRefereeSportCategory?: Maybe<DirRefereeSportCategory>;
  upsertDirRefereeSportCategory: DirRefereeSportCategory;
  deleteDirRefereeSportCategory?: Maybe<DirRefereeSportCategory>;
  createDirSportingEvent: DirSportingEvent;
  updateDirSportingEvent?: Maybe<DirSportingEvent>;
  upsertDirSportingEvent: DirSportingEvent;
  deleteDirSportingEvent?: Maybe<DirSportingEvent>;
  createDirSportingEventSport: DirSportingEventSport;
  updateDirSportingEventSport?: Maybe<DirSportingEventSport>;
  upsertDirSportingEventSport: DirSportingEventSport;
  deleteDirSportingEventSport?: Maybe<DirSportingEventSport>;
  createDirSportingEventAgeGroup: DirSportingEventAgeGroup;
  updateDirSportingEventAgeGroup?: Maybe<DirSportingEventAgeGroup>;
  upsertDirSportingEventAgeGroup: DirSportingEventAgeGroup;
  deleteDirSportingEventAgeGroup?: Maybe<DirSportingEventAgeGroup>;
  createDirSportingEventStatus: DirSportingEventStatus;
  updateDirSportingEventStatus?: Maybe<DirSportingEventStatus>;
  upsertDirSportingEventStatus: DirSportingEventStatus;
  deleteDirSportingEventStatus?: Maybe<DirSportingEventStatus>;
  createDirSportingEventStatusHistory: DirSportingEventStatusHistory;
  updateDirSportingEventStatusHistory?: Maybe<DirSportingEventStatusHistory>;
  upsertDirSportingEventStatusHistory: DirSportingEventStatusHistory;
  deleteDirSportingEventStatusHistory?: Maybe<DirSportingEventStatusHistory>;
  createDirSportingEventProgramType: DirSportingEventProgramType;
  updateDirSportingEventProgramType?: Maybe<DirSportingEventProgramType>;
  upsertDirSportingEventProgramType: DirSportingEventProgramType;
  deleteDirSportingEventProgramType?: Maybe<DirSportingEventProgramType>;
  createDirSportingEventReferee: DirSportingEventReferee;
  updateDirSportingEventReferee?: Maybe<DirSportingEventReferee>;
  upsertDirSportingEventReferee: DirSportingEventReferee;
  deleteDirSportingEventReferee?: Maybe<DirSportingEventReferee>;
  createDirSportingEventCompetitionCalendar: DirSportingEventCompetitionCalendar;
  updateDirSportingEventCompetitionCalendar?: Maybe<DirSportingEventCompetitionCalendar>;
  upsertDirSportingEventCompetitionCalendar: DirSportingEventCompetitionCalendar;
  deleteDirSportingEventCompetitionCalendar?: Maybe<DirSportingEventCompetitionCalendar>;
  createDirNationalTeam: DirNationalTeam;
  updateDirNationalTeam?: Maybe<DirNationalTeam>;
  upsertDirNationalTeam: DirNationalTeam;
  deleteDirNationalTeam?: Maybe<DirNationalTeam>;
  createDirNationalTeamStatus: DirNationalTeamStatus;
  updateDirNationalTeamStatus?: Maybe<DirNationalTeamStatus>;
  upsertDirNationalTeamStatus: DirNationalTeamStatus;
  deleteDirNationalTeamStatus?: Maybe<DirNationalTeamStatus>;
  createDirNationalTeamStatusHistory: DirNationalTeamStatusHistory;
  updateDirNationalTeamStatusHistory?: Maybe<DirNationalTeamStatusHistory>;
  upsertDirNationalTeamStatusHistory: DirNationalTeamStatusHistory;
  deleteDirNationalTeamStatusHistory?: Maybe<DirNationalTeamStatusHistory>;
  createDirNationalTeamTrainer: DirNationalTeamTrainer;
  updateDirNationalTeamTrainer?: Maybe<DirNationalTeamTrainer>;
  upsertDirNationalTeamTrainer: DirNationalTeamTrainer;
  deleteDirNationalTeamTrainer?: Maybe<DirNationalTeamTrainer>;
  createDirNationalTeamAthleteGroup: DirNationalTeamAthleteGroup;
  updateDirNationalTeamAthleteGroup?: Maybe<DirNationalTeamAthleteGroup>;
  upsertDirNationalTeamAthleteGroup: DirNationalTeamAthleteGroup;
  deleteDirNationalTeamAthleteGroup?: Maybe<DirNationalTeamAthleteGroup>;
  createDirNationalTeamAthlete: DirNationalTeamAthlete;
  updateDirNationalTeamAthlete?: Maybe<DirNationalTeamAthlete>;
  upsertDirNationalTeamAthlete: DirNationalTeamAthlete;
  deleteDirNationalTeamAthlete?: Maybe<DirNationalTeamAthlete>;
  createDirNationalTeamMember: DirNationalTeamMember;
  updateDirNationalTeamMember?: Maybe<DirNationalTeamMember>;
  upsertDirNationalTeamMember: DirNationalTeamMember;
  deleteDirNationalTeamMember?: Maybe<DirNationalTeamMember>;
  createDirCalendar: DirCalendar;
  updateDirCalendar?: Maybe<DirCalendar>;
  upsertDirCalendar: DirCalendar;
  deleteDirCalendar?: Maybe<DirCalendar>;
  createDirDocument: DirDocument;
  updateDirDocument?: Maybe<DirDocument>;
  upsertDirDocument: DirDocument;
  deleteDirDocument?: Maybe<DirDocument>;
  createAddressType: AddressType;
  updateAddressType?: Maybe<AddressType>;
  upsertAddressType: AddressType;
  deleteAddressType?: Maybe<AddressType>;
  createTaskStatus: TaskStatus;
  updateTaskStatus?: Maybe<TaskStatus>;
  upsertTaskStatus: TaskStatus;
  deleteTaskStatus?: Maybe<TaskStatus>;
  createFile: File;
  updateFile?: Maybe<File>;
  upsertFile: File;
  deleteFile?: Maybe<File>;
  /** Регистрация нового пользователя */
  signup: AuthPayload;
  /** Вход */
  signin: AuthPayload;
  /** Сброс пароля */
  publicResetPassword: Scalars['Boolean'];
  /** Активация пользователя */
  activateUser: User;
  /** Деактивация пользователя */
  deactivateUser: User;
  /** Смена роли пользователя */
  changeUserRole: User;
  /** Смена email пользователя */
  changeUserEmail: User;
  /** Сброс пароля пользователя */
  resetUserPassword: User;
  /** Удаление аккаунта пользователя */
  deleteUserAccount: Scalars['Boolean'];
  /** Отзыв токена пользователя */
  revokeToken: User;
  /** Отзыв всех токенов пользователя */
  revokeAllTokens: User;
  /** Объединение персон */
  mergePerson?: Maybe<DirPerson>;
  /** Создать задание объединения Персон */
  openMergePersonTask: DirPersonMergeTask;
  /** Выполнить и закрыть задание объединения Персон */
  closeMergePersonTask: DirPersonMergeTask;
  /** Отменить задание объединения Персон */
  cancelMergePersonTask: DirPersonMergeTask;
  /** Упольнение Тренера и перевод Спортсменов на самоподготовку */
  trainerDismissalWithAthletesSelfTraining: Scalars['Boolean'];
  /** Упольнение Тренера и отчисление Спортсменов */
  trainerDismissalWithAthletesExpel: Scalars['Boolean'];
  /** Упольнение Тренера и перевод Спортсменов другому Тренеру */
  trainerDismissalWithAthletesAssignToAnotherTrainer: Scalars['Boolean'];
  /** Исключение Спортсменов */
  athletesExpel: Scalars['Boolean'];
  /** Исключение Спортсменов с переводом к другому Тренеру */
  athletesExpelWithMoveToAnotherTrainer: Scalars['Boolean'];
  /** Исключение Спортсменов с переводом на другой Этап подготовки */
  athletesExpelWithMoveToAnotherTrainingStage: Scalars['Boolean'];
  /** Исключение Спортсменов с переводом на самоподготовку */
  athletesExpelWithMoveToSelfTraining: Scalars['Boolean'];
  /** Загрузка файла */
  uploadFile?: Maybe<File>;
};

export type MutationCreateUserArgs = {
  data: UserCreateInput;
};

export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpsertUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};

export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationCreateUserRoleArgs = {
  data: UserRoleCreateInput;
};

export type MutationUpdateUserRoleArgs = {
  data: UserRoleUpdateInput;
  where: UserRoleWhereUniqueInput;
};

export type MutationUpsertUserRoleArgs = {
  where: UserRoleWhereUniqueInput;
  create: UserRoleCreateInput;
  update: UserRoleUpdateInput;
};

export type MutationDeleteUserRoleArgs = {
  where: UserRoleWhereUniqueInput;
};

export type MutationCreateRegularUserArgs = {
  data: RegularUserCreateInput;
};

export type MutationUpdateRegularUserArgs = {
  data: RegularUserUpdateInput;
  where: RegularUserWhereUniqueInput;
};

export type MutationUpsertRegularUserArgs = {
  where: RegularUserWhereUniqueInput;
  create: RegularUserCreateInput;
  update: RegularUserUpdateInput;
};

export type MutationDeleteRegularUserArgs = {
  where: RegularUserWhereUniqueInput;
};

export type MutationCreateSystemUserArgs = {
  data: SystemUserCreateInput;
};

export type MutationUpdateSystemUserArgs = {
  data: SystemUserUpdateInput;
  where: SystemUserWhereUniqueInput;
};

export type MutationUpsertSystemUserArgs = {
  where: SystemUserWhereUniqueInput;
  create: SystemUserCreateInput;
  update: SystemUserUpdateInput;
};

export type MutationDeleteSystemUserArgs = {
  where: SystemUserWhereUniqueInput;
};

export type MutationCreateRegionalMinsportProfileArgs = {
  data: RegionalMinsportProfileCreateInput;
};

export type MutationUpdateRegionalMinsportProfileArgs = {
  data: RegionalMinsportProfileUpdateInput;
  where: RegionalMinsportProfileWhereUniqueInput;
};

export type MutationUpsertRegionalMinsportProfileArgs = {
  where: RegionalMinsportProfileWhereUniqueInput;
  create: RegionalMinsportProfileCreateInput;
  update: RegionalMinsportProfileUpdateInput;
};

export type MutationDeleteRegionalMinsportProfileArgs = {
  where: RegionalMinsportProfileWhereUniqueInput;
};

export type MutationCreateRegionalSchoolProfileArgs = {
  data: RegionalSchoolProfileCreateInput;
};

export type MutationUpdateRegionalSchoolProfileArgs = {
  data: RegionalSchoolProfileUpdateInput;
  where: RegionalSchoolProfileWhereUniqueInput;
};

export type MutationUpsertRegionalSchoolProfileArgs = {
  where: RegionalSchoolProfileWhereUniqueInput;
  create: RegionalSchoolProfileCreateInput;
  update: RegionalSchoolProfileUpdateInput;
};

export type MutationDeleteRegionalSchoolProfileArgs = {
  where: RegionalSchoolProfileWhereUniqueInput;
};

export type MutationCreateFieldHistoryArgs = {
  data: FieldHistoryCreateInput;
};

export type MutationUpdateFieldHistoryArgs = {
  data: FieldHistoryUpdateInput;
  where: FieldHistoryWhereUniqueInput;
};

export type MutationUpsertFieldHistoryArgs = {
  where: FieldHistoryWhereUniqueInput;
  create: FieldHistoryCreateInput;
  update: FieldHistoryUpdateInput;
};

export type MutationDeleteFieldHistoryArgs = {
  where: FieldHistoryWhereUniqueInput;
};

export type MutationCreateArchiveArgs = {
  data: ArchiveCreateInput;
};

export type MutationUpdateArchiveArgs = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MutationUpsertArchiveArgs = {
  where: ArchiveWhereUniqueInput;
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
};

export type MutationDeleteArchiveArgs = {
  where: ArchiveWhereUniqueInput;
};

export type MutationCreateClassifierArgs = {
  data: ClassifierCreateInput;
};

export type MutationUpdateClassifierArgs = {
  data: ClassifierUpdateInput;
  where: ClassifierWhereUniqueInput;
};

export type MutationUpsertClassifierArgs = {
  where: ClassifierWhereUniqueInput;
  create: ClassifierCreateInput;
  update: ClassifierUpdateInput;
};

export type MutationDeleteClassifierArgs = {
  where: ClassifierWhereUniqueInput;
};

export type MutationCreateClassifierValueArgs = {
  data: ClassifierValueCreateInput;
};

export type MutationUpdateClassifierValueArgs = {
  data: ClassifierValueUpdateInput;
  where: ClassifierValueWhereUniqueInput;
};

export type MutationUpsertClassifierValueArgs = {
  where: ClassifierValueWhereUniqueInput;
  create: ClassifierValueCreateInput;
  update: ClassifierValueUpdateInput;
};

export type MutationDeleteClassifierValueArgs = {
  where: ClassifierValueWhereUniqueInput;
};

export type MutationCreateDirRegionArgs = {
  data: DirRegionCreateInput;
};

export type MutationUpdateDirRegionArgs = {
  data: DirRegionUpdateInput;
  where: DirRegionWhereUniqueInput;
};

export type MutationUpsertDirRegionArgs = {
  where: DirRegionWhereUniqueInput;
  create: DirRegionCreateInput;
  update: DirRegionUpdateInput;
};

export type MutationDeleteDirRegionArgs = {
  where: DirRegionWhereUniqueInput;
};

export type MutationCreateDirFederalDistrictArgs = {
  data: DirFederalDistrictCreateInput;
};

export type MutationUpdateDirFederalDistrictArgs = {
  data: DirFederalDistrictUpdateInput;
  where: DirFederalDistrictWhereUniqueInput;
};

export type MutationUpsertDirFederalDistrictArgs = {
  where: DirFederalDistrictWhereUniqueInput;
  create: DirFederalDistrictCreateInput;
  update: DirFederalDistrictUpdateInput;
};

export type MutationDeleteDirFederalDistrictArgs = {
  where: DirFederalDistrictWhereUniqueInput;
};

export type MutationCreateDirForeignCityArgs = {
  data: DirForeignCityCreateInput;
};

export type MutationUpdateDirForeignCityArgs = {
  data: DirForeignCityUpdateInput;
  where: DirForeignCityWhereUniqueInput;
};

export type MutationUpsertDirForeignCityArgs = {
  where: DirForeignCityWhereUniqueInput;
  create: DirForeignCityCreateInput;
  update: DirForeignCityUpdateInput;
};

export type MutationDeleteDirForeignCityArgs = {
  where: DirForeignCityWhereUniqueInput;
};

export type MutationCreateDirCountryArgs = {
  data: DirCountryCreateInput;
};

export type MutationUpdateDirCountryArgs = {
  data: DirCountryUpdateInput;
  where: DirCountryWhereUniqueInput;
};

export type MutationUpsertDirCountryArgs = {
  where: DirCountryWhereUniqueInput;
  create: DirCountryCreateInput;
  update: DirCountryUpdateInput;
};

export type MutationDeleteDirCountryArgs = {
  where: DirCountryWhereUniqueInput;
};

export type MutationCreateDirOrganizationArgs = {
  data: DirOrganizationCreateInput;
};

export type MutationUpdateDirOrganizationArgs = {
  data: DirOrganizationUpdateInput;
  where: DirOrganizationWhereUniqueInput;
};

export type MutationUpsertDirOrganizationArgs = {
  where: DirOrganizationWhereUniqueInput;
  create: DirOrganizationCreateInput;
  update: DirOrganizationUpdateInput;
};

export type MutationDeleteDirOrganizationArgs = {
  where: DirOrganizationWhereUniqueInput;
};

export type MutationCreateDirOrganizationSportArgs = {
  data: DirOrganizationSportCreateInput;
};

export type MutationUpdateDirOrganizationSportArgs = {
  data: DirOrganizationSportUpdateInput;
  where: DirOrganizationSportWhereUniqueInput;
};

export type MutationUpsertDirOrganizationSportArgs = {
  where: DirOrganizationSportWhereUniqueInput;
  create: DirOrganizationSportCreateInput;
  update: DirOrganizationSportUpdateInput;
};

export type MutationDeleteDirOrganizationSportArgs = {
  where: DirOrganizationSportWhereUniqueInput;
};

export type MutationCreateDirOrganizationAddressArgs = {
  data: DirOrganizationAddressCreateInput;
};

export type MutationUpdateDirOrganizationAddressArgs = {
  data: DirOrganizationAddressUpdateInput;
  where: DirOrganizationAddressWhereUniqueInput;
};

export type MutationUpsertDirOrganizationAddressArgs = {
  where: DirOrganizationAddressWhereUniqueInput;
  create: DirOrganizationAddressCreateInput;
  update: DirOrganizationAddressUpdateInput;
};

export type MutationDeleteDirOrganizationAddressArgs = {
  where: DirOrganizationAddressWhereUniqueInput;
};

export type MutationCreateDirOrganizationPhoneArgs = {
  data: DirOrganizationPhoneCreateInput;
};

export type MutationUpdateDirOrganizationPhoneArgs = {
  data: DirOrganizationPhoneUpdateInput;
  where: DirOrganizationPhoneWhereUniqueInput;
};

export type MutationUpsertDirOrganizationPhoneArgs = {
  where: DirOrganizationPhoneWhereUniqueInput;
  create: DirOrganizationPhoneCreateInput;
  update: DirOrganizationPhoneUpdateInput;
};

export type MutationDeleteDirOrganizationPhoneArgs = {
  where: DirOrganizationPhoneWhereUniqueInput;
};

export type MutationCreateDirOrganizationEmailArgs = {
  data: DirOrganizationEmailCreateInput;
};

export type MutationUpdateDirOrganizationEmailArgs = {
  data: DirOrganizationEmailUpdateInput;
  where: DirOrganizationEmailWhereUniqueInput;
};

export type MutationUpsertDirOrganizationEmailArgs = {
  where: DirOrganizationEmailWhereUniqueInput;
  create: DirOrganizationEmailCreateInput;
  update: DirOrganizationEmailUpdateInput;
};

export type MutationDeleteDirOrganizationEmailArgs = {
  where: DirOrganizationEmailWhereUniqueInput;
};

export type MutationCreateDirPersonArgs = {
  data: DirPersonCreateInput;
};

export type MutationUpdateDirPersonArgs = {
  data: DirPersonUpdateInput;
  where: DirPersonWhereUniqueInput;
};

export type MutationUpsertDirPersonArgs = {
  where: DirPersonWhereUniqueInput;
  create: DirPersonCreateInput;
  update: DirPersonUpdateInput;
};

export type MutationDeleteDirPersonArgs = {
  where: DirPersonWhereUniqueInput;
};

export type MutationCreateDirPersonPhoneArgs = {
  data: DirPersonPhoneCreateInput;
};

export type MutationUpdateDirPersonPhoneArgs = {
  data: DirPersonPhoneUpdateInput;
  where: DirPersonPhoneWhereUniqueInput;
};

export type MutationUpsertDirPersonPhoneArgs = {
  where: DirPersonPhoneWhereUniqueInput;
  create: DirPersonPhoneCreateInput;
  update: DirPersonPhoneUpdateInput;
};

export type MutationDeleteDirPersonPhoneArgs = {
  where: DirPersonPhoneWhereUniqueInput;
};

export type MutationCreateDirPersonConfirmStatusArgs = {
  data: DirPersonConfirmStatusCreateInput;
};

export type MutationUpdateDirPersonConfirmStatusArgs = {
  data: DirPersonConfirmStatusUpdateInput;
  where: DirPersonConfirmStatusWhereUniqueInput;
};

export type MutationUpsertDirPersonConfirmStatusArgs = {
  where: DirPersonConfirmStatusWhereUniqueInput;
  create: DirPersonConfirmStatusCreateInput;
  update: DirPersonConfirmStatusUpdateInput;
};

export type MutationDeleteDirPersonConfirmStatusArgs = {
  where: DirPersonConfirmStatusWhereUniqueInput;
};

export type MutationCreateDirPersonAdditionalRegionArgs = {
  data: DirPersonAdditionalRegionCreateInput;
};

export type MutationUpdateDirPersonAdditionalRegionArgs = {
  data: DirPersonAdditionalRegionUpdateInput;
  where: DirPersonAdditionalRegionWhereUniqueInput;
};

export type MutationUpsertDirPersonAdditionalRegionArgs = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
  create: DirPersonAdditionalRegionCreateInput;
  update: DirPersonAdditionalRegionUpdateInput;
};

export type MutationDeleteDirPersonAdditionalRegionArgs = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
};

export type MutationCreateDirPersonMergeTaskArgs = {
  data: DirPersonMergeTaskCreateInput;
};

export type MutationUpdateDirPersonMergeTaskArgs = {
  data: DirPersonMergeTaskUpdateInput;
  where: DirPersonMergeTaskWhereUniqueInput;
};

export type MutationUpsertDirPersonMergeTaskArgs = {
  where: DirPersonMergeTaskWhereUniqueInput;
  create: DirPersonMergeTaskCreateInput;
  update: DirPersonMergeTaskUpdateInput;
};

export type MutationDeleteDirPersonMergeTaskArgs = {
  where: DirPersonMergeTaskWhereUniqueInput;
};

export type MutationCreateDirPersonAddressArgs = {
  data: DirPersonAddressCreateInput;
};

export type MutationUpdateDirPersonAddressArgs = {
  data: DirPersonAddressUpdateInput;
  where: DirPersonAddressWhereUniqueInput;
};

export type MutationUpsertDirPersonAddressArgs = {
  where: DirPersonAddressWhereUniqueInput;
  create: DirPersonAddressCreateInput;
  update: DirPersonAddressUpdateInput;
};

export type MutationDeleteDirPersonAddressArgs = {
  where: DirPersonAddressWhereUniqueInput;
};

export type MutationCreateDirSportArgs = {
  data: DirSportCreateInput;
};

export type MutationUpdateDirSportArgs = {
  data: DirSportUpdateInput;
  where: DirSportWhereUniqueInput;
};

export type MutationUpsertDirSportArgs = {
  where: DirSportWhereUniqueInput;
  create: DirSportCreateInput;
  update: DirSportUpdateInput;
};

export type MutationDeleteDirSportArgs = {
  where: DirSportWhereUniqueInput;
};

export type MutationCreateDirSportDisciplineArgs = {
  data: DirSportDisciplineCreateInput;
};

export type MutationUpdateDirSportDisciplineArgs = {
  data: DirSportDisciplineUpdateInput;
  where: DirSportDisciplineWhereUniqueInput;
};

export type MutationUpsertDirSportDisciplineArgs = {
  where: DirSportDisciplineWhereUniqueInput;
  create: DirSportDisciplineCreateInput;
  update: DirSportDisciplineUpdateInput;
};

export type MutationDeleteDirSportDisciplineArgs = {
  where: DirSportDisciplineWhereUniqueInput;
};

export type MutationCreateDirSportDisciplineGroupArgs = {
  data: DirSportDisciplineGroupCreateInput;
};

export type MutationUpdateDirSportDisciplineGroupArgs = {
  data: DirSportDisciplineGroupUpdateInput;
  where: DirSportDisciplineGroupWhereUniqueInput;
};

export type MutationUpsertDirSportDisciplineGroupArgs = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  create: DirSportDisciplineGroupCreateInput;
  update: DirSportDisciplineGroupUpdateInput;
};

export type MutationDeleteDirSportDisciplineGroupArgs = {
  where: DirSportDisciplineGroupWhereUniqueInput;
};

export type MutationCreateDirSportAgeGroupArgs = {
  data: DirSportAgeGroupCreateInput;
};

export type MutationUpdateDirSportAgeGroupArgs = {
  data: DirSportAgeGroupUpdateInput;
  where: DirSportAgeGroupWhereUniqueInput;
};

export type MutationUpsertDirSportAgeGroupArgs = {
  where: DirSportAgeGroupWhereUniqueInput;
  create: DirSportAgeGroupCreateInput;
  update: DirSportAgeGroupUpdateInput;
};

export type MutationDeleteDirSportAgeGroupArgs = {
  where: DirSportAgeGroupWhereUniqueInput;
};

export type MutationCreateDirSportTrainingStageArgs = {
  data: DirSportTrainingStageCreateInput;
};

export type MutationUpdateDirSportTrainingStageArgs = {
  data: DirSportTrainingStageUpdateInput;
  where: DirSportTrainingStageWhereUniqueInput;
};

export type MutationUpsertDirSportTrainingStageArgs = {
  where: DirSportTrainingStageWhereUniqueInput;
  create: DirSportTrainingStageCreateInput;
  update: DirSportTrainingStageUpdateInput;
};

export type MutationDeleteDirSportTrainingStageArgs = {
  where: DirSportTrainingStageWhereUniqueInput;
};

export type MutationCreateDirSportBaseRegionArgs = {
  data: DirSportBaseRegionCreateInput;
};

export type MutationUpdateDirSportBaseRegionArgs = {
  data: DirSportBaseRegionUpdateInput;
  where: DirSportBaseRegionWhereUniqueInput;
};

export type MutationUpsertDirSportBaseRegionArgs = {
  where: DirSportBaseRegionWhereUniqueInput;
  create: DirSportBaseRegionCreateInput;
  update: DirSportBaseRegionUpdateInput;
};

export type MutationDeleteDirSportBaseRegionArgs = {
  where: DirSportBaseRegionWhereUniqueInput;
};

export type MutationCreateDirAthleteArgs = {
  data: DirAthleteCreateInput;
};

export type MutationUpdateDirAthleteArgs = {
  data: DirAthleteUpdateInput;
  where: DirAthleteWhereUniqueInput;
};

export type MutationUpsertDirAthleteArgs = {
  where: DirAthleteWhereUniqueInput;
  create: DirAthleteCreateInput;
  update: DirAthleteUpdateInput;
};

export type MutationDeleteDirAthleteArgs = {
  where: DirAthleteWhereUniqueInput;
};

export type MutationCreateDirAthleteSportArgs = {
  data: DirAthleteSportCreateInput;
};

export type MutationUpdateDirAthleteSportArgs = {
  data: DirAthleteSportUpdateInput;
  where: DirAthleteSportWhereUniqueInput;
};

export type MutationUpsertDirAthleteSportArgs = {
  where: DirAthleteSportWhereUniqueInput;
  create: DirAthleteSportCreateInput;
  update: DirAthleteSportUpdateInput;
};

export type MutationDeleteDirAthleteSportArgs = {
  where: DirAthleteSportWhereUniqueInput;
};

export type MutationCreateDirAthleteSportOrganizationArgs = {
  data: DirAthleteSportOrganizationCreateInput;
};

export type MutationUpdateDirAthleteSportOrganizationArgs = {
  data: DirAthleteSportOrganizationUpdateInput;
  where: DirAthleteSportOrganizationWhereUniqueInput;
};

export type MutationUpsertDirAthleteSportOrganizationArgs = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
  create: DirAthleteSportOrganizationCreateInput;
  update: DirAthleteSportOrganizationUpdateInput;
};

export type MutationDeleteDirAthleteSportOrganizationArgs = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
};

export type MutationCreateDirAthleteSportRankAndAwardArgs = {
  data: DirAthleteSportRankAndAwardCreateInput;
};

export type MutationUpdateDirAthleteSportRankAndAwardArgs = {
  data: DirAthleteSportRankAndAwardUpdateInput;
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
};

export type MutationUpsertDirAthleteSportRankAndAwardArgs = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
  create: DirAthleteSportRankAndAwardCreateInput;
  update: DirAthleteSportRankAndAwardUpdateInput;
};

export type MutationDeleteDirAthleteSportRankAndAwardArgs = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
};

export type MutationCreateDirAthleteMedCertArgs = {
  data: DirAthleteMedCertCreateInput;
};

export type MutationUpdateDirAthleteMedCertArgs = {
  data: DirAthleteMedCertUpdateInput;
  where: DirAthleteMedCertWhereUniqueInput;
};

export type MutationUpsertDirAthleteMedCertArgs = {
  where: DirAthleteMedCertWhereUniqueInput;
  create: DirAthleteMedCertCreateInput;
  update: DirAthleteMedCertUpdateInput;
};

export type MutationDeleteDirAthleteMedCertArgs = {
  where: DirAthleteMedCertWhereUniqueInput;
};

export type MutationCreateDirAthleteMedCertSportArgs = {
  data: DirAthleteMedCertSportCreateInput;
};

export type MutationUpdateDirAthleteMedCertSportArgs = {
  data: DirAthleteMedCertSportUpdateInput;
  where: DirAthleteMedCertSportWhereUniqueInput;
};

export type MutationUpsertDirAthleteMedCertSportArgs = {
  where: DirAthleteMedCertSportWhereUniqueInput;
  create: DirAthleteMedCertSportCreateInput;
  update: DirAthleteMedCertSportUpdateInput;
};

export type MutationDeleteDirAthleteMedCertSportArgs = {
  where: DirAthleteMedCertSportWhereUniqueInput;
};

export type MutationCreateDirAthleteCompetitionResultArgs = {
  data: DirAthleteCompetitionResultCreateInput;
};

export type MutationUpdateDirAthleteCompetitionResultArgs = {
  data: DirAthleteCompetitionResultUpdateInput;
  where: DirAthleteCompetitionResultWhereUniqueInput;
};

export type MutationUpsertDirAthleteCompetitionResultArgs = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
  create: DirAthleteCompetitionResultCreateInput;
  update: DirAthleteCompetitionResultUpdateInput;
};

export type MutationDeleteDirAthleteCompetitionResultArgs = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
};

export type MutationCreateDirTrainerArgs = {
  data: DirTrainerCreateInput;
};

export type MutationUpdateDirTrainerArgs = {
  data: DirTrainerUpdateInput;
  where: DirTrainerWhereUniqueInput;
};

export type MutationUpsertDirTrainerArgs = {
  where: DirTrainerWhereUniqueInput;
  create: DirTrainerCreateInput;
  update: DirTrainerUpdateInput;
};

export type MutationDeleteDirTrainerArgs = {
  where: DirTrainerWhereUniqueInput;
};

export type MutationCreateDirTrainerSportArgs = {
  data: DirTrainerSportCreateInput;
};

export type MutationUpdateDirTrainerSportArgs = {
  data: DirTrainerSportUpdateInput;
  where: DirTrainerSportWhereUniqueInput;
};

export type MutationUpsertDirTrainerSportArgs = {
  where: DirTrainerSportWhereUniqueInput;
  create: DirTrainerSportCreateInput;
  update: DirTrainerSportUpdateInput;
};

export type MutationDeleteDirTrainerSportArgs = {
  where: DirTrainerSportWhereUniqueInput;
};

export type MutationCreateDirTrainerSportOrganizationArgs = {
  data: DirTrainerSportOrganizationCreateInput;
};

export type MutationUpdateDirTrainerSportOrganizationArgs = {
  data: DirTrainerSportOrganizationUpdateInput;
  where: DirTrainerSportOrganizationWhereUniqueInput;
};

export type MutationUpsertDirTrainerSportOrganizationArgs = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
  create: DirTrainerSportOrganizationCreateInput;
  update: DirTrainerSportOrganizationUpdateInput;
};

export type MutationDeleteDirTrainerSportOrganizationArgs = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
};

export type MutationCreateDirTrainerSportCategoryArgs = {
  data: DirTrainerSportCategoryCreateInput;
};

export type MutationUpdateDirTrainerSportCategoryArgs = {
  data: DirTrainerSportCategoryUpdateInput;
  where: DirTrainerSportCategoryWhereUniqueInput;
};

export type MutationUpsertDirTrainerSportCategoryArgs = {
  where: DirTrainerSportCategoryWhereUniqueInput;
  create: DirTrainerSportCategoryCreateInput;
  update: DirTrainerSportCategoryUpdateInput;
};

export type MutationDeleteDirTrainerSportCategoryArgs = {
  where: DirTrainerSportCategoryWhereUniqueInput;
};

export type MutationCreateDirRefereeArgs = {
  data: DirRefereeCreateInput;
};

export type MutationUpdateDirRefereeArgs = {
  data: DirRefereeUpdateInput;
  where: DirRefereeWhereUniqueInput;
};

export type MutationUpsertDirRefereeArgs = {
  where: DirRefereeWhereUniqueInput;
  create: DirRefereeCreateInput;
  update: DirRefereeUpdateInput;
};

export type MutationDeleteDirRefereeArgs = {
  where: DirRefereeWhereUniqueInput;
};

export type MutationCreateDirRefereeSportArgs = {
  data: DirRefereeSportCreateInput;
};

export type MutationUpdateDirRefereeSportArgs = {
  data: DirRefereeSportUpdateInput;
  where: DirRefereeSportWhereUniqueInput;
};

export type MutationUpsertDirRefereeSportArgs = {
  where: DirRefereeSportWhereUniqueInput;
  create: DirRefereeSportCreateInput;
  update: DirRefereeSportUpdateInput;
};

export type MutationDeleteDirRefereeSportArgs = {
  where: DirRefereeSportWhereUniqueInput;
};

export type MutationCreateDirRefereeSportCategoryArgs = {
  data: DirRefereeSportCategoryCreateInput;
};

export type MutationUpdateDirRefereeSportCategoryArgs = {
  data: DirRefereeSportCategoryUpdateInput;
  where: DirRefereeSportCategoryWhereUniqueInput;
};

export type MutationUpsertDirRefereeSportCategoryArgs = {
  where: DirRefereeSportCategoryWhereUniqueInput;
  create: DirRefereeSportCategoryCreateInput;
  update: DirRefereeSportCategoryUpdateInput;
};

export type MutationDeleteDirRefereeSportCategoryArgs = {
  where: DirRefereeSportCategoryWhereUniqueInput;
};

export type MutationCreateDirSportingEventArgs = {
  data: DirSportingEventCreateInput;
};

export type MutationUpdateDirSportingEventArgs = {
  data: DirSportingEventUpdateInput;
  where: DirSportingEventWhereUniqueInput;
};

export type MutationUpsertDirSportingEventArgs = {
  where: DirSportingEventWhereUniqueInput;
  create: DirSportingEventCreateInput;
  update: DirSportingEventUpdateInput;
};

export type MutationDeleteDirSportingEventArgs = {
  where: DirSportingEventWhereUniqueInput;
};

export type MutationCreateDirSportingEventSportArgs = {
  data: DirSportingEventSportCreateInput;
};

export type MutationUpdateDirSportingEventSportArgs = {
  data: DirSportingEventSportUpdateInput;
  where: DirSportingEventSportWhereUniqueInput;
};

export type MutationUpsertDirSportingEventSportArgs = {
  where: DirSportingEventSportWhereUniqueInput;
  create: DirSportingEventSportCreateInput;
  update: DirSportingEventSportUpdateInput;
};

export type MutationDeleteDirSportingEventSportArgs = {
  where: DirSportingEventSportWhereUniqueInput;
};

export type MutationCreateDirSportingEventAgeGroupArgs = {
  data: DirSportingEventAgeGroupCreateInput;
};

export type MutationUpdateDirSportingEventAgeGroupArgs = {
  data: DirSportingEventAgeGroupUpdateInput;
  where: DirSportingEventAgeGroupWhereUniqueInput;
};

export type MutationUpsertDirSportingEventAgeGroupArgs = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
  create: DirSportingEventAgeGroupCreateInput;
  update: DirSportingEventAgeGroupUpdateInput;
};

export type MutationDeleteDirSportingEventAgeGroupArgs = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
};

export type MutationCreateDirSportingEventStatusArgs = {
  data: DirSportingEventStatusCreateInput;
};

export type MutationUpdateDirSportingEventStatusArgs = {
  data: DirSportingEventStatusUpdateInput;
  where: DirSportingEventStatusWhereUniqueInput;
};

export type MutationUpsertDirSportingEventStatusArgs = {
  where: DirSportingEventStatusWhereUniqueInput;
  create: DirSportingEventStatusCreateInput;
  update: DirSportingEventStatusUpdateInput;
};

export type MutationDeleteDirSportingEventStatusArgs = {
  where: DirSportingEventStatusWhereUniqueInput;
};

export type MutationCreateDirSportingEventStatusHistoryArgs = {
  data: DirSportingEventStatusHistoryCreateInput;
};

export type MutationUpdateDirSportingEventStatusHistoryArgs = {
  data: DirSportingEventStatusHistoryUpdateInput;
  where: DirSportingEventStatusHistoryWhereUniqueInput;
};

export type MutationUpsertDirSportingEventStatusHistoryArgs = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
  create: DirSportingEventStatusHistoryCreateInput;
  update: DirSportingEventStatusHistoryUpdateInput;
};

export type MutationDeleteDirSportingEventStatusHistoryArgs = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
};

export type MutationCreateDirSportingEventProgramTypeArgs = {
  data: DirSportingEventProgramTypeCreateInput;
};

export type MutationUpdateDirSportingEventProgramTypeArgs = {
  data: DirSportingEventProgramTypeUpdateInput;
  where: DirSportingEventProgramTypeWhereUniqueInput;
};

export type MutationUpsertDirSportingEventProgramTypeArgs = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
  create: DirSportingEventProgramTypeCreateInput;
  update: DirSportingEventProgramTypeUpdateInput;
};

export type MutationDeleteDirSportingEventProgramTypeArgs = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
};

export type MutationCreateDirSportingEventRefereeArgs = {
  data: DirSportingEventRefereeCreateInput;
};

export type MutationUpdateDirSportingEventRefereeArgs = {
  data: DirSportingEventRefereeUpdateInput;
  where: DirSportingEventRefereeWhereUniqueInput;
};

export type MutationUpsertDirSportingEventRefereeArgs = {
  where: DirSportingEventRefereeWhereUniqueInput;
  create: DirSportingEventRefereeCreateInput;
  update: DirSportingEventRefereeUpdateInput;
};

export type MutationDeleteDirSportingEventRefereeArgs = {
  where: DirSportingEventRefereeWhereUniqueInput;
};

export type MutationCreateDirSportingEventCompetitionCalendarArgs = {
  data: DirSportingEventCompetitionCalendarCreateInput;
};

export type MutationUpdateDirSportingEventCompetitionCalendarArgs = {
  data: DirSportingEventCompetitionCalendarUpdateInput;
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
};

export type MutationUpsertDirSportingEventCompetitionCalendarArgs = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
  create: DirSportingEventCompetitionCalendarCreateInput;
  update: DirSportingEventCompetitionCalendarUpdateInput;
};

export type MutationDeleteDirSportingEventCompetitionCalendarArgs = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
};

export type MutationCreateDirNationalTeamArgs = {
  data: DirNationalTeamCreateInput;
};

export type MutationUpdateDirNationalTeamArgs = {
  data: DirNationalTeamUpdateInput;
  where: DirNationalTeamWhereUniqueInput;
};

export type MutationUpsertDirNationalTeamArgs = {
  where: DirNationalTeamWhereUniqueInput;
  create: DirNationalTeamCreateInput;
  update: DirNationalTeamUpdateInput;
};

export type MutationDeleteDirNationalTeamArgs = {
  where: DirNationalTeamWhereUniqueInput;
};

export type MutationCreateDirNationalTeamStatusArgs = {
  data: DirNationalTeamStatusCreateInput;
};

export type MutationUpdateDirNationalTeamStatusArgs = {
  data: DirNationalTeamStatusUpdateInput;
  where: DirNationalTeamStatusWhereUniqueInput;
};

export type MutationUpsertDirNationalTeamStatusArgs = {
  where: DirNationalTeamStatusWhereUniqueInput;
  create: DirNationalTeamStatusCreateInput;
  update: DirNationalTeamStatusUpdateInput;
};

export type MutationDeleteDirNationalTeamStatusArgs = {
  where: DirNationalTeamStatusWhereUniqueInput;
};

export type MutationCreateDirNationalTeamStatusHistoryArgs = {
  data: DirNationalTeamStatusHistoryCreateInput;
};

export type MutationUpdateDirNationalTeamStatusHistoryArgs = {
  data: DirNationalTeamStatusHistoryUpdateInput;
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
};

export type MutationUpsertDirNationalTeamStatusHistoryArgs = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
  create: DirNationalTeamStatusHistoryCreateInput;
  update: DirNationalTeamStatusHistoryUpdateInput;
};

export type MutationDeleteDirNationalTeamStatusHistoryArgs = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
};

export type MutationCreateDirNationalTeamTrainerArgs = {
  data: DirNationalTeamTrainerCreateInput;
};

export type MutationUpdateDirNationalTeamTrainerArgs = {
  data: DirNationalTeamTrainerUpdateInput;
  where: DirNationalTeamTrainerWhereUniqueInput;
};

export type MutationUpsertDirNationalTeamTrainerArgs = {
  where: DirNationalTeamTrainerWhereUniqueInput;
  create: DirNationalTeamTrainerCreateInput;
  update: DirNationalTeamTrainerUpdateInput;
};

export type MutationDeleteDirNationalTeamTrainerArgs = {
  where: DirNationalTeamTrainerWhereUniqueInput;
};

export type MutationCreateDirNationalTeamAthleteGroupArgs = {
  data: DirNationalTeamAthleteGroupCreateInput;
};

export type MutationUpdateDirNationalTeamAthleteGroupArgs = {
  data: DirNationalTeamAthleteGroupUpdateInput;
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
};

export type MutationUpsertDirNationalTeamAthleteGroupArgs = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
  create: DirNationalTeamAthleteGroupCreateInput;
  update: DirNationalTeamAthleteGroupUpdateInput;
};

export type MutationDeleteDirNationalTeamAthleteGroupArgs = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
};

export type MutationCreateDirNationalTeamAthleteArgs = {
  data: DirNationalTeamAthleteCreateInput;
};

export type MutationUpdateDirNationalTeamAthleteArgs = {
  data: DirNationalTeamAthleteUpdateInput;
  where: DirNationalTeamAthleteWhereUniqueInput;
};

export type MutationUpsertDirNationalTeamAthleteArgs = {
  where: DirNationalTeamAthleteWhereUniqueInput;
  create: DirNationalTeamAthleteCreateInput;
  update: DirNationalTeamAthleteUpdateInput;
};

export type MutationDeleteDirNationalTeamAthleteArgs = {
  where: DirNationalTeamAthleteWhereUniqueInput;
};

export type MutationCreateDirNationalTeamMemberArgs = {
  data: DirNationalTeamMemberCreateInput;
};

export type MutationUpdateDirNationalTeamMemberArgs = {
  data: DirNationalTeamMemberUpdateInput;
  where: DirNationalTeamMemberWhereUniqueInput;
};

export type MutationUpsertDirNationalTeamMemberArgs = {
  where: DirNationalTeamMemberWhereUniqueInput;
  create: DirNationalTeamMemberCreateInput;
  update: DirNationalTeamMemberUpdateInput;
};

export type MutationDeleteDirNationalTeamMemberArgs = {
  where: DirNationalTeamMemberWhereUniqueInput;
};

export type MutationCreateDirCalendarArgs = {
  data: DirCalendarCreateInput;
};

export type MutationUpdateDirCalendarArgs = {
  data: DirCalendarUpdateInput;
  where: DirCalendarWhereUniqueInput;
};

export type MutationUpsertDirCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
  create: DirCalendarCreateInput;
  update: DirCalendarUpdateInput;
};

export type MutationDeleteDirCalendarArgs = {
  where: DirCalendarWhereUniqueInput;
};

export type MutationCreateDirDocumentArgs = {
  data: DirDocumentCreateInput;
};

export type MutationUpdateDirDocumentArgs = {
  data: DirDocumentUpdateInput;
  where: DirDocumentWhereUniqueInput;
};

export type MutationUpsertDirDocumentArgs = {
  where: DirDocumentWhereUniqueInput;
  create: DirDocumentCreateInput;
  update: DirDocumentUpdateInput;
};

export type MutationDeleteDirDocumentArgs = {
  where: DirDocumentWhereUniqueInput;
};

export type MutationCreateAddressTypeArgs = {
  data: AddressTypeCreateInput;
};

export type MutationUpdateAddressTypeArgs = {
  data: AddressTypeUpdateInput;
  where: AddressTypeWhereUniqueInput;
};

export type MutationUpsertAddressTypeArgs = {
  where: AddressTypeWhereUniqueInput;
  create: AddressTypeCreateInput;
  update: AddressTypeUpdateInput;
};

export type MutationDeleteAddressTypeArgs = {
  where: AddressTypeWhereUniqueInput;
};

export type MutationCreateTaskStatusArgs = {
  data: TaskStatusCreateInput;
};

export type MutationUpdateTaskStatusArgs = {
  data: TaskStatusUpdateInput;
  where: TaskStatusWhereUniqueInput;
};

export type MutationUpsertTaskStatusArgs = {
  where: TaskStatusWhereUniqueInput;
  create: TaskStatusCreateInput;
  update: TaskStatusUpdateInput;
};

export type MutationDeleteTaskStatusArgs = {
  where: TaskStatusWhereUniqueInput;
};

export type MutationCreateFileArgs = {
  data: FileCreateInput;
};

export type MutationUpdateFileArgs = {
  data: FileUpdateInput;
  where: FileWhereUniqueInput;
};

export type MutationUpsertFileArgs = {
  where: FileWhereUniqueInput;
  create: FileCreateInput;
  update: FileUpdateInput;
};

export type MutationDeleteFileArgs = {
  where: FileWhereUniqueInput;
};

export type MutationSignupArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
  password: Scalars['String'];
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
};

export type MutationSigninArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationPublicResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationActivateUserArgs = {
  userID: Scalars['String'];
};

export type MutationDeactivateUserArgs = {
  userID: Scalars['String'];
};

export type MutationChangeUserRoleArgs = {
  userID: Scalars['String'];
  role: Scalars['String'];
};

export type MutationChangeUserEmailArgs = {
  userID: Scalars['String'];
  email: Scalars['String'];
};

export type MutationResetUserPasswordArgs = {
  userID: Scalars['String'];
};

export type MutationDeleteUserAccountArgs = {
  userID: Scalars['String'];
};

export type MutationRevokeTokenArgs = {
  userID: Scalars['String'];
  token: Scalars['String'];
};

export type MutationRevokeAllTokensArgs = {
  userID: Scalars['String'];
};

export type MutationMergePersonArgs = {
  primary: DirPersonWhereUniqueInput;
  duplicate: DirPersonWhereUniqueInput;
};

export type MutationOpenMergePersonTaskArgs = {
  primary: DirPersonWhereUniqueInput;
  duplicate: DirPersonWhereUniqueInput;
  comment?: Maybe<Scalars['String']>;
};

export type MutationCloseMergePersonTaskArgs = {
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type MutationCancelMergePersonTaskArgs = {
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type MutationTrainerDismissalWithAthletesSelfTrainingArgs = {
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
};

export type MutationTrainerDismissalWithAthletesExpelArgs = {
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
};

export type MutationTrainerDismissalWithAthletesAssignToAnotherTrainerArgs = {
  fromTrainerID: Scalars['UUID'];
  toTrainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
};

export type MutationAthletesExpelArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID?: Maybe<Scalars['UUID']>;
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};

export type MutationAthletesExpelWithMoveToAnotherTrainerArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  fromTrainerID?: Maybe<Scalars['UUID']>;
  toTrainerID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};

export type MutationAthletesExpelWithMoveToAnotherTrainingStageArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID?: Maybe<Scalars['UUID']>;
  fromTrainingStageID: Scalars['UUID'];
  toTrainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};

export type MutationAthletesExpelWithMoveToSelfTrainingArgs = {
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
};

export type MutationUploadFileArgs = {
  group?: Scalars['String'];
  file: Scalars['Upload'];
};

export type UserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
};

export type UserCreateOneWithoutCreatedByInput = {
  create?: Maybe<UserCreateWithoutCreatedByInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutCreatedByInput = {
  id?: Maybe<Scalars['UUID']>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
};

export type UserCreateOneWithoutUpdatedByInput = {
  create?: Maybe<UserCreateWithoutUpdatedByInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutUpdatedByInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonCreateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
};

export type UserRoleCreateOneInput = {
  create?: Maybe<UserRoleCreateInput>;
  connect?: Maybe<UserRoleWhereUniqueInput>;
};

export type UserRoleCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type RegularUserCreateOneInput = {
  create?: Maybe<RegularUserCreateInput>;
  connect?: Maybe<RegularUserWhereUniqueInput>;
};

export type RegularUserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationCreateOneInput>;
};

export type UserCreateOneInput = {
  create?: Maybe<UserCreateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type DirOrganizationCreateOneInput = {
  create?: Maybe<DirOrganizationCreateInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
};

export type ArchiveCreateOneInput = {
  create?: Maybe<ArchiveCreateInput>;
  connect?: Maybe<ArchiveWhereUniqueInput>;
};

export type ArchiveCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  reason: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type DirOrganizationCreateOneWithoutChildrenInput = {
  create?: Maybe<DirOrganizationCreateWithoutChildrenInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
};

export type DirRegionCreateOneInput = {
  create?: Maybe<DirRegionCreateInput>;
  connect?: Maybe<DirRegionWhereUniqueInput>;
};

export type DirRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  dirFederalDistrict: DirFederalDistrictCreateOneWithoutDirRegionsInput;
};

export type DirFederalDistrictCreateOneWithoutDirRegionsInput = {
  create?: Maybe<DirFederalDistrictCreateWithoutDirRegionsInput>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictCreateWithoutDirRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
};

export type ClassifierValueCreateManyInput = {
  create?: Maybe<Array<ClassifierValueCreateInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
};

export type ClassifierValueCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier: ClassifierCreateOneWithoutValuesInput;
};

export type ClassifierCreateOneWithoutValuesInput = {
  create?: Maybe<ClassifierCreateWithoutValuesInput>;
  connect?: Maybe<ClassifierWhereUniqueInput>;
};

export type ClassifierCreateWithoutValuesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirSportCreateManyInput = {
  create?: Maybe<Array<DirSportCreateInput>>;
  connect?: Maybe<Array<DirSportWhereUniqueInput>>;
};

export type DirSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirSportDisciplineCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
};

export type DirSportDisciplineCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDisciplinesInput>;
};

export type DirSportDisciplineGroupCreateManyWithoutDisciplinesInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDisciplinesInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
};

export type DirSportDisciplineGroupCreateWithoutDisciplinesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplineGroupsInput>;
};

export type DirSportCreateOneWithoutDisciplineGroupsInput = {
  create?: Maybe<DirSportCreateWithoutDisciplineGroupsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutDisciplineGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirSportAgeGroupCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportAgeGroupCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
};

export type DirSportAgeGroupCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup: ClassifierValueCreateOneInput;
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
};

export type ClassifierValueCreateOneInput = {
  create?: Maybe<ClassifierValueCreateInput>;
  connect?: Maybe<ClassifierValueWhereUniqueInput>;
};

export type DirSportTrainingStageCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportTrainingStageCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
};

export type DirSportTrainingStageCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainingStage: ClassifierValueCreateOneInput;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportBaseRegionCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirSportBaseRegionCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
};

export type DirSportBaseRegionCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirRegion: DirRegionCreateOneInput;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportDisciplineGroupCreateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDirSportInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
};

export type DirSportDisciplineGroupCreateWithoutDirSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDisciplineGroupsInput>;
};

export type DirSportDisciplineCreateManyWithoutDisciplineGroupsInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDisciplineGroupsInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
};

export type DirSportDisciplineCreateWithoutDisciplineGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplinesInput>;
};

export type DirSportCreateOneWithoutDisciplinesInput = {
  create?: Maybe<DirSportCreateWithoutDisciplinesInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutDisciplinesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirOrganizationSportCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationSportCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
};

export type DirOrganizationSportCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirSportCreateOneInput = {
  create?: Maybe<DirSportCreateInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportDisciplineGroupCreateManyInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
};

export type DirSportDisciplineGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDisciplineGroupsInput>;
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplineGroupsInput>;
};

export type DirOrganizationAddressCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationAddressCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
};

export type DirOrganizationAddressCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type AddressTypeCreateOneInput = {
  create?: Maybe<AddressTypeCreateInput>;
  connect?: Maybe<AddressTypeWhereUniqueInput>;
};

export type AddressTypeCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirOrganizationPhoneCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationPhoneCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
};

export type DirOrganizationPhoneCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationEmailCreateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationEmailCreateWithoutDirOrganizationInput>>;
  connect?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
};

export type DirOrganizationEmailCreateWithoutDirOrganizationInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationCreatewebsitesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type DirOrganizationCreateManyWithoutParentInput = {
  create?: Maybe<Array<DirOrganizationCreateWithoutParentInput>>;
  connect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
};

export type DirOrganizationCreateWithoutParentInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
};

export type SystemUserCreateOneInput = {
  create?: Maybe<SystemUserCreateInput>;
  connect?: Maybe<SystemUserWhereUniqueInput>;
};

export type SystemUserCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
};

export type DirPersonCreateOneWithoutUserProfileInput = {
  create?: Maybe<DirPersonCreateWithoutUserProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutUserProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type FieldHistoryCreateManyInput = {
  create?: Maybe<Array<FieldHistoryCreateInput>>;
  connect?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
};

export type FieldHistoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  field: Scalars['String'];
  value: Scalars['Json'];
};

export type DirPersonCreateOneWithoutDuplicatesInput = {
  create?: Maybe<DirPersonCreateWithoutDuplicatesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutDuplicatesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type UserCreateOneWithoutPersonProfileInput = {
  create?: Maybe<UserCreateWithoutPersonProfileInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutPersonProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserCreateOneWithoutUpdatedByInput>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: UserRoleCreateOneInput;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserCreateOneInput>;
  system?: Maybe<SystemUserCreateOneInput>;
  position?: Maybe<Scalars['String']>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileCreateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileCreateOneInput>;
};

export type RegionalMinsportProfileCreateOneInput = {
  create?: Maybe<RegionalMinsportProfileCreateInput>;
  connect?: Maybe<RegionalMinsportProfileWhereUniqueInput>;
};

export type RegionalMinsportProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictCreateOneInput>;
};

export type DirFederalDistrictCreateOneInput = {
  create?: Maybe<DirFederalDistrictCreateInput>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirRegions?: Maybe<DirRegionCreateManyWithoutDirFederalDistrictInput>;
};

export type DirRegionCreateManyWithoutDirFederalDistrictInput = {
  create?: Maybe<Array<DirRegionCreateWithoutDirFederalDistrictInput>>;
  connect?: Maybe<Array<DirRegionWhereUniqueInput>>;
};

export type DirRegionCreateWithoutDirFederalDistrictInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
};

export type RegionalSchoolProfileCreateOneInput = {
  create?: Maybe<RegionalSchoolProfileCreateInput>;
  connect?: Maybe<RegionalSchoolProfileWhereUniqueInput>;
};

export type RegionalSchoolProfileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization: DirOrganizationCreateOneInput;
};

export type DirAthleteCreateOneWithoutDirPersonInput = {
  create?: Maybe<DirAthleteCreateWithoutDirPersonInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertCreateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput>;
};

export type DirPersonCreateOneInput = {
  create?: Maybe<DirPersonCreateInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirPersonCreateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonCreateWithoutPrimaryInput>>;
  connect?: Maybe<Array<DirPersonWhereUniqueInput>>;
};

export type DirPersonCreateWithoutPrimaryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirTrainerCreateOneWithoutDirPersonInput = {
  create?: Maybe<DirTrainerCreateWithoutDirPersonInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirTrainerSportCreateManyWithoutDirTrainerInput>;
};

export type DirTrainerSportCreateManyWithoutDirTrainerInput = {
  create?: Maybe<Array<DirTrainerSportCreateWithoutDirTrainerInput>>;
  connect?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
};

export type DirTrainerSportCreateWithoutDirTrainerInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  organizations?: Maybe<DirTrainerSportOrganizationCreateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryCreateManyWithoutSportInput>;
};

export type DirTrainerSportOrganizationCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportOrganizationCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
};

export type DirTrainerSportOrganizationCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneInput;
  clsPosition: ClassifierValueCreateOneInput;
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerSportCategoryCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportCategoryCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
};

export type DirTrainerSportCategoryCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
};

export type DirRefereeCreateOneWithoutDirPersonInput = {
  create?: Maybe<DirRefereeCreateWithoutDirPersonInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirRefereeSportCreateManyWithoutDirRefereeInput>;
};

export type DirRefereeSportCreateManyWithoutDirRefereeInput = {
  create?: Maybe<Array<DirRefereeSportCreateWithoutDirRefereeInput>>;
  connect?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
};

export type DirRefereeSportCreateWithoutDirRefereeInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryCreateManyWithoutSportInput>;
};

export type DirRefereeSportCategoryCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirRefereeSportCategoryCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
};

export type DirRefereeSportCategoryCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRefereeCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
};

export type DirDocumentCreateOneInput = {
  create?: Maybe<DirDocumentCreateInput>;
  connect?: Maybe<DirDocumentWhereUniqueInput>;
};

export type DirDocumentCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  date: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  file: FileCreateOneInput;
};

export type FileCreateOneInput = {
  create?: Maybe<FileCreateInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type FileCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['String'];
};

export type DirPersonConfirmStatusCreateOneInput = {
  create?: Maybe<DirPersonConfirmStatusCreateInput>;
  connect?: Maybe<DirPersonConfirmStatusWhereUniqueInput>;
};

export type DirPersonConfirmStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type DirCountryCreateManyInput = {
  create?: Maybe<Array<DirCountryCreateInput>>;
  connect?: Maybe<Array<DirCountryWhereUniqueInput>>;
};

export type DirCountryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirForeignCities?: Maybe<DirForeignCityCreateManyWithoutDirCountryInput>;
};

export type DirForeignCityCreateManyWithoutDirCountryInput = {
  create?: Maybe<Array<DirForeignCityCreateWithoutDirCountryInput>>;
  connect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
};

export type DirForeignCityCreateWithoutDirCountryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
};

export type DirForeignCityCreateOneInput = {
  create?: Maybe<DirForeignCityCreateInput>;
  connect?: Maybe<DirForeignCityWhereUniqueInput>;
};

export type DirForeignCityCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  dirCountry: DirCountryCreateOneWithoutDirForeignCitiesInput;
};

export type DirCountryCreateOneWithoutDirForeignCitiesInput = {
  create?: Maybe<DirCountryCreateWithoutDirForeignCitiesInput>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirCountryCreateWithoutDirForeignCitiesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
};

export type DirCountryCreateOneInput = {
  create?: Maybe<DirCountryCreateInput>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirPersonPhoneCreateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonPhoneCreateWithoutDirPersonInput>>;
  connect?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
};

export type DirPersonPhoneCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirPersonAdditionalRegionCreateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAdditionalRegionCreateWithoutDirPersonInput>>;
  connect?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
};

export type DirPersonAdditionalRegionCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
};

export type DirPersonMergeTaskCreateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutPrimaryInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
};

export type DirPersonMergeTaskCreateWithoutPrimaryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  status: TaskStatusCreateOneInput;
  comment?: Maybe<Scalars['String']>;
  duplicate: DirPersonCreateOneWithoutMergeTasksAsDuplicateInput;
};

export type TaskStatusCreateOneInput = {
  create?: Maybe<TaskStatusCreateInput>;
  connect?: Maybe<TaskStatusWhereUniqueInput>;
};

export type TaskStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type DirPersonCreateOneWithoutMergeTasksAsDuplicateInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsDuplicateInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutMergeTasksAsDuplicateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirPersonAddressCreateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAddressCreateWithoutDirPersonInput>>;
  connect?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
};

export type DirPersonAddressCreateWithoutDirPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type DirPersonMergeTaskCreateManyWithoutDuplicateInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutDuplicateInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
};

export type DirPersonMergeTaskCreateWithoutDuplicateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  status: TaskStatusCreateOneInput;
  comment?: Maybe<Scalars['String']>;
  primary: DirPersonCreateOneWithoutMergeTasksAsPrimaryInput;
};

export type DirPersonCreateOneWithoutMergeTasksAsPrimaryInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsPrimaryInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutMergeTasksAsPrimaryInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirAthleteSportCreateManyInput = {
  create?: Maybe<Array<DirAthleteSportCreateInput>>;
  connect?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
};

export type DirAthleteSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  dirTrainers?: Maybe<DirTrainerCreateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationCreateManyInput>;
  ranksAndAwards?: Maybe<DirAthleteSportRankAndAwardCreateManyWithoutSportInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineCreateManyInput = {
  create?: Maybe<Array<DirSportDisciplineCreateInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
};

export type DirSportDisciplineCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDisciplinesInput>;
  dirSport?: Maybe<DirSportCreateOneWithoutDisciplinesInput>;
};

export type DirTrainerCreateManyInput = {
  create?: Maybe<Array<DirTrainerCreateInput>>;
  connect?: Maybe<Array<DirTrainerWhereUniqueInput>>;
};

export type DirTrainerCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutTrainerProfileInput;
  sports?: Maybe<DirTrainerSportCreateManyWithoutDirTrainerInput>;
};

export type DirPersonCreateOneWithoutTrainerProfileInput = {
  create?: Maybe<DirPersonCreateWithoutTrainerProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutTrainerProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirAthleteSportOrganizationCreateManyInput = {
  create?: Maybe<Array<DirAthleteSportOrganizationCreateInput>>;
  connect?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
};

export type DirAthleteSportOrganizationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneInput;
  clsTrainingStage: ClassifierValueCreateOneInput;
  dirTrainer?: Maybe<DirTrainerCreateOneInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart: Scalars['DateTime'];
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerCreateOneInput = {
  create?: Maybe<DirTrainerCreateInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirAthleteSportRankAndAwardCreateManyWithoutSportInput = {
  create?: Maybe<Array<DirAthleteSportRankAndAwardCreateWithoutSportInput>>;
  connect?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
};

export type DirAthleteSportRankAndAwardCreateWithoutSportInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRankAndAward: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
};

export type DirAthleteMedCertCreateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteMedCertCreateWithoutDirAthleteInput>>;
  connect?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
};

export type DirAthleteMedCertCreateWithoutDirAthleteInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportCreateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertSportCreateManyWithoutMedCertInput = {
  create?: Maybe<Array<DirAthleteMedCertSportCreateWithoutMedCertInput>>;
  connect?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
};

export type DirAthleteMedCertSportCreateWithoutMedCertInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  clsTrainingStage?: Maybe<ClassifierValueCreateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteCompetitionResultCreateWithoutDirAthleteInput>>;
  connect?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
};

export type DirAthleteCompetitionResultCreateWithoutDirAthleteInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sportingEventProgramType: DirSportingEventProgramTypeCreateOneInput;
  point: Scalars['Int'];
  result: Scalars['String'];
};

export type DirSportingEventProgramTypeCreateOneInput = {
  create?: Maybe<DirSportingEventProgramTypeCreateInput>;
  connect?: Maybe<DirSportingEventProgramTypeWhereUniqueInput>;
};

export type DirSportingEventProgramTypeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutProgramTypesInput;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  start?: Maybe<Scalars['String']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  discipline: DirSportDisciplineCreateOneInput;
};

export type DirSportingEventCreateOneWithoutProgramTypesInput = {
  create?: Maybe<DirSportingEventCreateWithoutProgramTypesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutProgramTypesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirNationalTeamCreateManyWithoutDirSportingEventInput = {
  create?: Maybe<Array<DirNationalTeamCreateWithoutDirSportingEventInput>>;
  connect?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
};

export type DirNationalTeamCreateWithoutDirSportingEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
};

export type DirNationalTeamStatusCreateOneInput = {
  create?: Maybe<DirNationalTeamStatusCreateInput>;
  connect?: Maybe<DirNationalTeamStatusWhereUniqueInput>;
};

export type DirNationalTeamStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirNationalTeamStatusHistoryCreateManyInput = {
  create?: Maybe<Array<DirNationalTeamStatusHistoryCreateInput>>;
  connect?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
};

export type DirNationalTeamStatusHistoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  date: Scalars['DateTime'];
  status: DirNationalTeamStatusCreateOneInput;
  user: UserCreateOneInput;
};

export type DirNationalTeamTrainerCreateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamTrainerCreateWithoutTeamInput>>;
  connect?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
};

export type DirNationalTeamTrainerCreateWithoutTeamInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainerCreateOneInput;
  clsPosition?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirNationalTeamAthleteGroupCreateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamAthleteGroupCreateWithoutTeamInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
};

export type DirNationalTeamAthleteGroupCreateWithoutTeamInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteCreateManyWithoutGroupInput>;
};

export type DirNationalTeamAthleteCreateManyWithoutGroupInput = {
  create?: Maybe<Array<DirNationalTeamAthleteCreateWithoutGroupInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
};

export type DirNationalTeamAthleteCreateWithoutGroupInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirAthlete: DirAthleteCreateOneInput;
  bestResult?: Maybe<Scalars['String']>;
};

export type DirAthleteCreateOneInput = {
  create?: Maybe<DirAthleteCreateInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAthleteProfileInput;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertCreateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput>;
};

export type DirPersonCreateOneWithoutAthleteProfileInput = {
  create?: Maybe<DirPersonCreateWithoutAthleteProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutAthleteProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirNationalTeamMemberCreateManyInput = {
  create?: Maybe<Array<DirNationalTeamMemberCreateInput>>;
  connect?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
};

export type DirNationalTeamMemberCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
  clsPosition?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirSportingEventCreateOneWithoutChildrenInput = {
  create?: Maybe<DirSportingEventCreateWithoutChildrenInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirSportingEventStatusCreateOneInput = {
  create?: Maybe<DirSportingEventStatusCreateInput>;
  connect?: Maybe<DirSportingEventStatusWhereUniqueInput>;
};

export type DirSportingEventStatusCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type DirSportingEventStatusHistoryCreateManyInput = {
  create?: Maybe<Array<DirSportingEventStatusHistoryCreateInput>>;
  connect?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
};

export type DirSportingEventStatusHistoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  date: Scalars['DateTime'];
  status: DirSportingEventStatusCreateOneInput;
  user: UserCreateOneInput;
};

export type DirCalendarCreateOneInput = {
  create?: Maybe<DirCalendarCreateInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type DirSportingEventSportCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventSportCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
};

export type DirSportingEventSportCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirSportingEventAgeGroupCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventAgeGroupCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
};

export type DirSportingEventAgeGroupCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueCreateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventProgramTypeCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventProgramTypeCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
};

export type DirSportingEventProgramTypeCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
  start?: Maybe<Scalars['String']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  discipline: DirSportDisciplineCreateOneInput;
};

export type DirSportDisciplineCreateOneInput = {
  create?: Maybe<DirSportDisciplineCreateInput>;
  connect?: Maybe<DirSportDisciplineWhereUniqueInput>;
};

export type DirSportingEventRefereeCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRefereeCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
};

export type DirSportingEventRefereeCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirRefereeCreateOneInput;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition: ClassifierValueCreateOneInput;
};

export type DirRefereeCreateOneInput = {
  create?: Maybe<DirRefereeCreateInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutRefereeProfileInput;
  sports?: Maybe<DirRefereeSportCreateManyWithoutDirRefereeInput>;
};

export type DirPersonCreateOneWithoutRefereeProfileInput = {
  create?: Maybe<DirPersonCreateWithoutRefereeProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutRefereeProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirSportingEventCompetitionCalendarCreateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventCompetitionCalendarCreateWithoutEventInput>>;
  connect?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
};

export type DirSportingEventCompetitionCalendarCreateWithoutEventInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineCreateOneInput>;
};

export type DirDocumentCreateManyInput = {
  create?: Maybe<Array<DirDocumentCreateInput>>;
  connect?: Maybe<Array<DirDocumentWhereUniqueInput>>;
};

export type DirSportingEventCreateManyWithoutParentInput = {
  create?: Maybe<Array<DirSportingEventCreateWithoutParentInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
};

export type DirSportingEventCreateWithoutParentInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type UserUpdateInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
};

export type UserUpdateOneWithoutCreatedByInput = {
  create?: Maybe<UserCreateWithoutCreatedByInput>;
  update?: Maybe<UserUpdateWithoutCreatedByDataInput>;
  upsert?: Maybe<UserUpsertWithoutCreatedByInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutCreatedByDataInput = {
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
};

export type UserUpdateOneWithoutUpdatedByInput = {
  create?: Maybe<UserCreateWithoutUpdatedByInput>;
  update?: Maybe<UserUpdateWithoutUpdatedByDataInput>;
  upsert?: Maybe<UserUpsertWithoutUpdatedByInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutUpdatedByDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
};

export type UserRoleUpdateOneRequiredInput = {
  create?: Maybe<UserRoleCreateInput>;
  update?: Maybe<UserRoleUpdateDataInput>;
  upsert?: Maybe<UserRoleUpsertNestedInput>;
  connect?: Maybe<UserRoleWhereUniqueInput>;
};

export type UserRoleUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type UserRoleUpsertNestedInput = {
  update: UserRoleUpdateDataInput;
  create: UserRoleCreateInput;
};

export type RegularUserUpdateOneInput = {
  create?: Maybe<RegularUserCreateInput>;
  update?: Maybe<RegularUserUpdateDataInput>;
  upsert?: Maybe<RegularUserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RegularUserWhereUniqueInput>;
};

export type RegularUserUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneInput>;
};

export type UserUpdateOneInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  personProfile?: Maybe<DirPersonUpdateOneWithoutUserProfileInput>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
};

export type SystemUserUpdateOneInput = {
  create?: Maybe<SystemUserCreateInput>;
  update?: Maybe<SystemUserUpdateDataInput>;
  upsert?: Maybe<SystemUserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SystemUserWhereUniqueInput>;
};

export type SystemUserUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
};

export type SystemUserUpsertNestedInput = {
  update: SystemUserUpdateDataInput;
  create: SystemUserCreateInput;
};

export type DirPersonUpdateOneWithoutUserProfileInput = {
  create?: Maybe<DirPersonCreateWithoutUserProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutUserProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutUserProfileInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutUserProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type FieldHistoryUpdateManyInput = {
  create?: Maybe<Array<FieldHistoryCreateInput>>;
  update?: Maybe<Array<FieldHistoryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<FieldHistoryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  set?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<FieldHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FieldHistoryScalarWhereInput>>;
  updateMany?: Maybe<Array<FieldHistoryUpdateManyWithWhereNestedInput>>;
};

export type FieldHistoryUpdateWithWhereUniqueNestedInput = {
  where: FieldHistoryWhereUniqueInput;
  data: FieldHistoryUpdateDataInput;
};

export type FieldHistoryUpdateDataInput = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
};

export type FieldHistoryUpsertWithWhereUniqueNestedInput = {
  where: FieldHistoryWhereUniqueInput;
  update: FieldHistoryUpdateDataInput;
  create: FieldHistoryCreateInput;
};

export type FieldHistoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  field_not?: Maybe<Scalars['String']>;
  field_in?: Maybe<Array<Scalars['String']>>;
  field_not_in?: Maybe<Array<Scalars['String']>>;
  field_lt?: Maybe<Scalars['String']>;
  field_lte?: Maybe<Scalars['String']>;
  field_gt?: Maybe<Scalars['String']>;
  field_gte?: Maybe<Scalars['String']>;
  field_contains?: Maybe<Scalars['String']>;
  field_not_contains?: Maybe<Scalars['String']>;
  field_starts_with?: Maybe<Scalars['String']>;
  field_not_starts_with?: Maybe<Scalars['String']>;
  field_ends_with?: Maybe<Scalars['String']>;
  field_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<FieldHistoryScalarWhereInput>>;
  OR?: Maybe<Array<FieldHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<FieldHistoryScalarWhereInput>>;
};

export type FieldHistoryUpdateManyWithWhereNestedInput = {
  where: FieldHistoryScalarWhereInput;
  data: FieldHistoryUpdateManyDataInput;
};

export type FieldHistoryUpdateManyDataInput = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
};

export type ArchiveUpdateOneInput = {
  create?: Maybe<ArchiveCreateInput>;
  update?: Maybe<ArchiveUpdateDataInput>;
  upsert?: Maybe<ArchiveUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ArchiveWhereUniqueInput>;
};

export type ArchiveUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  reason?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type ArchiveUpsertNestedInput = {
  update: ArchiveUpdateDataInput;
  create: ArchiveCreateInput;
};

export type DirPersonUpdateOneWithoutDuplicatesInput = {
  create?: Maybe<DirPersonCreateWithoutDuplicatesInput>;
  update?: Maybe<DirPersonUpdateWithoutDuplicatesDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutDuplicatesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutDuplicatesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type UserUpdateOneWithoutPersonProfileInput = {
  create?: Maybe<UserCreateWithoutPersonProfileInput>;
  update?: Maybe<UserUpdateWithoutPersonProfileDataInput>;
  upsert?: Maybe<UserUpsertWithoutPersonProfileInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutPersonProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneWithoutCreatedByInput>;
  updatedBy?: Maybe<UserUpdateOneWithoutUpdatedByInput>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleUpdateOneRequiredInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<RegularUserUpdateOneInput>;
  system?: Maybe<SystemUserUpdateOneInput>;
  position?: Maybe<Scalars['String']>;
  regionalMinsportProfile?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  regionalSchoolProfile?: Maybe<RegionalSchoolProfileUpdateOneInput>;
};

export type RegionalMinsportProfileUpdateOneInput = {
  create?: Maybe<RegionalMinsportProfileCreateInput>;
  update?: Maybe<RegionalMinsportProfileUpdateDataInput>;
  upsert?: Maybe<RegionalMinsportProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RegionalMinsportProfileWhereUniqueInput>;
};

export type RegionalMinsportProfileUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneInput>;
};

export type DirRegionUpdateOneInput = {
  create?: Maybe<DirRegionCreateInput>;
  update?: Maybe<DirRegionUpdateDataInput>;
  upsert?: Maybe<DirRegionUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirRegionWhereUniqueInput>;
};

export type DirRegionUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput>;
};

export type DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput = {
  create?: Maybe<DirFederalDistrictCreateWithoutDirRegionsInput>;
  update?: Maybe<DirFederalDistrictUpdateWithoutDirRegionsDataInput>;
  upsert?: Maybe<DirFederalDistrictUpsertWithoutDirRegionsInput>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictUpdateWithoutDirRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictUpsertWithoutDirRegionsInput = {
  update: DirFederalDistrictUpdateWithoutDirRegionsDataInput;
  create: DirFederalDistrictCreateWithoutDirRegionsInput;
};

export type DirRegionUpsertNestedInput = {
  update: DirRegionUpdateDataInput;
  create: DirRegionCreateInput;
};

export type DirFederalDistrictUpdateOneInput = {
  create?: Maybe<DirFederalDistrictCreateInput>;
  update?: Maybe<DirFederalDistrictUpdateDataInput>;
  upsert?: Maybe<DirFederalDistrictUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirFederalDistrictWhereUniqueInput>;
};

export type DirFederalDistrictUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirRegions?: Maybe<DirRegionUpdateManyWithoutDirFederalDistrictInput>;
};

export type DirRegionUpdateManyWithoutDirFederalDistrictInput = {
  create?: Maybe<Array<DirRegionCreateWithoutDirFederalDistrictInput>>;
  delete?: Maybe<Array<DirRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirRegionUpdateWithWhereUniqueWithoutDirFederalDistrictInput>>;
  upsert?: Maybe<Array<DirRegionUpsertWithWhereUniqueWithoutDirFederalDistrictInput>>;
  deleteMany?: Maybe<Array<DirRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRegionUpdateManyWithWhereNestedInput>>;
};

export type DirRegionUpdateWithWhereUniqueWithoutDirFederalDistrictInput = {
  where: DirRegionWhereUniqueInput;
  data: DirRegionUpdateWithoutDirFederalDistrictDataInput;
};

export type DirRegionUpdateWithoutDirFederalDistrictDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type DirRegionUpsertWithWhereUniqueWithoutDirFederalDistrictInput = {
  where: DirRegionWhereUniqueInput;
  update: DirRegionUpdateWithoutDirFederalDistrictDataInput;
  create: DirRegionCreateWithoutDirFederalDistrictInput;
};

export type DirRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirRegionScalarWhereInput>>;
};

export type DirRegionUpdateManyWithWhereNestedInput = {
  where: DirRegionScalarWhereInput;
  data: DirRegionUpdateManyDataInput;
};

export type DirRegionUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type DirFederalDistrictUpsertNestedInput = {
  update: DirFederalDistrictUpdateDataInput;
  create: DirFederalDistrictCreateInput;
};

export type RegionalMinsportProfileUpsertNestedInput = {
  update: RegionalMinsportProfileUpdateDataInput;
  create: RegionalMinsportProfileCreateInput;
};

export type RegionalSchoolProfileUpdateOneInput = {
  create?: Maybe<RegionalSchoolProfileCreateInput>;
  update?: Maybe<RegionalSchoolProfileUpdateDataInput>;
  upsert?: Maybe<RegionalSchoolProfileUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RegionalSchoolProfileWhereUniqueInput>;
};

export type RegionalSchoolProfileUpdateDataInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredInput = {
  create?: Maybe<DirOrganizationCreateInput>;
  update?: Maybe<DirOrganizationUpdateDataInput>;
  upsert?: Maybe<DirOrganizationUpsertNestedInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationUpdateOneWithoutChildrenInput = {
  create?: Maybe<DirOrganizationCreateWithoutChildrenInput>;
  update?: Maybe<DirOrganizationUpdateWithoutChildrenDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutChildrenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutChildrenDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type ClassifierValueUpdateManyInput = {
  create?: Maybe<Array<ClassifierValueCreateInput>>;
  update?: Maybe<Array<ClassifierValueUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<ClassifierValueUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  set?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  updateMany?: Maybe<Array<ClassifierValueUpdateManyWithWhereNestedInput>>;
};

export type ClassifierValueUpdateWithWhereUniqueNestedInput = {
  where: ClassifierValueWhereUniqueInput;
  data: ClassifierValueUpdateDataInput;
};

export type ClassifierValueUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier?: Maybe<ClassifierUpdateOneRequiredWithoutValuesInput>;
};

export type ClassifierUpdateOneRequiredWithoutValuesInput = {
  create?: Maybe<ClassifierCreateWithoutValuesInput>;
  update?: Maybe<ClassifierUpdateWithoutValuesDataInput>;
  upsert?: Maybe<ClassifierUpsertWithoutValuesInput>;
  connect?: Maybe<ClassifierWhereUniqueInput>;
};

export type ClassifierUpdateWithoutValuesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ClassifierUpsertWithoutValuesInput = {
  update: ClassifierUpdateWithoutValuesDataInput;
  create: ClassifierCreateWithoutValuesInput;
};

export type ClassifierValueUpsertWithWhereUniqueNestedInput = {
  where: ClassifierValueWhereUniqueInput;
  update: ClassifierValueUpdateDataInput;
  create: ClassifierValueCreateInput;
};

export type ClassifierValueScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  OR?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  NOT?: Maybe<Array<ClassifierValueScalarWhereInput>>;
};

export type ClassifierValueUpdateManyWithWhereNestedInput = {
  where: ClassifierValueScalarWhereInput;
  data: ClassifierValueUpdateManyDataInput;
};

export type ClassifierValueUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type DirSportUpdateManyInput = {
  create?: Maybe<Array<DirSportCreateInput>>;
  update?: Maybe<Array<DirSportUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportWhereUniqueInput>>;
  set?: Maybe<Array<DirSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportUpdateManyWithWhereNestedInput>>;
};

export type DirSportUpdateWithWhereUniqueNestedInput = {
  where: DirSportWhereUniqueInput;
  data: DirSportUpdateDataInput;
};

export type DirSportUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportDisciplineUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportDisciplineUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineWhereUniqueInput;
  data: DirSportDisciplineUpdateWithoutDirSportDataInput;
};

export type DirSportDisciplineUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput>;
};

export type DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDisciplinesInput>>;
  delete?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDisciplinesInput>>;
  upsert?: Maybe<Array<DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDisciplinesInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDisciplinesInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  data: DirSportDisciplineGroupUpdateWithoutDisciplinesDataInput;
};

export type DirSportDisciplineGroupUpdateWithoutDisciplinesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplineGroupsInput>;
};

export type DirSportUpdateOneWithoutDisciplineGroupsInput = {
  create?: Maybe<DirSportCreateWithoutDisciplineGroupsInput>;
  update?: Maybe<DirSportUpdateWithoutDisciplineGroupsDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutDisciplineGroupsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutDisciplineGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportAgeGroupUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportAgeGroupCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportAgeGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportAgeGroupUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportAgeGroupUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportAgeGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportAgeGroupUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportAgeGroupWhereUniqueInput;
  data: DirSportAgeGroupUpdateWithoutDirSportDataInput;
};

export type DirSportAgeGroupUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type ClassifierValueUpdateOneRequiredInput = {
  create?: Maybe<ClassifierValueCreateInput>;
  update?: Maybe<ClassifierValueUpdateDataInput>;
  upsert?: Maybe<ClassifierValueUpsertNestedInput>;
  connect?: Maybe<ClassifierValueWhereUniqueInput>;
};

export type ClassifierValueUpsertNestedInput = {
  update: ClassifierValueUpdateDataInput;
  create: ClassifierValueCreateInput;
};

export type DirSportAgeGroupUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportAgeGroupWhereUniqueInput;
  update: DirSportAgeGroupUpdateWithoutDirSportDataInput;
  create: DirSportAgeGroupCreateWithoutDirSportInput;
};

export type DirSportAgeGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportAgeGroupScalarWhereInput>>;
};

export type DirSportAgeGroupUpdateManyWithWhereNestedInput = {
  where: DirSportAgeGroupScalarWhereInput;
  data: DirSportAgeGroupUpdateManyDataInput;
};

export type DirSportAgeGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportTrainingStageUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportTrainingStageCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  set?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportTrainingStageWhereUniqueInput>>;
  update?: Maybe<Array<DirSportTrainingStageUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportTrainingStageUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportTrainingStageUpdateManyWithWhereNestedInput>>;
};

export type DirSportTrainingStageUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportTrainingStageWhereUniqueInput;
  data: DirSportTrainingStageUpdateWithoutDirSportDataInput;
};

export type DirSportTrainingStageUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportTrainingStageUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportTrainingStageWhereUniqueInput;
  update: DirSportTrainingStageUpdateWithoutDirSportDataInput;
  create: DirSportTrainingStageCreateWithoutDirSportInput;
};

export type DirSportTrainingStageScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  minGroupSize_not?: Maybe<Scalars['Int']>;
  minGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  minGroupSize_lt?: Maybe<Scalars['Int']>;
  minGroupSize_lte?: Maybe<Scalars['Int']>;
  minGroupSize_gt?: Maybe<Scalars['Int']>;
  minGroupSize_gte?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize_not?: Maybe<Scalars['Int']>;
  maxGroupSize_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_not_in?: Maybe<Array<Scalars['Int']>>;
  maxGroupSize_lt?: Maybe<Scalars['Int']>;
  maxGroupSize_lte?: Maybe<Scalars['Int']>;
  maxGroupSize_gt?: Maybe<Scalars['Int']>;
  maxGroupSize_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
  OR?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportTrainingStageScalarWhereInput>>;
};

export type DirSportTrainingStageUpdateManyWithWhereNestedInput = {
  where: DirSportTrainingStageScalarWhereInput;
  data: DirSportTrainingStageUpdateManyDataInput;
};

export type DirSportTrainingStageUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportBaseRegionUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirSportBaseRegionCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportBaseRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirSportBaseRegionUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirSportBaseRegionUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportBaseRegionUpdateManyWithWhereNestedInput>>;
};

export type DirSportBaseRegionUpdateWithWhereUniqueWithoutSportInput = {
  where: DirSportBaseRegionWhereUniqueInput;
  data: DirSportBaseRegionUpdateWithoutSportDataInput;
};

export type DirSportBaseRegionUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirRegionUpdateOneRequiredInput = {
  create?: Maybe<DirRegionCreateInput>;
  update?: Maybe<DirRegionUpdateDataInput>;
  upsert?: Maybe<DirRegionUpsertNestedInput>;
  connect?: Maybe<DirRegionWhereUniqueInput>;
};

export type DirSportBaseRegionUpsertWithWhereUniqueWithoutSportInput = {
  where: DirSportBaseRegionWhereUniqueInput;
  update: DirSportBaseRegionUpdateWithoutSportDataInput;
  create: DirSportBaseRegionCreateWithoutSportInput;
};

export type DirSportBaseRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportBaseRegionScalarWhereInput>>;
};

export type DirSportBaseRegionUpdateManyWithWhereNestedInput = {
  where: DirSportBaseRegionScalarWhereInput;
  data: DirSportBaseRegionUpdateManyDataInput;
};

export type DirSportBaseRegionUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportUpsertWithoutDisciplineGroupsInput = {
  update: DirSportUpdateWithoutDisciplineGroupsDataInput;
  create: DirSportCreateWithoutDisciplineGroupsInput;
};

export type DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDisciplinesInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  update: DirSportDisciplineGroupUpdateWithoutDisciplinesDataInput;
  create: DirSportDisciplineGroupCreateWithoutDisciplinesInput;
};

export type DirSportDisciplineGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
};

export type DirSportDisciplineGroupUpdateManyWithWhereNestedInput = {
  where: DirSportDisciplineGroupScalarWhereInput;
  data: DirSportDisciplineGroupUpdateManyDataInput;
};

export type DirSportDisciplineGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineWhereUniqueInput;
  update: DirSportDisciplineUpdateWithoutDirSportDataInput;
  create: DirSportDisciplineCreateWithoutDirSportInput;
};

export type DirSportDisciplineScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  OR?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
};

export type DirSportDisciplineUpdateManyWithWhereNestedInput = {
  where: DirSportDisciplineScalarWhereInput;
  data: DirSportDisciplineUpdateManyDataInput;
};

export type DirSportDisciplineUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineGroupUpdateManyWithoutDirSportInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateWithoutDirSportInput>>;
  delete?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDirSportInput>>;
  upsert?: Maybe<Array<DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDirSportInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineGroupUpdateWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  data: DirSportDisciplineGroupUpdateWithoutDirSportDataInput;
};

export type DirSportDisciplineGroupUpdateWithoutDirSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput>;
};

export type DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput = {
  create?: Maybe<Array<DirSportDisciplineCreateWithoutDisciplineGroupsInput>>;
  delete?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  update?: Maybe<Array<DirSportDisciplineUpdateWithWhereUniqueWithoutDisciplineGroupsInput>>;
  upsert?: Maybe<Array<DirSportDisciplineUpsertWithWhereUniqueWithoutDisciplineGroupsInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineUpdateWithWhereUniqueWithoutDisciplineGroupsInput = {
  where: DirSportDisciplineWhereUniqueInput;
  data: DirSportDisciplineUpdateWithoutDisciplineGroupsDataInput;
};

export type DirSportDisciplineUpdateWithoutDisciplineGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplinesInput>;
};

export type DirSportUpdateOneWithoutDisciplinesInput = {
  create?: Maybe<DirSportCreateWithoutDisciplinesInput>;
  update?: Maybe<DirSportUpdateWithoutDisciplinesDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutDisciplinesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutDisciplinesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportUpsertWithoutDisciplinesInput = {
  update: DirSportUpdateWithoutDisciplinesDataInput;
  create: DirSportCreateWithoutDisciplinesInput;
};

export type DirSportDisciplineUpsertWithWhereUniqueWithoutDisciplineGroupsInput = {
  where: DirSportDisciplineWhereUniqueInput;
  update: DirSportDisciplineUpdateWithoutDisciplineGroupsDataInput;
  create: DirSportDisciplineCreateWithoutDisciplineGroupsInput;
};

export type DirSportDisciplineGroupUpsertWithWhereUniqueWithoutDirSportInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  update: DirSportDisciplineGroupUpdateWithoutDirSportDataInput;
  create: DirSportDisciplineGroupCreateWithoutDirSportInput;
};

export type DirSportUpsertWithWhereUniqueNestedInput = {
  where: DirSportWhereUniqueInput;
  update: DirSportUpdateDataInput;
  create: DirSportCreateInput;
};

export type DirSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  evsk_not?: Maybe<Scalars['String']>;
  evsk_in?: Maybe<Array<Scalars['String']>>;
  evsk_not_in?: Maybe<Array<Scalars['String']>>;
  evsk_lt?: Maybe<Scalars['String']>;
  evsk_lte?: Maybe<Scalars['String']>;
  evsk_gt?: Maybe<Scalars['String']>;
  evsk_gte?: Maybe<Scalars['String']>;
  evsk_contains?: Maybe<Scalars['String']>;
  evsk_not_contains?: Maybe<Scalars['String']>;
  evsk_starts_with?: Maybe<Scalars['String']>;
  evsk_not_starts_with?: Maybe<Scalars['String']>;
  evsk_ends_with?: Maybe<Scalars['String']>;
  evsk_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportScalarWhereInput>>;
  OR?: Maybe<Array<DirSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportScalarWhereInput>>;
};

export type DirSportUpdateManyWithWhereNestedInput = {
  where: DirSportScalarWhereInput;
  data: DirSportUpdateManyDataInput;
};

export type DirSportUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirOrganizationSportUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationSportCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationSportWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationSportUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationSportUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationSportUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationSportUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationSportWhereUniqueInput;
  data: DirOrganizationSportUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationSportUpdateWithoutDirOrganizationDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirSportUpdateOneRequiredInput = {
  create?: Maybe<DirSportCreateInput>;
  update?: Maybe<DirSportUpdateDataInput>;
  upsert?: Maybe<DirSportUpsertNestedInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpsertNestedInput = {
  update: DirSportUpdateDataInput;
  create: DirSportCreateInput;
};

export type DirSportDisciplineGroupUpdateManyInput = {
  create?: Maybe<Array<DirSportDisciplineGroupCreateInput>>;
  update?: Maybe<Array<DirSportDisciplineGroupUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportDisciplineGroupUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineGroupUpdateWithWhereUniqueNestedInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  data: DirSportDisciplineGroupUpdateDataInput;
};

export type DirSportDisciplineGroupUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplineGroupsInput>;
};

export type DirSportDisciplineGroupUpsertWithWhereUniqueNestedInput = {
  where: DirSportDisciplineGroupWhereUniqueInput;
  update: DirSportDisciplineGroupUpdateDataInput;
  create: DirSportDisciplineGroupCreateInput;
};

export type DirOrganizationSportUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationSportWhereUniqueInput;
  update: DirOrganizationSportUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationSportCreateWithoutDirOrganizationInput;
};

export type DirOrganizationSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  trainingStartDate_not?: Maybe<Scalars['DateTime']>;
  trainingStartDate_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStartDate_lt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_lte?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gt?: Maybe<Scalars['DateTime']>;
  trainingStartDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationSportScalarWhereInput>>;
};

export type DirOrganizationSportUpdateManyWithWhereNestedInput = {
  where: DirOrganizationSportScalarWhereInput;
  data: DirOrganizationSportUpdateManyDataInput;
};

export type DirOrganizationSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
};

export type DirOrganizationAddressUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationAddressCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationAddressWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationAddressUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationAddressUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationAddressUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationAddressUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationAddressWhereUniqueInput;
  data: DirOrganizationAddressUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationAddressUpdateWithoutDirOrganizationDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type AddressTypeUpdateOneRequiredInput = {
  create?: Maybe<AddressTypeCreateInput>;
  update?: Maybe<AddressTypeUpdateDataInput>;
  upsert?: Maybe<AddressTypeUpsertNestedInput>;
  connect?: Maybe<AddressTypeWhereUniqueInput>;
};

export type AddressTypeUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type AddressTypeUpsertNestedInput = {
  update: AddressTypeUpdateDataInput;
  create: AddressTypeCreateInput;
};

export type DirOrganizationAddressUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationAddressWhereUniqueInput;
  update: DirOrganizationAddressUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationAddressCreateWithoutDirOrganizationInput;
};

export type DirOrganizationAddressScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationAddressScalarWhereInput>>;
};

export type DirOrganizationAddressUpdateManyWithWhereNestedInput = {
  where: DirOrganizationAddressScalarWhereInput;
  data: DirOrganizationAddressUpdateManyDataInput;
};

export type DirOrganizationAddressUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationPhoneCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationPhoneWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationPhoneUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationPhoneUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationPhoneUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationPhoneUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationPhoneWhereUniqueInput;
  data: DirOrganizationPhoneUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationPhoneUpdateWithoutDirOrganizationDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationPhoneUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationPhoneWhereUniqueInput;
  update: DirOrganizationPhoneUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationPhoneCreateWithoutDirOrganizationInput;
};

export type DirOrganizationPhoneScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationPhoneScalarWhereInput>>;
};

export type DirOrganizationPhoneUpdateManyWithWhereNestedInput = {
  where: DirOrganizationPhoneScalarWhereInput;
  data: DirOrganizationPhoneUpdateManyDataInput;
};

export type DirOrganizationPhoneUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
};

export type DirOrganizationEmailUpdateManyWithoutDirOrganizationInput = {
  create?: Maybe<Array<DirOrganizationEmailCreateWithoutDirOrganizationInput>>;
  delete?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationEmailWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationEmailUpdateWithWhereUniqueWithoutDirOrganizationInput>>;
  upsert?: Maybe<Array<DirOrganizationEmailUpsertWithWhereUniqueWithoutDirOrganizationInput>>;
  deleteMany?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationEmailUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationEmailUpdateWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationEmailWhereUniqueInput;
  data: DirOrganizationEmailUpdateWithoutDirOrganizationDataInput;
};

export type DirOrganizationEmailUpdateWithoutDirOrganizationDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationEmailUpsertWithWhereUniqueWithoutDirOrganizationInput = {
  where: DirOrganizationEmailWhereUniqueInput;
  update: DirOrganizationEmailUpdateWithoutDirOrganizationDataInput;
  create: DirOrganizationEmailCreateWithoutDirOrganizationInput;
};

export type DirOrganizationEmailScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationEmailScalarWhereInput>>;
};

export type DirOrganizationEmailUpdateManyWithWhereNestedInput = {
  where: DirOrganizationEmailScalarWhereInput;
  data: DirOrganizationEmailUpdateManyDataInput;
};

export type DirOrganizationEmailUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};

export type DirOrganizationUpdatewebsitesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type DirOrganizationUpsertWithoutChildrenInput = {
  update: DirOrganizationUpdateWithoutChildrenDataInput;
  create: DirOrganizationCreateWithoutChildrenInput;
};

export type DirOrganizationUpdateManyWithoutParentInput = {
  create?: Maybe<Array<DirOrganizationCreateWithoutParentInput>>;
  delete?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  connect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  set?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirOrganizationWhereUniqueInput>>;
  update?: Maybe<Array<DirOrganizationUpdateWithWhereUniqueWithoutParentInput>>;
  upsert?: Maybe<Array<DirOrganizationUpsertWithWhereUniqueWithoutParentInput>>;
  deleteMany?: Maybe<Array<DirOrganizationScalarWhereInput>>;
  updateMany?: Maybe<Array<DirOrganizationUpdateManyWithWhereNestedInput>>;
};

export type DirOrganizationUpdateWithWhereUniqueWithoutParentInput = {
  where: DirOrganizationWhereUniqueInput;
  data: DirOrganizationUpdateWithoutParentDataInput;
};

export type DirOrganizationUpdateWithoutParentDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationUpsertWithWhereUniqueWithoutParentInput = {
  where: DirOrganizationWhereUniqueInput;
  update: DirOrganizationUpdateWithoutParentDataInput;
  create: DirOrganizationCreateWithoutParentInput;
};

export type DirOrganizationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  isLegalEntity_not?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  ogrn_not?: Maybe<Scalars['String']>;
  ogrn_in?: Maybe<Array<Scalars['String']>>;
  ogrn_not_in?: Maybe<Array<Scalars['String']>>;
  ogrn_lt?: Maybe<Scalars['String']>;
  ogrn_lte?: Maybe<Scalars['String']>;
  ogrn_gt?: Maybe<Scalars['String']>;
  ogrn_gte?: Maybe<Scalars['String']>;
  ogrn_contains?: Maybe<Scalars['String']>;
  ogrn_not_contains?: Maybe<Scalars['String']>;
  ogrn_starts_with?: Maybe<Scalars['String']>;
  ogrn_not_starts_with?: Maybe<Scalars['String']>;
  ogrn_ends_with?: Maybe<Scalars['String']>;
  ogrn_not_ends_with?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  registrationDate_not?: Maybe<Scalars['DateTime']>;
  registrationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  registrationDate_lt?: Maybe<Scalars['DateTime']>;
  registrationDate_lte?: Maybe<Scalars['DateTime']>;
  registrationDate_gt?: Maybe<Scalars['DateTime']>;
  registrationDate_gte?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  inn_not?: Maybe<Scalars['String']>;
  inn_in?: Maybe<Array<Scalars['String']>>;
  inn_not_in?: Maybe<Array<Scalars['String']>>;
  inn_lt?: Maybe<Scalars['String']>;
  inn_lte?: Maybe<Scalars['String']>;
  inn_gt?: Maybe<Scalars['String']>;
  inn_gte?: Maybe<Scalars['String']>;
  inn_contains?: Maybe<Scalars['String']>;
  inn_not_contains?: Maybe<Scalars['String']>;
  inn_starts_with?: Maybe<Scalars['String']>;
  inn_not_starts_with?: Maybe<Scalars['String']>;
  inn_ends_with?: Maybe<Scalars['String']>;
  inn_not_ends_with?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  kpp_not?: Maybe<Scalars['String']>;
  kpp_in?: Maybe<Array<Scalars['String']>>;
  kpp_not_in?: Maybe<Array<Scalars['String']>>;
  kpp_lt?: Maybe<Scalars['String']>;
  kpp_lte?: Maybe<Scalars['String']>;
  kpp_gt?: Maybe<Scalars['String']>;
  kpp_gte?: Maybe<Scalars['String']>;
  kpp_contains?: Maybe<Scalars['String']>;
  kpp_not_contains?: Maybe<Scalars['String']>;
  kpp_starts_with?: Maybe<Scalars['String']>;
  kpp_not_starts_with?: Maybe<Scalars['String']>;
  kpp_ends_with?: Maybe<Scalars['String']>;
  kpp_not_ends_with?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  legalAddress_not?: Maybe<Scalars['String']>;
  legalAddress_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_not_in?: Maybe<Array<Scalars['String']>>;
  legalAddress_lt?: Maybe<Scalars['String']>;
  legalAddress_lte?: Maybe<Scalars['String']>;
  legalAddress_gt?: Maybe<Scalars['String']>;
  legalAddress_gte?: Maybe<Scalars['String']>;
  legalAddress_contains?: Maybe<Scalars['String']>;
  legalAddress_not_contains?: Maybe<Scalars['String']>;
  legalAddress_starts_with?: Maybe<Scalars['String']>;
  legalAddress_not_starts_with?: Maybe<Scalars['String']>;
  legalAddress_ends_with?: Maybe<Scalars['String']>;
  legalAddress_not_ends_with?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  postcode_not?: Maybe<Scalars['String']>;
  postcode_in?: Maybe<Array<Scalars['String']>>;
  postcode_not_in?: Maybe<Array<Scalars['String']>>;
  postcode_lt?: Maybe<Scalars['String']>;
  postcode_lte?: Maybe<Scalars['String']>;
  postcode_gt?: Maybe<Scalars['String']>;
  postcode_gte?: Maybe<Scalars['String']>;
  postcode_contains?: Maybe<Scalars['String']>;
  postcode_not_contains?: Maybe<Scalars['String']>;
  postcode_starts_with?: Maybe<Scalars['String']>;
  postcode_not_starts_with?: Maybe<Scalars['String']>;
  postcode_ends_with?: Maybe<Scalars['String']>;
  postcode_not_ends_with?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headPosition_not?: Maybe<Scalars['String']>;
  headPosition_in?: Maybe<Array<Scalars['String']>>;
  headPosition_not_in?: Maybe<Array<Scalars['String']>>;
  headPosition_lt?: Maybe<Scalars['String']>;
  headPosition_lte?: Maybe<Scalars['String']>;
  headPosition_gt?: Maybe<Scalars['String']>;
  headPosition_gte?: Maybe<Scalars['String']>;
  headPosition_contains?: Maybe<Scalars['String']>;
  headPosition_not_contains?: Maybe<Scalars['String']>;
  headPosition_starts_with?: Maybe<Scalars['String']>;
  headPosition_not_starts_with?: Maybe<Scalars['String']>;
  headPosition_ends_with?: Maybe<Scalars['String']>;
  headPosition_not_ends_with?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  headFullName_not?: Maybe<Scalars['String']>;
  headFullName_in?: Maybe<Array<Scalars['String']>>;
  headFullName_not_in?: Maybe<Array<Scalars['String']>>;
  headFullName_lt?: Maybe<Scalars['String']>;
  headFullName_lte?: Maybe<Scalars['String']>;
  headFullName_gt?: Maybe<Scalars['String']>;
  headFullName_gte?: Maybe<Scalars['String']>;
  headFullName_contains?: Maybe<Scalars['String']>;
  headFullName_not_contains?: Maybe<Scalars['String']>;
  headFullName_starts_with?: Maybe<Scalars['String']>;
  headFullName_not_starts_with?: Maybe<Scalars['String']>;
  headFullName_ends_with?: Maybe<Scalars['String']>;
  headFullName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirOrganizationScalarWhereInput>>;
  OR?: Maybe<Array<DirOrganizationScalarWhereInput>>;
  NOT?: Maybe<Array<DirOrganizationScalarWhereInput>>;
};

export type DirOrganizationUpdateManyWithWhereNestedInput = {
  where: DirOrganizationScalarWhereInput;
  data: DirOrganizationUpdateManyDataInput;
};

export type DirOrganizationUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationUpsertNestedInput = {
  update: DirOrganizationUpdateDataInput;
  create: DirOrganizationCreateInput;
};

export type RegionalSchoolProfileUpsertNestedInput = {
  update: RegionalSchoolProfileUpdateDataInput;
  create: RegionalSchoolProfileCreateInput;
};

export type UserUpsertWithoutPersonProfileInput = {
  update: UserUpdateWithoutPersonProfileDataInput;
  create: UserCreateWithoutPersonProfileInput;
};

export type DirAthleteUpdateOneWithoutDirPersonInput = {
  create?: Maybe<DirAthleteCreateWithoutDirPersonInput>;
  update?: Maybe<DirAthleteUpdateWithoutDirPersonDataInput>;
  upsert?: Maybe<DirAthleteUpsertWithoutDirPersonInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirPersonUpdateOneInput = {
  create?: Maybe<DirPersonCreateInput>;
  update?: Maybe<DirPersonUpdateDataInput>;
  upsert?: Maybe<DirPersonUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonUpdateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonCreateWithoutPrimaryInput>>;
  delete?: Maybe<Array<DirPersonWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonUpdateWithWhereUniqueWithoutPrimaryInput>>;
  upsert?: Maybe<Array<DirPersonUpsertWithWhereUniqueWithoutPrimaryInput>>;
  deleteMany?: Maybe<Array<DirPersonScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonUpdateManyWithWhereNestedInput>>;
};

export type DirPersonUpdateWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonWhereUniqueInput;
  data: DirPersonUpdateWithoutPrimaryDataInput;
};

export type DirPersonUpdateWithoutPrimaryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirTrainerUpdateOneWithoutDirPersonInput = {
  create?: Maybe<DirTrainerCreateWithoutDirPersonInput>;
  update?: Maybe<DirTrainerUpdateWithoutDirPersonDataInput>;
  upsert?: Maybe<DirTrainerUpsertWithoutDirPersonInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirTrainerSportUpdateManyWithoutDirTrainerInput>;
};

export type DirTrainerSportUpdateManyWithoutDirTrainerInput = {
  create?: Maybe<Array<DirTrainerSportCreateWithoutDirTrainerInput>>;
  delete?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerSportWhereUniqueInput>>;
  update?: Maybe<Array<DirTrainerSportUpdateWithWhereUniqueWithoutDirTrainerInput>>;
  upsert?: Maybe<Array<DirTrainerSportUpsertWithWhereUniqueWithoutDirTrainerInput>>;
  deleteMany?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerSportUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerSportUpdateWithWhereUniqueWithoutDirTrainerInput = {
  where: DirTrainerSportWhereUniqueInput;
  data: DirTrainerSportUpdateWithoutDirTrainerDataInput;
};

export type DirTrainerSportUpdateWithoutDirTrainerDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  organizations?: Maybe<DirTrainerSportOrganizationUpdateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryUpdateManyWithoutSportInput>;
};

export type DirTrainerSportOrganizationUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportOrganizationCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerSportOrganizationWhereUniqueInput>>;
  update?: Maybe<Array<DirTrainerSportOrganizationUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirTrainerSportOrganizationUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerSportOrganizationUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerSportOrganizationUpdateWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
  data: DirTrainerSportOrganizationUpdateWithoutSportDataInput;
};

export type DirTrainerSportOrganizationUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerSportOrganizationUpsertWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportOrganizationWhereUniqueInput;
  update: DirTrainerSportOrganizationUpdateWithoutSportDataInput;
  create: DirTrainerSportOrganizationCreateWithoutSportInput;
};

export type DirTrainerSportOrganizationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  employmentDate_not?: Maybe<Scalars['DateTime']>;
  employmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  employmentDate_lt?: Maybe<Scalars['DateTime']>;
  employmentDate_lte?: Maybe<Scalars['DateTime']>;
  employmentDate_gt?: Maybe<Scalars['DateTime']>;
  employmentDate_gte?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
  dismissalDate_not?: Maybe<Scalars['DateTime']>;
  dismissalDate_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  dismissalDate_lt?: Maybe<Scalars['DateTime']>;
  dismissalDate_lte?: Maybe<Scalars['DateTime']>;
  dismissalDate_gt?: Maybe<Scalars['DateTime']>;
  dismissalDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportOrganizationScalarWhereInput>>;
};

export type DirTrainerSportOrganizationUpdateManyWithWhereNestedInput = {
  where: DirTrainerSportOrganizationScalarWhereInput;
  data: DirTrainerSportOrganizationUpdateManyDataInput;
};

export type DirTrainerSportOrganizationUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerSportCategoryUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirTrainerSportCategoryCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerSportCategoryWhereUniqueInput>>;
  update?: Maybe<Array<DirTrainerSportCategoryUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirTrainerSportCategoryUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerSportCategoryUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerSportCategoryUpdateWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportCategoryWhereUniqueInput;
  data: DirTrainerSportCategoryUpdateWithoutSportDataInput;
};

export type DirTrainerSportCategoryUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
};

export type DirTrainerSportCategoryUpsertWithWhereUniqueWithoutSportInput = {
  where: DirTrainerSportCategoryWhereUniqueInput;
  update: DirTrainerSportCategoryUpdateWithoutSportDataInput;
  create: DirTrainerSportCategoryCreateWithoutSportInput;
};

export type DirTrainerSportCategoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportCategoryScalarWhereInput>>;
};

export type DirTrainerSportCategoryUpdateManyWithWhereNestedInput = {
  where: DirTrainerSportCategoryScalarWhereInput;
  data: DirTrainerSportCategoryUpdateManyDataInput;
};

export type DirTrainerSportCategoryUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
};

export type DirTrainerSportUpsertWithWhereUniqueWithoutDirTrainerInput = {
  where: DirTrainerSportWhereUniqueInput;
  update: DirTrainerSportUpdateWithoutDirTrainerDataInput;
  create: DirTrainerSportCreateWithoutDirTrainerInput;
};

export type DirTrainerSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerSportScalarWhereInput>>;
};

export type DirTrainerSportUpdateManyWithWhereNestedInput = {
  where: DirTrainerSportScalarWhereInput;
  data: DirTrainerSportUpdateManyDataInput;
};

export type DirTrainerSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirTrainerUpsertWithoutDirPersonInput = {
  update: DirTrainerUpdateWithoutDirPersonDataInput;
  create: DirTrainerCreateWithoutDirPersonInput;
};

export type DirRefereeUpdateOneWithoutDirPersonInput = {
  create?: Maybe<DirRefereeCreateWithoutDirPersonInput>;
  update?: Maybe<DirRefereeUpdateWithoutDirPersonDataInput>;
  upsert?: Maybe<DirRefereeUpsertWithoutDirPersonInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sports?: Maybe<DirRefereeSportUpdateManyWithoutDirRefereeInput>;
};

export type DirRefereeSportUpdateManyWithoutDirRefereeInput = {
  create?: Maybe<Array<DirRefereeSportCreateWithoutDirRefereeInput>>;
  delete?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  set?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRefereeSportWhereUniqueInput>>;
  update?: Maybe<Array<DirRefereeSportUpdateWithWhereUniqueWithoutDirRefereeInput>>;
  upsert?: Maybe<Array<DirRefereeSportUpsertWithWhereUniqueWithoutDirRefereeInput>>;
  deleteMany?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRefereeSportUpdateManyWithWhereNestedInput>>;
};

export type DirRefereeSportUpdateWithWhereUniqueWithoutDirRefereeInput = {
  where: DirRefereeSportWhereUniqueInput;
  data: DirRefereeSportUpdateWithoutDirRefereeDataInput;
};

export type DirRefereeSportUpdateWithoutDirRefereeDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryUpdateManyWithoutSportInput>;
};

export type DirRefereeSportCategoryUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirRefereeSportCategoryCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  set?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirRefereeSportCategoryWhereUniqueInput>>;
  update?: Maybe<Array<DirRefereeSportCategoryUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirRefereeSportCategoryUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirRefereeSportCategoryUpdateManyWithWhereNestedInput>>;
};

export type DirRefereeSportCategoryUpdateWithWhereUniqueWithoutSportInput = {
  where: DirRefereeSportCategoryWhereUniqueInput;
  data: DirRefereeSportCategoryUpdateWithoutSportDataInput;
};

export type DirRefereeSportCategoryUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRefereeCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
};

export type DirDocumentUpdateOneInput = {
  create?: Maybe<DirDocumentCreateInput>;
  update?: Maybe<DirDocumentUpdateDataInput>;
  upsert?: Maybe<DirDocumentUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirDocumentWhereUniqueInput>;
};

export type DirDocumentUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  file?: Maybe<FileUpdateOneRequiredInput>;
};

export type FileUpdateOneRequiredInput = {
  create?: Maybe<FileCreateInput>;
  update?: Maybe<FileUpdateDataInput>;
  upsert?: Maybe<FileUpsertNestedInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type FileUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type FileUpsertNestedInput = {
  update: FileUpdateDataInput;
  create: FileCreateInput;
};

export type DirDocumentUpsertNestedInput = {
  update: DirDocumentUpdateDataInput;
  create: DirDocumentCreateInput;
};

export type DirRefereeSportCategoryUpsertWithWhereUniqueWithoutSportInput = {
  where: DirRefereeSportCategoryWhereUniqueInput;
  update: DirRefereeSportCategoryUpdateWithoutSportDataInput;
  create: DirRefereeSportCategoryCreateWithoutSportInput;
};

export type DirRefereeSportCategoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  isEndless_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportCategoryScalarWhereInput>>;
};

export type DirRefereeSportCategoryUpdateManyWithWhereNestedInput = {
  where: DirRefereeSportCategoryScalarWhereInput;
  data: DirRefereeSportCategoryUpdateManyDataInput;
};

export type DirRefereeSportCategoryUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
};

export type DirRefereeSportUpsertWithWhereUniqueWithoutDirRefereeInput = {
  where: DirRefereeSportWhereUniqueInput;
  update: DirRefereeSportUpdateWithoutDirRefereeDataInput;
  create: DirRefereeSportCreateWithoutDirRefereeInput;
};

export type DirRefereeSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  workExperience?: Maybe<Scalars['Int']>;
  workExperience_not?: Maybe<Scalars['Int']>;
  workExperience_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_not_in?: Maybe<Array<Scalars['Int']>>;
  workExperience_lt?: Maybe<Scalars['Int']>;
  workExperience_lte?: Maybe<Scalars['Int']>;
  workExperience_gt?: Maybe<Scalars['Int']>;
  workExperience_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
  OR?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirRefereeSportScalarWhereInput>>;
};

export type DirRefereeSportUpdateManyWithWhereNestedInput = {
  where: DirRefereeSportScalarWhereInput;
  data: DirRefereeSportUpdateManyDataInput;
};

export type DirRefereeSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  workExperience?: Maybe<Scalars['Int']>;
};

export type DirRefereeUpsertWithoutDirPersonInput = {
  update: DirRefereeUpdateWithoutDirPersonDataInput;
  create: DirRefereeCreateWithoutDirPersonInput;
};

export type DirPersonConfirmStatusUpdateOneRequiredInput = {
  create?: Maybe<DirPersonConfirmStatusCreateInput>;
  update?: Maybe<DirPersonConfirmStatusUpdateDataInput>;
  upsert?: Maybe<DirPersonConfirmStatusUpsertNestedInput>;
  connect?: Maybe<DirPersonConfirmStatusWhereUniqueInput>;
};

export type DirPersonConfirmStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DirPersonConfirmStatusUpsertNestedInput = {
  update: DirPersonConfirmStatusUpdateDataInput;
  create: DirPersonConfirmStatusCreateInput;
};

export type ClassifierValueUpdateOneInput = {
  create?: Maybe<ClassifierValueCreateInput>;
  update?: Maybe<ClassifierValueUpdateDataInput>;
  upsert?: Maybe<ClassifierValueUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ClassifierValueWhereUniqueInput>;
};

export type DirCountryUpdateManyInput = {
  create?: Maybe<Array<DirCountryCreateInput>>;
  update?: Maybe<Array<DirCountryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirCountryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirCountryWhereUniqueInput>>;
  connect?: Maybe<Array<DirCountryWhereUniqueInput>>;
  set?: Maybe<Array<DirCountryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirCountryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirCountryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirCountryUpdateManyWithWhereNestedInput>>;
};

export type DirCountryUpdateWithWhereUniqueNestedInput = {
  where: DirCountryWhereUniqueInput;
  data: DirCountryUpdateDataInput;
};

export type DirCountryUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirForeignCities?: Maybe<DirForeignCityUpdateManyWithoutDirCountryInput>;
};

export type DirForeignCityUpdateManyWithoutDirCountryInput = {
  create?: Maybe<Array<DirForeignCityCreateWithoutDirCountryInput>>;
  delete?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  connect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  set?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirForeignCityWhereUniqueInput>>;
  update?: Maybe<Array<DirForeignCityUpdateWithWhereUniqueWithoutDirCountryInput>>;
  upsert?: Maybe<Array<DirForeignCityUpsertWithWhereUniqueWithoutDirCountryInput>>;
  deleteMany?: Maybe<Array<DirForeignCityScalarWhereInput>>;
  updateMany?: Maybe<Array<DirForeignCityUpdateManyWithWhereNestedInput>>;
};

export type DirForeignCityUpdateWithWhereUniqueWithoutDirCountryInput = {
  where: DirForeignCityWhereUniqueInput;
  data: DirForeignCityUpdateWithoutDirCountryDataInput;
};

export type DirForeignCityUpdateWithoutDirCountryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirForeignCityUpsertWithWhereUniqueWithoutDirCountryInput = {
  where: DirForeignCityWhereUniqueInput;
  update: DirForeignCityUpdateWithoutDirCountryDataInput;
  create: DirForeignCityCreateWithoutDirCountryInput;
};

export type DirForeignCityScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirForeignCityScalarWhereInput>>;
  OR?: Maybe<Array<DirForeignCityScalarWhereInput>>;
  NOT?: Maybe<Array<DirForeignCityScalarWhereInput>>;
};

export type DirForeignCityUpdateManyWithWhereNestedInput = {
  where: DirForeignCityScalarWhereInput;
  data: DirForeignCityUpdateManyDataInput;
};

export type DirForeignCityUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirCountryUpsertWithWhereUniqueNestedInput = {
  where: DirCountryWhereUniqueInput;
  update: DirCountryUpdateDataInput;
  create: DirCountryCreateInput;
};

export type DirCountryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirCountryScalarWhereInput>>;
  OR?: Maybe<Array<DirCountryScalarWhereInput>>;
  NOT?: Maybe<Array<DirCountryScalarWhereInput>>;
};

export type DirCountryUpdateManyWithWhereNestedInput = {
  where: DirCountryScalarWhereInput;
  data: DirCountryUpdateManyDataInput;
};

export type DirCountryUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirForeignCityUpdateOneInput = {
  create?: Maybe<DirForeignCityCreateInput>;
  update?: Maybe<DirForeignCityUpdateDataInput>;
  upsert?: Maybe<DirForeignCityUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirForeignCityWhereUniqueInput>;
};

export type DirForeignCityUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirCountry?: Maybe<DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput>;
};

export type DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput = {
  create?: Maybe<DirCountryCreateWithoutDirForeignCitiesInput>;
  update?: Maybe<DirCountryUpdateWithoutDirForeignCitiesDataInput>;
  upsert?: Maybe<DirCountryUpsertWithoutDirForeignCitiesInput>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirCountryUpdateWithoutDirForeignCitiesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type DirCountryUpsertWithoutDirForeignCitiesInput = {
  update: DirCountryUpdateWithoutDirForeignCitiesDataInput;
  create: DirCountryCreateWithoutDirForeignCitiesInput;
};

export type DirForeignCityUpsertNestedInput = {
  update: DirForeignCityUpdateDataInput;
  create: DirForeignCityCreateInput;
};

export type DirCountryUpdateOneInput = {
  create?: Maybe<DirCountryCreateInput>;
  update?: Maybe<DirCountryUpdateDataInput>;
  upsert?: Maybe<DirCountryUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirCountryWhereUniqueInput>;
};

export type DirCountryUpsertNestedInput = {
  update: DirCountryUpdateDataInput;
  create: DirCountryCreateInput;
};

export type DirPersonPhoneUpdateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonPhoneCreateWithoutDirPersonInput>>;
  delete?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonPhoneWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonPhoneUpdateWithWhereUniqueWithoutDirPersonInput>>;
  upsert?: Maybe<Array<DirPersonPhoneUpsertWithWhereUniqueWithoutDirPersonInput>>;
  deleteMany?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonPhoneUpdateManyWithWhereNestedInput>>;
};

export type DirPersonPhoneUpdateWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonPhoneWhereUniqueInput;
  data: DirPersonPhoneUpdateWithoutDirPersonDataInput;
};

export type DirPersonPhoneUpdateWithoutDirPersonDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirPersonPhoneUpsertWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonPhoneWhereUniqueInput;
  update: DirPersonPhoneUpdateWithoutDirPersonDataInput;
  create: DirPersonPhoneCreateWithoutDirPersonInput;
};

export type DirPersonPhoneScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  operatorCode_not?: Maybe<Scalars['String']>;
  operatorCode_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_not_in?: Maybe<Array<Scalars['String']>>;
  operatorCode_lt?: Maybe<Scalars['String']>;
  operatorCode_lte?: Maybe<Scalars['String']>;
  operatorCode_gt?: Maybe<Scalars['String']>;
  operatorCode_gte?: Maybe<Scalars['String']>;
  operatorCode_contains?: Maybe<Scalars['String']>;
  operatorCode_not_contains?: Maybe<Scalars['String']>;
  operatorCode_starts_with?: Maybe<Scalars['String']>;
  operatorCode_not_starts_with?: Maybe<Scalars['String']>;
  operatorCode_ends_with?: Maybe<Scalars['String']>;
  operatorCode_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  additionalNumber_not?: Maybe<Scalars['String']>;
  additionalNumber_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_not_in?: Maybe<Array<Scalars['String']>>;
  additionalNumber_lt?: Maybe<Scalars['String']>;
  additionalNumber_lte?: Maybe<Scalars['String']>;
  additionalNumber_gt?: Maybe<Scalars['String']>;
  additionalNumber_gte?: Maybe<Scalars['String']>;
  additionalNumber_contains?: Maybe<Scalars['String']>;
  additionalNumber_not_contains?: Maybe<Scalars['String']>;
  additionalNumber_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_not_starts_with?: Maybe<Scalars['String']>;
  additionalNumber_ends_with?: Maybe<Scalars['String']>;
  additionalNumber_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonPhoneScalarWhereInput>>;
};

export type DirPersonPhoneUpdateManyWithWhereNestedInput = {
  where: DirPersonPhoneScalarWhereInput;
  data: DirPersonPhoneUpdateManyDataInput;
};

export type DirPersonPhoneUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
};

export type DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAdditionalRegionCreateWithoutDirPersonInput>>;
  delete?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonAdditionalRegionWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonAdditionalRegionUpdateWithWhereUniqueWithoutDirPersonInput>>;
  upsert?: Maybe<Array<DirPersonAdditionalRegionUpsertWithWhereUniqueWithoutDirPersonInput>>;
  deleteMany?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonAdditionalRegionUpdateManyWithWhereNestedInput>>;
};

export type DirPersonAdditionalRegionUpdateWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
  data: DirPersonAdditionalRegionUpdateWithoutDirPersonDataInput;
};

export type DirPersonAdditionalRegionUpdateWithoutDirPersonDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
};

export type DirPersonAdditionalRegionUpsertWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAdditionalRegionWhereUniqueInput;
  update: DirPersonAdditionalRegionUpdateWithoutDirPersonDataInput;
  create: DirPersonAdditionalRegionCreateWithoutDirPersonInput;
};

export type DirPersonAdditionalRegionScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonAdditionalRegionScalarWhereInput>>;
};

export type DirPersonAdditionalRegionUpdateManyWithWhereNestedInput = {
  where: DirPersonAdditionalRegionScalarWhereInput;
  data: DirPersonAdditionalRegionUpdateManyDataInput;
};

export type DirPersonAdditionalRegionUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
};

export type DirPersonMergeTaskUpdateManyWithoutPrimaryInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutPrimaryInput>>;
  delete?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonMergeTaskUpdateWithWhereUniqueWithoutPrimaryInput>>;
  upsert?: Maybe<Array<DirPersonMergeTaskUpsertWithWhereUniqueWithoutPrimaryInput>>;
  deleteMany?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonMergeTaskUpdateManyWithWhereNestedInput>>;
};

export type DirPersonMergeTaskUpdateWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  data: DirPersonMergeTaskUpdateWithoutPrimaryDataInput;
};

export type DirPersonMergeTaskUpdateWithoutPrimaryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  status?: Maybe<TaskStatusUpdateOneRequiredInput>;
  comment?: Maybe<Scalars['String']>;
  duplicate?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsDuplicateInput>;
};

export type TaskStatusUpdateOneRequiredInput = {
  create?: Maybe<TaskStatusCreateInput>;
  update?: Maybe<TaskStatusUpdateDataInput>;
  upsert?: Maybe<TaskStatusUpsertNestedInput>;
  connect?: Maybe<TaskStatusWhereUniqueInput>;
};

export type TaskStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TaskStatusUpsertNestedInput = {
  update: TaskStatusUpdateDataInput;
  create: TaskStatusCreateInput;
};

export type DirPersonUpdateOneRequiredWithoutMergeTasksAsDuplicateInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsDuplicateInput>;
  update?: Maybe<DirPersonUpdateWithoutMergeTasksAsDuplicateDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutMergeTasksAsDuplicateInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutMergeTasksAsDuplicateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonAddressUpdateManyWithoutDirPersonInput = {
  create?: Maybe<Array<DirPersonAddressCreateWithoutDirPersonInput>>;
  delete?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonAddressWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonAddressUpdateWithWhereUniqueWithoutDirPersonInput>>;
  upsert?: Maybe<Array<DirPersonAddressUpsertWithWhereUniqueWithoutDirPersonInput>>;
  deleteMany?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonAddressUpdateManyWithWhereNestedInput>>;
};

export type DirPersonAddressUpdateWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAddressWhereUniqueInput;
  data: DirPersonAddressUpdateWithoutDirPersonDataInput;
};

export type DirPersonAddressUpdateWithoutDirPersonDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type DirPersonAddressUpsertWithWhereUniqueWithoutDirPersonInput = {
  where: DirPersonAddressWhereUniqueInput;
  update: DirPersonAddressUpdateWithoutDirPersonDataInput;
  create: DirPersonAddressCreateWithoutDirPersonInput;
};

export type DirPersonAddressScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Scalars['String']>>;
  place_not_in?: Maybe<Array<Scalars['String']>>;
  place_lt?: Maybe<Scalars['String']>;
  place_lte?: Maybe<Scalars['String']>;
  place_gt?: Maybe<Scalars['String']>;
  place_gte?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_not?: Maybe<Scalars['String']>;
  street_in?: Maybe<Array<Scalars['String']>>;
  street_not_in?: Maybe<Array<Scalars['String']>>;
  street_lt?: Maybe<Scalars['String']>;
  street_lte?: Maybe<Scalars['String']>;
  street_gt?: Maybe<Scalars['String']>;
  street_gte?: Maybe<Scalars['String']>;
  street_contains?: Maybe<Scalars['String']>;
  street_not_contains?: Maybe<Scalars['String']>;
  street_starts_with?: Maybe<Scalars['String']>;
  street_not_starts_with?: Maybe<Scalars['String']>;
  street_ends_with?: Maybe<Scalars['String']>;
  street_not_ends_with?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  house_not?: Maybe<Scalars['String']>;
  house_in?: Maybe<Array<Scalars['String']>>;
  house_not_in?: Maybe<Array<Scalars['String']>>;
  house_lt?: Maybe<Scalars['String']>;
  house_lte?: Maybe<Scalars['String']>;
  house_gt?: Maybe<Scalars['String']>;
  house_gte?: Maybe<Scalars['String']>;
  house_contains?: Maybe<Scalars['String']>;
  house_not_contains?: Maybe<Scalars['String']>;
  house_starts_with?: Maybe<Scalars['String']>;
  house_not_starts_with?: Maybe<Scalars['String']>;
  house_ends_with?: Maybe<Scalars['String']>;
  house_not_ends_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonAddressScalarWhereInput>>;
};

export type DirPersonAddressUpdateManyWithWhereNestedInput = {
  where: DirPersonAddressScalarWhereInput;
  data: DirPersonAddressUpdateManyDataInput;
};

export type DirPersonAddressUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirPersonUpsertWithoutMergeTasksAsDuplicateInput = {
  update: DirPersonUpdateWithoutMergeTasksAsDuplicateDataInput;
  create: DirPersonCreateWithoutMergeTasksAsDuplicateInput;
};

export type DirPersonMergeTaskUpsertWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  update: DirPersonMergeTaskUpdateWithoutPrimaryDataInput;
  create: DirPersonMergeTaskCreateWithoutPrimaryInput;
};

export type DirPersonMergeTaskScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
};

export type DirPersonMergeTaskUpdateManyWithWhereNestedInput = {
  where: DirPersonMergeTaskScalarWhereInput;
  data: DirPersonMergeTaskUpdateManyDataInput;
};

export type DirPersonMergeTaskUpdateManyDataInput = {
  comment?: Maybe<Scalars['String']>;
};

export type DirPersonMergeTaskUpdateManyWithoutDuplicateInput = {
  create?: Maybe<Array<DirPersonMergeTaskCreateWithoutDuplicateInput>>;
  delete?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  connect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  set?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirPersonMergeTaskWhereUniqueInput>>;
  update?: Maybe<Array<DirPersonMergeTaskUpdateWithWhereUniqueWithoutDuplicateInput>>;
  upsert?: Maybe<Array<DirPersonMergeTaskUpsertWithWhereUniqueWithoutDuplicateInput>>;
  deleteMany?: Maybe<Array<DirPersonMergeTaskScalarWhereInput>>;
  updateMany?: Maybe<Array<DirPersonMergeTaskUpdateManyWithWhereNestedInput>>;
};

export type DirPersonMergeTaskUpdateWithWhereUniqueWithoutDuplicateInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  data: DirPersonMergeTaskUpdateWithoutDuplicateDataInput;
};

export type DirPersonMergeTaskUpdateWithoutDuplicateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  status?: Maybe<TaskStatusUpdateOneRequiredInput>;
  comment?: Maybe<Scalars['String']>;
  primary?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsPrimaryInput>;
};

export type DirPersonUpdateOneRequiredWithoutMergeTasksAsPrimaryInput = {
  create?: Maybe<DirPersonCreateWithoutMergeTasksAsPrimaryInput>;
  update?: Maybe<DirPersonUpdateWithoutMergeTasksAsPrimaryDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutMergeTasksAsPrimaryInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutMergeTasksAsPrimaryDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonUpsertWithoutMergeTasksAsPrimaryInput = {
  update: DirPersonUpdateWithoutMergeTasksAsPrimaryDataInput;
  create: DirPersonCreateWithoutMergeTasksAsPrimaryInput;
};

export type DirPersonMergeTaskUpsertWithWhereUniqueWithoutDuplicateInput = {
  where: DirPersonMergeTaskWhereUniqueInput;
  update: DirPersonMergeTaskUpdateWithoutDuplicateDataInput;
  create: DirPersonMergeTaskCreateWithoutDuplicateInput;
};

export type DirPersonUpsertWithWhereUniqueWithoutPrimaryInput = {
  where: DirPersonWhereUniqueInput;
  update: DirPersonUpdateWithoutPrimaryDataInput;
  create: DirPersonCreateWithoutPrimaryInput;
};

export type DirPersonScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  mergedAt_not?: Maybe<Scalars['DateTime']>;
  mergedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  mergedAt_lt?: Maybe<Scalars['DateTime']>;
  mergedAt_lte?: Maybe<Scalars['DateTime']>;
  mergedAt_gt?: Maybe<Scalars['DateTime']>;
  mergedAt_gte?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  previousLastname_not?: Maybe<Scalars['String']>;
  previousLastname_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_not_in?: Maybe<Array<Scalars['String']>>;
  previousLastname_lt?: Maybe<Scalars['String']>;
  previousLastname_lte?: Maybe<Scalars['String']>;
  previousLastname_gt?: Maybe<Scalars['String']>;
  previousLastname_gte?: Maybe<Scalars['String']>;
  previousLastname_contains?: Maybe<Scalars['String']>;
  previousLastname_not_contains?: Maybe<Scalars['String']>;
  previousLastname_starts_with?: Maybe<Scalars['String']>;
  previousLastname_not_starts_with?: Maybe<Scalars['String']>;
  previousLastname_ends_with?: Maybe<Scalars['String']>;
  previousLastname_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  isMale_not?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  snils_not?: Maybe<Scalars['String']>;
  snils_in?: Maybe<Array<Scalars['String']>>;
  snils_not_in?: Maybe<Array<Scalars['String']>>;
  snils_lt?: Maybe<Scalars['String']>;
  snils_lte?: Maybe<Scalars['String']>;
  snils_gt?: Maybe<Scalars['String']>;
  snils_gte?: Maybe<Scalars['String']>;
  snils_contains?: Maybe<Scalars['String']>;
  snils_not_contains?: Maybe<Scalars['String']>;
  snils_starts_with?: Maybe<Scalars['String']>;
  snils_not_starts_with?: Maybe<Scalars['String']>;
  snils_ends_with?: Maybe<Scalars['String']>;
  snils_not_ends_with?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportSerial_not?: Maybe<Scalars['String']>;
  passportSerial_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_not_in?: Maybe<Array<Scalars['String']>>;
  passportSerial_lt?: Maybe<Scalars['String']>;
  passportSerial_lte?: Maybe<Scalars['String']>;
  passportSerial_gt?: Maybe<Scalars['String']>;
  passportSerial_gte?: Maybe<Scalars['String']>;
  passportSerial_contains?: Maybe<Scalars['String']>;
  passportSerial_not_contains?: Maybe<Scalars['String']>;
  passportSerial_starts_with?: Maybe<Scalars['String']>;
  passportSerial_not_starts_with?: Maybe<Scalars['String']>;
  passportSerial_ends_with?: Maybe<Scalars['String']>;
  passportSerial_not_ends_with?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportNumber_not?: Maybe<Scalars['String']>;
  passportNumber_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_not_in?: Maybe<Array<Scalars['String']>>;
  passportNumber_lt?: Maybe<Scalars['String']>;
  passportNumber_lte?: Maybe<Scalars['String']>;
  passportNumber_gt?: Maybe<Scalars['String']>;
  passportNumber_gte?: Maybe<Scalars['String']>;
  passportNumber_contains?: Maybe<Scalars['String']>;
  passportNumber_not_contains?: Maybe<Scalars['String']>;
  passportNumber_starts_with?: Maybe<Scalars['String']>;
  passportNumber_not_starts_with?: Maybe<Scalars['String']>;
  passportNumber_ends_with?: Maybe<Scalars['String']>;
  passportNumber_not_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssuedBy_not?: Maybe<Scalars['String']>;
  passportIssuedBy_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_not_in?: Maybe<Array<Scalars['String']>>;
  passportIssuedBy_lt?: Maybe<Scalars['String']>;
  passportIssuedBy_lte?: Maybe<Scalars['String']>;
  passportIssuedBy_gt?: Maybe<Scalars['String']>;
  passportIssuedBy_gte?: Maybe<Scalars['String']>;
  passportIssuedBy_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_not_contains?: Maybe<Scalars['String']>;
  passportIssuedBy_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_starts_with?: Maybe<Scalars['String']>;
  passportIssuedBy_ends_with?: Maybe<Scalars['String']>;
  passportIssuedBy_not_ends_with?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  passportIssueDate_not?: Maybe<Scalars['DateTime']>;
  passportIssueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  passportIssueDate_lt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_lte?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gt?: Maybe<Scalars['DateTime']>;
  passportIssueDate_gte?: Maybe<Scalars['DateTime']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirPersonScalarWhereInput>>;
  OR?: Maybe<Array<DirPersonScalarWhereInput>>;
  NOT?: Maybe<Array<DirPersonScalarWhereInput>>;
};

export type DirPersonUpdateManyWithWhereNestedInput = {
  where: DirPersonScalarWhereInput;
  data: DirPersonUpdateManyDataInput;
};

export type DirPersonUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  isRussia?: Maybe<Scalars['Boolean']>;
};

export type DirPersonUpsertNestedInput = {
  update: DirPersonUpdateDataInput;
  create: DirPersonCreateInput;
};

export type DirAthleteSportUpdateManyInput = {
  create?: Maybe<Array<DirAthleteSportCreateInput>>;
  update?: Maybe<Array<DirAthleteSportUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirAthleteSportUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteSportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteSportUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteSportUpdateWithWhereUniqueNestedInput = {
  where: DirAthleteSportWhereUniqueInput;
  data: DirAthleteSportUpdateDataInput;
};

export type DirAthleteSportUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  dirTrainers?: Maybe<DirTrainerUpdateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationUpdateManyInput>;
  ranksAndAwards?: Maybe<DirAthleteSportRankAndAwardUpdateManyWithoutSportInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirSportDisciplineUpdateManyInput = {
  create?: Maybe<Array<DirSportDisciplineCreateInput>>;
  update?: Maybe<Array<DirSportDisciplineUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportDisciplineUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  set?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportDisciplineScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportDisciplineUpdateManyWithWhereNestedInput>>;
};

export type DirSportDisciplineUpdateWithWhereUniqueNestedInput = {
  where: DirSportDisciplineWhereUniqueInput;
  data: DirSportDisciplineUpdateDataInput;
};

export type DirSportDisciplineUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplinesInput>;
};

export type DirSportDisciplineUpsertWithWhereUniqueNestedInput = {
  where: DirSportDisciplineWhereUniqueInput;
  update: DirSportDisciplineUpdateDataInput;
  create: DirSportDisciplineCreateInput;
};

export type DirTrainerUpdateManyInput = {
  create?: Maybe<Array<DirTrainerCreateInput>>;
  update?: Maybe<Array<DirTrainerUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirTrainerUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  connect?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  set?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirTrainerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirTrainerScalarWhereInput>>;
  updateMany?: Maybe<Array<DirTrainerUpdateManyWithWhereNestedInput>>;
};

export type DirTrainerUpdateWithWhereUniqueNestedInput = {
  where: DirTrainerWhereUniqueInput;
  data: DirTrainerUpdateDataInput;
};

export type DirTrainerUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutTrainerProfileInput>;
  sports?: Maybe<DirTrainerSportUpdateManyWithoutDirTrainerInput>;
};

export type DirPersonUpdateOneRequiredWithoutTrainerProfileInput = {
  create?: Maybe<DirPersonCreateWithoutTrainerProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutTrainerProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutTrainerProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutTrainerProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonUpsertWithoutTrainerProfileInput = {
  update: DirPersonUpdateWithoutTrainerProfileDataInput;
  create: DirPersonCreateWithoutTrainerProfileInput;
};

export type DirTrainerUpsertWithWhereUniqueNestedInput = {
  where: DirTrainerWhereUniqueInput;
  update: DirTrainerUpdateDataInput;
  create: DirTrainerCreateInput;
};

export type DirTrainerScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirTrainerScalarWhereInput>>;
  OR?: Maybe<Array<DirTrainerScalarWhereInput>>;
  NOT?: Maybe<Array<DirTrainerScalarWhereInput>>;
};

export type DirTrainerUpdateManyWithWhereNestedInput = {
  where: DirTrainerScalarWhereInput;
  data: DirTrainerUpdateManyDataInput;
};

export type DirTrainerUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteSportOrganizationUpdateManyInput = {
  create?: Maybe<Array<DirAthleteSportOrganizationCreateInput>>;
  update?: Maybe<Array<DirAthleteSportOrganizationUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirAthleteSportOrganizationUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteSportOrganizationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteSportOrganizationUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteSportOrganizationUpdateWithWhereUniqueNestedInput = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
  data: DirAthleteSportOrganizationUpdateDataInput;
};

export type DirAthleteSportOrganizationUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  dirTrainer?: Maybe<DirTrainerUpdateOneInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirTrainerUpdateOneInput = {
  create?: Maybe<DirTrainerCreateInput>;
  update?: Maybe<DirTrainerUpdateDataInput>;
  upsert?: Maybe<DirTrainerUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerUpsertNestedInput = {
  update: DirTrainerUpdateDataInput;
  create: DirTrainerCreateInput;
};

export type DirAthleteSportOrganizationUpsertWithWhereUniqueNestedInput = {
  where: DirAthleteSportOrganizationWhereUniqueInput;
  update: DirAthleteSportOrganizationUpdateDataInput;
  create: DirAthleteSportOrganizationCreateInput;
};

export type DirAthleteSportOrganizationScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  isSelfTraining_not?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  trainingStart_not?: Maybe<Scalars['DateTime']>;
  trainingStart_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trainingStart_lt?: Maybe<Scalars['DateTime']>;
  trainingStart_lte?: Maybe<Scalars['DateTime']>;
  trainingStart_gt?: Maybe<Scalars['DateTime']>;
  trainingStart_gte?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
  exclusionDate_not?: Maybe<Scalars['DateTime']>;
  exclusionDate_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  exclusionDate_lt?: Maybe<Scalars['DateTime']>;
  exclusionDate_lte?: Maybe<Scalars['DateTime']>;
  exclusionDate_gt?: Maybe<Scalars['DateTime']>;
  exclusionDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportOrganizationScalarWhereInput>>;
};

export type DirAthleteSportOrganizationUpdateManyWithWhereNestedInput = {
  where: DirAthleteSportOrganizationScalarWhereInput;
  data: DirAthleteSportOrganizationUpdateManyDataInput;
};

export type DirAthleteSportOrganizationUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirAthleteSportRankAndAwardUpdateManyWithoutSportInput = {
  create?: Maybe<Array<DirAthleteSportRankAndAwardCreateWithoutSportInput>>;
  delete?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteSportRankAndAwardWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteSportRankAndAwardUpdateWithWhereUniqueWithoutSportInput>>;
  upsert?: Maybe<Array<DirAthleteSportRankAndAwardUpsertWithWhereUniqueWithoutSportInput>>;
  deleteMany?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteSportRankAndAwardUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteSportRankAndAwardUpdateWithWhereUniqueWithoutSportInput = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
  data: DirAthleteSportRankAndAwardUpdateWithoutSportDataInput;
};

export type DirAthleteSportRankAndAwardUpdateWithoutSportDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsRankAndAward?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
};

export type DirAthleteSportRankAndAwardUpsertWithWhereUniqueWithoutSportInput = {
  where: DirAthleteSportRankAndAwardWhereUniqueInput;
  update: DirAthleteSportRankAndAwardUpdateWithoutSportDataInput;
  create: DirAthleteSportRankAndAwardCreateWithoutSportInput;
};

export type DirAthleteSportRankAndAwardScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  assignmentDate_not?: Maybe<Scalars['DateTime']>;
  assignmentDate_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  assignmentDate_lt?: Maybe<Scalars['DateTime']>;
  assignmentDate_lte?: Maybe<Scalars['DateTime']>;
  assignmentDate_gt?: Maybe<Scalars['DateTime']>;
  assignmentDate_gte?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  confirmDate_not?: Maybe<Scalars['DateTime']>;
  confirmDate_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  confirmDate_lt?: Maybe<Scalars['DateTime']>;
  confirmDate_lte?: Maybe<Scalars['DateTime']>;
  confirmDate_gt?: Maybe<Scalars['DateTime']>;
  confirmDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportRankAndAwardScalarWhereInput>>;
};

export type DirAthleteSportRankAndAwardUpdateManyWithWhereNestedInput = {
  where: DirAthleteSportRankAndAwardScalarWhereInput;
  data: DirAthleteSportRankAndAwardUpdateManyDataInput;
};

export type DirAthleteSportRankAndAwardUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type DirAthleteSportUpsertWithWhereUniqueNestedInput = {
  where: DirAthleteSportWhereUniqueInput;
  update: DirAthleteSportUpdateDataInput;
  create: DirAthleteSportCreateInput;
};

export type DirAthleteSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  role_not?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Scalars['String']>>;
  role_not_in?: Maybe<Array<Scalars['String']>>;
  role_lt?: Maybe<Scalars['String']>;
  role_lte?: Maybe<Scalars['String']>;
  role_gt?: Maybe<Scalars['String']>;
  role_gte?: Maybe<Scalars['String']>;
  role_contains?: Maybe<Scalars['String']>;
  role_not_contains?: Maybe<Scalars['String']>;
  role_starts_with?: Maybe<Scalars['String']>;
  role_not_starts_with?: Maybe<Scalars['String']>;
  role_ends_with?: Maybe<Scalars['String']>;
  role_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteSportScalarWhereInput>>;
};

export type DirAthleteSportUpdateManyWithWhereNestedInput = {
  where: DirAthleteSportScalarWhereInput;
  data: DirAthleteSportUpdateManyDataInput;
};

export type DirAthleteSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteMedCertUpdateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteMedCertCreateWithoutDirAthleteInput>>;
  delete?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteMedCertWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteMedCertUpdateWithWhereUniqueWithoutDirAthleteInput>>;
  upsert?: Maybe<Array<DirAthleteMedCertUpsertWithWhereUniqueWithoutDirAthleteInput>>;
  deleteMany?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteMedCertUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteMedCertUpdateWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteMedCertWhereUniqueInput;
  data: DirAthleteMedCertUpdateWithoutDirAthleteDataInput;
};

export type DirAthleteMedCertUpdateWithoutDirAthleteDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportUpdateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertSportUpdateManyWithoutMedCertInput = {
  create?: Maybe<Array<DirAthleteMedCertSportCreateWithoutMedCertInput>>;
  delete?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteMedCertSportWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteMedCertSportUpdateWithWhereUniqueWithoutMedCertInput>>;
  upsert?: Maybe<Array<DirAthleteMedCertSportUpsertWithWhereUniqueWithoutMedCertInput>>;
  deleteMany?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteMedCertSportUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteMedCertSportUpdateWithWhereUniqueWithoutMedCertInput = {
  where: DirAthleteMedCertSportWhereUniqueInput;
  data: DirAthleteMedCertSportUpdateWithoutMedCertDataInput;
};

export type DirAthleteMedCertSportUpdateWithoutMedCertDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirAthleteMedCertSportUpsertWithWhereUniqueWithoutMedCertInput = {
  where: DirAthleteMedCertSportWhereUniqueInput;
  update: DirAthleteMedCertSportUpdateWithoutMedCertDataInput;
  create: DirAthleteMedCertSportCreateWithoutMedCertInput;
};

export type DirAthleteMedCertSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertSportScalarWhereInput>>;
};

export type DirAthleteMedCertSportUpdateManyWithWhereNestedInput = {
  where: DirAthleteMedCertSportScalarWhereInput;
  data: DirAthleteMedCertSportUpdateManyDataInput;
};

export type DirAthleteMedCertSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirAthleteMedCertUpsertWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteMedCertWhereUniqueInput;
  update: DirAthleteMedCertUpdateWithoutDirAthleteDataInput;
  create: DirAthleteMedCertCreateWithoutDirAthleteInput;
};

export type DirAthleteMedCertScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  displayNumber_not?: Maybe<Scalars['String']>;
  displayNumber_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_not_in?: Maybe<Array<Scalars['String']>>;
  displayNumber_lt?: Maybe<Scalars['String']>;
  displayNumber_lte?: Maybe<Scalars['String']>;
  displayNumber_gt?: Maybe<Scalars['String']>;
  displayNumber_gte?: Maybe<Scalars['String']>;
  displayNumber_contains?: Maybe<Scalars['String']>;
  displayNumber_not_contains?: Maybe<Scalars['String']>;
  displayNumber_starts_with?: Maybe<Scalars['String']>;
  displayNumber_not_starts_with?: Maybe<Scalars['String']>;
  displayNumber_ends_with?: Maybe<Scalars['String']>;
  displayNumber_not_ends_with?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  issueDate_not?: Maybe<Scalars['DateTime']>;
  issueDate_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  issueDate_lt?: Maybe<Scalars['DateTime']>;
  issueDate_lte?: Maybe<Scalars['DateTime']>;
  issueDate_gt?: Maybe<Scalars['DateTime']>;
  issueDate_gte?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_not?: Maybe<Scalars['DateTime']>;
  validUntil_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_not_in?: Maybe<Array<Scalars['DateTime']>>;
  validUntil_lt?: Maybe<Scalars['DateTime']>;
  validUntil_lte?: Maybe<Scalars['DateTime']>;
  validUntil_gt?: Maybe<Scalars['DateTime']>;
  validUntil_gte?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationName_not?: Maybe<Scalars['String']>;
  organizationName_in?: Maybe<Array<Scalars['String']>>;
  organizationName_not_in?: Maybe<Array<Scalars['String']>>;
  organizationName_lt?: Maybe<Scalars['String']>;
  organizationName_lte?: Maybe<Scalars['String']>;
  organizationName_gt?: Maybe<Scalars['String']>;
  organizationName_gte?: Maybe<Scalars['String']>;
  organizationName_contains?: Maybe<Scalars['String']>;
  organizationName_not_contains?: Maybe<Scalars['String']>;
  organizationName_starts_with?: Maybe<Scalars['String']>;
  organizationName_not_starts_with?: Maybe<Scalars['String']>;
  organizationName_ends_with?: Maybe<Scalars['String']>;
  organizationName_not_ends_with?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationAddress_not?: Maybe<Scalars['String']>;
  organizationAddress_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_not_in?: Maybe<Array<Scalars['String']>>;
  organizationAddress_lt?: Maybe<Scalars['String']>;
  organizationAddress_lte?: Maybe<Scalars['String']>;
  organizationAddress_gt?: Maybe<Scalars['String']>;
  organizationAddress_gte?: Maybe<Scalars['String']>;
  organizationAddress_contains?: Maybe<Scalars['String']>;
  organizationAddress_not_contains?: Maybe<Scalars['String']>;
  organizationAddress_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_not_starts_with?: Maybe<Scalars['String']>;
  organizationAddress_ends_with?: Maybe<Scalars['String']>;
  organizationAddress_not_ends_with?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationPhone_not?: Maybe<Scalars['String']>;
  organizationPhone_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_not_in?: Maybe<Array<Scalars['String']>>;
  organizationPhone_lt?: Maybe<Scalars['String']>;
  organizationPhone_lte?: Maybe<Scalars['String']>;
  organizationPhone_gt?: Maybe<Scalars['String']>;
  organizationPhone_gte?: Maybe<Scalars['String']>;
  organizationPhone_contains?: Maybe<Scalars['String']>;
  organizationPhone_not_contains?: Maybe<Scalars['String']>;
  organizationPhone_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_not_starts_with?: Maybe<Scalars['String']>;
  organizationPhone_ends_with?: Maybe<Scalars['String']>;
  organizationPhone_not_ends_with?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  organizationEmail_not?: Maybe<Scalars['String']>;
  organizationEmail_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_not_in?: Maybe<Array<Scalars['String']>>;
  organizationEmail_lt?: Maybe<Scalars['String']>;
  organizationEmail_lte?: Maybe<Scalars['String']>;
  organizationEmail_gt?: Maybe<Scalars['String']>;
  organizationEmail_gte?: Maybe<Scalars['String']>;
  organizationEmail_contains?: Maybe<Scalars['String']>;
  organizationEmail_not_contains?: Maybe<Scalars['String']>;
  organizationEmail_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_not_starts_with?: Maybe<Scalars['String']>;
  organizationEmail_ends_with?: Maybe<Scalars['String']>;
  organizationEmail_not_ends_with?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  isValid_not?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_not?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  physicalExaminationDate_lt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_lte?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gt?: Maybe<Scalars['DateTime']>;
  physicalExaminationDate_gte?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
  hasRestrictions_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteMedCertScalarWhereInput>>;
};

export type DirAthleteMedCertUpdateManyWithWhereNestedInput = {
  where: DirAthleteMedCertScalarWhereInput;
  data: DirAthleteMedCertUpdateManyDataInput;
};

export type DirAthleteMedCertUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput = {
  create?: Maybe<Array<DirAthleteCompetitionResultCreateWithoutDirAthleteInput>>;
  delete?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  connect?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  set?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirAthleteCompetitionResultWhereUniqueInput>>;
  update?: Maybe<Array<DirAthleteCompetitionResultUpdateWithWhereUniqueWithoutDirAthleteInput>>;
  upsert?: Maybe<Array<DirAthleteCompetitionResultUpsertWithWhereUniqueWithoutDirAthleteInput>>;
  deleteMany?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
  updateMany?: Maybe<Array<DirAthleteCompetitionResultUpdateManyWithWhereNestedInput>>;
};

export type DirAthleteCompetitionResultUpdateWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
  data: DirAthleteCompetitionResultUpdateWithoutDirAthleteDataInput;
};

export type DirAthleteCompetitionResultUpdateWithoutDirAthleteDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sportingEventProgramType?: Maybe<DirSportingEventProgramTypeUpdateOneRequiredInput>;
  point?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
};

export type DirSportingEventProgramTypeUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventProgramTypeCreateInput>;
  update?: Maybe<DirSportingEventProgramTypeUpdateDataInput>;
  upsert?: Maybe<DirSportingEventProgramTypeUpsertNestedInput>;
  connect?: Maybe<DirSportingEventProgramTypeWhereUniqueInput>;
};

export type DirSportingEventProgramTypeUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutProgramTypesInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  discipline?: Maybe<DirSportDisciplineUpdateOneRequiredInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutProgramTypesInput = {
  create?: Maybe<DirSportingEventCreateWithoutProgramTypesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutProgramTypesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutProgramTypesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutProgramTypesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirNationalTeamUpdateManyWithoutDirSportingEventInput = {
  create?: Maybe<Array<DirNationalTeamCreateWithoutDirSportingEventInput>>;
  delete?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamUpdateWithWhereUniqueWithoutDirSportingEventInput>>;
  upsert?: Maybe<Array<DirNationalTeamUpsertWithWhereUniqueWithoutDirSportingEventInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamUpdateWithWhereUniqueWithoutDirSportingEventInput = {
  where: DirNationalTeamWhereUniqueInput;
  data: DirNationalTeamUpdateWithoutDirSportingEventDataInput;
};

export type DirNationalTeamUpdateWithoutDirSportingEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
};

export type DirNationalTeamStatusUpdateOneRequiredInput = {
  create?: Maybe<DirNationalTeamStatusCreateInput>;
  update?: Maybe<DirNationalTeamStatusUpdateDataInput>;
  upsert?: Maybe<DirNationalTeamStatusUpsertNestedInput>;
  connect?: Maybe<DirNationalTeamStatusWhereUniqueInput>;
};

export type DirNationalTeamStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirNationalTeamStatusUpsertNestedInput = {
  update: DirNationalTeamStatusUpdateDataInput;
  create: DirNationalTeamStatusCreateInput;
};

export type DirNationalTeamStatusHistoryUpdateManyInput = {
  create?: Maybe<Array<DirNationalTeamStatusHistoryCreateInput>>;
  update?: Maybe<Array<DirNationalTeamStatusHistoryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirNationalTeamStatusHistoryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamStatusHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamStatusHistoryUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamStatusHistoryUpdateWithWhereUniqueNestedInput = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
  data: DirNationalTeamStatusHistoryUpdateDataInput;
};

export type DirNationalTeamStatusHistoryUpdateDataInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type UserUpdateOneRequiredInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpsertNestedInput = {
  update: UserUpdateDataInput;
  create: UserCreateInput;
};

export type DirNationalTeamStatusHistoryUpsertWithWhereUniqueNestedInput = {
  where: DirNationalTeamStatusHistoryWhereUniqueInput;
  update: DirNationalTeamStatusHistoryUpdateDataInput;
  create: DirNationalTeamStatusHistoryCreateInput;
};

export type DirNationalTeamStatusHistoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamStatusHistoryScalarWhereInput>>;
};

export type DirNationalTeamStatusHistoryUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamStatusHistoryScalarWhereInput;
  data: DirNationalTeamStatusHistoryUpdateManyDataInput;
};

export type DirNationalTeamStatusHistoryUpdateManyDataInput = {
  date?: Maybe<Scalars['DateTime']>;
};

export type DirNationalTeamTrainerUpdateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamTrainerCreateWithoutTeamInput>>;
  delete?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamTrainerWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamTrainerUpdateWithWhereUniqueWithoutTeamInput>>;
  upsert?: Maybe<Array<DirNationalTeamTrainerUpsertWithWhereUniqueWithoutTeamInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamTrainerUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamTrainerUpdateWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamTrainerWhereUniqueInput;
  data: DirNationalTeamTrainerUpdateWithoutTeamDataInput;
};

export type DirNationalTeamTrainerUpdateWithoutTeamDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirTrainerUpdateOneRequiredInput = {
  create?: Maybe<DirTrainerCreateInput>;
  update?: Maybe<DirTrainerUpdateDataInput>;
  upsert?: Maybe<DirTrainerUpsertNestedInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirNationalTeamTrainerUpsertWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamTrainerWhereUniqueInput;
  update: DirNationalTeamTrainerUpdateWithoutTeamDataInput;
  create: DirNationalTeamTrainerCreateWithoutTeamInput;
};

export type DirNationalTeamTrainerScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamTrainerScalarWhereInput>>;
};

export type DirNationalTeamTrainerUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamTrainerScalarWhereInput;
  data: DirNationalTeamTrainerUpdateManyDataInput;
};

export type DirNationalTeamTrainerUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput = {
  create?: Maybe<Array<DirNationalTeamAthleteGroupCreateWithoutTeamInput>>;
  delete?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamAthleteGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamAthleteGroupUpdateWithWhereUniqueWithoutTeamInput>>;
  upsert?: Maybe<Array<DirNationalTeamAthleteGroupUpsertWithWhereUniqueWithoutTeamInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamAthleteGroupUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamAthleteGroupUpdateWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
  data: DirNationalTeamAthleteGroupUpdateWithoutTeamDataInput;
};

export type DirNationalTeamAthleteGroupUpdateWithoutTeamDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteUpdateManyWithoutGroupInput>;
};

export type DirNationalTeamAthleteUpdateManyWithoutGroupInput = {
  create?: Maybe<Array<DirNationalTeamAthleteCreateWithoutGroupInput>>;
  delete?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamAthleteWhereUniqueInput>>;
  update?: Maybe<Array<DirNationalTeamAthleteUpdateWithWhereUniqueWithoutGroupInput>>;
  upsert?: Maybe<Array<DirNationalTeamAthleteUpsertWithWhereUniqueWithoutGroupInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamAthleteUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamAthleteUpdateWithWhereUniqueWithoutGroupInput = {
  where: DirNationalTeamAthleteWhereUniqueInput;
  data: DirNationalTeamAthleteUpdateWithoutGroupDataInput;
};

export type DirNationalTeamAthleteUpdateWithoutGroupDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredInput>;
  bestResult?: Maybe<Scalars['String']>;
};

export type DirAthleteUpdateOneRequiredInput = {
  create?: Maybe<DirAthleteCreateInput>;
  update?: Maybe<DirAthleteUpdateDataInput>;
  upsert?: Maybe<DirAthleteUpsertNestedInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirPersonUpdateOneRequiredWithoutAthleteProfileInput = {
  create?: Maybe<DirPersonCreateWithoutAthleteProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutAthleteProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutAthleteProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutAthleteProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonUpsertWithoutAthleteProfileInput = {
  update: DirPersonUpdateWithoutAthleteProfileDataInput;
  create: DirPersonCreateWithoutAthleteProfileInput;
};

export type DirAthleteUpsertNestedInput = {
  update: DirAthleteUpdateDataInput;
  create: DirAthleteCreateInput;
};

export type DirNationalTeamAthleteUpsertWithWhereUniqueWithoutGroupInput = {
  where: DirNationalTeamAthleteWhereUniqueInput;
  update: DirNationalTeamAthleteUpdateWithoutGroupDataInput;
  create: DirNationalTeamAthleteCreateWithoutGroupInput;
};

export type DirNationalTeamAthleteScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  bestResult?: Maybe<Scalars['String']>;
  bestResult_not?: Maybe<Scalars['String']>;
  bestResult_in?: Maybe<Array<Scalars['String']>>;
  bestResult_not_in?: Maybe<Array<Scalars['String']>>;
  bestResult_lt?: Maybe<Scalars['String']>;
  bestResult_lte?: Maybe<Scalars['String']>;
  bestResult_gt?: Maybe<Scalars['String']>;
  bestResult_gte?: Maybe<Scalars['String']>;
  bestResult_contains?: Maybe<Scalars['String']>;
  bestResult_not_contains?: Maybe<Scalars['String']>;
  bestResult_starts_with?: Maybe<Scalars['String']>;
  bestResult_not_starts_with?: Maybe<Scalars['String']>;
  bestResult_ends_with?: Maybe<Scalars['String']>;
  bestResult_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteScalarWhereInput>>;
};

export type DirNationalTeamAthleteUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamAthleteScalarWhereInput;
  data: DirNationalTeamAthleteUpdateManyDataInput;
};

export type DirNationalTeamAthleteUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  bestResult?: Maybe<Scalars['String']>;
};

export type DirNationalTeamAthleteGroupUpsertWithWhereUniqueWithoutTeamInput = {
  where: DirNationalTeamAthleteGroupWhereUniqueInput;
  update: DirNationalTeamAthleteGroupUpdateWithoutTeamDataInput;
  create: DirNationalTeamAthleteGroupCreateWithoutTeamInput;
};

export type DirNationalTeamAthleteGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Scalars['Int']>>;
  order_not_in?: Maybe<Array<Scalars['Int']>>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamAthleteGroupScalarWhereInput>>;
};

export type DirNationalTeamAthleteGroupUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamAthleteGroupScalarWhereInput;
  data: DirNationalTeamAthleteGroupUpdateManyDataInput;
};

export type DirNationalTeamAthleteGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirNationalTeamMemberUpdateManyInput = {
  create?: Maybe<Array<DirNationalTeamMemberCreateInput>>;
  update?: Maybe<Array<DirNationalTeamMemberUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirNationalTeamMemberUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  connect?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  set?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirNationalTeamMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
  updateMany?: Maybe<Array<DirNationalTeamMemberUpdateManyWithWhereNestedInput>>;
};

export type DirNationalTeamMemberUpdateWithWhereUniqueNestedInput = {
  where: DirNationalTeamMemberWhereUniqueInput;
  data: DirNationalTeamMemberUpdateDataInput;
};

export type DirNationalTeamMemberUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirNationalTeamMemberUpsertWithWhereUniqueNestedInput = {
  where: DirNationalTeamMemberWhereUniqueInput;
  update: DirNationalTeamMemberUpdateDataInput;
  create: DirNationalTeamMemberCreateInput;
};

export type DirNationalTeamMemberScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  lastname_not?: Maybe<Scalars['String']>;
  lastname_in?: Maybe<Array<Scalars['String']>>;
  lastname_not_in?: Maybe<Array<Scalars['String']>>;
  lastname_lt?: Maybe<Scalars['String']>;
  lastname_lte?: Maybe<Scalars['String']>;
  lastname_gt?: Maybe<Scalars['String']>;
  lastname_gte?: Maybe<Scalars['String']>;
  lastname_contains?: Maybe<Scalars['String']>;
  lastname_not_contains?: Maybe<Scalars['String']>;
  lastname_starts_with?: Maybe<Scalars['String']>;
  lastname_not_starts_with?: Maybe<Scalars['String']>;
  lastname_ends_with?: Maybe<Scalars['String']>;
  lastname_not_ends_with?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  firstname_not?: Maybe<Scalars['String']>;
  firstname_in?: Maybe<Array<Scalars['String']>>;
  firstname_not_in?: Maybe<Array<Scalars['String']>>;
  firstname_lt?: Maybe<Scalars['String']>;
  firstname_lte?: Maybe<Scalars['String']>;
  firstname_gt?: Maybe<Scalars['String']>;
  firstname_gte?: Maybe<Scalars['String']>;
  firstname_contains?: Maybe<Scalars['String']>;
  firstname_not_contains?: Maybe<Scalars['String']>;
  firstname_starts_with?: Maybe<Scalars['String']>;
  firstname_not_starts_with?: Maybe<Scalars['String']>;
  firstname_ends_with?: Maybe<Scalars['String']>;
  firstname_not_ends_with?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  patronymic_not?: Maybe<Scalars['String']>;
  patronymic_in?: Maybe<Array<Scalars['String']>>;
  patronymic_not_in?: Maybe<Array<Scalars['String']>>;
  patronymic_lt?: Maybe<Scalars['String']>;
  patronymic_lte?: Maybe<Scalars['String']>;
  patronymic_gt?: Maybe<Scalars['String']>;
  patronymic_gte?: Maybe<Scalars['String']>;
  patronymic_contains?: Maybe<Scalars['String']>;
  patronymic_not_contains?: Maybe<Scalars['String']>;
  patronymic_starts_with?: Maybe<Scalars['String']>;
  patronymic_not_starts_with?: Maybe<Scalars['String']>;
  patronymic_ends_with?: Maybe<Scalars['String']>;
  patronymic_not_ends_with?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  birthday_not?: Maybe<Scalars['DateTime']>;
  birthday_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_not_in?: Maybe<Array<Scalars['DateTime']>>;
  birthday_lt?: Maybe<Scalars['DateTime']>;
  birthday_lte?: Maybe<Scalars['DateTime']>;
  birthday_gt?: Maybe<Scalars['DateTime']>;
  birthday_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamMemberScalarWhereInput>>;
};

export type DirNationalTeamMemberUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamMemberScalarWhereInput;
  data: DirNationalTeamMemberUpdateManyDataInput;
};

export type DirNationalTeamMemberUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
};

export type DirNationalTeamUpsertWithWhereUniqueWithoutDirSportingEventInput = {
  where: DirNationalTeamWhereUniqueInput;
  update: DirNationalTeamUpdateWithoutDirSportingEventDataInput;
  create: DirNationalTeamCreateWithoutDirSportingEventInput;
};

export type DirNationalTeamScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Scalars['String']>>;
  fullName_not_in?: Maybe<Array<Scalars['String']>>;
  fullName_lt?: Maybe<Scalars['String']>;
  fullName_lte?: Maybe<Scalars['String']>;
  fullName_gt?: Maybe<Scalars['String']>;
  fullName_gte?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  shortName_not?: Maybe<Scalars['String']>;
  shortName_in?: Maybe<Array<Scalars['String']>>;
  shortName_not_in?: Maybe<Array<Scalars['String']>>;
  shortName_lt?: Maybe<Scalars['String']>;
  shortName_lte?: Maybe<Scalars['String']>;
  shortName_gt?: Maybe<Scalars['String']>;
  shortName_gte?: Maybe<Scalars['String']>;
  shortName_contains?: Maybe<Scalars['String']>;
  shortName_not_contains?: Maybe<Scalars['String']>;
  shortName_starts_with?: Maybe<Scalars['String']>;
  shortName_not_starts_with?: Maybe<Scalars['String']>;
  shortName_ends_with?: Maybe<Scalars['String']>;
  shortName_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  manTeamQuota_not?: Maybe<Scalars['Int']>;
  manTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  manTeamQuota_lt?: Maybe<Scalars['Int']>;
  manTeamQuota_lte?: Maybe<Scalars['Int']>;
  manTeamQuota_gt?: Maybe<Scalars['Int']>;
  manTeamQuota_gte?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota_not?: Maybe<Scalars['Int']>;
  womanTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  womanTeamQuota_lt?: Maybe<Scalars['Int']>;
  womanTeamQuota_lte?: Maybe<Scalars['Int']>;
  womanTeamQuota_gt?: Maybe<Scalars['Int']>;
  womanTeamQuota_gte?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota_not?: Maybe<Scalars['Int']>;
  supportTeamQuota_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  supportTeamQuota_lt?: Maybe<Scalars['Int']>;
  supportTeamQuota_lte?: Maybe<Scalars['Int']>;
  supportTeamQuota_gt?: Maybe<Scalars['Int']>;
  supportTeamQuota_gte?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  athletesCall_not?: Maybe<Scalars['String']>;
  athletesCall_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_not_in?: Maybe<Array<Scalars['String']>>;
  athletesCall_lt?: Maybe<Scalars['String']>;
  athletesCall_lte?: Maybe<Scalars['String']>;
  athletesCall_gt?: Maybe<Scalars['String']>;
  athletesCall_gte?: Maybe<Scalars['String']>;
  athletesCall_contains?: Maybe<Scalars['String']>;
  athletesCall_not_contains?: Maybe<Scalars['String']>;
  athletesCall_starts_with?: Maybe<Scalars['String']>;
  athletesCall_not_starts_with?: Maybe<Scalars['String']>;
  athletesCall_ends_with?: Maybe<Scalars['String']>;
  athletesCall_not_ends_with?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  score_not?: Maybe<Scalars['Int']>;
  score_in?: Maybe<Array<Scalars['Int']>>;
  score_not_in?: Maybe<Array<Scalars['Int']>>;
  score_lt?: Maybe<Scalars['Int']>;
  score_lte?: Maybe<Scalars['Int']>;
  score_gt?: Maybe<Scalars['Int']>;
  score_gte?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  isNotified_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
  OR?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
  NOT?: Maybe<Array<DirNationalTeamScalarWhereInput>>;
};

export type DirNationalTeamUpdateManyWithWhereNestedInput = {
  where: DirNationalTeamScalarWhereInput;
  data: DirNationalTeamUpdateManyDataInput;
};

export type DirNationalTeamUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventUpdateOneWithoutChildrenInput = {
  create?: Maybe<DirSportingEventCreateWithoutChildrenInput>;
  update?: Maybe<DirSportingEventUpdateWithoutChildrenDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutChildrenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutChildrenDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventStatusUpdateOneRequiredInput = {
  create?: Maybe<DirSportingEventStatusCreateInput>;
  update?: Maybe<DirSportingEventStatusUpdateDataInput>;
  upsert?: Maybe<DirSportingEventStatusUpsertNestedInput>;
  connect?: Maybe<DirSportingEventStatusWhereUniqueInput>;
};

export type DirSportingEventStatusUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventStatusUpsertNestedInput = {
  update: DirSportingEventStatusUpdateDataInput;
  create: DirSportingEventStatusCreateInput;
};

export type DirSportingEventStatusHistoryUpdateManyInput = {
  create?: Maybe<Array<DirSportingEventStatusHistoryCreateInput>>;
  update?: Maybe<Array<DirSportingEventStatusHistoryUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirSportingEventStatusHistoryUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventStatusHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventStatusHistoryUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventStatusHistoryUpdateWithWhereUniqueNestedInput = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
  data: DirSportingEventStatusHistoryUpdateDataInput;
};

export type DirSportingEventStatusHistoryUpdateDataInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type DirSportingEventStatusHistoryUpsertWithWhereUniqueNestedInput = {
  where: DirSportingEventStatusHistoryWhereUniqueInput;
  update: DirSportingEventStatusHistoryUpdateDataInput;
  create: DirSportingEventStatusHistoryCreateInput;
};

export type DirSportingEventStatusHistoryScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventStatusHistoryScalarWhereInput>>;
};

export type DirSportingEventStatusHistoryUpdateManyWithWhereNestedInput = {
  where: DirSportingEventStatusHistoryScalarWhereInput;
  data: DirSportingEventStatusHistoryUpdateManyDataInput;
};

export type DirSportingEventStatusHistoryUpdateManyDataInput = {
  date?: Maybe<Scalars['DateTime']>;
};

export type DirCalendarUpdateOneRequiredInput = {
  create?: Maybe<DirCalendarCreateInput>;
  update?: Maybe<DirCalendarUpdateDataInput>;
  upsert?: Maybe<DirCalendarUpsertNestedInput>;
  connect?: Maybe<DirCalendarWhereUniqueInput>;
};

export type DirCalendarUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirCalendarUpsertNestedInput = {
  update: DirCalendarUpdateDataInput;
  create: DirCalendarCreateInput;
};

export type DirSportingEventSportUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventSportCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventSportWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventSportUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventSportUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventSportUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventSportUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventSportWhereUniqueInput;
  data: DirSportingEventSportUpdateWithoutEventDataInput;
};

export type DirSportingEventSportUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirSportingEventSportUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventSportWhereUniqueInput;
  update: DirSportingEventSportUpdateWithoutEventDataInput;
  create: DirSportingEventSportCreateWithoutEventInput;
};

export type DirSportingEventSportScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventSportScalarWhereInput>>;
};

export type DirSportingEventSportUpdateManyWithWhereNestedInput = {
  where: DirSportingEventSportScalarWhereInput;
  data: DirSportingEventSportUpdateManyDataInput;
};

export type DirSportingEventSportUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
};

export type DirSportingEventAgeGroupUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventAgeGroupCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventAgeGroupWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventAgeGroupUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventAgeGroupUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventAgeGroupUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventAgeGroupUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
  data: DirSportingEventAgeGroupUpdateWithoutEventDataInput;
};

export type DirSportingEventAgeGroupUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventAgeGroupUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventAgeGroupWhereUniqueInput;
  update: DirSportingEventAgeGroupUpdateWithoutEventDataInput;
  create: DirSportingEventAgeGroupCreateWithoutEventInput;
};

export type DirSportingEventAgeGroupScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventAgeGroupScalarWhereInput>>;
};

export type DirSportingEventAgeGroupUpdateManyWithWhereNestedInput = {
  where: DirSportingEventAgeGroupScalarWhereInput;
  data: DirSportingEventAgeGroupUpdateManyDataInput;
};

export type DirSportingEventAgeGroupUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventProgramTypeUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventProgramTypeCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventProgramTypeWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventProgramTypeUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventProgramTypeUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventProgramTypeUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventProgramTypeUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
  data: DirSportingEventProgramTypeUpdateWithoutEventDataInput;
};

export type DirSportingEventProgramTypeUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  discipline?: Maybe<DirSportDisciplineUpdateOneRequiredInput>;
};

export type DirSportDisciplineUpdateOneRequiredInput = {
  create?: Maybe<DirSportDisciplineCreateInput>;
  update?: Maybe<DirSportDisciplineUpdateDataInput>;
  upsert?: Maybe<DirSportDisciplineUpsertNestedInput>;
  connect?: Maybe<DirSportDisciplineWhereUniqueInput>;
};

export type DirSportDisciplineUpsertNestedInput = {
  update: DirSportDisciplineUpdateDataInput;
  create: DirSportDisciplineCreateInput;
};

export type DirSportingEventProgramTypeUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventProgramTypeWhereUniqueInput;
  update: DirSportingEventProgramTypeUpdateWithoutEventDataInput;
  create: DirSportingEventProgramTypeCreateWithoutEventInput;
};

export type DirSportingEventProgramTypeScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  minAge_not?: Maybe<Scalars['Int']>;
  minAge_in?: Maybe<Array<Scalars['Int']>>;
  minAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minAge_lt?: Maybe<Scalars['Int']>;
  minAge_lte?: Maybe<Scalars['Int']>;
  minAge_gt?: Maybe<Scalars['Int']>;
  minAge_gte?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  maxAge_not?: Maybe<Scalars['Int']>;
  maxAge_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxAge_lt?: Maybe<Scalars['Int']>;
  maxAge_lte?: Maybe<Scalars['Int']>;
  maxAge_gt?: Maybe<Scalars['Int']>;
  maxAge_gte?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  start_not?: Maybe<Scalars['String']>;
  start_in?: Maybe<Array<Scalars['String']>>;
  start_not_in?: Maybe<Array<Scalars['String']>>;
  start_lt?: Maybe<Scalars['String']>;
  start_lte?: Maybe<Scalars['String']>;
  start_gt?: Maybe<Scalars['String']>;
  start_gte?: Maybe<Scalars['String']>;
  start_contains?: Maybe<Scalars['String']>;
  start_not_contains?: Maybe<Scalars['String']>;
  start_starts_with?: Maybe<Scalars['String']>;
  start_not_starts_with?: Maybe<Scalars['String']>;
  start_ends_with?: Maybe<Scalars['String']>;
  start_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventProgramTypeScalarWhereInput>>;
};

export type DirSportingEventProgramTypeUpdateManyWithWhereNestedInput = {
  where: DirSportingEventProgramTypeScalarWhereInput;
  data: DirSportingEventProgramTypeUpdateManyDataInput;
};

export type DirSportingEventProgramTypeUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
};

export type DirSportingEventRefereeUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventRefereeCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventRefereeWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventRefereeUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventRefereeUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventRefereeUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventRefereeUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRefereeWhereUniqueInput;
  data: DirSportingEventRefereeUpdateWithoutEventDataInput;
};

export type DirSportingEventRefereeUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirRefereeUpdateOneRequiredInput = {
  create?: Maybe<DirRefereeCreateInput>;
  update?: Maybe<DirRefereeUpdateDataInput>;
  upsert?: Maybe<DirRefereeUpsertNestedInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutRefereeProfileInput>;
  sports?: Maybe<DirRefereeSportUpdateManyWithoutDirRefereeInput>;
};

export type DirPersonUpdateOneRequiredWithoutRefereeProfileInput = {
  create?: Maybe<DirPersonCreateWithoutRefereeProfileInput>;
  update?: Maybe<DirPersonUpdateWithoutRefereeProfileDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutRefereeProfileInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutRefereeProfileDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonUpsertWithoutRefereeProfileInput = {
  update: DirPersonUpdateWithoutRefereeProfileDataInput;
  create: DirPersonCreateWithoutRefereeProfileInput;
};

export type DirRefereeUpsertNestedInput = {
  update: DirRefereeUpdateDataInput;
  create: DirRefereeCreateInput;
};

export type DirSportingEventRefereeUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventRefereeWhereUniqueInput;
  update: DirSportingEventRefereeUpdateWithoutEventDataInput;
  create: DirSportingEventRefereeCreateWithoutEventInput;
};

export type DirSportingEventRefereeScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPrimary_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventRefereeScalarWhereInput>>;
};

export type DirSportingEventRefereeUpdateManyWithWhereNestedInput = {
  where: DirSportingEventRefereeScalarWhereInput;
  data: DirSportingEventRefereeUpdateManyDataInput;
};

export type DirSportingEventRefereeUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput = {
  create?: Maybe<Array<DirSportingEventCompetitionCalendarCreateWithoutEventInput>>;
  delete?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventCompetitionCalendarWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventCompetitionCalendarUpdateWithWhereUniqueWithoutEventInput>>;
  upsert?: Maybe<Array<DirSportingEventCompetitionCalendarUpsertWithWhereUniqueWithoutEventInput>>;
  deleteMany?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventCompetitionCalendarUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventCompetitionCalendarUpdateWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
  data: DirSportingEventCompetitionCalendarUpdateWithoutEventDataInput;
};

export type DirSportingEventCompetitionCalendarUpdateWithoutEventDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineUpdateOneInput>;
};

export type DirSportDisciplineUpdateOneInput = {
  create?: Maybe<DirSportDisciplineCreateInput>;
  update?: Maybe<DirSportDisciplineUpdateDataInput>;
  upsert?: Maybe<DirSportDisciplineUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportDisciplineWhereUniqueInput>;
};

export type DirSportingEventCompetitionCalendarUpsertWithWhereUniqueWithoutEventInput = {
  where: DirSportingEventCompetitionCalendarWhereUniqueInput;
  update: DirSportingEventCompetitionCalendarUpdateWithoutEventDataInput;
  create: DirSportingEventCompetitionCalendarCreateWithoutEventInput;
};

export type DirSportingEventCompetitionCalendarScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  day_not?: Maybe<Scalars['DateTime']>;
  day_in?: Maybe<Array<Scalars['DateTime']>>;
  day_not_in?: Maybe<Array<Scalars['DateTime']>>;
  day_lt?: Maybe<Scalars['DateTime']>;
  day_lte?: Maybe<Scalars['DateTime']>;
  day_gt?: Maybe<Scalars['DateTime']>;
  day_gte?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  time_not?: Maybe<Scalars['String']>;
  time_in?: Maybe<Array<Scalars['String']>>;
  time_not_in?: Maybe<Array<Scalars['String']>>;
  time_lt?: Maybe<Scalars['String']>;
  time_lte?: Maybe<Scalars['String']>;
  time_gt?: Maybe<Scalars['String']>;
  time_gte?: Maybe<Scalars['String']>;
  time_contains?: Maybe<Scalars['String']>;
  time_not_contains?: Maybe<Scalars['String']>;
  time_starts_with?: Maybe<Scalars['String']>;
  time_not_starts_with?: Maybe<Scalars['String']>;
  time_ends_with?: Maybe<Scalars['String']>;
  time_not_ends_with?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  members_not?: Maybe<Scalars['String']>;
  members_in?: Maybe<Array<Scalars['String']>>;
  members_not_in?: Maybe<Array<Scalars['String']>>;
  members_lt?: Maybe<Scalars['String']>;
  members_lte?: Maybe<Scalars['String']>;
  members_gt?: Maybe<Scalars['String']>;
  members_gte?: Maybe<Scalars['String']>;
  members_contains?: Maybe<Scalars['String']>;
  members_not_contains?: Maybe<Scalars['String']>;
  members_starts_with?: Maybe<Scalars['String']>;
  members_not_starts_with?: Maybe<Scalars['String']>;
  members_ends_with?: Maybe<Scalars['String']>;
  members_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventCompetitionCalendarScalarWhereInput>>;
};

export type DirSportingEventCompetitionCalendarUpdateManyWithWhereNestedInput = {
  where: DirSportingEventCompetitionCalendarScalarWhereInput;
  data: DirSportingEventCompetitionCalendarUpdateManyDataInput;
};

export type DirSportingEventCompetitionCalendarUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
};

export type DirDocumentUpdateManyInput = {
  create?: Maybe<Array<DirDocumentCreateInput>>;
  update?: Maybe<Array<DirDocumentUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DirDocumentUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  connect?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  set?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirDocumentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirDocumentScalarWhereInput>>;
  updateMany?: Maybe<Array<DirDocumentUpdateManyWithWhereNestedInput>>;
};

export type DirDocumentUpdateWithWhereUniqueNestedInput = {
  where: DirDocumentWhereUniqueInput;
  data: DirDocumentUpdateDataInput;
};

export type DirDocumentUpsertWithWhereUniqueNestedInput = {
  where: DirDocumentWhereUniqueInput;
  update: DirDocumentUpdateDataInput;
  create: DirDocumentCreateInput;
};

export type DirDocumentScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Scalars['DateTime']>>;
  date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Scalars['String']>>;
  number_not_in?: Maybe<Array<Scalars['String']>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirDocumentScalarWhereInput>>;
  OR?: Maybe<Array<DirDocumentScalarWhereInput>>;
  NOT?: Maybe<Array<DirDocumentScalarWhereInput>>;
};

export type DirDocumentUpdateManyWithWhereNestedInput = {
  where: DirDocumentScalarWhereInput;
  data: DirDocumentUpdateManyDataInput;
};

export type DirDocumentUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type DirOrganizationUpdateOneInput = {
  create?: Maybe<DirOrganizationCreateInput>;
  update?: Maybe<DirOrganizationUpdateDataInput>;
  upsert?: Maybe<DirOrganizationUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirSportingEventUpsertWithoutChildrenInput = {
  update: DirSportingEventUpdateWithoutChildrenDataInput;
  create: DirSportingEventCreateWithoutChildrenInput;
};

export type DirSportingEventUpdateManyWithoutParentInput = {
  create?: Maybe<Array<DirSportingEventCreateWithoutParentInput>>;
  delete?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  connect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  set?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  disconnect?: Maybe<Array<DirSportingEventWhereUniqueInput>>;
  update?: Maybe<Array<DirSportingEventUpdateWithWhereUniqueWithoutParentInput>>;
  upsert?: Maybe<Array<DirSportingEventUpsertWithWhereUniqueWithoutParentInput>>;
  deleteMany?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  updateMany?: Maybe<Array<DirSportingEventUpdateManyWithWhereNestedInput>>;
};

export type DirSportingEventUpdateWithWhereUniqueWithoutParentInput = {
  where: DirSportingEventWhereUniqueInput;
  data: DirSportingEventUpdateWithoutParentDataInput;
};

export type DirSportingEventUpdateWithoutParentDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventUpsertWithWhereUniqueWithoutParentInput = {
  where: DirSportingEventWhereUniqueInput;
  update: DirSportingEventUpdateWithoutParentDataInput;
  create: DirSportingEventCreateWithoutParentInput;
};

export type DirSportingEventScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  registryNumber?: Maybe<Scalars['Int']>;
  registryNumber_not?: Maybe<Scalars['Int']>;
  registryNumber_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  registryNumber_lt?: Maybe<Scalars['Int']>;
  registryNumber_lte?: Maybe<Scalars['Int']>;
  registryNumber_gt?: Maybe<Scalars['Int']>;
  registryNumber_gte?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  venue_not?: Maybe<Scalars['String']>;
  venue_in?: Maybe<Array<Scalars['String']>>;
  venue_not_in?: Maybe<Array<Scalars['String']>>;
  venue_lt?: Maybe<Scalars['String']>;
  venue_lte?: Maybe<Scalars['String']>;
  venue_gt?: Maybe<Scalars['String']>;
  venue_gte?: Maybe<Scalars['String']>;
  venue_contains?: Maybe<Scalars['String']>;
  venue_not_contains?: Maybe<Scalars['String']>;
  venue_starts_with?: Maybe<Scalars['String']>;
  venue_not_starts_with?: Maybe<Scalars['String']>;
  venue_ends_with?: Maybe<Scalars['String']>;
  venue_not_ends_with?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  object_not?: Maybe<Scalars['String']>;
  object_in?: Maybe<Array<Scalars['String']>>;
  object_not_in?: Maybe<Array<Scalars['String']>>;
  object_lt?: Maybe<Scalars['String']>;
  object_lte?: Maybe<Scalars['String']>;
  object_gt?: Maybe<Scalars['String']>;
  object_gte?: Maybe<Scalars['String']>;
  object_contains?: Maybe<Scalars['String']>;
  object_not_contains?: Maybe<Scalars['String']>;
  object_starts_with?: Maybe<Scalars['String']>;
  object_not_starts_with?: Maybe<Scalars['String']>;
  object_ends_with?: Maybe<Scalars['String']>;
  object_not_ends_with?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_not?: Maybe<Scalars['String']>;
  comment_in?: Maybe<Array<Scalars['String']>>;
  comment_not_in?: Maybe<Array<Scalars['String']>>;
  comment_lt?: Maybe<Scalars['String']>;
  comment_lte?: Maybe<Scalars['String']>;
  comment_gt?: Maybe<Scalars['String']>;
  comment_gte?: Maybe<Scalars['String']>;
  comment_contains?: Maybe<Scalars['String']>;
  comment_not_contains?: Maybe<Scalars['String']>;
  comment_starts_with?: Maybe<Scalars['String']>;
  comment_not_starts_with?: Maybe<Scalars['String']>;
  comment_ends_with?: Maybe<Scalars['String']>;
  comment_not_ends_with?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  minManAge_not?: Maybe<Scalars['Int']>;
  minManAge_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minManAge_lt?: Maybe<Scalars['Int']>;
  minManAge_lte?: Maybe<Scalars['Int']>;
  minManAge_gt?: Maybe<Scalars['Int']>;
  minManAge_gte?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  maxManAge_not?: Maybe<Scalars['Int']>;
  maxManAge_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManAge_lt?: Maybe<Scalars['Int']>;
  maxManAge_lte?: Maybe<Scalars['Int']>;
  maxManAge_gt?: Maybe<Scalars['Int']>;
  maxManAge_gte?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  minManBirthYear_not?: Maybe<Scalars['Int']>;
  minManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minManBirthYear_lt?: Maybe<Scalars['Int']>;
  minManBirthYear_lte?: Maybe<Scalars['Int']>;
  minManBirthYear_gt?: Maybe<Scalars['Int']>;
  minManBirthYear_gte?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear_not?: Maybe<Scalars['Int']>;
  maxManBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxManBirthYear_lt?: Maybe<Scalars['Int']>;
  maxManBirthYear_lte?: Maybe<Scalars['Int']>;
  maxManBirthYear_gt?: Maybe<Scalars['Int']>;
  maxManBirthYear_gte?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  minWomanAge_not?: Maybe<Scalars['Int']>;
  minWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanAge_lt?: Maybe<Scalars['Int']>;
  minWomanAge_lte?: Maybe<Scalars['Int']>;
  minWomanAge_gt?: Maybe<Scalars['Int']>;
  minWomanAge_gte?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge_not?: Maybe<Scalars['Int']>;
  maxWomanAge_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanAge_lt?: Maybe<Scalars['Int']>;
  maxWomanAge_lte?: Maybe<Scalars['Int']>;
  maxWomanAge_gt?: Maybe<Scalars['Int']>;
  maxWomanAge_gte?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  minWomanBirthYear_not?: Maybe<Scalars['Int']>;
  minWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  minWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  minWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_not?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_not_in?: Maybe<Array<Scalars['Int']>>;
  maxWomanBirthYear_lt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_lte?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gt?: Maybe<Scalars['Int']>;
  maxWomanBirthYear_gte?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  membersQuota_not?: Maybe<Scalars['Int']>;
  membersQuota_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  membersQuota_lt?: Maybe<Scalars['Int']>;
  membersQuota_lte?: Maybe<Scalars['Int']>;
  membersQuota_gt?: Maybe<Scalars['Int']>;
  membersQuota_gte?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  refereesQuota_not?: Maybe<Scalars['Int']>;
  refereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  refereesQuota_lt?: Maybe<Scalars['Int']>;
  refereesQuota_lte?: Maybe<Scalars['Int']>;
  refereesQuota_gt?: Maybe<Scalars['Int']>;
  refereesQuota_gte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_not?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_not_in?: Maybe<Array<Scalars['Int']>>;
  nonresidentRefereesQuota_lt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_lte?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gt?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota_gte?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  ekp_not?: Maybe<Scalars['String']>;
  ekp_in?: Maybe<Array<Scalars['String']>>;
  ekp_not_in?: Maybe<Array<Scalars['String']>>;
  ekp_lt?: Maybe<Scalars['String']>;
  ekp_lte?: Maybe<Scalars['String']>;
  ekp_gt?: Maybe<Scalars['String']>;
  ekp_gte?: Maybe<Scalars['String']>;
  ekp_contains?: Maybe<Scalars['String']>;
  ekp_not_contains?: Maybe<Scalars['String']>;
  ekp_starts_with?: Maybe<Scalars['String']>;
  ekp_not_starts_with?: Maybe<Scalars['String']>;
  ekp_ends_with?: Maybe<Scalars['String']>;
  ekp_not_ends_with?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  isRussia_not?: Maybe<Scalars['Boolean']>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUB_not?: Maybe<Scalars['Float']>;
  planRUB_in?: Maybe<Array<Scalars['Float']>>;
  planRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  planRUB_lt?: Maybe<Scalars['Float']>;
  planRUB_lte?: Maybe<Scalars['Float']>;
  planRUB_gt?: Maybe<Scalars['Float']>;
  planRUB_gte?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planRUBperPerson_not?: Maybe<Scalars['Float']>;
  planRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  planRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  planRUBperPerson_lt?: Maybe<Scalars['Float']>;
  planRUBperPerson_lte?: Maybe<Scalars['Float']>;
  planRUBperPerson_gt?: Maybe<Scalars['Float']>;
  planRUBperPerson_gte?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSD_not?: Maybe<Scalars['Float']>;
  planUSD_in?: Maybe<Array<Scalars['Float']>>;
  planUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  planUSD_lt?: Maybe<Scalars['Float']>;
  planUSD_lte?: Maybe<Scalars['Float']>;
  planUSD_gt?: Maybe<Scalars['Float']>;
  planUSD_gte?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planUSDperPerson_not?: Maybe<Scalars['Float']>;
  planUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  planUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  planUSDperPerson_lt?: Maybe<Scalars['Float']>;
  planUSDperPerson_lte?: Maybe<Scalars['Float']>;
  planUSDperPerson_gt?: Maybe<Scalars['Float']>;
  planUSDperPerson_gte?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  planDate_not?: Maybe<Scalars['DateTime']>;
  planDate_in?: Maybe<Array<Scalars['DateTime']>>;
  planDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  planDate_lt?: Maybe<Scalars['DateTime']>;
  planDate_lte?: Maybe<Scalars['DateTime']>;
  planDate_gt?: Maybe<Scalars['DateTime']>;
  planDate_gte?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUB_not?: Maybe<Scalars['Float']>;
  currentPlanRUB_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUB_lt?: Maybe<Scalars['Float']>;
  currentPlanRUB_lte?: Maybe<Scalars['Float']>;
  currentPlanRUB_gt?: Maybe<Scalars['Float']>;
  currentPlanRUB_gte?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_not?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanRUBperPerson_lt?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_lte?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_gt?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson_gte?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSD_not?: Maybe<Scalars['Float']>;
  currentPlanUSD_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSD_lt?: Maybe<Scalars['Float']>;
  currentPlanUSD_lte?: Maybe<Scalars['Float']>;
  currentPlanUSD_gt?: Maybe<Scalars['Float']>;
  currentPlanUSD_gte?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_not?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  currentPlanUSDperPerson_lt?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_lte?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_gt?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson_gte?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  currentPlanDate_not?: Maybe<Scalars['DateTime']>;
  currentPlanDate_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPlanDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPlanDate_lt?: Maybe<Scalars['DateTime']>;
  currentPlanDate_lte?: Maybe<Scalars['DateTime']>;
  currentPlanDate_gt?: Maybe<Scalars['DateTime']>;
  currentPlanDate_gte?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUB_not?: Maybe<Scalars['Float']>;
  factRUB_in?: Maybe<Array<Scalars['Float']>>;
  factRUB_not_in?: Maybe<Array<Scalars['Float']>>;
  factRUB_lt?: Maybe<Scalars['Float']>;
  factRUB_lte?: Maybe<Scalars['Float']>;
  factRUB_gt?: Maybe<Scalars['Float']>;
  factRUB_gte?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factRUBperPerson_not?: Maybe<Scalars['Float']>;
  factRUBperPerson_in?: Maybe<Array<Scalars['Float']>>;
  factRUBperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  factRUBperPerson_lt?: Maybe<Scalars['Float']>;
  factRUBperPerson_lte?: Maybe<Scalars['Float']>;
  factRUBperPerson_gt?: Maybe<Scalars['Float']>;
  factRUBperPerson_gte?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSD_not?: Maybe<Scalars['Float']>;
  factUSD_in?: Maybe<Array<Scalars['Float']>>;
  factUSD_not_in?: Maybe<Array<Scalars['Float']>>;
  factUSD_lt?: Maybe<Scalars['Float']>;
  factUSD_lte?: Maybe<Scalars['Float']>;
  factUSD_gt?: Maybe<Scalars['Float']>;
  factUSD_gte?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factUSDperPerson_not?: Maybe<Scalars['Float']>;
  factUSDperPerson_in?: Maybe<Array<Scalars['Float']>>;
  factUSDperPerson_not_in?: Maybe<Array<Scalars['Float']>>;
  factUSDperPerson_lt?: Maybe<Scalars['Float']>;
  factUSDperPerson_lte?: Maybe<Scalars['Float']>;
  factUSDperPerson_gt?: Maybe<Scalars['Float']>;
  factUSDperPerson_gte?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  factDate_not?: Maybe<Scalars['DateTime']>;
  factDate_in?: Maybe<Array<Scalars['DateTime']>>;
  factDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  factDate_lt?: Maybe<Scalars['DateTime']>;
  factDate_lte?: Maybe<Scalars['DateTime']>;
  factDate_gt?: Maybe<Scalars['DateTime']>;
  factDate_gte?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesMan_not?: Maybe<Scalars['Int']>;
  quotaAthletesMan_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesMan_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesMan_lt?: Maybe<Scalars['Int']>;
  quotaAthletesMan_lte?: Maybe<Scalars['Int']>;
  quotaAthletesMan_gt?: Maybe<Scalars['Int']>;
  quotaAthletesMan_gte?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_not?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesWoman_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAthletesWoman_lt?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_lte?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_gt?: Maybe<Scalars['Int']>;
  quotaAthletesWoman_gte?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaTrainers_not?: Maybe<Scalars['Int']>;
  quotaTrainers_in?: Maybe<Array<Scalars['Int']>>;
  quotaTrainers_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaTrainers_lt?: Maybe<Scalars['Int']>;
  quotaTrainers_lte?: Maybe<Scalars['Int']>;
  quotaTrainers_gt?: Maybe<Scalars['Int']>;
  quotaTrainers_gte?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaReferees_not?: Maybe<Scalars['Int']>;
  quotaReferees_in?: Maybe<Array<Scalars['Int']>>;
  quotaReferees_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaReferees_lt?: Maybe<Scalars['Int']>;
  quotaReferees_lte?: Maybe<Scalars['Int']>;
  quotaReferees_gt?: Maybe<Scalars['Int']>;
  quotaReferees_gte?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaAdministration_not?: Maybe<Scalars['Int']>;
  quotaAdministration_in?: Maybe<Array<Scalars['Int']>>;
  quotaAdministration_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaAdministration_lt?: Maybe<Scalars['Int']>;
  quotaAdministration_lte?: Maybe<Scalars['Int']>;
  quotaAdministration_gt?: Maybe<Scalars['Int']>;
  quotaAdministration_gte?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_not?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_in?: Maybe<Array<Scalars['Int']>>;
  quotaMedicalStaff_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaMedicalStaff_lt?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_lte?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_gt?: Maybe<Scalars['Int']>;
  quotaMedicalStaff_gte?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaSpecialists_not?: Maybe<Scalars['Int']>;
  quotaSpecialists_in?: Maybe<Array<Scalars['Int']>>;
  quotaSpecialists_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaSpecialists_lt?: Maybe<Scalars['Int']>;
  quotaSpecialists_lte?: Maybe<Scalars['Int']>;
  quotaSpecialists_gt?: Maybe<Scalars['Int']>;
  quotaSpecialists_gte?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  quotaTeams_not?: Maybe<Scalars['Int']>;
  quotaTeams_in?: Maybe<Array<Scalars['Int']>>;
  quotaTeams_not_in?: Maybe<Array<Scalars['Int']>>;
  quotaTeams_lt?: Maybe<Scalars['Int']>;
  quotaTeams_lte?: Maybe<Scalars['Int']>;
  quotaTeams_gt?: Maybe<Scalars['Int']>;
  quotaTeams_gte?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isImportant_not?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation_not?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  OR?: Maybe<Array<DirSportingEventScalarWhereInput>>;
  NOT?: Maybe<Array<DirSportingEventScalarWhereInput>>;
};

export type DirSportingEventUpdateManyWithWhereNestedInput = {
  where: DirSportingEventScalarWhereInput;
  data: DirSportingEventUpdateManyDataInput;
};

export type DirSportingEventUpdateManyDataInput = {
  registryNumber?: Maybe<Scalars['Int']>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
};

export type DirSportingEventUpsertWithoutProgramTypesInput = {
  update: DirSportingEventUpdateWithoutProgramTypesDataInput;
  create: DirSportingEventCreateWithoutProgramTypesInput;
};

export type DirSportingEventProgramTypeUpsertNestedInput = {
  update: DirSportingEventProgramTypeUpdateDataInput;
  create: DirSportingEventProgramTypeCreateInput;
};

export type DirAthleteCompetitionResultUpsertWithWhereUniqueWithoutDirAthleteInput = {
  where: DirAthleteCompetitionResultWhereUniqueInput;
  update: DirAthleteCompetitionResultUpdateWithoutDirAthleteDataInput;
  create: DirAthleteCompetitionResultCreateWithoutDirAthleteInput;
};

export type DirAthleteCompetitionResultScalarWhereInput = {
  id?: Maybe<Scalars['UUID']>;
  id_not?: Maybe<Scalars['UUID']>;
  id_in?: Maybe<Array<Scalars['UUID']>>;
  id_not_in?: Maybe<Array<Scalars['UUID']>>;
  id_lt?: Maybe<Scalars['UUID']>;
  id_lte?: Maybe<Scalars['UUID']>;
  id_gt?: Maybe<Scalars['UUID']>;
  id_gte?: Maybe<Scalars['UUID']>;
  id_contains?: Maybe<Scalars['UUID']>;
  id_not_contains?: Maybe<Scalars['UUID']>;
  id_starts_with?: Maybe<Scalars['UUID']>;
  id_not_starts_with?: Maybe<Scalars['UUID']>;
  id_ends_with?: Maybe<Scalars['UUID']>;
  id_not_ends_with?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  iasKey?: Maybe<Scalars['Int']>;
  iasKey_not?: Maybe<Scalars['Int']>;
  iasKey_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_not_in?: Maybe<Array<Scalars['Int']>>;
  iasKey_lt?: Maybe<Scalars['Int']>;
  iasKey_lte?: Maybe<Scalars['Int']>;
  iasKey_gt?: Maybe<Scalars['Int']>;
  iasKey_gte?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['Int']>;
  point_not?: Maybe<Scalars['Int']>;
  point_in?: Maybe<Array<Scalars['Int']>>;
  point_not_in?: Maybe<Array<Scalars['Int']>>;
  point_lt?: Maybe<Scalars['Int']>;
  point_lte?: Maybe<Scalars['Int']>;
  point_gt?: Maybe<Scalars['Int']>;
  point_gte?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
  result_not?: Maybe<Scalars['String']>;
  result_in?: Maybe<Array<Scalars['String']>>;
  result_not_in?: Maybe<Array<Scalars['String']>>;
  result_lt?: Maybe<Scalars['String']>;
  result_lte?: Maybe<Scalars['String']>;
  result_gt?: Maybe<Scalars['String']>;
  result_gte?: Maybe<Scalars['String']>;
  result_contains?: Maybe<Scalars['String']>;
  result_not_contains?: Maybe<Scalars['String']>;
  result_starts_with?: Maybe<Scalars['String']>;
  result_not_starts_with?: Maybe<Scalars['String']>;
  result_ends_with?: Maybe<Scalars['String']>;
  result_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
  OR?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
  NOT?: Maybe<Array<DirAthleteCompetitionResultScalarWhereInput>>;
};

export type DirAthleteCompetitionResultUpdateManyWithWhereNestedInput = {
  where: DirAthleteCompetitionResultScalarWhereInput;
  data: DirAthleteCompetitionResultUpdateManyDataInput;
};

export type DirAthleteCompetitionResultUpdateManyDataInput = {
  iasKey?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
};

export type DirAthleteUpsertWithoutDirPersonInput = {
  update: DirAthleteUpdateWithoutDirPersonDataInput;
  create: DirAthleteCreateWithoutDirPersonInput;
};

export type DirPersonUpsertWithoutDuplicatesInput = {
  update: DirPersonUpdateWithoutDuplicatesDataInput;
  create: DirPersonCreateWithoutDuplicatesInput;
};

export type DirPersonUpsertWithoutUserProfileInput = {
  update: DirPersonUpdateWithoutUserProfileDataInput;
  create: DirPersonCreateWithoutUserProfileInput;
};

export type RegularUserUpsertNestedInput = {
  update: RegularUserUpdateDataInput;
  create: RegularUserCreateInput;
};

export type UserUpsertWithoutUpdatedByInput = {
  update: UserUpdateWithoutUpdatedByDataInput;
  create: UserCreateWithoutUpdatedByInput;
};

export type UserUpsertWithoutCreatedByInput = {
  update: UserUpdateWithoutCreatedByDataInput;
  create: UserCreateWithoutCreatedByInput;
};

export type UserRoleUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canSelect?: Maybe<Scalars['Boolean']>;
};

export type RegularUserUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneInput>;
};

export type SystemUserUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
};

export type RegionalMinsportProfileUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneInput>;
};

export type RegionalSchoolProfileUpdateInput = {
  createdBy?: Maybe<Scalars['Json']>;
  updatedBy?: Maybe<Scalars['Json']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
};

export type FieldHistoryUpdateInput = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Json']>;
};

export type ArchiveUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  reason?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type ClassifierCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  values?: Maybe<ClassifierValueCreateManyWithoutClassifierInput>;
};

export type ClassifierValueCreateManyWithoutClassifierInput = {
  create?: Maybe<Array<ClassifierValueCreateWithoutClassifierInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
};

export type ClassifierValueCreateWithoutClassifierInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ClassifierUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  values?: Maybe<ClassifierValueUpdateManyWithoutClassifierInput>;
};

export type ClassifierValueUpdateManyWithoutClassifierInput = {
  create?: Maybe<Array<ClassifierValueCreateWithoutClassifierInput>>;
  delete?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  connect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  set?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  update?: Maybe<Array<ClassifierValueUpdateWithWhereUniqueWithoutClassifierInput>>;
  upsert?: Maybe<Array<ClassifierValueUpsertWithWhereUniqueWithoutClassifierInput>>;
  deleteMany?: Maybe<Array<ClassifierValueScalarWhereInput>>;
  updateMany?: Maybe<Array<ClassifierValueUpdateManyWithWhereNestedInput>>;
};

export type ClassifierValueUpdateWithWhereUniqueWithoutClassifierInput = {
  where: ClassifierValueWhereUniqueInput;
  data: ClassifierValueUpdateWithoutClassifierDataInput;
};

export type ClassifierValueUpdateWithoutClassifierDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ClassifierValueUpsertWithWhereUniqueWithoutClassifierInput = {
  where: ClassifierValueWhereUniqueInput;
  update: ClassifierValueUpdateWithoutClassifierDataInput;
  create: ClassifierValueCreateWithoutClassifierInput;
};

export type ClassifierValueUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  classifier?: Maybe<ClassifierUpdateOneRequiredWithoutValuesInput>;
};

export type DirRegionUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dirFederalDistrict?: Maybe<DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput>;
};

export type DirFederalDistrictUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirRegions?: Maybe<DirRegionUpdateManyWithoutDirFederalDistrictInput>;
};

export type DirForeignCityUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirCountry?: Maybe<DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput>;
};

export type DirCountryUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  dirForeignCities?: Maybe<DirForeignCityUpdateManyWithoutDirCountryInput>;
};

export type DirOrganizationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirOrganizationCreateOneWithoutSportsInput = {
  create?: Maybe<DirOrganizationCreateWithoutSportsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
};

export type DirOrganizationSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  trainingStartDate?: Maybe<Scalars['DateTime']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirOrganizationCreateWithoutSportsInput>;
  update?: Maybe<DirOrganizationUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutSportsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationUpsertWithoutSportsInput = {
  update: DirOrganizationUpdateWithoutSportsDataInput;
  create: DirOrganizationCreateWithoutSportsInput;
};

export type DirOrganizationAddressCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutAddressesInput;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type DirOrganizationCreateOneWithoutAddressesInput = {
  create?: Maybe<DirOrganizationCreateWithoutAddressesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
};

export type DirOrganizationAddressUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutAddressesInput>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutAddressesInput = {
  create?: Maybe<DirOrganizationCreateWithoutAddressesInput>;
  update?: Maybe<DirOrganizationUpdateWithoutAddressesDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutAddressesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutAddressesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationUpsertWithoutAddressesInput = {
  update: DirOrganizationUpdateWithoutAddressesDataInput;
  create: DirOrganizationCreateWithoutAddressesInput;
};

export type DirOrganizationPhoneCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutPhonesInput;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationCreateOneWithoutPhonesInput = {
  create?: Maybe<DirOrganizationCreateWithoutPhonesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutPhonesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
};

export type DirOrganizationPhoneUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutPhonesInput>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutPhonesInput = {
  create?: Maybe<DirOrganizationCreateWithoutPhonesInput>;
  update?: Maybe<DirOrganizationUpdateWithoutPhonesDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutPhonesInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutPhonesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  emails?: Maybe<DirOrganizationEmailUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationUpsertWithoutPhonesInput = {
  update: DirOrganizationUpdateWithoutPhonesDataInput;
  create: DirOrganizationCreateWithoutPhonesInput;
};

export type DirOrganizationEmailCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneWithoutEmailsInput;
  email: Scalars['String'];
  clsContactType: ClassifierValueCreateOneInput;
};

export type DirOrganizationCreateOneWithoutEmailsInput = {
  create?: Maybe<DirOrganizationCreateWithoutEmailsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationCreateWithoutEmailsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationCreateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationCreateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  name: Scalars['String'];
  clsOrganizationCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueCreateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportCreateManyInput>;
  sports?: Maybe<DirOrganizationSportCreateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressCreateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneCreateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationCreatewebsitesInput>;
};

export type DirOrganizationEmailUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredWithoutEmailsInput>;
  email?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirOrganizationUpdateOneRequiredWithoutEmailsInput = {
  create?: Maybe<DirOrganizationCreateWithoutEmailsInput>;
  update?: Maybe<DirOrganizationUpdateWithoutEmailsDataInput>;
  upsert?: Maybe<DirOrganizationUpsertWithoutEmailsInput>;
  connect?: Maybe<DirOrganizationWhereUniqueInput>;
};

export type DirOrganizationUpdateWithoutEmailsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  parent?: Maybe<DirOrganizationUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirOrganizationUpdateManyWithoutParentInput>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  name?: Maybe<Scalars['String']>;
  clsOrganizationCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsDepartmentalAffiliations?: Maybe<ClassifierValueUpdateManyInput>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  ogrn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  dirSports?: Maybe<DirSportUpdateManyInput>;
  sports?: Maybe<DirOrganizationSportUpdateManyWithoutDirOrganizationInput>;
  addresses?: Maybe<DirOrganizationAddressUpdateManyWithoutDirOrganizationInput>;
  phones?: Maybe<DirOrganizationPhoneUpdateManyWithoutDirOrganizationInput>;
  websites?: Maybe<DirOrganizationUpdatewebsitesInput>;
};

export type DirOrganizationUpsertWithoutEmailsInput = {
  update: DirOrganizationUpdateWithoutEmailsDataInput;
  create: DirOrganizationCreateWithoutEmailsInput;
};

export type DirPersonUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonPhoneCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: ClassifierValueCreateOneInput;
  dirPerson: DirPersonCreateOneWithoutPhonesInput;
};

export type DirPersonCreateOneWithoutPhonesInput = {
  create?: Maybe<DirPersonCreateWithoutPhonesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutPhonesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirPersonPhoneUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  operatorCode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutPhonesInput>;
};

export type DirPersonUpdateOneRequiredWithoutPhonesInput = {
  create?: Maybe<DirPersonCreateWithoutPhonesInput>;
  update?: Maybe<DirPersonUpdateWithoutPhonesDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutPhonesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutPhonesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonUpsertWithoutPhonesInput = {
  update: DirPersonUpdateWithoutPhonesDataInput;
  create: DirPersonCreateWithoutPhonesInput;
};

export type DirPersonConfirmStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DirPersonAdditionalRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirPerson: DirPersonCreateOneWithoutAdditionalRegionsInput;
};

export type DirPersonCreateOneWithoutAdditionalRegionsInput = {
  create?: Maybe<DirPersonCreateWithoutAdditionalRegionsInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutAdditionalRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressCreateManyWithoutDirPersonInput>;
};

export type DirPersonAdditionalRegionUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAdditionalRegionsInput>;
};

export type DirPersonUpdateOneRequiredWithoutAdditionalRegionsInput = {
  create?: Maybe<DirPersonCreateWithoutAdditionalRegionsInput>;
  update?: Maybe<DirPersonUpdateWithoutAdditionalRegionsDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutAdditionalRegionsInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutAdditionalRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
  addresses?: Maybe<DirPersonAddressUpdateManyWithoutDirPersonInput>;
};

export type DirPersonUpsertWithoutAdditionalRegionsInput = {
  update: DirPersonUpdateWithoutAdditionalRegionsDataInput;
  create: DirPersonCreateWithoutAdditionalRegionsInput;
};

export type DirPersonMergeTaskCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  status: TaskStatusCreateOneInput;
  comment?: Maybe<Scalars['String']>;
  primary: DirPersonCreateOneWithoutMergeTasksAsPrimaryInput;
  duplicate: DirPersonCreateOneWithoutMergeTasksAsDuplicateInput;
};

export type DirPersonMergeTaskUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  status?: Maybe<TaskStatusUpdateOneRequiredInput>;
  comment?: Maybe<Scalars['String']>;
  primary?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsPrimaryInput>;
  duplicate?: Maybe<DirPersonUpdateOneRequiredWithoutMergeTasksAsDuplicateInput>;
};

export type DirPersonAddressCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAddressesInput;
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type: AddressTypeCreateOneInput;
};

export type DirPersonCreateOneWithoutAddressesInput = {
  create?: Maybe<DirPersonCreateWithoutAddressesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  fieldHistory?: Maybe<FieldHistoryCreateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonCreateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonCreateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserCreateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteCreateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerCreateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeCreateOneWithoutDirPersonInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  firstname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueCreateOneInput>;
  dirCitizenships?: Maybe<DirCountryCreateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  phones?: Maybe<DirPersonPhoneCreateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionCreateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskCreateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskCreateManyWithoutDuplicateInput>;
};

export type DirPersonAddressUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAddressesInput>;
  place?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeUpdateOneRequiredInput>;
};

export type DirPersonUpdateOneRequiredWithoutAddressesInput = {
  create?: Maybe<DirPersonCreateWithoutAddressesInput>;
  update?: Maybe<DirPersonUpdateWithoutAddressesDataInput>;
  upsert?: Maybe<DirPersonUpsertWithoutAddressesInput>;
  connect?: Maybe<DirPersonWhereUniqueInput>;
};

export type DirPersonUpdateWithoutAddressesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  fieldHistory?: Maybe<FieldHistoryUpdateManyInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  mergedAt?: Maybe<Scalars['DateTime']>;
  primary?: Maybe<DirPersonUpdateOneWithoutDuplicatesInput>;
  duplicates?: Maybe<DirPersonUpdateManyWithoutPrimaryInput>;
  userProfile?: Maybe<UserUpdateOneWithoutPersonProfileInput>;
  athleteProfile?: Maybe<DirAthleteUpdateOneWithoutDirPersonInput>;
  trainerProfile?: Maybe<DirTrainerUpdateOneWithoutDirPersonInput>;
  refereeProfile?: Maybe<DirRefereeUpdateOneWithoutDirPersonInput>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
  email?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isMale?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportIssuedBy?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
  dirCitizenships?: Maybe<DirCountryUpdateManyInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  phones?: Maybe<DirPersonPhoneUpdateManyWithoutDirPersonInput>;
  additionalRegions?: Maybe<DirPersonAdditionalRegionUpdateManyWithoutDirPersonInput>;
  mergeTasksAsPrimary?: Maybe<DirPersonMergeTaskUpdateManyWithoutPrimaryInput>;
  mergeTasksAsDuplicate?: Maybe<DirPersonMergeTaskUpdateManyWithoutDuplicateInput>;
};

export type DirPersonUpsertWithoutAddressesInput = {
  update: DirPersonUpdateWithoutAddressesDataInput;
  create: DirPersonCreateWithoutAddressesInput;
};

export type DirSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportDisciplineUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDisciplinesInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplinesInput>;
};

export type DirSportDisciplineGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDisciplineGroupsInput>;
  dirSport?: Maybe<DirSportUpdateOneWithoutDisciplineGroupsInput>;
};

export type DirSportAgeGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup: ClassifierValueCreateOneInput;
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportCreateOneWithoutAgeGroupsInput>;
};

export type DirSportCreateOneWithoutAgeGroupsInput = {
  create?: Maybe<DirSportCreateWithoutAgeGroupsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutAgeGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirSportAgeGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutAgeGroupsInput>;
};

export type DirSportUpdateOneWithoutAgeGroupsInput = {
  create?: Maybe<DirSportCreateWithoutAgeGroupsInput>;
  update?: Maybe<DirSportUpdateWithoutAgeGroupsDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutAgeGroupsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutAgeGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportUpsertWithoutAgeGroupsInput = {
  update: DirSportUpdateWithoutAgeGroupsDataInput;
  create: DirSportCreateWithoutAgeGroupsInput;
};

export type DirSportTrainingStageCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportCreateOneWithoutTrainingStagesInput>;
  clsTrainingStage: ClassifierValueCreateOneInput;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportCreateOneWithoutTrainingStagesInput = {
  create?: Maybe<DirSportCreateWithoutTrainingStagesInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutTrainingStagesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  baseRegions?: Maybe<DirSportBaseRegionCreateManyWithoutSportInput>;
};

export type DirSportTrainingStageUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneWithoutTrainingStagesInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
};

export type DirSportUpdateOneWithoutTrainingStagesInput = {
  create?: Maybe<DirSportCreateWithoutTrainingStagesInput>;
  update?: Maybe<DirSportUpdateWithoutTrainingStagesDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutTrainingStagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutTrainingStagesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  baseRegions?: Maybe<DirSportBaseRegionUpdateManyWithoutSportInput>;
};

export type DirSportUpsertWithoutTrainingStagesInput = {
  update: DirSportUpdateWithoutTrainingStagesDataInput;
  create: DirSportCreateWithoutTrainingStagesInput;
};

export type DirSportBaseRegionCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirSportCreateOneWithoutBaseRegionsInput;
  dirRegion: DirRegionCreateOneInput;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportCreateOneWithoutBaseRegionsInput = {
  create?: Maybe<DirSportCreateWithoutBaseRegionsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportCreateWithoutBaseRegionsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupCreateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueCreateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueCreateManyInput>;
  clsCategories?: Maybe<ClassifierValueCreateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageCreateManyWithoutDirSportInput>;
};

export type DirSportBaseRegionUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirSportUpdateOneRequiredWithoutBaseRegionsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirSportUpdateOneRequiredWithoutBaseRegionsInput = {
  create?: Maybe<DirSportCreateWithoutBaseRegionsInput>;
  update?: Maybe<DirSportUpdateWithoutBaseRegionsDataInput>;
  upsert?: Maybe<DirSportUpsertWithoutBaseRegionsInput>;
  connect?: Maybe<DirSportWhereUniqueInput>;
};

export type DirSportUpdateWithoutBaseRegionsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  evsk?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  clsSportAndDisciplineGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyWithoutDirSportInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyWithoutDirSportInput>;
  ageGroups?: Maybe<DirSportAgeGroupUpdateManyWithoutDirSportInput>;
  clsTrainingStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsRanksAndAwards?: Maybe<ClassifierValueUpdateManyInput>;
  clsCategories?: Maybe<ClassifierValueUpdateManyInput>;
  trainingStages?: Maybe<DirSportTrainingStageUpdateManyWithoutDirSportInput>;
};

export type DirSportUpsertWithoutBaseRegionsInput = {
  update: DirSportUpdateWithoutBaseRegionsDataInput;
  create: DirSportCreateWithoutBaseRegionsInput;
};

export type DirAthleteUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirAthleteSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  dirTrainers?: Maybe<DirTrainerUpdateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationUpdateManyInput>;
  ranksAndAwards?: Maybe<DirAthleteSportRankAndAwardUpdateManyWithoutSportInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteSportOrganizationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  dirTrainer?: Maybe<DirTrainerUpdateOneInput>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
};

export type DirAthleteSportRankAndAwardCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirAthleteSportCreateOneWithoutRanksAndAwardsInput;
  clsRankAndAward: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
};

export type DirAthleteSportCreateOneWithoutRanksAndAwardsInput = {
  create?: Maybe<DirAthleteSportCreateWithoutRanksAndAwardsInput>;
  connect?: Maybe<DirAthleteSportWhereUniqueInput>;
};

export type DirAthleteSportCreateWithoutRanksAndAwardsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  dirTrainers?: Maybe<DirTrainerCreateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationCreateManyInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteSportRankAndAwardUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirAthleteSportUpdateOneRequiredWithoutRanksAndAwardsInput>;
  clsRankAndAward?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  confirmDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
};

export type DirAthleteSportUpdateOneRequiredWithoutRanksAndAwardsInput = {
  create?: Maybe<DirAthleteSportCreateWithoutRanksAndAwardsInput>;
  update?: Maybe<DirAthleteSportUpdateWithoutRanksAndAwardsDataInput>;
  upsert?: Maybe<DirAthleteSportUpsertWithoutRanksAndAwardsInput>;
  connect?: Maybe<DirAthleteSportWhereUniqueInput>;
};

export type DirAthleteSportUpdateWithoutRanksAndAwardsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  dirTrainers?: Maybe<DirTrainerUpdateManyInput>;
  organizations?: Maybe<DirAthleteSportOrganizationUpdateManyInput>;
  role?: Maybe<Scalars['String']>;
};

export type DirAthleteSportUpsertWithoutRanksAndAwardsInput = {
  update: DirAthleteSportUpdateWithoutRanksAndAwardsDataInput;
  create: DirAthleteSportCreateWithoutRanksAndAwardsInput;
};

export type DirAthleteMedCertCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete: DirAthleteCreateOneWithoutMedCertsInput;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportCreateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteCreateOneWithoutMedCertsInput = {
  create?: Maybe<DirAthleteCreateWithoutMedCertsInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateWithoutMedCertsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAthleteProfileInput;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultCreateManyWithoutDirAthleteInput>;
};

export type DirAthleteMedCertUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredWithoutMedCertsInput>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  sports?: Maybe<DirAthleteMedCertSportUpdateManyWithoutMedCertInput>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteUpdateOneRequiredWithoutMedCertsInput = {
  create?: Maybe<DirAthleteCreateWithoutMedCertsInput>;
  update?: Maybe<DirAthleteUpdateWithoutMedCertsDataInput>;
  upsert?: Maybe<DirAthleteUpsertWithoutMedCertsInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateWithoutMedCertsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  competetionResult?: Maybe<DirAthleteCompetitionResultUpdateManyWithoutDirAthleteInput>;
};

export type DirAthleteUpsertWithoutMedCertsInput = {
  update: DirAthleteUpdateWithoutMedCertsDataInput;
  create: DirAthleteCreateWithoutMedCertsInput;
};

export type DirAthleteMedCertSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  medCert: DirAthleteMedCertCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  clsTrainingStage?: Maybe<ClassifierValueCreateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirAthleteMedCertCreateOneWithoutSportsInput = {
  create?: Maybe<DirAthleteMedCertCreateWithoutSportsInput>;
  connect?: Maybe<DirAthleteMedCertWhereUniqueInput>;
};

export type DirAthleteMedCertCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete: DirAthleteCreateOneWithoutMedCertsInput;
  issueDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName: Scalars['String'];
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  medCert?: Maybe<DirAthleteMedCertUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  clsTrainingStage?: Maybe<ClassifierValueUpdateOneInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirAthleteMedCertUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirAthleteMedCertCreateWithoutSportsInput>;
  update?: Maybe<DirAthleteMedCertUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirAthleteMedCertUpsertWithoutSportsInput>;
  connect?: Maybe<DirAthleteMedCertWhereUniqueInput>;
};

export type DirAthleteMedCertUpdateWithoutSportsDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  displayNumber?: Maybe<Scalars['String']>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredWithoutMedCertsInput>;
  issueDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  organizationPhone?: Maybe<Scalars['String']>;
  organizationEmail?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  physicalExaminationDate?: Maybe<Scalars['DateTime']>;
  hasRestrictions?: Maybe<Scalars['Boolean']>;
};

export type DirAthleteMedCertUpsertWithoutSportsInput = {
  update: DirAthleteMedCertUpdateWithoutSportsDataInput;
  create: DirAthleteMedCertCreateWithoutSportsInput;
};

export type DirAthleteCompetitionResultCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirAthlete: DirAthleteCreateOneWithoutCompetetionResultInput;
  sportingEventProgramType: DirSportingEventProgramTypeCreateOneInput;
  point: Scalars['Int'];
  result: Scalars['String'];
};

export type DirAthleteCreateOneWithoutCompetetionResultInput = {
  create?: Maybe<DirAthleteCreateWithoutCompetetionResultInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteCreateWithoutCompetetionResultInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutAthleteProfileInput;
  dirPersonAgent?: Maybe<DirPersonCreateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueCreateOneInput>;
  sports?: Maybe<DirAthleteSportCreateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertCreateManyWithoutDirAthleteInput>;
};

export type DirAthleteCompetitionResultUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredWithoutCompetetionResultInput>;
  sportingEventProgramType?: Maybe<DirSportingEventProgramTypeUpdateOneRequiredInput>;
  point?: Maybe<Scalars['Int']>;
  result?: Maybe<Scalars['String']>;
};

export type DirAthleteUpdateOneRequiredWithoutCompetetionResultInput = {
  create?: Maybe<DirAthleteCreateWithoutCompetetionResultInput>;
  update?: Maybe<DirAthleteUpdateWithoutCompetetionResultDataInput>;
  upsert?: Maybe<DirAthleteUpsertWithoutCompetetionResultInput>;
  connect?: Maybe<DirAthleteWhereUniqueInput>;
};

export type DirAthleteUpdateWithoutCompetetionResultDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutAthleteProfileInput>;
  dirPersonAgent?: Maybe<DirPersonUpdateOneInput>;
  clsPersonAgent?: Maybe<ClassifierValueUpdateOneInput>;
  sports?: Maybe<DirAthleteSportUpdateManyInput>;
  medCerts?: Maybe<DirAthleteMedCertUpdateManyWithoutDirAthleteInput>;
};

export type DirAthleteUpsertWithoutCompetetionResultInput = {
  update: DirAthleteUpdateWithoutCompetetionResultDataInput;
  create: DirAthleteCreateWithoutCompetetionResultInput;
};

export type DirTrainerUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutTrainerProfileInput>;
  sports?: Maybe<DirTrainerSportUpdateManyWithoutDirTrainerInput>;
};

export type DirTrainerSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainerCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  organizations?: Maybe<DirTrainerSportOrganizationCreateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryCreateManyWithoutSportInput>;
};

export type DirTrainerCreateOneWithoutSportsInput = {
  create?: Maybe<DirTrainerCreateWithoutSportsInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutTrainerProfileInput;
};

export type DirTrainerSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  organizations?: Maybe<DirTrainerSportOrganizationUpdateManyWithoutSportInput>;
  categories?: Maybe<DirTrainerSportCategoryUpdateManyWithoutSportInput>;
};

export type DirTrainerUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirTrainerCreateWithoutSportsInput>;
  update?: Maybe<DirTrainerUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirTrainerUpsertWithoutSportsInput>;
  connect?: Maybe<DirTrainerWhereUniqueInput>;
};

export type DirTrainerUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutTrainerProfileInput>;
};

export type DirTrainerUpsertWithoutSportsInput = {
  update: DirTrainerUpdateWithoutSportsDataInput;
  create: DirTrainerCreateWithoutSportsInput;
};

export type DirTrainerSportOrganizationCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization: DirOrganizationCreateOneInput;
  clsPosition: ClassifierValueCreateOneInput;
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
  sport: DirTrainerSportCreateOneWithoutOrganizationsInput;
};

export type DirTrainerSportCreateOneWithoutOrganizationsInput = {
  create?: Maybe<DirTrainerSportCreateWithoutOrganizationsInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportCreateWithoutOrganizationsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainerCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  categories?: Maybe<DirTrainerSportCategoryCreateManyWithoutSportInput>;
};

export type DirTrainerSportOrganizationUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirOrganization?: Maybe<DirOrganizationUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  employmentDate?: Maybe<Scalars['DateTime']>;
  dismissalDate?: Maybe<Scalars['DateTime']>;
  sport?: Maybe<DirTrainerSportUpdateOneRequiredWithoutOrganizationsInput>;
};

export type DirTrainerSportUpdateOneRequiredWithoutOrganizationsInput = {
  create?: Maybe<DirTrainerSportCreateWithoutOrganizationsInput>;
  update?: Maybe<DirTrainerSportUpdateWithoutOrganizationsDataInput>;
  upsert?: Maybe<DirTrainerSportUpsertWithoutOrganizationsInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportUpdateWithoutOrganizationsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  categories?: Maybe<DirTrainerSportCategoryUpdateManyWithoutSportInput>;
};

export type DirTrainerSportUpsertWithoutOrganizationsInput = {
  update: DirTrainerSportUpdateWithoutOrganizationsDataInput;
  create: DirTrainerSportCreateWithoutOrganizationsInput;
};

export type DirTrainerSportCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  sport: DirTrainerSportCreateOneWithoutCategoriesInput;
};

export type DirTrainerSportCreateOneWithoutCategoriesInput = {
  create?: Maybe<DirTrainerSportCreateWithoutCategoriesInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportCreateWithoutCategoriesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer: DirTrainerCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  organizations?: Maybe<DirTrainerSportOrganizationCreateManyWithoutSportInput>;
};

export type DirTrainerSportCategoryUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  clsTrainerCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  sport?: Maybe<DirTrainerSportUpdateOneRequiredWithoutCategoriesInput>;
};

export type DirTrainerSportUpdateOneRequiredWithoutCategoriesInput = {
  create?: Maybe<DirTrainerSportCreateWithoutCategoriesInput>;
  update?: Maybe<DirTrainerSportUpdateWithoutCategoriesDataInput>;
  upsert?: Maybe<DirTrainerSportUpsertWithoutCategoriesInput>;
  connect?: Maybe<DirTrainerSportWhereUniqueInput>;
};

export type DirTrainerSportUpdateWithoutCategoriesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  organizations?: Maybe<DirTrainerSportOrganizationUpdateManyWithoutSportInput>;
};

export type DirTrainerSportUpsertWithoutCategoriesInput = {
  update: DirTrainerSportUpdateWithoutCategoriesDataInput;
  create: DirTrainerSportCreateWithoutCategoriesInput;
};

export type DirRefereeUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutRefereeProfileInput>;
  sports?: Maybe<DirRefereeSportUpdateManyWithoutDirRefereeInput>;
};

export type DirRefereeSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirRefereeCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryCreateManyWithoutSportInput>;
};

export type DirRefereeCreateOneWithoutSportsInput = {
  create?: Maybe<DirRefereeCreateWithoutSportsInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson: DirPersonCreateOneWithoutRefereeProfileInput;
};

export type DirRefereeSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  workExperience?: Maybe<Scalars['Int']>;
  categories?: Maybe<DirRefereeSportCategoryUpdateManyWithoutSportInput>;
};

export type DirRefereeUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirRefereeCreateWithoutSportsInput>;
  update?: Maybe<DirRefereeUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirRefereeUpsertWithoutSportsInput>;
  connect?: Maybe<DirRefereeWhereUniqueInput>;
};

export type DirRefereeUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirPerson?: Maybe<DirPersonUpdateOneRequiredWithoutRefereeProfileInput>;
};

export type DirRefereeUpsertWithoutSportsInput = {
  update: DirRefereeUpdateWithoutSportsDataInput;
  create: DirRefereeCreateWithoutSportsInput;
};

export type DirRefereeSportCategoryCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport: DirRefereeSportCreateOneWithoutCategoriesInput;
  clsRefereeCategory: ClassifierValueCreateOneInput;
  assignmentDate: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  dirDocument?: Maybe<DirDocumentCreateOneInput>;
};

export type DirRefereeSportCreateOneWithoutCategoriesInput = {
  create?: Maybe<DirRefereeSportCreateWithoutCategoriesInput>;
  connect?: Maybe<DirRefereeSportWhereUniqueInput>;
};

export type DirRefereeSportCreateWithoutCategoriesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee: DirRefereeCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  workExperience?: Maybe<Scalars['Int']>;
};

export type DirRefereeSportCategoryUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  sport?: Maybe<DirRefereeSportUpdateOneRequiredWithoutCategoriesInput>;
  clsRefereeCategory?: Maybe<ClassifierValueUpdateOneRequiredInput>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  isEndless?: Maybe<Scalars['Boolean']>;
  dirDocument?: Maybe<DirDocumentUpdateOneInput>;
};

export type DirRefereeSportUpdateOneRequiredWithoutCategoriesInput = {
  create?: Maybe<DirRefereeSportCreateWithoutCategoriesInput>;
  update?: Maybe<DirRefereeSportUpdateWithoutCategoriesDataInput>;
  upsert?: Maybe<DirRefereeSportUpsertWithoutCategoriesInput>;
  connect?: Maybe<DirRefereeSportWhereUniqueInput>;
};

export type DirRefereeSportUpdateWithoutCategoriesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  workExperience?: Maybe<Scalars['Int']>;
};

export type DirRefereeSportUpsertWithoutCategoriesInput = {
  update: DirRefereeSportUpdateWithoutCategoriesDataInput;
  create: DirRefereeSportCreateWithoutCategoriesInput;
};

export type DirSportingEventCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirSportingEventUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventSportCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutSportsInput;
  dirSport: DirSportCreateOneInput;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirSportingEventCreateOneWithoutSportsInput = {
  create?: Maybe<DirSportingEventCreateWithoutSportsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutSportsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirSportingEventSportUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutSportsInput>;
  dirSport?: Maybe<DirSportUpdateOneRequiredInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutSportsInput = {
  create?: Maybe<DirSportingEventCreateWithoutSportsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutSportsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutSportsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutSportsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventUpsertWithoutSportsInput = {
  update: DirSportingEventUpdateWithoutSportsDataInput;
  create: DirSportingEventCreateWithoutSportsInput;
};

export type DirSportingEventAgeGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutAgeGroupsInput;
  clsAgeGroup?: Maybe<ClassifierValueCreateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventCreateOneWithoutAgeGroupsInput = {
  create?: Maybe<DirSportingEventCreateWithoutAgeGroupsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutAgeGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirSportingEventAgeGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutAgeGroupsInput>;
  clsAgeGroup?: Maybe<ClassifierValueUpdateOneInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
};

export type DirSportingEventUpdateOneRequiredWithoutAgeGroupsInput = {
  create?: Maybe<DirSportingEventCreateWithoutAgeGroupsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutAgeGroupsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutAgeGroupsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutAgeGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventUpsertWithoutAgeGroupsInput = {
  update: DirSportingEventUpdateWithoutAgeGroupsDataInput;
  create: DirSportingEventCreateWithoutAgeGroupsInput;
};

export type DirSportingEventStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirSportingEventStatusHistoryUpdateInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type DirSportingEventProgramTypeUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutProgramTypesInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  discipline?: Maybe<DirSportDisciplineUpdateOneRequiredInput>;
};

export type DirSportingEventRefereeCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutRefereesInput;
  dirReferee: DirRefereeCreateOneInput;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition: ClassifierValueCreateOneInput;
};

export type DirSportingEventCreateOneWithoutRefereesInput = {
  create?: Maybe<DirSportingEventCreateWithoutRefereesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutRefereesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirSportingEventRefereeUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutRefereesInput>;
  dirReferee?: Maybe<DirRefereeUpdateOneRequiredInput>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  clsRefereePosition?: Maybe<ClassifierValueUpdateOneRequiredInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutRefereesInput = {
  create?: Maybe<DirSportingEventCreateWithoutRefereesInput>;
  update?: Maybe<DirSportingEventUpdateWithoutRefereesDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutRefereesInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutRefereesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventUpsertWithoutRefereesInput = {
  update: DirSportingEventUpdateWithoutRefereesDataInput;
  create: DirSportingEventCreateWithoutRefereesInput;
};

export type DirSportingEventCompetitionCalendarCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event: DirSportingEventCreateOneWithoutCompetitionCalendarInput;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineCreateOneInput>;
};

export type DirSportingEventCreateOneWithoutCompetitionCalendarInput = {
  create?: Maybe<DirSportingEventCreateWithoutCompetitionCalendarInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutCompetitionCalendarInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamCreateManyWithoutDirSportingEventInput>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirSportingEventCompetitionCalendarUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  event?: Maybe<DirSportingEventUpdateOneRequiredWithoutCompetitionCalendarInput>;
  day?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['String']>;
  members?: Maybe<Scalars['String']>;
  discipline?: Maybe<DirSportDisciplineUpdateOneInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutCompetitionCalendarInput = {
  create?: Maybe<DirSportingEventCreateWithoutCompetitionCalendarInput>;
  update?: Maybe<DirSportingEventUpdateWithoutCompetitionCalendarDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutCompetitionCalendarInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutCompetitionCalendarDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  dirNationalTeams?: Maybe<DirNationalTeamUpdateManyWithoutDirSportingEventInput>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventUpsertWithoutCompetitionCalendarInput = {
  update: DirSportingEventUpdateWithoutCompetitionCalendarDataInput;
  create: DirSportingEventCreateWithoutCompetitionCalendarInput;
};

export type DirNationalTeamCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
};

export type DirSportingEventCreateOneWithoutDirNationalTeamsInput = {
  create?: Maybe<DirSportingEventCreateWithoutDirNationalTeamsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventCreateWithoutDirNationalTeamsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  venue: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionCreateOneInput>;
  dirCountry?: Maybe<DirCountryCreateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityCreateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventCreateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventCreateManyWithoutParentInput>;
  status: DirSportingEventStatusCreateOneInput;
  statusHistory?: Maybe<DirSportingEventStatusHistoryCreateManyInput>;
  dirCalendar: DirCalendarCreateOneInput;
  clsEventCategories?: Maybe<ClassifierValueCreateManyInput>;
  sports?: Maybe<DirSportingEventSportCreateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupCreateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeCreateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeCreateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueCreateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarCreateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentCreateManyInput>;
  competitionProtocols?: Maybe<DirDocumentCreateManyInput>;
  organizer?: Maybe<DirOrganizationCreateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueCreateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentCreateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueCreateOneInput>;
  clsDivisions?: Maybe<ClassifierValueCreateManyInput>;
  clsEventStages?: Maybe<ClassifierValueCreateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueCreateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueCreateManyInput>;
};

export type DirNationalTeamUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
};

export type DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput = {
  create?: Maybe<DirSportingEventCreateWithoutDirNationalTeamsInput>;
  update?: Maybe<DirSportingEventUpdateWithoutDirNationalTeamsDataInput>;
  upsert?: Maybe<DirSportingEventUpsertWithoutDirNationalTeamsInput>;
  connect?: Maybe<DirSportingEventWhereUniqueInput>;
};

export type DirSportingEventUpdateWithoutDirNationalTeamsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minManBirthYear?: Maybe<Scalars['Int']>;
  maxManBirthYear?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minWomanBirthYear?: Maybe<Scalars['Int']>;
  maxWomanBirthYear?: Maybe<Scalars['Int']>;
  membersQuota?: Maybe<Scalars['Int']>;
  refereesQuota?: Maybe<Scalars['Int']>;
  nonresidentRefereesQuota?: Maybe<Scalars['Int']>;
  ekp?: Maybe<Scalars['String']>;
  isRussia?: Maybe<Scalars['Boolean']>;
  dirRegion?: Maybe<DirRegionUpdateOneInput>;
  dirCountry?: Maybe<DirCountryUpdateOneInput>;
  dirForeignCity?: Maybe<DirForeignCityUpdateOneInput>;
  planRUB?: Maybe<Scalars['Float']>;
  planRUBperPerson?: Maybe<Scalars['Float']>;
  planUSD?: Maybe<Scalars['Float']>;
  planUSDperPerson?: Maybe<Scalars['Float']>;
  planDate?: Maybe<Scalars['DateTime']>;
  currentPlanRUB?: Maybe<Scalars['Float']>;
  currentPlanRUBperPerson?: Maybe<Scalars['Float']>;
  currentPlanUSD?: Maybe<Scalars['Float']>;
  currentPlanUSDperPerson?: Maybe<Scalars['Float']>;
  currentPlanDate?: Maybe<Scalars['DateTime']>;
  factRUB?: Maybe<Scalars['Float']>;
  factRUBperPerson?: Maybe<Scalars['Float']>;
  factUSD?: Maybe<Scalars['Float']>;
  factUSDperPerson?: Maybe<Scalars['Float']>;
  factDate?: Maybe<Scalars['DateTime']>;
  quotaAthletesMan?: Maybe<Scalars['Int']>;
  quotaAthletesWoman?: Maybe<Scalars['Int']>;
  quotaTrainers?: Maybe<Scalars['Int']>;
  quotaReferees?: Maybe<Scalars['Int']>;
  quotaAdministration?: Maybe<Scalars['Int']>;
  quotaMedicalStaff?: Maybe<Scalars['Int']>;
  quotaSpecialists?: Maybe<Scalars['Int']>;
  quotaTeams?: Maybe<Scalars['Int']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isOlympicGamesPreparation?: Maybe<Scalars['Boolean']>;
  isOrganizerOrParticipant?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<DirSportingEventUpdateOneWithoutChildrenInput>;
  children?: Maybe<DirSportingEventUpdateManyWithoutParentInput>;
  status?: Maybe<DirSportingEventStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirSportingEventStatusHistoryUpdateManyInput>;
  dirCalendar?: Maybe<DirCalendarUpdateOneRequiredInput>;
  clsEventCategories?: Maybe<ClassifierValueUpdateManyInput>;
  sports?: Maybe<DirSportingEventSportUpdateManyWithoutEventInput>;
  ageGroups?: Maybe<DirSportingEventAgeGroupUpdateManyWithoutEventInput>;
  programTypes?: Maybe<DirSportingEventProgramTypeUpdateManyWithoutEventInput>;
  referees?: Maybe<DirSportingEventRefereeUpdateManyWithoutEventInput>;
  clsMinRang?: Maybe<ClassifierValueUpdateOneInput>;
  competitionCalendar?: Maybe<DirSportingEventCompetitionCalendarUpdateManyWithoutEventInput>;
  competitionReglaments?: Maybe<DirDocumentUpdateManyInput>;
  competitionProtocols?: Maybe<DirDocumentUpdateManyInput>;
  organizer?: Maybe<DirOrganizationUpdateOneInput>;
  clsDirectionality?: Maybe<ClassifierValueUpdateOneInput>;
  refereeCollegiumDocuments?: Maybe<DirDocumentUpdateManyInput>;
  clsSummarizingType?: Maybe<ClassifierValueUpdateOneInput>;
  clsDivisions?: Maybe<ClassifierValueUpdateManyInput>;
  clsEventStages?: Maybe<ClassifierValueUpdateManyInput>;
  clsParticipatingOrgCategories?: Maybe<ClassifierValueUpdateManyInput>;
  clsFinancingTypes?: Maybe<ClassifierValueUpdateManyInput>;
};

export type DirSportingEventUpsertWithoutDirNationalTeamsInput = {
  update: DirSportingEventUpdateWithoutDirNationalTeamsDataInput;
  create: DirSportingEventCreateWithoutDirNationalTeamsInput;
};

export type DirNationalTeamStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type DirNationalTeamStatusHistoryUpdateInput = {
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  user?: Maybe<UserUpdateOneRequiredInput>;
};

export type DirNationalTeamTrainerCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  team: DirNationalTeamCreateOneWithoutTrainersInput;
  dirTrainer: DirTrainerCreateOneInput;
  clsPosition?: Maybe<ClassifierValueCreateOneInput>;
};

export type DirNationalTeamCreateOneWithoutTrainersInput = {
  create?: Maybe<DirNationalTeamCreateWithoutTrainersInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamCreateWithoutTrainersInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
};

export type DirNationalTeamTrainerUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutTrainersInput>;
  dirTrainer?: Maybe<DirTrainerUpdateOneRequiredInput>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirNationalTeamUpdateOneRequiredWithoutTrainersInput = {
  create?: Maybe<DirNationalTeamCreateWithoutTrainersInput>;
  update?: Maybe<DirNationalTeamUpdateWithoutTrainersDataInput>;
  upsert?: Maybe<DirNationalTeamUpsertWithoutTrainersInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamUpdateWithoutTrainersDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  athleteGroups?: Maybe<DirNationalTeamAthleteGroupUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
};

export type DirNationalTeamUpsertWithoutTrainersInput = {
  update: DirNationalTeamUpdateWithoutTrainersDataInput;
  create: DirNationalTeamCreateWithoutTrainersInput;
};

export type DirNationalTeamAthleteGroupCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team: DirNationalTeamCreateOneWithoutAthleteGroupsInput;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteCreateManyWithoutGroupInput>;
};

export type DirNationalTeamCreateOneWithoutAthleteGroupsInput = {
  create?: Maybe<DirNationalTeamCreateWithoutAthleteGroupsInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamCreateWithoutAthleteGroupsInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota: Scalars['Int'];
  womanTeamQuota: Scalars['Int'];
  supportTeamQuota: Scalars['Int'];
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status: DirNationalTeamStatusCreateOneInput;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryCreateManyInput>;
  dirSportingEvent: DirSportingEventCreateOneWithoutDirNationalTeamsInput;
  dirRegion: DirRegionCreateOneInput;
  trainers?: Maybe<DirNationalTeamTrainerCreateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberCreateManyInput>;
  medics?: Maybe<DirNationalTeamMemberCreateManyInput>;
};

export type DirNationalTeamAthleteGroupUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutAthleteGroupsInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
  athletes?: Maybe<DirNationalTeamAthleteUpdateManyWithoutGroupInput>;
};

export type DirNationalTeamUpdateOneRequiredWithoutAthleteGroupsInput = {
  create?: Maybe<DirNationalTeamCreateWithoutAthleteGroupsInput>;
  update?: Maybe<DirNationalTeamUpdateWithoutAthleteGroupsDataInput>;
  upsert?: Maybe<DirNationalTeamUpsertWithoutAthleteGroupsInput>;
  connect?: Maybe<DirNationalTeamWhereUniqueInput>;
};

export type DirNationalTeamUpdateWithoutAthleteGroupsDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  manTeamQuota?: Maybe<Scalars['Int']>;
  womanTeamQuota?: Maybe<Scalars['Int']>;
  supportTeamQuota?: Maybe<Scalars['Int']>;
  athletesCall?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isNotified?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DirNationalTeamStatusUpdateOneRequiredInput>;
  statusHistory?: Maybe<DirNationalTeamStatusHistoryUpdateManyInput>;
  dirSportingEvent?: Maybe<DirSportingEventUpdateOneRequiredWithoutDirNationalTeamsInput>;
  dirRegion?: Maybe<DirRegionUpdateOneRequiredInput>;
  trainers?: Maybe<DirNationalTeamTrainerUpdateManyWithoutTeamInput>;
  specialists?: Maybe<DirNationalTeamMemberUpdateManyInput>;
  medics?: Maybe<DirNationalTeamMemberUpdateManyInput>;
};

export type DirNationalTeamUpsertWithoutAthleteGroupsInput = {
  update: DirNationalTeamUpdateWithoutAthleteGroupsDataInput;
  create: DirNationalTeamCreateWithoutAthleteGroupsInput;
};

export type DirNationalTeamAthleteCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  group: DirNationalTeamAthleteGroupCreateOneWithoutAthletesInput;
  dirAthlete: DirAthleteCreateOneInput;
  bestResult?: Maybe<Scalars['String']>;
};

export type DirNationalTeamAthleteGroupCreateOneWithoutAthletesInput = {
  create?: Maybe<DirNationalTeamAthleteGroupCreateWithoutAthletesInput>;
  connect?: Maybe<DirNationalTeamAthleteGroupWhereUniqueInput>;
};

export type DirNationalTeamAthleteGroupCreateWithoutAthletesInput = {
  id?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  updatedBy?: Maybe<UserCreateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team: DirNationalTeamCreateOneWithoutAthleteGroupsInput;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueCreateManyInput>;
  disciplines?: Maybe<DirSportDisciplineCreateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupCreateManyInput>;
};

export type DirNationalTeamAthleteUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  group?: Maybe<DirNationalTeamAthleteGroupUpdateOneRequiredWithoutAthletesInput>;
  dirAthlete?: Maybe<DirAthleteUpdateOneRequiredInput>;
  bestResult?: Maybe<Scalars['String']>;
};

export type DirNationalTeamAthleteGroupUpdateOneRequiredWithoutAthletesInput = {
  create?: Maybe<DirNationalTeamAthleteGroupCreateWithoutAthletesInput>;
  update?: Maybe<DirNationalTeamAthleteGroupUpdateWithoutAthletesDataInput>;
  upsert?: Maybe<DirNationalTeamAthleteGroupUpsertWithoutAthletesInput>;
  connect?: Maybe<DirNationalTeamAthleteGroupWhereUniqueInput>;
};

export type DirNationalTeamAthleteGroupUpdateWithoutAthletesDataInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  team?: Maybe<DirNationalTeamUpdateOneRequiredWithoutAthleteGroupsInput>;
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  clsAgeGroups?: Maybe<ClassifierValueUpdateManyInput>;
  disciplines?: Maybe<DirSportDisciplineUpdateManyInput>;
  disciplineGroups?: Maybe<DirSportDisciplineGroupUpdateManyInput>;
};

export type DirNationalTeamAthleteGroupUpsertWithoutAthletesInput = {
  update: DirNationalTeamAthleteGroupUpdateWithoutAthletesDataInput;
  create: DirNationalTeamAthleteGroupCreateWithoutAthletesInput;
};

export type DirNationalTeamMemberUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  clsPosition?: Maybe<ClassifierValueUpdateOneInput>;
};

export type DirCalendarUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  registryNumber?: Maybe<Scalars['Int']>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DirDocumentUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  archive?: Maybe<ArchiveUpdateOneInput>;
  iasKey?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  file?: Maybe<FileUpdateOneRequiredInput>;
};

export type AddressTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type TaskStatusUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type FileUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>;
  updatedBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};

export type PublicAthleteMedCerts = {
  __typename?: 'PublicAthleteMedCerts';
  athlete: PublicAthleteInfo;
  medCerts: Array<PublicMedCertInfo>;
};

export type ArchiveStatusFragment = { __typename?: 'Archive' } & Pick<Archive, 'id' | 'reason' | 'date' | 'comment'>;

export type AthletesExpelMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID?: Maybe<Scalars['UUID']>;
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;

export type AthletesExpelMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'athletesExpel'>;

export type AthletesExpelWithMoveToAnotherTrainerMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  fromTrainerID: Scalars['UUID'];
  toTrainerID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;

export type AthletesExpelWithMoveToAnotherTrainerMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'athletesExpelWithMoveToAnotherTrainer'
>;

export type AthletesExpelWithMoveToAnotherTrainingStageMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  fromTrainingStageID: Scalars['UUID'];
  toTrainingStageID: Scalars['UUID'];
  trainerID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;

export type AthletesExpelWithMoveToAnotherTrainingStageMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'athletesExpelWithMoveToAnotherTrainingStage'
>;

export type AthletesExpelWithMoveToSelfTrainingMutationVariables = Exact<{
  athletesID: Array<Scalars['UUID']>;
  sportID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  trainerID: Scalars['UUID'];
  trainingStageID: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;

export type AthletesExpelWithMoveToSelfTrainingMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'athletesExpelWithMoveToSelfTraining'
>;

export type UpdateDirAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirAthleteUpdateInput;
}>;

export type UpdateDirAthleteMutation = { __typename?: 'Mutation' } & {
  updateDirAthlete?: Maybe<{ __typename?: 'DirAthlete' } & AthleteFrFragment>;
};

export type CreateAthleteMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
}>;

export type CreateAthleteMutation = { __typename?: 'Mutation' } & {
  createDirAthlete: { __typename?: 'DirAthlete' } & AthleteFrFragment;
};

export type AthleteConnectDisciplineToSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDiscipline?: Maybe<Scalars['UUID']>;
}>;

export type AthleteConnectDisciplineToSportMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSport?: Maybe<
    { __typename?: 'DirAthleteSport' } & {
      disciplines?: Maybe<
        Array<
          { __typename?: 'DirSportDiscipline' } & { value: DirSportDiscipline['id']; label: DirSportDiscipline['name'] }
        >
      >;
    }
  >;
};

export type AthleteDisconnectDisciplineToSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDiscipline?: Maybe<Scalars['UUID']>;
}>;

export type AthleteDisconnectDisciplineToSportMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSport?: Maybe<
    { __typename?: 'DirAthleteSport' } & {
      disciplines?: Maybe<
        Array<
          { __typename?: 'DirSportDiscipline' } & { value: DirSportDiscipline['id']; label: DirSportDiscipline['name'] }
        >
      >;
    }
  >;
};

export type UpdateAthleteSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirAthleteSportUpdateInput;
}>;

export type UpdateAthleteSportMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSport?: Maybe<{ __typename?: 'DirAthleteSport' } & Pick<DirAthleteSport, 'id'>>;
};

export type AthleteUpdateAmpluaToSportMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  amplua?: Maybe<Scalars['String']>;
}>;

export type AthleteUpdateAmpluaToSportMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSport?: Maybe<{ __typename?: 'DirAthleteSport' } & Pick<DirAthleteSport, 'id'>>;
};

export type AddTypeSportsToAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;

export type AddTypeSportsToAthleteMutation = { __typename?: 'Mutation' } & {
  updateDirAthlete?: Maybe<{ __typename?: 'DirAthlete' } & AthleteFrFragment>;
};

export type TypeSportAthleteArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type TypeSportAthleteArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSport?: Maybe<
    { __typename?: 'DirAthleteSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type TypeSportAthleteUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportAthleteUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSport?: Maybe<
    { __typename?: 'DirAthleteSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type AddAthleteOrganizationMutationVariables = Exact<{
  idSport: Scalars['UUID'];
  idDirOrganization: Scalars['UUID'];
  idDirTrainer?: Maybe<DirTrainerCreateOneInput>;
  clsTrainingStage: Scalars['UUID'];
  trainingStart: Scalars['DateTime'];
  isSelfTraining?: Maybe<Scalars['Boolean']>;
}>;

export type AddAthleteOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSport?: Maybe<{ __typename?: 'DirAthleteSport' } & Pick<DirAthleteSport, 'id'>>;
};

export type UpdateAthleteOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  idDirOrganization?: Maybe<Scalars['UUID']>;
  idDirTrainer?: Maybe<DirTrainerUpdateOneInput>;
  clsTrainingStage?: Maybe<Scalars['UUID']>;
  trainingStart?: Maybe<Scalars['DateTime']>;
  exclusionDate?: Maybe<Scalars['DateTime']>;
  isSelfTraining?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateAthleteOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSportOrganization?: Maybe<
    { __typename?: 'DirAthleteSportOrganization' } & Pick<DirAthleteSportOrganization, 'id'>
  >;
};

export type DeleteAthleteOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  exclusionDate: Scalars['DateTime'];
}>;

export type DeleteAthleteOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirAthleteSportOrganization?: Maybe<
    { __typename?: 'DirAthleteSportOrganization' } & Pick<DirAthleteSportOrganization, 'id'>
  >;
};

export type AthleteFrFragment = { __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'> & {
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
    medCerts?: Maybe<
      Array<
        { __typename?: 'DirAthleteMedCert' } & Pick<
          DirAthleteMedCert,
          | 'id'
          | 'issueDate'
          | 'displayNumber'
          | 'organizationName'
          | 'organizationPhone'
          | 'organizationEmail'
          | 'organizationAddress'
          | 'physicalExaminationDate'
          | 'hasRestrictions'
          | 'validUntil'
          | 'isValid'
          | 'deletedAt'
        > & {
            sports?: Maybe<
              Array<
                { __typename?: 'DirAthleteMedCertSport' } & {
                  dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'fullName'> & {
                      disciplineGroups?: Maybe<
                        Array<{ __typename?: 'DirSportDisciplineGroup' } & Pick<DirSportDisciplineGroup, 'name'>>
                      >;
                    };
                  clsTrainingStage?: Maybe<{ __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'shortName'>>;
                  disciplineGroups?: Maybe<
                    Array<{ __typename?: 'DirSportDisciplineGroup' } & Pick<DirSportDisciplineGroup, 'name'>>
                  >;
                }
              >
            >;
          }
      >
    >;
    dirPerson: { __typename?: 'DirPerson' } & PersonFrFragment;
    sports?: Maybe<
      Array<
        { __typename?: 'DirAthleteSport' } & Pick<DirAthleteSport, 'id' | 'role'> & {
            archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
            dirSport: { __typename?: 'DirSport' } & { value: DirSport['id']; label: DirSport['fullName'] };
            ranksAndAwards?: Maybe<
              Array<
                { __typename?: 'DirAthleteSportRankAndAward' } & Pick<
                  DirAthleteSportRankAndAward,
                  'id' | 'assignmentDate' | 'confirmDate' | 'validUntil'
                > & {
                    clsRankAndAward: { __typename?: 'ClassifierValue' } & {
                      value: ClassifierValue['id'];
                      label: ClassifierValue['fullName'];
                    };
                  }
              >
            >;
            disciplines?: Maybe<
              Array<
                { __typename?: 'DirSportDiscipline' } & {
                  value: DirSportDiscipline['id'];
                  label: DirSportDiscipline['name'];
                }
              >
            >;
            organizations?: Maybe<
              Array<
                { __typename?: 'DirAthleteSportOrganization' } & Pick<
                  DirAthleteSportOrganization,
                  'id' | 'isSelfTraining' | 'trainingStart' | 'exclusionDate'
                > & {
                    dirOrganization: { __typename?: 'DirOrganization' } & {
                      value: DirOrganization['id'];
                      label: DirOrganization['name'];
                    };
                    dirTrainer?: Maybe<
                      { __typename?: 'DirTrainer' } & { value: DirTrainer['id'] } & {
                        dirPerson: { __typename?: 'DirPerson' } & Pick<
                          DirPerson,
                          'id' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'
                        > & { trainerProfile?: Maybe<{ __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'>> };
                        sports?: Maybe<
                          Array<
                            { __typename?: 'DirTrainerSport' } & {
                              organizations?: Maybe<
                                Array<
                                  { __typename?: 'DirTrainerSportOrganization' } & Pick<
                                    DirTrainerSportOrganization,
                                    'dismissalDate'
                                  > & {
                                      dirOrganization: { __typename?: 'DirOrganization' } & Pick<DirOrganization, 'id'>;
                                    }
                                >
                              >;
                            }
                          >
                        >;
                      }
                    >;
                    clsTrainingStage: { __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'shortName'> & {
                        value: ClassifierValue['id'];
                        label: ClassifierValue['shortName'];
                      };
                  }
              >
            >;
            dirTrainers?: Maybe<
              Array<
                { __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'> & {
                    dirPerson: { __typename?: 'DirPerson' } & Pick<
                      DirPerson,
                      'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday'
                    >;
                  }
              >
            >;
          }
      >
    >;
  };

export type AthleteListFrFragment = { __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'> & {
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
    dirPerson: { __typename?: 'DirPerson' } & PersonFrFragment;
    sports?: Maybe<
      Array<
        { __typename?: 'DirAthleteSport' } & Pick<DirAthleteSport, 'id'> & {
            archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
            dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id' | 'fullName'>;
            organizations?: Maybe<
              Array<
                { __typename?: 'DirAthleteSportOrganization' } & Pick<
                  DirAthleteSportOrganization,
                  'isSelfTraining' | 'trainingStart' | 'exclusionDate'
                > & {
                    dirOrganization: { __typename?: 'DirOrganization' } & {
                      value: DirOrganization['id'];
                      label: DirOrganization['name'];
                    };
                    dirTrainer?: Maybe<
                      { __typename?: 'DirTrainer' } & {
                        dirPerson: { __typename?: 'DirPerson' } & Pick<
                          DirPerson,
                          'id' | 'firstname' | 'patronymic' | 'lastname'
                        >;
                        sports?: Maybe<
                          Array<
                            { __typename?: 'DirTrainerSport' } & {
                              organizations?: Maybe<
                                Array<
                                  { __typename?: 'DirTrainerSportOrganization' } & Pick<
                                    DirTrainerSportOrganization,
                                    'dismissalDate'
                                  > & {
                                      dirOrganization: { __typename?: 'DirOrganization' } & Pick<DirOrganization, 'id'>;
                                    }
                                >
                              >;
                            }
                          >
                        >;
                      }
                    >;
                    clsTrainingStage: { __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'shortName'>;
                  }
              >
            >;
          }
      >
    >;
  };

export type AthletesQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirAthleteWhereInput>;
}>;

export type AthletesQuery = { __typename?: 'Query' } & { count: Query['dirAthletesCount'] } & {
  dirAthletes: Array<{ __typename?: 'DirAthlete' } & AthleteListFrFragment>;
};

export type AthletesCountQueryVariables = Exact<{
  filter?: Maybe<DirAthleteWhereInput>;
}>;

export type AthletesCountQuery = { __typename?: 'Query' } & { count: Query['dirAthletesCount'] };

export type AthleteQueryVariables = Exact<{
  filter: DirAthleteWhereUniqueInput;
}>;

export type AthleteQuery = { __typename?: 'Query' } & {
  dirAthlete?: Maybe<{ __typename?: 'DirAthlete' } & AthleteFrFragment>;
};

export type AthletesFilteredQueryVariables = Exact<{
  filter?: Maybe<DirAthleteWhereInput>;
}>;

export type AthletesFilteredQuery = { __typename?: 'Query' } & {
  dirAthletes: Array<{ __typename?: 'DirAthlete' } & AthleteFrFragment>;
};

export type AthleteCompetitionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type AthleteCompetitionQuery = { __typename?: 'Query' } & {
  dirAthlete?: Maybe<
    { __typename?: 'DirAthlete' } & { athleteId: DirAthlete['id'] } & {
      competetionResult?: Maybe<
        Array<
          { __typename?: 'DirAthleteCompetitionResult' } & Pick<
            DirAthleteCompetitionResult,
            'id' | 'result' | 'point'
          > & {
              sportingEventProgramType: { __typename?: 'DirSportingEventProgramType' } & Pick<
                DirSportingEventProgramType,
                'id' | 'start' | 'minAge' | 'maxAge'
              > & {
                  event: { __typename?: 'DirSportingEvent' } & Pick<
                    DirSportingEvent,
                    'id' | 'name' | 'venue' | 'startDate' | 'endDate'
                  > & {
                      clsEventCategories?: Maybe<
                        Array<
                          { __typename?: 'ClassifierValue' } & {
                            value: ClassifierValue['id'];
                            label: ClassifierValue['fullName'];
                          }
                        >
                      >;
                    };
                  clsAgeGroups?: Maybe<
                    Array<
                      { __typename?: 'ClassifierValue' } & {
                        value: ClassifierValue['id'];
                        label: ClassifierValue['fullName'];
                      }
                    >
                  >;
                  discipline: { __typename?: 'DirSportDiscipline' } & {
                    value: DirSportDiscipline['id'];
                    label: DirSportDiscipline['name'];
                  };
                };
            }
        >
      >;
    }
  >;
};

export type AthletesCountByTrainerIdInOrganizationQueryVariables = Exact<{
  id: Scalars['UUID'];
  orgID: Scalars['UUID'];
}>;

export type AthletesCountByTrainerIdInOrganizationQuery = { __typename?: 'Query' } & {
  count: Query['dirAthletesCount'];
};

export type CreateCalendarMutationVariables = Exact<{
  data: DirCalendarCreateInput;
}>;

export type CreateCalendarMutation = { __typename?: 'Mutation' } & {
  createDirCalendar: { __typename?: 'DirCalendar' } & CalendarFragment;
};

export type UpdateCalendarMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirCalendarUpdateInput;
}>;

export type UpdateCalendarMutation = { __typename?: 'Mutation' } & {
  updateDirCalendar?: Maybe<{ __typename?: 'DirCalendar' } & CalendarFragment>;
};

export type CalendarArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type CalendarArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirCalendar?: Maybe<
    { __typename?: 'DirCalendar' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type CalendarUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type CalendarUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirCalendar?: Maybe<
    { __typename?: 'DirCalendar' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type CalendarFragment = { __typename?: 'DirCalendar' } & Pick<
  DirCalendar,
  'id' | 'registryNumber' | 'fullName' | 'shortName' | 'startDate' | 'endDate'
> & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> };

export type CalendarsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirCalendarWhereInput>;
}>;

export type CalendarsQuery = { __typename?: 'Query' } & { count: Query['dirCalendarsCount'] } & {
  dirCalendars: Array<{ __typename?: 'DirCalendar' } & CalendarFragment>;
};

export type CalendarQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type CalendarQuery = { __typename?: 'Query' } & {
  dirCalendar?: Maybe<{ __typename?: 'DirCalendar' } & CalendarFragment>;
};

export type FindCalendarsQueryVariables = Exact<{
  search: Scalars['String'];
}>;

export type FindCalendarsQuery = { __typename?: 'Query' } & {
  dirCalendars: Array<
    { __typename?: 'DirCalendar' } & Pick<DirCalendar, 'shortName'> & {
        value: DirCalendar['id'];
        label: DirCalendar['fullName'];
      }
  >;
};

export type CreateClassifierMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateClassifierMutation = { __typename?: 'Mutation' } & {
  createClassifier: { __typename?: 'Classifier' } & ClassifierFragment;
};

export type UpdateClassifierMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;

export type UpdateClassifierMutation = { __typename?: 'Mutation' } & {
  updateClassifier?: Maybe<{ __typename?: 'Classifier' } & ClassifierFragment>;
};

export type CreateClassifierValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
}>;

export type CreateClassifierValueMutation = { __typename?: 'Mutation' } & {
  createClassifierValue: { __typename?: 'ClassifierValue' } & ClsValueFragment;
};

export type UpdateClassifierValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
}>;

export type UpdateClassifierValueMutation = { __typename?: 'Mutation' } & {
  updateClassifierValue?: Maybe<{ __typename?: 'ClassifierValue' } & ClsValueFragment>;
};

export type ClassifierArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type ClassifierArchiveMutation = { __typename?: 'Mutation' } & {
  updateClassifier?: Maybe<
    { __typename?: 'Classifier' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type ClassifierUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ClassifierUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateClassifier?: Maybe<
    { __typename?: 'Classifier' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type ClassifierValueArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type ClassifierValueArchiveMutation = { __typename?: 'Mutation' } & {
  updateClassifierValue?: Maybe<
    { __typename?: 'ClassifierValue' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type ClassifierValueUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ClassifierValueUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateClassifierValue?: Maybe<
    { __typename?: 'ClassifierValue' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type ClassifierFragment = { __typename?: 'Classifier' } & Pick<Classifier, 'id' | 'name' | 'registryNumber'> & {
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
  };

export type ClsValueFragment = { __typename?: 'ClassifierValue' } & Pick<
  ClassifierValue,
  'fullName' | 'shortName' | 'id' | 'order'
> & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> };

export type UniqueValidationClassifierValueQueryVariables = Exact<{
  fullName: Scalars['String'];
}>;

export type UniqueValidationClassifierValueQuery = { __typename?: 'Query' } & {
  classifierValues: Array<{ __typename?: 'ClassifierValue' } & ClsValueFragment>;
};

export type UniqueValidationClassifierQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type UniqueValidationClassifierQuery = { __typename?: 'Query' } & {
  classifiers: Array<{ __typename?: 'Classifier' } & ClassifierFragment>;
};

export type ClassifierByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ClassifierByIdQuery = { __typename?: 'Query' } & {
  classifier?: Maybe<
    { __typename?: 'Classifier' } & {
      values?: Maybe<Array<{ __typename?: 'ClassifierValue' } & ClsValueFragment>>;
    } & ClassifierFragment
  >;
};

export type ClassifiersQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<ClassifierWhereInput>;
}>;

export type ClassifiersQuery = { __typename?: 'Query' } & { count: Query['classifiersCount'] } & {
  classifiers: Array<{ __typename?: 'Classifier' } & ClassifierFragment>;
};

export type ClassifierValueFragment = { __typename?: 'ClassifierValue' } & {
  label: ClassifierValue['fullName'];
  value: ClassifierValue['id'];
};

export type ClsTrainingStagesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsTrainingStagesQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsAddressTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsAddressTypesQuery = { __typename?: 'Query' } & {
  addressTypes: Array<{ __typename?: 'AddressType' } & Pick<AddressType, 'id' | 'name'>>;
};

export type ClsAgeGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type ClsAgeGroupsQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsArchiveIssuesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsArchiveIssuesQuery = { __typename?: 'Query' } & {
  classifierValues: Array<{ __typename?: 'ClassifierValue' } & ClassifierValueFragment>;
};

export type ClsDepartmentAffiliationQueryVariables = Exact<{ [key: string]: never }>;

export type ClsDepartmentAffiliationQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsFamilyStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ClsFamilyStatusQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsGroupTypeSportsAndDisciplinesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsGroupTypeSportsAndDisciplinesQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsOrganizationCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsOrganizationCategoriesQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsPositionsQueryVariables = Exact<{ [key: string]: never }>;

export type ClsPositionsQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsRanksAndTitlesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsRanksAndTitlesQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsRefereeCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsRefereeCategoriesQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsRefereesPositionsQueryVariables = Exact<{
  filter?: Maybe<ClassifierValueWhereInput>;
}>;

export type ClsRefereesPositionsQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsSportingEventsCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ClsSportingEventsCategoriesQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type ClsStagePreparingQueryVariables = Exact<{ [key: string]: never }>;

export type ClsStagePreparingQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & {
      label: ClassifierValue['fullName'];
      shortLabel: ClassifierValue['shortName'];
      value: ClassifierValue['id'];
    }
  >;
};

export type ClsTypeContactQueryVariables = Exact<{ [key: string]: never }>;

export type ClsTypeContactQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadFileMutation = { __typename?: 'Mutation' } & {
  uploadFile?: Maybe<{ __typename?: 'File' } & Pick<File, 'id'>>;
};

export type CreateDocumentMutationVariables = Exact<{
  data: DirDocumentCreateInput;
}>;

export type CreateDocumentMutation = { __typename?: 'Mutation' } & {
  createDirDocument: { __typename?: 'DirDocument' } & Pick<DirDocument, 'id'>;
};

export type UpdateDocumentMutationVariables = Exact<{
  data: DirDocumentUpdateInput;
  id: Scalars['UUID'];
}>;

export type UpdateDocumentMutation = { __typename?: 'Mutation' } & {
  updateDirDocument?: Maybe<{ __typename?: 'DirDocument' } & Pick<DirDocument, 'id'>>;
};

export type DocumentArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type DocumentArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirDocument?: Maybe<
    { __typename?: 'DirDocument' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type DocumentUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type DocumentUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirDocument?: Maybe<
    { __typename?: 'DirDocument' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type DocumentFragment = { __typename?: 'DirDocument' } & Pick<
  DirDocument,
  'id' | 'date' | 'number' | 'title'
> & {
    file: { __typename?: 'File' } & Pick<File, 'id' | 'name' | 'path'>;
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
  };

export type DocumentsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirDocumentWhereInput>;
}>;

export type DocumentsQuery = { __typename?: 'Query' } & { count: Query['dirDocumentsCount'] } & {
  dirDocuments: Array<{ __typename?: 'DirDocument' } & DocumentFragment>;
};

export type DocumentsCountQueryVariables = Exact<{
  filter?: Maybe<DirDocumentWhereInput>;
}>;

export type DocumentsCountQuery = { __typename?: 'Query' } & { count: Query['dirDocumentsCount'] };

export type DocumentByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type DocumentByIdQuery = { __typename?: 'Query' } & {
  dirDocument?: Maybe<{ __typename?: 'DirDocument' } & DocumentFragment>;
};

export type FindDocumentsByTitleQueryVariables = Exact<{
  search: Scalars['String'];
  bannedId?: Maybe<Array<Scalars['UUID']>>;
}>;

export type FindDocumentsByTitleQuery = { __typename?: 'Query' } & {
  dirDocuments: Array<{ __typename?: 'DirDocument' } & { label: DirDocument['title']; value: DirDocument['id'] }>;
};

export type UpdateTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  idPerson: Scalars['UUID'];
}>;

export type UpdateTrainerMutation = { __typename?: 'Mutation' } & {
  updateDirTrainer?: Maybe<{ __typename?: 'DirTrainer' } & TrainerFrFragment>;
};

export type CreateTrainerMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
}>;

export type CreateTrainerMutation = { __typename?: 'Mutation' } & {
  createDirTrainer: { __typename?: 'DirTrainer' } & TrainerFrFragment;
};

export type AddTypeSportsToTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;

export type AddTypeSportsToTrainerMutation = { __typename?: 'Mutation' } & {
  updateDirTrainer?: Maybe<{ __typename?: 'DirTrainer' } & TrainerFrFragment>;
};

export type TypeSportTrainerArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type TypeSportTrainerArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirTrainerSport?: Maybe<
    { __typename?: 'DirTrainerSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type TypeSportTrainerUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportTrainerUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirTrainerSport?: Maybe<
    { __typename?: 'DirTrainerSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type CreateTrainerOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  clsPosition: Scalars['UUID'];
  idDirOrganization: Scalars['UUID'];
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
}>;

export type CreateTrainerOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirTrainerSport?: Maybe<{ __typename?: 'DirTrainerSport' } & Pick<DirTrainerSport, 'id'>>;
};

export type UpdateTrainerOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  clsPosition: Scalars['UUID'];
  idDirOrganization: Scalars['UUID'];
  employmentDate: Scalars['DateTime'];
  dismissalDate?: Maybe<Scalars['DateTime']>;
}>;

export type UpdateTrainerOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirTrainerSportOrganization?: Maybe<
    { __typename?: 'DirTrainerSportOrganization' } & Pick<DirTrainerSportOrganization, 'id'>
  >;
};

export type FireTrainerWithAthleteSelfTrainMutationVariables = Exact<{
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;

export type FireTrainerWithAthleteSelfTrainMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'trainerDismissalWithAthletesSelfTraining'
>;

export type FireTrainerWithAthleteExpelMutationVariables = Exact<{
  trainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;

export type FireTrainerWithAthleteExpelMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'trainerDismissalWithAthletesExpel'
>;

export type FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables = Exact<{
  fromTrainerID: Scalars['UUID'];
  toTrainerID: Scalars['UUID'];
  organizationID: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;

export type FireTrainerWithAthleteAssignToAnotherTrainerMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'trainerDismissalWithAthletesAssignToAnotherTrainer'
>;

export type FireTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  dismissalDate: Scalars['DateTime'];
}>;

export type FireTrainerMutation = { __typename?: 'Mutation' } & {
  updateDirTrainerSportOrganization?: Maybe<
    { __typename?: 'DirTrainerSportOrganization' } & Pick<DirTrainerSportOrganization, 'id'>
  >;
};

export type FederalDistrictFragment = { __typename?: 'DirFederalDistrict' } & Pick<
  DirFederalDistrict,
  'id' | 'fullName' | 'shortName'
> & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> };

export type ForeignCityByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ForeignCityByIdQuery = { __typename?: 'Query' } & {
  dirForeignCity?: Maybe<{ __typename?: 'DirForeignCity' } & ForeignCityFragment>;
};

export type FederalDistrictsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;

export type FederalDistrictsQuery = { __typename?: 'Query' } & { count: Query['dirFederalDistrictsCount'] } & {
  dirFederalDistricts: Array<{ __typename?: 'DirFederalDistrict' } & FederalDistrictFragment>;
};

export type ForeignCityArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type ForeignCityArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirForeignCity?: Maybe<
    { __typename?: 'DirForeignCity' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type ForeignCityUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ForeignCityUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirForeignCity?: Maybe<
    { __typename?: 'DirForeignCity' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type ForeignCityUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  countryId?: Maybe<DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput>;
}>;

export type ForeignCityUpdateMutation = { __typename?: 'Mutation' } & {
  updateDirForeignCity?: Maybe<{ __typename?: 'DirForeignCity' } & ForeignCityFragment>;
};

export type ForeignCityCreateMutationVariables = Exact<{
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  countryId: DirCountryCreateOneWithoutDirForeignCitiesInput;
}>;

export type ForeignCityCreateMutation = { __typename?: 'Mutation' } & {
  createDirForeignCity: { __typename?: 'DirForeignCity' } & ForeignCityFragment;
};

export type ForeignCityFragment = { __typename?: 'DirForeignCity' } & Pick<
  DirForeignCity,
  'id' | 'fullName' | 'shortName'
> & {
    dirCountry: { __typename?: 'DirCountry' } & { value: DirCountry['id']; label: DirCountry['fullName'] };
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
  };

export type ForeignCitiesQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirForeignCityWhereInput>;
}>;

export type ForeignCitiesQuery = { __typename?: 'Query' } & { count: Query['dirForeignCitiesCount'] } & {
  dirForeignCities: Array<{ __typename?: 'DirForeignCity' } & ForeignCityFragment>;
};

export type ForeignCitiesListQueryVariables = Exact<{
  filter?: Maybe<DirForeignCityWhereInput>;
}>;

export type ForeignCitiesListQuery = { __typename?: 'Query' } & {
  dirForeignCities: Array<
    { __typename?: 'DirForeignCity' } & { value: DirForeignCity['id']; label: DirForeignCity['fullName'] }
  >;
};

export type CountriesFetchQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesFetchQuery = { __typename?: 'Query' } & {
  dirCountries: Array<{ __typename?: 'DirCountry' } & { value: DirCountry['id']; label: DirCountry['fullName'] }>;
};

export type FederalDistrictsFetchQueryVariables = Exact<{ [key: string]: never }>;

export type FederalDistrictsFetchQuery = { __typename?: 'Query' } & {
  dirFederalDistricts: Array<
    { __typename?: 'DirFederalDistrict' } & { value: DirFederalDistrict['id']; label: DirFederalDistrict['fullName'] }
  >;
};

export type UserRoleQueryVariables = Exact<{ [key: string]: never }>;

export type UserRoleQuery = { __typename?: 'Query' } & {
  userRoles: Array<
    { __typename?: 'UserRole' } & Pick<UserRole, 'name'> & { value: UserRole['id']; label: UserRole['description'] }
  >;
};

export type ValidationTokenQueryVariables = Exact<{ [key: string]: never }>;

export type ValidationTokenQuery = { __typename?: 'Query' } & {
  classifierValues: Array<
    { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
  >;
};

export type UpdateNationalTeamMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirNationalTeamUpdateInput;
}>;

export type UpdateNationalTeamMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type NationalTeamArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<
    { __typename?: 'DirNationalTeam' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type NationalTeamUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type NationalTeamUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<
    { __typename?: 'DirNationalTeam' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type NationalTeamAddTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  trainerId: Scalars['UUID'];
}>;

export type NationalTeamAddTrainerMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamDeleteTrainerMutationVariables = Exact<{
  id: Scalars['UUID'];
  trainerId: Scalars['UUID'];
}>;

export type NationalTeamDeleteTrainerMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamAddSpecialistMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
}>;

export type NationalTeamAddSpecialistMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamDeleteSpecialistMutationVariables = Exact<{
  id: Scalars['UUID'];
  specId: Scalars['UUID'];
}>;

export type NationalTeamDeleteSpecialistMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamAddMedicMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic: Scalars['String'];
  birthday: Scalars['DateTime'];
}>;

export type NationalTeamAddMedicMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamDeleteMedicMutationVariables = Exact<{
  id: Scalars['UUID'];
  medId: Scalars['UUID'];
}>;

export type NationalTeamDeleteMedicMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamAddAthleteGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  ageGroupsId?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disciplinesId?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
}>;

export type NationalTeamAddAthleteGroupMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamUpdateAthleteGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  minAge?: Maybe<Scalars['Int']>;
  maxAge?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['UUID']>;
  ageGroupsId?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  disciplinesId?: Maybe<Array<DirSportDisciplineWhereUniqueInput>>;
}>;

export type NationalTeamUpdateAthleteGroupMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamDeleteAthleteGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  groupId?: Maybe<Scalars['UUID']>;
}>;

export type NationalTeamDeleteAthleteGroupMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamAddAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  groupId?: Maybe<Scalars['UUID']>;
  athleteId?: Maybe<Scalars['UUID']>;
  bestResult?: Maybe<Scalars['String']>;
}>;

export type NationalTeamAddAthleteMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamDeleteAthleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  groupId?: Maybe<Scalars['UUID']>;
  athleteId?: Maybe<Scalars['UUID']>;
}>;

export type NationalTeamDeleteAthleteMutation = { __typename?: 'Mutation' } & {
  updateDirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamFragment = { __typename?: 'DirNationalTeam' } & Pick<
  DirNationalTeam,
  'id' | 'registryNumber' | 'comment' | 'supportTeamQuota' | 'manTeamQuota' | 'womanTeamQuota' | 'score'
> & {
    trainers?: Maybe<
      Array<
        { __typename?: 'DirNationalTeamTrainer' } & Pick<DirNationalTeamTrainer, 'id'> & {
            dirTrainer: { __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'> & {
                dirPerson: { __typename?: 'DirPerson' } & Pick<
                  DirPerson,
                  'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'
                >;
              };
          }
      >
    >;
    specialists?: Maybe<
      Array<
        { __typename?: 'DirNationalTeamMember' } & Pick<
          DirNationalTeamMember,
          'id' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'
        >
      >
    >;
    medics?: Maybe<
      Array<
        { __typename?: 'DirNationalTeamMember' } & Pick<
          DirNationalTeamMember,
          'id' | 'firstname' | 'lastname' | 'patronymic' | 'birthday'
        >
      >
    >;
    athleteGroups?: Maybe<
      Array<
        { __typename?: 'DirNationalTeamAthleteGroup' } & Pick<
          DirNationalTeamAthleteGroup,
          'id' | 'minAge' | 'maxAge'
        > & {
            clsAgeGroups?: Maybe<Array<{ __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'id' | 'fullName'>>>;
            disciplines?: Maybe<
              Array<
                { __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'id' | 'name'> & {
                    dirSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id' | 'fullName'>>;
                  }
              >
            >;
            disciplineGroups?: Maybe<
              Array<{ __typename?: 'DirSportDisciplineGroup' } & Pick<DirSportDisciplineGroup, 'id' | 'name'>>
            >;
            athletes?: Maybe<
              Array<
                { __typename?: 'DirNationalTeamAthlete' } & Pick<DirNationalTeamAthlete, 'id' | 'bestResult'> & {
                    dirAthlete: { __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'> & {
                        dirPerson: { __typename?: 'DirPerson' } & Pick<
                          DirPerson,
                          'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday' | 'isMale'
                        >;
                        sports?: Maybe<
                          Array<
                            { __typename?: 'DirAthleteSport' } & Pick<DirAthleteSport, 'id'> & {
                                disciplines?: Maybe<
                                  Array<{ __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'id'>>
                                >;
                                dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id'>;
                                organizations?: Maybe<
                                  Array<
                                    { __typename?: 'DirAthleteSportOrganization' } & Pick<
                                      DirAthleteSportOrganization,
                                      'id'
                                    > & {
                                        dirOrganization: { __typename?: 'DirOrganization' } & Pick<
                                          DirOrganization,
                                          'id' | 'fullName'
                                        >;
                                      }
                                  >
                                >;
                                ranksAndAwards?: Maybe<
                                  Array<
                                    { __typename?: 'DirAthleteSportRankAndAward' } & Pick<
                                      DirAthleteSportRankAndAward,
                                      'id' | 'validUntil'
                                    > & {
                                        clsRankAndAward: { __typename?: 'ClassifierValue' } & Pick<
                                          ClassifierValue,
                                          'id' | 'shortName' | 'fullName'
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                        competetionResult?: Maybe<
                          Array<
                            { __typename?: 'DirAthleteCompetitionResult' } & Pick<
                              DirAthleteCompetitionResult,
                              'id' | 'point' | 'result'
                            > & {
                                sportingEventProgramType: { __typename?: 'DirSportingEventProgramType' } & Pick<
                                  DirSportingEventProgramType,
                                  'id' | 'start' | 'minAge' | 'maxAge'
                                > & {
                                    discipline: { __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'id'>;
                                  };
                              }
                          >
                        >;
                      };
                  }
              >
            >;
          }
      >
    >;
    dirRegion: { __typename?: 'DirRegion' } & Pick<DirRegion, 'id' | 'fullName'>;
    status: { __typename?: 'DirNationalTeamStatus' } & Pick<DirNationalTeamStatus, 'id' | 'name'>;
    statusHistory?: Maybe<
      Array<
        { __typename?: 'DirNationalTeamStatusHistory' } & Pick<DirNationalTeamStatusHistory, 'id' | 'date'> & {
            status: { __typename?: 'DirNationalTeamStatus' } & Pick<DirNationalTeamStatus, 'name'>;
            user: { __typename?: 'User' } & Pick<User, 'name' | 'email'>;
          }
      >
    >;
    dirSportingEvent: { __typename?: 'DirSportingEvent' } & Pick<
      DirSportingEvent,
      'id' | 'startDate' | 'endDate' | 'name' | 'registryNumber'
    > & {
        sports?: Maybe<
          Array<
            { __typename?: 'DirSportingEventSport' } & Pick<DirSportingEventSport, 'id'> & {
                dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id' | 'fullName'>;
                disciplines?: Maybe<
                  Array<{ __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'id' | 'name'>>
                >;
                disciplineGroups?: Maybe<
                  Array<{ __typename?: 'DirSportDisciplineGroup' } & Pick<DirSportDisciplineGroup, 'id' | 'name'>>
                >;
              }
          >
        >;
        dirCalendar: { __typename?: 'DirCalendar' } & Pick<DirCalendar, 'id' | 'fullName'>;
      };
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
  };

export type AthletesInTeamQueryVariables = Exact<{
  eventId: Scalars['UUID'];
  deniedAthletes?: Maybe<Array<Scalars['UUID']>>;
  search?: Maybe<Scalars['String']>;
}>;

export type AthletesInTeamQuery = { __typename?: 'Query' } & {
  dirNationalTeams: Array<
    { __typename?: 'DirNationalTeam' } & { teamId: DirNationalTeam['id'] } & {
      athleteGroups?: Maybe<
        Array<
          { __typename?: 'DirNationalTeamAthleteGroup' } & Pick<DirNationalTeamAthleteGroup, 'id'> & {
              athletes?: Maybe<
                Array<
                  { __typename?: 'DirNationalTeamAthlete' } & Pick<DirNationalTeamAthlete, 'id'> & {
                      dirAthlete: { __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'> & {
                          person: { __typename?: 'DirPerson' } & Pick<
                            DirPerson,
                            'id' | 'firstname' | 'lastname' | 'patronymic' | 'registryNumber' | 'birthday'
                          >;
                        };
                    }
                >
              >;
            }
        >
      >;
    }
  >;
};

export type NationalTeamsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirNationalTeamWhereInput>;
}>;

export type NationalTeamsQuery = { __typename?: 'Query' } & { count: Query['dirNationalTeamsCount'] } & {
  dirNationalTeams: Array<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type NationalTeamsCountQueryVariables = Exact<{
  filter?: Maybe<DirNationalTeamWhereInput>;
}>;

export type NationalTeamsCountQuery = { __typename?: 'Query' } & { count: Query['dirNationalTeamsCount'] };

export type NationalTeamQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type NationalTeamQuery = { __typename?: 'Query' } & {
  dirNationalTeam?: Maybe<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>;
};

export type CreateOrganizationMutationVariables = Exact<{
  data: DirOrganizationCreateInput;
}>;

export type CreateOrganizationMutation = { __typename?: 'Mutation' } & {
  createDirOrganization: { __typename?: 'DirOrganization' } & OrganizationFrFragment;
};

export type SimpleUpdateOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirOrganizationUpdateInput;
}>;

export type SimpleUpdateOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type AddEmailToOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
  type: Scalars['UUID'];
}>;

export type AddEmailToOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type UpdateOrganizationEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
  type: Scalars['UUID'];
  emailId: Scalars['UUID'];
}>;

export type UpdateOrganizationEmailMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type DeleteEmailFromOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  emailId: Scalars['UUID'];
}>;

export type DeleteEmailFromOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type UpdateOrganizationSitesMutationVariables = Exact<{
  id: Scalars['UUID'];
  sites?: Maybe<Array<Scalars['String']>>;
}>;

export type UpdateOrganizationSitesMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type AddAddressToOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  type: Scalars['Int'];
}>;

export type AddAddressToOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type UpdateOrganizationAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  place: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  type: Scalars['Int'];
  addressId: Scalars['UUID'];
}>;

export type UpdateOrganizationAddressMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type DeleteAddressFromOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  addressId: Scalars['UUID'];
}>;

export type DeleteAddressFromOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type AddPhoneToOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber: Scalars['String'];
  type: Scalars['UUID'];
}>;

export type AddPhoneToOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type UpdateOrganizationPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  countryCode: Scalars['String'];
  operatorCode: Scalars['String'];
  number: Scalars['String'];
  additionalNumber: Scalars['String'];
  type: Scalars['UUID'];
  phoneId: Scalars['UUID'];
}>;

export type UpdateOrganizationPhoneMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type DeletePhoneFromOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  phoneId: Scalars['UUID'];
}>;

export type DeletePhoneFromOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirOrganizationUpdateInput;
}>;

export type UpdateOrganizationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type UpdateOrganizationLegalInfoMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['DateTime']>;
  inn?: Maybe<Scalars['String']>;
  ogrn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  isLegacy: Scalars['Boolean'];
  legalAddress?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  headFullName?: Maybe<Scalars['String']>;
  headPosition?: Maybe<Scalars['String']>;
}>;

export type UpdateOrganizationLegalInfoMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationLegalInfoFrFragment>;
};

export type OrganizationRemoveTypeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  idSport: Scalars['UUID'];
}>;

export type OrganizationRemoveTypeSportMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationTypeSportsFrFragment>;
};

export type OrganizationCreateTypeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  idSport: Scalars['UUID'];
}>;

export type OrganizationCreateTypeSportMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationTypeSportsFrFragment>;
};

export type OrganizationRemoveDeparmentAffiliationMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type OrganizationRemoveDeparmentAffiliationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationDepartmentAffiliationFrFragment>;
};

export type OrganizationCreateDeparmentAffiliationMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type OrganizationCreateDeparmentAffiliationMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationDepartmentAffiliationFrFragment>;
};

export type UpdateOrganizationParentMutationVariables = Exact<{
  id: Scalars['UUID'];
  parentId: Scalars['UUID'];
}>;

export type UpdateOrganizationParentMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationDepartmentAffiliationFrFragment>;
};

export type AddOrganizationChildrenMutationVariables = Exact<{
  id: Scalars['UUID'];
  childID: Scalars['UUID'];
}>;

export type AddOrganizationChildrenMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationDepartmentAffiliationFrFragment>;
};

export type RemoveOrganizationParentMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RemoveOrganizationParentMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationDepartmentAffiliationFrFragment>;
};

export type RemoveOrganizationChildrenMutationVariables = Exact<{
  id: Scalars['UUID'];
  childID: Scalars['UUID'];
}>;

export type RemoveOrganizationChildrenMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationDepartmentAffiliationFrFragment>;
};

export type OrganizationArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type OrganizationArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<
    { __typename?: 'DirOrganization' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type OrganizationUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type OrganizationUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirOrganization?: Maybe<
    { __typename?: 'DirOrganization' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type OrganizationFrFragment = { __typename?: 'DirOrganization' } & Pick<
  DirOrganization,
  'id' | 'registryNumber' | 'name' | 'ogrn' | 'isLegalEntity' | 'websites'
> & {
    dirRegion?: Maybe<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
    clsOrganizationCategories?: Maybe<
      Array<{ __typename?: 'ClassifierValue' } & { value: ClassifierValue['id']; label: ClassifierValue['fullName'] }>
    >;
    addresses?: Maybe<
      Array<
        { __typename?: 'DirOrganizationAddress' } & Pick<
          DirOrganizationAddress,
          'id' | 'place' | 'street' | 'house' | 'number'
        > & { type: { __typename?: 'AddressType' } & Pick<AddressType, 'id' | 'name'> }
      >
    >;
    phones?: Maybe<
      Array<
        { __typename?: 'DirOrganizationPhone' } & Pick<
          DirOrganizationPhone,
          'id' | 'number' | 'additionalNumber' | 'operatorCode' | 'countryCode'
        > & { clsContactType: { __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'id' | 'fullName'> }
      >
    >;
    emails?: Maybe<
      Array<
        { __typename?: 'DirOrganizationEmail' } & Pick<DirOrganizationEmail, 'id' | 'email'> & {
            clsContactType: { __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'id' | 'fullName'>;
          }
      >
    >;
    parent?: Maybe<{ __typename?: 'DirOrganization' } & Pick<DirOrganization, 'id' | 'ogrn' | 'name'>>;
    children?: Maybe<Array<{ __typename?: 'DirOrganization' } & Pick<DirOrganization, 'id' | 'ogrn' | 'name'>>>;
  };

export type OrganizationLegalInfoFrFragment = { __typename?: 'DirOrganization' } & Pick<
  DirOrganization,
  | 'id'
  | 'name'
  | 'ogrn'
  | 'isLegalEntity'
  | 'fullName'
  | 'shortName'
  | 'registrationDate'
  | 'inn'
  | 'kpp'
  | 'legalAddress'
  | 'postcode'
  | 'headFullName'
  | 'headPosition'
>;

export type OrganizationTypeSportsFrFragment = { __typename?: 'DirOrganization' } & Pick<DirOrganization, 'id'> & {
    dirSports?: Maybe<Array<{ __typename?: 'DirSport' } & { value: DirSport['id']; label: DirSport['fullName'] }>>;
  };

export type OrganizationDepartmentAffiliationFrFragment = { __typename?: 'DirOrganization' } & Pick<
  DirOrganization,
  'id'
> & {
    clsDepartmentalAffiliations?: Maybe<
      Array<{ __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }>
    >;
  };

export type OrganizationsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirOrganizationWhereInput>;
}>;

export type OrganizationsQuery = { __typename?: 'Query' } & { count: Query['dirOrganizationsCount'] } & {
  dirOrganizations: Array<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type OrganizationsCountQueryVariables = Exact<{
  filter?: Maybe<DirOrganizationWhereInput>;
}>;

export type OrganizationsCountQuery = { __typename?: 'Query' } & { count: Query['dirOrganizationsCount'] };

export type OrganizationByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type OrganizationByIdQuery = { __typename?: 'Query' } & {
  dirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationFrFragment>;
};

export type OrganizationLegalInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type OrganizationLegalInfoQuery = { __typename?: 'Query' } & {
  dirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationLegalInfoFrFragment>;
};

export type OrganizationTypeSportsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type OrganizationTypeSportsQuery = { __typename?: 'Query' } & {
  dirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationTypeSportsFrFragment>;
};

export type OrganizationDepartmentAffiliationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type OrganizationDepartmentAffiliationQuery = { __typename?: 'Query' } & {
  dirOrganization?: Maybe<{ __typename?: 'DirOrganization' } & OrganizationDepartmentAffiliationFrFragment>;
};

export type FindOrganizationsQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirOrganizationWhereInput>;
}>;

export type FindOrganizationsQuery = { __typename?: 'Query' } & {
  data: Array<
    { __typename?: 'SimilarOrganization' } & {
      organization: { __typename?: 'DirOrganization' } & {
        value: DirOrganization['id'];
        label: DirOrganization['name'];
      };
    }
  >;
};

export type SuggestOrganizationsQueryVariables = Exact<{
  ogrn: Scalars['String'];
}>;

export type SuggestOrganizationsQuery = { __typename?: 'Query' } & {
  suggestOrganizations: Array<
    { __typename?: 'OrganizationSearchResultType' } & Pick<
      OrganizationSearchResultType,
      | 'fullName'
      | 'shortName'
      | 'registrationDate'
      | 'inn'
      | 'kpp'
      | 'legalAddress'
      | 'postcode'
      | 'headPosition'
      | 'headFullName'
    >
  >;
};

export type OrganizationsTrainersBySportQueryVariables = Exact<{
  sportID?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
}>;

export type OrganizationsTrainersBySportQuery = { __typename?: 'Query' } & {
  dirTrainers: Array<
    { __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'> & {
        dirPerson: { __typename?: 'DirPerson' } & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic'>;
        sports?: Maybe<
          Array<
            { __typename?: 'DirTrainerSport' } & {
              dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
                  clsTrainingStages?: Maybe<
                    Array<{ __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'id' | 'fullName'>>
                  >;
                };
            }
          >
        >;
      }
  >;
};

export type OrganizationsAthletesBySportQueryVariables = Exact<{
  sportID?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
}>;

export type OrganizationsAthletesBySportQuery = { __typename?: 'Query' } & {
  dirAthletes: Array<
    { __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'> & {
        dirPerson: { __typename?: 'DirPerson' } & Pick<DirPerson, 'firstname' | 'lastname' | 'patronymic'>;
        sports?: Maybe<
          Array<
            { __typename?: 'DirAthleteSport' } & {
              dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id'>;
              organizations?: Maybe<
                Array<
                  { __typename?: 'DirAthleteSportOrganization' } & Pick<
                    DirAthleteSportOrganization,
                    'isSelfTraining'
                  > & {
                      dirTrainer?: Maybe<{ __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'>>;
                      clsTrainingStage: { __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'id' | 'fullName'>;
                    }
                >
              >;
            }
          >
        >;
      }
  >;
};

export type RemovePersonCitizenshipMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type RemovePersonCitizenshipMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type CreatePersonCitizenshipMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type CreatePersonCitizenshipMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  regionID?: Maybe<DirRegionUpdateOneInput>;
  countryID?: Maybe<DirCountryUpdateOneInput>;
  foreignCityID?: Maybe<DirForeignCityUpdateOneInput>;
  isRussia?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  confirmStatus?: Maybe<DirPersonConfirmStatusUpdateOneRequiredInput>;
}>;

export type UpdatePersonMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type CreatePersonMutationVariables = Exact<{
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  previousLastname?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isMale: Scalars['Boolean'];
  regionID?: Maybe<DirRegionCreateOneInput>;
  countryID?: Maybe<DirCountryCreateOneInput>;
  foreignCityID?: Maybe<DirForeignCityCreateOneInput>;
  confirmStatus: DirPersonConfirmStatusCreateOneInput;
  email?: Maybe<Scalars['String']>;
}>;

export type CreatePersonMutation = { __typename?: 'Mutation' } & {
  createDirPerson: { __typename?: 'DirPerson' } & PersonFrFragment;
};

export type UpdatePersonMartialStatusMutationVariables = Exact<{
  id: Scalars['UUID'];
  clsMaritalStatus?: Maybe<ClassifierValueUpdateOneInput>;
}>;

export type UpdatePersonMartialStatusMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type RemovePersonPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  date?: Maybe<Scalars['DateTime']>;
}>;

export type RemovePersonPhoneMutation = { __typename?: 'Mutation' } & {
  updateDirPersonPhone?: Maybe<{ __typename?: 'DirPersonPhone' } & Pick<DirPersonPhone, 'id' | 'deletedAt'>>;
};

export type AddPersonPhoneMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
  operatorCode: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: Scalars['UUID'];
}>;

export type AddPersonPhoneMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type UpdatePersonPhoneMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
  id: Scalars['UUID'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
  operatorCode: Scalars['String'];
  additionalNumber?: Maybe<Scalars['String']>;
  clsContactType: Scalars['UUID'];
}>;

export type UpdatePersonPhoneMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type PersonArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type PersonArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<
    { __typename?: 'DirPerson' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type PersonUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type PersonUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirPerson?: Maybe<
    { __typename?: 'DirPerson' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type CountryDirValueFragment = { __typename?: 'DirCountry' } & {
  value: DirCountry['id'];
  label: DirCountry['fullName'];
};

export type RegionDirValueFragment = { __typename?: 'DirRegion' } & {
  value: DirRegion['id'];
  label: DirRegion['fullName'];
};

export type ForeignCityDirValueFragment = { __typename?: 'DirForeignCity' } & {
  value: DirForeignCity['id'];
  label: DirForeignCity['fullName'];
};

export type CitizenshipDirValueFragment = { __typename?: 'DirCountry' } & {
  value: DirCountry['id'];
  label: DirCountry['fullName'];
};

export type PhoneFragment = { __typename?: 'DirPersonPhone' } & Pick<
  DirPersonPhone,
  'id' | 'number' | 'deletedAt' | 'countryCode' | 'operatorCode' | 'additionalNumber'
> & {
    clsContactType: { __typename?: 'ClassifierValue' } & {
      value: ClassifierValue['id'];
      label: ClassifierValue['fullName'];
    };
  };

export type PersonFrFragment = { __typename?: 'DirPerson' } & Pick<
  DirPerson,
  | 'id'
  | 'registryNumber'
  | 'firstname'
  | 'lastname'
  | 'isMale'
  | 'previousLastname'
  | 'patronymic'
  | 'email'
  | 'birthday'
  | 'isRussia'
> & {
    clsMaritalStatus?: Maybe<
      { __typename?: 'ClassifierValue' } & { value: ClassifierValue['id']; label: ClassifierValue['fullName'] }
    >;
    primary?: Maybe<
      { __typename?: 'DirPerson' } & Pick<DirPerson, 'registryNumber' | 'firstname' | 'lastname' | 'patronymic'>
    >;
    duplicates?: Maybe<
      Array<{ __typename?: 'DirPerson' } & Pick<DirPerson, 'registryNumber' | 'firstname' | 'lastname' | 'patronymic'>>
    >;
    confirmStatus: { __typename?: 'DirPersonConfirmStatus' } & {
      value: DirPersonConfirmStatus['id'];
      label: DirPersonConfirmStatus['name'];
    };
    dirCountry?: Maybe<{ __typename?: 'DirCountry' } & CountryDirValueFragment>;
    dirForeignCity?: Maybe<{ __typename?: 'DirForeignCity' } & ForeignCityDirValueFragment>;
    dirRegion?: Maybe<{ __typename?: 'DirRegion' } & RegionDirValueFragment>;
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
    dirCitizenships?: Maybe<Array<{ __typename?: 'DirCountry' } & CitizenshipDirValueFragment>>;
    athleteProfile?: Maybe<{ __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'>>;
    trainerProfile?: Maybe<{ __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'>>;
    refereeProfile?: Maybe<{ __typename?: 'DirReferee' } & Pick<DirReferee, 'id'>>;
    phones?: Maybe<Array<{ __typename?: 'DirPersonPhone' } & PhoneFragment>>;
  };

export type FindSimiliarPersonQueryVariables = Exact<{
  search: Scalars['String'];
  filter?: Maybe<DirPersonWhereInput>;
}>;

export type FindSimiliarPersonQuery = { __typename?: 'Query' } & {
  dirPersonsSimilar: Array<
    { __typename?: 'SimilarPerson' } & Pick<SimilarPerson, 'similarity'> & {
        person: { __typename?: 'DirPerson' } & PersonFrFragment;
      }
  >;
};

export type CheckExistEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type CheckExistEmailQuery = { __typename?: 'Query' } & {
  dirPersons: Array<{ __typename?: 'DirPerson' } & Pick<DirPerson, 'id' | 'email'>>;
};

export type PersonsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirPersonWhereInput>;
}>;

export type PersonsQuery = { __typename?: 'Query' } & { count: Query['dirPersonsCount'] } & {
  dirPersons: Array<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type PersonByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type PersonByIdQuery = { __typename?: 'Query' } & {
  dirPerson?: Maybe<{ __typename?: 'DirPerson' } & PersonFrFragment>;
};

export type UpdateRefereeMutationVariables = Exact<{
  id: Scalars['UUID'];
  idPerson: Scalars['UUID'];
}>;

export type UpdateRefereeMutation = { __typename?: 'Mutation' } & {
  updateDirReferee?: Maybe<{ __typename?: 'DirReferee' } & Pick<DirReferee, 'id'>>;
};

export type CreateRefereeMutationVariables = Exact<{
  idPerson: Scalars['UUID'];
}>;

export type CreateRefereeMutation = { __typename?: 'Mutation' } & {
  createDirReferee: { __typename?: 'DirReferee' } & Pick<DirReferee, 'id'>;
};

export type UpdateRefereeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirRefereeSportUpdateInput;
}>;

export type UpdateRefereeSportMutation = { __typename?: 'Mutation' } & {
  updateDirRefereeSport?: Maybe<{ __typename?: 'DirRefereeSport' } & Pick<DirRefereeSport, 'id'>>;
};

export type CreateRefereeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
  sportId: Scalars['UUID'];
}>;

export type CreateRefereeSportMutation = { __typename?: 'Mutation' } & {
  updateDirReferee?: Maybe<{ __typename?: 'DirReferee' } & Pick<DirReferee, 'id'>>;
};

export type RefereeSportArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type RefereeSportArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirRefereeSport?: Maybe<
    { __typename?: 'DirRefereeSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type RefereeSportUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RefereeSportUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirRefereeSport?: Maybe<
    { __typename?: 'DirRefereeSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type RefereeFragment = { __typename?: 'DirReferee' } & Pick<DirReferee, 'id'> & {
    dirPerson: { __typename?: 'DirPerson' } & Pick<
      DirPerson,
      'id' | 'registryNumber' | 'firstname' | 'lastname' | 'patronymic' | 'birthday' | 'isMale' | 'isRussia'
    > & {
        dirRegion?: Maybe<{ __typename?: 'DirRegion' } & Pick<DirRegion, 'id' | 'fullName'>>;
        dirForeignCity?: Maybe<{ __typename?: 'DirForeignCity' } & Pick<DirForeignCity, 'id' | 'fullName'>>;
        dirCountry?: Maybe<{ __typename?: 'DirCountry' } & Pick<DirCountry, 'id' | 'fullName'>>;
      };
    sports?: Maybe<
      Array<
        { __typename?: 'DirRefereeSport' } & Pick<DirRefereeSport, 'id' | 'workExperience'> & {
            categories?: Maybe<
              Array<
                { __typename?: 'DirRefereeSportCategory' } & Pick<
                  DirRefereeSportCategory,
                  'id' | 'validUntil' | 'isEndless' | 'assignmentDate'
                > & {
                    clsRefereeCategory: { __typename?: 'ClassifierValue' } & {
                      value: ClassifierValue['id'];
                      label: ClassifierValue['fullName'];
                    };
                    dirDocument?: Maybe<
                      { __typename?: 'DirDocument' } & Pick<DirDocument, 'id'> & {
                          file: { __typename?: 'File' } & Pick<File, 'id' | 'path'>;
                        }
                    >;
                  }
              >
            >;
            dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id' | 'fullName'>;
            archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
          }
      >
    >;
  };

export type RefereesQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirRefereeWhereInput>;
}>;

export type RefereesQuery = { __typename?: 'Query' } & { count: Query['dirRefereesCount'] } & {
  dirReferees: Array<{ __typename?: 'DirReferee' } & RefereeFragment>;
};

export type RefereesCountQueryVariables = Exact<{
  filter?: Maybe<DirRefereeWhereInput>;
}>;

export type RefereesCountQuery = { __typename?: 'Query' } & { count: Query['dirRefereesCount'] };

export type RefereeByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RefereeByIdQuery = { __typename?: 'Query' } & {
  dirReferee?: Maybe<{ __typename?: 'DirReferee' } & RefereeFragment>;
};

export type RegionArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type RegionArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirRegion?: Maybe<
    { __typename?: 'DirRegion' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type RegionUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RegionUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirRegion?: Maybe<
    { __typename?: 'DirRegion' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type RegionUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  regionId: DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput;
}>;

export type RegionUpdateMutation = { __typename?: 'Mutation' } & {
  updateDirRegion?: Maybe<{ __typename?: 'DirRegion' } & RegionFragment>;
};

export type RegionCreateMutationVariables = Exact<{
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  code: Scalars['String'];
  regionId: DirFederalDistrictCreateOneWithoutDirRegionsInput;
}>;

export type RegionCreateMutation = { __typename?: 'Mutation' } & {
  createDirRegion: { __typename?: 'DirRegion' } & RegionFragment;
};

export type RegionFragment = { __typename?: 'DirRegion' } & Pick<
  DirRegion,
  'id' | 'fullName' | 'shortName' | 'code'
> & {
    dirFederalDistrict: { __typename?: 'DirFederalDistrict' } & {
      value: DirFederalDistrict['id'];
      label: DirFederalDistrict['fullName'];
    };
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
  };

export type RegionByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RegionByIdQuery = { __typename?: 'Query' } & {
  dirRegion?: Maybe<{ __typename?: 'DirRegion' } & RegionFragment>;
};

export type ListOrganizationOfOivQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ListOrganizationOfOivQuery = { __typename?: 'Query' } & {
  dirOrganizations: Array<
    { __typename?: 'DirOrganization' } & { value: DirOrganization['id']; label: DirOrganization['name'] }
  >;
};

export type ListSportsOfBaseRegionsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type ListSportsOfBaseRegionsQuery = { __typename?: 'Query' } & {
  dirSports: Array<
    { __typename?: 'DirSport' } & { value: DirSport['id']; label: DirSport['fullName'] } & {
      baseRegions?: Maybe<
        Array<
          { __typename?: 'DirSportBaseRegion' } & Pick<DirSportBaseRegion, 'id' | 'startDate' | 'endDate'> & {
              dirRegion: { __typename?: 'DirRegion' } & Pick<DirRegion, 'id'>;
            }
        >
      >;
    }
  >;
};

export type RegionsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirRegionWhereInput>;
}>;

export type RegionsQuery = { __typename?: 'Query' } & { count: Query['dirRegionsCount'] } & {
  dirRegions: Array<{ __typename?: 'DirRegion' } & RegionFragment>;
};

export type RegionsListQueryVariables = Exact<{ [key: string]: never }>;

export type RegionsListQuery = { __typename?: 'Query' } & {
  dirRegions: Array<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
};

export type GroupDisciplineFragment = { __typename?: 'DirSportDisciplineGroup' } & {
  value: DirSportDisciplineGroup['id'];
  label: DirSportDisciplineGroup['name'];
};

export type DisciplineGroupsQueryVariables = Exact<{
  filter?: Maybe<DirSportDisciplineGroupWhereInput>;
}>;

export type DisciplineGroupsQuery = { __typename?: 'Query' } & {
  dirSportDisciplineGroups: Array<{ __typename?: 'DirSportDisciplineGroup' } & GroupDisciplineFragment>;
};

export type EventsStatusFragment = { __typename?: 'DirSportingEventStatus' } & {
  value: DirSportingEventStatus['id'];
  label: DirSportingEventStatus['name'];
};

export type EventsStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type EventsStatusesQuery = { __typename?: 'Query' } & {
  dirSportingEventStatuses: Array<{ __typename?: 'DirSportingEventStatus' } & EventsStatusFragment>;
};

export type TeamStatusFragment = { __typename?: 'DirNationalTeamStatus' } & {
  value: DirNationalTeamStatus['id'];
  label: DirNationalTeamStatus['name'];
};

export type TeamStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type TeamStatusesQuery = { __typename?: 'Query' } & {
  dirNationalTeamStatuses: Array<{ __typename?: 'DirNationalTeamStatus' } & TeamStatusFragment>;
};

export type DirAthletesSimilarQueryVariables = Exact<{
  search: Scalars['String'];
  sportId?: Maybe<Scalars['UUID']>;
  bannedAthletes?: Maybe<Array<Scalars['UUID']>>;
  minBday?: Maybe<Scalars['DateTime']>;
  maxBday?: Maybe<Scalars['DateTime']>;
}>;

export type DirAthletesSimilarQuery = { __typename?: 'Query' } & {
  dirPersonsSimilar: Array<
    { __typename?: 'SimilarPerson' } & {
      person: { __typename?: 'DirPerson' } & Pick<
        DirPerson,
        'registryNumber' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'
      > & { athleteProfile?: Maybe<{ __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'>> };
    }
  >;
};

export type DirCalendarsSimilarQueryVariables = Exact<{
  search: Scalars['String'];
}>;

export type DirCalendarsSimilarQuery = { __typename?: 'Query' } & {
  dirCalendars: Array<
    { __typename?: 'DirCalendar' } & Pick<DirCalendar, 'id' | 'fullName' | 'shortName'> & {
        archive?: Maybe<{ __typename?: 'Archive' } & Pick<Archive, 'id'>>;
      }
  >;
};

export type FindSimiliarSportingEventsQueryVariables = Exact<{
  search: Scalars['String'];
  besideArray?: Maybe<Array<Scalars['UUID']>>;
}>;

export type FindSimiliarSportingEventsQuery = { __typename?: 'Query' } & {
  dirSportingEvents: Array<
    { __typename?: 'DirSportingEvent' } & Pick<DirSportingEvent, 'registryNumber'> & {
        value: DirSportingEvent['id'];
        label: DirSportingEvent['name'];
      }
  >;
};

export type DirTrainerSimilarQueryVariables = Exact<{
  search: Scalars['String'];
  bannedTrainers?: Maybe<Array<Scalars['UUID']>>;
  sportId?: Maybe<Array<Scalars['UUID']>>;
}>;

export type DirTrainerSimilarQuery = { __typename?: 'Query' } & {
  dirPersonsSimilar: Array<
    { __typename?: 'SimilarPerson' } & {
      person: { __typename?: 'DirPerson' } & Pick<
        DirPerson,
        'registryNumber' | 'firstname' | 'patronymic' | 'lastname' | 'birthday'
      > & { trainerProfile?: Maybe<{ __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'>> };
    }
  >;
};

export type CreateSportingEventMutationVariables = Exact<{
  data: DirSportingEventCreateInput;
}>;

export type CreateSportingEventMutation = { __typename?: 'Mutation' } & {
  createDirSportingEvent: { __typename?: 'DirSportingEvent' } & SportingEventFragment;
};

export type UpdateSportingEventMutationVariables = Exact<{
  id: Scalars['UUID'];
  data: DirSportingEventUpdateInput;
}>;

export type UpdateSportingEventMutation = { __typename?: 'Mutation' } & {
  updateDirSportingEvent?: Maybe<{ __typename?: 'DirSportingEvent' } & SportingEventFragment>;
};

export type SportingEventArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type SportingEventArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirSportingEvent?: Maybe<
    { __typename?: 'DirSportingEvent' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type SportingEventUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type SportingEventUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirSportingEvent?: Maybe<
    { __typename?: 'DirSportingEvent' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type SportingEventFragment = { __typename?: 'DirSportingEvent' } & Pick<
  DirSportingEvent,
  | 'id'
  | 'name'
  | 'venue'
  | 'ekp'
  | 'object'
  | 'startDate'
  | 'endDate'
  | 'registryNumber'
  | 'membersQuota'
  | 'nonresidentRefereesQuota'
  | 'refereesQuota'
  | 'minManAge'
  | 'maxManAge'
  | 'minManBirthYear'
  | 'maxManBirthYear'
  | 'minWomanAge'
  | 'maxWomanAge'
  | 'minWomanBirthYear'
  | 'maxWomanBirthYear'
  | 'comment'
> & {
    clsEventCategories?: Maybe<
      Array<{ __typename?: 'ClassifierValue' } & { value: ClassifierValue['id']; label: ClassifierValue['fullName'] }>
    >;
    clsMinRang?: Maybe<
      { __typename?: 'ClassifierValue' } & { value: ClassifierValue['id']; label: ClassifierValue['fullName'] }
    >;
    parent?: Maybe<
      { __typename?: 'DirSportingEvent' } & Pick<DirSportingEvent, 'registryNumber'> & {
          value: DirSportingEvent['id'];
          label: DirSportingEvent['name'];
        }
    >;
    children?: Maybe<
      Array<
        { __typename?: 'DirSportingEvent' } & Pick<DirSportingEvent, 'registryNumber'> & {
            value: DirSportingEvent['id'];
            label: DirSportingEvent['name'];
          }
      >
    >;
    archive?: Maybe<{ __typename?: 'Archive' } & Pick<Archive, 'id' | 'comment' | 'reason'>>;
    status: { __typename?: 'DirSportingEventStatus' } & {
      value: DirSportingEventStatus['id'];
      label: DirSportingEventStatus['name'];
    };
    statusHistory?: Maybe<
      Array<
        { __typename?: 'DirSportingEventStatusHistory' } & Pick<DirSportingEventStatusHistory, 'id' | 'date'> & {
            user: { __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email'>;
            status: { __typename?: 'DirSportingEventStatus' } & Pick<DirSportingEventStatus, 'name'>;
          }
      >
    >;
    sports?: Maybe<
      Array<
        { __typename?: 'DirSportingEventSport' } & Pick<DirSportingEventSport, 'id'> & {
            dirSport: { __typename?: 'DirSport' } & { value: DirSport['id']; label: DirSport['fullName'] };
            disciplines?: Maybe<
              Array<
                { __typename?: 'DirSportDiscipline' } & {
                  value: DirSportDiscipline['id'];
                  label: DirSportDiscipline['name'];
                } & { typeSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id'>> }
              >
            >;
          }
      >
    >;
    dirCalendar: { __typename?: 'DirCalendar' } & Pick<DirCalendar, 'shortName'> & {
        value: DirCalendar['id'];
        label: DirCalendar['fullName'];
      };
    competitionCalendar?: Maybe<
      Array<
        { __typename?: 'DirSportingEventCompetitionCalendar' } & Pick<
          DirSportingEventCompetitionCalendar,
          'id' | 'day' | 'time' | 'members'
        > & {
            discipline?: Maybe<
              { __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'code'> & {
                  value: DirSportDiscipline['id'];
                  label: DirSportDiscipline['name'];
                }
            >;
          }
      >
    >;
    programTypes?: Maybe<
      Array<
        { __typename?: 'DirSportingEventProgramType' } & Pick<
          DirSportingEventProgramType,
          'id' | 'minAge' | 'maxAge' | 'start'
        > & {
            clsAgeGroups?: Maybe<
              Array<
                { __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'shortName'> & {
                    value: ClassifierValue['id'];
                    label: ClassifierValue['fullName'];
                  }
              >
            >;
            discipline: { __typename?: 'DirSportDiscipline' } & {
              value: DirSportDiscipline['id'];
              label: DirSportDiscipline['name'];
            };
          }
      >
    >;
    dirNationalTeams?: Maybe<
      Array<
        { __typename?: 'DirNationalTeam' } & Pick<
          DirNationalTeam,
          'id' | 'email' | 'athletesCall' | 'manTeamQuota' | 'womanTeamQuota' | 'supportTeamQuota' | 'score' | 'point'
        > & {
            dirRegion: { __typename?: 'DirRegion' } & Pick<DirRegion, 'shortName'> & {
                value: DirRegion['id'];
                label: DirRegion['fullName'];
              };
            status: { __typename?: 'DirNationalTeamStatus' } & {
              value: DirNationalTeamStatus['id'];
              label: DirNationalTeamStatus['name'];
            };
            athleteGroups?: Maybe<
              Array<
                { __typename?: 'DirNationalTeamAthleteGroup' } & Pick<DirNationalTeamAthleteGroup, 'id'> & {
                    athletes?: Maybe<
                      Array<
                        { __typename?: 'DirNationalTeamAthlete' } & Pick<DirNationalTeamAthlete, 'id'> & {
                            dirAthlete: { __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'> & {
                                dirPerson: { __typename?: 'DirPerson' } & Pick<
                                  DirPerson,
                                  | 'id'
                                  | 'firstname'
                                  | 'lastname'
                                  | 'patronymic'
                                  | 'registryNumber'
                                  | 'birthday'
                                  | 'isMale'
                                >;
                                competetionResult?: Maybe<
                                  Array<
                                    { __typename?: 'DirAthleteCompetitionResult' } & Pick<
                                      DirAthleteCompetitionResult,
                                      'id' | 'point' | 'result'
                                    > & {
                                        sportingEventProgramType: { __typename?: 'DirSportingEventProgramType' } & Pick<
                                          DirSportingEventProgramType,
                                          'id'
                                        > & {
                                            clsAgeGroups?: Maybe<
                                              Array<
                                                { __typename?: 'ClassifierValue' } & { value: ClassifierValue['id'] }
                                              >
                                            >;
                                          };
                                      }
                                  >
                                >;
                              };
                          }
                      >
                    >;
                  }
              >
            >;
            specialists?: Maybe<Array<{ __typename?: 'DirNationalTeamMember' } & Pick<DirNationalTeamMember, 'id'>>>;
          }
      >
    >;
    competitionReglaments?: Maybe<
      Array<
        { __typename?: 'DirDocument' } & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'> & {
            file: { __typename?: 'File' } & Pick<File, 'id' | 'path'>;
          }
      >
    >;
    competitionProtocols?: Maybe<
      Array<
        { __typename?: 'DirDocument' } & Pick<DirDocument, 'id' | 'title' | 'number' | 'date'> & {
            file: { __typename?: 'File' } & Pick<File, 'id' | 'path'>;
          }
      >
    >;
    referees?: Maybe<
      Array<
        { __typename?: 'DirSportingEventReferee' } & Pick<DirSportingEventReferee, 'id' | 'isPrimary'> & {
            clsRefereePosition: { __typename?: 'ClassifierValue' } & {
              value: ClassifierValue['id'];
              label: ClassifierValue['fullName'];
            };
            dirReferee: { __typename?: 'DirReferee' } & Pick<DirReferee, 'id'> & {
                sports?: Maybe<
                  Array<
                    { __typename?: 'DirRefereeSport' } & Pick<DirRefereeSport, 'id'> & {
                        dirSport: { __typename?: 'DirSport' } & { value: DirSport['id'] };
                        categories?: Maybe<
                          Array<
                            { __typename?: 'DirRefereeSportCategory' } & Pick<
                              DirRefereeSportCategory,
                              'id' | 'validUntil' | 'isEndless'
                            > & {
                                clsRefereeCategory: { __typename?: 'ClassifierValue' } & Pick<
                                  ClassifierValue,
                                  'id' | 'fullName'
                                >;
                                dirDocument?: Maybe<
                                  { __typename?: 'DirDocument' } & Pick<DirDocument, 'id'> & {
                                      file: { __typename?: 'File' } & Pick<File, 'id' | 'name' | 'path'>;
                                    }
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
                dirPerson: { __typename?: 'DirPerson' } & Pick<
                  DirPerson,
                  'id' | 'firstname' | 'lastname' | 'patronymic' | 'email' | 'isRussia'
                > & {
                    phones?: Maybe<Array<{ __typename?: 'DirPersonPhone' } & Pick<DirPersonPhone, 'id' | 'number'>>>;
                    dirRegion?: Maybe<{ __typename?: 'DirRegion' } & Pick<DirRegion, 'id' | 'fullName'>>;
                    dirCountry?: Maybe<{ __typename?: 'DirCountry' } & Pick<DirCountry, 'id' | 'fullName'>>;
                    dirForeignCity?: Maybe<{ __typename?: 'DirForeignCity' } & Pick<DirForeignCity, 'id' | 'fullName'>>;
                  };
              };
          }
      >
    >;
  };

export type SportingEventsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirSportingEventWhereInput>;
}>;

export type SportingEventsQuery = { __typename?: 'Query' } & { count: Query['dirSportingEventsCount'] } & {
  dirSportingEvents: Array<{ __typename?: 'DirSportingEvent' } & SportingEventFragment>;
};

export type SportingEventsCountQueryVariables = Exact<{
  filter?: Maybe<DirSportingEventWhereInput>;
}>;

export type SportingEventsCountQuery = { __typename?: 'Query' } & { count: Query['dirSportingEventsCount'] };

export type SportingEventQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type SportingEventQuery = { __typename?: 'Query' } & {
  dirSportingEvent?: Maybe<{ __typename?: 'DirSportingEvent' } & SportingEventFragment>;
};

export type TrainerFrFragment = { __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id' | 'registryNumber'> & {
    archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
    dirPerson: { __typename?: 'DirPerson' } & PersonFrFragment;
    sports?: Maybe<
      Array<
        { __typename?: 'DirTrainerSport' } & Pick<DirTrainerSport, 'id'> & {
            dirSport: { __typename?: 'DirSport' } & { value: DirSport['id']; label: DirSport['fullName'] };
            archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
            organizations?: Maybe<
              Array<
                { __typename?: 'DirTrainerSportOrganization' } & Pick<
                  DirTrainerSportOrganization,
                  'id' | 'employmentDate' | 'dismissalDate'
                > & {
                    clsPosition: { __typename?: 'ClassifierValue' } & {
                      value: ClassifierValue['id'];
                      label: ClassifierValue['fullName'];
                    };
                    dirOrganization: { __typename?: 'DirOrganization' } & {
                      value: DirOrganization['id'];
                      label: DirOrganization['name'];
                    };
                  }
              >
            >;
          }
      >
    >;
  };

export type TrainersQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirTrainerWhereInput>;
}>;

export type TrainersQuery = { __typename?: 'Query' } & { count: Query['dirTrainersCount'] } & {
  dirTrainers: Array<
    { __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id' | 'registryNumber'> & {
        archive?: Maybe<{ __typename?: 'Archive' } & Pick<Archive, 'id'>>;
        dirPerson: { __typename?: 'DirPerson' } & PersonFrFragment;
        sports?: Maybe<
          Array<
            { __typename?: 'DirTrainerSport' } & { dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'fullName'> }
          >
        >;
      }
  >;
};

export type TrainersCountQueryVariables = Exact<{
  filter?: Maybe<DirTrainerWhereInput>;
}>;

export type TrainersCountQuery = { __typename?: 'Query' } & { count: Query['dirTrainersCount'] };

export type TrainerByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TrainerByIdQuery = { __typename?: 'Query' } & {
  dirTrainer?: Maybe<{ __typename?: 'DirTrainer' } & TrainerFrFragment>;
};

export type TrainersFilteredQueryVariables = Exact<{
  filter?: Maybe<DirTrainerWhereInput>;
}>;

export type TrainersFilteredQuery = { __typename?: 'Query' } & {
  dirTrainers: Array<{ __typename?: 'DirTrainer' } & TrainerFrFragment>;
};

export type TypeSportAgeGroupsRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCls: Scalars['UUID'];
}>;

export type TypeSportAgeGroupsRemoveMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & AgeGroupFragment>;
};

export type TypeSportAgeGroupsUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  idCls: Scalars['UUID'];
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
}>;

export type TypeSportAgeGroupsUpdateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & AgeGroupFragment>;
};

export type TypeSportAgeGroupsCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idCls: Scalars['UUID'];
  minAge: Scalars['Int'];
  maxAge?: Maybe<Scalars['Int']>;
}>;

export type TypeSportAgeGroupsCreateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & AgeGroupFragment>;
};

export type AgeGroupFrFragment = { __typename?: 'DirSportAgeGroup' } & Pick<
  DirSportAgeGroup,
  'id' | 'minAge' | 'maxAge'
> & {
    clsAgeGroup: { __typename?: 'ClassifierValue' } & {
      value: ClassifierValue['id'];
      label: ClassifierValue['fullName'];
    };
  };

export type AgeGroupFragment = { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
    ageGroups?: Maybe<Array<{ __typename?: 'DirSportAgeGroup' } & AgeGroupFrFragment>>;
  };

export type TypeSportAgeGroupsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportAgeGroupsQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<{ __typename?: 'DirSport' } & AgeGroupFragment>;
};

export type RemoveBaseRegionByIdMutationVariables = Exact<{
  id: Scalars['UUID'];
  idBaseRegion: Scalars['UUID'];
}>;

export type RemoveBaseRegionByIdMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id'> & BaseRegionFragment>;
};

export type UpdateBaseRegionByIdMutationVariables = Exact<{
  id: Scalars['UUID'];
  idBaseRegion: Scalars['UUID'];
  idRegion: Scalars['UUID'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;

export type UpdateBaseRegionByIdMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id'> & BaseRegionFragment>;
};

export type CreteBaseRegionMutationVariables = Exact<{
  id: Scalars['UUID'];
  idRegion: Scalars['UUID'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;

export type CreteBaseRegionMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id'> & BaseRegionFragment>;
};

export type BaseRegionFragment = { __typename?: 'DirSport' } & {
  baseRegions?: Maybe<
    Array<
      { __typename?: 'DirSportBaseRegion' } & Pick<DirSportBaseRegion, 'id' | 'startDate' | 'endDate'> & {
          dirRegion: { __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['shortName'] };
        }
    >
  >;
};

export type BaseRegionsByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type BaseRegionsByIdQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id'> & BaseRegionFragment>;
};

export type TypeSportDisciplinesUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idDiscipline: Scalars['UUID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
}>;

export type TypeSportDisciplinesUpdateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplinesFragment>;
};

export type TypeSportDisciplinesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
}>;

export type TypeSportDisciplinesCreateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplinesFragment>;
};

export type TypeSportDisciplinesArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type TypeSportDisciplinesArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirSportDiscipline?: Maybe<
    { __typename?: 'DirSportDiscipline' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type TypeSportDisciplinesArchiveRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportDisciplinesArchiveRemoveMutation = { __typename?: 'Mutation' } & {
  updateDirSportDiscipline?: Maybe<{ __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'id'>>;
};

export type DisciplinesFragment = { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
    disciplines?: Maybe<
      Array<
        { __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'id' | 'name' | 'code'> & {
            archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
          }
      >
    >;
  };

export type TypeSportDisciplinesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportDisciplinesQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplinesFragment>;
};

export type TypeSportDisciplinesDrillQueryVariables = Exact<{
  filter?: Maybe<DirSportDisciplineWhereInput>;
}>;

export type TypeSportDisciplinesDrillQuery = { __typename?: 'Query' } & {
  dirSportDisciplines: Array<
    { __typename?: 'DirSportDiscipline' } & { value: DirSportDiscipline['id']; label: DirSportDiscipline['name'] }
  >;
};

export type MultipleTypeSportDisciplinesDrillQueryVariables = Exact<{
  id?: Maybe<Array<Scalars['UUID']>>;
}>;

export type MultipleTypeSportDisciplinesDrillQuery = { __typename?: 'Query' } & {
  dirSports: Array<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        disciplines?: Maybe<
          Array<
            { __typename?: 'DirSportDiscipline' } & {
              value: DirSportDiscipline['id'];
              label: DirSportDiscipline['name'];
            } & { typeSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id'>> }
          >
        >;
      }
  >;
};

export type GenerateXlsFileQueryVariables = Exact<{
  filterString: Scalars['String'];
  filter: DirSportWhereInput;
  first: Scalars['Int'];
}>;

export type GenerateXlsFileQuery = { __typename?: 'Query' } & {
  dirSportsReport: { __typename?: 'DirSportReport' } & Pick<DirSportReport, 'xlsx'>;
};

export type GroupTypesSportAndDisciplinesRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type GroupTypesSportAndDisciplinesRemoveMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        clsSportAndDisciplineGroups?: Maybe<
          Array<{ __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'fullName' | 'order' | 'id'>>
        >;
      }
  >;
};

export type GroupTypesSportAndDisciplinesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type GroupTypesSportAndDisciplinesCreateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        clsSportAndDisciplineGroups?: Maybe<
          Array<{ __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'fullName' | 'order' | 'id'>>
        >;
      }
  >;
};

export type GroupTypesSportAndDisciplinesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type GroupTypesSportAndDisciplinesQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        clsSportAndDisciplineGroups?: Maybe<Array<{ __typename?: 'ClassifierValue' } & ClassifierValueFragment>>;
      }
  >;
};

export type TypeSportGroupDisciplinesRenameMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  name: Scalars['String'];
}>;

export type TypeSportGroupDisciplinesRenameMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplineGroupFragment>;
};

export type TypeSportGroupDisciplinesAssignDisciplineMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  discipline: Scalars['UUID'];
}>;

export type TypeSportGroupDisciplinesAssignDisciplineMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplineGroupFragment>;
};

export type TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables = Exact<{
  id: Scalars['UUID'];
  idGroup: Scalars['UUID'];
  discipline: Scalars['UUID'];
}>;

export type TypeSportGroupDisciplinesUnAssignDisciplineMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplineGroupFragment>;
};

export type TypeSportGroupDisciplinesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;

export type TypeSportGroupDisciplinesCreateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplineGroupFragment>;
};

export type TypeSportGroupDisciplinesArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type TypeSportGroupDisciplinesArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirSportDisciplineGroup?: Maybe<
    { __typename?: 'DirSportDisciplineGroup' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type DisciplineGroupFragment = { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
    disciplineGroups?: Maybe<
      Array<
        { __typename?: 'DirSportDisciplineGroup' } & Pick<DirSportDisciplineGroup, 'id' | 'name'> & {
            disciplines?: Maybe<
              Array<
                { __typename?: 'DirSportDiscipline' } & {
                  value: DirSportDiscipline['id'];
                  label: DirSportDiscipline['name'];
                }
              >
            >;
            archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
          }
      >
    >;
    disciplines?: Maybe<
      Array<
        { __typename?: 'DirSportDiscipline' } & { value: DirSportDiscipline['id']; label: DirSportDiscipline['name'] }
      >
    >;
  };

export type TypeSportGroupsDisciplinesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportGroupsDisciplinesQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<{ __typename?: 'DirSport' } & DisciplineGroupFragment>;
};

export type TypeSportsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<DirSportWhereInput>;
}>;

export type TypeSportsQuery = { __typename?: 'Query' } & { count: Query['dirSportsCount'] } & {
  dirSports: Array<
    { __typename?: 'DirSport' } & Pick<DirSport, 'evsk' | 'fullName' | 'registryNumber' | 'id'> & {
        archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
      }
  >;
};

export type TypeSportsCountQueryVariables = Exact<{
  filter?: Maybe<DirSportWhereInput>;
}>;

export type TypeSportsCountQuery = { __typename?: 'Query' } & { count: Query['dirSportsCount'] };

export type TypeSportCreateMutationVariables = Exact<{
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  evsk: Scalars['String'];
}>;

export type TypeSportCreateMutation = { __typename?: 'Mutation' } & {
  createDirSport: { __typename?: 'DirSport' } & MainInfoFragment;
};

export type TypeSportUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  fullName: Scalars['String'];
  shortName: Scalars['String'];
  evsk?: Maybe<Scalars['String']>;
}>;

export type TypeSportUpdateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<{ __typename?: 'DirSport' } & MainInfoFragment>;
};

export type TypeSportArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  archive: ArchiveUpdateOneInput;
}>;

export type TypeSportArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type TypeSportUnArchiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportUnArchiveMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & { archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment> }
  >;
};

export type MainInfoFragment = { __typename?: 'DirSport' } & Pick<
  DirSport,
  'id' | 'fullName' | 'shortName' | 'evsk' | 'registryNumber'
> & { archive?: Maybe<{ __typename?: 'Archive' } & Pick<Archive, 'comment' | 'id' | 'reason' | 'date'>> };

export type FetchTypeSportByIdMainInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type FetchTypeSportByIdMainInfoQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<{ __typename?: 'DirSport' } & MainInfoFragment>;
};

export type TypeSportsListQueryVariables = Exact<{ [key: string]: never }>;

export type TypeSportsListQuery = { __typename?: 'Query' } & {
  dirSports: Array<{ __typename?: 'DirSport' } & { value: DirSport['id']; label: DirSport['fullName'] }>;
};

export type TypeSportRanksAndTitlesCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type TypeSportRanksAndTitlesCreateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        clsRanksAndAwards?: Maybe<
          Array<
            { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
          >
        >;
      }
  >;
};

export type TypeSportRanksAndTitlesRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type TypeSportRanksAndTitlesRemoveMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        clsRanksAndAwards?: Maybe<
          Array<
            { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
          >
        >;
      }
  >;
};

export type TypeSportRanksAndTitlesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportRanksAndTitlesQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        clsRanksAndAwards?: Maybe<
          Array<
            { __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }
          >
        >;
      }
  >;
};

export type TypeSportStagePrepareCreateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idStep: Scalars['UUID'];
  idRank?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
}>;

export type TypeSportStagePrepareCreateMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        trainingStages?: Maybe<Array<{ __typename?: 'DirSportTrainingStage' } & SportTrainingStageFragment>>;
      }
  >;
};

export type TypeSportStagePrepareUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  idStep: Scalars['UUID'];
  idRank?: Maybe<Array<ClassifierValueWhereUniqueInput>>;
  minManAge?: Maybe<Scalars['Int']>;
  maxManAge?: Maybe<Scalars['Int']>;
  minWomanAge?: Maybe<Scalars['Int']>;
  maxWomanAge?: Maybe<Scalars['Int']>;
  minGroupSize?: Maybe<Scalars['Int']>;
  maxGroupSize?: Maybe<Scalars['Int']>;
}>;

export type TypeSportStagePrepareUpdateMutation = { __typename?: 'Mutation' } & {
  updateDirSportTrainingStage?: Maybe<{ __typename?: 'DirSportTrainingStage' } & SportTrainingStageFragment>;
};

export type TypeSportStagePrepareRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
  idItem: Scalars['UUID'];
}>;

export type TypeSportStagePrepareRemoveMutation = { __typename?: 'Mutation' } & {
  updateDirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        trainingStages?: Maybe<Array<{ __typename?: 'DirSportTrainingStage' } & SportTrainingStageFragment>>;
      }
  >;
};

export type SportTrainingStageFragment = { __typename?: 'DirSportTrainingStage' } & Pick<
  DirSportTrainingStage,
  'id' | 'minManAge' | 'maxManAge' | 'minWomanAge' | 'maxWomanAge' | 'minGroupSize' | 'maxGroupSize'
> & {
    clsTrainingStage: { __typename?: 'ClassifierValue' } & {
      label: ClassifierValue['fullName'];
      value: ClassifierValue['id'];
    };
    clsRanksAndAwards?: Maybe<
      Array<{ __typename?: 'ClassifierValue' } & { label: ClassifierValue['fullName']; value: ClassifierValue['id'] }>
    >;
  };

export type TypeSportStagePreparingQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type TypeSportStagePreparingQuery = { __typename?: 'Query' } & {
  dirSport?: Maybe<
    { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
        trainingStages?: Maybe<Array<{ __typename?: 'DirSportTrainingStage' } & SportTrainingStageFragment>>;
      }
  >;
};

export type RemoveTypeSportMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RemoveTypeSportMutation = { __typename?: 'Mutation' } & {
  deleteDirSport?: Maybe<{ __typename?: 'DirSport' } & Pick<DirSport, 'id'>>;
};

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type RemoveUserMutation = { __typename?: 'Mutation' } & {
  deleteUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
};

export type CreateUserMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
  regional?: Maybe<RegionalMinsportProfileCreateOneInput>;
  school?: Maybe<RegionalSchoolProfileCreateOneInput>;
}>;

export type CreateUserMutation = { __typename?: 'Mutation' } & {
  signup: { __typename?: 'AuthPayload' } & Pick<AuthPayload, 'token'> & {
      user: { __typename?: 'User' } & UserFrFragment;
    };
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['UUID'];
  userID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  email: Scalars['String'];
  regional?: Maybe<RegionalMinsportProfileUpdateOneInput>;
  school?: Maybe<RegionalSchoolProfileUpdateOneInput>;
  regular?: Maybe<RegularUserUpdateOneInput>;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser?: Maybe<{ __typename?: 'User' } & UserFrFragment>;
  changeUserRole: { __typename?: 'User' } & Pick<User, 'id'>;
  changeUserEmail: { __typename?: 'User' } & Pick<User, 'id'>;
};

export type UpdateUserContactInfoMutationVariables = Exact<{
  id: Scalars['UUID'];
  regular?: Maybe<RegularUserUpdateOneInput>;
}>;

export type UpdateUserContactInfoMutation = { __typename?: 'Mutation' } & {
  updateUser?: Maybe<{ __typename?: 'User' } & UserFrFragment>;
};

export type UserFrFragment = { __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email'> & {
    role: { __typename?: 'UserRole' } & Pick<UserRole, 'description'> & {
        value: UserRole['id'];
        label: UserRole['name'];
      };
    regular?: Maybe<
      { __typename?: 'RegularUser' } & Pick<
        RegularUser,
        'address' | 'birthday' | 'firstname' | 'lastname' | 'patronymic' | 'isMale' | 'position' | 'phone'
      > & {
          dirOrganization?: Maybe<
            { __typename?: 'DirOrganization' } & { value: DirOrganization['id']; label: DirOrganization['name'] }
          >;
        }
    >;
  };

export type UsersQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  filter?: Maybe<UserWhereInput>;
}>;

export type UsersQuery = { __typename?: 'Query' } & { count: Query['usersCount'] } & {
  users: Array<{ __typename?: 'User' } & UserFrFragment>;
};

export type UserQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type UserQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & {
      regionalMinsportProfile?: Maybe<
        { __typename?: 'RegionalMinsportProfile' } & Pick<RegionalMinsportProfile, 'id'> & {
            dirRegion?: Maybe<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
            dirFederalDistrict?: Maybe<
              { __typename?: 'DirFederalDistrict' } & {
                value: DirFederalDistrict['id'];
                label: DirFederalDistrict['fullName'];
              }
            >;
          }
      >;
      regionalSchoolProfile?: Maybe<
        { __typename?: 'RegionalSchoolProfile' } & Pick<RegionalSchoolProfile, 'id'> & {
            dirOrganization: { __typename?: 'DirOrganization' } & {
              value: DirOrganization['id'];
              label: DirOrganization['name'];
            };
          }
      >;
    } & UserFrFragment
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  resetEmail: Scalars['String'];
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'publicResetPassword'>;

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  signin: { __typename?: 'AuthPayload' } & Pick<AuthPayload, 'token'> & {
      user: { __typename?: 'User' } & {
        role: { __typename?: 'UserRole' } & Pick<UserRole, 'name'>;
        personProfile?: Maybe<
          { __typename?: 'DirPerson' } & {
            athleteProfile?: Maybe<{ __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'>>;
            trainerProfile?: Maybe<{ __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'>>;
            dirRegion?: Maybe<{ __typename?: 'DirRegion' } & Pick<DirRegion, 'id'>>;
            dirForeignCity?: Maybe<{ __typename?: 'DirForeignCity' } & Pick<DirForeignCity, 'id'>>;
          }
        >;
        regionalMinsportProfile?: Maybe<
          { __typename?: 'RegionalMinsportProfile' } & {
            dirRegion?: Maybe<{ __typename?: 'DirRegion' } & Pick<DirRegion, 'id'>>;
            dirFederalDistrict?: Maybe<{ __typename?: 'DirFederalDistrict' } & Pick<DirFederalDistrict, 'id'>>;
          }
        >;
        regionalSchoolProfile?: Maybe<
          { __typename?: 'RegionalSchoolProfile' } & {
            dirOrganization: { __typename?: 'DirOrganization' } & Pick<DirOrganization, 'id'>;
          }
        >;
      };
    };
};

export const ArchiveStatusFragmentDoc = Apollo.gql`
    fragment ArchiveStatus on Archive {
  id
  reason
  date
  comment
}
    `;
export const CountryDirValueFragmentDoc = Apollo.gql`
    fragment CountryDirValue on DirCountry {
  value: id
  label: fullName
}
    `;
export const ForeignCityDirValueFragmentDoc = Apollo.gql`
    fragment ForeignCityDirValue on DirForeignCity {
  value: id
  label: fullName
}
    `;
export const RegionDirValueFragmentDoc = Apollo.gql`
    fragment RegionDirValue on DirRegion {
  value: id
  label: fullName
}
    `;
export const CitizenshipDirValueFragmentDoc = Apollo.gql`
    fragment CitizenshipDirValue on DirCountry {
  value: id
  label: fullName
}
    `;
export const PhoneFragmentDoc = Apollo.gql`
    fragment Phone on DirPersonPhone {
  id
  number
  deletedAt
  countryCode
  operatorCode
  additionalNumber
  clsContactType {
    value: id
    label: fullName
  }
}
    `;
export const PersonFrFragmentDoc = Apollo.gql`
    fragment PersonFR on DirPerson {
  id
  registryNumber
  firstname
  lastname
  isMale
  clsMaritalStatus {
    value: id
    label: fullName
  }
  previousLastname
  patronymic
  email
  birthday
  primary {
    registryNumber
    firstname
    lastname
    patronymic
  }
  duplicates {
    registryNumber
    firstname
    lastname
    patronymic
  }
  confirmStatus {
    value: id
    label: name
  }
  isRussia
  dirCountry {
    ...CountryDirValue
  }
  dirForeignCity {
    ...ForeignCityDirValue
  }
  dirRegion {
    ...RegionDirValue
  }
  archive {
    ...ArchiveStatus
  }
  dirCitizenships(orderBy: fullName_ASC) {
    ...CitizenshipDirValue
  }
  athleteProfile {
    id
  }
  trainerProfile {
    id
  }
  refereeProfile {
    id
  }
  phones {
    ...Phone
  }
}
    ${CountryDirValueFragmentDoc}
${ForeignCityDirValueFragmentDoc}
${RegionDirValueFragmentDoc}
${ArchiveStatusFragmentDoc}
${CitizenshipDirValueFragmentDoc}
${PhoneFragmentDoc}`;
export const AthleteFrFragmentDoc = Apollo.gql`
    fragment AthleteFR on DirAthlete {
  id
  archive {
    ...ArchiveStatus
  }
  medCerts {
    id
    issueDate
    displayNumber
    organizationName
    organizationPhone
    organizationEmail
    organizationAddress
    physicalExaminationDate
    hasRestrictions
    sports {
      dirSport {
        fullName
        disciplineGroups {
          name
        }
      }
      clsTrainingStage {
        shortName
      }
      disciplineGroups {
        name
      }
    }
    validUntil
    isValid
    deletedAt
  }
  dirPerson {
    ...PersonFR
  }
  sports {
    id
    archive {
      ...ArchiveStatus
    }
    dirSport {
      value: id
      label: fullName
    }
    ranksAndAwards {
      id
      clsRankAndAward {
        value: id
        label: fullName
      }
      assignmentDate
      confirmDate
      validUntil
    }
    disciplines {
      value: id
      label: name
    }
    role
    organizations {
      id
      isSelfTraining
      trainingStart
      exclusionDate
      dirOrganization {
        value: id
        label: name
      }
      dirTrainer {
        value: id
        dirPerson {
          trainerProfile {
            id
          }
          id
          firstname
          patronymic
          lastname
          birthday
        }
        sports {
          organizations {
            dirOrganization {
              id
            }
            dismissalDate
          }
        }
      }
      clsTrainingStage {
        value: id
        label: shortName
        shortName
      }
    }
    dirTrainers {
      id
      dirPerson {
        firstname
        lastname
        patronymic
        registryNumber
        birthday
      }
    }
  }
}
    ${ArchiveStatusFragmentDoc}
${PersonFrFragmentDoc}`;
export const AthleteListFrFragmentDoc = Apollo.gql`
    fragment AthleteListFR on DirAthlete {
  id
  archive {
    ...ArchiveStatus
  }
  dirPerson {
    ...PersonFR
  }
  sports {
    id
    archive {
      ...ArchiveStatus
    }
    dirSport {
      id
      fullName
    }
    organizations {
      isSelfTraining
      trainingStart
      exclusionDate
      dirOrganization {
        value: id
        label: name
      }
      dirTrainer {
        dirPerson {
          id
          firstname
          patronymic
          lastname
        }
        sports {
          organizations {
            dirOrganization {
              id
            }
            dismissalDate
          }
        }
      }
      clsTrainingStage {
        shortName
      }
    }
  }
}
    ${ArchiveStatusFragmentDoc}
${PersonFrFragmentDoc}`;
export const CalendarFragmentDoc = Apollo.gql`
    fragment Calendar on DirCalendar {
  id
  registryNumber
  fullName
  shortName
  startDate
  endDate
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const ClassifierFragmentDoc = Apollo.gql`
    fragment Classifier on Classifier {
  id
  name
  registryNumber
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const ClsValueFragmentDoc = Apollo.gql`
    fragment ClsValue on ClassifierValue {
  fullName
  shortName
  id
  order
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const ClassifierValueFragmentDoc = Apollo.gql`
    fragment ClassifierValue on ClassifierValue {
  label: fullName
  value: id
}
    `;
export const DocumentFragmentDoc = Apollo.gql`
    fragment Document on DirDocument {
  id
  date
  number
  title
  file {
    id
    name
    path
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const FederalDistrictFragmentDoc = Apollo.gql`
    fragment FederalDistrict on DirFederalDistrict {
  id
  fullName
  shortName
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const ForeignCityFragmentDoc = Apollo.gql`
    fragment ForeignCity on DirForeignCity {
  id
  fullName
  shortName
  dirCountry {
    value: id
    label: fullName
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const NationalTeamFragmentDoc = Apollo.gql`
    fragment NationalTeam on DirNationalTeam {
  id
  registryNumber
  comment
  supportTeamQuota
  manTeamQuota
  womanTeamQuota
  trainers {
    id
    dirTrainer {
      id
      dirPerson {
        id
        registryNumber
        firstname
        lastname
        patronymic
        birthday
      }
    }
  }
  specialists {
    id
    firstname
    lastname
    patronymic
    birthday
  }
  medics {
    id
    firstname
    lastname
    patronymic
    birthday
  }
  score
  athleteGroups {
    id
    minAge
    maxAge
    clsAgeGroups {
      id
      fullName
    }
    disciplines {
      id
      name
      dirSport {
        id
        fullName
      }
    }
    disciplineGroups {
      id
      name
    }
    athletes {
      id
      bestResult
      dirAthlete {
        id
        dirPerson {
          registryNumber
          firstname
          lastname
          patronymic
          birthday
          isMale
        }
        sports {
          id
          disciplines {
            id
          }
          dirSport {
            id
          }
          organizations(where: {exclusionDate: null}) {
            id
            dirOrganization {
              id
              fullName
            }
          }
          ranksAndAwards {
            id
            clsRankAndAward {
              id
              shortName
              fullName
            }
            validUntil
          }
        }
        competetionResult {
          id
          point
          result
          sportingEventProgramType {
            id
            start
            minAge
            maxAge
            discipline {
              id
            }
          }
        }
      }
    }
  }
  dirRegion {
    id
    fullName
  }
  status {
    id
    name
  }
  statusHistory {
    id
    date
    status {
      name
    }
    user {
      name
      email
    }
  }
  dirSportingEvent {
    id
    startDate
    endDate
    name
    registryNumber
    sports {
      id
      dirSport {
        id
        fullName
      }
      disciplines {
        id
        name
      }
      disciplineGroups {
        id
        name
      }
    }
    dirCalendar {
      id
      fullName
    }
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const OrganizationFrFragmentDoc = Apollo.gql`
    fragment OrganizationFR on DirOrganization {
  id
  registryNumber
  name
  ogrn
  isLegalEntity
  dirRegion {
    value: id
    label: fullName
  }
  archive {
    ...ArchiveStatus
  }
  clsOrganizationCategories {
    value: id
    label: fullName
  }
  addresses {
    id
    place
    street
    house
    number
    type {
      id
      name
    }
  }
  phones {
    id
    number
    additionalNumber
    operatorCode
    countryCode
    clsContactType {
      id
      fullName
    }
  }
  emails {
    id
    email
    clsContactType {
      id
      fullName
    }
  }
  parent {
    id
    ogrn
    name
  }
  children {
    id
    ogrn
    name
  }
  websites
}
    ${ArchiveStatusFragmentDoc}`;
export const OrganizationLegalInfoFrFragmentDoc = Apollo.gql`
    fragment OrganizationLegalInfoFR on DirOrganization {
  id
  name
  ogrn
  isLegalEntity
  fullName
  shortName
  registrationDate
  inn
  kpp
  legalAddress
  postcode
  headFullName
  headPosition
}
    `;
export const OrganizationTypeSportsFrFragmentDoc = Apollo.gql`
    fragment OrganizationTypeSportsFR on DirOrganization {
  id
  dirSports {
    value: id
    label: fullName
  }
}
    `;
export const OrganizationDepartmentAffiliationFrFragmentDoc = Apollo.gql`
    fragment OrganizationDepartmentAffiliationFR on DirOrganization {
  id
  clsDepartmentalAffiliations(orderBy: fullName_ASC) {
    label: fullName
    value: id
  }
}
    `;
export const RefereeFragmentDoc = Apollo.gql`
    fragment Referee on DirReferee {
  id
  dirPerson {
    id
    registryNumber
    firstname
    lastname
    patronymic
    birthday
    isMale
    isRussia
    dirRegion {
      id
      fullName
    }
    dirForeignCity {
      id
      fullName
    }
    dirCountry {
      id
      fullName
    }
  }
  sports {
    id
    workExperience
    categories {
      id
      clsRefereeCategory {
        value: id
        label: fullName
      }
      validUntil
      isEndless
      assignmentDate
      dirDocument {
        id
        file {
          id
          path
        }
      }
    }
    dirSport {
      id
      fullName
    }
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const RegionFragmentDoc = Apollo.gql`
    fragment Region on DirRegion {
  id
  fullName
  shortName
  code
  dirFederalDistrict {
    value: id
    label: fullName
  }
  archive {
    ...ArchiveStatus
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const GroupDisciplineFragmentDoc = Apollo.gql`
    fragment GroupDiscipline on DirSportDisciplineGroup {
  value: id
  label: name
}
    `;
export const EventsStatusFragmentDoc = Apollo.gql`
    fragment EventsStatus on DirSportingEventStatus {
  value: id
  label: name
}
    `;
export const TeamStatusFragmentDoc = Apollo.gql`
    fragment TeamStatus on DirNationalTeamStatus {
  value: id
  label: name
}
    `;
export const SportingEventFragmentDoc = Apollo.gql`
    fragment SportingEvent on DirSportingEvent {
  id
  name
  venue
  ekp
  object
  startDate
  endDate
  registryNumber
  membersQuota
  nonresidentRefereesQuota
  refereesQuota
  minManAge
  maxManAge
  minManBirthYear
  maxManBirthYear
  minWomanAge
  maxWomanAge
  minWomanBirthYear
  maxWomanBirthYear
  clsEventCategories {
    value: id
    label: fullName
  }
  clsMinRang {
    value: id
    label: fullName
  }
  parent {
    value: id
    registryNumber
    label: name
  }
  children {
    value: id
    registryNumber
    label: name
  }
  comment
  archive {
    id
    comment
    reason
  }
  status {
    value: id
    label: name
  }
  statusHistory {
    id
    date
    user {
      id
      name
      email
    }
    status {
      name
    }
  }
  sports {
    id
    dirSport {
      value: id
      label: fullName
    }
    disciplines {
      typeSport: dirSport {
        id
      }
      value: id
      label: name
    }
  }
  dirCalendar {
    value: id
    label: fullName
    shortName
  }
  competitionCalendar(orderBy: day_ASC) {
    id
    day
    time
    discipline {
      value: id
      label: name
      code
    }
    members
  }
  programTypes {
    id
    minAge
    maxAge
    start
    clsAgeGroups {
      value: id
      label: fullName
      shortName
    }
    discipline {
      value: id
      label: name
    }
  }
  dirNationalTeams(orderBy: score_ASC) {
    id
    email
    athletesCall
    dirRegion {
      value: id
      label: fullName
      shortName
    }
    manTeamQuota
    womanTeamQuota
    supportTeamQuota
    status {
      value: id
      label: name
    }
    score
    point
    athleteGroups {
      id
      athletes {
        id
        dirAthlete {
          id
          dirPerson {
            id
            firstname
            lastname
            patronymic
            registryNumber
            birthday
            isMale
          }
          competetionResult {
            id
            point
            result
            sportingEventProgramType {
              id
              clsAgeGroups {
                value: id
              }
            }
          }
        }
      }
    }
    specialists {
      id
    }
  }
  competitionReglaments {
    id
    title
    number
    date
    file {
      id
      path
    }
  }
  competitionProtocols {
    id
    title
    number
    date
    file {
      id
      path
    }
  }
  referees {
    id
    isPrimary
    clsRefereePosition {
      value: id
      label: fullName
    }
    dirReferee {
      id
      sports {
        id
        dirSport {
          value: id
        }
        categories(orderBy: validUntil_DESC) {
          id
          validUntil
          isEndless
          clsRefereeCategory {
            id
            fullName
          }
          dirDocument {
            id
            file {
              id
              name
              path
            }
          }
        }
      }
      dirPerson {
        id
        firstname
        lastname
        patronymic
        email
        phones {
          id
          number
        }
        isRussia
        dirRegion {
          id
          fullName
        }
        dirCountry {
          id
          fullName
        }
        dirForeignCity {
          id
          fullName
        }
      }
    }
  }
}
    `;
export const TrainerFrFragmentDoc = Apollo.gql`
    fragment TrainerFR on DirTrainer {
  id
  registryNumber
  archive {
    ...ArchiveStatus
  }
  dirPerson {
    ...PersonFR
  }
  sports {
    id
    dirSport {
      value: id
      label: fullName
    }
    archive {
      ...ArchiveStatus
    }
    organizations {
      id
      clsPosition {
        value: id
        label: fullName
      }
      dirOrganization {
        value: id
        label: name
      }
      employmentDate
      dismissalDate
    }
  }
}
    ${ArchiveStatusFragmentDoc}
${PersonFrFragmentDoc}`;
export const AgeGroupFrFragmentDoc = Apollo.gql`
    fragment AgeGroupFR on DirSportAgeGroup {
  id
  minAge
  maxAge
  clsAgeGroup {
    value: id
    label: fullName
  }
}
    `;
export const AgeGroupFragmentDoc = Apollo.gql`
    fragment AgeGroup on DirSport {
  id
  ageGroups {
    ...AgeGroupFR
  }
}
    ${AgeGroupFrFragmentDoc}`;
export const BaseRegionFragmentDoc = Apollo.gql`
    fragment BaseRegion on DirSport {
  baseRegions {
    id
    dirRegion {
      value: id
      label: shortName
    }
    startDate
    endDate
  }
}
    `;
export const DisciplinesFragmentDoc = Apollo.gql`
    fragment Disciplines on DirSport {
  id
  disciplines {
    id
    name
    code
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const DisciplineGroupFragmentDoc = Apollo.gql`
    fragment DisciplineGroup on DirSport {
  id
  disciplineGroups {
    id
    name
    disciplines {
      value: id
      label: name
    }
    archive {
      ...ArchiveStatus
    }
  }
  disciplines {
    value: id
    label: name
  }
}
    ${ArchiveStatusFragmentDoc}`;
export const MainInfoFragmentDoc = Apollo.gql`
    fragment MainInfo on DirSport {
  id
  fullName
  shortName
  evsk
  registryNumber
  archive {
    comment
    id
    reason
    date
  }
}
    `;
export const SportTrainingStageFragmentDoc = Apollo.gql`
    fragment SportTrainingStage on DirSportTrainingStage {
  id
  clsTrainingStage {
    label: fullName
    value: id
  }
  minManAge
  maxManAge
  minWomanAge
  maxWomanAge
  minGroupSize
  maxGroupSize
  clsRanksAndAwards {
    label: fullName
    value: id
  }
}
    `;
export const UserFrFragmentDoc = Apollo.gql`
    fragment UserFR on User {
  id
  name
  email
  role {
    value: id
    label: name
    description
  }
  regular {
    address
    birthday
    firstname
    lastname
    patronymic
    isMale
    position
    phone
    dirOrganization {
      value: id
      label: name
    }
  }
}
    `;
export const AthletesExpelDocument = Apollo.gql`
    mutation AthletesExpel($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $trainerID: UUID, $trainingStageID: UUID!, $exclusionDate: DateTime!) {
  athletesExpel(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, trainerID: $trainerID, trainingStageID: $trainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelMutationFn = Apollo.MutationFunction<AthletesExpelMutation, AthletesExpelMutationVariables>;

/**
 * __useAthletesExpelMutation__
 *
 * To run a mutation, you first call `useAthletesExpelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelMutation, { data, loading, error }] = useAthletesExpelMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      trainerID: // value for 'trainerID'
 *      trainingStageID: // value for 'trainingStageID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AthletesExpelMutation, AthletesExpelMutationVariables>,
) {
  return ApolloReactHooks.useMutation<AthletesExpelMutation, AthletesExpelMutationVariables>(
    AthletesExpelDocument,
    baseOptions,
  );
}
export type AthletesExpelMutationHookResult = ReturnType<typeof useAthletesExpelMutation>;
export type AthletesExpelMutationResult = Apollo.MutationResult<AthletesExpelMutation>;
export type AthletesExpelMutationOptions = Apollo.BaseMutationOptions<
  AthletesExpelMutation,
  AthletesExpelMutationVariables
>;
export const AthletesExpelWithMoveToAnotherTrainerDocument = Apollo.gql`
    mutation AthletesExpelWithMoveToAnotherTrainer($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $fromTrainerID: UUID!, $toTrainerID: UUID!, $trainingStageID: UUID!, $exclusionDate: DateTime!) {
  athletesExpelWithMoveToAnotherTrainer(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, fromTrainerID: $fromTrainerID, toTrainerID: $toTrainerID, trainingStageID: $trainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelWithMoveToAnotherTrainerMutationFn = Apollo.MutationFunction<
  AthletesExpelWithMoveToAnotherTrainerMutation,
  AthletesExpelWithMoveToAnotherTrainerMutationVariables
>;

/**
 * __useAthletesExpelWithMoveToAnotherTrainerMutation__
 *
 * To run a mutation, you first call `useAthletesExpelWithMoveToAnotherTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelWithMoveToAnotherTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelWithMoveToAnotherTrainerMutation, { data, loading, error }] = useAthletesExpelWithMoveToAnotherTrainerMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      fromTrainerID: // value for 'fromTrainerID'
 *      toTrainerID: // value for 'toTrainerID'
 *      trainingStageID: // value for 'trainingStageID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelWithMoveToAnotherTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AthletesExpelWithMoveToAnotherTrainerMutation,
    AthletesExpelWithMoveToAnotherTrainerMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AthletesExpelWithMoveToAnotherTrainerMutation,
    AthletesExpelWithMoveToAnotherTrainerMutationVariables
  >(AthletesExpelWithMoveToAnotherTrainerDocument, baseOptions);
}
export type AthletesExpelWithMoveToAnotherTrainerMutationHookResult = ReturnType<
  typeof useAthletesExpelWithMoveToAnotherTrainerMutation
>;
export type AthletesExpelWithMoveToAnotherTrainerMutationResult = Apollo.MutationResult<
  AthletesExpelWithMoveToAnotherTrainerMutation
>;
export type AthletesExpelWithMoveToAnotherTrainerMutationOptions = Apollo.BaseMutationOptions<
  AthletesExpelWithMoveToAnotherTrainerMutation,
  AthletesExpelWithMoveToAnotherTrainerMutationVariables
>;
export const AthletesExpelWithMoveToAnotherTrainingStageDocument = Apollo.gql`
    mutation AthletesExpelWithMoveToAnotherTrainingStage($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $fromTrainingStageID: UUID!, $toTrainingStageID: UUID!, $trainerID: UUID!, $exclusionDate: DateTime!) {
  athletesExpelWithMoveToAnotherTrainingStage(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, trainerID: $trainerID, fromTrainingStageID: $fromTrainingStageID, toTrainingStageID: $toTrainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelWithMoveToAnotherTrainingStageMutationFn = Apollo.MutationFunction<
  AthletesExpelWithMoveToAnotherTrainingStageMutation,
  AthletesExpelWithMoveToAnotherTrainingStageMutationVariables
>;

/**
 * __useAthletesExpelWithMoveToAnotherTrainingStageMutation__
 *
 * To run a mutation, you first call `useAthletesExpelWithMoveToAnotherTrainingStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelWithMoveToAnotherTrainingStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelWithMoveToAnotherTrainingStageMutation, { data, loading, error }] = useAthletesExpelWithMoveToAnotherTrainingStageMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      fromTrainingStageID: // value for 'fromTrainingStageID'
 *      toTrainingStageID: // value for 'toTrainingStageID'
 *      trainerID: // value for 'trainerID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelWithMoveToAnotherTrainingStageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AthletesExpelWithMoveToAnotherTrainingStageMutation,
    AthletesExpelWithMoveToAnotherTrainingStageMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AthletesExpelWithMoveToAnotherTrainingStageMutation,
    AthletesExpelWithMoveToAnotherTrainingStageMutationVariables
  >(AthletesExpelWithMoveToAnotherTrainingStageDocument, baseOptions);
}
export type AthletesExpelWithMoveToAnotherTrainingStageMutationHookResult = ReturnType<
  typeof useAthletesExpelWithMoveToAnotherTrainingStageMutation
>;
export type AthletesExpelWithMoveToAnotherTrainingStageMutationResult = Apollo.MutationResult<
  AthletesExpelWithMoveToAnotherTrainingStageMutation
>;
export type AthletesExpelWithMoveToAnotherTrainingStageMutationOptions = Apollo.BaseMutationOptions<
  AthletesExpelWithMoveToAnotherTrainingStageMutation,
  AthletesExpelWithMoveToAnotherTrainingStageMutationVariables
>;
export const AthletesExpelWithMoveToSelfTrainingDocument = Apollo.gql`
    mutation AthletesExpelWithMoveToSelfTraining($athletesID: [UUID!]!, $sportID: UUID!, $organizationID: UUID!, $trainerID: UUID!, $trainingStageID: UUID!, $exclusionDate: DateTime!) {
  athletesExpelWithMoveToSelfTraining(athletesID: $athletesID, sportID: $sportID, organizationID: $organizationID, trainerID: $trainerID, trainingStageID: $trainingStageID, exclusionDate: $exclusionDate)
}
    `;
export type AthletesExpelWithMoveToSelfTrainingMutationFn = Apollo.MutationFunction<
  AthletesExpelWithMoveToSelfTrainingMutation,
  AthletesExpelWithMoveToSelfTrainingMutationVariables
>;

/**
 * __useAthletesExpelWithMoveToSelfTrainingMutation__
 *
 * To run a mutation, you first call `useAthletesExpelWithMoveToSelfTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthletesExpelWithMoveToSelfTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athletesExpelWithMoveToSelfTrainingMutation, { data, loading, error }] = useAthletesExpelWithMoveToSelfTrainingMutation({
 *   variables: {
 *      athletesID: // value for 'athletesID'
 *      sportID: // value for 'sportID'
 *      organizationID: // value for 'organizationID'
 *      trainerID: // value for 'trainerID'
 *      trainingStageID: // value for 'trainingStageID'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useAthletesExpelWithMoveToSelfTrainingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AthletesExpelWithMoveToSelfTrainingMutation,
    AthletesExpelWithMoveToSelfTrainingMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AthletesExpelWithMoveToSelfTrainingMutation,
    AthletesExpelWithMoveToSelfTrainingMutationVariables
  >(AthletesExpelWithMoveToSelfTrainingDocument, baseOptions);
}
export type AthletesExpelWithMoveToSelfTrainingMutationHookResult = ReturnType<
  typeof useAthletesExpelWithMoveToSelfTrainingMutation
>;
export type AthletesExpelWithMoveToSelfTrainingMutationResult = Apollo.MutationResult<
  AthletesExpelWithMoveToSelfTrainingMutation
>;
export type AthletesExpelWithMoveToSelfTrainingMutationOptions = Apollo.BaseMutationOptions<
  AthletesExpelWithMoveToSelfTrainingMutation,
  AthletesExpelWithMoveToSelfTrainingMutationVariables
>;
export const UpdateDirAthleteDocument = Apollo.gql`
    mutation updateDirAthlete($id: UUID!, $data: DirAthleteUpdateInput!) {
  updateDirAthlete(where: {id: $id}, data: $data) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;
export type UpdateDirAthleteMutationFn = Apollo.MutationFunction<
  UpdateDirAthleteMutation,
  UpdateDirAthleteMutationVariables
>;

/**
 * __useUpdateDirAthleteMutation__
 *
 * To run a mutation, you first call `useUpdateDirAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirAthleteMutation, { data, loading, error }] = useUpdateDirAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDirAthleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirAthleteMutation, UpdateDirAthleteMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateDirAthleteMutation, UpdateDirAthleteMutationVariables>(
    UpdateDirAthleteDocument,
    baseOptions,
  );
}
export type UpdateDirAthleteMutationHookResult = ReturnType<typeof useUpdateDirAthleteMutation>;
export type UpdateDirAthleteMutationResult = Apollo.MutationResult<UpdateDirAthleteMutation>;
export type UpdateDirAthleteMutationOptions = Apollo.BaseMutationOptions<
  UpdateDirAthleteMutation,
  UpdateDirAthleteMutationVariables
>;
export const CreateAthleteDocument = Apollo.gql`
    mutation CreateAthlete($idPerson: UUID!) {
  createDirAthlete(data: {dirPerson: {connect: {id: $idPerson}}}) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;
export type CreateAthleteMutationFn = Apollo.MutationFunction<CreateAthleteMutation, CreateAthleteMutationVariables>;

/**
 * __useCreateAthleteMutation__
 *
 * To run a mutation, you first call `useCreateAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAthleteMutation, { data, loading, error }] = useCreateAthleteMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useCreateAthleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAthleteMutation, CreateAthleteMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateAthleteMutation, CreateAthleteMutationVariables>(
    CreateAthleteDocument,
    baseOptions,
  );
}
export type CreateAthleteMutationHookResult = ReturnType<typeof useCreateAthleteMutation>;
export type CreateAthleteMutationResult = Apollo.MutationResult<CreateAthleteMutation>;
export type CreateAthleteMutationOptions = Apollo.BaseMutationOptions<
  CreateAthleteMutation,
  CreateAthleteMutationVariables
>;
export const AthleteConnectDisciplineToSportDocument = Apollo.gql`
    mutation AthleteConnectDisciplineToSport($idSport: UUID!, $idDiscipline: UUID) {
  updateDirAthleteSport(where: {id: $idSport}, data: {disciplines: {connect: [{id: $idDiscipline}]}}) {
    disciplines {
      value: id
      label: name
    }
  }
}
    `;
export type AthleteConnectDisciplineToSportMutationFn = Apollo.MutationFunction<
  AthleteConnectDisciplineToSportMutation,
  AthleteConnectDisciplineToSportMutationVariables
>;

/**
 * __useAthleteConnectDisciplineToSportMutation__
 *
 * To run a mutation, you first call `useAthleteConnectDisciplineToSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteConnectDisciplineToSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteConnectDisciplineToSportMutation, { data, loading, error }] = useAthleteConnectDisciplineToSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDiscipline: // value for 'idDiscipline'
 *   },
 * });
 */
export function useAthleteConnectDisciplineToSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AthleteConnectDisciplineToSportMutation,
    AthleteConnectDisciplineToSportMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AthleteConnectDisciplineToSportMutation,
    AthleteConnectDisciplineToSportMutationVariables
  >(AthleteConnectDisciplineToSportDocument, baseOptions);
}
export type AthleteConnectDisciplineToSportMutationHookResult = ReturnType<
  typeof useAthleteConnectDisciplineToSportMutation
>;
export type AthleteConnectDisciplineToSportMutationResult = Apollo.MutationResult<
  AthleteConnectDisciplineToSportMutation
>;
export type AthleteConnectDisciplineToSportMutationOptions = Apollo.BaseMutationOptions<
  AthleteConnectDisciplineToSportMutation,
  AthleteConnectDisciplineToSportMutationVariables
>;
export const AthleteDisconnectDisciplineToSportDocument = Apollo.gql`
    mutation AthleteDisconnectDisciplineToSport($idSport: UUID!, $idDiscipline: UUID) {
  updateDirAthleteSport(where: {id: $idSport}, data: {disciplines: {disconnect: [{id: $idDiscipline}]}}) {
    disciplines {
      value: id
      label: name
    }
  }
}
    `;
export type AthleteDisconnectDisciplineToSportMutationFn = Apollo.MutationFunction<
  AthleteDisconnectDisciplineToSportMutation,
  AthleteDisconnectDisciplineToSportMutationVariables
>;

/**
 * __useAthleteDisconnectDisciplineToSportMutation__
 *
 * To run a mutation, you first call `useAthleteDisconnectDisciplineToSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteDisconnectDisciplineToSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteDisconnectDisciplineToSportMutation, { data, loading, error }] = useAthleteDisconnectDisciplineToSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDiscipline: // value for 'idDiscipline'
 *   },
 * });
 */
export function useAthleteDisconnectDisciplineToSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AthleteDisconnectDisciplineToSportMutation,
    AthleteDisconnectDisciplineToSportMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AthleteDisconnectDisciplineToSportMutation,
    AthleteDisconnectDisciplineToSportMutationVariables
  >(AthleteDisconnectDisciplineToSportDocument, baseOptions);
}
export type AthleteDisconnectDisciplineToSportMutationHookResult = ReturnType<
  typeof useAthleteDisconnectDisciplineToSportMutation
>;
export type AthleteDisconnectDisciplineToSportMutationResult = Apollo.MutationResult<
  AthleteDisconnectDisciplineToSportMutation
>;
export type AthleteDisconnectDisciplineToSportMutationOptions = Apollo.BaseMutationOptions<
  AthleteDisconnectDisciplineToSportMutation,
  AthleteDisconnectDisciplineToSportMutationVariables
>;
export const UpdateAthleteSportDocument = Apollo.gql`
    mutation UpdateAthleteSport($id: UUID!, $data: DirAthleteSportUpdateInput!) {
  updateDirAthleteSport(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateAthleteSportMutationFn = Apollo.MutationFunction<
  UpdateAthleteSportMutation,
  UpdateAthleteSportMutationVariables
>;

/**
 * __useUpdateAthleteSportMutation__
 *
 * To run a mutation, you first call `useUpdateAthleteSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAthleteSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAthleteSportMutation, { data, loading, error }] = useUpdateAthleteSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAthleteSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAthleteSportMutation, UpdateAthleteSportMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateAthleteSportMutation, UpdateAthleteSportMutationVariables>(
    UpdateAthleteSportDocument,
    baseOptions,
  );
}
export type UpdateAthleteSportMutationHookResult = ReturnType<typeof useUpdateAthleteSportMutation>;
export type UpdateAthleteSportMutationResult = Apollo.MutationResult<UpdateAthleteSportMutation>;
export type UpdateAthleteSportMutationOptions = Apollo.BaseMutationOptions<
  UpdateAthleteSportMutation,
  UpdateAthleteSportMutationVariables
>;
export const AthleteUpdateAmpluaToSportDocument = Apollo.gql`
    mutation AthleteUpdateAmpluaToSport($idSport: UUID!, $amplua: String) {
  updateDirAthleteSport(where: {id: $idSport}, data: {role: $amplua}) {
    id
  }
}
    `;
export type AthleteUpdateAmpluaToSportMutationFn = Apollo.MutationFunction<
  AthleteUpdateAmpluaToSportMutation,
  AthleteUpdateAmpluaToSportMutationVariables
>;

/**
 * __useAthleteUpdateAmpluaToSportMutation__
 *
 * To run a mutation, you first call `useAthleteUpdateAmpluaToSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAthleteUpdateAmpluaToSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [athleteUpdateAmpluaToSportMutation, { data, loading, error }] = useAthleteUpdateAmpluaToSportMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      amplua: // value for 'amplua'
 *   },
 * });
 */
export function useAthleteUpdateAmpluaToSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AthleteUpdateAmpluaToSportMutation,
    AthleteUpdateAmpluaToSportMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AthleteUpdateAmpluaToSportMutation, AthleteUpdateAmpluaToSportMutationVariables>(
    AthleteUpdateAmpluaToSportDocument,
    baseOptions,
  );
}
export type AthleteUpdateAmpluaToSportMutationHookResult = ReturnType<typeof useAthleteUpdateAmpluaToSportMutation>;
export type AthleteUpdateAmpluaToSportMutationResult = Apollo.MutationResult<AthleteUpdateAmpluaToSportMutation>;
export type AthleteUpdateAmpluaToSportMutationOptions = Apollo.BaseMutationOptions<
  AthleteUpdateAmpluaToSportMutation,
  AthleteUpdateAmpluaToSportMutationVariables
>;
export const AddTypeSportsToAthleteDocument = Apollo.gql`
    mutation AddTypeSportsToAthlete($id: UUID!, $sportId: UUID!) {
  updateDirAthlete(where: {id: $id}, data: {sports: {create: {dirSport: {connect: {id: $sportId}}}}}) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;
export type AddTypeSportsToAthleteMutationFn = Apollo.MutationFunction<
  AddTypeSportsToAthleteMutation,
  AddTypeSportsToAthleteMutationVariables
>;

/**
 * __useAddTypeSportsToAthleteMutation__
 *
 * To run a mutation, you first call `useAddTypeSportsToAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTypeSportsToAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTypeSportsToAthleteMutation, { data, loading, error }] = useAddTypeSportsToAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useAddTypeSportsToAthleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTypeSportsToAthleteMutation,
    AddTypeSportsToAthleteMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AddTypeSportsToAthleteMutation, AddTypeSportsToAthleteMutationVariables>(
    AddTypeSportsToAthleteDocument,
    baseOptions,
  );
}
export type AddTypeSportsToAthleteMutationHookResult = ReturnType<typeof useAddTypeSportsToAthleteMutation>;
export type AddTypeSportsToAthleteMutationResult = Apollo.MutationResult<AddTypeSportsToAthleteMutation>;
export type AddTypeSportsToAthleteMutationOptions = Apollo.BaseMutationOptions<
  AddTypeSportsToAthleteMutation,
  AddTypeSportsToAthleteMutationVariables
>;
export const TypeSportAthleteArchiveDocument = Apollo.gql`
    mutation TypeSportAthleteArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirAthleteSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportAthleteArchiveMutationFn = Apollo.MutationFunction<
  TypeSportAthleteArchiveMutation,
  TypeSportAthleteArchiveMutationVariables
>;

/**
 * __useTypeSportAthleteArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportAthleteArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAthleteArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAthleteArchiveMutation, { data, loading, error }] = useTypeSportAthleteArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportAthleteArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportAthleteArchiveMutation,
    TypeSportAthleteArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportAthleteArchiveMutation, TypeSportAthleteArchiveMutationVariables>(
    TypeSportAthleteArchiveDocument,
    baseOptions,
  );
}
export type TypeSportAthleteArchiveMutationHookResult = ReturnType<typeof useTypeSportAthleteArchiveMutation>;
export type TypeSportAthleteArchiveMutationResult = Apollo.MutationResult<TypeSportAthleteArchiveMutation>;
export type TypeSportAthleteArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportAthleteArchiveMutation,
  TypeSportAthleteArchiveMutationVariables
>;
export const TypeSportAthleteUnArchiveDocument = Apollo.gql`
    mutation TypeSportAthleteUnArchive($id: UUID!) {
  updateDirAthleteSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportAthleteUnArchiveMutationFn = Apollo.MutationFunction<
  TypeSportAthleteUnArchiveMutation,
  TypeSportAthleteUnArchiveMutationVariables
>;

/**
 * __useTypeSportAthleteUnArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportAthleteUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAthleteUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAthleteUnArchiveMutation, { data, loading, error }] = useTypeSportAthleteUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportAthleteUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportAthleteUnArchiveMutation,
    TypeSportAthleteUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportAthleteUnArchiveMutation, TypeSportAthleteUnArchiveMutationVariables>(
    TypeSportAthleteUnArchiveDocument,
    baseOptions,
  );
}
export type TypeSportAthleteUnArchiveMutationHookResult = ReturnType<typeof useTypeSportAthleteUnArchiveMutation>;
export type TypeSportAthleteUnArchiveMutationResult = Apollo.MutationResult<TypeSportAthleteUnArchiveMutation>;
export type TypeSportAthleteUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportAthleteUnArchiveMutation,
  TypeSportAthleteUnArchiveMutationVariables
>;
export const AddAthleteOrganizationDocument = Apollo.gql`
    mutation AddAthleteOrganization($idSport: UUID!, $idDirOrganization: UUID!, $idDirTrainer: DirTrainerCreateOneInput, $clsTrainingStage: UUID!, $trainingStart: DateTime!, $isSelfTraining: Boolean) {
  updateDirAthleteSport(where: {id: $idSport}, data: {organizations: {create: {dirOrganization: {connect: {id: $idDirOrganization}}, clsTrainingStage: {connect: {id: $clsTrainingStage}}, dirTrainer: $idDirTrainer, isSelfTraining: $isSelfTraining, trainingStart: $trainingStart}}}) {
    id
  }
}
    `;
export type AddAthleteOrganizationMutationFn = Apollo.MutationFunction<
  AddAthleteOrganizationMutation,
  AddAthleteOrganizationMutationVariables
>;

/**
 * __useAddAthleteOrganizationMutation__
 *
 * To run a mutation, you first call `useAddAthleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteOrganizationMutation, { data, loading, error }] = useAddAthleteOrganizationMutation({
 *   variables: {
 *      idSport: // value for 'idSport'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      idDirTrainer: // value for 'idDirTrainer'
 *      clsTrainingStage: // value for 'clsTrainingStage'
 *      trainingStart: // value for 'trainingStart'
 *      isSelfTraining: // value for 'isSelfTraining'
 *   },
 * });
 */
export function useAddAthleteOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddAthleteOrganizationMutation,
    AddAthleteOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AddAthleteOrganizationMutation, AddAthleteOrganizationMutationVariables>(
    AddAthleteOrganizationDocument,
    baseOptions,
  );
}
export type AddAthleteOrganizationMutationHookResult = ReturnType<typeof useAddAthleteOrganizationMutation>;
export type AddAthleteOrganizationMutationResult = Apollo.MutationResult<AddAthleteOrganizationMutation>;
export type AddAthleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  AddAthleteOrganizationMutation,
  AddAthleteOrganizationMutationVariables
>;
export const UpdateAthleteOrganizationDocument = Apollo.gql`
    mutation UpdateAthleteOrganization($id: UUID!, $idDirOrganization: UUID, $idDirTrainer: DirTrainerUpdateOneInput, $clsTrainingStage: UUID, $trainingStart: DateTime, $exclusionDate: DateTime, $isSelfTraining: Boolean) {
  updateDirAthleteSportOrganization(where: {id: $id}, data: {dirOrganization: {connect: {id: $idDirOrganization}}, dirTrainer: $idDirTrainer, clsTrainingStage: {connect: {id: $clsTrainingStage}}, isSelfTraining: $isSelfTraining, trainingStart: $trainingStart, exclusionDate: $exclusionDate}) {
    id
  }
}
    `;
export type UpdateAthleteOrganizationMutationFn = Apollo.MutationFunction<
  UpdateAthleteOrganizationMutation,
  UpdateAthleteOrganizationMutationVariables
>;

/**
 * __useUpdateAthleteOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateAthleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAthleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAthleteOrganizationMutation, { data, loading, error }] = useUpdateAthleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      idDirTrainer: // value for 'idDirTrainer'
 *      clsTrainingStage: // value for 'clsTrainingStage'
 *      trainingStart: // value for 'trainingStart'
 *      exclusionDate: // value for 'exclusionDate'
 *      isSelfTraining: // value for 'isSelfTraining'
 *   },
 * });
 */
export function useUpdateAthleteOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAthleteOrganizationMutation,
    UpdateAthleteOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateAthleteOrganizationMutation, UpdateAthleteOrganizationMutationVariables>(
    UpdateAthleteOrganizationDocument,
    baseOptions,
  );
}
export type UpdateAthleteOrganizationMutationHookResult = ReturnType<typeof useUpdateAthleteOrganizationMutation>;
export type UpdateAthleteOrganizationMutationResult = Apollo.MutationResult<UpdateAthleteOrganizationMutation>;
export type UpdateAthleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAthleteOrganizationMutation,
  UpdateAthleteOrganizationMutationVariables
>;
export const DeleteAthleteOrganizationDocument = Apollo.gql`
    mutation DeleteAthleteOrganization($id: UUID!, $exclusionDate: DateTime!) {
  updateDirAthleteSportOrganization(where: {id: $id}, data: {exclusionDate: $exclusionDate}) {
    id
  }
}
    `;
export type DeleteAthleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteAthleteOrganizationMutation,
  DeleteAthleteOrganizationMutationVariables
>;

/**
 * __useDeleteAthleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteAthleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAthleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAthleteOrganizationMutation, { data, loading, error }] = useDeleteAthleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exclusionDate: // value for 'exclusionDate'
 *   },
 * });
 */
export function useDeleteAthleteOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAthleteOrganizationMutation,
    DeleteAthleteOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<DeleteAthleteOrganizationMutation, DeleteAthleteOrganizationMutationVariables>(
    DeleteAthleteOrganizationDocument,
    baseOptions,
  );
}
export type DeleteAthleteOrganizationMutationHookResult = ReturnType<typeof useDeleteAthleteOrganizationMutation>;
export type DeleteAthleteOrganizationMutationResult = Apollo.MutationResult<DeleteAthleteOrganizationMutation>;
export type DeleteAthleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteAthleteOrganizationMutation,
  DeleteAthleteOrganizationMutationVariables
>;
export const AthletesDocument = Apollo.gql`
    query Athletes($first: Int!, $skip: Int!, $filter: DirAthleteWhereInput) {
  dirAthletes(first: $first, skip: $skip, where: $filter) {
    ...AthleteListFR
  }
  count: dirAthletesCount(where: $filter)
}
    ${AthleteListFrFragmentDoc}`;

/**
 * __useAthletesQuery__
 *
 * To run a query within a React component, call `useAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesQuery, AthletesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<AthletesQuery, AthletesQueryVariables>(AthletesDocument, baseOptions);
}
export function useAthletesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesQuery, AthletesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<AthletesQuery, AthletesQueryVariables>(AthletesDocument, baseOptions);
}
export type AthletesQueryHookResult = ReturnType<typeof useAthletesQuery>;
export type AthletesLazyQueryHookResult = ReturnType<typeof useAthletesLazyQuery>;
export type AthletesQueryResult = Apollo.QueryResult<AthletesQuery, AthletesQueryVariables>;
export const AthletesCountDocument = Apollo.gql`
    query AthletesCount($filter: DirAthleteWhereInput) {
  count: dirAthletesCount(where: $filter)
}
    `;

/**
 * __useAthletesCountQuery__
 *
 * To run a query within a React component, call `useAthletesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesCountQuery, AthletesCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<AthletesCountQuery, AthletesCountQueryVariables>(AthletesCountDocument, baseOptions);
}
export function useAthletesCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesCountQuery, AthletesCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<AthletesCountQuery, AthletesCountQueryVariables>(
    AthletesCountDocument,
    baseOptions,
  );
}
export type AthletesCountQueryHookResult = ReturnType<typeof useAthletesCountQuery>;
export type AthletesCountLazyQueryHookResult = ReturnType<typeof useAthletesCountLazyQuery>;
export type AthletesCountQueryResult = Apollo.QueryResult<AthletesCountQuery, AthletesCountQueryVariables>;
export const AthleteDocument = Apollo.gql`
    query Athlete($filter: DirAthleteWhereUniqueInput!) {
  dirAthlete(where: $filter) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;

/**
 * __useAthleteQuery__
 *
 * To run a query within a React component, call `useAthleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteQuery, AthleteQueryVariables>) {
  return ApolloReactHooks.useQuery<AthleteQuery, AthleteQueryVariables>(AthleteDocument, baseOptions);
}
export function useAthleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteQuery, AthleteQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<AthleteQuery, AthleteQueryVariables>(AthleteDocument, baseOptions);
}
export type AthleteQueryHookResult = ReturnType<typeof useAthleteQuery>;
export type AthleteLazyQueryHookResult = ReturnType<typeof useAthleteLazyQuery>;
export type AthleteQueryResult = Apollo.QueryResult<AthleteQuery, AthleteQueryVariables>;
export const AthletesFilteredDocument = Apollo.gql`
    query AthletesFiltered($filter: DirAthleteWhereInput) {
  dirAthletes(where: $filter) {
    ...AthleteFR
  }
}
    ${AthleteFrFragmentDoc}`;

/**
 * __useAthletesFilteredQuery__
 *
 * To run a query within a React component, call `useAthletesFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesFilteredQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAthletesFilteredQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesFilteredQuery, AthletesFilteredQueryVariables>,
) {
  return ApolloReactHooks.useQuery<AthletesFilteredQuery, AthletesFilteredQueryVariables>(
    AthletesFilteredDocument,
    baseOptions,
  );
}
export function useAthletesFilteredLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesFilteredQuery, AthletesFilteredQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<AthletesFilteredQuery, AthletesFilteredQueryVariables>(
    AthletesFilteredDocument,
    baseOptions,
  );
}
export type AthletesFilteredQueryHookResult = ReturnType<typeof useAthletesFilteredQuery>;
export type AthletesFilteredLazyQueryHookResult = ReturnType<typeof useAthletesFilteredLazyQuery>;
export type AthletesFilteredQueryResult = Apollo.QueryResult<AthletesFilteredQuery, AthletesFilteredQueryVariables>;
export const AthleteCompetitionDocument = Apollo.gql`
    query AthleteCompetition($id: UUID!) {
  dirAthlete(where: {id: $id}) {
    athleteId: id
    competetionResult {
      id
      result
      point
      sportingEventProgramType {
        id
        start
        minAge
        maxAge
        event {
          id
          name
          venue
          startDate
          endDate
          clsEventCategories {
            value: id
            label: fullName
          }
        }
        clsAgeGroups {
          value: id
          label: fullName
        }
        discipline {
          value: id
          label: name
        }
      }
    }
  }
}
    `;

/**
 * __useAthleteCompetitionQuery__
 *
 * To run a query within a React component, call `useAthleteCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteCompetitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteCompetitionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>,
) {
  return ApolloReactHooks.useQuery<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>(
    AthleteCompetitionDocument,
    baseOptions,
  );
}
export function useAthleteCompetitionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<AthleteCompetitionQuery, AthleteCompetitionQueryVariables>(
    AthleteCompetitionDocument,
    baseOptions,
  );
}
export type AthleteCompetitionQueryHookResult = ReturnType<typeof useAthleteCompetitionQuery>;
export type AthleteCompetitionLazyQueryHookResult = ReturnType<typeof useAthleteCompetitionLazyQuery>;
export type AthleteCompetitionQueryResult = Apollo.QueryResult<
  AthleteCompetitionQuery,
  AthleteCompetitionQueryVariables
>;
export const AthletesCountByTrainerIdInOrganizationDocument = Apollo.gql`
    query AthletesCountByTrainerIdInOrganization($id: UUID!, $orgID: UUID!) {
  count: dirAthletesCount(where: {sports_some: {organizations_some: {dirOrganization: {id: $orgID}, dirTrainer: {id: $id}, exclusionDate_in: null}}})
}
    `;

/**
 * __useAthletesCountByTrainerIdInOrganizationQuery__
 *
 * To run a query within a React component, call `useAthletesCountByTrainerIdInOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesCountByTrainerIdInOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesCountByTrainerIdInOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      orgID: // value for 'orgID'
 *   },
 * });
 */
export function useAthletesCountByTrainerIdInOrganizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AthletesCountByTrainerIdInOrganizationQuery,
    AthletesCountByTrainerIdInOrganizationQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    AthletesCountByTrainerIdInOrganizationQuery,
    AthletesCountByTrainerIdInOrganizationQueryVariables
  >(AthletesCountByTrainerIdInOrganizationDocument, baseOptions);
}
export function useAthletesCountByTrainerIdInOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AthletesCountByTrainerIdInOrganizationQuery,
    AthletesCountByTrainerIdInOrganizationQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    AthletesCountByTrainerIdInOrganizationQuery,
    AthletesCountByTrainerIdInOrganizationQueryVariables
  >(AthletesCountByTrainerIdInOrganizationDocument, baseOptions);
}
export type AthletesCountByTrainerIdInOrganizationQueryHookResult = ReturnType<
  typeof useAthletesCountByTrainerIdInOrganizationQuery
>;
export type AthletesCountByTrainerIdInOrganizationLazyQueryHookResult = ReturnType<
  typeof useAthletesCountByTrainerIdInOrganizationLazyQuery
>;
export type AthletesCountByTrainerIdInOrganizationQueryResult = Apollo.QueryResult<
  AthletesCountByTrainerIdInOrganizationQuery,
  AthletesCountByTrainerIdInOrganizationQueryVariables
>;
export const CreateCalendarDocument = Apollo.gql`
    mutation CreateCalendar($data: DirCalendarCreateInput!) {
  createDirCalendar(data: $data) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}`;
export type CreateCalendarMutationFn = Apollo.MutationFunction<CreateCalendarMutation, CreateCalendarMutationVariables>;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarMutation, { data, loading, error }] = useCreateCalendarMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCalendarMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCalendarMutation, CreateCalendarMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateCalendarMutation, CreateCalendarMutationVariables>(
    CreateCalendarDocument,
    baseOptions,
  );
}
export type CreateCalendarMutationHookResult = ReturnType<typeof useCreateCalendarMutation>;
export type CreateCalendarMutationResult = Apollo.MutationResult<CreateCalendarMutation>;
export type CreateCalendarMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;
export const UpdateCalendarDocument = Apollo.gql`
    mutation UpdateCalendar($id: UUID!, $data: DirCalendarUpdateInput!) {
  updateDirCalendar(data: $data, where: {id: $id}) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}`;
export type UpdateCalendarMutationFn = Apollo.MutationFunction<UpdateCalendarMutation, UpdateCalendarMutationVariables>;

/**
 * __useUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarMutation, { data, loading, error }] = useUpdateCalendarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCalendarMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarMutation, UpdateCalendarMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateCalendarMutation, UpdateCalendarMutationVariables>(
    UpdateCalendarDocument,
    baseOptions,
  );
}
export type UpdateCalendarMutationHookResult = ReturnType<typeof useUpdateCalendarMutation>;
export type UpdateCalendarMutationResult = Apollo.MutationResult<UpdateCalendarMutation>;
export type UpdateCalendarMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarMutation,
  UpdateCalendarMutationVariables
>;
export const CalendarArchiveDocument = Apollo.gql`
    mutation CalendarArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirCalendar(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type CalendarArchiveMutationFn = Apollo.MutationFunction<
  CalendarArchiveMutation,
  CalendarArchiveMutationVariables
>;

/**
 * __useCalendarArchiveMutation__
 *
 * To run a mutation, you first call `useCalendarArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarArchiveMutation, { data, loading, error }] = useCalendarArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useCalendarArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarArchiveMutation, CalendarArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CalendarArchiveMutation, CalendarArchiveMutationVariables>(
    CalendarArchiveDocument,
    baseOptions,
  );
}
export type CalendarArchiveMutationHookResult = ReturnType<typeof useCalendarArchiveMutation>;
export type CalendarArchiveMutationResult = Apollo.MutationResult<CalendarArchiveMutation>;
export type CalendarArchiveMutationOptions = Apollo.BaseMutationOptions<
  CalendarArchiveMutation,
  CalendarArchiveMutationVariables
>;
export const CalendarUnArchiveDocument = Apollo.gql`
    mutation CalendarUnArchive($id: UUID!) {
  updateDirCalendar(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type CalendarUnArchiveMutationFn = Apollo.MutationFunction<
  CalendarUnArchiveMutation,
  CalendarUnArchiveMutationVariables
>;

/**
 * __useCalendarUnArchiveMutation__
 *
 * To run a mutation, you first call `useCalendarUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarUnArchiveMutation, { data, loading, error }] = useCalendarUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarUnArchiveMutation, CalendarUnArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CalendarUnArchiveMutation, CalendarUnArchiveMutationVariables>(
    CalendarUnArchiveDocument,
    baseOptions,
  );
}
export type CalendarUnArchiveMutationHookResult = ReturnType<typeof useCalendarUnArchiveMutation>;
export type CalendarUnArchiveMutationResult = Apollo.MutationResult<CalendarUnArchiveMutation>;
export type CalendarUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  CalendarUnArchiveMutation,
  CalendarUnArchiveMutationVariables
>;
export const CalendarsDocument = Apollo.gql`
    query Calendars($first: Int!, $skip: Int!, $filter: DirCalendarWhereInput) {
  dirCalendars(first: $first, skip: $skip, where: $filter) {
    ...Calendar
  }
  count: dirCalendarsCount(where: $filter)
}
    ${CalendarFragmentDoc}`;

/**
 * __useCalendarsQuery__
 *
 * To run a query within a React component, call `useCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCalendarsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarsQuery, CalendarsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<CalendarsQuery, CalendarsQueryVariables>(CalendarsDocument, baseOptions);
}
export function useCalendarsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarsQuery, CalendarsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<CalendarsQuery, CalendarsQueryVariables>(CalendarsDocument, baseOptions);
}
export type CalendarsQueryHookResult = ReturnType<typeof useCalendarsQuery>;
export type CalendarsLazyQueryHookResult = ReturnType<typeof useCalendarsLazyQuery>;
export type CalendarsQueryResult = Apollo.QueryResult<CalendarsQuery, CalendarsQueryVariables>;
export const CalendarDocument = Apollo.gql`
    query Calendar($id: UUID!) {
  dirCalendar(where: {id: $id}) {
    ...Calendar
  }
}
    ${CalendarFragmentDoc}`;

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarQuery, CalendarQueryVariables>,
) {
  return ApolloReactHooks.useQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, baseOptions);
}
export function useCalendarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarQuery, CalendarQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, baseOptions);
}
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>;
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>;
export type CalendarQueryResult = Apollo.QueryResult<CalendarQuery, CalendarQueryVariables>;
export const FindCalendarsDocument = Apollo.gql`
    query FindCalendars($search: String!) {
  dirCalendars(where: {fullName_contains: $search}, first: 10) {
    value: id
    label: fullName
    shortName
  }
}
    `;

/**
 * __useFindCalendarsQuery__
 *
 * To run a query within a React component, call `useFindCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCalendarsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFindCalendarsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FindCalendarsQuery, FindCalendarsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<FindCalendarsQuery, FindCalendarsQueryVariables>(FindCalendarsDocument, baseOptions);
}
export function useFindCalendarsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindCalendarsQuery, FindCalendarsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<FindCalendarsQuery, FindCalendarsQueryVariables>(
    FindCalendarsDocument,
    baseOptions,
  );
}
export type FindCalendarsQueryHookResult = ReturnType<typeof useFindCalendarsQuery>;
export type FindCalendarsLazyQueryHookResult = ReturnType<typeof useFindCalendarsLazyQuery>;
export type FindCalendarsQueryResult = Apollo.QueryResult<FindCalendarsQuery, FindCalendarsQueryVariables>;
export const CreateClassifierDocument = Apollo.gql`
    mutation CreateClassifier($name: String!) {
  createClassifier(data: {name: $name}) {
    ...Classifier
  }
}
    ${ClassifierFragmentDoc}`;
export type CreateClassifierMutationFn = Apollo.MutationFunction<
  CreateClassifierMutation,
  CreateClassifierMutationVariables
>;

/**
 * __useCreateClassifierMutation__
 *
 * To run a mutation, you first call `useCreateClassifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassifierMutation, { data, loading, error }] = useCreateClassifierMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateClassifierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClassifierMutation, CreateClassifierMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateClassifierMutation, CreateClassifierMutationVariables>(
    CreateClassifierDocument,
    baseOptions,
  );
}
export type CreateClassifierMutationHookResult = ReturnType<typeof useCreateClassifierMutation>;
export type CreateClassifierMutationResult = Apollo.MutationResult<CreateClassifierMutation>;
export type CreateClassifierMutationOptions = Apollo.BaseMutationOptions<
  CreateClassifierMutation,
  CreateClassifierMutationVariables
>;
export const UpdateClassifierDocument = Apollo.gql`
    mutation UpdateClassifier($id: UUID!, $name: String!) {
  updateClassifier(where: {id: $id}, data: {name: $name}) {
    ...Classifier
  }
}
    ${ClassifierFragmentDoc}`;
export type UpdateClassifierMutationFn = Apollo.MutationFunction<
  UpdateClassifierMutation,
  UpdateClassifierMutationVariables
>;

/**
 * __useUpdateClassifierMutation__
 *
 * To run a mutation, you first call `useUpdateClassifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassifierMutation, { data, loading, error }] = useUpdateClassifierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateClassifierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClassifierMutation, UpdateClassifierMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateClassifierMutation, UpdateClassifierMutationVariables>(
    UpdateClassifierDocument,
    baseOptions,
  );
}
export type UpdateClassifierMutationHookResult = ReturnType<typeof useUpdateClassifierMutation>;
export type UpdateClassifierMutationResult = Apollo.MutationResult<UpdateClassifierMutation>;
export type UpdateClassifierMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassifierMutation,
  UpdateClassifierMutationVariables
>;
export const CreateClassifierValueDocument = Apollo.gql`
    mutation CreateClassifierValue($id: UUID!, $fullName: String!, $shortName: String!, $order: Int) {
  createClassifierValue(data: {classifier: {connect: {id: $id}}, shortName: $shortName, fullName: $fullName, order: $order}) {
    ...ClsValue
  }
}
    ${ClsValueFragmentDoc}`;
export type CreateClassifierValueMutationFn = Apollo.MutationFunction<
  CreateClassifierValueMutation,
  CreateClassifierValueMutationVariables
>;

/**
 * __useCreateClassifierValueMutation__
 *
 * To run a mutation, you first call `useCreateClassifierValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassifierValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassifierValueMutation, { data, loading, error }] = useCreateClassifierValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateClassifierValueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateClassifierValueMutation,
    CreateClassifierValueMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<CreateClassifierValueMutation, CreateClassifierValueMutationVariables>(
    CreateClassifierValueDocument,
    baseOptions,
  );
}
export type CreateClassifierValueMutationHookResult = ReturnType<typeof useCreateClassifierValueMutation>;
export type CreateClassifierValueMutationResult = Apollo.MutationResult<CreateClassifierValueMutation>;
export type CreateClassifierValueMutationOptions = Apollo.BaseMutationOptions<
  CreateClassifierValueMutation,
  CreateClassifierValueMutationVariables
>;
export const UpdateClassifierValueDocument = Apollo.gql`
    mutation UpdateClassifierValue($id: UUID!, $fullName: String!, $shortName: String!, $order: Int) {
  updateClassifierValue(where: {id: $id}, data: {shortName: $shortName, fullName: $fullName, order: $order}) {
    ...ClsValue
  }
}
    ${ClsValueFragmentDoc}`;
export type UpdateClassifierValueMutationFn = Apollo.MutationFunction<
  UpdateClassifierValueMutation,
  UpdateClassifierValueMutationVariables
>;

/**
 * __useUpdateClassifierValueMutation__
 *
 * To run a mutation, you first call `useUpdateClassifierValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassifierValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassifierValueMutation, { data, loading, error }] = useUpdateClassifierValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateClassifierValueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClassifierValueMutation,
    UpdateClassifierValueMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateClassifierValueMutation, UpdateClassifierValueMutationVariables>(
    UpdateClassifierValueDocument,
    baseOptions,
  );
}
export type UpdateClassifierValueMutationHookResult = ReturnType<typeof useUpdateClassifierValueMutation>;
export type UpdateClassifierValueMutationResult = Apollo.MutationResult<UpdateClassifierValueMutation>;
export type UpdateClassifierValueMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassifierValueMutation,
  UpdateClassifierValueMutationVariables
>;
export const ClassifierArchiveDocument = Apollo.gql`
    mutation ClassifierArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateClassifier(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierArchiveMutationFn = Apollo.MutationFunction<
  ClassifierArchiveMutation,
  ClassifierArchiveMutationVariables
>;

/**
 * __useClassifierArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierArchiveMutation, { data, loading, error }] = useClassifierArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useClassifierArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ClassifierArchiveMutation, ClassifierArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<ClassifierArchiveMutation, ClassifierArchiveMutationVariables>(
    ClassifierArchiveDocument,
    baseOptions,
  );
}
export type ClassifierArchiveMutationHookResult = ReturnType<typeof useClassifierArchiveMutation>;
export type ClassifierArchiveMutationResult = Apollo.MutationResult<ClassifierArchiveMutation>;
export type ClassifierArchiveMutationOptions = Apollo.BaseMutationOptions<
  ClassifierArchiveMutation,
  ClassifierArchiveMutationVariables
>;
export const ClassifierUnArchiveDocument = Apollo.gql`
    mutation ClassifierUnArchive($id: UUID!) {
  updateClassifier(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierUnArchiveMutationFn = Apollo.MutationFunction<
  ClassifierUnArchiveMutation,
  ClassifierUnArchiveMutationVariables
>;

/**
 * __useClassifierUnArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierUnArchiveMutation, { data, loading, error }] = useClassifierUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassifierUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ClassifierUnArchiveMutation, ClassifierUnArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<ClassifierUnArchiveMutation, ClassifierUnArchiveMutationVariables>(
    ClassifierUnArchiveDocument,
    baseOptions,
  );
}
export type ClassifierUnArchiveMutationHookResult = ReturnType<typeof useClassifierUnArchiveMutation>;
export type ClassifierUnArchiveMutationResult = Apollo.MutationResult<ClassifierUnArchiveMutation>;
export type ClassifierUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  ClassifierUnArchiveMutation,
  ClassifierUnArchiveMutationVariables
>;
export const ClassifierValueArchiveDocument = Apollo.gql`
    mutation ClassifierValueArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateClassifierValue(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierValueArchiveMutationFn = Apollo.MutationFunction<
  ClassifierValueArchiveMutation,
  ClassifierValueArchiveMutationVariables
>;

/**
 * __useClassifierValueArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierValueArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierValueArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierValueArchiveMutation, { data, loading, error }] = useClassifierValueArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useClassifierValueArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClassifierValueArchiveMutation,
    ClassifierValueArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<ClassifierValueArchiveMutation, ClassifierValueArchiveMutationVariables>(
    ClassifierValueArchiveDocument,
    baseOptions,
  );
}
export type ClassifierValueArchiveMutationHookResult = ReturnType<typeof useClassifierValueArchiveMutation>;
export type ClassifierValueArchiveMutationResult = Apollo.MutationResult<ClassifierValueArchiveMutation>;
export type ClassifierValueArchiveMutationOptions = Apollo.BaseMutationOptions<
  ClassifierValueArchiveMutation,
  ClassifierValueArchiveMutationVariables
>;
export const ClassifierValueUnArchiveDocument = Apollo.gql`
    mutation ClassifierValueUnArchive($id: UUID!) {
  updateClassifierValue(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ClassifierValueUnArchiveMutationFn = Apollo.MutationFunction<
  ClassifierValueUnArchiveMutation,
  ClassifierValueUnArchiveMutationVariables
>;

/**
 * __useClassifierValueUnArchiveMutation__
 *
 * To run a mutation, you first call `useClassifierValueUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassifierValueUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classifierValueUnArchiveMutation, { data, loading, error }] = useClassifierValueUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassifierValueUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClassifierValueUnArchiveMutation,
    ClassifierValueUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<ClassifierValueUnArchiveMutation, ClassifierValueUnArchiveMutationVariables>(
    ClassifierValueUnArchiveDocument,
    baseOptions,
  );
}
export type ClassifierValueUnArchiveMutationHookResult = ReturnType<typeof useClassifierValueUnArchiveMutation>;
export type ClassifierValueUnArchiveMutationResult = Apollo.MutationResult<ClassifierValueUnArchiveMutation>;
export type ClassifierValueUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  ClassifierValueUnArchiveMutation,
  ClassifierValueUnArchiveMutationVariables
>;
export const UniqueValidationClassifierValueDocument = Apollo.gql`
    query UniqueValidationClassifierValue($fullName: String!) {
  classifierValues(where: {fullName: $fullName}) {
    ...ClsValue
  }
}
    ${ClsValueFragmentDoc}`;

/**
 * __useUniqueValidationClassifierValueQuery__
 *
 * To run a query within a React component, call `useUniqueValidationClassifierValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueValidationClassifierValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueValidationClassifierValueQuery({
 *   variables: {
 *      fullName: // value for 'fullName'
 *   },
 * });
 */
export function useUniqueValidationClassifierValueQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UniqueValidationClassifierValueQuery,
    UniqueValidationClassifierValueQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<UniqueValidationClassifierValueQuery, UniqueValidationClassifierValueQueryVariables>(
    UniqueValidationClassifierValueDocument,
    baseOptions,
  );
}
export function useUniqueValidationClassifierValueLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UniqueValidationClassifierValueQuery,
    UniqueValidationClassifierValueQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    UniqueValidationClassifierValueQuery,
    UniqueValidationClassifierValueQueryVariables
  >(UniqueValidationClassifierValueDocument, baseOptions);
}
export type UniqueValidationClassifierValueQueryHookResult = ReturnType<typeof useUniqueValidationClassifierValueQuery>;
export type UniqueValidationClassifierValueLazyQueryHookResult = ReturnType<
  typeof useUniqueValidationClassifierValueLazyQuery
>;
export type UniqueValidationClassifierValueQueryResult = Apollo.QueryResult<
  UniqueValidationClassifierValueQuery,
  UniqueValidationClassifierValueQueryVariables
>;
export const UniqueValidationClassifierDocument = Apollo.gql`
    query UniqueValidationClassifier($name: String!) {
  classifiers(where: {name: $name}) {
    ...Classifier
  }
}
    ${ClassifierFragmentDoc}`;

/**
 * __useUniqueValidationClassifierQuery__
 *
 * To run a query within a React component, call `useUniqueValidationClassifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueValidationClassifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueValidationClassifierQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUniqueValidationClassifierQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UniqueValidationClassifierQuery,
    UniqueValidationClassifierQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<UniqueValidationClassifierQuery, UniqueValidationClassifierQueryVariables>(
    UniqueValidationClassifierDocument,
    baseOptions,
  );
}
export function useUniqueValidationClassifierLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UniqueValidationClassifierQuery,
    UniqueValidationClassifierQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<UniqueValidationClassifierQuery, UniqueValidationClassifierQueryVariables>(
    UniqueValidationClassifierDocument,
    baseOptions,
  );
}
export type UniqueValidationClassifierQueryHookResult = ReturnType<typeof useUniqueValidationClassifierQuery>;
export type UniqueValidationClassifierLazyQueryHookResult = ReturnType<typeof useUniqueValidationClassifierLazyQuery>;
export type UniqueValidationClassifierQueryResult = Apollo.QueryResult<
  UniqueValidationClassifierQuery,
  UniqueValidationClassifierQueryVariables
>;
export const ClassifierByIdDocument = Apollo.gql`
    query ClassifierById($id: UUID!) {
  classifier(where: {id: $id}) {
    ...Classifier
    values(orderBy: fullName_ASC) {
      ...ClsValue
    }
  }
}
    ${ClassifierFragmentDoc}
${ClsValueFragmentDoc}`;

/**
 * __useClassifierByIdQuery__
 *
 * To run a query within a React component, call `useClassifierByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassifierByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassifierByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassifierByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClassifierByIdQuery, ClassifierByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClassifierByIdQuery, ClassifierByIdQueryVariables>(
    ClassifierByIdDocument,
    baseOptions,
  );
}
export function useClassifierByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClassifierByIdQuery, ClassifierByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClassifierByIdQuery, ClassifierByIdQueryVariables>(
    ClassifierByIdDocument,
    baseOptions,
  );
}
export type ClassifierByIdQueryHookResult = ReturnType<typeof useClassifierByIdQuery>;
export type ClassifierByIdLazyQueryHookResult = ReturnType<typeof useClassifierByIdLazyQuery>;
export type ClassifierByIdQueryResult = Apollo.QueryResult<ClassifierByIdQuery, ClassifierByIdQueryVariables>;
export const ClassifiersDocument = Apollo.gql`
    query Classifiers($first: Int!, $skip: Int!, $filter: ClassifierWhereInput) {
  classifiers(orderBy: name_ASC, first: $first, skip: $skip, where: $filter) {
    ...Classifier
  }
  count: classifiersCount
}
    ${ClassifierFragmentDoc}`;

/**
 * __useClassifiersQuery__
 *
 * To run a query within a React component, call `useClassifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassifiersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClassifiersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClassifiersQuery, ClassifiersQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClassifiersQuery, ClassifiersQueryVariables>(ClassifiersDocument, baseOptions);
}
export function useClassifiersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClassifiersQuery, ClassifiersQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClassifiersQuery, ClassifiersQueryVariables>(ClassifiersDocument, baseOptions);
}
export type ClassifiersQueryHookResult = ReturnType<typeof useClassifiersQuery>;
export type ClassifiersLazyQueryHookResult = ReturnType<typeof useClassifiersLazyQuery>;
export type ClassifiersQueryResult = Apollo.QueryResult<ClassifiersQuery, ClassifiersQueryVariables>;
export const ClsTrainingStagesDocument = Apollo.gql`
    query clsTrainingStages {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Этапы подготовки"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsTrainingStagesQuery__
 *
 * To run a query within a React component, call `useClsTrainingStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsTrainingStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsTrainingStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsTrainingStagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>(
    ClsTrainingStagesDocument,
    baseOptions,
  );
}
export function useClsTrainingStagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>(
    ClsTrainingStagesDocument,
    baseOptions,
  );
}
export type ClsTrainingStagesQueryHookResult = ReturnType<typeof useClsTrainingStagesQuery>;
export type ClsTrainingStagesLazyQueryHookResult = ReturnType<typeof useClsTrainingStagesLazyQuery>;
export type ClsTrainingStagesQueryResult = Apollo.QueryResult<ClsTrainingStagesQuery, ClsTrainingStagesQueryVariables>;
export const ClsAddressTypesDocument = Apollo.gql`
    query clsAddressTypes {
  addressTypes {
    id
    name
  }
}
    `;

/**
 * __useClsAddressTypesQuery__
 *
 * To run a query within a React component, call `useClsAddressTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsAddressTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsAddressTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsAddressTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>(
    ClsAddressTypesDocument,
    baseOptions,
  );
}
export function useClsAddressTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>(
    ClsAddressTypesDocument,
    baseOptions,
  );
}
export type ClsAddressTypesQueryHookResult = ReturnType<typeof useClsAddressTypesQuery>;
export type ClsAddressTypesLazyQueryHookResult = ReturnType<typeof useClsAddressTypesLazyQuery>;
export type ClsAddressTypesQueryResult = Apollo.QueryResult<ClsAddressTypesQuery, ClsAddressTypesQueryVariables>;
export const ClsAgeGroupsDocument = Apollo.gql`
    query clsAgeGroups {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Половозрастные группы"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsAgeGroupsQuery__
 *
 * To run a query within a React component, call `useClsAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsAgeGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsAgeGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>(ClsAgeGroupsDocument, baseOptions);
}
export function useClsAgeGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>(
    ClsAgeGroupsDocument,
    baseOptions,
  );
}
export type ClsAgeGroupsQueryHookResult = ReturnType<typeof useClsAgeGroupsQuery>;
export type ClsAgeGroupsLazyQueryHookResult = ReturnType<typeof useClsAgeGroupsLazyQuery>;
export type ClsAgeGroupsQueryResult = Apollo.QueryResult<ClsAgeGroupsQuery, ClsAgeGroupsQueryVariables>;
export const ClsArchiveIssuesDocument = Apollo.gql`
    query clsArchiveIssues {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Причина помещения в архив"}}) {
    ...ClassifierValue
  }
}
    ${ClassifierValueFragmentDoc}`;

/**
 * __useClsArchiveIssuesQuery__
 *
 * To run a query within a React component, call `useClsArchiveIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsArchiveIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsArchiveIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsArchiveIssuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>(
    ClsArchiveIssuesDocument,
    baseOptions,
  );
}
export function useClsArchiveIssuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>(
    ClsArchiveIssuesDocument,
    baseOptions,
  );
}
export type ClsArchiveIssuesQueryHookResult = ReturnType<typeof useClsArchiveIssuesQuery>;
export type ClsArchiveIssuesLazyQueryHookResult = ReturnType<typeof useClsArchiveIssuesLazyQuery>;
export type ClsArchiveIssuesQueryResult = Apollo.QueryResult<ClsArchiveIssuesQuery, ClsArchiveIssuesQueryVariables>;
export const ClsDepartmentAffiliationDocument = Apollo.gql`
    query clsDepartmentAffiliation {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Ведомственная принадлежность организации"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsDepartmentAffiliationQuery__
 *
 * To run a query within a React component, call `useClsDepartmentAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsDepartmentAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsDepartmentAffiliationQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsDepartmentAffiliationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClsDepartmentAffiliationQuery,
    ClsDepartmentAffiliationQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<ClsDepartmentAffiliationQuery, ClsDepartmentAffiliationQueryVariables>(
    ClsDepartmentAffiliationDocument,
    baseOptions,
  );
}
export function useClsDepartmentAffiliationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClsDepartmentAffiliationQuery,
    ClsDepartmentAffiliationQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<ClsDepartmentAffiliationQuery, ClsDepartmentAffiliationQueryVariables>(
    ClsDepartmentAffiliationDocument,
    baseOptions,
  );
}
export type ClsDepartmentAffiliationQueryHookResult = ReturnType<typeof useClsDepartmentAffiliationQuery>;
export type ClsDepartmentAffiliationLazyQueryHookResult = ReturnType<typeof useClsDepartmentAffiliationLazyQuery>;
export type ClsDepartmentAffiliationQueryResult = Apollo.QueryResult<
  ClsDepartmentAffiliationQuery,
  ClsDepartmentAffiliationQueryVariables
>;
export const ClsFamilyStatusDocument = Apollo.gql`
    query clsFamilyStatus {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Семейное положение"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsFamilyStatusQuery__
 *
 * To run a query within a React component, call `useClsFamilyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsFamilyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsFamilyStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsFamilyStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>(
    ClsFamilyStatusDocument,
    baseOptions,
  );
}
export function useClsFamilyStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>(
    ClsFamilyStatusDocument,
    baseOptions,
  );
}
export type ClsFamilyStatusQueryHookResult = ReturnType<typeof useClsFamilyStatusQuery>;
export type ClsFamilyStatusLazyQueryHookResult = ReturnType<typeof useClsFamilyStatusLazyQuery>;
export type ClsFamilyStatusQueryResult = Apollo.QueryResult<ClsFamilyStatusQuery, ClsFamilyStatusQueryVariables>;
export const ClsGroupTypeSportsAndDisciplinesDocument = Apollo.gql`
    query clsGroupTypeSportsAndDisciplines {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Группа видов спорта и дисциплин"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsGroupTypeSportsAndDisciplinesQuery__
 *
 * To run a query within a React component, call `useClsGroupTypeSportsAndDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsGroupTypeSportsAndDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsGroupTypeSportsAndDisciplinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsGroupTypeSportsAndDisciplinesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClsGroupTypeSportsAndDisciplinesQuery,
    ClsGroupTypeSportsAndDisciplinesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ClsGroupTypeSportsAndDisciplinesQuery,
    ClsGroupTypeSportsAndDisciplinesQueryVariables
  >(ClsGroupTypeSportsAndDisciplinesDocument, baseOptions);
}
export function useClsGroupTypeSportsAndDisciplinesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClsGroupTypeSportsAndDisciplinesQuery,
    ClsGroupTypeSportsAndDisciplinesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ClsGroupTypeSportsAndDisciplinesQuery,
    ClsGroupTypeSportsAndDisciplinesQueryVariables
  >(ClsGroupTypeSportsAndDisciplinesDocument, baseOptions);
}
export type ClsGroupTypeSportsAndDisciplinesQueryHookResult = ReturnType<
  typeof useClsGroupTypeSportsAndDisciplinesQuery
>;
export type ClsGroupTypeSportsAndDisciplinesLazyQueryHookResult = ReturnType<
  typeof useClsGroupTypeSportsAndDisciplinesLazyQuery
>;
export type ClsGroupTypeSportsAndDisciplinesQueryResult = Apollo.QueryResult<
  ClsGroupTypeSportsAndDisciplinesQuery,
  ClsGroupTypeSportsAndDisciplinesQueryVariables
>;
export const ClsOrganizationCategoriesDocument = Apollo.gql`
    query clsOrganizationCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Категория организации"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsOrganizationCategoriesQuery__
 *
 * To run a query within a React component, call `useClsOrganizationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsOrganizationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsOrganizationCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsOrganizationCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClsOrganizationCategoriesQuery,
    ClsOrganizationCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<ClsOrganizationCategoriesQuery, ClsOrganizationCategoriesQueryVariables>(
    ClsOrganizationCategoriesDocument,
    baseOptions,
  );
}
export function useClsOrganizationCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClsOrganizationCategoriesQuery,
    ClsOrganizationCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<ClsOrganizationCategoriesQuery, ClsOrganizationCategoriesQueryVariables>(
    ClsOrganizationCategoriesDocument,
    baseOptions,
  );
}
export type ClsOrganizationCategoriesQueryHookResult = ReturnType<typeof useClsOrganizationCategoriesQuery>;
export type ClsOrganizationCategoriesLazyQueryHookResult = ReturnType<typeof useClsOrganizationCategoriesLazyQuery>;
export type ClsOrganizationCategoriesQueryResult = Apollo.QueryResult<
  ClsOrganizationCategoriesQuery,
  ClsOrganizationCategoriesQueryVariables
>;
export const ClsPositionsDocument = Apollo.gql`
    query clsPositions {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Должность"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsPositionsQuery__
 *
 * To run a query within a React component, call `useClsPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsPositionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsPositionsQuery, ClsPositionsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsPositionsQuery, ClsPositionsQueryVariables>(ClsPositionsDocument, baseOptions);
}
export function useClsPositionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsPositionsQuery, ClsPositionsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsPositionsQuery, ClsPositionsQueryVariables>(
    ClsPositionsDocument,
    baseOptions,
  );
}
export type ClsPositionsQueryHookResult = ReturnType<typeof useClsPositionsQuery>;
export type ClsPositionsLazyQueryHookResult = ReturnType<typeof useClsPositionsLazyQuery>;
export type ClsPositionsQueryResult = Apollo.QueryResult<ClsPositionsQuery, ClsPositionsQueryVariables>;
export const ClsRanksAndTitlesDocument = Apollo.gql`
    query clsRanksAndTitles {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Разряды и звания"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsRanksAndTitlesQuery__
 *
 * To run a query within a React component, call `useClsRanksAndTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsRanksAndTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsRanksAndTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsRanksAndTitlesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>(
    ClsRanksAndTitlesDocument,
    baseOptions,
  );
}
export function useClsRanksAndTitlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>(
    ClsRanksAndTitlesDocument,
    baseOptions,
  );
}
export type ClsRanksAndTitlesQueryHookResult = ReturnType<typeof useClsRanksAndTitlesQuery>;
export type ClsRanksAndTitlesLazyQueryHookResult = ReturnType<typeof useClsRanksAndTitlesLazyQuery>;
export type ClsRanksAndTitlesQueryResult = Apollo.QueryResult<ClsRanksAndTitlesQuery, ClsRanksAndTitlesQueryVariables>;
export const ClsRefereeCategoriesDocument = Apollo.gql`
    query clsRefereeCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {id: "4db3912a-bf7f-4496-8d63-a1499933ab1c"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsRefereeCategoriesQuery__
 *
 * To run a query within a React component, call `useClsRefereeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsRefereeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsRefereeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsRefereeCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>(
    ClsRefereeCategoriesDocument,
    baseOptions,
  );
}
export function useClsRefereeCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsRefereeCategoriesQuery, ClsRefereeCategoriesQueryVariables>(
    ClsRefereeCategoriesDocument,
    baseOptions,
  );
}
export type ClsRefereeCategoriesQueryHookResult = ReturnType<typeof useClsRefereeCategoriesQuery>;
export type ClsRefereeCategoriesLazyQueryHookResult = ReturnType<typeof useClsRefereeCategoriesLazyQuery>;
export type ClsRefereeCategoriesQueryResult = Apollo.QueryResult<
  ClsRefereeCategoriesQuery,
  ClsRefereeCategoriesQueryVariables
>;
export const ClsRefereesPositionsDocument = Apollo.gql`
    query clsRefereesPositions($filter: ClassifierValueWhereInput) {
  classifierValues(orderBy: fullName_ASC, where: $filter) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsRefereesPositionsQuery__
 *
 * To run a query within a React component, call `useClsRefereesPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsRefereesPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsRefereesPositionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClsRefereesPositionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>(
    ClsRefereesPositionsDocument,
    baseOptions,
  );
}
export function useClsRefereesPositionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsRefereesPositionsQuery, ClsRefereesPositionsQueryVariables>(
    ClsRefereesPositionsDocument,
    baseOptions,
  );
}
export type ClsRefereesPositionsQueryHookResult = ReturnType<typeof useClsRefereesPositionsQuery>;
export type ClsRefereesPositionsLazyQueryHookResult = ReturnType<typeof useClsRefereesPositionsLazyQuery>;
export type ClsRefereesPositionsQueryResult = Apollo.QueryResult<
  ClsRefereesPositionsQuery,
  ClsRefereesPositionsQueryVariables
>;
export const ClsSportingEventsCategoriesDocument = Apollo.gql`
    query clsSportingEventsCategories {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Категория спортмероприятия (ранг)"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsSportingEventsCategoriesQuery__
 *
 * To run a query within a React component, call `useClsSportingEventsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsSportingEventsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsSportingEventsCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsSportingEventsCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClsSportingEventsCategoriesQuery,
    ClsSportingEventsCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<ClsSportingEventsCategoriesQuery, ClsSportingEventsCategoriesQueryVariables>(
    ClsSportingEventsCategoriesDocument,
    baseOptions,
  );
}
export function useClsSportingEventsCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClsSportingEventsCategoriesQuery,
    ClsSportingEventsCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<ClsSportingEventsCategoriesQuery, ClsSportingEventsCategoriesQueryVariables>(
    ClsSportingEventsCategoriesDocument,
    baseOptions,
  );
}
export type ClsSportingEventsCategoriesQueryHookResult = ReturnType<typeof useClsSportingEventsCategoriesQuery>;
export type ClsSportingEventsCategoriesLazyQueryHookResult = ReturnType<typeof useClsSportingEventsCategoriesLazyQuery>;
export type ClsSportingEventsCategoriesQueryResult = Apollo.QueryResult<
  ClsSportingEventsCategoriesQuery,
  ClsSportingEventsCategoriesQueryVariables
>;
export const ClsStagePreparingDocument = Apollo.gql`
    query clsStagePreparing {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Этапы подготовки"}}) {
    label: fullName
    shortLabel: shortName
    value: id
  }
}
    `;

/**
 * __useClsStagePreparingQuery__
 *
 * To run a query within a React component, call `useClsStagePreparingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsStagePreparingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsStagePreparingQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsStagePreparingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>(
    ClsStagePreparingDocument,
    baseOptions,
  );
}
export function useClsStagePreparingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>(
    ClsStagePreparingDocument,
    baseOptions,
  );
}
export type ClsStagePreparingQueryHookResult = ReturnType<typeof useClsStagePreparingQuery>;
export type ClsStagePreparingLazyQueryHookResult = ReturnType<typeof useClsStagePreparingLazyQuery>;
export type ClsStagePreparingQueryResult = Apollo.QueryResult<ClsStagePreparingQuery, ClsStagePreparingQueryVariables>;
export const ClsTypeContactDocument = Apollo.gql`
    query clsTypeContact {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Тип контакта"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useClsTypeContactQuery__
 *
 * To run a query within a React component, call `useClsTypeContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useClsTypeContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClsTypeContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useClsTypeContactQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClsTypeContactQuery, ClsTypeContactQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ClsTypeContactQuery, ClsTypeContactQueryVariables>(
    ClsTypeContactDocument,
    baseOptions,
  );
}
export function useClsTypeContactLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClsTypeContactQuery, ClsTypeContactQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ClsTypeContactQuery, ClsTypeContactQueryVariables>(
    ClsTypeContactDocument,
    baseOptions,
  );
}
export type ClsTypeContactQueryHookResult = ReturnType<typeof useClsTypeContactQuery>;
export type ClsTypeContactLazyQueryHookResult = ReturnType<typeof useClsTypeContactLazyQuery>;
export type ClsTypeContactQueryResult = Apollo.QueryResult<ClsTypeContactQuery, ClsTypeContactQueryVariables>;
export const UploadFileDocument = Apollo.gql`
    mutation UploadFile($file: Upload!) {
  uploadFile(file: $file) {
    id
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
}
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const CreateDocumentDocument = Apollo.gql`
    mutation CreateDocument($data: DirDocumentCreateInput!) {
  createDirDocument(data: $data) {
    id
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
    CreateDocumentDocument,
    baseOptions,
  );
}
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>;
export const UpdateDocumentDocument = Apollo.gql`
    mutation UpdateDocument($data: DirDocumentUpdateInput!, $id: UUID!) {
  updateDirDocument(data: $data, where: {id: $id}) {
    id
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(
    UpdateDocumentDocument,
    baseOptions,
  );
}
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>;
export const DocumentArchiveDocument = Apollo.gql`
    mutation DocumentArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirDocument(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type DocumentArchiveMutationFn = Apollo.MutationFunction<
  DocumentArchiveMutation,
  DocumentArchiveMutationVariables
>;

/**
 * __useDocumentArchiveMutation__
 *
 * To run a mutation, you first call `useDocumentArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentArchiveMutation, { data, loading, error }] = useDocumentArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useDocumentArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentArchiveMutation, DocumentArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<DocumentArchiveMutation, DocumentArchiveMutationVariables>(
    DocumentArchiveDocument,
    baseOptions,
  );
}
export type DocumentArchiveMutationHookResult = ReturnType<typeof useDocumentArchiveMutation>;
export type DocumentArchiveMutationResult = Apollo.MutationResult<DocumentArchiveMutation>;
export type DocumentArchiveMutationOptions = Apollo.BaseMutationOptions<
  DocumentArchiveMutation,
  DocumentArchiveMutationVariables
>;
export const DocumentUnArchiveDocument = Apollo.gql`
    mutation DocumentUnArchive($id: UUID!) {
  updateDirDocument(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type DocumentUnArchiveMutationFn = Apollo.MutationFunction<
  DocumentUnArchiveMutation,
  DocumentUnArchiveMutationVariables
>;

/**
 * __useDocumentUnArchiveMutation__
 *
 * To run a mutation, you first call `useDocumentUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentUnArchiveMutation, { data, loading, error }] = useDocumentUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentUnArchiveMutation, DocumentUnArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<DocumentUnArchiveMutation, DocumentUnArchiveMutationVariables>(
    DocumentUnArchiveDocument,
    baseOptions,
  );
}
export type DocumentUnArchiveMutationHookResult = ReturnType<typeof useDocumentUnArchiveMutation>;
export type DocumentUnArchiveMutationResult = Apollo.MutationResult<DocumentUnArchiveMutation>;
export type DocumentUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  DocumentUnArchiveMutation,
  DocumentUnArchiveMutationVariables
>;
export const DocumentsDocument = Apollo.gql`
    query Documents($first: Int!, $skip: Int!, $filter: DirDocumentWhereInput) {
  dirDocuments(first: $first, skip: $skip, where: $filter) {
    ...Document
  }
  count: dirDocumentsCount(where: $filter)
}
    ${DocumentFragmentDoc}`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDocumentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
}
export function useDocumentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
}
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const DocumentsCountDocument = Apollo.gql`
    query DocumentsCount($filter: DirDocumentWhereInput) {
  count: dirDocumentsCount(where: $filter)
}
    `;

/**
 * __useDocumentsCountQuery__
 *
 * To run a query within a React component, call `useDocumentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDocumentsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentsCountQuery, DocumentsCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<DocumentsCountQuery, DocumentsCountQueryVariables>(
    DocumentsCountDocument,
    baseOptions,
  );
}
export function useDocumentsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentsCountQuery, DocumentsCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<DocumentsCountQuery, DocumentsCountQueryVariables>(
    DocumentsCountDocument,
    baseOptions,
  );
}
export type DocumentsCountQueryHookResult = ReturnType<typeof useDocumentsCountQuery>;
export type DocumentsCountLazyQueryHookResult = ReturnType<typeof useDocumentsCountLazyQuery>;
export type DocumentsCountQueryResult = Apollo.QueryResult<DocumentsCountQuery, DocumentsCountQueryVariables>;
export const DocumentByIdDocument = Apollo.gql`
    query DocumentById($id: UUID!) {
  dirDocument(where: {id: $id}) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useDocumentByIdQuery__
 *
 * To run a query within a React component, call `useDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentByIdQuery, DocumentByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<DocumentByIdQuery, DocumentByIdQueryVariables>(DocumentByIdDocument, baseOptions);
}
export function useDocumentByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentByIdQuery, DocumentByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<DocumentByIdQuery, DocumentByIdQueryVariables>(
    DocumentByIdDocument,
    baseOptions,
  );
}
export type DocumentByIdQueryHookResult = ReturnType<typeof useDocumentByIdQuery>;
export type DocumentByIdLazyQueryHookResult = ReturnType<typeof useDocumentByIdLazyQuery>;
export type DocumentByIdQueryResult = Apollo.QueryResult<DocumentByIdQuery, DocumentByIdQueryVariables>;
export const FindDocumentsByTitleDocument = Apollo.gql`
    query FindDocumentsByTitle($search: String!, $bannedId: [UUID!]) {
  dirDocuments(where: {title_contains: $search, id_not_in: $bannedId}) {
    label: title
    value: id
  }
}
    `;

/**
 * __useFindDocumentsByTitleQuery__
 *
 * To run a query within a React component, call `useFindDocumentsByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDocumentsByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDocumentsByTitleQuery({
 *   variables: {
 *      search: // value for 'search'
 *      bannedId: // value for 'bannedId'
 *   },
 * });
 */
export function useFindDocumentsByTitleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>,
) {
  return ApolloReactHooks.useQuery<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>(
    FindDocumentsByTitleDocument,
    baseOptions,
  );
}
export function useFindDocumentsByTitleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<FindDocumentsByTitleQuery, FindDocumentsByTitleQueryVariables>(
    FindDocumentsByTitleDocument,
    baseOptions,
  );
}
export type FindDocumentsByTitleQueryHookResult = ReturnType<typeof useFindDocumentsByTitleQuery>;
export type FindDocumentsByTitleLazyQueryHookResult = ReturnType<typeof useFindDocumentsByTitleLazyQuery>;
export type FindDocumentsByTitleQueryResult = Apollo.QueryResult<
  FindDocumentsByTitleQuery,
  FindDocumentsByTitleQueryVariables
>;
export const UpdateTrainerDocument = Apollo.gql`
    mutation UpdateTrainer($id: UUID!, $idPerson: UUID!) {
  updateDirTrainer(where: {id: $id}, data: {dirPerson: {connect: {id: $idPerson}}}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;
export type UpdateTrainerMutationFn = Apollo.MutationFunction<UpdateTrainerMutation, UpdateTrainerMutationVariables>;

/**
 * __useUpdateTrainerMutation__
 *
 * To run a mutation, you first call `useUpdateTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainerMutation, { data, loading, error }] = useUpdateTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useUpdateTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainerMutation, UpdateTrainerMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateTrainerMutation, UpdateTrainerMutationVariables>(
    UpdateTrainerDocument,
    baseOptions,
  );
}
export type UpdateTrainerMutationHookResult = ReturnType<typeof useUpdateTrainerMutation>;
export type UpdateTrainerMutationResult = Apollo.MutationResult<UpdateTrainerMutation>;
export type UpdateTrainerMutationOptions = Apollo.BaseMutationOptions<
  UpdateTrainerMutation,
  UpdateTrainerMutationVariables
>;
export const CreateTrainerDocument = Apollo.gql`
    mutation CreateTrainer($idPerson: UUID!) {
  createDirTrainer(data: {dirPerson: {connect: {id: $idPerson}}}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;
export type CreateTrainerMutationFn = Apollo.MutationFunction<CreateTrainerMutation, CreateTrainerMutationVariables>;

/**
 * __useCreateTrainerMutation__
 *
 * To run a mutation, you first call `useCreateTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainerMutation, { data, loading, error }] = useCreateTrainerMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useCreateTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTrainerMutation, CreateTrainerMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateTrainerMutation, CreateTrainerMutationVariables>(
    CreateTrainerDocument,
    baseOptions,
  );
}
export type CreateTrainerMutationHookResult = ReturnType<typeof useCreateTrainerMutation>;
export type CreateTrainerMutationResult = Apollo.MutationResult<CreateTrainerMutation>;
export type CreateTrainerMutationOptions = Apollo.BaseMutationOptions<
  CreateTrainerMutation,
  CreateTrainerMutationVariables
>;
export const AddTypeSportsToTrainerDocument = Apollo.gql`
    mutation AddTypeSportsToTrainer($id: UUID!, $sportId: UUID!) {
  updateDirTrainer(where: {id: $id}, data: {sports: {create: {dirSport: {connect: {id: $sportId}}}}}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;
export type AddTypeSportsToTrainerMutationFn = Apollo.MutationFunction<
  AddTypeSportsToTrainerMutation,
  AddTypeSportsToTrainerMutationVariables
>;

/**
 * __useAddTypeSportsToTrainerMutation__
 *
 * To run a mutation, you first call `useAddTypeSportsToTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTypeSportsToTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTypeSportsToTrainerMutation, { data, loading, error }] = useAddTypeSportsToTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useAddTypeSportsToTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTypeSportsToTrainerMutation,
    AddTypeSportsToTrainerMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AddTypeSportsToTrainerMutation, AddTypeSportsToTrainerMutationVariables>(
    AddTypeSportsToTrainerDocument,
    baseOptions,
  );
}
export type AddTypeSportsToTrainerMutationHookResult = ReturnType<typeof useAddTypeSportsToTrainerMutation>;
export type AddTypeSportsToTrainerMutationResult = Apollo.MutationResult<AddTypeSportsToTrainerMutation>;
export type AddTypeSportsToTrainerMutationOptions = Apollo.BaseMutationOptions<
  AddTypeSportsToTrainerMutation,
  AddTypeSportsToTrainerMutationVariables
>;
export const TypeSportTrainerArchiveDocument = Apollo.gql`
    mutation TypeSportTrainerArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirTrainerSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportTrainerArchiveMutationFn = Apollo.MutationFunction<
  TypeSportTrainerArchiveMutation,
  TypeSportTrainerArchiveMutationVariables
>;

/**
 * __useTypeSportTrainerArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportTrainerArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportTrainerArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportTrainerArchiveMutation, { data, loading, error }] = useTypeSportTrainerArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportTrainerArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportTrainerArchiveMutation,
    TypeSportTrainerArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportTrainerArchiveMutation, TypeSportTrainerArchiveMutationVariables>(
    TypeSportTrainerArchiveDocument,
    baseOptions,
  );
}
export type TypeSportTrainerArchiveMutationHookResult = ReturnType<typeof useTypeSportTrainerArchiveMutation>;
export type TypeSportTrainerArchiveMutationResult = Apollo.MutationResult<TypeSportTrainerArchiveMutation>;
export type TypeSportTrainerArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportTrainerArchiveMutation,
  TypeSportTrainerArchiveMutationVariables
>;
export const TypeSportTrainerUnArchiveDocument = Apollo.gql`
    mutation TypeSportTrainerUnArchive($id: UUID!) {
  updateDirTrainerSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportTrainerUnArchiveMutationFn = Apollo.MutationFunction<
  TypeSportTrainerUnArchiveMutation,
  TypeSportTrainerUnArchiveMutationVariables
>;

/**
 * __useTypeSportTrainerUnArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportTrainerUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportTrainerUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportTrainerUnArchiveMutation, { data, loading, error }] = useTypeSportTrainerUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportTrainerUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportTrainerUnArchiveMutation,
    TypeSportTrainerUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportTrainerUnArchiveMutation, TypeSportTrainerUnArchiveMutationVariables>(
    TypeSportTrainerUnArchiveDocument,
    baseOptions,
  );
}
export type TypeSportTrainerUnArchiveMutationHookResult = ReturnType<typeof useTypeSportTrainerUnArchiveMutation>;
export type TypeSportTrainerUnArchiveMutationResult = Apollo.MutationResult<TypeSportTrainerUnArchiveMutation>;
export type TypeSportTrainerUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportTrainerUnArchiveMutation,
  TypeSportTrainerUnArchiveMutationVariables
>;
export const CreateTrainerOrganizationDocument = Apollo.gql`
    mutation CreateTrainerOrganization($id: UUID!, $clsPosition: UUID!, $idDirOrganization: UUID!, $employmentDate: DateTime!, $dismissalDate: DateTime) {
  updateDirTrainerSport(where: {id: $id}, data: {organizations: {create: {clsPosition: {connect: {id: $clsPosition}}, dirOrganization: {connect: {id: $idDirOrganization}}, employmentDate: $employmentDate, dismissalDate: $dismissalDate}}}) {
    id
  }
}
    `;
export type CreateTrainerOrganizationMutationFn = Apollo.MutationFunction<
  CreateTrainerOrganizationMutation,
  CreateTrainerOrganizationMutationVariables
>;

/**
 * __useCreateTrainerOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateTrainerOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainerOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainerOrganizationMutation, { data, loading, error }] = useCreateTrainerOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clsPosition: // value for 'clsPosition'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      employmentDate: // value for 'employmentDate'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useCreateTrainerOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTrainerOrganizationMutation,
    CreateTrainerOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<CreateTrainerOrganizationMutation, CreateTrainerOrganizationMutationVariables>(
    CreateTrainerOrganizationDocument,
    baseOptions,
  );
}
export type CreateTrainerOrganizationMutationHookResult = ReturnType<typeof useCreateTrainerOrganizationMutation>;
export type CreateTrainerOrganizationMutationResult = Apollo.MutationResult<CreateTrainerOrganizationMutation>;
export type CreateTrainerOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateTrainerOrganizationMutation,
  CreateTrainerOrganizationMutationVariables
>;
export const UpdateTrainerOrganizationDocument = Apollo.gql`
    mutation UpdateTrainerOrganization($id: UUID!, $clsPosition: UUID!, $idDirOrganization: UUID!, $employmentDate: DateTime!, $dismissalDate: DateTime) {
  updateDirTrainerSportOrganization(where: {id: $id}, data: {clsPosition: {connect: {id: $clsPosition}}, dirOrganization: {connect: {id: $idDirOrganization}}, employmentDate: $employmentDate, dismissalDate: $dismissalDate}) {
    id
  }
}
    `;
export type UpdateTrainerOrganizationMutationFn = Apollo.MutationFunction<
  UpdateTrainerOrganizationMutation,
  UpdateTrainerOrganizationMutationVariables
>;

/**
 * __useUpdateTrainerOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateTrainerOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainerOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainerOrganizationMutation, { data, loading, error }] = useUpdateTrainerOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clsPosition: // value for 'clsPosition'
 *      idDirOrganization: // value for 'idDirOrganization'
 *      employmentDate: // value for 'employmentDate'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useUpdateTrainerOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTrainerOrganizationMutation,
    UpdateTrainerOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateTrainerOrganizationMutation, UpdateTrainerOrganizationMutationVariables>(
    UpdateTrainerOrganizationDocument,
    baseOptions,
  );
}
export type UpdateTrainerOrganizationMutationHookResult = ReturnType<typeof useUpdateTrainerOrganizationMutation>;
export type UpdateTrainerOrganizationMutationResult = Apollo.MutationResult<UpdateTrainerOrganizationMutation>;
export type UpdateTrainerOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateTrainerOrganizationMutation,
  UpdateTrainerOrganizationMutationVariables
>;
export const FireTrainerWithAthleteSelfTrainDocument = Apollo.gql`
    mutation fireTrainerWithAthleteSelfTrain($trainerID: UUID!, $organizationID: UUID!, $dismissalDate: DateTime!) {
  trainerDismissalWithAthletesSelfTraining(trainerID: $trainerID, organizationID: $organizationID, dismissalDate: $dismissalDate)
}
    `;
export type FireTrainerWithAthleteSelfTrainMutationFn = Apollo.MutationFunction<
  FireTrainerWithAthleteSelfTrainMutation,
  FireTrainerWithAthleteSelfTrainMutationVariables
>;

/**
 * __useFireTrainerWithAthleteSelfTrainMutation__
 *
 * To run a mutation, you first call `useFireTrainerWithAthleteSelfTrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerWithAthleteSelfTrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerWithAthleteSelfTrainMutation, { data, loading, error }] = useFireTrainerWithAthleteSelfTrainMutation({
 *   variables: {
 *      trainerID: // value for 'trainerID'
 *      organizationID: // value for 'organizationID'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerWithAthleteSelfTrainMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FireTrainerWithAthleteSelfTrainMutation,
    FireTrainerWithAthleteSelfTrainMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    FireTrainerWithAthleteSelfTrainMutation,
    FireTrainerWithAthleteSelfTrainMutationVariables
  >(FireTrainerWithAthleteSelfTrainDocument, baseOptions);
}
export type FireTrainerWithAthleteSelfTrainMutationHookResult = ReturnType<
  typeof useFireTrainerWithAthleteSelfTrainMutation
>;
export type FireTrainerWithAthleteSelfTrainMutationResult = Apollo.MutationResult<
  FireTrainerWithAthleteSelfTrainMutation
>;
export type FireTrainerWithAthleteSelfTrainMutationOptions = Apollo.BaseMutationOptions<
  FireTrainerWithAthleteSelfTrainMutation,
  FireTrainerWithAthleteSelfTrainMutationVariables
>;
export const FireTrainerWithAthleteExpelDocument = Apollo.gql`
    mutation fireTrainerWithAthleteExpel($trainerID: UUID!, $organizationID: UUID!, $dismissalDate: DateTime!) {
  trainerDismissalWithAthletesExpel(trainerID: $trainerID, organizationID: $organizationID, dismissalDate: $dismissalDate)
}
    `;
export type FireTrainerWithAthleteExpelMutationFn = Apollo.MutationFunction<
  FireTrainerWithAthleteExpelMutation,
  FireTrainerWithAthleteExpelMutationVariables
>;

/**
 * __useFireTrainerWithAthleteExpelMutation__
 *
 * To run a mutation, you first call `useFireTrainerWithAthleteExpelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerWithAthleteExpelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerWithAthleteExpelMutation, { data, loading, error }] = useFireTrainerWithAthleteExpelMutation({
 *   variables: {
 *      trainerID: // value for 'trainerID'
 *      organizationID: // value for 'organizationID'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerWithAthleteExpelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FireTrainerWithAthleteExpelMutation,
    FireTrainerWithAthleteExpelMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    FireTrainerWithAthleteExpelMutation,
    FireTrainerWithAthleteExpelMutationVariables
  >(FireTrainerWithAthleteExpelDocument, baseOptions);
}
export type FireTrainerWithAthleteExpelMutationHookResult = ReturnType<typeof useFireTrainerWithAthleteExpelMutation>;
export type FireTrainerWithAthleteExpelMutationResult = Apollo.MutationResult<FireTrainerWithAthleteExpelMutation>;
export type FireTrainerWithAthleteExpelMutationOptions = Apollo.BaseMutationOptions<
  FireTrainerWithAthleteExpelMutation,
  FireTrainerWithAthleteExpelMutationVariables
>;
export const FireTrainerWithAthleteAssignToAnotherTrainerDocument = Apollo.gql`
    mutation fireTrainerWithAthleteAssignToAnotherTrainer($fromTrainerID: UUID!, $toTrainerID: UUID!, $organizationID: UUID!, $dismissalDate: DateTime!) {
  trainerDismissalWithAthletesAssignToAnotherTrainer(fromTrainerID: $fromTrainerID, toTrainerID: $toTrainerID, organizationID: $organizationID, dismissalDate: $dismissalDate)
}
    `;
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationFn = Apollo.MutationFunction<
  FireTrainerWithAthleteAssignToAnotherTrainerMutation,
  FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables
>;

/**
 * __useFireTrainerWithAthleteAssignToAnotherTrainerMutation__
 *
 * To run a mutation, you first call `useFireTrainerWithAthleteAssignToAnotherTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerWithAthleteAssignToAnotherTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerWithAthleteAssignToAnotherTrainerMutation, { data, loading, error }] = useFireTrainerWithAthleteAssignToAnotherTrainerMutation({
 *   variables: {
 *      fromTrainerID: // value for 'fromTrainerID'
 *      toTrainerID: // value for 'toTrainerID'
 *      organizationID: // value for 'organizationID'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerWithAthleteAssignToAnotherTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FireTrainerWithAthleteAssignToAnotherTrainerMutation,
    FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    FireTrainerWithAthleteAssignToAnotherTrainerMutation,
    FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables
  >(FireTrainerWithAthleteAssignToAnotherTrainerDocument, baseOptions);
}
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationHookResult = ReturnType<
  typeof useFireTrainerWithAthleteAssignToAnotherTrainerMutation
>;
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationResult = Apollo.MutationResult<
  FireTrainerWithAthleteAssignToAnotherTrainerMutation
>;
export type FireTrainerWithAthleteAssignToAnotherTrainerMutationOptions = Apollo.BaseMutationOptions<
  FireTrainerWithAthleteAssignToAnotherTrainerMutation,
  FireTrainerWithAthleteAssignToAnotherTrainerMutationVariables
>;
export const FireTrainerDocument = Apollo.gql`
    mutation FireTrainer($id: UUID!, $dismissalDate: DateTime!) {
  updateDirTrainerSportOrganization(where: {id: $id}, data: {dismissalDate: $dismissalDate}) {
    id
  }
}
    `;
export type FireTrainerMutationFn = Apollo.MutationFunction<FireTrainerMutation, FireTrainerMutationVariables>;

/**
 * __useFireTrainerMutation__
 *
 * To run a mutation, you first call `useFireTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTrainerMutation, { data, loading, error }] = useFireTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dismissalDate: // value for 'dismissalDate'
 *   },
 * });
 */
export function useFireTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<FireTrainerMutation, FireTrainerMutationVariables>,
) {
  return ApolloReactHooks.useMutation<FireTrainerMutation, FireTrainerMutationVariables>(
    FireTrainerDocument,
    baseOptions,
  );
}
export type FireTrainerMutationHookResult = ReturnType<typeof useFireTrainerMutation>;
export type FireTrainerMutationResult = Apollo.MutationResult<FireTrainerMutation>;
export type FireTrainerMutationOptions = Apollo.BaseMutationOptions<FireTrainerMutation, FireTrainerMutationVariables>;
export const ForeignCityByIdDocument = Apollo.gql`
    query ForeignCityById($id: UUID!) {
  dirForeignCity(where: {id: $id}) {
    ...ForeignCity
  }
}
    ${ForeignCityFragmentDoc}`;

/**
 * __useForeignCityByIdQuery__
 *
 * To run a query within a React component, call `useForeignCityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useForeignCityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForeignCityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForeignCityByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>(
    ForeignCityByIdDocument,
    baseOptions,
  );
}
export function useForeignCityByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>(
    ForeignCityByIdDocument,
    baseOptions,
  );
}
export type ForeignCityByIdQueryHookResult = ReturnType<typeof useForeignCityByIdQuery>;
export type ForeignCityByIdLazyQueryHookResult = ReturnType<typeof useForeignCityByIdLazyQuery>;
export type ForeignCityByIdQueryResult = Apollo.QueryResult<ForeignCityByIdQuery, ForeignCityByIdQueryVariables>;
export const FederalDistrictsDocument = Apollo.gql`
    query FederalDistricts($first: Int!, $skip: Int!) {
  dirFederalDistricts(first: $first, skip: $skip) {
    ...FederalDistrict
  }
  count: dirFederalDistrictsCount
}
    ${FederalDistrictFragmentDoc}`;

/**
 * __useFederalDistrictsQuery__
 *
 * To run a query within a React component, call `useFederalDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFederalDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFederalDistrictsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFederalDistrictsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FederalDistrictsQuery, FederalDistrictsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<FederalDistrictsQuery, FederalDistrictsQueryVariables>(
    FederalDistrictsDocument,
    baseOptions,
  );
}
export function useFederalDistrictsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FederalDistrictsQuery, FederalDistrictsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<FederalDistrictsQuery, FederalDistrictsQueryVariables>(
    FederalDistrictsDocument,
    baseOptions,
  );
}
export type FederalDistrictsQueryHookResult = ReturnType<typeof useFederalDistrictsQuery>;
export type FederalDistrictsLazyQueryHookResult = ReturnType<typeof useFederalDistrictsLazyQuery>;
export type FederalDistrictsQueryResult = Apollo.QueryResult<FederalDistrictsQuery, FederalDistrictsQueryVariables>;
export const ForeignCityArchiveDocument = Apollo.gql`
    mutation ForeignCityArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirForeignCity(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ForeignCityArchiveMutationFn = Apollo.MutationFunction<
  ForeignCityArchiveMutation,
  ForeignCityArchiveMutationVariables
>;

/**
 * __useForeignCityArchiveMutation__
 *
 * To run a mutation, you first call `useForeignCityArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityArchiveMutation, { data, loading, error }] = useForeignCityArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useForeignCityArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ForeignCityArchiveMutation, ForeignCityArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<ForeignCityArchiveMutation, ForeignCityArchiveMutationVariables>(
    ForeignCityArchiveDocument,
    baseOptions,
  );
}
export type ForeignCityArchiveMutationHookResult = ReturnType<typeof useForeignCityArchiveMutation>;
export type ForeignCityArchiveMutationResult = Apollo.MutationResult<ForeignCityArchiveMutation>;
export type ForeignCityArchiveMutationOptions = Apollo.BaseMutationOptions<
  ForeignCityArchiveMutation,
  ForeignCityArchiveMutationVariables
>;
export const ForeignCityUnArchiveDocument = Apollo.gql`
    mutation ForeignCityUnArchive($id: UUID!) {
  updateDirForeignCity(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type ForeignCityUnArchiveMutationFn = Apollo.MutationFunction<
  ForeignCityUnArchiveMutation,
  ForeignCityUnArchiveMutationVariables
>;

/**
 * __useForeignCityUnArchiveMutation__
 *
 * To run a mutation, you first call `useForeignCityUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityUnArchiveMutation, { data, loading, error }] = useForeignCityUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForeignCityUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ForeignCityUnArchiveMutation,
    ForeignCityUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<ForeignCityUnArchiveMutation, ForeignCityUnArchiveMutationVariables>(
    ForeignCityUnArchiveDocument,
    baseOptions,
  );
}
export type ForeignCityUnArchiveMutationHookResult = ReturnType<typeof useForeignCityUnArchiveMutation>;
export type ForeignCityUnArchiveMutationResult = Apollo.MutationResult<ForeignCityUnArchiveMutation>;
export type ForeignCityUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  ForeignCityUnArchiveMutation,
  ForeignCityUnArchiveMutationVariables
>;
export const ForeignCityUpdateDocument = Apollo.gql`
    mutation ForeignCityUpdate($id: UUID!, $fullName: String!, $shortName: String!, $countryId: DirCountryUpdateOneRequiredWithoutDirForeignCitiesInput) {
  updateDirForeignCity(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, dirCountry: $countryId}) {
    ...ForeignCity
  }
}
    ${ForeignCityFragmentDoc}`;
export type ForeignCityUpdateMutationFn = Apollo.MutationFunction<
  ForeignCityUpdateMutation,
  ForeignCityUpdateMutationVariables
>;

/**
 * __useForeignCityUpdateMutation__
 *
 * To run a mutation, you first call `useForeignCityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityUpdateMutation, { data, loading, error }] = useForeignCityUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useForeignCityUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ForeignCityUpdateMutation, ForeignCityUpdateMutationVariables>,
) {
  return ApolloReactHooks.useMutation<ForeignCityUpdateMutation, ForeignCityUpdateMutationVariables>(
    ForeignCityUpdateDocument,
    baseOptions,
  );
}
export type ForeignCityUpdateMutationHookResult = ReturnType<typeof useForeignCityUpdateMutation>;
export type ForeignCityUpdateMutationResult = Apollo.MutationResult<ForeignCityUpdateMutation>;
export type ForeignCityUpdateMutationOptions = Apollo.BaseMutationOptions<
  ForeignCityUpdateMutation,
  ForeignCityUpdateMutationVariables
>;
export const ForeignCityCreateDocument = Apollo.gql`
    mutation ForeignCityCreate($fullName: String!, $shortName: String!, $countryId: DirCountryCreateOneWithoutDirForeignCitiesInput!) {
  createDirForeignCity(data: {fullName: $fullName, shortName: $shortName, dirCountry: $countryId}) {
    ...ForeignCity
  }
}
    ${ForeignCityFragmentDoc}`;
export type ForeignCityCreateMutationFn = Apollo.MutationFunction<
  ForeignCityCreateMutation,
  ForeignCityCreateMutationVariables
>;

/**
 * __useForeignCityCreateMutation__
 *
 * To run a mutation, you first call `useForeignCityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForeignCityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foreignCityCreateMutation, { data, loading, error }] = useForeignCityCreateMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useForeignCityCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ForeignCityCreateMutation, ForeignCityCreateMutationVariables>,
) {
  return ApolloReactHooks.useMutation<ForeignCityCreateMutation, ForeignCityCreateMutationVariables>(
    ForeignCityCreateDocument,
    baseOptions,
  );
}
export type ForeignCityCreateMutationHookResult = ReturnType<typeof useForeignCityCreateMutation>;
export type ForeignCityCreateMutationResult = Apollo.MutationResult<ForeignCityCreateMutation>;
export type ForeignCityCreateMutationOptions = Apollo.BaseMutationOptions<
  ForeignCityCreateMutation,
  ForeignCityCreateMutationVariables
>;
export const ForeignCitiesDocument = Apollo.gql`
    query ForeignCities($first: Int!, $skip: Int!, $filter: DirForeignCityWhereInput) {
  dirForeignCities(first: $first, skip: $skip, where: $filter) {
    ...ForeignCity
  }
  count: dirForeignCitiesCount
}
    ${ForeignCityFragmentDoc}`;

/**
 * __useForeignCitiesQuery__
 *
 * To run a query within a React component, call `useForeignCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useForeignCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForeignCitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useForeignCitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ForeignCitiesQuery, ForeignCitiesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ForeignCitiesQuery, ForeignCitiesQueryVariables>(ForeignCitiesDocument, baseOptions);
}
export function useForeignCitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForeignCitiesQuery, ForeignCitiesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ForeignCitiesQuery, ForeignCitiesQueryVariables>(
    ForeignCitiesDocument,
    baseOptions,
  );
}
export type ForeignCitiesQueryHookResult = ReturnType<typeof useForeignCitiesQuery>;
export type ForeignCitiesLazyQueryHookResult = ReturnType<typeof useForeignCitiesLazyQuery>;
export type ForeignCitiesQueryResult = Apollo.QueryResult<ForeignCitiesQuery, ForeignCitiesQueryVariables>;
export const ForeignCitiesListDocument = Apollo.gql`
    query ForeignCitiesList($filter: DirForeignCityWhereInput) {
  dirForeignCities(orderBy: fullName_ASC, where: $filter) {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useForeignCitiesListQuery__
 *
 * To run a query within a React component, call `useForeignCitiesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useForeignCitiesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForeignCitiesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useForeignCitiesListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>(
    ForeignCitiesListDocument,
    baseOptions,
  );
}
export function useForeignCitiesListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>(
    ForeignCitiesListDocument,
    baseOptions,
  );
}
export type ForeignCitiesListQueryHookResult = ReturnType<typeof useForeignCitiesListQuery>;
export type ForeignCitiesListLazyQueryHookResult = ReturnType<typeof useForeignCitiesListLazyQuery>;
export type ForeignCitiesListQueryResult = Apollo.QueryResult<ForeignCitiesListQuery, ForeignCitiesListQueryVariables>;
export const CountriesFetchDocument = Apollo.gql`
    query CountriesFetch {
  dirCountries(orderBy: fullName_ASC) {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useCountriesFetchQuery__
 *
 * To run a query within a React component, call `useCountriesFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesFetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesFetchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CountriesFetchQuery, CountriesFetchQueryVariables>,
) {
  return ApolloReactHooks.useQuery<CountriesFetchQuery, CountriesFetchQueryVariables>(
    CountriesFetchDocument,
    baseOptions,
  );
}
export function useCountriesFetchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountriesFetchQuery, CountriesFetchQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<CountriesFetchQuery, CountriesFetchQueryVariables>(
    CountriesFetchDocument,
    baseOptions,
  );
}
export type CountriesFetchQueryHookResult = ReturnType<typeof useCountriesFetchQuery>;
export type CountriesFetchLazyQueryHookResult = ReturnType<typeof useCountriesFetchLazyQuery>;
export type CountriesFetchQueryResult = Apollo.QueryResult<CountriesFetchQuery, CountriesFetchQueryVariables>;
export const FederalDistrictsFetchDocument = Apollo.gql`
    query FederalDistrictsFetch {
  dirFederalDistricts {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useFederalDistrictsFetchQuery__
 *
 * To run a query within a React component, call `useFederalDistrictsFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFederalDistrictsFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFederalDistrictsFetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useFederalDistrictsFetchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>,
) {
  return ApolloReactHooks.useQuery<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>(
    FederalDistrictsFetchDocument,
    baseOptions,
  );
}
export function useFederalDistrictsFetchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<FederalDistrictsFetchQuery, FederalDistrictsFetchQueryVariables>(
    FederalDistrictsFetchDocument,
    baseOptions,
  );
}
export type FederalDistrictsFetchQueryHookResult = ReturnType<typeof useFederalDistrictsFetchQuery>;
export type FederalDistrictsFetchLazyQueryHookResult = ReturnType<typeof useFederalDistrictsFetchLazyQuery>;
export type FederalDistrictsFetchQueryResult = Apollo.QueryResult<
  FederalDistrictsFetchQuery,
  FederalDistrictsFetchQueryVariables
>;
export const UserRoleDocument = Apollo.gql`
    query UserRole {
  userRoles(where: {canSelect: true}) {
    value: id
    name
    label: description
  }
}
    `;

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRoleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>,
) {
  return ApolloReactHooks.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, baseOptions);
}
export function useUserRoleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, baseOptions);
}
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = Apollo.QueryResult<UserRoleQuery, UserRoleQueryVariables>;
export const ValidationTokenDocument = Apollo.gql`
    query ValidationToken {
  classifierValues(orderBy: fullName_ASC, where: {classifier: {name: "Половозрастные группы"}}) {
    label: fullName
    value: id
  }
}
    `;

/**
 * __useValidationTokenQuery__
 *
 * To run a query within a React component, call `useValidationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidationTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useValidationTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ValidationTokenQuery, ValidationTokenQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ValidationTokenQuery, ValidationTokenQueryVariables>(
    ValidationTokenDocument,
    baseOptions,
  );
}
export function useValidationTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidationTokenQuery, ValidationTokenQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ValidationTokenQuery, ValidationTokenQueryVariables>(
    ValidationTokenDocument,
    baseOptions,
  );
}
export type ValidationTokenQueryHookResult = ReturnType<typeof useValidationTokenQuery>;
export type ValidationTokenLazyQueryHookResult = ReturnType<typeof useValidationTokenLazyQuery>;
export type ValidationTokenQueryResult = Apollo.QueryResult<ValidationTokenQuery, ValidationTokenQueryVariables>;
export const UpdateNationalTeamDocument = Apollo.gql`
    mutation UpdateNationalTeam($id: UUID!, $data: DirNationalTeamUpdateInput!) {
  updateDirNationalTeam(data: $data, where: {id: $id}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type UpdateNationalTeamMutationFn = Apollo.MutationFunction<
  UpdateNationalTeamMutation,
  UpdateNationalTeamMutationVariables
>;

/**
 * __useUpdateNationalTeamMutation__
 *
 * To run a mutation, you first call `useUpdateNationalTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNationalTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNationalTeamMutation, { data, loading, error }] = useUpdateNationalTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNationalTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNationalTeamMutation, UpdateNationalTeamMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateNationalTeamMutation, UpdateNationalTeamMutationVariables>(
    UpdateNationalTeamDocument,
    baseOptions,
  );
}
export type UpdateNationalTeamMutationHookResult = ReturnType<typeof useUpdateNationalTeamMutation>;
export type UpdateNationalTeamMutationResult = Apollo.MutationResult<UpdateNationalTeamMutation>;
export type UpdateNationalTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateNationalTeamMutation,
  UpdateNationalTeamMutationVariables
>;
export const NationalTeamArchiveDocument = Apollo.gql`
    mutation NationalTeamArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirNationalTeam(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type NationalTeamArchiveMutationFn = Apollo.MutationFunction<
  NationalTeamArchiveMutation,
  NationalTeamArchiveMutationVariables
>;

/**
 * __useNationalTeamArchiveMutation__
 *
 * To run a mutation, you first call `useNationalTeamArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamArchiveMutation, { data, loading, error }] = useNationalTeamArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useNationalTeamArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<NationalTeamArchiveMutation, NationalTeamArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<NationalTeamArchiveMutation, NationalTeamArchiveMutationVariables>(
    NationalTeamArchiveDocument,
    baseOptions,
  );
}
export type NationalTeamArchiveMutationHookResult = ReturnType<typeof useNationalTeamArchiveMutation>;
export type NationalTeamArchiveMutationResult = Apollo.MutationResult<NationalTeamArchiveMutation>;
export type NationalTeamArchiveMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamArchiveMutation,
  NationalTeamArchiveMutationVariables
>;
export const NationalTeamUnArchiveDocument = Apollo.gql`
    mutation NationalTeamUnArchive($id: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type NationalTeamUnArchiveMutationFn = Apollo.MutationFunction<
  NationalTeamUnArchiveMutation,
  NationalTeamUnArchiveMutationVariables
>;

/**
 * __useNationalTeamUnArchiveMutation__
 *
 * To run a mutation, you first call `useNationalTeamUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamUnArchiveMutation, { data, loading, error }] = useNationalTeamUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNationalTeamUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamUnArchiveMutation,
    NationalTeamUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamUnArchiveMutation, NationalTeamUnArchiveMutationVariables>(
    NationalTeamUnArchiveDocument,
    baseOptions,
  );
}
export type NationalTeamUnArchiveMutationHookResult = ReturnType<typeof useNationalTeamUnArchiveMutation>;
export type NationalTeamUnArchiveMutationResult = Apollo.MutationResult<NationalTeamUnArchiveMutation>;
export type NationalTeamUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamUnArchiveMutation,
  NationalTeamUnArchiveMutationVariables
>;
export const NationalTeamAddTrainerDocument = Apollo.gql`
    mutation NationalTeamAddTrainer($id: UUID!, $trainerId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {trainers: {create: {dirTrainer: {connect: {id: $trainerId}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddTrainerMutationFn = Apollo.MutationFunction<
  NationalTeamAddTrainerMutation,
  NationalTeamAddTrainerMutationVariables
>;

/**
 * __useNationalTeamAddTrainerMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddTrainerMutation, { data, loading, error }] = useNationalTeamAddTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trainerId: // value for 'trainerId'
 *   },
 * });
 */
export function useNationalTeamAddTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamAddTrainerMutation,
    NationalTeamAddTrainerMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamAddTrainerMutation, NationalTeamAddTrainerMutationVariables>(
    NationalTeamAddTrainerDocument,
    baseOptions,
  );
}
export type NationalTeamAddTrainerMutationHookResult = ReturnType<typeof useNationalTeamAddTrainerMutation>;
export type NationalTeamAddTrainerMutationResult = Apollo.MutationResult<NationalTeamAddTrainerMutation>;
export type NationalTeamAddTrainerMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamAddTrainerMutation,
  NationalTeamAddTrainerMutationVariables
>;
export const NationalTeamDeleteTrainerDocument = Apollo.gql`
    mutation NationalTeamDeleteTrainer($id: UUID!, $trainerId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {trainers: {delete: {id: $trainerId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteTrainerMutationFn = Apollo.MutationFunction<
  NationalTeamDeleteTrainerMutation,
  NationalTeamDeleteTrainerMutationVariables
>;

/**
 * __useNationalTeamDeleteTrainerMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteTrainerMutation, { data, loading, error }] = useNationalTeamDeleteTrainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trainerId: // value for 'trainerId'
 *   },
 * });
 */
export function useNationalTeamDeleteTrainerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamDeleteTrainerMutation,
    NationalTeamDeleteTrainerMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamDeleteTrainerMutation, NationalTeamDeleteTrainerMutationVariables>(
    NationalTeamDeleteTrainerDocument,
    baseOptions,
  );
}
export type NationalTeamDeleteTrainerMutationHookResult = ReturnType<typeof useNationalTeamDeleteTrainerMutation>;
export type NationalTeamDeleteTrainerMutationResult = Apollo.MutationResult<NationalTeamDeleteTrainerMutation>;
export type NationalTeamDeleteTrainerMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamDeleteTrainerMutation,
  NationalTeamDeleteTrainerMutationVariables
>;
export const NationalTeamAddSpecialistDocument = Apollo.gql`
    mutation NationalTeamAddSpecialist($id: UUID!, $firstname: String!, $lastname: String!, $patronymic: String!, $birthday: DateTime!) {
  updateDirNationalTeam(where: {id: $id}, data: {specialists: {create: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, birthday: $birthday}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddSpecialistMutationFn = Apollo.MutationFunction<
  NationalTeamAddSpecialistMutation,
  NationalTeamAddSpecialistMutationVariables
>;

/**
 * __useNationalTeamAddSpecialistMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddSpecialistMutation, { data, loading, error }] = useNationalTeamAddSpecialistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useNationalTeamAddSpecialistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamAddSpecialistMutation,
    NationalTeamAddSpecialistMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamAddSpecialistMutation, NationalTeamAddSpecialistMutationVariables>(
    NationalTeamAddSpecialistDocument,
    baseOptions,
  );
}
export type NationalTeamAddSpecialistMutationHookResult = ReturnType<typeof useNationalTeamAddSpecialistMutation>;
export type NationalTeamAddSpecialistMutationResult = Apollo.MutationResult<NationalTeamAddSpecialistMutation>;
export type NationalTeamAddSpecialistMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamAddSpecialistMutation,
  NationalTeamAddSpecialistMutationVariables
>;
export const NationalTeamDeleteSpecialistDocument = Apollo.gql`
    mutation NationalTeamDeleteSpecialist($id: UUID!, $specId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {specialists: {delete: {id: $specId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteSpecialistMutationFn = Apollo.MutationFunction<
  NationalTeamDeleteSpecialistMutation,
  NationalTeamDeleteSpecialistMutationVariables
>;

/**
 * __useNationalTeamDeleteSpecialistMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteSpecialistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteSpecialistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteSpecialistMutation, { data, loading, error }] = useNationalTeamDeleteSpecialistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      specId: // value for 'specId'
 *   },
 * });
 */
export function useNationalTeamDeleteSpecialistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamDeleteSpecialistMutation,
    NationalTeamDeleteSpecialistMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    NationalTeamDeleteSpecialistMutation,
    NationalTeamDeleteSpecialistMutationVariables
  >(NationalTeamDeleteSpecialistDocument, baseOptions);
}
export type NationalTeamDeleteSpecialistMutationHookResult = ReturnType<typeof useNationalTeamDeleteSpecialistMutation>;
export type NationalTeamDeleteSpecialistMutationResult = Apollo.MutationResult<NationalTeamDeleteSpecialistMutation>;
export type NationalTeamDeleteSpecialistMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamDeleteSpecialistMutation,
  NationalTeamDeleteSpecialistMutationVariables
>;
export const NationalTeamAddMedicDocument = Apollo.gql`
    mutation NationalTeamAddMedic($id: UUID!, $firstname: String!, $lastname: String!, $patronymic: String!, $birthday: DateTime!) {
  updateDirNationalTeam(where: {id: $id}, data: {medics: {create: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, birthday: $birthday}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddMedicMutationFn = Apollo.MutationFunction<
  NationalTeamAddMedicMutation,
  NationalTeamAddMedicMutationVariables
>;

/**
 * __useNationalTeamAddMedicMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddMedicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddMedicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddMedicMutation, { data, loading, error }] = useNationalTeamAddMedicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useNationalTeamAddMedicMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamAddMedicMutation,
    NationalTeamAddMedicMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamAddMedicMutation, NationalTeamAddMedicMutationVariables>(
    NationalTeamAddMedicDocument,
    baseOptions,
  );
}
export type NationalTeamAddMedicMutationHookResult = ReturnType<typeof useNationalTeamAddMedicMutation>;
export type NationalTeamAddMedicMutationResult = Apollo.MutationResult<NationalTeamAddMedicMutation>;
export type NationalTeamAddMedicMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamAddMedicMutation,
  NationalTeamAddMedicMutationVariables
>;
export const NationalTeamDeleteMedicDocument = Apollo.gql`
    mutation NationalTeamDeleteMedic($id: UUID!, $medId: UUID!) {
  updateDirNationalTeam(where: {id: $id}, data: {medics: {delete: {id: $medId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteMedicMutationFn = Apollo.MutationFunction<
  NationalTeamDeleteMedicMutation,
  NationalTeamDeleteMedicMutationVariables
>;

/**
 * __useNationalTeamDeleteMedicMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteMedicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteMedicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteMedicMutation, { data, loading, error }] = useNationalTeamDeleteMedicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      medId: // value for 'medId'
 *   },
 * });
 */
export function useNationalTeamDeleteMedicMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamDeleteMedicMutation,
    NationalTeamDeleteMedicMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamDeleteMedicMutation, NationalTeamDeleteMedicMutationVariables>(
    NationalTeamDeleteMedicDocument,
    baseOptions,
  );
}
export type NationalTeamDeleteMedicMutationHookResult = ReturnType<typeof useNationalTeamDeleteMedicMutation>;
export type NationalTeamDeleteMedicMutationResult = Apollo.MutationResult<NationalTeamDeleteMedicMutation>;
export type NationalTeamDeleteMedicMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamDeleteMedicMutation,
  NationalTeamDeleteMedicMutationVariables
>;
export const NationalTeamAddAthleteGroupDocument = Apollo.gql`
    mutation NationalTeamAddAthleteGroup($id: UUID!, $minAge: Int, $maxAge: Int, $ageGroupsId: [ClassifierValueWhereUniqueInput!], $disciplinesId: [DirSportDisciplineWhereUniqueInput!]) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {create: {minAge: $minAge, maxAge: $maxAge, clsAgeGroups: {connect: $ageGroupsId}, disciplines: {connect: $disciplinesId}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddAthleteGroupMutationFn = Apollo.MutationFunction<
  NationalTeamAddAthleteGroupMutation,
  NationalTeamAddAthleteGroupMutationVariables
>;

/**
 * __useNationalTeamAddAthleteGroupMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddAthleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddAthleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddAthleteGroupMutation, { data, loading, error }] = useNationalTeamAddAthleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *      ageGroupsId: // value for 'ageGroupsId'
 *      disciplinesId: // value for 'disciplinesId'
 *   },
 * });
 */
export function useNationalTeamAddAthleteGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamAddAthleteGroupMutation,
    NationalTeamAddAthleteGroupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    NationalTeamAddAthleteGroupMutation,
    NationalTeamAddAthleteGroupMutationVariables
  >(NationalTeamAddAthleteGroupDocument, baseOptions);
}
export type NationalTeamAddAthleteGroupMutationHookResult = ReturnType<typeof useNationalTeamAddAthleteGroupMutation>;
export type NationalTeamAddAthleteGroupMutationResult = Apollo.MutationResult<NationalTeamAddAthleteGroupMutation>;
export type NationalTeamAddAthleteGroupMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamAddAthleteGroupMutation,
  NationalTeamAddAthleteGroupMutationVariables
>;
export const NationalTeamUpdateAthleteGroupDocument = Apollo.gql`
    mutation NationalTeamUpdateAthleteGroup($id: UUID!, $minAge: Int, $maxAge: Int, $groupId: UUID, $ageGroupsId: [ClassifierValueWhereUniqueInput!], $disciplinesId: [DirSportDisciplineWhereUniqueInput!]) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {update: {where: {id: $groupId}, data: {minAge: $minAge, maxAge: $maxAge, clsAgeGroups: {set: $ageGroupsId}, disciplines: {set: $disciplinesId}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamUpdateAthleteGroupMutationFn = Apollo.MutationFunction<
  NationalTeamUpdateAthleteGroupMutation,
  NationalTeamUpdateAthleteGroupMutationVariables
>;

/**
 * __useNationalTeamUpdateAthleteGroupMutation__
 *
 * To run a mutation, you first call `useNationalTeamUpdateAthleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamUpdateAthleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamUpdateAthleteGroupMutation, { data, loading, error }] = useNationalTeamUpdateAthleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *      groupId: // value for 'groupId'
 *      ageGroupsId: // value for 'ageGroupsId'
 *      disciplinesId: // value for 'disciplinesId'
 *   },
 * });
 */
export function useNationalTeamUpdateAthleteGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamUpdateAthleteGroupMutation,
    NationalTeamUpdateAthleteGroupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    NationalTeamUpdateAthleteGroupMutation,
    NationalTeamUpdateAthleteGroupMutationVariables
  >(NationalTeamUpdateAthleteGroupDocument, baseOptions);
}
export type NationalTeamUpdateAthleteGroupMutationHookResult = ReturnType<
  typeof useNationalTeamUpdateAthleteGroupMutation
>;
export type NationalTeamUpdateAthleteGroupMutationResult = Apollo.MutationResult<
  NationalTeamUpdateAthleteGroupMutation
>;
export type NationalTeamUpdateAthleteGroupMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamUpdateAthleteGroupMutation,
  NationalTeamUpdateAthleteGroupMutationVariables
>;
export const NationalTeamDeleteAthleteGroupDocument = Apollo.gql`
    mutation NationalTeamDeleteAthleteGroup($id: UUID!, $groupId: UUID) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {delete: {id: $groupId}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteAthleteGroupMutationFn = Apollo.MutationFunction<
  NationalTeamDeleteAthleteGroupMutation,
  NationalTeamDeleteAthleteGroupMutationVariables
>;

/**
 * __useNationalTeamDeleteAthleteGroupMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteAthleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteAthleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteAthleteGroupMutation, { data, loading, error }] = useNationalTeamDeleteAthleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useNationalTeamDeleteAthleteGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamDeleteAthleteGroupMutation,
    NationalTeamDeleteAthleteGroupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    NationalTeamDeleteAthleteGroupMutation,
    NationalTeamDeleteAthleteGroupMutationVariables
  >(NationalTeamDeleteAthleteGroupDocument, baseOptions);
}
export type NationalTeamDeleteAthleteGroupMutationHookResult = ReturnType<
  typeof useNationalTeamDeleteAthleteGroupMutation
>;
export type NationalTeamDeleteAthleteGroupMutationResult = Apollo.MutationResult<
  NationalTeamDeleteAthleteGroupMutation
>;
export type NationalTeamDeleteAthleteGroupMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamDeleteAthleteGroupMutation,
  NationalTeamDeleteAthleteGroupMutationVariables
>;
export const NationalTeamAddAthleteDocument = Apollo.gql`
    mutation NationalTeamAddAthlete($id: UUID!, $groupId: UUID, $athleteId: UUID, $bestResult: String) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {update: {where: {id: $groupId}, data: {athletes: {create: {dirAthlete: {connect: {id: $athleteId}}, bestResult: $bestResult}}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamAddAthleteMutationFn = Apollo.MutationFunction<
  NationalTeamAddAthleteMutation,
  NationalTeamAddAthleteMutationVariables
>;

/**
 * __useNationalTeamAddAthleteMutation__
 *
 * To run a mutation, you first call `useNationalTeamAddAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamAddAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamAddAthleteMutation, { data, loading, error }] = useNationalTeamAddAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *      athleteId: // value for 'athleteId'
 *      bestResult: // value for 'bestResult'
 *   },
 * });
 */
export function useNationalTeamAddAthleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamAddAthleteMutation,
    NationalTeamAddAthleteMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamAddAthleteMutation, NationalTeamAddAthleteMutationVariables>(
    NationalTeamAddAthleteDocument,
    baseOptions,
  );
}
export type NationalTeamAddAthleteMutationHookResult = ReturnType<typeof useNationalTeamAddAthleteMutation>;
export type NationalTeamAddAthleteMutationResult = Apollo.MutationResult<NationalTeamAddAthleteMutation>;
export type NationalTeamAddAthleteMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamAddAthleteMutation,
  NationalTeamAddAthleteMutationVariables
>;
export const NationalTeamDeleteAthleteDocument = Apollo.gql`
    mutation NationalTeamDeleteAthlete($id: UUID!, $groupId: UUID, $athleteId: UUID) {
  updateDirNationalTeam(where: {id: $id}, data: {athleteGroups: {update: {where: {id: $groupId}, data: {athletes: {delete: {id: $athleteId}}}}}}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;
export type NationalTeamDeleteAthleteMutationFn = Apollo.MutationFunction<
  NationalTeamDeleteAthleteMutation,
  NationalTeamDeleteAthleteMutationVariables
>;

/**
 * __useNationalTeamDeleteAthleteMutation__
 *
 * To run a mutation, you first call `useNationalTeamDeleteAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamDeleteAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nationalTeamDeleteAthleteMutation, { data, loading, error }] = useNationalTeamDeleteAthleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useNationalTeamDeleteAthleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NationalTeamDeleteAthleteMutation,
    NationalTeamDeleteAthleteMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<NationalTeamDeleteAthleteMutation, NationalTeamDeleteAthleteMutationVariables>(
    NationalTeamDeleteAthleteDocument,
    baseOptions,
  );
}
export type NationalTeamDeleteAthleteMutationHookResult = ReturnType<typeof useNationalTeamDeleteAthleteMutation>;
export type NationalTeamDeleteAthleteMutationResult = Apollo.MutationResult<NationalTeamDeleteAthleteMutation>;
export type NationalTeamDeleteAthleteMutationOptions = Apollo.BaseMutationOptions<
  NationalTeamDeleteAthleteMutation,
  NationalTeamDeleteAthleteMutationVariables
>;
export const AthletesInTeamDocument = Apollo.gql`
    query AthletesInTeam($eventId: UUID!, $deniedAthletes: [UUID!], $search: String) {
  dirNationalTeams(where: {dirSportingEvent: {id: $eventId}}) {
    teamId: id
    athleteGroups {
      id
      athletes(where: {dirAthlete: {id_not_in: $deniedAthletes, dirPerson: {lastname_contains: $search}}}) {
        id
        dirAthlete {
          id
          person: dirPerson {
            id
            firstname
            lastname
            patronymic
            registryNumber
            birthday
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAthletesInTeamQuery__
 *
 * To run a query within a React component, call `useAthletesInTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesInTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesInTeamQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      deniedAthletes: // value for 'deniedAthletes'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAthletesInTeamQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesInTeamQuery, AthletesInTeamQueryVariables>,
) {
  return ApolloReactHooks.useQuery<AthletesInTeamQuery, AthletesInTeamQueryVariables>(
    AthletesInTeamDocument,
    baseOptions,
  );
}
export function useAthletesInTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesInTeamQuery, AthletesInTeamQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<AthletesInTeamQuery, AthletesInTeamQueryVariables>(
    AthletesInTeamDocument,
    baseOptions,
  );
}
export type AthletesInTeamQueryHookResult = ReturnType<typeof useAthletesInTeamQuery>;
export type AthletesInTeamLazyQueryHookResult = ReturnType<typeof useAthletesInTeamLazyQuery>;
export type AthletesInTeamQueryResult = Apollo.QueryResult<AthletesInTeamQuery, AthletesInTeamQueryVariables>;
export const NationalTeamsDocument = Apollo.gql`
    query NationalTeams($first: Int!, $skip: Int!, $filter: DirNationalTeamWhereInput) {
  dirNationalTeams(first: $first, skip: $skip, where: $filter) {
    ...NationalTeam
  }
  count: dirNationalTeamsCount(where: $filter)
}
    ${NationalTeamFragmentDoc}`;

/**
 * __useNationalTeamsQuery__
 *
 * To run a query within a React component, call `useNationalTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNationalTeamsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamsQuery, NationalTeamsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<NationalTeamsQuery, NationalTeamsQueryVariables>(NationalTeamsDocument, baseOptions);
}
export function useNationalTeamsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamsQuery, NationalTeamsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<NationalTeamsQuery, NationalTeamsQueryVariables>(
    NationalTeamsDocument,
    baseOptions,
  );
}
export type NationalTeamsQueryHookResult = ReturnType<typeof useNationalTeamsQuery>;
export type NationalTeamsLazyQueryHookResult = ReturnType<typeof useNationalTeamsLazyQuery>;
export type NationalTeamsQueryResult = Apollo.QueryResult<NationalTeamsQuery, NationalTeamsQueryVariables>;
export const NationalTeamsCountDocument = Apollo.gql`
    query NationalTeamsCount($filter: DirNationalTeamWhereInput) {
  count: dirNationalTeamsCount(where: $filter)
}
    `;

/**
 * __useNationalTeamsCountQuery__
 *
 * To run a query within a React component, call `useNationalTeamsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNationalTeamsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>(
    NationalTeamsCountDocument,
    baseOptions,
  );
}
export function useNationalTeamsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<NationalTeamsCountQuery, NationalTeamsCountQueryVariables>(
    NationalTeamsCountDocument,
    baseOptions,
  );
}
export type NationalTeamsCountQueryHookResult = ReturnType<typeof useNationalTeamsCountQuery>;
export type NationalTeamsCountLazyQueryHookResult = ReturnType<typeof useNationalTeamsCountLazyQuery>;
export type NationalTeamsCountQueryResult = Apollo.QueryResult<
  NationalTeamsCountQuery,
  NationalTeamsCountQueryVariables
>;
export const NationalTeamDocument = Apollo.gql`
    query NationalTeam($id: UUID!) {
  dirNationalTeam(where: {id: $id}) {
    ...NationalTeam
  }
}
    ${NationalTeamFragmentDoc}`;

/**
 * __useNationalTeamQuery__
 *
 * To run a query within a React component, call `useNationalTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useNationalTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNationalTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNationalTeamQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NationalTeamQuery, NationalTeamQueryVariables>,
) {
  return ApolloReactHooks.useQuery<NationalTeamQuery, NationalTeamQueryVariables>(NationalTeamDocument, baseOptions);
}
export function useNationalTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NationalTeamQuery, NationalTeamQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<NationalTeamQuery, NationalTeamQueryVariables>(
    NationalTeamDocument,
    baseOptions,
  );
}
export type NationalTeamQueryHookResult = ReturnType<typeof useNationalTeamQuery>;
export type NationalTeamLazyQueryHookResult = ReturnType<typeof useNationalTeamLazyQuery>;
export type NationalTeamQueryResult = Apollo.QueryResult<NationalTeamQuery, NationalTeamQueryVariables>;
export const CreateOrganizationDocument = Apollo.gql`
    mutation CreateOrganization($data: DirOrganizationCreateInput!) {
  createDirOrganization(data: $data) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    baseOptions,
  );
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const SimpleUpdateOrganizationDocument = Apollo.gql`
    mutation SimpleUpdateOrganization($id: UUID!, $data: DirOrganizationUpdateInput!) {
  updateDirOrganization(where: {id: $id}, data: $data) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type SimpleUpdateOrganizationMutationFn = Apollo.MutationFunction<
  SimpleUpdateOrganizationMutation,
  SimpleUpdateOrganizationMutationVariables
>;

/**
 * __useSimpleUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useSimpleUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimpleUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simpleUpdateOrganizationMutation, { data, loading, error }] = useSimpleUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSimpleUpdateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SimpleUpdateOrganizationMutation,
    SimpleUpdateOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<SimpleUpdateOrganizationMutation, SimpleUpdateOrganizationMutationVariables>(
    SimpleUpdateOrganizationDocument,
    baseOptions,
  );
}
export type SimpleUpdateOrganizationMutationHookResult = ReturnType<typeof useSimpleUpdateOrganizationMutation>;
export type SimpleUpdateOrganizationMutationResult = Apollo.MutationResult<SimpleUpdateOrganizationMutation>;
export type SimpleUpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  SimpleUpdateOrganizationMutation,
  SimpleUpdateOrganizationMutationVariables
>;
export const AddEmailToOrganizationDocument = Apollo.gql`
    mutation AddEmailToOrganization($id: UUID!, $email: String!, $type: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {emails: {create: {email: $email, clsContactType: {connect: {id: $type}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type AddEmailToOrganizationMutationFn = Apollo.MutationFunction<
  AddEmailToOrganizationMutation,
  AddEmailToOrganizationMutationVariables
>;

/**
 * __useAddEmailToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddEmailToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailToOrganizationMutation, { data, loading, error }] = useAddEmailToOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddEmailToOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddEmailToOrganizationMutation,
    AddEmailToOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AddEmailToOrganizationMutation, AddEmailToOrganizationMutationVariables>(
    AddEmailToOrganizationDocument,
    baseOptions,
  );
}
export type AddEmailToOrganizationMutationHookResult = ReturnType<typeof useAddEmailToOrganizationMutation>;
export type AddEmailToOrganizationMutationResult = Apollo.MutationResult<AddEmailToOrganizationMutation>;
export type AddEmailToOrganizationMutationOptions = Apollo.BaseMutationOptions<
  AddEmailToOrganizationMutation,
  AddEmailToOrganizationMutationVariables
>;
export const UpdateOrganizationEmailDocument = Apollo.gql`
    mutation UpdateOrganizationEmail($id: UUID!, $email: String!, $type: UUID!, $emailId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {emails: {update: {where: {id: $emailId}, data: {email: $email, clsContactType: {connect: {id: $type}}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationEmailMutationFn = Apollo.MutationFunction<
  UpdateOrganizationEmailMutation,
  UpdateOrganizationEmailMutationVariables
>;

/**
 * __useUpdateOrganizationEmailMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationEmailMutation, { data, loading, error }] = useUpdateOrganizationEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useUpdateOrganizationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationEmailMutation,
    UpdateOrganizationEmailMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateOrganizationEmailMutation, UpdateOrganizationEmailMutationVariables>(
    UpdateOrganizationEmailDocument,
    baseOptions,
  );
}
export type UpdateOrganizationEmailMutationHookResult = ReturnType<typeof useUpdateOrganizationEmailMutation>;
export type UpdateOrganizationEmailMutationResult = Apollo.MutationResult<UpdateOrganizationEmailMutation>;
export type UpdateOrganizationEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationEmailMutation,
  UpdateOrganizationEmailMutationVariables
>;
export const DeleteEmailFromOrganizationDocument = Apollo.gql`
    mutation deleteEmailFromOrganization($id: UUID!, $emailId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {emails: {delete: {id: $emailId}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type DeleteEmailFromOrganizationMutationFn = Apollo.MutationFunction<
  DeleteEmailFromOrganizationMutation,
  DeleteEmailFromOrganizationMutationVariables
>;

/**
 * __useDeleteEmailFromOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteEmailFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailFromOrganizationMutation, { data, loading, error }] = useDeleteEmailFromOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useDeleteEmailFromOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEmailFromOrganizationMutation,
    DeleteEmailFromOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteEmailFromOrganizationMutation,
    DeleteEmailFromOrganizationMutationVariables
  >(DeleteEmailFromOrganizationDocument, baseOptions);
}
export type DeleteEmailFromOrganizationMutationHookResult = ReturnType<typeof useDeleteEmailFromOrganizationMutation>;
export type DeleteEmailFromOrganizationMutationResult = Apollo.MutationResult<DeleteEmailFromOrganizationMutation>;
export type DeleteEmailFromOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailFromOrganizationMutation,
  DeleteEmailFromOrganizationMutationVariables
>;
export const UpdateOrganizationSitesDocument = Apollo.gql`
    mutation UpdateOrganizationSites($id: UUID!, $sites: [String!]) {
  updateDirOrganization(where: {id: $id}, data: {websites: {set: $sites}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationSitesMutationFn = Apollo.MutationFunction<
  UpdateOrganizationSitesMutation,
  UpdateOrganizationSitesMutationVariables
>;

/**
 * __useUpdateOrganizationSitesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSitesMutation, { data, loading, error }] = useUpdateOrganizationSitesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sites: // value for 'sites'
 *   },
 * });
 */
export function useUpdateOrganizationSitesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationSitesMutation,
    UpdateOrganizationSitesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateOrganizationSitesMutation, UpdateOrganizationSitesMutationVariables>(
    UpdateOrganizationSitesDocument,
    baseOptions,
  );
}
export type UpdateOrganizationSitesMutationHookResult = ReturnType<typeof useUpdateOrganizationSitesMutation>;
export type UpdateOrganizationSitesMutationResult = Apollo.MutationResult<UpdateOrganizationSitesMutation>;
export type UpdateOrganizationSitesMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationSitesMutation,
  UpdateOrganizationSitesMutationVariables
>;
export const AddAddressToOrganizationDocument = Apollo.gql`
    mutation AddAddressToOrganization($id: UUID!, $place: String!, $street: String!, $house: String!, $number: String, $type: Int!) {
  updateDirOrganization(where: {id: $id}, data: {addresses: {create: {place: $place, street: $street, house: $house, number: $number, type: {connect: {id: $type}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type AddAddressToOrganizationMutationFn = Apollo.MutationFunction<
  AddAddressToOrganizationMutation,
  AddAddressToOrganizationMutationVariables
>;

/**
 * __useAddAddressToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddAddressToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressToOrganizationMutation, { data, loading, error }] = useAddAddressToOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      place: // value for 'place'
 *      street: // value for 'street'
 *      house: // value for 'house'
 *      number: // value for 'number'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddAddressToOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddAddressToOrganizationMutation,
    AddAddressToOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AddAddressToOrganizationMutation, AddAddressToOrganizationMutationVariables>(
    AddAddressToOrganizationDocument,
    baseOptions,
  );
}
export type AddAddressToOrganizationMutationHookResult = ReturnType<typeof useAddAddressToOrganizationMutation>;
export type AddAddressToOrganizationMutationResult = Apollo.MutationResult<AddAddressToOrganizationMutation>;
export type AddAddressToOrganizationMutationOptions = Apollo.BaseMutationOptions<
  AddAddressToOrganizationMutation,
  AddAddressToOrganizationMutationVariables
>;
export const UpdateOrganizationAddressDocument = Apollo.gql`
    mutation UpdateOrganizationAddress($id: UUID!, $place: String!, $street: String!, $house: String!, $number: String, $type: Int!, $addressId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {addresses: {update: {where: {id: $addressId}, data: {place: $place, street: $street, house: $house, number: $number, type: {connect: {id: $type}}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationAddressMutationFn = Apollo.MutationFunction<
  UpdateOrganizationAddressMutation,
  UpdateOrganizationAddressMutationVariables
>;

/**
 * __useUpdateOrganizationAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationAddressMutation, { data, loading, error }] = useUpdateOrganizationAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      place: // value for 'place'
 *      street: // value for 'street'
 *      house: // value for 'house'
 *      number: // value for 'number'
 *      type: // value for 'type'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useUpdateOrganizationAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationAddressMutation,
    UpdateOrganizationAddressMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>(
    UpdateOrganizationAddressDocument,
    baseOptions,
  );
}
export type UpdateOrganizationAddressMutationHookResult = ReturnType<typeof useUpdateOrganizationAddressMutation>;
export type UpdateOrganizationAddressMutationResult = Apollo.MutationResult<UpdateOrganizationAddressMutation>;
export type UpdateOrganizationAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationAddressMutation,
  UpdateOrganizationAddressMutationVariables
>;
export const DeleteAddressFromOrganizationDocument = Apollo.gql`
    mutation DeleteAddressFromOrganization($id: UUID!, $addressId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {addresses: {delete: {id: $addressId}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type DeleteAddressFromOrganizationMutationFn = Apollo.MutationFunction<
  DeleteAddressFromOrganizationMutation,
  DeleteAddressFromOrganizationMutationVariables
>;

/**
 * __useDeleteAddressFromOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteAddressFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressFromOrganizationMutation, { data, loading, error }] = useDeleteAddressFromOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteAddressFromOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAddressFromOrganizationMutation,
    DeleteAddressFromOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteAddressFromOrganizationMutation,
    DeleteAddressFromOrganizationMutationVariables
  >(DeleteAddressFromOrganizationDocument, baseOptions);
}
export type DeleteAddressFromOrganizationMutationHookResult = ReturnType<
  typeof useDeleteAddressFromOrganizationMutation
>;
export type DeleteAddressFromOrganizationMutationResult = Apollo.MutationResult<DeleteAddressFromOrganizationMutation>;
export type DeleteAddressFromOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddressFromOrganizationMutation,
  DeleteAddressFromOrganizationMutationVariables
>;
export const AddPhoneToOrganizationDocument = Apollo.gql`
    mutation AddPhoneToOrganization($id: UUID!, $countryCode: String!, $operatorCode: String!, $number: String!, $additionalNumber: String!, $type: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {phones: {create: {countryCode: $countryCode, operatorCode: $operatorCode, number: $number, additionalNumber: $additionalNumber, clsContactType: {connect: {id: $type}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type AddPhoneToOrganizationMutationFn = Apollo.MutationFunction<
  AddPhoneToOrganizationMutation,
  AddPhoneToOrganizationMutationVariables
>;

/**
 * __useAddPhoneToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddPhoneToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhoneToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhoneToOrganizationMutation, { data, loading, error }] = useAddPhoneToOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      countryCode: // value for 'countryCode'
 *      operatorCode: // value for 'operatorCode'
 *      number: // value for 'number'
 *      additionalNumber: // value for 'additionalNumber'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddPhoneToOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPhoneToOrganizationMutation,
    AddPhoneToOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AddPhoneToOrganizationMutation, AddPhoneToOrganizationMutationVariables>(
    AddPhoneToOrganizationDocument,
    baseOptions,
  );
}
export type AddPhoneToOrganizationMutationHookResult = ReturnType<typeof useAddPhoneToOrganizationMutation>;
export type AddPhoneToOrganizationMutationResult = Apollo.MutationResult<AddPhoneToOrganizationMutation>;
export type AddPhoneToOrganizationMutationOptions = Apollo.BaseMutationOptions<
  AddPhoneToOrganizationMutation,
  AddPhoneToOrganizationMutationVariables
>;
export const UpdateOrganizationPhoneDocument = Apollo.gql`
    mutation UpdateOrganizationPhone($id: UUID!, $countryCode: String!, $operatorCode: String!, $number: String!, $additionalNumber: String!, $type: UUID!, $phoneId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {phones: {update: {where: {id: $phoneId}, data: {countryCode: $countryCode, number: $number, operatorCode: $operatorCode, additionalNumber: $additionalNumber, clsContactType: {connect: {id: $type}}}}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationPhoneMutationFn = Apollo.MutationFunction<
  UpdateOrganizationPhoneMutation,
  UpdateOrganizationPhoneMutationVariables
>;

/**
 * __useUpdateOrganizationPhoneMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationPhoneMutation, { data, loading, error }] = useUpdateOrganizationPhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      countryCode: // value for 'countryCode'
 *      operatorCode: // value for 'operatorCode'
 *      number: // value for 'number'
 *      additionalNumber: // value for 'additionalNumber'
 *      type: // value for 'type'
 *      phoneId: // value for 'phoneId'
 *   },
 * });
 */
export function useUpdateOrganizationPhoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationPhoneMutation,
    UpdateOrganizationPhoneMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateOrganizationPhoneMutation, UpdateOrganizationPhoneMutationVariables>(
    UpdateOrganizationPhoneDocument,
    baseOptions,
  );
}
export type UpdateOrganizationPhoneMutationHookResult = ReturnType<typeof useUpdateOrganizationPhoneMutation>;
export type UpdateOrganizationPhoneMutationResult = Apollo.MutationResult<UpdateOrganizationPhoneMutation>;
export type UpdateOrganizationPhoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationPhoneMutation,
  UpdateOrganizationPhoneMutationVariables
>;
export const DeletePhoneFromOrganizationDocument = Apollo.gql`
    mutation DeletePhoneFromOrganization($id: UUID!, $phoneId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {phones: {delete: {id: $phoneId}}}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type DeletePhoneFromOrganizationMutationFn = Apollo.MutationFunction<
  DeletePhoneFromOrganizationMutation,
  DeletePhoneFromOrganizationMutationVariables
>;

/**
 * __useDeletePhoneFromOrganizationMutation__
 *
 * To run a mutation, you first call `useDeletePhoneFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneFromOrganizationMutation, { data, loading, error }] = useDeletePhoneFromOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      phoneId: // value for 'phoneId'
 *   },
 * });
 */
export function useDeletePhoneFromOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePhoneFromOrganizationMutation,
    DeletePhoneFromOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeletePhoneFromOrganizationMutation,
    DeletePhoneFromOrganizationMutationVariables
  >(DeletePhoneFromOrganizationDocument, baseOptions);
}
export type DeletePhoneFromOrganizationMutationHookResult = ReturnType<typeof useDeletePhoneFromOrganizationMutation>;
export type DeletePhoneFromOrganizationMutationResult = Apollo.MutationResult<DeletePhoneFromOrganizationMutation>;
export type DeletePhoneFromOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeletePhoneFromOrganizationMutation,
  DeletePhoneFromOrganizationMutationVariables
>;
export const UpdateOrganizationDocument = Apollo.gql`
    mutation UpdateOrganization($id: UUID!, $data: DirOrganizationUpdateInput!) {
  updateDirOrganization(where: {id: $id}, data: $data) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    baseOptions,
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const UpdateOrganizationLegalInfoDocument = Apollo.gql`
    mutation UpdateOrganizationLegalInfo($id: UUID!, $fullName: String, $shortName: String, $registrationDate: DateTime, $inn: String, $ogrn: String, $kpp: String, $isLegacy: Boolean!, $legalAddress: String, $postcode: String, $headFullName: String, $headPosition: String) {
  updateDirOrganization(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, registrationDate: $registrationDate, inn: $inn, kpp: $kpp, ogrn: $ogrn, isLegalEntity: $isLegacy, legalAddress: $legalAddress, postcode: $postcode, headFullName: $headFullName, headPosition: $headPosition}) {
    ...OrganizationLegalInfoFR
  }
}
    ${OrganizationLegalInfoFrFragmentDoc}`;
export type UpdateOrganizationLegalInfoMutationFn = Apollo.MutationFunction<
  UpdateOrganizationLegalInfoMutation,
  UpdateOrganizationLegalInfoMutationVariables
>;

/**
 * __useUpdateOrganizationLegalInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationLegalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationLegalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationLegalInfoMutation, { data, loading, error }] = useUpdateOrganizationLegalInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      registrationDate: // value for 'registrationDate'
 *      inn: // value for 'inn'
 *      ogrn: // value for 'ogrn'
 *      kpp: // value for 'kpp'
 *      isLegacy: // value for 'isLegacy'
 *      legalAddress: // value for 'legalAddress'
 *      postcode: // value for 'postcode'
 *      headFullName: // value for 'headFullName'
 *      headPosition: // value for 'headPosition'
 *   },
 * });
 */
export function useUpdateOrganizationLegalInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationLegalInfoMutation,
    UpdateOrganizationLegalInfoMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrganizationLegalInfoMutation,
    UpdateOrganizationLegalInfoMutationVariables
  >(UpdateOrganizationLegalInfoDocument, baseOptions);
}
export type UpdateOrganizationLegalInfoMutationHookResult = ReturnType<typeof useUpdateOrganizationLegalInfoMutation>;
export type UpdateOrganizationLegalInfoMutationResult = Apollo.MutationResult<UpdateOrganizationLegalInfoMutation>;
export type UpdateOrganizationLegalInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationLegalInfoMutation,
  UpdateOrganizationLegalInfoMutationVariables
>;
export const OrganizationRemoveTypeSportDocument = Apollo.gql`
    mutation OrganizationRemoveTypeSport($id: UUID!, $idSport: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {dirSports: {disconnect: {id: $idSport}}}) {
    ...OrganizationTypeSportsFR
  }
}
    ${OrganizationTypeSportsFrFragmentDoc}`;
export type OrganizationRemoveTypeSportMutationFn = Apollo.MutationFunction<
  OrganizationRemoveTypeSportMutation,
  OrganizationRemoveTypeSportMutationVariables
>;

/**
 * __useOrganizationRemoveTypeSportMutation__
 *
 * To run a mutation, you first call `useOrganizationRemoveTypeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRemoveTypeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveTypeSportMutation, { data, loading, error }] = useOrganizationRemoveTypeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idSport: // value for 'idSport'
 *   },
 * });
 */
export function useOrganizationRemoveTypeSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationRemoveTypeSportMutation,
    OrganizationRemoveTypeSportMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    OrganizationRemoveTypeSportMutation,
    OrganizationRemoveTypeSportMutationVariables
  >(OrganizationRemoveTypeSportDocument, baseOptions);
}
export type OrganizationRemoveTypeSportMutationHookResult = ReturnType<typeof useOrganizationRemoveTypeSportMutation>;
export type OrganizationRemoveTypeSportMutationResult = Apollo.MutationResult<OrganizationRemoveTypeSportMutation>;
export type OrganizationRemoveTypeSportMutationOptions = Apollo.BaseMutationOptions<
  OrganizationRemoveTypeSportMutation,
  OrganizationRemoveTypeSportMutationVariables
>;
export const OrganizationCreateTypeSportDocument = Apollo.gql`
    mutation OrganizationCreateTypeSport($id: UUID!, $idSport: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {dirSports: {connect: {id: $idSport}}}) {
    ...OrganizationTypeSportsFR
  }
}
    ${OrganizationTypeSportsFrFragmentDoc}`;
export type OrganizationCreateTypeSportMutationFn = Apollo.MutationFunction<
  OrganizationCreateTypeSportMutation,
  OrganizationCreateTypeSportMutationVariables
>;

/**
 * __useOrganizationCreateTypeSportMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateTypeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateTypeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateTypeSportMutation, { data, loading, error }] = useOrganizationCreateTypeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idSport: // value for 'idSport'
 *   },
 * });
 */
export function useOrganizationCreateTypeSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationCreateTypeSportMutation,
    OrganizationCreateTypeSportMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    OrganizationCreateTypeSportMutation,
    OrganizationCreateTypeSportMutationVariables
  >(OrganizationCreateTypeSportDocument, baseOptions);
}
export type OrganizationCreateTypeSportMutationHookResult = ReturnType<typeof useOrganizationCreateTypeSportMutation>;
export type OrganizationCreateTypeSportMutationResult = Apollo.MutationResult<OrganizationCreateTypeSportMutation>;
export type OrganizationCreateTypeSportMutationOptions = Apollo.BaseMutationOptions<
  OrganizationCreateTypeSportMutation,
  OrganizationCreateTypeSportMutationVariables
>;
export const OrganizationRemoveDeparmentAffiliationDocument = Apollo.gql`
    mutation OrganizationRemoveDeparmentAffiliation($id: UUID!, $idItem: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {clsDepartmentalAffiliations: {disconnect: {id: $idItem}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type OrganizationRemoveDeparmentAffiliationMutationFn = Apollo.MutationFunction<
  OrganizationRemoveDeparmentAffiliationMutation,
  OrganizationRemoveDeparmentAffiliationMutationVariables
>;

/**
 * __useOrganizationRemoveDeparmentAffiliationMutation__
 *
 * To run a mutation, you first call `useOrganizationRemoveDeparmentAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRemoveDeparmentAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveDeparmentAffiliationMutation, { data, loading, error }] = useOrganizationRemoveDeparmentAffiliationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useOrganizationRemoveDeparmentAffiliationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationRemoveDeparmentAffiliationMutation,
    OrganizationRemoveDeparmentAffiliationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    OrganizationRemoveDeparmentAffiliationMutation,
    OrganizationRemoveDeparmentAffiliationMutationVariables
  >(OrganizationRemoveDeparmentAffiliationDocument, baseOptions);
}
export type OrganizationRemoveDeparmentAffiliationMutationHookResult = ReturnType<
  typeof useOrganizationRemoveDeparmentAffiliationMutation
>;
export type OrganizationRemoveDeparmentAffiliationMutationResult = Apollo.MutationResult<
  OrganizationRemoveDeparmentAffiliationMutation
>;
export type OrganizationRemoveDeparmentAffiliationMutationOptions = Apollo.BaseMutationOptions<
  OrganizationRemoveDeparmentAffiliationMutation,
  OrganizationRemoveDeparmentAffiliationMutationVariables
>;
export const OrganizationCreateDeparmentAffiliationDocument = Apollo.gql`
    mutation OrganizationCreateDeparmentAffiliation($id: UUID!, $idItem: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {clsDepartmentalAffiliations: {connect: {id: $idItem}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type OrganizationCreateDeparmentAffiliationMutationFn = Apollo.MutationFunction<
  OrganizationCreateDeparmentAffiliationMutation,
  OrganizationCreateDeparmentAffiliationMutationVariables
>;

/**
 * __useOrganizationCreateDeparmentAffiliationMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateDeparmentAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateDeparmentAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateDeparmentAffiliationMutation, { data, loading, error }] = useOrganizationCreateDeparmentAffiliationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useOrganizationCreateDeparmentAffiliationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationCreateDeparmentAffiliationMutation,
    OrganizationCreateDeparmentAffiliationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    OrganizationCreateDeparmentAffiliationMutation,
    OrganizationCreateDeparmentAffiliationMutationVariables
  >(OrganizationCreateDeparmentAffiliationDocument, baseOptions);
}
export type OrganizationCreateDeparmentAffiliationMutationHookResult = ReturnType<
  typeof useOrganizationCreateDeparmentAffiliationMutation
>;
export type OrganizationCreateDeparmentAffiliationMutationResult = Apollo.MutationResult<
  OrganizationCreateDeparmentAffiliationMutation
>;
export type OrganizationCreateDeparmentAffiliationMutationOptions = Apollo.BaseMutationOptions<
  OrganizationCreateDeparmentAffiliationMutation,
  OrganizationCreateDeparmentAffiliationMutationVariables
>;
export const UpdateOrganizationParentDocument = Apollo.gql`
    mutation UpdateOrganizationParent($id: UUID!, $parentId: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {parent: {connect: {id: $parentId}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type UpdateOrganizationParentMutationFn = Apollo.MutationFunction<
  UpdateOrganizationParentMutation,
  UpdateOrganizationParentMutationVariables
>;

/**
 * __useUpdateOrganizationParentMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationParentMutation, { data, loading, error }] = useUpdateOrganizationParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUpdateOrganizationParentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationParentMutation,
    UpdateOrganizationParentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateOrganizationParentMutation, UpdateOrganizationParentMutationVariables>(
    UpdateOrganizationParentDocument,
    baseOptions,
  );
}
export type UpdateOrganizationParentMutationHookResult = ReturnType<typeof useUpdateOrganizationParentMutation>;
export type UpdateOrganizationParentMutationResult = Apollo.MutationResult<UpdateOrganizationParentMutation>;
export type UpdateOrganizationParentMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationParentMutation,
  UpdateOrganizationParentMutationVariables
>;
export const AddOrganizationChildrenDocument = Apollo.gql`
    mutation AddOrganizationChildren($id: UUID!, $childID: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {children: {connect: {id: $childID}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type AddOrganizationChildrenMutationFn = Apollo.MutationFunction<
  AddOrganizationChildrenMutation,
  AddOrganizationChildrenMutationVariables
>;

/**
 * __useAddOrganizationChildrenMutation__
 *
 * To run a mutation, you first call `useAddOrganizationChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationChildrenMutation, { data, loading, error }] = useAddOrganizationChildrenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      childID: // value for 'childID'
 *   },
 * });
 */
export function useAddOrganizationChildrenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddOrganizationChildrenMutation,
    AddOrganizationChildrenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<AddOrganizationChildrenMutation, AddOrganizationChildrenMutationVariables>(
    AddOrganizationChildrenDocument,
    baseOptions,
  );
}
export type AddOrganizationChildrenMutationHookResult = ReturnType<typeof useAddOrganizationChildrenMutation>;
export type AddOrganizationChildrenMutationResult = Apollo.MutationResult<AddOrganizationChildrenMutation>;
export type AddOrganizationChildrenMutationOptions = Apollo.BaseMutationOptions<
  AddOrganizationChildrenMutation,
  AddOrganizationChildrenMutationVariables
>;
export const RemoveOrganizationParentDocument = Apollo.gql`
    mutation RemoveOrganizationParent($id: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {parent: {disconnect: true}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type RemoveOrganizationParentMutationFn = Apollo.MutationFunction<
  RemoveOrganizationParentMutation,
  RemoveOrganizationParentMutationVariables
>;

/**
 * __useRemoveOrganizationParentMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationParentMutation, { data, loading, error }] = useRemoveOrganizationParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrganizationParentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveOrganizationParentMutation,
    RemoveOrganizationParentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<RemoveOrganizationParentMutation, RemoveOrganizationParentMutationVariables>(
    RemoveOrganizationParentDocument,
    baseOptions,
  );
}
export type RemoveOrganizationParentMutationHookResult = ReturnType<typeof useRemoveOrganizationParentMutation>;
export type RemoveOrganizationParentMutationResult = Apollo.MutationResult<RemoveOrganizationParentMutation>;
export type RemoveOrganizationParentMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrganizationParentMutation,
  RemoveOrganizationParentMutationVariables
>;
export const RemoveOrganizationChildrenDocument = Apollo.gql`
    mutation RemoveOrganizationChildren($id: UUID!, $childID: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {children: {disconnect: {id: $childID}}}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;
export type RemoveOrganizationChildrenMutationFn = Apollo.MutationFunction<
  RemoveOrganizationChildrenMutation,
  RemoveOrganizationChildrenMutationVariables
>;

/**
 * __useRemoveOrganizationChildrenMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationChildrenMutation, { data, loading, error }] = useRemoveOrganizationChildrenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      childID: // value for 'childID'
 *   },
 * });
 */
export function useRemoveOrganizationChildrenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveOrganizationChildrenMutation,
    RemoveOrganizationChildrenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<RemoveOrganizationChildrenMutation, RemoveOrganizationChildrenMutationVariables>(
    RemoveOrganizationChildrenDocument,
    baseOptions,
  );
}
export type RemoveOrganizationChildrenMutationHookResult = ReturnType<typeof useRemoveOrganizationChildrenMutation>;
export type RemoveOrganizationChildrenMutationResult = Apollo.MutationResult<RemoveOrganizationChildrenMutation>;
export type RemoveOrganizationChildrenMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrganizationChildrenMutation,
  RemoveOrganizationChildrenMutationVariables
>;
export const OrganizationArchiveDocument = Apollo.gql`
    mutation OrganizationArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirOrganization(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type OrganizationArchiveMutationFn = Apollo.MutationFunction<
  OrganizationArchiveMutation,
  OrganizationArchiveMutationVariables
>;

/**
 * __useOrganizationArchiveMutation__
 *
 * To run a mutation, you first call `useOrganizationArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationArchiveMutation, { data, loading, error }] = useOrganizationArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useOrganizationArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationArchiveMutation, OrganizationArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<OrganizationArchiveMutation, OrganizationArchiveMutationVariables>(
    OrganizationArchiveDocument,
    baseOptions,
  );
}
export type OrganizationArchiveMutationHookResult = ReturnType<typeof useOrganizationArchiveMutation>;
export type OrganizationArchiveMutationResult = Apollo.MutationResult<OrganizationArchiveMutation>;
export type OrganizationArchiveMutationOptions = Apollo.BaseMutationOptions<
  OrganizationArchiveMutation,
  OrganizationArchiveMutationVariables
>;
export const OrganizationUnArchiveDocument = Apollo.gql`
    mutation OrganizationUnArchive($id: UUID!) {
  updateDirOrganization(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type OrganizationUnArchiveMutationFn = Apollo.MutationFunction<
  OrganizationUnArchiveMutation,
  OrganizationUnArchiveMutationVariables
>;

/**
 * __useOrganizationUnArchiveMutation__
 *
 * To run a mutation, you first call `useOrganizationUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUnArchiveMutation, { data, loading, error }] = useOrganizationUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrganizationUnArchiveMutation,
    OrganizationUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<OrganizationUnArchiveMutation, OrganizationUnArchiveMutationVariables>(
    OrganizationUnArchiveDocument,
    baseOptions,
  );
}
export type OrganizationUnArchiveMutationHookResult = ReturnType<typeof useOrganizationUnArchiveMutation>;
export type OrganizationUnArchiveMutationResult = Apollo.MutationResult<OrganizationUnArchiveMutation>;
export type OrganizationUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  OrganizationUnArchiveMutation,
  OrganizationUnArchiveMutationVariables
>;
export const OrganizationsDocument = Apollo.gql`
    query Organizations($first: Int!, $skip: Int!, $filter: DirOrganizationWhereInput) {
  dirOrganizations(first: $first, skip: $skip, where: $filter) {
    ...OrganizationFR
  }
  count: dirOrganizationsCount(where: $filter)
}
    ${OrganizationFrFragmentDoc}`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, baseOptions);
}
export function useOrganizationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    baseOptions,
  );
}
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const OrganizationsCountDocument = Apollo.gql`
    query OrganizationsCount($filter: DirOrganizationWhereInput) {
  count: dirOrganizationsCount(where: $filter)
}
    `;

/**
 * __useOrganizationsCountQuery__
 *
 * To run a query within a React component, call `useOrganizationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsCountQuery, OrganizationsCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<OrganizationsCountQuery, OrganizationsCountQueryVariables>(
    OrganizationsCountDocument,
    baseOptions,
  );
}
export function useOrganizationsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsCountQuery, OrganizationsCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<OrganizationsCountQuery, OrganizationsCountQueryVariables>(
    OrganizationsCountDocument,
    baseOptions,
  );
}
export type OrganizationsCountQueryHookResult = ReturnType<typeof useOrganizationsCountQuery>;
export type OrganizationsCountLazyQueryHookResult = ReturnType<typeof useOrganizationsCountLazyQuery>;
export type OrganizationsCountQueryResult = Apollo.QueryResult<
  OrganizationsCountQuery,
  OrganizationsCountQueryVariables
>;
export const OrganizationByIdDocument = Apollo.gql`
    query OrganizationById($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    ...OrganizationFR
  }
}
    ${OrganizationFrFragmentDoc}`;

/**
 * __useOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationByIdQuery, OrganizationByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<OrganizationByIdQuery, OrganizationByIdQueryVariables>(
    OrganizationByIdDocument,
    baseOptions,
  );
}
export function useOrganizationByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationByIdQuery, OrganizationByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<OrganizationByIdQuery, OrganizationByIdQueryVariables>(
    OrganizationByIdDocument,
    baseOptions,
  );
}
export type OrganizationByIdQueryHookResult = ReturnType<typeof useOrganizationByIdQuery>;
export type OrganizationByIdLazyQueryHookResult = ReturnType<typeof useOrganizationByIdLazyQuery>;
export type OrganizationByIdQueryResult = Apollo.QueryResult<OrganizationByIdQuery, OrganizationByIdQueryVariables>;
export const OrganizationLegalInfoDocument = Apollo.gql`
    query OrganizationLegalInfo($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    ...OrganizationLegalInfoFR
  }
}
    ${OrganizationLegalInfoFrFragmentDoc}`;

/**
 * __useOrganizationLegalInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationLegalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLegalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationLegalInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationLegalInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>,
) {
  return ApolloReactHooks.useQuery<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>(
    OrganizationLegalInfoDocument,
    baseOptions,
  );
}
export function useOrganizationLegalInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<OrganizationLegalInfoQuery, OrganizationLegalInfoQueryVariables>(
    OrganizationLegalInfoDocument,
    baseOptions,
  );
}
export type OrganizationLegalInfoQueryHookResult = ReturnType<typeof useOrganizationLegalInfoQuery>;
export type OrganizationLegalInfoLazyQueryHookResult = ReturnType<typeof useOrganizationLegalInfoLazyQuery>;
export type OrganizationLegalInfoQueryResult = Apollo.QueryResult<
  OrganizationLegalInfoQuery,
  OrganizationLegalInfoQueryVariables
>;
export const OrganizationTypeSportsDocument = Apollo.gql`
    query OrganizationTypeSports($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    ...OrganizationTypeSportsFR
  }
}
    ${OrganizationTypeSportsFrFragmentDoc}`;

/**
 * __useOrganizationTypeSportsQuery__
 *
 * To run a query within a React component, call `useOrganizationTypeSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTypeSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTypeSportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationTypeSportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>(
    OrganizationTypeSportsDocument,
    baseOptions,
  );
}
export function useOrganizationTypeSportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationTypeSportsQuery,
    OrganizationTypeSportsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<OrganizationTypeSportsQuery, OrganizationTypeSportsQueryVariables>(
    OrganizationTypeSportsDocument,
    baseOptions,
  );
}
export type OrganizationTypeSportsQueryHookResult = ReturnType<typeof useOrganizationTypeSportsQuery>;
export type OrganizationTypeSportsLazyQueryHookResult = ReturnType<typeof useOrganizationTypeSportsLazyQuery>;
export type OrganizationTypeSportsQueryResult = Apollo.QueryResult<
  OrganizationTypeSportsQuery,
  OrganizationTypeSportsQueryVariables
>;
export const OrganizationDepartmentAffiliationDocument = Apollo.gql`
    query OrganizationDepartmentAffiliation($id: UUID!) {
  dirOrganization(where: {id: $id}) {
    ...OrganizationDepartmentAffiliationFR
  }
}
    ${OrganizationDepartmentAffiliationFrFragmentDoc}`;

/**
 * __useOrganizationDepartmentAffiliationQuery__
 *
 * To run a query within a React component, call `useOrganizationDepartmentAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDepartmentAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDepartmentAffiliationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationDepartmentAffiliationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationDepartmentAffiliationQuery,
    OrganizationDepartmentAffiliationQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationDepartmentAffiliationQuery,
    OrganizationDepartmentAffiliationQueryVariables
  >(OrganizationDepartmentAffiliationDocument, baseOptions);
}
export function useOrganizationDepartmentAffiliationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationDepartmentAffiliationQuery,
    OrganizationDepartmentAffiliationQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationDepartmentAffiliationQuery,
    OrganizationDepartmentAffiliationQueryVariables
  >(OrganizationDepartmentAffiliationDocument, baseOptions);
}
export type OrganizationDepartmentAffiliationQueryHookResult = ReturnType<
  typeof useOrganizationDepartmentAffiliationQuery
>;
export type OrganizationDepartmentAffiliationLazyQueryHookResult = ReturnType<
  typeof useOrganizationDepartmentAffiliationLazyQuery
>;
export type OrganizationDepartmentAffiliationQueryResult = Apollo.QueryResult<
  OrganizationDepartmentAffiliationQuery,
  OrganizationDepartmentAffiliationQueryVariables
>;
export const FindOrganizationsDocument = Apollo.gql`
    query FindOrganizations($search: String!, $filter: DirOrganizationWhereInput) {
  data: dirOrganizationsSimilar(search: $search, where: $filter) {
    organization {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useFindOrganizationsQuery__
 *
 * To run a query within a React component, call `useFindOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrganizationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindOrganizationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FindOrganizationsQuery, FindOrganizationsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<FindOrganizationsQuery, FindOrganizationsQueryVariables>(
    FindOrganizationsDocument,
    baseOptions,
  );
}
export function useFindOrganizationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindOrganizationsQuery, FindOrganizationsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<FindOrganizationsQuery, FindOrganizationsQueryVariables>(
    FindOrganizationsDocument,
    baseOptions,
  );
}
export type FindOrganizationsQueryHookResult = ReturnType<typeof useFindOrganizationsQuery>;
export type FindOrganizationsLazyQueryHookResult = ReturnType<typeof useFindOrganizationsLazyQuery>;
export type FindOrganizationsQueryResult = Apollo.QueryResult<FindOrganizationsQuery, FindOrganizationsQueryVariables>;
export const SuggestOrganizationsDocument = Apollo.gql`
    query suggestOrganizations($ogrn: String!) {
  suggestOrganizations(by: $ogrn) {
    fullName
    shortName
    registrationDate
    inn
    kpp
    legalAddress
    postcode
    headPosition
    headFullName
  }
}
    `;

/**
 * __useSuggestOrganizationsQuery__
 *
 * To run a query within a React component, call `useSuggestOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestOrganizationsQuery({
 *   variables: {
 *      ogrn: // value for 'ogrn'
 *   },
 * });
 */
export function useSuggestOrganizationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>(
    SuggestOrganizationsDocument,
    baseOptions,
  );
}
export function useSuggestOrganizationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<SuggestOrganizationsQuery, SuggestOrganizationsQueryVariables>(
    SuggestOrganizationsDocument,
    baseOptions,
  );
}
export type SuggestOrganizationsQueryHookResult = ReturnType<typeof useSuggestOrganizationsQuery>;
export type SuggestOrganizationsLazyQueryHookResult = ReturnType<typeof useSuggestOrganizationsLazyQuery>;
export type SuggestOrganizationsQueryResult = Apollo.QueryResult<
  SuggestOrganizationsQuery,
  SuggestOrganizationsQueryVariables
>;
export const OrganizationsTrainersBySportDocument = Apollo.gql`
    query OrganizationsTrainersBySport($sportID: UUID, $id: UUID) {
  dirTrainers(where: {sports_some: {dirSport: {id: $sportID}, organizations_some: {dirOrganization: {id: $id}}}}) {
    id
    dirPerson {
      id
      firstname
      lastname
      patronymic
    }
    sports {
      dirSport {
        id
        clsTrainingStages {
          id
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useOrganizationsTrainersBySportQuery__
 *
 * To run a query within a React component, call `useOrganizationsTrainersBySportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsTrainersBySportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsTrainersBySportQuery({
 *   variables: {
 *      sportID: // value for 'sportID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationsTrainersBySportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationsTrainersBySportQuery,
    OrganizationsTrainersBySportQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<OrganizationsTrainersBySportQuery, OrganizationsTrainersBySportQueryVariables>(
    OrganizationsTrainersBySportDocument,
    baseOptions,
  );
}
export function useOrganizationsTrainersBySportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationsTrainersBySportQuery,
    OrganizationsTrainersBySportQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<OrganizationsTrainersBySportQuery, OrganizationsTrainersBySportQueryVariables>(
    OrganizationsTrainersBySportDocument,
    baseOptions,
  );
}
export type OrganizationsTrainersBySportQueryHookResult = ReturnType<typeof useOrganizationsTrainersBySportQuery>;
export type OrganizationsTrainersBySportLazyQueryHookResult = ReturnType<
  typeof useOrganizationsTrainersBySportLazyQuery
>;
export type OrganizationsTrainersBySportQueryResult = Apollo.QueryResult<
  OrganizationsTrainersBySportQuery,
  OrganizationsTrainersBySportQueryVariables
>;
export const OrganizationsAthletesBySportDocument = Apollo.gql`
    query OrganizationsAthletesBySport($sportID: UUID, $id: UUID) {
  dirAthletes(where: {sports_some: {dirSport: {id: $sportID}, organizations_some: {dirOrganization: {id: $id}, exclusionDate: null}}}) {
    id
    dirPerson {
      firstname
      lastname
      patronymic
    }
    sports {
      dirSport {
        id
      }
      organizations(where: {exclusionDate: null}) {
        dirTrainer {
          id
        }
        clsTrainingStage {
          id
          fullName
        }
        isSelfTraining
      }
    }
  }
}
    `;

/**
 * __useOrganizationsAthletesBySportQuery__
 *
 * To run a query within a React component, call `useOrganizationsAthletesBySportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsAthletesBySportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsAthletesBySportQuery({
 *   variables: {
 *      sportID: // value for 'sportID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationsAthletesBySportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationsAthletesBySportQuery,
    OrganizationsAthletesBySportQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<OrganizationsAthletesBySportQuery, OrganizationsAthletesBySportQueryVariables>(
    OrganizationsAthletesBySportDocument,
    baseOptions,
  );
}
export function useOrganizationsAthletesBySportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationsAthletesBySportQuery,
    OrganizationsAthletesBySportQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<OrganizationsAthletesBySportQuery, OrganizationsAthletesBySportQueryVariables>(
    OrganizationsAthletesBySportDocument,
    baseOptions,
  );
}
export type OrganizationsAthletesBySportQueryHookResult = ReturnType<typeof useOrganizationsAthletesBySportQuery>;
export type OrganizationsAthletesBySportLazyQueryHookResult = ReturnType<
  typeof useOrganizationsAthletesBySportLazyQuery
>;
export type OrganizationsAthletesBySportQueryResult = Apollo.QueryResult<
  OrganizationsAthletesBySportQuery,
  OrganizationsAthletesBySportQueryVariables
>;
export const RemovePersonCitizenshipDocument = Apollo.gql`
    mutation RemovePersonCitizenship($id: UUID!, $idItem: UUID!) {
  updateDirPerson(where: {id: $id}, data: {dirCitizenships: {disconnect: {id: $idItem}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type RemovePersonCitizenshipMutationFn = Apollo.MutationFunction<
  RemovePersonCitizenshipMutation,
  RemovePersonCitizenshipMutationVariables
>;

/**
 * __useRemovePersonCitizenshipMutation__
 *
 * To run a mutation, you first call `useRemovePersonCitizenshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePersonCitizenshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePersonCitizenshipMutation, { data, loading, error }] = useRemovePersonCitizenshipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useRemovePersonCitizenshipMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemovePersonCitizenshipMutation,
    RemovePersonCitizenshipMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<RemovePersonCitizenshipMutation, RemovePersonCitizenshipMutationVariables>(
    RemovePersonCitizenshipDocument,
    baseOptions,
  );
}
export type RemovePersonCitizenshipMutationHookResult = ReturnType<typeof useRemovePersonCitizenshipMutation>;
export type RemovePersonCitizenshipMutationResult = Apollo.MutationResult<RemovePersonCitizenshipMutation>;
export type RemovePersonCitizenshipMutationOptions = Apollo.BaseMutationOptions<
  RemovePersonCitizenshipMutation,
  RemovePersonCitizenshipMutationVariables
>;
export const CreatePersonCitizenshipDocument = Apollo.gql`
    mutation CreatePersonCitizenship($id: UUID!, $idItem: UUID!) {
  updateDirPerson(where: {id: $id}, data: {dirCitizenships: {connect: {id: $idItem}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type CreatePersonCitizenshipMutationFn = Apollo.MutationFunction<
  CreatePersonCitizenshipMutation,
  CreatePersonCitizenshipMutationVariables
>;

/**
 * __useCreatePersonCitizenshipMutation__
 *
 * To run a mutation, you first call `useCreatePersonCitizenshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonCitizenshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonCitizenshipMutation, { data, loading, error }] = useCreatePersonCitizenshipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useCreatePersonCitizenshipMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePersonCitizenshipMutation,
    CreatePersonCitizenshipMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<CreatePersonCitizenshipMutation, CreatePersonCitizenshipMutationVariables>(
    CreatePersonCitizenshipDocument,
    baseOptions,
  );
}
export type CreatePersonCitizenshipMutationHookResult = ReturnType<typeof useCreatePersonCitizenshipMutation>;
export type CreatePersonCitizenshipMutationResult = Apollo.MutationResult<CreatePersonCitizenshipMutation>;
export type CreatePersonCitizenshipMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonCitizenshipMutation,
  CreatePersonCitizenshipMutationVariables
>;
export const UpdatePersonDocument = Apollo.gql`
    mutation UpdatePerson($id: UUID!, $firstname: String!, $lastname: String!, $patronymic: String, $previousLastname: String, $birthday: DateTime!, $isMale: Boolean!, $regionID: DirRegionUpdateOneInput, $countryID: DirCountryUpdateOneInput, $foreignCityID: DirForeignCityUpdateOneInput, $isRussia: Boolean, $email: String, $confirmStatus: DirPersonConfirmStatusUpdateOneRequiredInput) {
  updateDirPerson(where: {id: $id}, data: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, previousLastname: $previousLastname, birthday: $birthday, email: $email, isMale: $isMale, dirRegion: $regionID, dirCountry: $countryID, dirForeignCity: $foreignCityID, isRussia: $isRussia, confirmStatus: $confirmStatus}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      previousLastname: // value for 'previousLastname'
 *      birthday: // value for 'birthday'
 *      isMale: // value for 'isMale'
 *      regionID: // value for 'regionID'
 *      countryID: // value for 'countryID'
 *      foreignCityID: // value for 'foreignCityID'
 *      isRussia: // value for 'isRussia'
 *      email: // value for 'email'
 *      confirmStatus: // value for 'confirmStatus'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(
    UpdatePersonDocument,
    baseOptions,
  );
}
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;
export const CreatePersonDocument = Apollo.gql`
    mutation createPerson($firstname: String!, $lastname: String!, $patronymic: String, $previousLastname: String, $birthday: DateTime!, $isMale: Boolean!, $regionID: DirRegionCreateOneInput, $countryID: DirCountryCreateOneInput, $foreignCityID: DirForeignCityCreateOneInput, $confirmStatus: DirPersonConfirmStatusCreateOneInput!, $email: String) {
  createDirPerson(data: {firstname: $firstname, lastname: $lastname, patronymic: $patronymic, previousLastname: $previousLastname, birthday: $birthday, isMale: $isMale, email: $email, dirRegion: $regionID, dirCountry: $countryID, dirForeignCity: $foreignCityID, confirmStatus: $confirmStatus}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type CreatePersonMutationFn = Apollo.MutationFunction<CreatePersonMutation, CreatePersonMutationVariables>;

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      patronymic: // value for 'patronymic'
 *      previousLastname: // value for 'previousLastname'
 *      birthday: // value for 'birthday'
 *      isMale: // value for 'isMale'
 *      regionID: // value for 'regionID'
 *      countryID: // value for 'countryID'
 *      foreignCityID: // value for 'foreignCityID'
 *      confirmStatus: // value for 'confirmStatus'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreatePersonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePersonMutation, CreatePersonMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreatePersonMutation, CreatePersonMutationVariables>(
    CreatePersonDocument,
    baseOptions,
  );
}
export type CreatePersonMutationHookResult = ReturnType<typeof useCreatePersonMutation>;
export type CreatePersonMutationResult = Apollo.MutationResult<CreatePersonMutation>;
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonMutation,
  CreatePersonMutationVariables
>;
export const UpdatePersonMartialStatusDocument = Apollo.gql`
    mutation UpdatePersonMartialStatus($id: UUID!, $clsMaritalStatus: ClassifierValueUpdateOneInput) {
  updateDirPerson(where: {id: $id}, data: {clsMaritalStatus: $clsMaritalStatus}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type UpdatePersonMartialStatusMutationFn = Apollo.MutationFunction<
  UpdatePersonMartialStatusMutation,
  UpdatePersonMartialStatusMutationVariables
>;

/**
 * __useUpdatePersonMartialStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMartialStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMartialStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMartialStatusMutation, { data, loading, error }] = useUpdatePersonMartialStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clsMaritalStatus: // value for 'clsMaritalStatus'
 *   },
 * });
 */
export function useUpdatePersonMartialStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePersonMartialStatusMutation,
    UpdatePersonMartialStatusMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdatePersonMartialStatusMutation, UpdatePersonMartialStatusMutationVariables>(
    UpdatePersonMartialStatusDocument,
    baseOptions,
  );
}
export type UpdatePersonMartialStatusMutationHookResult = ReturnType<typeof useUpdatePersonMartialStatusMutation>;
export type UpdatePersonMartialStatusMutationResult = Apollo.MutationResult<UpdatePersonMartialStatusMutation>;
export type UpdatePersonMartialStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMartialStatusMutation,
  UpdatePersonMartialStatusMutationVariables
>;
export const RemovePersonPhoneDocument = Apollo.gql`
    mutation RemovePersonPhone($id: UUID!, $date: DateTime) {
  updateDirPersonPhone(where: {id: $id}, data: {deletedAt: $date}) {
    id
    deletedAt
  }
}
    `;
export type RemovePersonPhoneMutationFn = Apollo.MutationFunction<
  RemovePersonPhoneMutation,
  RemovePersonPhoneMutationVariables
>;

/**
 * __useRemovePersonPhoneMutation__
 *
 * To run a mutation, you first call `useRemovePersonPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePersonPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePersonPhoneMutation, { data, loading, error }] = useRemovePersonPhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRemovePersonPhoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePersonPhoneMutation, RemovePersonPhoneMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RemovePersonPhoneMutation, RemovePersonPhoneMutationVariables>(
    RemovePersonPhoneDocument,
    baseOptions,
  );
}
export type RemovePersonPhoneMutationHookResult = ReturnType<typeof useRemovePersonPhoneMutation>;
export type RemovePersonPhoneMutationResult = Apollo.MutationResult<RemovePersonPhoneMutation>;
export type RemovePersonPhoneMutationOptions = Apollo.BaseMutationOptions<
  RemovePersonPhoneMutation,
  RemovePersonPhoneMutationVariables
>;
export const AddPersonPhoneDocument = Apollo.gql`
    mutation AddPersonPhone($idPerson: UUID!, $countryCode: String!, $number: String!, $operatorCode: String!, $additionalNumber: String, $clsContactType: UUID!) {
  updateDirPerson(where: {id: $idPerson}, data: {phones: {create: {countryCode: $countryCode, operatorCode: $operatorCode, additionalNumber: $additionalNumber, number: $number, clsContactType: {connect: {id: $clsContactType}}}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type AddPersonPhoneMutationFn = Apollo.MutationFunction<AddPersonPhoneMutation, AddPersonPhoneMutationVariables>;

/**
 * __useAddPersonPhoneMutation__
 *
 * To run a mutation, you first call `useAddPersonPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonPhoneMutation, { data, loading, error }] = useAddPersonPhoneMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *      countryCode: // value for 'countryCode'
 *      number: // value for 'number'
 *      operatorCode: // value for 'operatorCode'
 *      additionalNumber: // value for 'additionalNumber'
 *      clsContactType: // value for 'clsContactType'
 *   },
 * });
 */
export function useAddPersonPhoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddPersonPhoneMutation, AddPersonPhoneMutationVariables>,
) {
  return ApolloReactHooks.useMutation<AddPersonPhoneMutation, AddPersonPhoneMutationVariables>(
    AddPersonPhoneDocument,
    baseOptions,
  );
}
export type AddPersonPhoneMutationHookResult = ReturnType<typeof useAddPersonPhoneMutation>;
export type AddPersonPhoneMutationResult = Apollo.MutationResult<AddPersonPhoneMutation>;
export type AddPersonPhoneMutationOptions = Apollo.BaseMutationOptions<
  AddPersonPhoneMutation,
  AddPersonPhoneMutationVariables
>;
export const UpdatePersonPhoneDocument = Apollo.gql`
    mutation UpdatePersonPhone($idPerson: UUID!, $id: UUID!, $countryCode: String!, $number: String!, $operatorCode: String!, $additionalNumber: String, $clsContactType: UUID!) {
  updateDirPerson(where: {id: $idPerson}, data: {phones: {update: {where: {id: $id}, data: {countryCode: $countryCode, operatorCode: $operatorCode, additionalNumber: $additionalNumber, number: $number, clsContactType: {connect: {id: $clsContactType}}}}}}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;
export type UpdatePersonPhoneMutationFn = Apollo.MutationFunction<
  UpdatePersonPhoneMutation,
  UpdatePersonPhoneMutationVariables
>;

/**
 * __useUpdatePersonPhoneMutation__
 *
 * To run a mutation, you first call `useUpdatePersonPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonPhoneMutation, { data, loading, error }] = useUpdatePersonPhoneMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *      id: // value for 'id'
 *      countryCode: // value for 'countryCode'
 *      number: // value for 'number'
 *      operatorCode: // value for 'operatorCode'
 *      additionalNumber: // value for 'additionalNumber'
 *      clsContactType: // value for 'clsContactType'
 *   },
 * });
 */
export function useUpdatePersonPhoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePersonPhoneMutation, UpdatePersonPhoneMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdatePersonPhoneMutation, UpdatePersonPhoneMutationVariables>(
    UpdatePersonPhoneDocument,
    baseOptions,
  );
}
export type UpdatePersonPhoneMutationHookResult = ReturnType<typeof useUpdatePersonPhoneMutation>;
export type UpdatePersonPhoneMutationResult = Apollo.MutationResult<UpdatePersonPhoneMutation>;
export type UpdatePersonPhoneMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonPhoneMutation,
  UpdatePersonPhoneMutationVariables
>;
export const PersonArchiveDocument = Apollo.gql`
    mutation PersonArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirPerson(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type PersonArchiveMutationFn = Apollo.MutationFunction<PersonArchiveMutation, PersonArchiveMutationVariables>;

/**
 * __usePersonArchiveMutation__
 *
 * To run a mutation, you first call `usePersonArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personArchiveMutation, { data, loading, error }] = usePersonArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function usePersonArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<PersonArchiveMutation, PersonArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<PersonArchiveMutation, PersonArchiveMutationVariables>(
    PersonArchiveDocument,
    baseOptions,
  );
}
export type PersonArchiveMutationHookResult = ReturnType<typeof usePersonArchiveMutation>;
export type PersonArchiveMutationResult = Apollo.MutationResult<PersonArchiveMutation>;
export type PersonArchiveMutationOptions = Apollo.BaseMutationOptions<
  PersonArchiveMutation,
  PersonArchiveMutationVariables
>;
export const PersonUnArchiveDocument = Apollo.gql`
    mutation PersonUnArchive($id: UUID!) {
  updateDirPerson(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type PersonUnArchiveMutationFn = Apollo.MutationFunction<
  PersonUnArchiveMutation,
  PersonUnArchiveMutationVariables
>;

/**
 * __usePersonUnArchiveMutation__
 *
 * To run a mutation, you first call `usePersonUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personUnArchiveMutation, { data, loading, error }] = usePersonUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<PersonUnArchiveMutation, PersonUnArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<PersonUnArchiveMutation, PersonUnArchiveMutationVariables>(
    PersonUnArchiveDocument,
    baseOptions,
  );
}
export type PersonUnArchiveMutationHookResult = ReturnType<typeof usePersonUnArchiveMutation>;
export type PersonUnArchiveMutationResult = Apollo.MutationResult<PersonUnArchiveMutation>;
export type PersonUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  PersonUnArchiveMutation,
  PersonUnArchiveMutationVariables
>;
export const FindSimiliarPersonDocument = Apollo.gql`
    query FindSimiliarPerson($search: String!, $filter: DirPersonWhereInput) {
  dirPersonsSimilar(search: $search, where: $filter) {
    similarity
    person {
      ...PersonFR
    }
  }
}
    ${PersonFrFragmentDoc}`;

/**
 * __useFindSimiliarPersonQuery__
 *
 * To run a query within a React component, call `useFindSimiliarPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimiliarPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimiliarPersonQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindSimiliarPersonQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>,
) {
  return ApolloReactHooks.useQuery<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>(
    FindSimiliarPersonDocument,
    baseOptions,
  );
}
export function useFindSimiliarPersonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<FindSimiliarPersonQuery, FindSimiliarPersonQueryVariables>(
    FindSimiliarPersonDocument,
    baseOptions,
  );
}
export type FindSimiliarPersonQueryHookResult = ReturnType<typeof useFindSimiliarPersonQuery>;
export type FindSimiliarPersonLazyQueryHookResult = ReturnType<typeof useFindSimiliarPersonLazyQuery>;
export type FindSimiliarPersonQueryResult = Apollo.QueryResult<
  FindSimiliarPersonQuery,
  FindSimiliarPersonQueryVariables
>;
export const CheckExistEmailDocument = Apollo.gql`
    query CheckExistEmail($email: String!) {
  dirPersons(where: {email: $email}) {
    id
    email
  }
}
    `;

/**
 * __useCheckExistEmailQuery__
 *
 * To run a query within a React component, call `useCheckExistEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckExistEmailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CheckExistEmailQuery, CheckExistEmailQueryVariables>,
) {
  return ApolloReactHooks.useQuery<CheckExistEmailQuery, CheckExistEmailQueryVariables>(
    CheckExistEmailDocument,
    baseOptions,
  );
}
export function useCheckExistEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckExistEmailQuery, CheckExistEmailQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<CheckExistEmailQuery, CheckExistEmailQueryVariables>(
    CheckExistEmailDocument,
    baseOptions,
  );
}
export type CheckExistEmailQueryHookResult = ReturnType<typeof useCheckExistEmailQuery>;
export type CheckExistEmailLazyQueryHookResult = ReturnType<typeof useCheckExistEmailLazyQuery>;
export type CheckExistEmailQueryResult = Apollo.QueryResult<CheckExistEmailQuery, CheckExistEmailQueryVariables>;
export const PersonsDocument = Apollo.gql`
    query Persons($first: Int!, $skip: Int!, $filter: DirPersonWhereInput) {
  dirPersons(first: $first, skip: $skip, where: $filter) {
    ...PersonFR
  }
  count: dirPersonsCount(where: $filter)
}
    ${PersonFrFragmentDoc}`;

/**
 * __usePersonsQuery__
 *
 * To run a query within a React component, call `usePersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePersonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonsQuery, PersonsQueryVariables>) {
  return ApolloReactHooks.useQuery<PersonsQuery, PersonsQueryVariables>(PersonsDocument, baseOptions);
}
export function usePersonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonsQuery, PersonsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<PersonsQuery, PersonsQueryVariables>(PersonsDocument, baseOptions);
}
export type PersonsQueryHookResult = ReturnType<typeof usePersonsQuery>;
export type PersonsLazyQueryHookResult = ReturnType<typeof usePersonsLazyQuery>;
export type PersonsQueryResult = Apollo.QueryResult<PersonsQuery, PersonsQueryVariables>;
export const PersonByIdDocument = Apollo.gql`
    query PersonById($id: UUID!) {
  dirPerson(where: {id: $id}) {
    ...PersonFR
  }
}
    ${PersonFrFragmentDoc}`;

/**
 * __usePersonByIdQuery__
 *
 * To run a query within a React component, call `usePersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PersonByIdQuery, PersonByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<PersonByIdQuery, PersonByIdQueryVariables>(PersonByIdDocument, baseOptions);
}
export function usePersonByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonByIdQuery, PersonByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<PersonByIdQuery, PersonByIdQueryVariables>(PersonByIdDocument, baseOptions);
}
export type PersonByIdQueryHookResult = ReturnType<typeof usePersonByIdQuery>;
export type PersonByIdLazyQueryHookResult = ReturnType<typeof usePersonByIdLazyQuery>;
export type PersonByIdQueryResult = Apollo.QueryResult<PersonByIdQuery, PersonByIdQueryVariables>;
export const UpdateRefereeDocument = Apollo.gql`
    mutation UpdateReferee($id: UUID!, $idPerson: UUID!) {
  updateDirReferee(where: {id: $id}, data: {dirPerson: {connect: {id: $idPerson}}}) {
    id
  }
}
    `;
export type UpdateRefereeMutationFn = Apollo.MutationFunction<UpdateRefereeMutation, UpdateRefereeMutationVariables>;

/**
 * __useUpdateRefereeMutation__
 *
 * To run a mutation, you first call `useUpdateRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRefereeMutation, { data, loading, error }] = useUpdateRefereeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useUpdateRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRefereeMutation, UpdateRefereeMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateRefereeMutation, UpdateRefereeMutationVariables>(
    UpdateRefereeDocument,
    baseOptions,
  );
}
export type UpdateRefereeMutationHookResult = ReturnType<typeof useUpdateRefereeMutation>;
export type UpdateRefereeMutationResult = Apollo.MutationResult<UpdateRefereeMutation>;
export type UpdateRefereeMutationOptions = Apollo.BaseMutationOptions<
  UpdateRefereeMutation,
  UpdateRefereeMutationVariables
>;
export const CreateRefereeDocument = Apollo.gql`
    mutation CreateReferee($idPerson: UUID!) {
  createDirReferee(data: {dirPerson: {connect: {id: $idPerson}}}) {
    id
  }
}
    `;
export type CreateRefereeMutationFn = Apollo.MutationFunction<CreateRefereeMutation, CreateRefereeMutationVariables>;

/**
 * __useCreateRefereeMutation__
 *
 * To run a mutation, you first call `useCreateRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefereeMutation, { data, loading, error }] = useCreateRefereeMutation({
 *   variables: {
 *      idPerson: // value for 'idPerson'
 *   },
 * });
 */
export function useCreateRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRefereeMutation, CreateRefereeMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateRefereeMutation, CreateRefereeMutationVariables>(
    CreateRefereeDocument,
    baseOptions,
  );
}
export type CreateRefereeMutationHookResult = ReturnType<typeof useCreateRefereeMutation>;
export type CreateRefereeMutationResult = Apollo.MutationResult<CreateRefereeMutation>;
export type CreateRefereeMutationOptions = Apollo.BaseMutationOptions<
  CreateRefereeMutation,
  CreateRefereeMutationVariables
>;
export const UpdateRefereeSportDocument = Apollo.gql`
    mutation UpdateRefereeSport($id: UUID!, $data: DirRefereeSportUpdateInput!) {
  updateDirRefereeSport(where: {id: $id}, data: $data) {
    id
  }
}
    `;
export type UpdateRefereeSportMutationFn = Apollo.MutationFunction<
  UpdateRefereeSportMutation,
  UpdateRefereeSportMutationVariables
>;

/**
 * __useUpdateRefereeSportMutation__
 *
 * To run a mutation, you first call `useUpdateRefereeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRefereeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRefereeSportMutation, { data, loading, error }] = useUpdateRefereeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRefereeSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRefereeSportMutation, UpdateRefereeSportMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateRefereeSportMutation, UpdateRefereeSportMutationVariables>(
    UpdateRefereeSportDocument,
    baseOptions,
  );
}
export type UpdateRefereeSportMutationHookResult = ReturnType<typeof useUpdateRefereeSportMutation>;
export type UpdateRefereeSportMutationResult = Apollo.MutationResult<UpdateRefereeSportMutation>;
export type UpdateRefereeSportMutationOptions = Apollo.BaseMutationOptions<
  UpdateRefereeSportMutation,
  UpdateRefereeSportMutationVariables
>;
export const CreateRefereeSportDocument = Apollo.gql`
    mutation CreateRefereeSport($id: UUID!, $sportId: UUID!) {
  updateDirReferee(where: {id: $id}, data: {sports: {create: {dirSport: {connect: {id: $sportId}}}}}) {
    id
  }
}
    `;
export type CreateRefereeSportMutationFn = Apollo.MutationFunction<
  CreateRefereeSportMutation,
  CreateRefereeSportMutationVariables
>;

/**
 * __useCreateRefereeSportMutation__
 *
 * To run a mutation, you first call `useCreateRefereeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefereeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefereeSportMutation, { data, loading, error }] = useCreateRefereeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useCreateRefereeSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRefereeSportMutation, CreateRefereeSportMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateRefereeSportMutation, CreateRefereeSportMutationVariables>(
    CreateRefereeSportDocument,
    baseOptions,
  );
}
export type CreateRefereeSportMutationHookResult = ReturnType<typeof useCreateRefereeSportMutation>;
export type CreateRefereeSportMutationResult = Apollo.MutationResult<CreateRefereeSportMutation>;
export type CreateRefereeSportMutationOptions = Apollo.BaseMutationOptions<
  CreateRefereeSportMutation,
  CreateRefereeSportMutationVariables
>;
export const RefereeSportArchiveDocument = Apollo.gql`
    mutation RefereeSportArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirRefereeSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RefereeSportArchiveMutationFn = Apollo.MutationFunction<
  RefereeSportArchiveMutation,
  RefereeSportArchiveMutationVariables
>;

/**
 * __useRefereeSportArchiveMutation__
 *
 * To run a mutation, you first call `useRefereeSportArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefereeSportArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refereeSportArchiveMutation, { data, loading, error }] = useRefereeSportArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useRefereeSportArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RefereeSportArchiveMutation, RefereeSportArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RefereeSportArchiveMutation, RefereeSportArchiveMutationVariables>(
    RefereeSportArchiveDocument,
    baseOptions,
  );
}
export type RefereeSportArchiveMutationHookResult = ReturnType<typeof useRefereeSportArchiveMutation>;
export type RefereeSportArchiveMutationResult = Apollo.MutationResult<RefereeSportArchiveMutation>;
export type RefereeSportArchiveMutationOptions = Apollo.BaseMutationOptions<
  RefereeSportArchiveMutation,
  RefereeSportArchiveMutationVariables
>;
export const RefereeSportUnArchiveDocument = Apollo.gql`
    mutation RefereeSportUnArchive($id: UUID!) {
  updateDirRefereeSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RefereeSportUnArchiveMutationFn = Apollo.MutationFunction<
  RefereeSportUnArchiveMutation,
  RefereeSportUnArchiveMutationVariables
>;

/**
 * __useRefereeSportUnArchiveMutation__
 *
 * To run a mutation, you first call `useRefereeSportUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefereeSportUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refereeSportUnArchiveMutation, { data, loading, error }] = useRefereeSportUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefereeSportUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefereeSportUnArchiveMutation,
    RefereeSportUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<RefereeSportUnArchiveMutation, RefereeSportUnArchiveMutationVariables>(
    RefereeSportUnArchiveDocument,
    baseOptions,
  );
}
export type RefereeSportUnArchiveMutationHookResult = ReturnType<typeof useRefereeSportUnArchiveMutation>;
export type RefereeSportUnArchiveMutationResult = Apollo.MutationResult<RefereeSportUnArchiveMutation>;
export type RefereeSportUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  RefereeSportUnArchiveMutation,
  RefereeSportUnArchiveMutationVariables
>;
export const RefereesDocument = Apollo.gql`
    query Referees($first: Int!, $skip: Int!, $filter: DirRefereeWhereInput) {
  dirReferees(first: $first, skip: $skip, where: $filter) {
    ...Referee
  }
  count: dirRefereesCount(where: $filter)
}
    ${RefereeFragmentDoc}`;

/**
 * __useRefereesQuery__
 *
 * To run a query within a React component, call `useRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRefereesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesQuery, RefereesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<RefereesQuery, RefereesQueryVariables>(RefereesDocument, baseOptions);
}
export function useRefereesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesQuery, RefereesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<RefereesQuery, RefereesQueryVariables>(RefereesDocument, baseOptions);
}
export type RefereesQueryHookResult = ReturnType<typeof useRefereesQuery>;
export type RefereesLazyQueryHookResult = ReturnType<typeof useRefereesLazyQuery>;
export type RefereesQueryResult = Apollo.QueryResult<RefereesQuery, RefereesQueryVariables>;
export const RefereesCountDocument = Apollo.gql`
    query RefereesCount($filter: DirRefereeWhereInput) {
  count: dirRefereesCount(where: $filter)
}
    `;

/**
 * __useRefereesCountQuery__
 *
 * To run a query within a React component, call `useRefereesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRefereesCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesCountQuery, RefereesCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<RefereesCountQuery, RefereesCountQueryVariables>(RefereesCountDocument, baseOptions);
}
export function useRefereesCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesCountQuery, RefereesCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<RefereesCountQuery, RefereesCountQueryVariables>(
    RefereesCountDocument,
    baseOptions,
  );
}
export type RefereesCountQueryHookResult = ReturnType<typeof useRefereesCountQuery>;
export type RefereesCountLazyQueryHookResult = ReturnType<typeof useRefereesCountLazyQuery>;
export type RefereesCountQueryResult = Apollo.QueryResult<RefereesCountQuery, RefereesCountQueryVariables>;
export const RefereeByIdDocument = Apollo.gql`
    query RefereeById($id: UUID!) {
  dirReferee(where: {id: $id}) {
    ...Referee
  }
}
    ${RefereeFragmentDoc}`;

/**
 * __useRefereeByIdQuery__
 *
 * To run a query within a React component, call `useRefereeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefereeByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RefereeByIdQuery, RefereeByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<RefereeByIdQuery, RefereeByIdQueryVariables>(RefereeByIdDocument, baseOptions);
}
export function useRefereeByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereeByIdQuery, RefereeByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<RefereeByIdQuery, RefereeByIdQueryVariables>(RefereeByIdDocument, baseOptions);
}
export type RefereeByIdQueryHookResult = ReturnType<typeof useRefereeByIdQuery>;
export type RefereeByIdLazyQueryHookResult = ReturnType<typeof useRefereeByIdLazyQuery>;
export type RefereeByIdQueryResult = Apollo.QueryResult<RefereeByIdQuery, RefereeByIdQueryVariables>;
export const RegionArchiveDocument = Apollo.gql`
    mutation RegionArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirRegion(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RegionArchiveMutationFn = Apollo.MutationFunction<RegionArchiveMutation, RegionArchiveMutationVariables>;

/**
 * __useRegionArchiveMutation__
 *
 * To run a mutation, you first call `useRegionArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionArchiveMutation, { data, loading, error }] = useRegionArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useRegionArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RegionArchiveMutation, RegionArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RegionArchiveMutation, RegionArchiveMutationVariables>(
    RegionArchiveDocument,
    baseOptions,
  );
}
export type RegionArchiveMutationHookResult = ReturnType<typeof useRegionArchiveMutation>;
export type RegionArchiveMutationResult = Apollo.MutationResult<RegionArchiveMutation>;
export type RegionArchiveMutationOptions = Apollo.BaseMutationOptions<
  RegionArchiveMutation,
  RegionArchiveMutationVariables
>;
export const RegionUnArchiveDocument = Apollo.gql`
    mutation RegionUnArchive($id: UUID!) {
  updateDirRegion(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type RegionUnArchiveMutationFn = Apollo.MutationFunction<
  RegionUnArchiveMutation,
  RegionUnArchiveMutationVariables
>;

/**
 * __useRegionUnArchiveMutation__
 *
 * To run a mutation, you first call `useRegionUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionUnArchiveMutation, { data, loading, error }] = useRegionUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RegionUnArchiveMutation, RegionUnArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RegionUnArchiveMutation, RegionUnArchiveMutationVariables>(
    RegionUnArchiveDocument,
    baseOptions,
  );
}
export type RegionUnArchiveMutationHookResult = ReturnType<typeof useRegionUnArchiveMutation>;
export type RegionUnArchiveMutationResult = Apollo.MutationResult<RegionUnArchiveMutation>;
export type RegionUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  RegionUnArchiveMutation,
  RegionUnArchiveMutationVariables
>;
export const RegionUpdateDocument = Apollo.gql`
    mutation RegionUpdate($id: UUID!, $fullName: String!, $shortName: String!, $code: String!, $regionId: DirFederalDistrictUpdateOneRequiredWithoutDirRegionsInput!) {
  updateDirRegion(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, code: $code, dirFederalDistrict: $regionId}) {
    ...Region
  }
}
    ${RegionFragmentDoc}`;
export type RegionUpdateMutationFn = Apollo.MutationFunction<RegionUpdateMutation, RegionUpdateMutationVariables>;

/**
 * __useRegionUpdateMutation__
 *
 * To run a mutation, you first call `useRegionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionUpdateMutation, { data, loading, error }] = useRegionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      code: // value for 'code'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useRegionUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RegionUpdateMutation, RegionUpdateMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RegionUpdateMutation, RegionUpdateMutationVariables>(
    RegionUpdateDocument,
    baseOptions,
  );
}
export type RegionUpdateMutationHookResult = ReturnType<typeof useRegionUpdateMutation>;
export type RegionUpdateMutationResult = Apollo.MutationResult<RegionUpdateMutation>;
export type RegionUpdateMutationOptions = Apollo.BaseMutationOptions<
  RegionUpdateMutation,
  RegionUpdateMutationVariables
>;
export const RegionCreateDocument = Apollo.gql`
    mutation RegionCreate($fullName: String!, $shortName: String!, $code: String!, $regionId: DirFederalDistrictCreateOneWithoutDirRegionsInput!) {
  createDirRegion(data: {fullName: $fullName, shortName: $shortName, code: $code, dirFederalDistrict: $regionId}) {
    ...Region
  }
}
    ${RegionFragmentDoc}`;
export type RegionCreateMutationFn = Apollo.MutationFunction<RegionCreateMutation, RegionCreateMutationVariables>;

/**
 * __useRegionCreateMutation__
 *
 * To run a mutation, you first call `useRegionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regionCreateMutation, { data, loading, error }] = useRegionCreateMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      code: // value for 'code'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useRegionCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RegionCreateMutation, RegionCreateMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RegionCreateMutation, RegionCreateMutationVariables>(
    RegionCreateDocument,
    baseOptions,
  );
}
export type RegionCreateMutationHookResult = ReturnType<typeof useRegionCreateMutation>;
export type RegionCreateMutationResult = Apollo.MutationResult<RegionCreateMutation>;
export type RegionCreateMutationOptions = Apollo.BaseMutationOptions<
  RegionCreateMutation,
  RegionCreateMutationVariables
>;
export const RegionByIdDocument = Apollo.gql`
    query RegionById($id: UUID!) {
  dirRegion(where: {id: $id}) {
    ...Region
  }
}
    ${RegionFragmentDoc}`;

/**
 * __useRegionByIdQuery__
 *
 * To run a query within a React component, call `useRegionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RegionByIdQuery, RegionByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<RegionByIdQuery, RegionByIdQueryVariables>(RegionByIdDocument, baseOptions);
}
export function useRegionByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionByIdQuery, RegionByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<RegionByIdQuery, RegionByIdQueryVariables>(RegionByIdDocument, baseOptions);
}
export type RegionByIdQueryHookResult = ReturnType<typeof useRegionByIdQuery>;
export type RegionByIdLazyQueryHookResult = ReturnType<typeof useRegionByIdLazyQuery>;
export type RegionByIdQueryResult = Apollo.QueryResult<RegionByIdQuery, RegionByIdQueryVariables>;
export const ListOrganizationOfOivDocument = Apollo.gql`
    query ListOrganizationOfOiv($id: UUID!) {
  dirOrganizations(where: {clsOrganizationCategories_some: {registryNumber: 100002468}, dirRegion: {id: $id}}) {
    value: id
    label: name
  }
}
    `;

/**
 * __useListOrganizationOfOivQuery__
 *
 * To run a query within a React component, call `useListOrganizationOfOivQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationOfOivQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationOfOivQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListOrganizationOfOivQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>(
    ListOrganizationOfOivDocument,
    baseOptions,
  );
}
export function useListOrganizationOfOivLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ListOrganizationOfOivQuery, ListOrganizationOfOivQueryVariables>(
    ListOrganizationOfOivDocument,
    baseOptions,
  );
}
export type ListOrganizationOfOivQueryHookResult = ReturnType<typeof useListOrganizationOfOivQuery>;
export type ListOrganizationOfOivLazyQueryHookResult = ReturnType<typeof useListOrganizationOfOivLazyQuery>;
export type ListOrganizationOfOivQueryResult = Apollo.QueryResult<
  ListOrganizationOfOivQuery,
  ListOrganizationOfOivQueryVariables
>;
export const ListSportsOfBaseRegionsDocument = Apollo.gql`
    query ListSportsOfBaseRegions($id: UUID!) {
  dirSports(where: {baseRegions_some: {dirRegion: {id: $id}}}) {
    value: id
    label: fullName
    baseRegions {
      id
      dirRegion {
        id
      }
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useListSportsOfBaseRegionsQuery__
 *
 * To run a query within a React component, call `useListSportsOfBaseRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSportsOfBaseRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSportsOfBaseRegionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListSportsOfBaseRegionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>(
    ListSportsOfBaseRegionsDocument,
    baseOptions,
  );
}
export function useListSportsOfBaseRegionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListSportsOfBaseRegionsQuery,
    ListSportsOfBaseRegionsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<ListSportsOfBaseRegionsQuery, ListSportsOfBaseRegionsQueryVariables>(
    ListSportsOfBaseRegionsDocument,
    baseOptions,
  );
}
export type ListSportsOfBaseRegionsQueryHookResult = ReturnType<typeof useListSportsOfBaseRegionsQuery>;
export type ListSportsOfBaseRegionsLazyQueryHookResult = ReturnType<typeof useListSportsOfBaseRegionsLazyQuery>;
export type ListSportsOfBaseRegionsQueryResult = Apollo.QueryResult<
  ListSportsOfBaseRegionsQuery,
  ListSportsOfBaseRegionsQueryVariables
>;
export const RegionsDocument = Apollo.gql`
    query Regions($first: Int!, $skip: Int!, $filter: DirRegionWhereInput) {
  dirRegions(orderBy: fullName_ASC, first: $first, skip: $skip, where: $filter) {
    ...Region
  }
  count: dirRegionsCount
}
    ${RegionFragmentDoc}`;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
  return ApolloReactHooks.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
}
export function useRegionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
}
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = Apollo.QueryResult<RegionsQuery, RegionsQueryVariables>;
export const RegionsListDocument = Apollo.gql`
    query RegionsList {
  dirRegions(orderBy: fullName_ASC) {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useRegionsListQuery__
 *
 * To run a query within a React component, call `useRegionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RegionsListQuery, RegionsListQueryVariables>,
) {
  return ApolloReactHooks.useQuery<RegionsListQuery, RegionsListQueryVariables>(RegionsListDocument, baseOptions);
}
export function useRegionsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionsListQuery, RegionsListQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<RegionsListQuery, RegionsListQueryVariables>(RegionsListDocument, baseOptions);
}
export type RegionsListQueryHookResult = ReturnType<typeof useRegionsListQuery>;
export type RegionsListLazyQueryHookResult = ReturnType<typeof useRegionsListLazyQuery>;
export type RegionsListQueryResult = Apollo.QueryResult<RegionsListQuery, RegionsListQueryVariables>;
export const DisciplineGroupsDocument = Apollo.gql`
    query DisciplineGroups($filter: DirSportDisciplineGroupWhereInput) {
  dirSportDisciplineGroups(where: $filter) {
    ...GroupDiscipline
  }
}
    ${GroupDisciplineFragmentDoc}`;

/**
 * __useDisciplineGroupsQuery__
 *
 * To run a query within a React component, call `useDisciplineGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisciplineGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisciplineGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDisciplineGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>(
    DisciplineGroupsDocument,
    baseOptions,
  );
}
export function useDisciplineGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>(
    DisciplineGroupsDocument,
    baseOptions,
  );
}
export type DisciplineGroupsQueryHookResult = ReturnType<typeof useDisciplineGroupsQuery>;
export type DisciplineGroupsLazyQueryHookResult = ReturnType<typeof useDisciplineGroupsLazyQuery>;
export type DisciplineGroupsQueryResult = Apollo.QueryResult<DisciplineGroupsQuery, DisciplineGroupsQueryVariables>;
export const EventsStatusesDocument = Apollo.gql`
    query EventsStatuses {
  dirSportingEventStatuses {
    ...EventsStatus
  }
}
    ${EventsStatusFragmentDoc}`;

/**
 * __useEventsStatusesQuery__
 *
 * To run a query within a React component, call `useEventsStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventsStatusesQuery, EventsStatusesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<EventsStatusesQuery, EventsStatusesQueryVariables>(
    EventsStatusesDocument,
    baseOptions,
  );
}
export function useEventsStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsStatusesQuery, EventsStatusesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<EventsStatusesQuery, EventsStatusesQueryVariables>(
    EventsStatusesDocument,
    baseOptions,
  );
}
export type EventsStatusesQueryHookResult = ReturnType<typeof useEventsStatusesQuery>;
export type EventsStatusesLazyQueryHookResult = ReturnType<typeof useEventsStatusesLazyQuery>;
export type EventsStatusesQueryResult = Apollo.QueryResult<EventsStatusesQuery, EventsStatusesQueryVariables>;
export const TeamStatusesDocument = Apollo.gql`
    query TeamStatuses {
  dirNationalTeamStatuses {
    ...TeamStatus
  }
}
    ${TeamStatusFragmentDoc}`;

/**
 * __useTeamStatusesQuery__
 *
 * To run a query within a React component, call `useTeamStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TeamStatusesQuery, TeamStatusesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TeamStatusesQuery, TeamStatusesQueryVariables>(TeamStatusesDocument, baseOptions);
}
export function useTeamStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamStatusesQuery, TeamStatusesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TeamStatusesQuery, TeamStatusesQueryVariables>(
    TeamStatusesDocument,
    baseOptions,
  );
}
export type TeamStatusesQueryHookResult = ReturnType<typeof useTeamStatusesQuery>;
export type TeamStatusesLazyQueryHookResult = ReturnType<typeof useTeamStatusesLazyQuery>;
export type TeamStatusesQueryResult = Apollo.QueryResult<TeamStatusesQuery, TeamStatusesQueryVariables>;
export const DirAthletesSimilarDocument = Apollo.gql`
    query dirAthletesSimilar($search: String!, $sportId: UUID, $bannedAthletes: [UUID!], $minBday: DateTime, $maxBday: DateTime) {
  dirPersonsSimilar(search: $search, where: {birthday_lte: $minBday, birthday_gte: $maxBday, athleteProfile: {id_not_in: $bannedAthletes, sports_some: {dirSport: {id: $sportId}}}}) {
    person {
      athleteProfile {
        id
      }
      registryNumber
      firstname
      patronymic
      lastname
      birthday
    }
  }
}
    `;

/**
 * __useDirAthletesSimilarQuery__
 *
 * To run a query within a React component, call `useDirAthletesSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirAthletesSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirAthletesSimilarQuery({
 *   variables: {
 *      search: // value for 'search'
 *      sportId: // value for 'sportId'
 *      bannedAthletes: // value for 'bannedAthletes'
 *      minBday: // value for 'minBday'
 *      maxBday: // value for 'maxBday'
 *   },
 * });
 */
export function useDirAthletesSimilarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>,
) {
  return ApolloReactHooks.useQuery<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>(
    DirAthletesSimilarDocument,
    baseOptions,
  );
}
export function useDirAthletesSimilarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<DirAthletesSimilarQuery, DirAthletesSimilarQueryVariables>(
    DirAthletesSimilarDocument,
    baseOptions,
  );
}
export type DirAthletesSimilarQueryHookResult = ReturnType<typeof useDirAthletesSimilarQuery>;
export type DirAthletesSimilarLazyQueryHookResult = ReturnType<typeof useDirAthletesSimilarLazyQuery>;
export type DirAthletesSimilarQueryResult = Apollo.QueryResult<
  DirAthletesSimilarQuery,
  DirAthletesSimilarQueryVariables
>;
export const DirCalendarsSimilarDocument = Apollo.gql`
    query dirCalendarsSimilar($search: String!) {
  dirCalendars(where: {fullName_contains: $search, archive: null}, orderBy: fullName_ASC) {
    id
    fullName
    shortName
    archive {
      id
    }
  }
}
    `;

/**
 * __useDirCalendarsSimilarQuery__
 *
 * To run a query within a React component, call `useDirCalendarsSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirCalendarsSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirCalendarsSimilarQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDirCalendarsSimilarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>,
) {
  return ApolloReactHooks.useQuery<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>(
    DirCalendarsSimilarDocument,
    baseOptions,
  );
}
export function useDirCalendarsSimilarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<DirCalendarsSimilarQuery, DirCalendarsSimilarQueryVariables>(
    DirCalendarsSimilarDocument,
    baseOptions,
  );
}
export type DirCalendarsSimilarQueryHookResult = ReturnType<typeof useDirCalendarsSimilarQuery>;
export type DirCalendarsSimilarLazyQueryHookResult = ReturnType<typeof useDirCalendarsSimilarLazyQuery>;
export type DirCalendarsSimilarQueryResult = Apollo.QueryResult<
  DirCalendarsSimilarQuery,
  DirCalendarsSimilarQueryVariables
>;
export const FindSimiliarSportingEventsDocument = Apollo.gql`
    query FindSimiliarSportingEvents($search: String!, $besideArray: [UUID!]) {
  dirSportingEvents(where: {name_contains: $search, id_not_in: $besideArray}) {
    value: id
    label: name
    registryNumber
  }
}
    `;

/**
 * __useFindSimiliarSportingEventsQuery__
 *
 * To run a query within a React component, call `useFindSimiliarSportingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimiliarSportingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimiliarSportingEventsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      besideArray: // value for 'besideArray'
 *   },
 * });
 */
export function useFindSimiliarSportingEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindSimiliarSportingEventsQuery,
    FindSimiliarSportingEventsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<FindSimiliarSportingEventsQuery, FindSimiliarSportingEventsQueryVariables>(
    FindSimiliarSportingEventsDocument,
    baseOptions,
  );
}
export function useFindSimiliarSportingEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindSimiliarSportingEventsQuery,
    FindSimiliarSportingEventsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<FindSimiliarSportingEventsQuery, FindSimiliarSportingEventsQueryVariables>(
    FindSimiliarSportingEventsDocument,
    baseOptions,
  );
}
export type FindSimiliarSportingEventsQueryHookResult = ReturnType<typeof useFindSimiliarSportingEventsQuery>;
export type FindSimiliarSportingEventsLazyQueryHookResult = ReturnType<typeof useFindSimiliarSportingEventsLazyQuery>;
export type FindSimiliarSportingEventsQueryResult = Apollo.QueryResult<
  FindSimiliarSportingEventsQuery,
  FindSimiliarSportingEventsQueryVariables
>;
export const DirTrainerSimilarDocument = Apollo.gql`
    query dirTrainerSimilar($search: String!, $bannedTrainers: [UUID!], $sportId: [UUID!]) {
  dirPersonsSimilar(search: $search, where: {trainerProfile: {id_not_in: $bannedTrainers, sports_some: {dirSport: {id_in: $sportId}}}}) {
    person {
      trainerProfile {
        id
      }
      registryNumber
      firstname
      patronymic
      lastname
      birthday
    }
  }
}
    `;

/**
 * __useDirTrainerSimilarQuery__
 *
 * To run a query within a React component, call `useDirTrainerSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirTrainerSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirTrainerSimilarQuery({
 *   variables: {
 *      search: // value for 'search'
 *      bannedTrainers: // value for 'bannedTrainers'
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useDirTrainerSimilarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>,
) {
  return ApolloReactHooks.useQuery<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>(
    DirTrainerSimilarDocument,
    baseOptions,
  );
}
export function useDirTrainerSimilarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>(
    DirTrainerSimilarDocument,
    baseOptions,
  );
}
export type DirTrainerSimilarQueryHookResult = ReturnType<typeof useDirTrainerSimilarQuery>;
export type DirTrainerSimilarLazyQueryHookResult = ReturnType<typeof useDirTrainerSimilarLazyQuery>;
export type DirTrainerSimilarQueryResult = Apollo.QueryResult<DirTrainerSimilarQuery, DirTrainerSimilarQueryVariables>;
export const CreateSportingEventDocument = Apollo.gql`
    mutation CreateSportingEvent($data: DirSportingEventCreateInput!) {
  createDirSportingEvent(data: $data) {
    ...SportingEvent
  }
}
    ${SportingEventFragmentDoc}`;
export type CreateSportingEventMutationFn = Apollo.MutationFunction<
  CreateSportingEventMutation,
  CreateSportingEventMutationVariables
>;

/**
 * __useCreateSportingEventMutation__
 *
 * To run a mutation, you first call `useCreateSportingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportingEventMutation, { data, loading, error }] = useCreateSportingEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSportingEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSportingEventMutation, CreateSportingEventMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateSportingEventMutation, CreateSportingEventMutationVariables>(
    CreateSportingEventDocument,
    baseOptions,
  );
}
export type CreateSportingEventMutationHookResult = ReturnType<typeof useCreateSportingEventMutation>;
export type CreateSportingEventMutationResult = Apollo.MutationResult<CreateSportingEventMutation>;
export type CreateSportingEventMutationOptions = Apollo.BaseMutationOptions<
  CreateSportingEventMutation,
  CreateSportingEventMutationVariables
>;
export const UpdateSportingEventDocument = Apollo.gql`
    mutation UpdateSportingEvent($id: UUID!, $data: DirSportingEventUpdateInput!) {
  updateDirSportingEvent(data: $data, where: {id: $id}) {
    ...SportingEvent
  }
}
    ${SportingEventFragmentDoc}`;
export type UpdateSportingEventMutationFn = Apollo.MutationFunction<
  UpdateSportingEventMutation,
  UpdateSportingEventMutationVariables
>;

/**
 * __useUpdateSportingEventMutation__
 *
 * To run a mutation, you first call `useUpdateSportingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportingEventMutation, { data, loading, error }] = useUpdateSportingEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSportingEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSportingEventMutation, UpdateSportingEventMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateSportingEventMutation, UpdateSportingEventMutationVariables>(
    UpdateSportingEventDocument,
    baseOptions,
  );
}
export type UpdateSportingEventMutationHookResult = ReturnType<typeof useUpdateSportingEventMutation>;
export type UpdateSportingEventMutationResult = Apollo.MutationResult<UpdateSportingEventMutation>;
export type UpdateSportingEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateSportingEventMutation,
  UpdateSportingEventMutationVariables
>;
export const SportingEventArchiveDocument = Apollo.gql`
    mutation SportingEventArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSportingEvent(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type SportingEventArchiveMutationFn = Apollo.MutationFunction<
  SportingEventArchiveMutation,
  SportingEventArchiveMutationVariables
>;

/**
 * __useSportingEventArchiveMutation__
 *
 * To run a mutation, you first call `useSportingEventArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportingEventArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportingEventArchiveMutation, { data, loading, error }] = useSportingEventArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useSportingEventArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SportingEventArchiveMutation,
    SportingEventArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<SportingEventArchiveMutation, SportingEventArchiveMutationVariables>(
    SportingEventArchiveDocument,
    baseOptions,
  );
}
export type SportingEventArchiveMutationHookResult = ReturnType<typeof useSportingEventArchiveMutation>;
export type SportingEventArchiveMutationResult = Apollo.MutationResult<SportingEventArchiveMutation>;
export type SportingEventArchiveMutationOptions = Apollo.BaseMutationOptions<
  SportingEventArchiveMutation,
  SportingEventArchiveMutationVariables
>;
export const SportingEventUnArchiveDocument = Apollo.gql`
    mutation SportingEventUnArchive($id: UUID!) {
  updateDirSportingEvent(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type SportingEventUnArchiveMutationFn = Apollo.MutationFunction<
  SportingEventUnArchiveMutation,
  SportingEventUnArchiveMutationVariables
>;

/**
 * __useSportingEventUnArchiveMutation__
 *
 * To run a mutation, you first call `useSportingEventUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportingEventUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportingEventUnArchiveMutation, { data, loading, error }] = useSportingEventUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SportingEventUnArchiveMutation,
    SportingEventUnArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<SportingEventUnArchiveMutation, SportingEventUnArchiveMutationVariables>(
    SportingEventUnArchiveDocument,
    baseOptions,
  );
}
export type SportingEventUnArchiveMutationHookResult = ReturnType<typeof useSportingEventUnArchiveMutation>;
export type SportingEventUnArchiveMutationResult = Apollo.MutationResult<SportingEventUnArchiveMutation>;
export type SportingEventUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  SportingEventUnArchiveMutation,
  SportingEventUnArchiveMutationVariables
>;
export const SportingEventsDocument = Apollo.gql`
    query SportingEvents($first: Int!, $skip: Int!, $filter: DirSportingEventWhereInput) {
  dirSportingEvents(first: $first, skip: $skip, where: $filter) {
    ...SportingEvent
  }
  count: dirSportingEventsCount(where: $filter)
}
    ${SportingEventFragmentDoc}`;

/**
 * __useSportingEventsQuery__
 *
 * To run a query within a React component, call `useSportingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventsQuery, SportingEventsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<SportingEventsQuery, SportingEventsQueryVariables>(
    SportingEventsDocument,
    baseOptions,
  );
}
export function useSportingEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventsQuery, SportingEventsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<SportingEventsQuery, SportingEventsQueryVariables>(
    SportingEventsDocument,
    baseOptions,
  );
}
export type SportingEventsQueryHookResult = ReturnType<typeof useSportingEventsQuery>;
export type SportingEventsLazyQueryHookResult = ReturnType<typeof useSportingEventsLazyQuery>;
export type SportingEventsQueryResult = Apollo.QueryResult<SportingEventsQuery, SportingEventsQueryVariables>;
export const SportingEventsCountDocument = Apollo.gql`
    query SportingEventsCount($filter: DirSportingEventWhereInput) {
  count: dirSportingEventsCount(where: $filter)
}
    `;

/**
 * __useSportingEventsCountQuery__
 *
 * To run a query within a React component, call `useSportingEventsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSportingEventsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventsCountQuery, SportingEventsCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<SportingEventsCountQuery, SportingEventsCountQueryVariables>(
    SportingEventsCountDocument,
    baseOptions,
  );
}
export function useSportingEventsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventsCountQuery, SportingEventsCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<SportingEventsCountQuery, SportingEventsCountQueryVariables>(
    SportingEventsCountDocument,
    baseOptions,
  );
}
export type SportingEventsCountQueryHookResult = ReturnType<typeof useSportingEventsCountQuery>;
export type SportingEventsCountLazyQueryHookResult = ReturnType<typeof useSportingEventsCountLazyQuery>;
export type SportingEventsCountQueryResult = Apollo.QueryResult<
  SportingEventsCountQuery,
  SportingEventsCountQueryVariables
>;
export const SportingEventDocument = Apollo.gql`
    query SportingEvent($id: UUID!) {
  dirSportingEvent(where: {id: $id}) {
    ...SportingEvent
  }
}
    ${SportingEventFragmentDoc}`;

/**
 * __useSportingEventQuery__
 *
 * To run a query within a React component, call `useSportingEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportingEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportingEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSportingEventQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SportingEventQuery, SportingEventQueryVariables>,
) {
  return ApolloReactHooks.useQuery<SportingEventQuery, SportingEventQueryVariables>(SportingEventDocument, baseOptions);
}
export function useSportingEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportingEventQuery, SportingEventQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<SportingEventQuery, SportingEventQueryVariables>(
    SportingEventDocument,
    baseOptions,
  );
}
export type SportingEventQueryHookResult = ReturnType<typeof useSportingEventQuery>;
export type SportingEventLazyQueryHookResult = ReturnType<typeof useSportingEventLazyQuery>;
export type SportingEventQueryResult = Apollo.QueryResult<SportingEventQuery, SportingEventQueryVariables>;
export const TrainersDocument = Apollo.gql`
    query Trainers($first: Int!, $skip: Int!, $filter: DirTrainerWhereInput) {
  dirTrainers(first: $first, skip: $skip, where: $filter) {
    id
    registryNumber
    archive {
      id
    }
    dirPerson {
      ...PersonFR
    }
    sports {
      dirSport {
        fullName
      }
    }
  }
  count: dirTrainersCount(where: $filter)
}
    ${PersonFrFragmentDoc}`;

/**
 * __useTrainersQuery__
 *
 * To run a query within a React component, call `useTrainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersQuery, TrainersQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TrainersQuery, TrainersQueryVariables>(TrainersDocument, baseOptions);
}
export function useTrainersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersQuery, TrainersQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TrainersQuery, TrainersQueryVariables>(TrainersDocument, baseOptions);
}
export type TrainersQueryHookResult = ReturnType<typeof useTrainersQuery>;
export type TrainersLazyQueryHookResult = ReturnType<typeof useTrainersLazyQuery>;
export type TrainersQueryResult = Apollo.QueryResult<TrainersQuery, TrainersQueryVariables>;
export const TrainersCountDocument = Apollo.gql`
    query TrainersCount($filter: DirTrainerWhereInput) {
  count: dirTrainersCount(where: $filter)
}
    `;

/**
 * __useTrainersCountQuery__
 *
 * To run a query within a React component, call `useTrainersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersCountQuery, TrainersCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TrainersCountQuery, TrainersCountQueryVariables>(TrainersCountDocument, baseOptions);
}
export function useTrainersCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersCountQuery, TrainersCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TrainersCountQuery, TrainersCountQueryVariables>(
    TrainersCountDocument,
    baseOptions,
  );
}
export type TrainersCountQueryHookResult = ReturnType<typeof useTrainersCountQuery>;
export type TrainersCountLazyQueryHookResult = ReturnType<typeof useTrainersCountLazyQuery>;
export type TrainersCountQueryResult = Apollo.QueryResult<TrainersCountQuery, TrainersCountQueryVariables>;
export const TrainerByIdDocument = Apollo.gql`
    query TrainerById($id: UUID!) {
  dirTrainer(where: {id: $id}) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;

/**
 * __useTrainerByIdQuery__
 *
 * To run a query within a React component, call `useTrainerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrainerByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TrainerByIdQuery, TrainerByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TrainerByIdQuery, TrainerByIdQueryVariables>(TrainerByIdDocument, baseOptions);
}
export function useTrainerByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainerByIdQuery, TrainerByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TrainerByIdQuery, TrainerByIdQueryVariables>(TrainerByIdDocument, baseOptions);
}
export type TrainerByIdQueryHookResult = ReturnType<typeof useTrainerByIdQuery>;
export type TrainerByIdLazyQueryHookResult = ReturnType<typeof useTrainerByIdLazyQuery>;
export type TrainerByIdQueryResult = Apollo.QueryResult<TrainerByIdQuery, TrainerByIdQueryVariables>;
export const TrainersFilteredDocument = Apollo.gql`
    query TrainersFiltered($filter: DirTrainerWhereInput) {
  dirTrainers(where: $filter) {
    ...TrainerFR
  }
}
    ${TrainerFrFragmentDoc}`;

/**
 * __useTrainersFilteredQuery__
 *
 * To run a query within a React component, call `useTrainersFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersFilteredQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrainersFilteredQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersFilteredQuery, TrainersFilteredQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TrainersFilteredQuery, TrainersFilteredQueryVariables>(
    TrainersFilteredDocument,
    baseOptions,
  );
}
export function useTrainersFilteredLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersFilteredQuery, TrainersFilteredQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TrainersFilteredQuery, TrainersFilteredQueryVariables>(
    TrainersFilteredDocument,
    baseOptions,
  );
}
export type TrainersFilteredQueryHookResult = ReturnType<typeof useTrainersFilteredQuery>;
export type TrainersFilteredLazyQueryHookResult = ReturnType<typeof useTrainersFilteredLazyQuery>;
export type TrainersFilteredQueryResult = Apollo.QueryResult<TrainersFilteredQuery, TrainersFilteredQueryVariables>;
export const TypeSportAgeGroupsRemoveDocument = Apollo.gql`
    mutation TypeSportAgeGroupsRemove($id: UUID!, $idCls: UUID!) {
  updateDirSport(where: {id: $id}, data: {ageGroups: {delete: {id: $idCls}}}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;
export type TypeSportAgeGroupsRemoveMutationFn = Apollo.MutationFunction<
  TypeSportAgeGroupsRemoveMutation,
  TypeSportAgeGroupsRemoveMutationVariables
>;

/**
 * __useTypeSportAgeGroupsRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsRemoveMutation, { data, loading, error }] = useTypeSportAgeGroupsRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCls: // value for 'idCls'
 *   },
 * });
 */
export function useTypeSportAgeGroupsRemoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportAgeGroupsRemoveMutation,
    TypeSportAgeGroupsRemoveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportAgeGroupsRemoveMutation, TypeSportAgeGroupsRemoveMutationVariables>(
    TypeSportAgeGroupsRemoveDocument,
    baseOptions,
  );
}
export type TypeSportAgeGroupsRemoveMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsRemoveMutation>;
export type TypeSportAgeGroupsRemoveMutationResult = Apollo.MutationResult<TypeSportAgeGroupsRemoveMutation>;
export type TypeSportAgeGroupsRemoveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportAgeGroupsRemoveMutation,
  TypeSportAgeGroupsRemoveMutationVariables
>;
export const TypeSportAgeGroupsUpdateDocument = Apollo.gql`
    mutation TypeSportAgeGroupsUpdate($id: UUID!, $idGroup: UUID!, $idCls: UUID!, $minAge: Int!, $maxAge: Int) {
  updateDirSport(where: {id: $id}, data: {ageGroups: {update: {where: {id: $idGroup}, data: {clsAgeGroup: {connect: {id: $idCls}}, minAge: $minAge, maxAge: $maxAge}}}}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;
export type TypeSportAgeGroupsUpdateMutationFn = Apollo.MutationFunction<
  TypeSportAgeGroupsUpdateMutation,
  TypeSportAgeGroupsUpdateMutationVariables
>;

/**
 * __useTypeSportAgeGroupsUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsUpdateMutation, { data, loading, error }] = useTypeSportAgeGroupsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      idCls: // value for 'idCls'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *   },
 * });
 */
export function useTypeSportAgeGroupsUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportAgeGroupsUpdateMutation,
    TypeSportAgeGroupsUpdateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportAgeGroupsUpdateMutation, TypeSportAgeGroupsUpdateMutationVariables>(
    TypeSportAgeGroupsUpdateDocument,
    baseOptions,
  );
}
export type TypeSportAgeGroupsUpdateMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsUpdateMutation>;
export type TypeSportAgeGroupsUpdateMutationResult = Apollo.MutationResult<TypeSportAgeGroupsUpdateMutation>;
export type TypeSportAgeGroupsUpdateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportAgeGroupsUpdateMutation,
  TypeSportAgeGroupsUpdateMutationVariables
>;
export const TypeSportAgeGroupsCreateDocument = Apollo.gql`
    mutation TypeSportAgeGroupsCreate($id: UUID!, $idCls: UUID!, $minAge: Int!, $maxAge: Int) {
  updateDirSport(where: {id: $id}, data: {ageGroups: {create: {clsAgeGroup: {connect: {id: $idCls}}, minAge: $minAge, maxAge: $maxAge}}}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;
export type TypeSportAgeGroupsCreateMutationFn = Apollo.MutationFunction<
  TypeSportAgeGroupsCreateMutation,
  TypeSportAgeGroupsCreateMutationVariables
>;

/**
 * __useTypeSportAgeGroupsCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportAgeGroupsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportAgeGroupsCreateMutation, { data, loading, error }] = useTypeSportAgeGroupsCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idCls: // value for 'idCls'
 *      minAge: // value for 'minAge'
 *      maxAge: // value for 'maxAge'
 *   },
 * });
 */
export function useTypeSportAgeGroupsCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportAgeGroupsCreateMutation,
    TypeSportAgeGroupsCreateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportAgeGroupsCreateMutation, TypeSportAgeGroupsCreateMutationVariables>(
    TypeSportAgeGroupsCreateDocument,
    baseOptions,
  );
}
export type TypeSportAgeGroupsCreateMutationHookResult = ReturnType<typeof useTypeSportAgeGroupsCreateMutation>;
export type TypeSportAgeGroupsCreateMutationResult = Apollo.MutationResult<TypeSportAgeGroupsCreateMutation>;
export type TypeSportAgeGroupsCreateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportAgeGroupsCreateMutation,
  TypeSportAgeGroupsCreateMutationVariables
>;
export const TypeSportAgeGroupsDocument = Apollo.gql`
    query TypeSportAgeGroups($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...AgeGroup
  }
}
    ${AgeGroupFragmentDoc}`;

/**
 * __useTypeSportAgeGroupsQuery__
 *
 * To run a query within a React component, call `useTypeSportAgeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportAgeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportAgeGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportAgeGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>(
    TypeSportAgeGroupsDocument,
    baseOptions,
  );
}
export function useTypeSportAgeGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportAgeGroupsQuery, TypeSportAgeGroupsQueryVariables>(
    TypeSportAgeGroupsDocument,
    baseOptions,
  );
}
export type TypeSportAgeGroupsQueryHookResult = ReturnType<typeof useTypeSportAgeGroupsQuery>;
export type TypeSportAgeGroupsLazyQueryHookResult = ReturnType<typeof useTypeSportAgeGroupsLazyQuery>;
export type TypeSportAgeGroupsQueryResult = Apollo.QueryResult<
  TypeSportAgeGroupsQuery,
  TypeSportAgeGroupsQueryVariables
>;
export const RemoveBaseRegionByIdDocument = Apollo.gql`
    mutation RemoveBaseRegionByID($id: UUID!, $idBaseRegion: UUID!) {
  updateDirSport(where: {id: $id}, data: {baseRegions: {delete: {id: $idBaseRegion}}}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;
export type RemoveBaseRegionByIdMutationFn = Apollo.MutationFunction<
  RemoveBaseRegionByIdMutation,
  RemoveBaseRegionByIdMutationVariables
>;

/**
 * __useRemoveBaseRegionByIdMutation__
 *
 * To run a mutation, you first call `useRemoveBaseRegionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBaseRegionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBaseRegionByIdMutation, { data, loading, error }] = useRemoveBaseRegionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idBaseRegion: // value for 'idBaseRegion'
 *   },
 * });
 */
export function useRemoveBaseRegionByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveBaseRegionByIdMutation,
    RemoveBaseRegionByIdMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<RemoveBaseRegionByIdMutation, RemoveBaseRegionByIdMutationVariables>(
    RemoveBaseRegionByIdDocument,
    baseOptions,
  );
}
export type RemoveBaseRegionByIdMutationHookResult = ReturnType<typeof useRemoveBaseRegionByIdMutation>;
export type RemoveBaseRegionByIdMutationResult = Apollo.MutationResult<RemoveBaseRegionByIdMutation>;
export type RemoveBaseRegionByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveBaseRegionByIdMutation,
  RemoveBaseRegionByIdMutationVariables
>;
export const UpdateBaseRegionByIdDocument = Apollo.gql`
    mutation UpdateBaseRegionByID($id: UUID!, $idBaseRegion: UUID!, $idRegion: UUID!, $startDate: DateTime, $endDate: DateTime) {
  updateDirSport(where: {id: $id}, data: {baseRegions: {update: {where: {id: $idBaseRegion}, data: {dirRegion: {connect: {id: $idRegion}}, startDate: $startDate, endDate: $endDate}}}}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;
export type UpdateBaseRegionByIdMutationFn = Apollo.MutationFunction<
  UpdateBaseRegionByIdMutation,
  UpdateBaseRegionByIdMutationVariables
>;

/**
 * __useUpdateBaseRegionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBaseRegionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaseRegionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaseRegionByIdMutation, { data, loading, error }] = useUpdateBaseRegionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idBaseRegion: // value for 'idBaseRegion'
 *      idRegion: // value for 'idRegion'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateBaseRegionByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBaseRegionByIdMutation,
    UpdateBaseRegionByIdMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateBaseRegionByIdMutation, UpdateBaseRegionByIdMutationVariables>(
    UpdateBaseRegionByIdDocument,
    baseOptions,
  );
}
export type UpdateBaseRegionByIdMutationHookResult = ReturnType<typeof useUpdateBaseRegionByIdMutation>;
export type UpdateBaseRegionByIdMutationResult = Apollo.MutationResult<UpdateBaseRegionByIdMutation>;
export type UpdateBaseRegionByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBaseRegionByIdMutation,
  UpdateBaseRegionByIdMutationVariables
>;
export const CreteBaseRegionDocument = Apollo.gql`
    mutation CreteBaseRegion($id: UUID!, $idRegion: UUID!, $startDate: DateTime, $endDate: DateTime) {
  updateDirSport(where: {id: $id}, data: {baseRegions: {create: {dirRegion: {connect: {id: $idRegion}}, startDate: $startDate, endDate: $endDate}}}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;
export type CreteBaseRegionMutationFn = Apollo.MutationFunction<
  CreteBaseRegionMutation,
  CreteBaseRegionMutationVariables
>;

/**
 * __useCreteBaseRegionMutation__
 *
 * To run a mutation, you first call `useCreteBaseRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreteBaseRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creteBaseRegionMutation, { data, loading, error }] = useCreteBaseRegionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idRegion: // value for 'idRegion'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreteBaseRegionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreteBaseRegionMutation, CreteBaseRegionMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreteBaseRegionMutation, CreteBaseRegionMutationVariables>(
    CreteBaseRegionDocument,
    baseOptions,
  );
}
export type CreteBaseRegionMutationHookResult = ReturnType<typeof useCreteBaseRegionMutation>;
export type CreteBaseRegionMutationResult = Apollo.MutationResult<CreteBaseRegionMutation>;
export type CreteBaseRegionMutationOptions = Apollo.BaseMutationOptions<
  CreteBaseRegionMutation,
  CreteBaseRegionMutationVariables
>;
export const BaseRegionsByIdDocument = Apollo.gql`
    query BaseRegionsById($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    ...BaseRegion
  }
}
    ${BaseRegionFragmentDoc}`;

/**
 * __useBaseRegionsByIdQuery__
 *
 * To run a query within a React component, call `useBaseRegionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseRegionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseRegionsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBaseRegionsByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>,
) {
  return ApolloReactHooks.useQuery<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>(
    BaseRegionsByIdDocument,
    baseOptions,
  );
}
export function useBaseRegionsByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>(
    BaseRegionsByIdDocument,
    baseOptions,
  );
}
export type BaseRegionsByIdQueryHookResult = ReturnType<typeof useBaseRegionsByIdQuery>;
export type BaseRegionsByIdLazyQueryHookResult = ReturnType<typeof useBaseRegionsByIdLazyQuery>;
export type BaseRegionsByIdQueryResult = Apollo.QueryResult<BaseRegionsByIdQuery, BaseRegionsByIdQueryVariables>;
export const TypeSportDisciplinesUpdateDocument = Apollo.gql`
    mutation TypeSportDisciplinesUpdate($id: UUID!, $idDiscipline: UUID!, $name: String!, $code: String) {
  updateDirSport(where: {id: $id}, data: {disciplines: {update: {where: {id: $idDiscipline}, data: {name: $name, code: $code}}}}) {
    ...Disciplines
  }
}
    ${DisciplinesFragmentDoc}`;
export type TypeSportDisciplinesUpdateMutationFn = Apollo.MutationFunction<
  TypeSportDisciplinesUpdateMutation,
  TypeSportDisciplinesUpdateMutationVariables
>;

/**
 * __useTypeSportDisciplinesUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesUpdateMutation, { data, loading, error }] = useTypeSportDisciplinesUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idDiscipline: // value for 'idDiscipline'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTypeSportDisciplinesUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportDisciplinesUpdateMutation,
    TypeSportDisciplinesUpdateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportDisciplinesUpdateMutation, TypeSportDisciplinesUpdateMutationVariables>(
    TypeSportDisciplinesUpdateDocument,
    baseOptions,
  );
}
export type TypeSportDisciplinesUpdateMutationHookResult = ReturnType<typeof useTypeSportDisciplinesUpdateMutation>;
export type TypeSportDisciplinesUpdateMutationResult = Apollo.MutationResult<TypeSportDisciplinesUpdateMutation>;
export type TypeSportDisciplinesUpdateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportDisciplinesUpdateMutation,
  TypeSportDisciplinesUpdateMutationVariables
>;
export const TypeSportDisciplinesCreateDocument = Apollo.gql`
    mutation TypeSportDisciplinesCreate($id: UUID!, $name: String!, $code: String) {
  updateDirSport(where: {id: $id}, data: {disciplines: {create: {name: $name, code: $code}}}) {
    ...Disciplines
  }
}
    ${DisciplinesFragmentDoc}`;
export type TypeSportDisciplinesCreateMutationFn = Apollo.MutationFunction<
  TypeSportDisciplinesCreateMutation,
  TypeSportDisciplinesCreateMutationVariables
>;

/**
 * __useTypeSportDisciplinesCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesCreateMutation, { data, loading, error }] = useTypeSportDisciplinesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTypeSportDisciplinesCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportDisciplinesCreateMutation,
    TypeSportDisciplinesCreateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<TypeSportDisciplinesCreateMutation, TypeSportDisciplinesCreateMutationVariables>(
    TypeSportDisciplinesCreateDocument,
    baseOptions,
  );
}
export type TypeSportDisciplinesCreateMutationHookResult = ReturnType<typeof useTypeSportDisciplinesCreateMutation>;
export type TypeSportDisciplinesCreateMutationResult = Apollo.MutationResult<TypeSportDisciplinesCreateMutation>;
export type TypeSportDisciplinesCreateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportDisciplinesCreateMutation,
  TypeSportDisciplinesCreateMutationVariables
>;
export const TypeSportDisciplinesArchiveDocument = Apollo.gql`
    mutation TypeSportDisciplinesArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSportDiscipline(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportDisciplinesArchiveMutationFn = Apollo.MutationFunction<
  TypeSportDisciplinesArchiveMutation,
  TypeSportDisciplinesArchiveMutationVariables
>;

/**
 * __useTypeSportDisciplinesArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesArchiveMutation, { data, loading, error }] = useTypeSportDisciplinesArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportDisciplinesArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportDisciplinesArchiveMutation,
    TypeSportDisciplinesArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportDisciplinesArchiveMutation,
    TypeSportDisciplinesArchiveMutationVariables
  >(TypeSportDisciplinesArchiveDocument, baseOptions);
}
export type TypeSportDisciplinesArchiveMutationHookResult = ReturnType<typeof useTypeSportDisciplinesArchiveMutation>;
export type TypeSportDisciplinesArchiveMutationResult = Apollo.MutationResult<TypeSportDisciplinesArchiveMutation>;
export type TypeSportDisciplinesArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportDisciplinesArchiveMutation,
  TypeSportDisciplinesArchiveMutationVariables
>;
export const TypeSportDisciplinesArchiveRemoveDocument = Apollo.gql`
    mutation TypeSportDisciplinesArchiveRemove($id: UUID!) {
  updateDirSportDiscipline(where: {id: $id}, data: {archive: {delete: true}}) {
    id
  }
}
    `;
export type TypeSportDisciplinesArchiveRemoveMutationFn = Apollo.MutationFunction<
  TypeSportDisciplinesArchiveRemoveMutation,
  TypeSportDisciplinesArchiveRemoveMutationVariables
>;

/**
 * __useTypeSportDisciplinesArchiveRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportDisciplinesArchiveRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesArchiveRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportDisciplinesArchiveRemoveMutation, { data, loading, error }] = useTypeSportDisciplinesArchiveRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportDisciplinesArchiveRemoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportDisciplinesArchiveRemoveMutation,
    TypeSportDisciplinesArchiveRemoveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportDisciplinesArchiveRemoveMutation,
    TypeSportDisciplinesArchiveRemoveMutationVariables
  >(TypeSportDisciplinesArchiveRemoveDocument, baseOptions);
}
export type TypeSportDisciplinesArchiveRemoveMutationHookResult = ReturnType<
  typeof useTypeSportDisciplinesArchiveRemoveMutation
>;
export type TypeSportDisciplinesArchiveRemoveMutationResult = Apollo.MutationResult<
  TypeSportDisciplinesArchiveRemoveMutation
>;
export type TypeSportDisciplinesArchiveRemoveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportDisciplinesArchiveRemoveMutation,
  TypeSportDisciplinesArchiveRemoveMutationVariables
>;
export const TypeSportDisciplinesDocument = Apollo.gql`
    query TypeSportDisciplines($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...Disciplines
  }
}
    ${DisciplinesFragmentDoc}`;

/**
 * __useTypeSportDisciplinesQuery__
 *
 * To run a query within a React component, call `useTypeSportDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportDisciplinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportDisciplinesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>(
    TypeSportDisciplinesDocument,
    baseOptions,
  );
}
export function useTypeSportDisciplinesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportDisciplinesQuery, TypeSportDisciplinesQueryVariables>(
    TypeSportDisciplinesDocument,
    baseOptions,
  );
}
export type TypeSportDisciplinesQueryHookResult = ReturnType<typeof useTypeSportDisciplinesQuery>;
export type TypeSportDisciplinesLazyQueryHookResult = ReturnType<typeof useTypeSportDisciplinesLazyQuery>;
export type TypeSportDisciplinesQueryResult = Apollo.QueryResult<
  TypeSportDisciplinesQuery,
  TypeSportDisciplinesQueryVariables
>;
export const TypeSportDisciplinesDrillDocument = Apollo.gql`
    query TypeSportDisciplinesDrill($filter: DirSportDisciplineWhereInput) {
  dirSportDisciplines(where: $filter) {
    value: id
    label: name
  }
}
    `;

/**
 * __useTypeSportDisciplinesDrillQuery__
 *
 * To run a query within a React component, call `useTypeSportDisciplinesDrillQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportDisciplinesDrillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportDisciplinesDrillQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTypeSportDisciplinesDrillQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TypeSportDisciplinesDrillQuery,
    TypeSportDisciplinesDrillQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<TypeSportDisciplinesDrillQuery, TypeSportDisciplinesDrillQueryVariables>(
    TypeSportDisciplinesDrillDocument,
    baseOptions,
  );
}
export function useTypeSportDisciplinesDrillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TypeSportDisciplinesDrillQuery,
    TypeSportDisciplinesDrillQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportDisciplinesDrillQuery, TypeSportDisciplinesDrillQueryVariables>(
    TypeSportDisciplinesDrillDocument,
    baseOptions,
  );
}
export type TypeSportDisciplinesDrillQueryHookResult = ReturnType<typeof useTypeSportDisciplinesDrillQuery>;
export type TypeSportDisciplinesDrillLazyQueryHookResult = ReturnType<typeof useTypeSportDisciplinesDrillLazyQuery>;
export type TypeSportDisciplinesDrillQueryResult = Apollo.QueryResult<
  TypeSportDisciplinesDrillQuery,
  TypeSportDisciplinesDrillQueryVariables
>;
export const MultipleTypeSportDisciplinesDrillDocument = Apollo.gql`
    query MultipleTypeSportDisciplinesDrill($id: [UUID!]) {
  dirSports(where: {id_in: $id}) {
    id
    disciplines {
      typeSport: dirSport {
        id
      }
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useMultipleTypeSportDisciplinesDrillQuery__
 *
 * To run a query within a React component, call `useMultipleTypeSportDisciplinesDrillQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultipleTypeSportDisciplinesDrillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultipleTypeSportDisciplinesDrillQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMultipleTypeSportDisciplinesDrillQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MultipleTypeSportDisciplinesDrillQuery,
    MultipleTypeSportDisciplinesDrillQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    MultipleTypeSportDisciplinesDrillQuery,
    MultipleTypeSportDisciplinesDrillQueryVariables
  >(MultipleTypeSportDisciplinesDrillDocument, baseOptions);
}
export function useMultipleTypeSportDisciplinesDrillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MultipleTypeSportDisciplinesDrillQuery,
    MultipleTypeSportDisciplinesDrillQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    MultipleTypeSportDisciplinesDrillQuery,
    MultipleTypeSportDisciplinesDrillQueryVariables
  >(MultipleTypeSportDisciplinesDrillDocument, baseOptions);
}
export type MultipleTypeSportDisciplinesDrillQueryHookResult = ReturnType<
  typeof useMultipleTypeSportDisciplinesDrillQuery
>;
export type MultipleTypeSportDisciplinesDrillLazyQueryHookResult = ReturnType<
  typeof useMultipleTypeSportDisciplinesDrillLazyQuery
>;
export type MultipleTypeSportDisciplinesDrillQueryResult = Apollo.QueryResult<
  MultipleTypeSportDisciplinesDrillQuery,
  MultipleTypeSportDisciplinesDrillQueryVariables
>;
export const GenerateXlsFileDocument = Apollo.gql`
    query generateXlsFile($filterString: String!, $filter: DirSportWhereInput!, $first: Int!) {
  dirSportsReport {
    xlsx(where: $filter, orderBy: registryNumber_DESC, first: $first, filter: $filterString, registryNumberAlias: "Идентификатор вида спорта", evskAlias: "Номер ЕВСК", fullNameAlias: "Название", archiveAlias: "В архиве")
  }
}
    `;

/**
 * __useGenerateXlsFileQuery__
 *
 * To run a query within a React component, call `useGenerateXlsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateXlsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateXlsFileQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGenerateXlsFileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>(
    GenerateXlsFileDocument,
    baseOptions,
  );
}
export function useGenerateXlsFileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>(
    GenerateXlsFileDocument,
    baseOptions,
  );
}
export type GenerateXlsFileQueryHookResult = ReturnType<typeof useGenerateXlsFileQuery>;
export type GenerateXlsFileLazyQueryHookResult = ReturnType<typeof useGenerateXlsFileLazyQuery>;
export type GenerateXlsFileQueryResult = Apollo.QueryResult<GenerateXlsFileQuery, GenerateXlsFileQueryVariables>;
export const GroupTypesSportAndDisciplinesRemoveDocument = Apollo.gql`
    mutation GroupTypesSportAndDisciplinesRemove($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsSportAndDisciplineGroups: {disconnect: {id: $idItem}}}) {
    id
    clsSportAndDisciplineGroups(orderBy: fullName_ASC) {
      fullName
      order
      id
    }
  }
}
    `;
export type GroupTypesSportAndDisciplinesRemoveMutationFn = Apollo.MutationFunction<
  GroupTypesSportAndDisciplinesRemoveMutation,
  GroupTypesSportAndDisciplinesRemoveMutationVariables
>;

/**
 * __useGroupTypesSportAndDisciplinesRemoveMutation__
 *
 * To run a mutation, you first call `useGroupTypesSportAndDisciplinesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupTypesSportAndDisciplinesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupTypesSportAndDisciplinesRemoveMutation, { data, loading, error }] = useGroupTypesSportAndDisciplinesRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useGroupTypesSportAndDisciplinesRemoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GroupTypesSportAndDisciplinesRemoveMutation,
    GroupTypesSportAndDisciplinesRemoveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    GroupTypesSportAndDisciplinesRemoveMutation,
    GroupTypesSportAndDisciplinesRemoveMutationVariables
  >(GroupTypesSportAndDisciplinesRemoveDocument, baseOptions);
}
export type GroupTypesSportAndDisciplinesRemoveMutationHookResult = ReturnType<
  typeof useGroupTypesSportAndDisciplinesRemoveMutation
>;
export type GroupTypesSportAndDisciplinesRemoveMutationResult = Apollo.MutationResult<
  GroupTypesSportAndDisciplinesRemoveMutation
>;
export type GroupTypesSportAndDisciplinesRemoveMutationOptions = Apollo.BaseMutationOptions<
  GroupTypesSportAndDisciplinesRemoveMutation,
  GroupTypesSportAndDisciplinesRemoveMutationVariables
>;
export const GroupTypesSportAndDisciplinesCreateDocument = Apollo.gql`
    mutation GroupTypesSportAndDisciplinesCreate($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsSportAndDisciplineGroups: {connect: {id: $idItem}}}) {
    id
    clsSportAndDisciplineGroups(orderBy: fullName_ASC) {
      fullName
      order
      id
    }
  }
}
    `;
export type GroupTypesSportAndDisciplinesCreateMutationFn = Apollo.MutationFunction<
  GroupTypesSportAndDisciplinesCreateMutation,
  GroupTypesSportAndDisciplinesCreateMutationVariables
>;

/**
 * __useGroupTypesSportAndDisciplinesCreateMutation__
 *
 * To run a mutation, you first call `useGroupTypesSportAndDisciplinesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupTypesSportAndDisciplinesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupTypesSportAndDisciplinesCreateMutation, { data, loading, error }] = useGroupTypesSportAndDisciplinesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useGroupTypesSportAndDisciplinesCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GroupTypesSportAndDisciplinesCreateMutation,
    GroupTypesSportAndDisciplinesCreateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    GroupTypesSportAndDisciplinesCreateMutation,
    GroupTypesSportAndDisciplinesCreateMutationVariables
  >(GroupTypesSportAndDisciplinesCreateDocument, baseOptions);
}
export type GroupTypesSportAndDisciplinesCreateMutationHookResult = ReturnType<
  typeof useGroupTypesSportAndDisciplinesCreateMutation
>;
export type GroupTypesSportAndDisciplinesCreateMutationResult = Apollo.MutationResult<
  GroupTypesSportAndDisciplinesCreateMutation
>;
export type GroupTypesSportAndDisciplinesCreateMutationOptions = Apollo.BaseMutationOptions<
  GroupTypesSportAndDisciplinesCreateMutation,
  GroupTypesSportAndDisciplinesCreateMutationVariables
>;
export const GroupTypesSportAndDisciplinesDocument = Apollo.gql`
    query GroupTypesSportAndDisciplines($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    clsSportAndDisciplineGroups(orderBy: fullName_ASC) {
      ...ClassifierValue
    }
  }
}
    ${ClassifierValueFragmentDoc}`;

/**
 * __useGroupTypesSportAndDisciplinesQuery__
 *
 * To run a query within a React component, call `useGroupTypesSportAndDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupTypesSportAndDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupTypesSportAndDisciplinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupTypesSportAndDisciplinesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GroupTypesSportAndDisciplinesQuery,
    GroupTypesSportAndDisciplinesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GroupTypesSportAndDisciplinesQuery, GroupTypesSportAndDisciplinesQueryVariables>(
    GroupTypesSportAndDisciplinesDocument,
    baseOptions,
  );
}
export function useGroupTypesSportAndDisciplinesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GroupTypesSportAndDisciplinesQuery,
    GroupTypesSportAndDisciplinesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<GroupTypesSportAndDisciplinesQuery, GroupTypesSportAndDisciplinesQueryVariables>(
    GroupTypesSportAndDisciplinesDocument,
    baseOptions,
  );
}
export type GroupTypesSportAndDisciplinesQueryHookResult = ReturnType<typeof useGroupTypesSportAndDisciplinesQuery>;
export type GroupTypesSportAndDisciplinesLazyQueryHookResult = ReturnType<
  typeof useGroupTypesSportAndDisciplinesLazyQuery
>;
export type GroupTypesSportAndDisciplinesQueryResult = Apollo.QueryResult<
  GroupTypesSportAndDisciplinesQuery,
  GroupTypesSportAndDisciplinesQueryVariables
>;
export const TypeSportGroupDisciplinesRenameDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesRename($id: UUID!, $idGroup: UUID!, $name: String!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {update: {where: {id: $idGroup}, data: {name: $name}}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesRenameMutationFn = Apollo.MutationFunction<
  TypeSportGroupDisciplinesRenameMutation,
  TypeSportGroupDisciplinesRenameMutationVariables
>;

/**
 * __useTypeSportGroupDisciplinesRenameMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesRenameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesRenameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesRenameMutation, { data, loading, error }] = useTypeSportGroupDisciplinesRenameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesRenameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportGroupDisciplinesRenameMutation,
    TypeSportGroupDisciplinesRenameMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportGroupDisciplinesRenameMutation,
    TypeSportGroupDisciplinesRenameMutationVariables
  >(TypeSportGroupDisciplinesRenameDocument, baseOptions);
}
export type TypeSportGroupDisciplinesRenameMutationHookResult = ReturnType<
  typeof useTypeSportGroupDisciplinesRenameMutation
>;
export type TypeSportGroupDisciplinesRenameMutationResult = Apollo.MutationResult<
  TypeSportGroupDisciplinesRenameMutation
>;
export type TypeSportGroupDisciplinesRenameMutationOptions = Apollo.BaseMutationOptions<
  TypeSportGroupDisciplinesRenameMutation,
  TypeSportGroupDisciplinesRenameMutationVariables
>;
export const TypeSportGroupDisciplinesAssignDisciplineDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesAssignDiscipline($id: UUID!, $idGroup: UUID!, $discipline: UUID!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {update: {where: {id: $idGroup}, data: {disciplines: {connect: {id: $discipline}}}}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesAssignDisciplineMutationFn = Apollo.MutationFunction<
  TypeSportGroupDisciplinesAssignDisciplineMutation,
  TypeSportGroupDisciplinesAssignDisciplineMutationVariables
>;

/**
 * __useTypeSportGroupDisciplinesAssignDisciplineMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesAssignDisciplineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesAssignDisciplineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesAssignDisciplineMutation, { data, loading, error }] = useTypeSportGroupDisciplinesAssignDisciplineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      discipline: // value for 'discipline'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesAssignDisciplineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportGroupDisciplinesAssignDisciplineMutation,
    TypeSportGroupDisciplinesAssignDisciplineMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportGroupDisciplinesAssignDisciplineMutation,
    TypeSportGroupDisciplinesAssignDisciplineMutationVariables
  >(TypeSportGroupDisciplinesAssignDisciplineDocument, baseOptions);
}
export type TypeSportGroupDisciplinesAssignDisciplineMutationHookResult = ReturnType<
  typeof useTypeSportGroupDisciplinesAssignDisciplineMutation
>;
export type TypeSportGroupDisciplinesAssignDisciplineMutationResult = Apollo.MutationResult<
  TypeSportGroupDisciplinesAssignDisciplineMutation
>;
export type TypeSportGroupDisciplinesAssignDisciplineMutationOptions = Apollo.BaseMutationOptions<
  TypeSportGroupDisciplinesAssignDisciplineMutation,
  TypeSportGroupDisciplinesAssignDisciplineMutationVariables
>;
export const TypeSportGroupDisciplinesUnAssignDisciplineDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesUnAssignDiscipline($id: UUID!, $idGroup: UUID!, $discipline: UUID!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {update: {where: {id: $idGroup}, data: {disciplines: {disconnect: {id: $discipline}}}}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationFn = Apollo.MutationFunction<
  TypeSportGroupDisciplinesUnAssignDisciplineMutation,
  TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables
>;

/**
 * __useTypeSportGroupDisciplinesUnAssignDisciplineMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesUnAssignDisciplineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesUnAssignDisciplineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesUnAssignDisciplineMutation, { data, loading, error }] = useTypeSportGroupDisciplinesUnAssignDisciplineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idGroup: // value for 'idGroup'
 *      discipline: // value for 'discipline'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesUnAssignDisciplineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportGroupDisciplinesUnAssignDisciplineMutation,
    TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportGroupDisciplinesUnAssignDisciplineMutation,
    TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables
  >(TypeSportGroupDisciplinesUnAssignDisciplineDocument, baseOptions);
}
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationHookResult = ReturnType<
  typeof useTypeSportGroupDisciplinesUnAssignDisciplineMutation
>;
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationResult = Apollo.MutationResult<
  TypeSportGroupDisciplinesUnAssignDisciplineMutation
>;
export type TypeSportGroupDisciplinesUnAssignDisciplineMutationOptions = Apollo.BaseMutationOptions<
  TypeSportGroupDisciplinesUnAssignDisciplineMutation,
  TypeSportGroupDisciplinesUnAssignDisciplineMutationVariables
>;
export const TypeSportGroupDisciplinesCreateDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesCreate($id: UUID!, $name: String!) {
  updateDirSport(where: {id: $id}, data: {disciplineGroups: {create: {name: $name}}}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;
export type TypeSportGroupDisciplinesCreateMutationFn = Apollo.MutationFunction<
  TypeSportGroupDisciplinesCreateMutation,
  TypeSportGroupDisciplinesCreateMutationVariables
>;

/**
 * __useTypeSportGroupDisciplinesCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesCreateMutation, { data, loading, error }] = useTypeSportGroupDisciplinesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportGroupDisciplinesCreateMutation,
    TypeSportGroupDisciplinesCreateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportGroupDisciplinesCreateMutation,
    TypeSportGroupDisciplinesCreateMutationVariables
  >(TypeSportGroupDisciplinesCreateDocument, baseOptions);
}
export type TypeSportGroupDisciplinesCreateMutationHookResult = ReturnType<
  typeof useTypeSportGroupDisciplinesCreateMutation
>;
export type TypeSportGroupDisciplinesCreateMutationResult = Apollo.MutationResult<
  TypeSportGroupDisciplinesCreateMutation
>;
export type TypeSportGroupDisciplinesCreateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportGroupDisciplinesCreateMutation,
  TypeSportGroupDisciplinesCreateMutationVariables
>;
export const TypeSportGroupDisciplinesArchiveDocument = Apollo.gql`
    mutation TypeSportGroupDisciplinesArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSportDisciplineGroup(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportGroupDisciplinesArchiveMutationFn = Apollo.MutationFunction<
  TypeSportGroupDisciplinesArchiveMutation,
  TypeSportGroupDisciplinesArchiveMutationVariables
>;

/**
 * __useTypeSportGroupDisciplinesArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportGroupDisciplinesArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupDisciplinesArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportGroupDisciplinesArchiveMutation, { data, loading, error }] = useTypeSportGroupDisciplinesArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportGroupDisciplinesArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportGroupDisciplinesArchiveMutation,
    TypeSportGroupDisciplinesArchiveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportGroupDisciplinesArchiveMutation,
    TypeSportGroupDisciplinesArchiveMutationVariables
  >(TypeSportGroupDisciplinesArchiveDocument, baseOptions);
}
export type TypeSportGroupDisciplinesArchiveMutationHookResult = ReturnType<
  typeof useTypeSportGroupDisciplinesArchiveMutation
>;
export type TypeSportGroupDisciplinesArchiveMutationResult = Apollo.MutationResult<
  TypeSportGroupDisciplinesArchiveMutation
>;
export type TypeSportGroupDisciplinesArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportGroupDisciplinesArchiveMutation,
  TypeSportGroupDisciplinesArchiveMutationVariables
>;
export const TypeSportGroupsDisciplinesDocument = Apollo.gql`
    query TypeSportGroupsDisciplines($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...DisciplineGroup
  }
}
    ${DisciplineGroupFragmentDoc}`;

/**
 * __useTypeSportGroupsDisciplinesQuery__
 *
 * To run a query within a React component, call `useTypeSportGroupsDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportGroupsDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportGroupsDisciplinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportGroupsDisciplinesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TypeSportGroupsDisciplinesQuery,
    TypeSportGroupsDisciplinesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<TypeSportGroupsDisciplinesQuery, TypeSportGroupsDisciplinesQueryVariables>(
    TypeSportGroupsDisciplinesDocument,
    baseOptions,
  );
}
export function useTypeSportGroupsDisciplinesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TypeSportGroupsDisciplinesQuery,
    TypeSportGroupsDisciplinesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportGroupsDisciplinesQuery, TypeSportGroupsDisciplinesQueryVariables>(
    TypeSportGroupsDisciplinesDocument,
    baseOptions,
  );
}
export type TypeSportGroupsDisciplinesQueryHookResult = ReturnType<typeof useTypeSportGroupsDisciplinesQuery>;
export type TypeSportGroupsDisciplinesLazyQueryHookResult = ReturnType<typeof useTypeSportGroupsDisciplinesLazyQuery>;
export type TypeSportGroupsDisciplinesQueryResult = Apollo.QueryResult<
  TypeSportGroupsDisciplinesQuery,
  TypeSportGroupsDisciplinesQueryVariables
>;
export const TypeSportsDocument = Apollo.gql`
    query TypeSports($first: Int!, $skip: Int!, $filter: DirSportWhereInput) {
  dirSports(first: $first, skip: $skip, where: $filter) {
    evsk
    fullName
    registryNumber
    id
    archive {
      ...ArchiveStatus
    }
  }
  count: dirSportsCount(where: $filter)
}
    ${ArchiveStatusFragmentDoc}`;

/**
 * __useTypeSportsQuery__
 *
 * To run a query within a React component, call `useTypeSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTypeSportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportsQuery, TypeSportsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TypeSportsQuery, TypeSportsQueryVariables>(TypeSportsDocument, baseOptions);
}
export function useTypeSportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportsQuery, TypeSportsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportsQuery, TypeSportsQueryVariables>(TypeSportsDocument, baseOptions);
}
export type TypeSportsQueryHookResult = ReturnType<typeof useTypeSportsQuery>;
export type TypeSportsLazyQueryHookResult = ReturnType<typeof useTypeSportsLazyQuery>;
export type TypeSportsQueryResult = Apollo.QueryResult<TypeSportsQuery, TypeSportsQueryVariables>;
export const TypeSportsCountDocument = Apollo.gql`
    query TypeSportsCount($filter: DirSportWhereInput) {
  count: dirSportsCount(where: $filter)
}
    `;

/**
 * __useTypeSportsCountQuery__
 *
 * To run a query within a React component, call `useTypeSportsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTypeSportsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportsCountQuery, TypeSportsCountQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TypeSportsCountQuery, TypeSportsCountQueryVariables>(
    TypeSportsCountDocument,
    baseOptions,
  );
}
export function useTypeSportsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportsCountQuery, TypeSportsCountQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportsCountQuery, TypeSportsCountQueryVariables>(
    TypeSportsCountDocument,
    baseOptions,
  );
}
export type TypeSportsCountQueryHookResult = ReturnType<typeof useTypeSportsCountQuery>;
export type TypeSportsCountLazyQueryHookResult = ReturnType<typeof useTypeSportsCountLazyQuery>;
export type TypeSportsCountQueryResult = Apollo.QueryResult<TypeSportsCountQuery, TypeSportsCountQueryVariables>;
export const TypeSportCreateDocument = Apollo.gql`
    mutation TypeSportCreate($fullName: String!, $shortName: String!, $evsk: String!) {
  createDirSport(data: {fullName: $fullName, shortName: $shortName, evsk: $evsk}) {
    ...MainInfo
  }
}
    ${MainInfoFragmentDoc}`;
export type TypeSportCreateMutationFn = Apollo.MutationFunction<
  TypeSportCreateMutation,
  TypeSportCreateMutationVariables
>;

/**
 * __useTypeSportCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportCreateMutation, { data, loading, error }] = useTypeSportCreateMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      evsk: // value for 'evsk'
 *   },
 * });
 */
export function useTypeSportCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportCreateMutation, TypeSportCreateMutationVariables>,
) {
  return ApolloReactHooks.useMutation<TypeSportCreateMutation, TypeSportCreateMutationVariables>(
    TypeSportCreateDocument,
    baseOptions,
  );
}
export type TypeSportCreateMutationHookResult = ReturnType<typeof useTypeSportCreateMutation>;
export type TypeSportCreateMutationResult = Apollo.MutationResult<TypeSportCreateMutation>;
export type TypeSportCreateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportCreateMutation,
  TypeSportCreateMutationVariables
>;
export const TypeSportUpdateDocument = Apollo.gql`
    mutation TypeSportUpdate($id: UUID!, $fullName: String!, $shortName: String!, $evsk: String) {
  updateDirSport(where: {id: $id}, data: {fullName: $fullName, shortName: $shortName, evsk: $evsk}) {
    ...MainInfo
  }
}
    ${MainInfoFragmentDoc}`;
export type TypeSportUpdateMutationFn = Apollo.MutationFunction<
  TypeSportUpdateMutation,
  TypeSportUpdateMutationVariables
>;

/**
 * __useTypeSportUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportUpdateMutation, { data, loading, error }] = useTypeSportUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fullName: // value for 'fullName'
 *      shortName: // value for 'shortName'
 *      evsk: // value for 'evsk'
 *   },
 * });
 */
export function useTypeSportUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportUpdateMutation, TypeSportUpdateMutationVariables>,
) {
  return ApolloReactHooks.useMutation<TypeSportUpdateMutation, TypeSportUpdateMutationVariables>(
    TypeSportUpdateDocument,
    baseOptions,
  );
}
export type TypeSportUpdateMutationHookResult = ReturnType<typeof useTypeSportUpdateMutation>;
export type TypeSportUpdateMutationResult = Apollo.MutationResult<TypeSportUpdateMutation>;
export type TypeSportUpdateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportUpdateMutation,
  TypeSportUpdateMutationVariables
>;
export const TypeSportArchiveDocument = Apollo.gql`
    mutation TypeSportArchive($id: UUID!, $archive: ArchiveUpdateOneInput!) {
  updateDirSport(where: {id: $id}, data: {archive: $archive}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportArchiveMutationFn = Apollo.MutationFunction<
  TypeSportArchiveMutation,
  TypeSportArchiveMutationVariables
>;

/**
 * __useTypeSportArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportArchiveMutation, { data, loading, error }] = useTypeSportArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useTypeSportArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportArchiveMutation, TypeSportArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<TypeSportArchiveMutation, TypeSportArchiveMutationVariables>(
    TypeSportArchiveDocument,
    baseOptions,
  );
}
export type TypeSportArchiveMutationHookResult = ReturnType<typeof useTypeSportArchiveMutation>;
export type TypeSportArchiveMutationResult = Apollo.MutationResult<TypeSportArchiveMutation>;
export type TypeSportArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportArchiveMutation,
  TypeSportArchiveMutationVariables
>;
export const TypeSportUnArchiveDocument = Apollo.gql`
    mutation TypeSportUnArchive($id: UUID!) {
  updateDirSport(where: {id: $id}, data: {archive: {delete: true}}) {
    archive {
      ...ArchiveStatus
    }
  }
}
    ${ArchiveStatusFragmentDoc}`;
export type TypeSportUnArchiveMutationFn = Apollo.MutationFunction<
  TypeSportUnArchiveMutation,
  TypeSportUnArchiveMutationVariables
>;

/**
 * __useTypeSportUnArchiveMutation__
 *
 * To run a mutation, you first call `useTypeSportUnArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportUnArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportUnArchiveMutation, { data, loading, error }] = useTypeSportUnArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportUnArchiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TypeSportUnArchiveMutation, TypeSportUnArchiveMutationVariables>,
) {
  return ApolloReactHooks.useMutation<TypeSportUnArchiveMutation, TypeSportUnArchiveMutationVariables>(
    TypeSportUnArchiveDocument,
    baseOptions,
  );
}
export type TypeSportUnArchiveMutationHookResult = ReturnType<typeof useTypeSportUnArchiveMutation>;
export type TypeSportUnArchiveMutationResult = Apollo.MutationResult<TypeSportUnArchiveMutation>;
export type TypeSportUnArchiveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportUnArchiveMutation,
  TypeSportUnArchiveMutationVariables
>;
export const FetchTypeSportByIdMainInfoDocument = Apollo.gql`
    query FetchTypeSportByIdMainInfo($id: UUID!) {
  dirSport(where: {id: $id}) {
    ...MainInfo
  }
}
    ${MainInfoFragmentDoc}`;

/**
 * __useFetchTypeSportByIdMainInfoQuery__
 *
 * To run a query within a React component, call `useFetchTypeSportByIdMainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTypeSportByIdMainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTypeSportByIdMainInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchTypeSportByIdMainInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FetchTypeSportByIdMainInfoQuery,
    FetchTypeSportByIdMainInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<FetchTypeSportByIdMainInfoQuery, FetchTypeSportByIdMainInfoQueryVariables>(
    FetchTypeSportByIdMainInfoDocument,
    baseOptions,
  );
}
export function useFetchTypeSportByIdMainInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FetchTypeSportByIdMainInfoQuery,
    FetchTypeSportByIdMainInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<FetchTypeSportByIdMainInfoQuery, FetchTypeSportByIdMainInfoQueryVariables>(
    FetchTypeSportByIdMainInfoDocument,
    baseOptions,
  );
}
export type FetchTypeSportByIdMainInfoQueryHookResult = ReturnType<typeof useFetchTypeSportByIdMainInfoQuery>;
export type FetchTypeSportByIdMainInfoLazyQueryHookResult = ReturnType<typeof useFetchTypeSportByIdMainInfoLazyQuery>;
export type FetchTypeSportByIdMainInfoQueryResult = Apollo.QueryResult<
  FetchTypeSportByIdMainInfoQuery,
  FetchTypeSportByIdMainInfoQueryVariables
>;
export const TypeSportsListDocument = Apollo.gql`
    query TypeSportsList {
  dirSports(orderBy: fullName_ASC) {
    value: id
    label: fullName
  }
}
    `;

/**
 * __useTypeSportsListQuery__
 *
 * To run a query within a React component, call `useTypeSportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTypeSportsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportsListQuery, TypeSportsListQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TypeSportsListQuery, TypeSportsListQueryVariables>(
    TypeSportsListDocument,
    baseOptions,
  );
}
export function useTypeSportsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypeSportsListQuery, TypeSportsListQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportsListQuery, TypeSportsListQueryVariables>(
    TypeSportsListDocument,
    baseOptions,
  );
}
export type TypeSportsListQueryHookResult = ReturnType<typeof useTypeSportsListQuery>;
export type TypeSportsListLazyQueryHookResult = ReturnType<typeof useTypeSportsListLazyQuery>;
export type TypeSportsListQueryResult = Apollo.QueryResult<TypeSportsListQuery, TypeSportsListQueryVariables>;
export const TypeSportRanksAndTitlesCreateDocument = Apollo.gql`
    mutation TypeSportRanksAndTitlesCreate($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsRanksAndAwards: {connect: {id: $idItem}}}) {
    id
    clsRanksAndAwards {
      label: fullName
      value: id
    }
  }
}
    `;
export type TypeSportRanksAndTitlesCreateMutationFn = Apollo.MutationFunction<
  TypeSportRanksAndTitlesCreateMutation,
  TypeSportRanksAndTitlesCreateMutationVariables
>;

/**
 * __useTypeSportRanksAndTitlesCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportRanksAndTitlesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportRanksAndTitlesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportRanksAndTitlesCreateMutation, { data, loading, error }] = useTypeSportRanksAndTitlesCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useTypeSportRanksAndTitlesCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportRanksAndTitlesCreateMutation,
    TypeSportRanksAndTitlesCreateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportRanksAndTitlesCreateMutation,
    TypeSportRanksAndTitlesCreateMutationVariables
  >(TypeSportRanksAndTitlesCreateDocument, baseOptions);
}
export type TypeSportRanksAndTitlesCreateMutationHookResult = ReturnType<
  typeof useTypeSportRanksAndTitlesCreateMutation
>;
export type TypeSportRanksAndTitlesCreateMutationResult = Apollo.MutationResult<TypeSportRanksAndTitlesCreateMutation>;
export type TypeSportRanksAndTitlesCreateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportRanksAndTitlesCreateMutation,
  TypeSportRanksAndTitlesCreateMutationVariables
>;
export const TypeSportRanksAndTitlesRemoveDocument = Apollo.gql`
    mutation TypeSportRanksAndTitlesRemove($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {clsRanksAndAwards: {disconnect: {id: $idItem}}}) {
    id
    clsRanksAndAwards {
      label: fullName
      value: id
    }
  }
}
    `;
export type TypeSportRanksAndTitlesRemoveMutationFn = Apollo.MutationFunction<
  TypeSportRanksAndTitlesRemoveMutation,
  TypeSportRanksAndTitlesRemoveMutationVariables
>;

/**
 * __useTypeSportRanksAndTitlesRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportRanksAndTitlesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportRanksAndTitlesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportRanksAndTitlesRemoveMutation, { data, loading, error }] = useTypeSportRanksAndTitlesRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useTypeSportRanksAndTitlesRemoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportRanksAndTitlesRemoveMutation,
    TypeSportRanksAndTitlesRemoveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportRanksAndTitlesRemoveMutation,
    TypeSportRanksAndTitlesRemoveMutationVariables
  >(TypeSportRanksAndTitlesRemoveDocument, baseOptions);
}
export type TypeSportRanksAndTitlesRemoveMutationHookResult = ReturnType<
  typeof useTypeSportRanksAndTitlesRemoveMutation
>;
export type TypeSportRanksAndTitlesRemoveMutationResult = Apollo.MutationResult<TypeSportRanksAndTitlesRemoveMutation>;
export type TypeSportRanksAndTitlesRemoveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportRanksAndTitlesRemoveMutation,
  TypeSportRanksAndTitlesRemoveMutationVariables
>;
export const TypeSportRanksAndTitlesDocument = Apollo.gql`
    query TypeSportRanksAndTitles($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    clsRanksAndAwards {
      label: fullName
      value: id
    }
  }
}
    `;

/**
 * __useTypeSportRanksAndTitlesQuery__
 *
 * To run a query within a React component, call `useTypeSportRanksAndTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportRanksAndTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportRanksAndTitlesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportRanksAndTitlesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>(
    TypeSportRanksAndTitlesDocument,
    baseOptions,
  );
}
export function useTypeSportRanksAndTitlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TypeSportRanksAndTitlesQuery,
    TypeSportRanksAndTitlesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportRanksAndTitlesQuery, TypeSportRanksAndTitlesQueryVariables>(
    TypeSportRanksAndTitlesDocument,
    baseOptions,
  );
}
export type TypeSportRanksAndTitlesQueryHookResult = ReturnType<typeof useTypeSportRanksAndTitlesQuery>;
export type TypeSportRanksAndTitlesLazyQueryHookResult = ReturnType<typeof useTypeSportRanksAndTitlesLazyQuery>;
export type TypeSportRanksAndTitlesQueryResult = Apollo.QueryResult<
  TypeSportRanksAndTitlesQuery,
  TypeSportRanksAndTitlesQueryVariables
>;
export const TypeSportStagePrepareCreateDocument = Apollo.gql`
    mutation TypeSportStagePrepareCreate($id: UUID!, $idStep: UUID!, $idRank: [ClassifierValueWhereUniqueInput!], $minManAge: Int, $maxManAge: Int, $minWomanAge: Int, $maxWomanAge: Int, $minGroupSize: Int, $maxGroupSize: Int) {
  updateDirSport(where: {id: $id}, data: {trainingStages: {create: {clsTrainingStage: {connect: {id: $idStep}}, minManAge: $minManAge, maxManAge: $maxManAge, minWomanAge: $minWomanAge, maxWomanAge: $maxWomanAge, minGroupSize: $minGroupSize, maxGroupSize: $maxGroupSize, clsRanksAndAwards: {connect: $idRank}}}}) {
    id
    trainingStages {
      ...SportTrainingStage
    }
  }
}
    ${SportTrainingStageFragmentDoc}`;
export type TypeSportStagePrepareCreateMutationFn = Apollo.MutationFunction<
  TypeSportStagePrepareCreateMutation,
  TypeSportStagePrepareCreateMutationVariables
>;

/**
 * __useTypeSportStagePrepareCreateMutation__
 *
 * To run a mutation, you first call `useTypeSportStagePrepareCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePrepareCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportStagePrepareCreateMutation, { data, loading, error }] = useTypeSportStagePrepareCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idStep: // value for 'idStep'
 *      idRank: // value for 'idRank'
 *      minManAge: // value for 'minManAge'
 *      maxManAge: // value for 'maxManAge'
 *      minWomanAge: // value for 'minWomanAge'
 *      maxWomanAge: // value for 'maxWomanAge'
 *      minGroupSize: // value for 'minGroupSize'
 *      maxGroupSize: // value for 'maxGroupSize'
 *   },
 * });
 */
export function useTypeSportStagePrepareCreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportStagePrepareCreateMutation,
    TypeSportStagePrepareCreateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportStagePrepareCreateMutation,
    TypeSportStagePrepareCreateMutationVariables
  >(TypeSportStagePrepareCreateDocument, baseOptions);
}
export type TypeSportStagePrepareCreateMutationHookResult = ReturnType<typeof useTypeSportStagePrepareCreateMutation>;
export type TypeSportStagePrepareCreateMutationResult = Apollo.MutationResult<TypeSportStagePrepareCreateMutation>;
export type TypeSportStagePrepareCreateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportStagePrepareCreateMutation,
  TypeSportStagePrepareCreateMutationVariables
>;
export const TypeSportStagePrepareUpdateDocument = Apollo.gql`
    mutation TypeSportStagePrepareUpdate($id: UUID!, $idStep: UUID!, $idRank: [ClassifierValueWhereUniqueInput!], $minManAge: Int, $maxManAge: Int, $minWomanAge: Int, $maxWomanAge: Int, $minGroupSize: Int, $maxGroupSize: Int) {
  updateDirSportTrainingStage(where: {id: $id}, data: {clsTrainingStage: {connect: {id: $idStep}}, minManAge: $minManAge, maxManAge: $maxManAge, minWomanAge: $minWomanAge, maxWomanAge: $maxWomanAge, minGroupSize: $minGroupSize, maxGroupSize: $maxGroupSize, clsRanksAndAwards: {set: $idRank}}) {
    ...SportTrainingStage
  }
}
    ${SportTrainingStageFragmentDoc}`;
export type TypeSportStagePrepareUpdateMutationFn = Apollo.MutationFunction<
  TypeSportStagePrepareUpdateMutation,
  TypeSportStagePrepareUpdateMutationVariables
>;

/**
 * __useTypeSportStagePrepareUpdateMutation__
 *
 * To run a mutation, you first call `useTypeSportStagePrepareUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePrepareUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportStagePrepareUpdateMutation, { data, loading, error }] = useTypeSportStagePrepareUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idStep: // value for 'idStep'
 *      idRank: // value for 'idRank'
 *      minManAge: // value for 'minManAge'
 *      maxManAge: // value for 'maxManAge'
 *      minWomanAge: // value for 'minWomanAge'
 *      maxWomanAge: // value for 'maxWomanAge'
 *      minGroupSize: // value for 'minGroupSize'
 *      maxGroupSize: // value for 'maxGroupSize'
 *   },
 * });
 */
export function useTypeSportStagePrepareUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportStagePrepareUpdateMutation,
    TypeSportStagePrepareUpdateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportStagePrepareUpdateMutation,
    TypeSportStagePrepareUpdateMutationVariables
  >(TypeSportStagePrepareUpdateDocument, baseOptions);
}
export type TypeSportStagePrepareUpdateMutationHookResult = ReturnType<typeof useTypeSportStagePrepareUpdateMutation>;
export type TypeSportStagePrepareUpdateMutationResult = Apollo.MutationResult<TypeSportStagePrepareUpdateMutation>;
export type TypeSportStagePrepareUpdateMutationOptions = Apollo.BaseMutationOptions<
  TypeSportStagePrepareUpdateMutation,
  TypeSportStagePrepareUpdateMutationVariables
>;
export const TypeSportStagePrepareRemoveDocument = Apollo.gql`
    mutation TypeSportStagePrepareRemove($id: UUID!, $idItem: UUID!) {
  updateDirSport(where: {id: $id}, data: {trainingStages: {delete: {id: $idItem}}}) {
    id
    trainingStages {
      ...SportTrainingStage
    }
  }
}
    ${SportTrainingStageFragmentDoc}`;
export type TypeSportStagePrepareRemoveMutationFn = Apollo.MutationFunction<
  TypeSportStagePrepareRemoveMutation,
  TypeSportStagePrepareRemoveMutationVariables
>;

/**
 * __useTypeSportStagePrepareRemoveMutation__
 *
 * To run a mutation, you first call `useTypeSportStagePrepareRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePrepareRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [typeSportStagePrepareRemoveMutation, { data, loading, error }] = useTypeSportStagePrepareRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idItem: // value for 'idItem'
 *   },
 * });
 */
export function useTypeSportStagePrepareRemoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TypeSportStagePrepareRemoveMutation,
    TypeSportStagePrepareRemoveMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TypeSportStagePrepareRemoveMutation,
    TypeSportStagePrepareRemoveMutationVariables
  >(TypeSportStagePrepareRemoveDocument, baseOptions);
}
export type TypeSportStagePrepareRemoveMutationHookResult = ReturnType<typeof useTypeSportStagePrepareRemoveMutation>;
export type TypeSportStagePrepareRemoveMutationResult = Apollo.MutationResult<TypeSportStagePrepareRemoveMutation>;
export type TypeSportStagePrepareRemoveMutationOptions = Apollo.BaseMutationOptions<
  TypeSportStagePrepareRemoveMutation,
  TypeSportStagePrepareRemoveMutationVariables
>;
export const TypeSportStagePreparingDocument = Apollo.gql`
    query TypeSportStagePreparing($id: UUID!) {
  dirSport(where: {id: $id}) {
    id
    trainingStages {
      ...SportTrainingStage
    }
  }
}
    ${SportTrainingStageFragmentDoc}`;

/**
 * __useTypeSportStagePreparingQuery__
 *
 * To run a query within a React component, call `useTypeSportStagePreparingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeSportStagePreparingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeSportStagePreparingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTypeSportStagePreparingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>,
) {
  return ApolloReactHooks.useQuery<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>(
    TypeSportStagePreparingDocument,
    baseOptions,
  );
}
export function useTypeSportStagePreparingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TypeSportStagePreparingQuery,
    TypeSportStagePreparingQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<TypeSportStagePreparingQuery, TypeSportStagePreparingQueryVariables>(
    TypeSportStagePreparingDocument,
    baseOptions,
  );
}
export type TypeSportStagePreparingQueryHookResult = ReturnType<typeof useTypeSportStagePreparingQuery>;
export type TypeSportStagePreparingLazyQueryHookResult = ReturnType<typeof useTypeSportStagePreparingLazyQuery>;
export type TypeSportStagePreparingQueryResult = Apollo.QueryResult<
  TypeSportStagePreparingQuery,
  TypeSportStagePreparingQueryVariables
>;
export const RemoveTypeSportDocument = Apollo.gql`
    mutation RemoveTypeSport($id: UUID!) {
  deleteDirSport(where: {id: $id}) {
    id
  }
}
    `;
export type RemoveTypeSportMutationFn = Apollo.MutationFunction<
  RemoveTypeSportMutation,
  RemoveTypeSportMutationVariables
>;

/**
 * __useRemoveTypeSportMutation__
 *
 * To run a mutation, you first call `useRemoveTypeSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTypeSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTypeSportMutation, { data, loading, error }] = useRemoveTypeSportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTypeSportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTypeSportMutation, RemoveTypeSportMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RemoveTypeSportMutation, RemoveTypeSportMutationVariables>(
    RemoveTypeSportDocument,
    baseOptions,
  );
}
export type RemoveTypeSportMutationHookResult = ReturnType<typeof useRemoveTypeSportMutation>;
export type RemoveTypeSportMutationResult = Apollo.MutationResult<RemoveTypeSportMutation>;
export type RemoveTypeSportMutationOptions = Apollo.BaseMutationOptions<
  RemoveTypeSportMutation,
  RemoveTypeSportMutationVariables
>;
export const RemoveUserDocument = Apollo.gql`
    mutation RemoveUser($id: UUID!) {
  deleteUser(where: {id: $id}) {
    id
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const CreateUserDocument = Apollo.gql`
    mutation CreateUser($name: String, $email: String!, $role: String!, $regional: RegionalMinsportProfileCreateOneInput, $school: RegionalSchoolProfileCreateOneInput) {
  signup(name: $name, email: $email, role: $role, password: "a", regionalMinsportProfile: $regional, regionalSchoolProfile: $school) {
    token
    user {
      ...UserFR
    }
  }
}
    ${UserFrFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      regional: // value for 'regional'
 *      school: // value for 'school'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = Apollo.gql`
    mutation UpdateUser($id: UUID!, $userID: String!, $name: String, $role: String!, $email: String!, $regional: RegionalMinsportProfileUpdateOneInput, $school: RegionalSchoolProfileUpdateOneInput, $regular: RegularUserUpdateOneInput) {
  updateUser(where: {id: $id}, data: {name: $name, regionalMinsportProfile: $regional, regionalSchoolProfile: $school, regular: $regular}) {
    ...UserFR
  }
  changeUserRole(userID: $userID, role: $role) {
    id
  }
  changeUserEmail(userID: $userID, email: $email) {
    id
  }
}
    ${UserFrFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *      email: // value for 'email'
 *      regional: // value for 'regional'
 *      school: // value for 'school'
 *      regular: // value for 'regular'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserContactInfoDocument = Apollo.gql`
    mutation UpdateUserContactInfo($id: UUID!, $regular: RegularUserUpdateOneInput) {
  updateUser(where: {id: $id}, data: {regular: $regular}) {
    ...UserFR
  }
}
    ${UserFrFragmentDoc}`;
export type UpdateUserContactInfoMutationFn = Apollo.MutationFunction<
  UpdateUserContactInfoMutation,
  UpdateUserContactInfoMutationVariables
>;

/**
 * __useUpdateUserContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserContactInfoMutation, { data, loading, error }] = useUpdateUserContactInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      regular: // value for 'regular'
 *   },
 * });
 */
export function useUpdateUserContactInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserContactInfoMutation,
    UpdateUserContactInfoMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>(
    UpdateUserContactInfoDocument,
    baseOptions,
  );
}
export type UpdateUserContactInfoMutationHookResult = ReturnType<typeof useUpdateUserContactInfoMutation>;
export type UpdateUserContactInfoMutationResult = Apollo.MutationResult<UpdateUserContactInfoMutation>;
export type UpdateUserContactInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserContactInfoMutation,
  UpdateUserContactInfoMutationVariables
>;
export const UsersDocument = Apollo.gql`
    query Users($first: Int!, $skip: Int!, $filter: UserWhereInput) {
  users(first: $first, skip: $skip, where: $filter) {
    ...UserFR
  }
  count: usersCount(where: $filter)
}
    ${UserFrFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = Apollo.gql`
    query User($id: UUID!) {
  user(where: {id: $id}) {
    ...UserFR
    regionalMinsportProfile {
      id
      dirRegion {
        value: id
        label: fullName
      }
      dirFederalDistrict {
        value: id
        label: fullName
      }
    }
    regionalSchoolProfile {
      id
      dirOrganization {
        value: id
        label: name
      }
    }
  }
}
    ${UserFrFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ResetPasswordDocument = Apollo.gql`
    mutation ResetPassword($resetEmail: String!) {
  publicResetPassword(email: $resetEmail)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetEmail: // value for 'resetEmail'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    baseOptions,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const LoginDocument = Apollo.gql`
    mutation Login($email: String!, $password: String!) {
  signin(email: $email, password: $password) {
    token
    user {
      role {
        name
      }
      personProfile {
        athleteProfile {
          id
        }
        trainerProfile {
          id
        }
        dirRegion {
          id
        }
        dirForeignCity {
          id
        }
      }
      regionalMinsportProfile {
        dirRegion {
          id
        }
        dirFederalDistrict {
          id
        }
      }
      regionalSchoolProfile {
        dirOrganization {
          id
        }
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
