import React, { FC, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input, Select } from '../Inputs';
import { TypeSports, TeamStatus, GroupDisciplines } from '../../module';
import Regions from '../../module/Regions';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { Count } from '../Count';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useNationalTeamsCountLazyQuery } from '../../api';

interface SportingEvent {
  registryNumber?: number;
  name_contains?: string;
  sports_some?: {
    dirSport?: {
      id: string;
    };
  };
}

interface Filter {
  registryNumber?: number;
  dirSportingEvent: SportingEvent;
  status: {
    id?: string;
  };
  dirRegion?: {
    id?: string;
  };
}
interface Value {
  registryNumber: number;
  dirRegion: {
    label: string;
    value: string;
  };
  sportType: {
    label: string;
    value: string;
  };
  activityName: string;
  activityRegistryNumber: number;
  dealStatus: {
    label: string;
    value: string;
  };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const NationalTeams: FC<{ onSubmit?: (T: any) => void }> = ({ onSubmit = () => {} }) => {
  const style = useStyle();

  const [additional, setAdditional] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({});

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      registryNumber: undefined,
      dirRegion: undefined,
      sportType: undefined,
      activityName: undefined,
      activityRegistryNumber: undefined,
      dealStatus: undefined,
    },
  });

  const handleSearchBox = (value: Value) => {
    const filters: Filter = {
      dirSportingEvent: {},
      status: {},
    };

    if (value.registryNumber) {
      filters.registryNumber = Number(value.registryNumber);
    }
    if (value.dirRegion) {
      filters.dirRegion = {
        id: value.dirRegion.value,
      };
    }
    if (value.sportType) {
      filters.dirSportingEvent.sports_some = {
        ...filters.dirSportingEvent?.sports_some,
        dirSport: {
          id: value.sportType.value,
        },
      };
    }
    if (value.activityName) {
      filters.dirSportingEvent.name_contains = value.activityName;
    }
    if (value.activityRegistryNumber) {
      filters.dirSportingEvent.registryNumber = Number(value.activityRegistryNumber);
    }
    if (value.dealStatus) {
      filters.status = {
        id: value?.dealStatus.value,
      };
    }
    if (Object.keys(filters.dirSportingEvent).length === 0) delete filters.dirSportingEvent;
    if (Object.keys(filters.status).length === 0) delete filters.status;

    setFilter(filters);
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item container spacing={2} md={11} xs={12}>
            <Grid item xs={3}>
              <Input
                label="Реестровый номер"
                control={control}
                error={!!errors['registryNumber']}
                name="registryNumber"
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                label="Название мероприятия"
                control={control}
                error={!!errors['activityName']}
                name="activityName"
              />
            </Grid>
            <Grid item xs={3}>
              <Regions label="Регион" error={!!errors['dirRegion']} control={control} name="dirRegion" />
            </Grid>
            <Grid item xs={3}>
              <TypeSports label="Вид спорта" error={!!errors['sportType']} control={control} name="sportType" />
            </Grid>

            {additional && (
              <React.Fragment>
                <Grid item xs={3}>
                  <Input
                    label="Реестровый номер мероприятия"
                    control={control}
                    error={!!errors['activityRegistryNumber']}
                    name="activityRegistryNumber"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TeamStatus
                    label="статус согласования"
                    error={!!errors['dealStatus']}
                    control={control}
                    name="dealStatus"
                  />
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="button"
                onClick={() => {
                  onSubmit({});
                  setFilter({});
                  reset();
                }}
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
              <Button
                onClick={() => setAdditional(!additional)}
                type="button"
                className={style.btn}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<TuneIcon />}
              >
                Фильтр
              </Button>
            </Grid>
          </Grid>
          <Grid item container justify="center" md={1} xs={12}>
            <Count query={useNationalTeamsCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default NationalTeams;
