import React, { FC, useState, useEffect } from 'react';
import { Grid, Tooltip, IconButton, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import SportEvents from '../../../../module/SportEvents';
import { Input } from '../../../Inputs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';

import { Scalars, UpdateSportingEventMutationHookResult, useUpdateSportingEventMutation } from '../../../../api';
import _ from 'lodash';

interface lightEvent {
  value: Scalars['UUID'];
  label: string;
  registryNumber: number;
}

interface formEventInterface {
  dirSportingEvent: lightEvent;
}

interface commentValueInterface {
  comment: string;
}

interface EventInfo {
  classes?: any;
  commentUpdated: Function;
  parentAdded: Function;
  parentRemoved: Function;
  childAdded: Function;
  childRemoved: Function;
  onError: Function;
  dirSportingEvent?: any;
  readOnly?: boolean;
}

const Structure: FC<EventInfo> = ({
  classes,
  dirSportingEvent,
  parentAdded,
  parentRemoved,
  childAdded,
  childRemoved,
  commentUpdated,
  onError,
  readOnly,
}) => {
  const { id, parent, children } = dirSportingEvent;

  const [besideArray, setBesideArray] = useState<Array<Scalars['UUID']>>([]);

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      comment: dirSportingEvent?.comment,
    } as {
      comment: string;
    },
  });

  const {
    handleSubmit: parentFormHandleSubmit,
    control: parentFormControl,
    errors: parentFormError,
    reset: parentFormReset,
    formState: parentFormState,
  } = useForm({
    defaultValues: {
      dirSportingEvent: undefined,
    },
  });

  const {
    handleSubmit: childFormHandleSubmit,
    control: childFormControl,
    errors: childFormError,
    reset: childFormReset,
    formState: childFormState,
  } = useForm({
    defaultValues: {
      dirSportingEvent: undefined,
    },
  });

  const [open, setOpen] = useState('');

  const [updateSportingEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const handleClickOpen = (name: string) => {
    setOpen(name);
  };

  const handleClose = () => {
    setOpen('');
  };

  const onSubmit = async (values: commentValueInterface) => {
    try {
      await updateSportingEvent({
        variables: {
          id,
          data: {
            comment: values.comment,
          },
        },
      });
      commentUpdated();
    } catch (error) {
      onError(error);
    }
  };

  const updateBesideArray = (data: any) => {
    let availableChildArray = [id];

    if (data && data.parent) {
      availableChildArray = [...availableChildArray, data.parent.value];
    }
    if (data && data.children && data.children.length > 0) {
      availableChildArray = [...availableChildArray, ...data.children.map((child: lightEvent) => child.value)];
    }
    setBesideArray(_.sortedUniq(availableChildArray));
  };

  const handleUpdateParentEvent = async (values: formEventInterface) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            parent: { connect: { id: values.dirSportingEvent.value } },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        setOpen('');
        parentAdded();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleRemoveParentEvent = () => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            parent: { disconnect: true },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        parentRemoved();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleAddChildEvent = async (values: formEventInterface) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            children: { connect: [{ id: values.dirSportingEvent.value }] },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        setOpen('');
        childAdded();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleRemoveChildren = async (childID: Scalars['UUID']) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            children: { disconnect: [{ id: childID }] },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        childRemoved();
      });
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    updateBesideArray({ parent, children });
  }, [parent, children]);

  return (
    <React.Fragment>
      <Grid container className={classes.verticalSpace}>
        {parent && Object.keys(parent).length > 0 ? (
          <Grid item xs={12}>
            <span className={classes.title}>Основное мероприятие:&nbsp;</span> {parent.label}
            <span className={classes.title} style={{ margin: '0 1rem' }}>
              {parent.registryNumber}
            </span>
            {!readOnly && (
              <Tooltip title="изменить" placement="top-start">
                <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('parentDialog')}>
                  <EditIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
            {!readOnly && (
              <Tooltip title="удалить" placement="top-start">
                <IconButton className={classes.btn} color="secondary" onClick={handleRemoveParentEvent}>
                  <DeleteIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <span className={classes.title}>Основное мероприятие:&nbsp;</span>

            {!readOnly && (
              <Tooltip title="добавить" placement="top-start">
                <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('parentDialog')}>
                  <AddBoxIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}

        <Grid item>
          <span className={classes.title}>Зависимые мероприятия:&nbsp;</span>
          {!readOnly && (
            <Tooltip title="добавить" placement="top-start">
              <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('childrenDialog')}>
                <AddBoxIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        {children?.map((child: lightEvent) => (
          <Grid key={child.value} item xs={12}>
            <span>{child.label}</span>&nbsp;
            <span className={classes.label} style={{ margin: '0 1rem' }}>
              {child.registryNumber}
            </span>
            &nbsp;
            {!readOnly && (
              <Tooltip title="удалить" placement="top-start">
                <IconButton className={classes.btn} color="secondary" onClick={() => handleRemoveChildren(child.value)}>
                  <DeleteIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid container justify="space-between" alignItems="flex-end" className={classes.verticalSpace}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid item xs={12} className={classes.verticalSpace}>
            <Input
              label="Комментарий"
              control={control}
              error={!!errors['comment']}
              name="comment"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item container justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </form>
      </Grid>
      <Dialog
        open={open === 'parentDialog'}
        onClose={handleClose}
        aria-labelledby="parent-dialog-title"
        aria-describedby="parent-dialog-description"
      >
        <DialogTitle id="parent-dialog-title">Выбирать Мероприятие</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={parentFormHandleSubmit(handleUpdateParentEvent)} style={{ width: '40ch' }}>
            <SportEvents
              label="мероприятие"
              name="dirSportingEvent"
              filter={{ besideArray }}
              control={parentFormControl}
              error={!!parentFormError['dirSportingEvent']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!parentFormState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open === 'childrenDialog'}
        onClose={handleClose}
        aria-labelledby="children-dialog-title"
        aria-describedby="children-dialog-description"
      >
        <DialogTitle id="children-dialog-title">Выбирать Мероприятие</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={childFormHandleSubmit(handleAddChildEvent)} style={{ width: '40ch' }}>
            <SportEvents
              label="мероприятие"
              name="dirSportingEvent"
              filter={{ besideArray }}
              control={childFormControl}
              error={!!childFormError['dirSportingEvent']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!childFormState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Structure;
