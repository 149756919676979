import React, { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input } from '../Inputs';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { Count } from '../Count';
import { TrainersFilterAdditional } from './index';
import { makeStyles } from '@material-ui/core/styles';
import { useTrainersCountLazyQuery } from '../../api';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Trainers: FC<{ onSubmit?: (T: any) => void; value?: any }> = ({ onSubmit = () => {}, value }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, setValue, getValues } = useForm({ defaultValues: value });
  const [additional, setAdditional] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });

  useEffect(() => {
    reset(value);
  }, [value]);

  const handleSearchBox = (value: any) => {
    const filters: any = {
      dirPerson: {},
      sports_some: {
        organizations_some: {},
      },
    };

    for (let item in value) {
      if (item === 'birthday_gte' && value[item]) {
        filters.dirPerson.birthday_gte = value[item];
      }
      if (item === 'birthday_lte' && value[item]) {
        filters.dirPerson.birthday_lte = value[item];
      }
      if (item === 'registryNumber' && value[item]) {
        filters.registryNumber = +value[item];
      }
      if (
        (item === 'lastname' ||
          item === 'firstname' ||
          item === 'patronymic' ||
          item === 'previousLastname' ||
          item === 'email') &&
        value[item]
      ) {
        filters.dirPerson[item + '_contains'] = value[item];
      }
      if (item === 'clsStatusPerson' && value[item]) {
        filters.dirPerson.confirmStatus = {
          id: Number(value?.clsStatusPerson?.value),
        };
      }
      if (item === 'dirRegion' && value[item]) {
        filters.dirPerson[item] = {
          id: value?.dirRegion?.value,
        };
      }
      if (item === 'clsPosition' && value[item]) {
        filters.sports_some.organizations_some = {
          ...filters.sports_some.organizations_some,
          dismissalDate: null,
          clsPosition: { id: value?.clsPosition?.value },
        };
      }
      if (item === 'dirSport' && value[item]) {
        filters.sports_some.dirSport = {
          id: value?.dirSport?.value,
        };
      }
      if (item === 'dirOrganization' && value[item]) {
        filters.sports_some.organizations_some = {
          ...filters.sports_some.organizations_some,
          dirOrganization: { id: value[item].value },
          dismissalDate: null,
        };
      }
      if (item === 'isMale' && value?.isMale?.value) {
        filters.dirPerson[item] = value?.isMale?.value;
      }
      if (item === 'birthday' && value[item]) {
        filters.dirPerson[item + '_lte'] = value[item];
      }
      if (item === 'archive' && value?.archive?.value !== '') {
        if (value?.archive?.value === false) {
          filters[item] = null;
        }
        if (value[item].value === true) {
          filters.NOT = {
            archive: null,
          };
        }
      }
    }
    if (Object.keys(filters.dirPerson).length === 0) delete filters.dirPerson;
    if (
      Object.keys(filters.sports_some).length === 1 &&
      Object.keys(filters.sports_some.organizations_some).length === 0
    ) {
      delete filters.sports_some.organizations_some;
      delete filters.sports_some;
    }
    // if (user && user.regionalSchoolProfile) {
    //   filters.sports_some.organizations_some = {
    //     ...filters.sports_some.organizations_some,
    //     dirOrganization: {
    //       id: user.regionalSchoolProfile.dirOrganization.id,
    //     },
    //   };
    // }
    setFilter(filters);
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form
        onSubmit={handleSubmit(handleSearchBox)}
        onReset={(e?) => {
          setValue('registryNumber', '');
          reset();
          setFilter({
            archive: null,
          });
          onSubmit({
            archive: null,
          });
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item container spacing={2} md={11} xs={12}>
            <Grid item xs={3}>
              <Input
                label="Идентификатор тренера"
                control={control}
                error={!!errors['registryNumber']}
                name="registryNumber"
              />
            </Grid>
            <Grid item xs={3}>
              <Input label="Фамилия" control={control} error={!!errors['lastname']} name="lastname" />
            </Grid>
            <Grid item xs={3}>
              <Input label="Имя" control={control} error={!!errors['firstname']} name="firstname" />
            </Grid>
            <Grid item xs={3}>
              <Input label="Отчество" control={control} error={!!errors['patronymic']} name="patronymic" />
            </Grid>
            {additional && <TrainersFilterAdditional control={control} errors={errors} />}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="reset"
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
              <Button
                onClick={() => setAdditional(!additional)}
                type="button"
                className={style.btn}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<TuneIcon />}
              >
                Фильтр
              </Button>
            </Grid>
          </Grid>
          <Grid item container justify="center" md={1} xs={12}>
            <Count query={useTrainersCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Trainers;
