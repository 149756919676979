import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {
  createMuiTheme,
  LinearProgress,
  CssBaseline,
  MuiThemeProvider,
  Dialog,
  Grid,
  DialogContent,
} from '@material-ui/core';
import { blue, green, red } from '@material-ui/core/colors';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client';
import moment from 'moment-timezone';
import { Router, Switch, Route } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store/store';
import { NotFound } from './components/layouts';
import { createUploadLink } from 'apollo-upload-client';

import SERVICES from './legacy/ServiceSearch/services';
import BY_GUID from './legacy/ServiceSearch/byguid';
import ForTrainer from './legacy/ServiceSearch/for_trainer';
import ForAthlete from './legacy/ServiceSearch/for_athlete';
import { HomePage } from './components/HomePage';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { SnackbarProvider } from 'notistack';
import LoginPage from './_pages/Login/Login';
import { history } from './store/store';
import { TypeSportPage, TypeSportPageSingle } from './_pages/TypeSportPage';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { ForeignCitiesPage, ForeignCitiesSingle } from './_pages/ForeignCitiesPage';
import { FederalDistrictsPage } from './_pages/FederalDistrictPage';
import { RegionSingle, RegionsPage } from './_pages/RegionsPage';
import { ClassifierSingle, ClassifiersPage } from './_pages/ClassifierPage';
import { OrganizationPage, OrganizationsPage } from './_pages/OrganizationsPage';
import { PersonPage, PersonsPage } from './_pages/PersonsPage';
import { AthletePage, AthletesPage } from './_pages/AthletesPage';
import { TrainersPage, TrainerPage } from './_pages/TrainersPage';
import { UserPage, UsersPage } from './_pages/UsersPage';
import { CalendarPage, CalendarsPage } from './_pages/CalendarsPage';
import { NationalTeamPage, NationalTeamsPage } from './_pages/NationalTeamsPage';
import { SportingEventPage, SportingEventsPage } from './_pages/SportingEventsPage';
import { DocumentPage, DocumentsPage } from './_pages/DocumentsPage';
import { RefereePage, RefereesPage } from './_pages/RefereesPage';
import { ProgressBar } from './components/ProgressBar';
import { EventPage, EventsPage } from './_pages/EventsPage';
import { MinsportsPage } from './_pages/MinsportsPage';

const getMuiTheme = createMuiTheme({
  palette: {
    secondary: {
      main: green[700],
    },
  },
  typography: {
    htmlFontSize: 12,
    fontSize: 10,
    h4: {
      fontSize: '1rem',
      lineHeight: 1.17,
    },
    h2: {
      fontSize: '1.5rem',
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        marginTop: '-20px',
        maxWidth: '100%',
      },
    },
    MuiFormControl: {
      root: {
        flexGrow: 1,
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: blue[700],
        color: '#fff',
      },
    },
    MuiDrawer: {
      paperAnchorRight: {
        width: '50%',
      },
    },
    MuiAccordion: {
      rounded: {
        margin: '8px auto',
        borderRadius: '4px',
      },
    },
    MuiAccordionSummary: {
      root: {
        borderRadius: '4px',
      },
    },
  },
});

moment.tz('Etc/UTC').format();

// const SelfError = {
//   'Wrong password': 'Неверный логин или пароль',
//   'A unique constraint would be violated on DirOrganization. Details: Field name = ogrn':
//     'Организация с данным ОГРН уже существует в системе',
//   'A unique constraint would be violated on DirSport. Details: Field name = fullName':
//     'Данный вид спорта уже заведен в систему',
//   'A unique constraint would be violated on DirSport. Details: Field name = evsk':
//     'Вида Спорта с данным ЕВСК уже существует в системе',
//   'A unique constraint would be violated on User. Details: Field name = email':
//     'Пользователь с данным логином уже существует',
// };

const { useApolloNetworkStatus, link: networkStatusNotifierLink } = createNetworkStatusNotifier();

function GlobalLoadingIndicator() {
  const status = useApolloNetworkStatus();

  if (status.numPendingQueries > 0) {
    return null;

    return (
      <Dialog disablePortal={true} disableBackdropClick disableEscapeKeyDown aria-labelledby="simple-dialog-title" open>
        <DialogContent style={{ width: 300, paddingTop: 45 }}>
          <ProgressBar />
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const linkUpload = createUploadLink({
  uri: process.env.BACKEND_URI || 'https://gql-dev.master.devsport.ru/graphql',
});

console.log(process.env);

const client = new ApolloClient({
  link: networkStatusNotifierLink.concat(authLink.concat((linkUpload as unknown) as ApolloLink)),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

// Create redux store with history
const store = configureStore();

ReactDOM.render(
  <ApolloProvider client={client}>
    <MuiThemeProvider theme={getMuiTheme}>
      <Provider store={store.store}>
        <PersistGate persistor={store.persistor}>
          <CssBaseline />
          <GlobalLoadingIndicator />
          {/*<NetworkStatusNotifier*/}
          {/*  render={({ loading, error }: { loading: boolean; error: any }) => {*/}
          {/*    const { graphQLErrors = [] } = error || {};*/}
          {/*    if (graphQLErrors.length > 0 && graphQLErrors[0].message === 'Not Authorised!') {*/}
          {/*      localStorage.removeItem('token');*/}
          {/*      window.location.href = '/login';*/}
          {/*      return null;*/}
          {/*    }*/}
          {/*    return (*/}
          {/*      <>*/}
          {/*        {loading && <LinearProgress color="primary" variant="query" />}*/}
          {/*        /!*{error && (*!/*/}
          {/*        /!*  <SnackBars*!/*/}
          {/*        /!*    open={true}*!/*/}
          {/*        /!*    variant="error"*!/*/}
          {/*        /!*    message={_get(SelfError, graphQLErrors[0].message, graphQLErrors[0].message)}*!/*/}
          {/*        /!*  />*!/*/}
          {/*        /!*)}*!/*/}
          {/*      </>*/}
          {/*    );*/}
          {/*  }}*/}
          {/*/>*/}
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ConnectedRouter history={history}>
              <>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/type_sports" component={TypeSportPage} />
                  <Route exact path="/type_sports/:id" component={TypeSportPageSingle} />
                  <Route exact path="/foreign_cities" component={ForeignCitiesPage} />
                  <Route exact path="/federal_districts" component={FederalDistrictsPage} />
                  <Route exact path="/foreign_city/:id" component={ForeignCitiesSingle} />
                  <Route exact path="/regions" component={RegionsPage} />
                  <Route exact path="/region/:id" component={RegionSingle} />
                  <Route exact path="/classifiers" component={ClassifiersPage} />
                  <Route exact path="/classifier/:id" component={ClassifierSingle} />
                  <Route exact path="/organizations" component={OrganizationsPage} />
                  <Route exact path="/organization/:id" component={OrganizationPage} />
                  <Route exact path="/persons" component={PersonsPage} />
                  <Route exact path="/person/:id" component={PersonPage} />
                  <Route exact path="/athletes" component={AthletesPage} />
                  <Route exact path="/athlete/:id" component={AthletePage} />
                  <Route exact path="/trainers" component={TrainersPage} />
                  <Route exact path="/trainer/:id" component={TrainerPage} />
                  <Route exact path="/users" component={UsersPage} />
                  <Route exact path="/user/:id" component={UserPage} />
                  <Route exact path="/calendars" component={CalendarsPage} />
                  <Route exact path="/calendar/:id" component={CalendarPage} />
                  <Route exact path="/national_teams" component={NationalTeamsPage} />
                  <Route exact path="/national_team/:id" component={NationalTeamPage} />
                  <Route exact path="/sporting_events" component={SportingEventsPage} />
                  <Route exact path="/sporting_event/:id" component={SportingEventPage} />
                  <Route exact path="/documents" component={DocumentsPage} />
                  <Route exact path="/document/:id" component={DocumentPage} />
                  <Route exact path="/referees" component={RefereesPage} />
                  <Route exact path="/referee/:id" component={RefereePage} />
                  <Route exact path="/events" component={EventsPage} />
                  <Route exact path="/event/:id" component={EventPage} />
                  <Route exact path="/minsports" component={MinsportsPage} />
                  {/*<Route exact path="/directory/:directory_name/:id" component={Directory} />*/}
                  {/*<Route exact path="/directory/:directory_name" component={Directory} />*/}
                  <Route path="/login" component={LoginPage} />
                  {/*<Route exact path="/public_search" component={SERVICES} />*/}
                  {/*<Route path="/public_search/check_permission_for_athlete/" component={ForAthlete} />*/}
                  {/*<Route exact path="/public_search/check_permissions_for_trainer" component={ForTrainer} />*/}
                  {/*<Route exact path="/public_search/check_permission_by_guid" component={BY_GUID} />*/}
                  {/*<Route path="/public_search/check_permission_by_guid/:guid" component={BY_GUID} />*/}
                  <Route component={NotFound} />
                </Switch>
              </>
            </ConnectedRouter>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
