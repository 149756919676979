import React, { FC, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { useFederalDistrictsQuery } from '../../api';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { useHistory } from 'react-router-dom';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<number>(0);
  const { push } = useHistory();
  const access = usePermission('directory', 'federal_district');
  const { data } = useFederalDistrictsQuery({ variables: { skip: page, first: PER_PAGE } });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'fullName',
      label: 'Название',
      options: {
        sort: false,
      },
    },
    {
      name: 'shortName',
      label: 'Краткое название',
      options: {
        sort: false,
      },
    },
  ];

  return (
    <MainTemplate pageName="Федеральные округа" url="">
      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Федеральные округа</h2>
            </React.Fragment>
          }
          data={(data && data.dirFederalDistricts) || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            rowsPerPage: PER_PAGE,
            count: data && data.count,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
