import React, { useState, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsMoment from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
import { DirAthlete } from '../../types/graphql';
import {
  useAthletesFilteredQuery,
  useTrainersFilteredQuery,
  useClsStagePreparingQuery,
  AthletesExpelMutationHookResult,
  useAthletesExpelMutation,
  AthletesExpelWithMoveToAnotherTrainerMutationHookResult,
  useAthletesExpelWithMoveToAnotherTrainerMutation,
  AthletesExpelWithMoveToAnotherTrainingStageMutationHookResult,
  useAthletesExpelWithMoveToAnotherTrainingStageMutation,
  useAthletesExpelWithMoveToSelfTrainingMutation,
  AthletesExpelWithMoveToSelfTrainingMutationHookResult,
} from '../../api';
moment.locale('ru');

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    padding: '2rem',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) !== -1);
}

function union(a: any, b: any) {
  return [...a, ...not(b, a)];
}

const FireSportmen: FC<any> = ({ modalInfo, sportID, setDialogWidth, onFiredSuccessfuly }) => {
  const { orgId, trainerId, trainingStageId } = modalInfo;

  const filter = {
    sports_some: {
      dirSport: {
        id: sportID,
      },
      organizations_some: trainerId
        ? {
            dirOrganization: {
              id: orgId,
            },
            dirTrainer: {
              id: trainerId,
            },
            clsTrainingStage: {
              id: trainingStageId,
            },
            exclusionDate: null,
          }
        : {
            dirOrganization: {
              id: orgId,
            },
            clsTrainingStage: {
              id: trainingStageId,
            },
            exclusionDate: null,
          },
    },
  };

  const { loading, refetch: refetchSportmen, data: { dirAthletes = [] } = {} } = useAthletesFilteredQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter,
    },
  });

  const [athletesExpel, { error: athleteExpelError }]: AthletesExpelMutationHookResult = useAthletesExpelMutation({
    onCompleted() {
      if (!athleteExpelError) {
        onFiredSuccessfuly(true);
        refetchSportmen();
      }
    },
  });

  const [
    athletesExpelWithMoveToAnotherTrainer,
    { error: athletesExpelWithMoveToAnotherTrainerError },
  ]: AthletesExpelWithMoveToAnotherTrainerMutationHookResult = useAthletesExpelWithMoveToAnotherTrainerMutation({
    onCompleted() {
      if (!athletesExpelWithMoveToAnotherTrainerError) {
        onFiredSuccessfuly(true);
        refetchSportmen();
      }
    },
  });

  const [
    athletesExpelWithMoveToAnotherTrainingStage,
    { error: athletesExpelWithMoveToAnotherTrainingStageError },
  ]: AthletesExpelWithMoveToAnotherTrainingStageMutationHookResult = useAthletesExpelWithMoveToAnotherTrainingStageMutation(
    {
      onCompleted() {
        if (!athletesExpelWithMoveToAnotherTrainingStageError) {
          onFiredSuccessfuly(true);
          refetchSportmen();
        }
      },
    },
  );

  const [
    athletesExpelWithMoveToSelfTraining,
    { error: athletesExpelWithMoveToSelfTrainingError },
  ]: AthletesExpelWithMoveToSelfTrainingMutationHookResult = useAthletesExpelWithMoveToSelfTrainingMutation({
    onCompleted() {
      if (!athletesExpelWithMoveToSelfTrainingError) {
        onFiredSuccessfuly(true);
        refetchSportmen();
      }
    },
  });

  const { data: { dirTrainers = [] } = {} } = useTrainersFilteredQuery({
    variables: {
      filter: {
        sports_some: {
          dirSport: {
            id: sportID,
          },
          organizations_some: {
            dirOrganization: {
              id: orgId,
            },
          },
        },
      },
    },
  });
  const { data: { classifierValues: values = [] } = {} } = useClsStagePreparingQuery();

  const classes = useStyles();
  const [checked, setChecked] = useState<any>([]);
  const [isInSameOrg, setIsInSameOrg] = useState(false);
  const [openSelectBox, setOpenSelectBox] = useState('');
  const [toTrainer, setToTrainer] = useState('');
  const [toStage, setToStage] = useState('');
  const [exclusionDate, setExclusionDate] = useState<Date | null>(null);

  const handleTransferSportmen = () => {
    setIsInSameOrg(false);
    setDialogWidth('sm');
    const sportmenArray = checked.map((sportman: DirAthlete) => sportman.id);
    if (toTrainer) {
      athletesExpelWithMoveToAnotherTrainer({
        variables: {
          athletesID: sportmenArray,
          sportID,
          organizationID: orgId,
          fromTrainerID: trainerId,
          toTrainerID: toTrainer,
          trainingStageID: trainingStageId,
          exclusionDate,
        },
      });
    }
    if (toStage) {
      athletesExpelWithMoveToAnotherTrainingStage({
        variables: {
          athletesID: sportmenArray,
          sportID,
          organizationID: orgId,
          trainerID: trainerId,
          fromTrainingStageID: trainingStageId,
          toTrainingStageID: toStage,
          exclusionDate,
        },
      });
    }
  };

  const handleToSelfTraining = () => {
    const sportmenArray = checked.map((sportman: DirAthlete) => sportman.id);
    setOpenSelectBox('');
    setToTrainer('');
    setToStage('');
    athletesExpelWithMoveToSelfTraining({
      variables: {
        athletesID: sportmenArray,
        sportID,
        organizationID: orgId,
        trainerID: trainerId,
        trainingStageID: trainingStageId,
        exclusionDate,
      },
    });
  };

  const handleFireSportmen = () => {
    const sportmenArray = checked.map((sportman: DirAthlete) => sportman.id);
    athletesExpel({
      variables: trainerId
        ? {
            athletesID: sportmenArray,
            sportID,
            organizationID: orgId,
            trainerID: trainerId,
            trainingStageID: trainingStageId,
            exclusionDate,
          }
        : {
            athletesID: sportmenArray,
            sportID,
            organizationID: orgId,
            trainingStageID: trainingStageId,
            exclusionDate,
          },
    });
  };

  const handleDateChange = (date: Date | null) => setExclusionDate(date);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: any) => intersection(checked, items).length;

  const handleToTrainer = (event: any) => {
    setToTrainer(event.target.value);
  };

  const handleToNewStage = (event: any) => {
    setToStage(event.target.value);
  };

  const handleToggleAll = (items: any) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
      setOpenSelectBox('');
      setToTrainer('');
      setToStage('');
    } else {
      setChecked(union(checked, items));
    }
  };

  const customList = (title: string, items: any) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'Все поле выделены' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} выделены`}
      />
      <Divider />
      {loading && <span style={{ marginLeft: '1rem' }}>Загружается....</span>}
      <List className={classes.list} dense component="div" role="list">
        {dirAthletes.map((athlete: any) => {
          const labelId = `transfer-list-all-item-${athlete.id}-label`;

          return (
            <ListItem key={athlete.id} role="listitem" button onClick={handleToggle(athlete)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(athlete) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId + athlete.id}
                primary={`${athlete.dirPerson.lastname} ${athlete.dirPerson.firstname} ${
                  athlete.dirPerson.patronymic || ''
                }`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <div className={classes.root}>
      <div>{customList('Выделить все', dirAthletes)}</div>
      <MuiPickersUtilsProvider utils={DateFnsMoment} locale="ru">
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <KeyboardDatePicker
            disableToolbar
            fullWidth
            variant="inline"
            format="DD.MM.YYYY"
            label="Дата исключения"
            value={exclusionDate}
            onChange={(date: any, value) => handleDateChange(date)}
            invalidDateMessage="Неверный формат даты"
          />
        </div>
      </MuiPickersUtilsProvider>
      {isInSameOrg ? (
        <div style={{ paddingTop: '1rem' }}>
          <div>
            <Button
              style={{ marginRight: '0.5rem' }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (openSelectBox === 'toNewTrainer') {
                  setOpenSelectBox('');
                  setToTrainer('');
                  setToStage('');
                  return;
                }
                setToStage('');
                setOpenSelectBox('toNewTrainer');
              }}
              disabled={!(checked.length && exclusionDate)}
            >
              Перевод к другому Тренеру
            </Button>
            <Button
              style={{ margin: '0.5rem' }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (openSelectBox === 'toNewTrainingStage') {
                  setOpenSelectBox('');
                  setToTrainer('');
                  setToStage('');
                  return;
                }
                setToTrainer('');
                setOpenSelectBox('toNewTrainingStage');
              }}
              disabled={!checked.length}
            >
              Перевод на другой Этап подготовки
            </Button>
            <Button
              style={{ marginLeft: '0.5rem' }}
              variant="contained"
              color="primary"
              onClick={handleToSelfTraining}
              disabled={!checked.length}
            >
              Перевод на самоподготовку
            </Button>
          </div>
          {checked.length > 0 && openSelectBox === 'toNewTrainer' && (
            <div style={{ margin: '0.5rem 0' }}>
              <FormControl style={{ minWidth: '25ch' }}>
                <InputLabel id="toTrainerListLabel">Тренер</InputLabel>
                <Select
                  id="toTrainer"
                  labelId="toTrainerListLabel"
                  value={toTrainer}
                  onChange={handleToTrainer}
                  label="Тренер"
                >
                  <MenuItem value=""></MenuItem>

                  {dirTrainers &&
                    dirTrainers.map((trainer) => (
                      <MenuItem value={trainer.id} key={trainer.dirPerson.id}>
                        <span>{trainer.dirPerson.lastname}</span>&nbsp;
                        <span>{trainer.dirPerson.firstname}</span>&nbsp;
                        <span>{trainer.dirPerson.patronymic}</span>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          {checked.length > 0 && openSelectBox === 'toNewTrainingStage' && (
            <div style={{ margin: '0.5rem 0' }}>
              <FormControl style={{ minWidth: '45ch' }}>
                <InputLabel id="toNewTrainingStageListLabel">Новый этап подготовки</InputLabel>
                <Select
                  id="toNewTrainingStage"
                  labelId="toNewTrainingStageListLabel"
                  value={toStage}
                  onChange={handleToNewStage}
                  label="Новый этап подготовки"
                >
                  <MenuItem value=""></MenuItem>

                  {values &&
                    values.map((stage: any) => (
                      <MenuItem value={stage.value} key={stage.value}>
                        <span>{stage.label}</span>&nbsp;
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            {' '}
            <Button
              style={{ marginRight: '0.5rem' }}
              variant="contained"
              onClick={() => {
                setIsInSameOrg(false);
                setDialogWidth('sm');
              }}
            >
              Назад
            </Button>{' '}
            {checked.length > 0 && (toTrainer || toStage) && (
              <Button
                style={{ margin: '0.5rem' }}
                variant="contained"
                color="primary"
                onClick={handleTransferSportmen}
                disabled={!exclusionDate}
              >
                Переводить
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: '1rem' }}>
          <Button
            style={{ marginRight: '1rem' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setIsInSameOrg(true);
              setDialogWidth('md');
            }}
            disabled={!(checked.length && exclusionDate)}
          >
            Перевод в рамках организации
          </Button>
          <Button
            disabled={!(checked.length && exclusionDate)}
            variant="contained"
            color="secondary"
            onClick={handleFireSportmen}
          >
            Отчислить
          </Button>
        </div>
      )}
    </div>
  );
};

export default FireSportmen;
