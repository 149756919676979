import { Controller } from 'react-hook-form';
import { FC } from 'react';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Chip, FormControl, Input, InputLabel } from '@material-ui/core';
import _omit from 'lodash/omit';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import moment from 'moment';

interface ReactHookFormSelect extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  data: any[];
  error: boolean | undefined;
  multiple?: boolean;
  loading?: boolean;
  enablePortal?: boolean;
  onChangeInput?: (value: string) => void;
}

const Select: FC<ReactHookFormSelect> = ({
  loading,
  onChangeInput,
  multiple,
  data,
  label,
  error,
  control,
  name,
  rules,
  enablePortal,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          {...props}
          loading={loading}
          multiple={multiple}
          fullWidth
          id={name}
          options={data}
          disablePortal={!enablePortal}
          getOptionSelected={(option, value) => {
            return option.value === value.value;
          }}
          renderTags={(value: { value: any; person: any }[], getTagProps) =>
            value.map((option: { value: any; person: any }, index: number) => (
              <Chip
                variant="outlined"
                label={`${option.person.registryNumber} ${option.person.lastname} ${option.person.firstname} ${
                  option.person.patronymic || ''
                } ${(option.person.birthday && moment(option.person.birthday).format('DD.MM.YY')) || ''}`}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option: { value: any; person: any }) => {
            return `${option.person.registryNumber} ${option.person.lastname} ${option.person.firstname} ${
              option.person.patronymic || ''
            } ${(option.person.birthday && moment(option.person.birthday).format('DD.MM.YY')) || ''}`;
          }}
          onChange={(event, data) => {
            onChange(data);
          }}
          renderInput={(params) => {
            return (
              <FormControl style={{ minWidth: '100%' }} error={error}>
                <InputLabel>{label}</InputLabel>
                <Input
                  {..._omit(params, ['InputProps', 'InputLabelProps'])}
                  {...{ ...params.inputProps, ...params.InputProps }}
                  onChange={(event) => {
                    onChangeInput?.(event.target.value);
                  }}
                />
              </FormControl>
            );
          }}
        />
      )}
      rules={rules}
      control={control}
      name={name}
      defaultValue={multiple ? [] : null}
    />
  );
};

export default Select;
