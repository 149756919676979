import React, { FC, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { PersonFrFragment, useTrainersQuery } from '../../api';
import { Grid, Tooltip } from '@material-ui/core';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';
import moment from 'moment';
import { TrainersFilter } from '../../components/Filters';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });
  const { push } = useHistory();
  const access = usePermission('directory', 'trainers');
  const { data } = useTrainersQuery({ variables: { skip: page * PER_PAGE, first: PER_PAGE, filter } });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: '',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'registryNumber',
      label: 'Реестровый номер',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: Pick<PersonFrFragment, 'id'>, meta) => {
          return (
            <Tooltip title="Перейти в карточку" placement="top-start">
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={`/trainer/${meta.rowData[0]}`}
              >
                {value}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'dirPerson.lastname',
      label: 'Фамилия',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'dirPerson.firstname',
      label: 'Имя',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'dirPerson.patronymic',
      label: 'Отчество',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'dirPerson.birthday',
      label: 'Дата рождения',
      options: {
        filter: false,
        customBodyRender: (birthday) => {
          return moment(birthday).format('DD.MM.YYYY');
        },
      },
    },
    {
      name: 'dirPerson.isMale',
      label: 'Пол',
      options: {
        filter: false,
        customBodyRender: (isMale) => {
          return isMale ? 'Муж' : 'Жен';
        },
      },
    },
    {
      name: 'dirPerson.dirRegion.label',
      label: 'Регион основной',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <MainTemplate pageName="Тренеры" url="">
      <TrainersFilter onSubmit={(filter: any) => setFilter(filter)} />
      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Справочник тренеров</h2>
              <Grid container spacing={1}>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/trainer/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          data={(data && data.dirTrainers) || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            rowsPerPage: PER_PAGE,
            count: data && data.count,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
