import React, { FC } from 'react';
import { useClsDepartmentAffiliationQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SelectCls extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const DepartmentAffiliation: FC<SelectCls> = ({ label, error, control, name, rules }) => {
  const { data } = useClsDepartmentAffiliationQuery();
  return (
    <Select
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default DepartmentAffiliation;
