import React, { FC, useEffect, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { useCalendarsQuery } from '../../api';
import { CalendarsFilter } from '../../components/Filters';
import { Grid, Tooltip } from '@material-ui/core';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';
import { revealCalendar } from '../../utils/revealCalendar';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });
  const [calendars, setCalendars] = useState<any>([]);
  const { push } = useHistory();
  const access = usePermission('directory', 'classifiers');
  const { data } = useCalendarsQuery({ variables: { skip: page, first: PER_PAGE, filter }, fetchPolicy: 'no-cache' });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'info',
      label: 'Реестровый номер',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (info) => {
          const { id, registryNumber } = info || {};
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/calendar/${id}`}
                >
                  {registryNumber}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'fullName',
      label: 'Полное название',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'shortName',
      label: 'Краткое название',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'period',
      label: 'Период',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    setCalendars(revealCalendar(data?.dirCalendars || []));
  }, [page, data]);

  return (
    <MainTemplate pageName="Календарь" url="">
      <CalendarsFilter onSubmit={(filter: any) => setFilter(filter)} />
      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Справочник Календарей</h2>
              <Grid container spacing={1}>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/calendar/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          data={calendars || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            rowsPerPage: PER_PAGE,
            count: data && data.count,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
