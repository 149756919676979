import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import {
  CreateAthleteMutationHookResult,
  CreateTrainerMutationHookResult,
  useAthleteLazyQuery,
  useCreateAthleteMutation,
  useCreateTrainerMutation,
  useTrainerByIdLazyQuery,
} from '../../api';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { blue } from '@material-ui/core/colors';
import AdditionalInfo from '../../components/widgets/Persons/AdditionalInfo';
import MainInfo from '../../components/widgets/Persons/MainInfo';
import moment from 'moment';
import _get from 'lodash/get';
import Duplicates from '../../components/widgets/Persons/Duplicates';
import TypeSports from '../../components/widgets/Athletes/TypeSports';
import Organizations from '../../components/widgets/Athletes/Organizations';
import { useHistory } from 'react-router';
import Competition from '../../components/widgets/Athletes/Competition';
import Medic from '../../components/widgets/Athletes/Medic';
import PersonalTrainer from '../../components/widgets/Athletes/PersonalTrainer';

type AccordionTypes =
  | 'MainInfo'
  | 'Organizations'
  | 'ResultChallenge'
  | 'TypeSports'
  | 'AdditionalInfo'
  | 'MedCertificate'
  | 'Duplicates'
  | 'PersonalTrainer'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const { push } = useHistory();

  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');
  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const [fetch, { data, loading }] = useAthleteLazyQuery({ variables: { filter: { id } } });
  const [create]: CreateAthleteMutationHookResult = useCreateAthleteMutation();
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  useEffect(() => {
    if (id !== 'new' && id !== '') {
      fetch({ variables: { filter: { id } } });
    }
  }, [id]);

  return (
    <MainTemplate pageName="" url="">
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить нового спортсмена</h2>
        ) : (
          <h2>
            Спортсмен: {data?.dirAthlete?.dirPerson?.lastname} {data?.dirAthlete?.dirPerson?.firstname}{' '}
            {_get(data?.dirAthlete?.dirPerson, 'patronymic', '')}
            {moment(data?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YYYY')}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MainInfo
              id={data?.dirAthlete?.dirPerson?.id || 'new'}
              onUpdate={async (idPerson) => {
                if (id === 'new') {
                  const trainer = await create({ variables: { idPerson } });
                  push(`/athlete/${trainer?.data?.createDirAthlete?.id}`);
                }
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'AdditionalInfo'}
          onChange={handleChangeAccordion('AdditionalInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дополнительная информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {data?.dirAthlete?.dirPerson?.id && <AdditionalInfo id={data?.dirAthlete?.dirPerson.id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'ResultChallenge'}
          onChange={handleChangeAccordion('ResultChallenge')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Результаты соревнований</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirAthlete?.id && <Competition id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'TypeSports'}
          onChange={handleChangeAccordion('TypeSports')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Виды спорта</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirAthlete?.dirPerson?.id && <TypeSports id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'PersonalTrainer'}
          onChange={handleChangeAccordion('PersonalTrainer')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Личный тренер</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirAthlete?.dirPerson?.id && <PersonalTrainer id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Organizations'}
          onChange={handleChangeAccordion('Organizations')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Организации</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirAthlete?.dirPerson?.id && <Organizations id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'MedCertificate'}
          onChange={handleChangeAccordion('MedCertificate')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Допуски</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirAthlete?.dirPerson?.id && <Medic id={id} />}</AccordionDetails>
        </Accordion>

        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Duplicates'}
          onChange={handleChangeAccordion('Duplicates')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дубликаты</Typography>
          </AccordionSummary>
          <AccordionDetails>{data?.dirAthlete?.dirPerson?.id && <Duplicates id={id} />}</AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
