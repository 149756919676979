/*
1 - Read
2 - Edit
3 - Archive
 */

import { UserRole } from '../types/types';

const role: UserRole = {
  ADMIN: {
    users: {
      all: [1, 2, 3, 4, 5],
    },
    classifiers: {
      all: [1, 2, 3, 4, 5],
    },
    directory: {
      all: [1, 2, 3, 4, 5],
    },
  },
  CLASSIFIER_OPERATOR: {
    classifiers: {
      all: [1, 2, 3, 5],
    },
  },
  DIRECTORY_OPERATOR: {
    directory: {
      sportsmen: [1, 2, 3, 5],
      trainers: [1, 2, 3, 5],
      person: [1, 2, 3, 5],
      organizations: [1, 2, 3, 5],
      type_sports: [1, 2, 3, 5],
    },
  },
  MINSPORT: {
    directory: {
      sportsmen: [1],
      trainers: [1],
    },
  },
  REGIONAL_MINSPORT: {
    directory: {
      sportsmen: [1],
      trainers: [1],
      person: [1],
      organizations: [1],
    },
  },
  REGIONAL_SCHOOL: {
    directory: {
      sportsmen: [1, 2, 3, 4, 5],
      trainers: [1, 2, 3, 4, 5],
      organizations: [1, 2, 3, 4, 5],
    },
  },
  PERSON: {
    directory: {
      sportsmen: [1],
    },
  },
};

export default role;
