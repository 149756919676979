import { useAthleteLazyQuery } from '../../../api';
import React, { FC, useEffect } from 'react';
import moment from 'moment';
import { Card, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const Medic: FC<{ id: string }> = ({ id }) => {
  const [fetch, { data }] = useAthleteLazyQuery();

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetch({
        variables: { filter: { id } },
      });
    }
  }, [id]);

  if (data?.dirAthlete?.medCerts?.length === 0) return null;

  const urlHead = '//https://qr-ecds.sportfmba.ru/admissions/verify?id=';

  return (
    <div>
      {data?.dirAthlete?.medCerts?.map((item, index) => {
        return (
          !(item.deletedAt && moment().isSameOrAfter(item.deletedAt)) && (
            <Grid item container justify="space-between" spacing={1} key={index} style={{ marginBottom: 16 }}>
              <Grid item container xs={12}>
                <strong>GUID:&nbsp;</strong> {item.id}
              </Grid>
              <Grid item container xs={12}>
                <strong>Номер допуска:&nbsp;</strong> {item.displayNumber}
              </Grid>
              <Grid item container xs={12}>
                <strong>Дата осмотра / Дата выдачи / дата действия:&nbsp;</strong>{' '}
                {(item.physicalExaminationDate && moment(item.physicalExaminationDate).format('DD.MM.YYYY')) || '~'}
                {item.issueDate && moment(item.issueDate).format('DD.MM.YYYY')}
                &nbsp;/&nbsp;
                {item.validUntil ? (
                  <span
                    style={{
                      color: `${item.validUntil && moment().isAfter(item.validUntil) ? 'red' : 'rgba(0, 0, 0, 0.87)'}`,
                    }}
                  >
                    {moment(item.validUntil).format('DD.MM.YYYY')}
                  </span>
                ) : (
                  '~'
                )}
              </Grid>
              <Grid item container xs={12}>
                <strong>Организация, выдавшая допуск:&nbsp;</strong>{' '}
                {[item.organizationAddress, item.organizationPhone, item.organizationEmail]
                  .filter((item) => item && item !== '')
                  .join('/')}
              </Grid>
              <Grid item container xs={12}>
                <strong>Краткие данные организации:&nbsp;</strong> {item.organizationName}
              </Grid>
              {_.map(item.sports, (item) => (
                <Grid item container xs={12}>
                  <strong>Вид спорта / группа дисциплин / этап подготовки:&nbsp;</strong>{' '}
                  {item.dirSport && item.dirSport.fullName}/
                  {(item.dirSport &&
                    item.dirSport.disciplineGroups &&
                    item.dirSport.disciplineGroups.length > 0 &&
                    item.dirSport.disciplineGroups.map((item) => item.name).join(', ')) ||
                    '~'}
                  /{item.clsTrainingStage && item.clsTrainingStage.shortName}
                </Grid>
              ))}

              <Grid item container xs={12} justify="space-between">
                <Grid item>
                  <Link to={urlHead + item.id} target="_blank">
                    Просмотр статуса справки в ЕЦДС
                  </Link>
                </Grid>
                <Grid item>
                  <strong>{item.isValid ? 'Допущен' : 'Не допущен'}</strong>
                </Grid>
              </Grid>
            </Grid>
          )
        );
      })}
    </div>
  );
};

export default Medic;
