import {
  BaseRegionsByIdDocument,
  ClassifierValue,
  CreateTrainerOrganizationMutationHookResult,
  DirOrganization,
  DirTrainerSportOrganization,
  UpdateTrainerOrganizationMutationHookResult,
  useCreateTrainerOrganizationMutation,
  useTrainerByIdQuery,
  useUpdateTrainerOrganizationMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { DatePicker, Select } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import OrganizationsModule from '../../../module/Organizations';
import Positions from '../../../module/Positions';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import FireTrainer from '../../FireBox/FireTrainer';

type FormProps = Pick<DirTrainerSportOrganization, 'id' | 'employmentDate' | 'dismissalDate'> & {
  clsPosition: { __typename?: 'ClassifierValue' } & {
    value: ClassifierValue['id'];
    label: ClassifierValue['fullName'];
  };
  dirOrganization: { __typename?: 'DirOrganization' } & {
    value: DirOrganization['id'];
    label: DirOrganization['name'];
  };
};

const Organizations: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useTrainerByIdQuery({ variables: { id: id === 'new' ? '' : id } });
  const userOrg = useSelector((state: any) => {
    return (
      (state.user?.user?.regionalSchoolProfile && state.user?.user?.regionalSchoolProfile?.dirOrganization?.id) || null
    );
  });
  const { handleSubmit, watch, control, errors, reset, formState, register } = useForm({
    defaultValues: {
      idSport: undefined,
      defaultState: undefined,
      dirSport: undefined,
    } as any,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [modalFire, setModalFire] = useState(false);
  const [edit, setEdit] = useState<null | FormProps>(null);
  const [type, setType] = useState<null | 'fix' | 'fire' | 'redirect'>(null);
  const [mode, setMode] = useState<'list' | 'form'>('list');

  const [create]: CreateTrainerOrganizationMutationHookResult = useCreateTrainerOrganizationMutation();
  const [update]: UpdateTrainerOrganizationMutationHookResult = useUpdateTrainerOrganizationMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: BaseRegionsByIdDocument,
        data: {
          ...data?.updateDirTrainerSportOrganization,
        },
      });
    },
  });

  const typeSport = watch('dirSport');
  const dismissalDate = watch('dismissalDate');

  const onSubmit = async (values: any) => {
    if (id === 'new' && formState.isDirty) {
      reset();
      return;
    }

    const variables = {
      ...values,
      id: values.id,
      idDirOrganization: values?.dirOrganization?.value,
      clsPosition: values?.clsPosition?.value,
    };

    try {
      // Создаем новую организацию
      if (values.id === '') {
        await create({
          variables: {
            ...variables,
            id: values.dirSport?.value,
            employmentDate: values?.employmentDate,
            dismissalDate: null,
          },
        });
        enqueueSnackbar(`Организация "${values?.dirOrganization?.label}" успешно добавлена, тренеру`, {
          variant: 'success',
        });
      }

      // Создаем новую организацию если был перевод
      if (type === 'redirect') {
        await create({
          variables: {
            ...variables,
            id: values.idSport,
            employmentDate: values?.employmentDate,
            dismissalDate: null,
          },
        });
        await update({
          variables: {
            clsPosition: edit?.clsPosition?.value,
            id: values?.id,
            idDirOrganization: edit?.dirOrganization?.value,
            employmentDate: edit?.employmentDate,
            dismissalDate: values?.dismissalDate,
          },
        });
        enqueueSnackbar(
          `Тренер  успешно переведен из "${values?.defaultState?.dirOrganization?.label}" в "${values?.dirOrganization?.label}", тренеру`,
          { variant: 'success' },
        );
      }

      if (type === 'fix') {
        await update({
          variables,
        });
        enqueueSnackbar(`Организация "${values?.dirOrganization?.label}" успешно обновлена у тренера`, {
          variant: 'success',
        });
      }
      setEdit(null);
      setMode('list');
      setType(null);
      await refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onFireTrainer = async () => {
    await refetch();
    setModalFire(false);
    enqueueSnackbar(`Тренер  успешно уволен`, { variant: 'success' });
  };

  return (
    <>
      <Grid container>
        {!readonly && !(mode === 'form' || edit) && (
          <Grid item md={12}>
            <IconButton
              onClick={() => {
                setMode('form');
                reset();
              }}
              edge="end"
              aria-label="add"
              type="button"
            >
              <AddIcon color="primary" />
            </IconButton>
          </Grid>
        )}
        {(mode === 'form' || edit) && (
          <Grid item lg={9} md={12} xs={12}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <ContentWrapperWidget>
                <Grid container spacing={1}>
                  <input type="hidden" name="idSport" ref={register()} />
                  <input type="hidden" name="id" ref={register()} />
                  <input type="hidden" name="defaultState" ref={register()} />
                  <Grid item md={6}>
                    <Select
                      label="Вид спорта"
                      data={
                        data?.dirTrainer?.sports?.map((value) => ({
                          value: value.id,
                          label: value.dirSport.label,
                          valueFetch: value.dirSport.value,
                        })) || []
                      }
                      control={control}
                      error={!!errors['dirSport']}
                      name="dirSport"
                      disabled={type === 'fix' || type === 'redirect'}
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <OrganizationsModule
                      key={typeSport?.value || 'dirOrganization'}
                      label="Организация"
                      error={!!errors['dirOrganization']}
                      filter={{ dirSports_some: { id: typeSport?.valueFetch } }}
                      name="dirOrganization"
                      control={control}
                      disabled={type === 'fix' || type === 'redirect'}
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Positions
                      label="Должность"
                      error={!!errors['clsPosition']}
                      name="clsPosition"
                      control={control}
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <DatePicker
                      name="employmentDate"
                      label="Дата найма"
                      control={control}
                      error={!!errors['employmentDate']}
                      rules={{ required: true }}
                    />
                  </Grid>
                  {((type === 'fix' && dismissalDate) || type === 'redirect') && (
                    <Grid item md={3}>
                      <DatePicker
                        name="dismissalDate"
                        label={type === 'redirect' ? 'Дата перевода' : 'Дата увольнения'}
                        control={control}
                        error={!!errors['dismissalDate']}
                        rules={{ required: true }}
                      />
                    </Grid>
                  )}
                  <Grid item md={12}></Grid>
                  <Grid item md={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<SaveIcon />}
                      style={{ marginRight: '16px' }}
                    >
                      Сохранить
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      size="small"
                      onClick={() => {
                        setType(null);
                        setMode('list');
                        setEdit(null);
                      }}
                    >
                      Отменить
                    </Button>
                  </Grid>
                </Grid>
              </ContentWrapperWidget>
            </form>
          </Grid>
        )}

        {mode === 'list' &&
          !edit &&
          data?.dirTrainer?.sports?.map((sport) => {
            return sport?.organizations?.map((item) => {
              return (
                <Grid key={item.id} container item spacing={1} alignItems="center" md={6} style={{ marginTop: 16 }}>
                  {modalFire && (
                    <FireTrainer
                      sportOrgId={item.id}
                      orgId={item.dirOrganization.value}
                      onSubmit={onFireTrainer}
                      trainerId={id as string}
                      idSport={sport.dirSport.value}
                      onClose={() => setModalFire(false)}
                    />
                  )}
                  <Grid container item xs={12} spacing={1}>
                    <Grid container item xs={12}>
                      <strong>Вид спорта</strong>:&nbsp;
                      <Link
                        style={{
                          fontWeight: 500,
                          cursor: 'pointer',
                          textDecoration: 'none',
                        }}
                        target="_blank"
                        to={`/type_sports/${sport?.dirSport?.value}`}
                      >
                        {sport?.dirSport?.label}
                      </Link>
                    </Grid>
                    <Grid container item xs={12}>
                      <strong>Название организации</strong>:&nbsp;
                      <Link
                        style={{
                          fontWeight: 500,
                          cursor: 'pointer',
                          textDecoration: 'none',
                        }}
                        target="_blank"
                        to={`/organization/${item?.dirOrganization?.value}`}
                      >
                        {item.dirOrganization?.label}
                      </Link>
                    </Grid>
                    <Grid container item xs={12}>
                      <strong>Должность</strong>:&nbsp;{item.clsPosition.label}
                    </Grid>
                    <Grid container item xs={12}>
                      <strong>Дата найма</strong>:&nbsp;
                      {moment(item.employmentDate).format('DD.MM.YYYY')}
                    </Grid>
                    {item.dismissalDate && (
                      <Grid container item xs={12}>
                        <strong>Дата увольнения</strong>:&nbsp;
                        {moment(item.dismissalDate).format('DD.MM.YYYY')}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container xs={12}></Grid>
                  {!readonly && (
                    <Grid item container justify="flex-start" spacing={1}>
                      {!item.dismissalDate && ((userOrg && userOrg === item.dirOrganization.value) || !userOrg) && (
                        <Grid item>
                          <Button variant="outlined" color="primary" onClick={() => setModalFire(true)}>
                            Уволить
                          </Button>
                        </Grid>
                      )}
                      {!item.dismissalDate && ((userOrg && userOrg === item.dirOrganization.value) || !userOrg) && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              reset({
                                ...item,
                                dirSport: sport?.dirSport,
                                idSport: sport?.id,
                                defaultState: item,
                              });
                              setEdit(item);
                              setType('redirect');
                            }}
                          >
                            Перевод в рамках организации
                          </Button>
                        </Grid>
                      )}
                      {((userOrg && userOrg === item.dirOrganization.value) || !userOrg) && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              reset({
                                ...item,
                                dirSport: sport?.dirSport,
                                idSport: sport?.dirSport?.value,
                                defaultState: item,
                              });
                              setEdit(item);
                              setType('fix');
                            }}
                          >
                            Исправить ошибку
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              );
            });
          })}
      </Grid>
    </>
  );
};

export default Organizations;
