import { useAthleteCompetitionLazyQuery } from '../../../api';
import React, { FC, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import _get from 'lodash/get';
const Competition: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const [fetch, { data, refetch }] = useAthleteCompetitionLazyQuery({ variables: { id } });

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetch({
        variables: { id },
      });
    }
  }, [id]);

  if (data?.dirAthlete?.competetionResult?.length === 0) return null;

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Назв. мероприятия</TableCell>
            <TableCell>Категория</TableCell>
            <TableCell>с-по</TableCell>
            <TableCell>Место</TableCell>
            <TableCell>Возрастная группа</TableCell>
            <TableCell>Возраст</TableCell>
            <TableCell>Дисциплина</TableCell>
            <TableCell>Старт</TableCell>
            <TableCell>Занятое место</TableCell>
            <TableCell>Результат</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.dirAthlete?.competetionResult?.map((result) => (
            <TableRow key={result.id}>
              <TableCell component="th" scope="row">
                {result?.sportingEventProgramType?.event?.name || '~'}
              </TableCell>
              <TableCell>{_get(result, 'sportingEventProgramType.event.clsEventCategories[0].label') || '~'}</TableCell>
              <TableCell>
                {moment(result?.sportingEventProgramType?.event?.startDate).format('DD.MM.YY') || '~'}-
                {moment(result?.sportingEventProgramType?.event?.endDate).format('DD.MM.YY') || '~'}
              </TableCell>
              <TableCell>{result?.sportingEventProgramType?.event?.venue || '~'}</TableCell>
              <TableCell>{_get(result, 'sportingEventProgramType.clsAgeGroups[0].label') || '~'}</TableCell>
              <TableCell>
                {result?.sportingEventProgramType?.minAge || '~'}- {result?.sportingEventProgramType?.maxAge || '~'}
              </TableCell>
              <TableCell> {result?.sportingEventProgramType?.discipline?.label || '~'}</TableCell>
              <TableCell>{result?.sportingEventProgramType?.start || '~'}</TableCell>
              <TableCell>{result?.point || '~'}</TableCell>
              <TableCell>{result?.result || '~'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Competition;
