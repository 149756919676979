import {
  CalendarArchiveMutationHookResult,
  CalendarFragment,
  CalendarUnArchiveMutationHookResult,
  CreateCalendarMutationHookResult,
  UpdateCalendarMutationHookResult,
  useCalendarArchiveMutation,
  useCalendarQuery,
  useCalendarUnArchiveMutation,
  useCreateCalendarMutation,
  useUpdateCalendarMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';
import DatePicker from '../../Inputs/DatePicker';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useCalendarQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: data?.dirCalendar as CalendarFragment,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: CreateCalendarMutationHookResult = useCreateCalendarMutation();
  const [update]: UpdateCalendarMutationHookResult = useUpdateCalendarMutation();
  const [archive]: CalendarArchiveMutationHookResult = useCalendarArchiveMutation();
  const [unarchive]: CalendarUnArchiveMutationHookResult = useCalendarUnArchiveMutation();

  const onSubmit = async (values: CalendarFragment) => {
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            data: {
              fullName: values.fullName,
              shortName: values.shortName,
              startDate: values.startDate,
              endDate: values.endDate,
            },
          },
        });
        enqueueSnackbar(`Календарь "${values?.fullName}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            data: {
              fullName: values.fullName as string,
              shortName: values.shortName as string,
              startDate: values.startDate as Date,
              endDate: values.endDate as Date,
            },
          },
        });
        if (data?.createDirCalendar && data?.createDirCalendar?.id) {
          enqueueSnackbar(`Календарь "${values?.fullName}" успешно добавлен`, { variant: 'success' });
          push(`/calendar/${data?.createDirCalendar?.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirCalendar) {
      reset(data?.dirCalendar);
    }
  }, [data]);

  if (readonly) {
  }
  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Input
                    label="Название"
                    control={control}
                    error={!!errors['fullName']}
                    name="fullName"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Краткое название"
                    control={control}
                    error={!!errors['shortName']}
                    name="shortName"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    name="startDate"
                    label="От"
                    control={control}
                    error={!!errors['startDate']}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    name="endDate"
                    label="До"
                    control={control}
                    error={!!errors['endDate']}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button variant="outlined" color="primary" type="button" size="small">
                    Перейти к списку мероприятий
                  </Button>
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={6} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirCalendar?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
