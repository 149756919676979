import moment from 'moment';
import { SportingEventFragment } from '../api';

export const revealEvents = (array: Array<{ __typename?: 'DirSportingEvent' } & SportingEventFragment>) => {
  const unifiedArray = array.map((activity) => {
    const { id, registryNumber, startDate, endDate, sports, dirNationalTeams } = activity;
    const sportString = sports && sports.map((s) => s.dirSport && s.dirSport.label).join(' , ');
    let disciplinesString = '';
    sports?.forEach((sport) => {
      const disciplineString = sport.disciplines?.map((d) => d.label).join(' ,');
      disciplinesString += disciplineString;
    });
    return {
      info: { id, registryNumber },
      period: `${startDate && moment(startDate).format('DD.MM.YY')} - ${endDate && moment(endDate).format('DD.MM.YY')}`,
      sportTypendDisciplines: `${sportString} ${disciplinesString && `${'/ ' + disciplinesString}`}`,
      athletesCount: dirNationalTeams?.reduce(
        (acc, curr) =>
          acc +
          (curr.athleteGroups
            ? curr.athleteGroups.reduce(
                (accGroup, currGroup) => accGroup + (currGroup.athletes ? currGroup.athletes.length : 0),
                0,
              )
            : 0),
        0,
      ),
      athletesCompany: dirNationalTeams?.reduce(
        (acc, curr) => acc + (curr.specialists ? curr.specialists.length : 0),
        0,
      ),
      ...activity,
    };
  });
  return unifiedArray || [];
};

export const revealEventsMinsports = (array: Array<{ __typename?: 'DirSportingEvent' } & SportingEventFragment>) => {
  const unifiedArray = array.map((activity) => {
    const {
      id,
      registryNumber,
      startDate,
      endDate,
      sports,
      dirNationalTeams,
      minManAge,
      maxManAge,
      minWomanAge,
      maxWomanAge,
    } = activity;
    const sportString = sports && sports.map((s) => s.dirSport && s.dirSport.label).join(', ');
    let disciplinesString = '';
    sports?.forEach((sport) => {
      const disciplineString = sport.disciplines?.map((d) => d.label).join(', ');
      disciplinesString += disciplineString;
    });
    let ageString = 'мужчины';
    if ((minManAge != null && Number(minManAge) > 0) || (maxManAge != null && Number(maxManAge) > 0)) {
      ageString = ageString + ' - от ' + Number(minManAge);
      ageString = ageString + ' до ' + (Number(maxManAge) > 0 ? Number(maxManAge) : 99);
    }
    ageString = ageString + ', женщины';
    if ((minWomanAge != null && Number(minWomanAge) > 0) || (maxWomanAge != null && Number(maxWomanAge) > 0)) {
      ageString = ageString + ' - от ' + Number(minWomanAge);
      ageString = ageString + ' до ' + (Number(maxWomanAge) > 0 ? Number(maxWomanAge) : 99);
    }
    return {
      info: { id, registryNumber },
      period: `${startDate && moment(startDate).format('DD.MM.YY')} - ${endDate && moment(endDate).format('DD.MM.YY')}`,
      sportsString: `${sportString}`,
      disciplinesString: `${disciplinesString}`,
      ageString: `${ageString}`,
      organizer: 'ФМБА',
      ticket: 'Проект',
      athletesCount: dirNationalTeams?.reduce(
        (acc, curr) =>
          acc +
          (curr.athleteGroups
            ? curr.athleteGroups.reduce(
                (accGroup, currGroup) => accGroup + (currGroup.athletes ? currGroup.athletes.length : 0),
                0,
              )
            : 0),
        0,
      ),
      athletesCompany: dirNationalTeams?.reduce(
        (acc, curr) => acc + (curr.specialists ? curr.specialists.length : 0),
        0,
      ),
      ...activity,
    };
  });
  return unifiedArray || [];
};
