import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsMoment from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  fullWidth?: boolean;
  errorMessage?: string;
  invalidDateMessage?: string;
  error: boolean | undefined;
}

const DatePicker: FC<ReactHookFormInput> = ({
  name,
  error,
  label,
  errorMessage = '',
  invalidDateMessage = 'Неверный формат',
  control,
  rules,
  fullWidth,
}) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsMoment} locale="ru">
      <Controller
        render={({ onChange, ...props }) => {
          return (
            <KeyboardDatePicker
              fullWidth={fullWidth}
              variant="inline"
              format="DD.MM.YYYY"
              label={label}
              value={props.value}
              onChange={onChange}
              invalidDateMessage={invalidDateMessage}
              error={error}
              helperText={errorMessage}
            />
          );
        }}
        control={control}
        defaultValue={null}
        name={name}
        rules={rules}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
