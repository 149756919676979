import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules' | 'type'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
}

const Input: FC<ReactHookFormInput> = ({ name, error, type = 'text', label, errorMessage = '', control, rules }) => {
  return (
    <Controller
      as={<TextField size="small" type={type} label={label} fullWidth error={error} helperText={errorMessage} />}
      control={control}
      name={name}
      rules={rules}
      defaultValue=""
    />
  );
};

export default Input;
