import React, { ChangeEvent, FC, useState } from 'react';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { useSportingEventQuery } from '../../api';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { blue } from '@material-ui/core/colors';
import MainInfo from '../../components/widgets/SportingEvents/MainInfo';
import Calendar from '../../components/widgets/SportingEvents/Calendar';
import TeamInfo from '../../components/widgets/SportingEvents/TeamInfo';
import Rules from '../../components/widgets/SportingEvents/Rules';
import ProgramTypes from '../../components/widgets/SportingEvents/ProgramTypes';
import CompetitionResults from '../../components/widgets/SportingEvents/CompetitionResults';
import Referees from '../../components/widgets/SportingEvents/Referees';
import Documents from '../../components/widgets/SportingEvents/Documents';
type AccordionTypes =
  | 'MainInfo'
  | 'Calendar'
  | 'TeamInfo'
  | 'Rules'
  | 'ProgramTypes'
  | 'CompetitionResults'
  | 'Referees'
  | 'Documents'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();

  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  const { data } = useSportingEventQuery({ skip: !id, variables: { id }, fetchPolicy: 'no-cache' });
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  return (
    <MainTemplate pageName="" url="">
      <ContentWrapperWidget>
        {id === 'new' ? <h2>Добавить новое мереприятие</h2> : <h2>Мероприятие: {data?.dirSportingEvent?.name}</h2>}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MainInfo id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Calendar'}
          onChange={handleChangeAccordion('Calendar')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Календарь соревнования</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Calendar id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={id === 'new'} expanded={expanded === 'Rules'} onChange={handleChangeAccordion('Rules')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Требования к участникам</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Rules id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'TeamInfo'}
          onChange={handleChangeAccordion('TeamInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Состав участников</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <TeamInfo id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'ProgramTypes'}
          onChange={handleChangeAccordion('ProgramTypes')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Виды программ</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <ProgramTypes id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'CompetitionResults'}
          onChange={handleChangeAccordion('CompetitionResults')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Результаты соревнований</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <CompetitionResults id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Referees'}
          onChange={handleChangeAccordion('Referees')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Судейская коллегия</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Referees id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Documents'}
          onChange={handleChangeAccordion('Documents')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Отчетные документы</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Documents id={id} />
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
