import React, { FC, useState, useEffect } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import DisciplinesMultiselect from '../../../module/DisciplinesMultiSelect';
import AgeGroups from '../../../module/AgeGroups';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Input } from '../../Inputs';
import getMessage from '../../../messages';
import {
  Scalars,
  useSportingEventQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  SportingEventDocument,
} from '../../../api';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  minAge?: Scalars['Int'] | null;
  maxAge?: Scalars['Int'] | null;
  start?: string | null;
  discipline?: { label: string; value: Scalars['UUID'] } | null;
  clsAgeGroups?: { label: string; value: Scalars['UUID'] } | null;
}

const ProgramTypes: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const defaultState: formState = {
    minAge: undefined,
    maxAge: undefined,
    start: undefined,
    discipline: undefined,
    clsAgeGroups: undefined,
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [programTypeId, setProgramTypeId] = useState<Scalars['UUID']>('');

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  const { loading, refetch, data } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });

  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const [sports, programTypes] = [data?.dirSportingEvent?.sports, data?.dirSportingEvent?.programTypes];

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const onSubmit = async (values: formState) => {
    const { minAge, maxAge, start, clsAgeGroups, discipline } = values;
    try {
      if (currentRequest === 'update') {
        await updateEvent({
          variables: {
            data: {
              programTypes: {
                update: [
                  {
                    where: {
                      id: programTypeId,
                    },
                    data: {
                      minAge: Number(minAge) || null,
                      maxAge: Number(maxAge) || null,
                      start,
                      clsAgeGroups: {
                        set: [
                          {
                            id: clsAgeGroups?.value,
                          },
                        ],
                      },
                      discipline: {
                        connect: {
                          id: discipline?.value,
                        },
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });

        handleSnackBar('success', 'Вид програм успешно обновлена');
        setOpenDialog(false);
        setCurrentRequest('');
        setProgramTypeId('');
        reset(defaultState);
        refetch();
        return;
      }
      await updateEvent({
        variables: {
          data: {
            programTypes: {
              create: [
                {
                  minAge: Number(minAge) || 0,
                  maxAge: Number(maxAge) || 0,
                  start,
                  clsAgeGroups: {
                    connect: [
                      {
                        id: clsAgeGroups?.value,
                      },
                    ],
                  },
                  discipline: {
                    connect: {
                      id: discipline?.value,
                    },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'Вид програм успешно создано');
      setOpenDialog(false);
      reset(defaultState);
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteProgram = async (programId: Scalars['UUID']) => {
    try {
      await updateEvent({
        variables: {
          data: {
            programTypes: {
              delete: [
                {
                  id: programId,
                },
              ],
            },
          },
          id,
        },
      });

      handleSnackBar('success', 'Вид програм успешно удаленно');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress />;

  return (
    <div>
      {sports && sports.length > 0 && (
        <div>
          <Tooltip title="добавить" placement="top-start">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {programTypes && programTypes?.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="program types table">
            <TableHead>
              <TableRow>
                <TableCell>Возрастные группы</TableCell>
                <TableCell>Возраст с</TableCell>
                <TableCell>Возраст по&nbsp;</TableCell>
                <TableCell>Дисциплина&nbsp;</TableCell>
                <TableCell>Старт&nbsp;</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programTypes.map((program) => (
                <TableRow key={program.id}>
                  <TableCell component="th" scope="row">
                    {program.clsAgeGroups && program.clsAgeGroups[0]?.label}
                  </TableCell>
                  <TableCell>{program.minAge}</TableCell>
                  <TableCell>{program.maxAge}</TableCell>
                  <TableCell>{program.discipline?.label}</TableCell>
                  <TableCell>{program.start} </TableCell>
                  <TableCell>
                    <div className={classes.row}>
                      <Tooltip title="изменить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="primary"
                          onClick={() => {
                            reset({ ...program, clsAgeGroups: program.clsAgeGroups && program.clsAgeGroups[0] });
                            setProgramTypeId(program?.id);
                            setCurrentRequest('update');
                            setOpenDialog(true);
                          }}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="подробности" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="secondary"
                          onClick={() => deleteProgram(program?.id)}
                        >
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          setProgramTypeId('');
          reset(defaultState);
        }}
        aria-labelledby="add-programType-dialog"
        open={openDialog}
      >
        <DialogTitle id="add-programType-dialog">
          {(currentRequest === 'update' && 'Редактирование') || 'Новый'}
        </DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AgeGroups
                  label="Возрастные группы"
                  control={control}
                  error={!!errors['clsAgeGroups']}
                  name="clsAgeGroups"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DisciplinesMultiselect
                  id={sports?.map((sport) => sport?.dirSport?.value)}
                  multiple={false}
                  label="Дисциплин"
                  control={control}
                  error={!!errors['discipline']}
                  name="discipline"
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  label="Старт"
                  control={control}
                  error={!!errors['start']}
                  name="start"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item container justify="space-between">
                <Grid item xs={4}>
                  <Input
                    label="Возраст с"
                    control={control}
                    error={!!errors['minAge']}
                    name="minAge"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    label="Возраст по"
                    control={control}
                    error={!!errors['maxAge']}
                    name="maxAge"
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default ProgramTypes;
