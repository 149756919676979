import React, { FC } from 'react';
import { useTypeSportsListQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface TypeSports extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  multiple?: boolean;
}

const TypeSports: FC<TypeSports> = ({ label, error, control, name, rules, multiple = true }) => {
  const { data } = useTypeSportsListQuery();
  return (
    <Select
      label={label}
      multiple={multiple}
      data={(data && data.dirSports) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default TypeSports;
