import {
  ClassifierArchiveMutationHookResult,
  ClassifierFragment,
  ClassifierUnArchiveMutationHookResult,
  CreateClassifierMutationHookResult,
  UpdateClassifierMutationHookResult,
  useClassifierArchiveMutation,
  useClassifierByIdQuery,
  useClassifierUnArchiveMutation,
  useCreateClassifierMutation,
  useUniqueValidationClassifierQuery,
  useUpdateClassifierMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useClassifierByIdQuery({ variables: { id } });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: data?.classifier as ClassifierFragment,
  });
  const { data: exist, refetch: existFetch } = useUniqueValidationClassifierQuery({ skip: true });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: CreateClassifierMutationHookResult = useCreateClassifierMutation();
  const [update]: UpdateClassifierMutationHookResult = useUpdateClassifierMutation();
  const [archive]: ClassifierArchiveMutationHookResult = useClassifierArchiveMutation();
  const [unarchive]: ClassifierUnArchiveMutationHookResult = useClassifierUnArchiveMutation();

  const onSubmit = async (values: ClassifierFragment) => {
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            name: values.name,
          },
        });
        enqueueSnackbar(`Классификатор "${values?.name}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            name: values.name,
          },
        });
        if (data?.createClassifier && data?.createClassifier?.id) {
          enqueueSnackbar(`Классификатор "${values?.name}" успешно добавлен`, { variant: 'success' });
          push(`/region/${data?.createClassifier?.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.classifier) {
      reset(data?.classifier);
    }
  }, [reset, data]);

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <Input
                    label="Имя классификатора"
                    control={control}
                    error={!!errors['name']}
                    errorMessage={errors['name']?.message}
                    name="name"
                    rules={{
                      required: true,
                      validate: async (data1) => {
                        const isExist = await existFetch({ name: data1 });
                        return isExist?.data?.classifiers && isExist?.data?.classifiers.length === 0
                          ? undefined
                          : 'Данный классификатор уже существует';
                      },
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={6} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.classifier?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
