import React, { FC, useEffect, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { useAthletesQuery } from '../../api';
import { Grid, Tooltip } from '@material-ui/core';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';
import moment from 'moment';
import revealSportmenInfo from '../../utils/revealSportmenInfo';
import { AthletesFilter } from '../../components/Filters';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });
  const { push } = useHistory();
  const access = usePermission('directory', 'athletes');
  const { data } = useAthletesQuery({ variables: { skip: page * PER_PAGE, first: PER_PAGE, filter } });
  const [athletes, setAthletes] = useState([]);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'info',
      label: 'Реестровый номер',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (info) => {
          const { id, registryNumber } = info || {};
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/athlete/${id}`}
                >
                  {registryNumber}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'fio',
      label: 'Фамилия Имя Отчество',
      options: {
        filter: false,
        sort: false,
        filterType: 'textField',
      },
    },
    {
      name: 'dirPerson.birthday',
      label: 'Дата рождения',
      options: {
        filter: false,
        customBodyRender: (birthday) => moment(birthday).format('DD.MM.YYYY'),
      },
    },
    {
      name: 'dirPerson.isMale',
      label: 'Пол',
      options: {
        filter: false,
        customBodyRender: (isMale) => {
          return isMale ? 'Муж' : 'Жен';
        },
      },
    },
    {
      name: 'region',
      label: 'Регион основной',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'sport',
      label: 'Вид спорта',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'organization',
      label: 'Организация',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'trainingStages',
      label: 'Этапы подготовки',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'trainers',
      label: 'Тренеры',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    setAthletes(revealSportmenInfo(data?.dirAthletes || [], {}, {}));
  }, [page, data]);

  console.log(athletes);

  return (
    <MainTemplate pageName="Спортсмены" url="">
      <AthletesFilter onSubmit={(filter: any) => setFilter(filter)} />
      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Справочник спортсменов</h2>
              <Grid container spacing={1}>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/athlete/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          data={athletes || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            count: data?.count,
            rowsPerPage: PER_PAGE,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
