import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useForeignCitiesListQuery } from '../api';

interface ForeignCities extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
}

const ForeignCities: FC<ForeignCities> = ({ filter, label, error, control, name, rules }) => {
  const { data } = useForeignCitiesListQuery({ variables: { filter } });
  return (
    <Select
      label={label}
      data={(data && data.dirForeignCities) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default ForeignCities;
