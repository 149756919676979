import React, { FC } from 'react';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { DatePicker } from './index';
import { Grid } from '@material-ui/core';

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
}

const DatePickerRange: FC<{ start: ReactHookFormInput; end: ReactHookFormInput }> = ({ start, end }) => {
  console.log(start.errorMessage, end.errorMessage);
  return (
    <Grid container xs={12} justify="space-between" spacing={1}>
      <Grid item xs={6}>
        <DatePicker {...start} />
      </Grid>
      <Grid item xs={6}>
        <DatePicker {...end} />
      </Grid>
    </Grid>
  );
};
export default DatePickerRange;
