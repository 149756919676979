import _ from 'lodash';
import { AthleteListFrFragment } from '../api';

interface Athlete {
  info: {
    id: string;
    registryNumber: number;
  };
  fio: string;
  region?: string;
}

const revealSportmenInfo = (
  array: Array<{ __typename?: 'DirAthlete' } & AthleteListFrFragment>,
  user: any,
  filter: any,
) => {
  const { sports_some } = filter;

  const unifiedArray: any = [];
  if (array && array.length) {
    array.forEach((element) => {
      let athlete: Athlete = {
        ...element,
        info: {
          id: element.id,
          registryNumber: element.dirPerson.registryNumber,
        },
        fio: `${element.dirPerson.lastname} ${element.dirPerson.firstname} ${element.dirPerson.patronymic || ''}`,
      };
      if (element.dirPerson && element.dirPerson.dirRegion) {
        athlete = {
          ...athlete,
          region: element.dirPerson.dirRegion.label,
        };
      } else if (element.dirPerson && element.dirPerson.dirCountry) {
        if (element.dirPerson.dirCountry && !element.dirPerson.dirForeignCity) {
          athlete = {
            ...athlete,
            region: element.dirPerson.dirCountry.label,
          };
        } else if (element.dirPerson.dirCountry && element.dirPerson.dirForeignCity) {
          athlete = {
            ...athlete,
            region: `${element.dirPerson.dirForeignCity.label}, ${element.dirPerson.dirCountry.label}`,
          };
        }
      }
      if (element.sports && element.sports.length > 0) {
        const sportArray =
          sports_some && sports_some.dirSport
            ? element.sports.filter((s) => s.dirSport.id === sports_some.dirSport.id)
            : element.sports;

        sportArray.forEach((sport) => {
          if (sport.organizations && sport.organizations.length > 0) {
            const organizations =
              user && user.regionalSchoolProfile && user.regionalSchoolProfile.dirOrganization
                ? _.groupBy(
                    sport.organizations.filter(
                      (o) => o.dirOrganization.value === user.regionalSchoolProfile.dirOrganization.id,
                    ),
                    (o) => o.dirOrganization.label,
                  )
                : sports_some && sports_some.organizations_some && sports_some.organizations_some.dirOrganization
                ? _.groupBy(
                    sport.organizations.filter(
                      (o) => o.dirOrganization.value === sports_some.organizations_some.dirOrganization.id,
                    ),
                    (o) => o.dirOrganization.label,
                  )
                : sports_some &&
                  sports_some.organizations_some &&
                  sports_some.organizations_some.isSelfTraining === true
                ? _.groupBy(
                    sport.organizations.filter((o) => o.isSelfTraining),
                    (o) => o.dirOrganization.label,
                  )
                : sports_some &&
                  sports_some.organizations_some &&
                  sports_some.organizations_some.isSelfTraining_not === true
                ? _.groupBy(
                    sport.organizations.filter((o) => !o.isSelfTraining),
                    (o) => o.dirOrganization.label,
                  )
                : _.groupBy(
                    sport.organizations.filter((o) => !o.exclusionDate),
                    (o) => o.dirOrganization.label,
                  );

            _.forEach(organizations, (value, key) => {
              let trainers = '';
              let trainingStages = '';

              value.forEach((item, index) => {
                if (
                  item.dirTrainer &&
                  !trainers.includes(item.dirTrainer.dirPerson.lastname) &&
                  item.dirTrainer.sports &&
                  item.dirTrainer.sports.length > 0 &&
                  item.dirTrainer.sports.filter(
                    (s) =>
                      s.organizations &&
                      s.organizations.length > 0 &&
                      s.organizations.filter(
                        (o) =>
                          item.dirOrganization &&
                          o.dirOrganization &&
                          o.dirOrganization.id === item.dirOrganization.value &&
                          !o.dismissalDate,
                      ).length,
                  ).length
                ) {
                  trainers += `${item.dirTrainer.dirPerson.lastname} ${item.dirTrainer.dirPerson.firstname[0]}${
                    item.dirTrainer.dirPerson.patronymic ? `.${item.dirTrainer.dirPerson.patronymic[0]} ` : ''
                  }${
                    index < value.length - 1 &&
                    value[index + 1].dirTrainer &&
                    item.dirTrainer &&
                    value[index + 1]?.dirTrainer?.dirPerson.lastname !== item.dirTrainer.dirPerson.lastname
                      ? ', '
                      : ''
                  }\n`;
                }
                trainingStages += `${item.clsTrainingStage.shortName}${
                  index < value.length - 1 && value[index + 1].clsTrainingStage ? ', ' : ''
                }\n`;
              });

              unifiedArray.push({
                ...athlete,
                trainers,
                trainingStages,
                organization: key,
                sport: sport.dirSport.fullName,
              });
            });

            return;
          }
          if (
            user &&
            !user.regionalSchoolProfile &&
            !(sports_some && sports_some.organizations_some && sports_some.organizations_some.dirOrganization)
          ) {
            unifiedArray.push({ ...athlete, sport: sport.dirSport.fullName });
          }
        });
      } else if (!element.sports) {
        unifiedArray.push(element);
      }
    });
  }
  return unifiedArray;
};

export default revealSportmenInfo;
