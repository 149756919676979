import { Controller } from 'react-hook-form';
import { FC } from 'react';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import { Chip, FormControl, Input, InputLabel } from '@material-ui/core';
import _omit from 'lodash/omit';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ReactHookFormSelect extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  data: any[];
  error: boolean | undefined;
  multiple?: boolean;
  loading?: boolean;
  onChangeInput?: (value: string) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelect: FC<ReactHookFormSelect> = ({
  loading,
  onChangeInput,
  data,
  label,
  error,
  control,
  name,
  rules,
  multiple,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          {...props}
          multiple={multiple}
          loading={loading}
          loadingText="загрузка..."
          noOptionsText="Нет вариантов..."
          fullWidth
          id={name}
          options={data}
          disableCloseOnSelect
          filterSelectedOptions
          getOptionSelected={(option, value) => {
            return option.value === value.value;
          }}
          renderTags={(value: { value: any; label: string }[], getTagProps) =>
            value.map((option: { value: any; label: string }, index: number) => (
              <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
            ))
          }
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </React.Fragment>
          )}
          getOptionLabel={(option: { value: any; label: string }) => {
            return option.label || '';
          }}
          onChange={(event, data) => {
            onChange(data);
          }}
          renderInput={(params) => {
            return (
              <FormControl style={{ minWidth: '100%' }} error={error}>
                <InputLabel>{label}</InputLabel>
                <Input
                  {..._omit(params, ['InputProps', 'InputLabelProps'])}
                  {...{ ...params.inputProps, ...params.InputProps }}
                  onChange={(event) => {
                    onChangeInput?.(event.target.value);
                  }}
                />
              </FormControl>
            );
          }}
        />
      )}
      rules={rules}
      defaultValue={multiple ? [] : null}
      control={control}
      name={name}
    />
  );
};

export default MultiSelect;
