import React, { ChangeEvent, FC, useState } from 'react';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { CreateDocumentMutationHookResult, useCreateDocumentMutation, useDocumentByIdQuery } from '../../api';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { blue } from '@material-ui/core/colors';
import MainInfo from '../../components/widgets/Documents/MainInfo';

type AccordionTypes = 'MainInfo' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const { data } = useDocumentByIdQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const [create]: CreateDocumentMutationHookResult = useCreateDocumentMutation();
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  return (
    <MainTemplate pageName="" url="">
      <ContentWrapperWidget>
        {id === 'new' ? <h2>Добавить нового документа</h2> : <h2>Документ: {data?.dirDocument?.title}</h2>}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MainInfo
              id={data?.dirDocument?.id || 'new'}
              // onUpdate={(idPerson) => (id === 'new' ? create({ variables: { idPerson } }) : undefined)}
            />
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
