import {
  DirAthleteCompetitionResult,
  DirAthleteSportRankAndAward,
  DirNationalTeamAthleteGroup,
  DirNationalTeamMember,
  DirNationalTeamTrainer,
  DirSportingEventSport,
  NationalTeamAddAthleteGroupMutationHookResult,
  NationalTeamAddAthleteMutationHookResult,
  NationalTeamAddMedicMutationHookResult,
  NationalTeamAddSpecialistMutationHookResult,
  NationalTeamAddTrainerMutationHookResult,
  NationalTeamDeleteAthleteGroupMutationHookResult,
  NationalTeamDeleteAthleteMutationHookResult,
  NationalTeamDeleteMedicMutationHookResult,
  NationalTeamDeleteSpecialistMutationHookResult,
  NationalTeamDeleteTrainerMutationHookResult,
  NationalTeamUpdateAthleteGroupMutationHookResult,
  useClsAgeGroupsQuery,
  useDirAthletesSimilarQuery,
  useDirTrainerSimilarQuery,
  useNationalTeamAddAthleteGroupMutation,
  useNationalTeamAddAthleteMutation,
  useNationalTeamAddMedicMutation,
  useNationalTeamAddSpecialistMutation,
  useNationalTeamAddTrainerMutation,
  useNationalTeamDeleteAthleteGroupMutation,
  useNationalTeamDeleteAthleteMutation,
  useNationalTeamDeleteMedicMutation,
  useNationalTeamDeleteSpecialistMutation,
  useNationalTeamDeleteTrainerMutation,
  useNationalTeamQuery,
  useNationalTeamUpdateAthleteGroupMutation,
} from '../../../api';
import { useForm, Controller } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  LinearProgress,
  TextField,
  Link,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Input } from '../../Inputs';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomSuperSelect from '../../../module/CustomSuperSelect';
import moment from 'moment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import DatePicker from '../../Inputs/DatePicker';
import { DirAthleteSport, DirAthleteSportOrganization } from '../../../types/graphql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
    },
    link: {
      cursor: 'pointer',
    },
    ahtleteGroupsText: {
      paddingBottom: 15,
      textAlign: 'end',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    verticalSpace: {
      paddingBottom: 15,
    },
    verticalSpaceMarge: {
      marginBottom: 30,
    },
    divider: {
      height: 1,
      width: '100%',
      marginBottom: '1rem',
    },
    btn: {
      padding: '5px 5px',
      minWidth: '15px',
    },
  }),
);

const TeamInfo: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const classes = useStyles();
  const defaultValue = {
    firstname: '',
    lastname: '',
    patronymic: '',
    birthday: null,
  };

  const defaultAthGroupState: any = {
    minAge: '',
    maxAge: '',
  };

  const [canAdd, setCanAdd] = useState(false);
  const [canAddAthlete, setCanAddAthlete] = useState(false);
  const [newTrainer, setNewTrainer] = useState<String>('');
  const [newAthlete, setNewAthlete] = useState('');
  const [disciplinesId, setDisciplinesId] = useState<any>([]);
  const [ageGroupsId, setAgeGroupsId] = useState<any>([]);
  const [currentRequest, setCurrentRequest] = useState('');
  const [isShowingResult, setIsShowingResult] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [newAthleteBestResult, setNewAthleteBestResult] = useState('');
  const { data: { classifierValues: ageGroups = [] } = {} } = useClsAgeGroupsQuery();
  const { loading, data, refetch } = useNationalTeamQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache',
  });
  const { handleSubmit, control, errors, reset, formState } = useForm();
  const {
    handleSubmit: handleSecondSubmit,
    control: secondControl,
    errors: secondErrors,
    reset: secondReset,
    formState: secondFormState,
  } = useForm();
  const {
    handleSubmit: handleThirdSubmit,
    control: thirdControl,
    errors: thirdErrors,
    reset: thirdReset,
    formState: thirdFormState,
  } = useForm({
    defaultValues: defaultAthGroupState,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [addTrainer]: NationalTeamAddTrainerMutationHookResult = useNationalTeamAddTrainerMutation();
  const [deleteTrainer]: NationalTeamDeleteTrainerMutationHookResult = useNationalTeamDeleteTrainerMutation();
  const [addSpecialist]: NationalTeamAddSpecialistMutationHookResult = useNationalTeamAddSpecialistMutation();
  const [deleteSpecialist]: NationalTeamDeleteSpecialistMutationHookResult = useNationalTeamDeleteSpecialistMutation();
  const [addMedic]: NationalTeamAddMedicMutationHookResult = useNationalTeamAddMedicMutation();
  const [deleteMedic]: NationalTeamDeleteMedicMutationHookResult = useNationalTeamDeleteMedicMutation();
  const [addAthleteGroup]: NationalTeamAddAthleteGroupMutationHookResult = useNationalTeamAddAthleteGroupMutation();
  const [
    updateAthleteGroup,
  ]: NationalTeamUpdateAthleteGroupMutationHookResult = useNationalTeamUpdateAthleteGroupMutation();
  const [
    deleteAthleteGroup,
  ]: NationalTeamDeleteAthleteGroupMutationHookResult = useNationalTeamDeleteAthleteGroupMutation();
  const [addAthlete]: NationalTeamAddAthleteMutationHookResult = useNationalTeamAddAthleteMutation();
  const [deleteAthlete]: NationalTeamDeleteAthleteMutationHookResult = useNationalTeamDeleteAthleteMutation();

  const onDeleteTrainer = async (trainerId: string) => {
    try {
      await deleteTrainer({
        variables: {
          id,
          trainerId,
        },
      });
      enqueueSnackbar(`Тренер успешно добавлено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onAddTrainer = async () => {
    if (canAdd) {
      try {
        await addTrainer({
          variables: {
            id,
            trainerId: newTrainer,
          },
        });
        enqueueSnackbar(`Тренер успешно удалено`, { variant: 'success' });
        setNewTrainer('');
        refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onAddNewSpecialist = async (values: any) => {
    if (canAdd) {
      try {
        await addSpecialist({
          variables: {
            id,
            firstname: values.firstname,
            lastname: values.lastname,
            patronymic: values.patronymic,
            birthday: values.birthday,
          },
        });
        enqueueSnackbar(`Специалист  успешно добавлено`, { variant: 'success' });
        reset(defaultValue);
        refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onDeleteSpecialist = async (specId: string) => {
    try {
      await deleteSpecialist({
        variables: {
          id,
          specId,
        },
      });
      enqueueSnackbar(`Специалист  успешно удалено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onAddNewMedic = async (values: any) => {
    if (canAdd) {
      try {
        await addMedic({
          variables: {
            id,
            firstname: values.firstname,
            lastname: values.lastname,
            patronymic: values.patronymic,
            birthday: values.birthday,
          },
        });
        enqueueSnackbar(`Медик  успешно добавлено`, { variant: 'success' });
        secondReset(defaultValue);
        refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onDeleteMedic = async (medId: string) => {
    try {
      await deleteMedic({
        variables: {
          id,
          medId,
        },
      });
      enqueueSnackbar(`Медик  успешно удалено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onAddAthleteGroup = async (values: any) => {
    const { minAge, maxAge } = values;
    try {
      if (currentRequest === 'update') {
        await updateAthleteGroup({
          variables: {
            id,
            minAge: parseInt(minAge, 10),
            maxAge: parseInt(maxAge, 10),
            ageGroupsId: ageGroupsId.map((a: { value: string; type_name: string }) => ({ id: a.value })),
            disciplinesId: disciplinesId.map((d: { id: string; type_name: string }) => ({ id: d.id })),
            groupId,
          },
        });
        enqueueSnackbar(`Группа  успешно обновлена`, { variant: 'success' });
        thirdReset(defaultAthGroupState);
        setDisciplinesId([]);
        setAgeGroupsId([]);
        setOpenDialog(false);
        refetch();
        return;
      }
      await addAthleteGroup({
        variables: {
          id,
          minAge: parseInt(minAge, 10),
          maxAge: parseInt(maxAge, 10),
          ageGroupsId: ageGroupsId.map((a: { value: string; type_name: string }) => ({ id: a.value })),
          disciplinesId: disciplinesId.map((d: { id: string; type_name: string }) => ({ id: d.id })),
        },
      });
      enqueueSnackbar(`Группа  успешно добавлена`, { variant: 'success' });
      thirdReset(defaultAthGroupState);
      setDisciplinesId([]);
      setAgeGroupsId([]);
      setOpenDialog(false);
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };
  const onDeleteAthleteGroup = async (groupId: string) => {
    try {
      await deleteAthleteGroup({
        variables: {
          id,
          groupId,
        },
      });
      enqueueSnackbar(`Группа  успешно удалено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };
  const onAddAthlete = async (groupId: string) => {
    try {
      await addAthlete({
        variables: {
          id,
          groupId,
          athleteId: newAthlete,
          bestResult: newAthleteBestResult,
        },
      });
      enqueueSnackbar(`Спортсмен  успешно добавлено`, { variant: 'success' });
      setNewAthlete('');
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };
  const onDeleteAthlete = async (groupId: string, athleteId: string) => {
    try {
      await deleteAthlete({
        variables: {
          id,
          groupId,
          athleteId,
        },
      });
      enqueueSnackbar(`Спортсмен  успешно удалено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data && data.dirNationalTeam) {
      const actualFact =
        _.get(data, 'dirNationalTeam.specialists.length') +
        _.get(data, 'dirNationalTeam.medics.length') +
        _.get(data, 'dirNationalTeam.trainers.length');

      const actualAthletesQuota =
        _.get(data, 'dirNationalTeam.manTeamQuota') + _.get(data, 'dirNationalTeam.womanTeamQuota');
      const actualAthletesFact = _.get(data, 'dirNationalTeam.athleteGroups').reduce(
        (acc: number, current: any) => acc + current.athletes.length,
        0,
      );
      setCanAdd(actualFact < _.get(data, 'dirNationalTeam.supportTeamQuota'));
      setCanAddAthlete(actualAthletesQuota > actualAthletesFact);
    }
  }, [data, loading]);

  if (readonly) {
  }
  if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <>
      <Grid container>
        <ContentWrapperWidget>
          <Grid container spacing={1}>
            <Grid item container alignItems="flex-end" className={classes.verticalSpaceMarge} spacing={2}>
              <Grid item xs={2}>
                <span className={classes.title}>
                  Тренеры: {_.get(data, 'dirNationalTeam.trainers')?.length || 0}&nbsp;
                </span>
              </Grid>
              <Grid item>
                <CustomSuperSelect
                  queryHook={useDirTrainerSimilarQuery}
                  baseOptions={{
                    bannedTrainers: _.get(data, 'dirNationalTeam.trainers')?.map(
                      (trainer: DirNationalTeamTrainer) => trainer.dirTrainer.id,
                    ),
                    sportId: _.get(data, 'dirNationalTeam.dirSportingEvent.sports')?.map((s: DirSportingEventSport) =>
                      _.get(s, 'dirSport.id'),
                    ),
                  }}
                  minWidthValue="45ch"
                  name="trainer"
                  fieldValue={newTrainer}
                  onChange={(event: any) => setNewTrainer(event.target.value)}
                  label="поиск"
                  getOptionLabelFunc={(option) =>
                    ` ${option.person.lastname} ${option.person.firstname} ${option.person.patronymic || ''} ${moment(
                      option.person.birthday,
                    ).format('DD.MM.YY')} ${option.person.registryNumber}`
                  }
                  neededObjValue={`person.trainerProfile.id`}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title={(canAdd && 'добавить') || 'Исчерпан лимит на сопровождающих'} placement="top-start">
                  <IconButton
                    className={classes.btn}
                    color={canAdd && newTrainer ? 'primary' : 'default'}
                    onClick={onAddTrainer}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {_.get(data, 'dirNationalTeam.trainers')?.map((trainer: DirNationalTeamTrainer, index: number) => (
              <Grid key={trainer.id} item container alignItems="center">
                <Grid item xs={5}>
                  {index + 1}{' '}
                  <Link
                    style={{ fontWeight: 500, cursor: 'pointer' }}
                    target="_blank"
                    href={`/trainer/${trainer?.dirTrainer.id}`}
                  >
                    {_.get(trainer, 'dirTrainer.dirPerson.lastname')} {_.get(trainer, 'dirTrainer.dirPerson.firstname')}{' '}
                    {_.get(trainer, 'dirTrainer.dirPerson.patronymic') || ''}
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  {moment(_.get(trainer, 'dirTrainer.dirPerson.birthday')).format('DD.MM.YY')}
                </Grid>
                <Grid item xs={2}>
                  <span className={classes.title}>{_.get(trainer, 'dirTrainer.dirPerson.registryNumber')}</span>
                </Grid>
                <Grid item>
                  <Tooltip title="удалить" placement="top-start">
                    <IconButton className={classes.btn} color="secondary" onClick={() => onDeleteTrainer(trainer.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item container>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item container>
            <Grid item xs={12} className={classes.verticalSpace}>
              <span className={classes.title}>
                Специалисты: {_.get(data, 'dirNationalTeam.specialists')?.length || 0}&nbsp;
              </span>
            </Grid>
            <form onSubmit={handleSubmit(onAddNewSpecialist)} style={{ width: '100%' }}>
              <Grid container spacing={2} alignItems="flex-end" className={classes.verticalSpaceMarge}>
                <Grid item>
                  <Input
                    label="Фамилия"
                    control={control}
                    error={!!errors['lastname']}
                    name="lastname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    label="Имя"
                    control={control}
                    error={!!errors['firstname']}
                    name="firstname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    label="Отчество"
                    control={control}
                    error={!!errors['patronymic']}
                    name="patronymic"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    name="birthday"
                    label="ДР"
                    invalidDateMessage="Неверный формат"
                    control={control}
                    error={!!errors['birthday']}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title={(canAdd && 'добавить') || 'Исчерпан лимит на сопровождающих'} placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color={formState.isDirty && canAdd ? 'primary' : 'default'}
                      type="submit"
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
            {_.get(data, 'dirNationalTeam.specialists')?.map((spec: DirNationalTeamMember, index: number) => (
              <Grid key={spec.id} item container alignItems="center">
                <Grid item xs={5}>
                  {index + 1} {spec.lastname} {spec.firstname} {spec.patronymic}
                </Grid>
                <Grid item xs={2}>
                  {moment(spec.birthday).format('DD.MM.YY')}
                </Grid>
                <Grid item>
                  <Tooltip title="удалить" placement="top-start">
                    <IconButton className={classes.btn} color="secondary" onClick={() => onDeleteSpecialist(spec.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item container>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item container>
            <Grid item xs={12} className={classes.verticalSpace}>
              <span className={classes.title}>
                Медперсонал: {_.get(data, 'dirNationalTeam.medics')?.length || 0}&nbsp;
              </span>
            </Grid>
            <form onSubmit={handleSecondSubmit(onAddNewMedic)} style={{ width: '100%' }}>
              <Grid container spacing={2} alignItems="flex-end" className={classes.verticalSpaceMarge}>
                <Grid item>
                  <Input
                    label="Фамилия"
                    control={secondControl}
                    error={!!secondErrors['lastname']}
                    name="lastname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    label="Имя"
                    control={secondControl}
                    error={!!secondErrors['firstname']}
                    name="firstname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    label="Отчество"
                    control={secondControl}
                    error={!!secondErrors['patronymic']}
                    name="patronymic"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    name="birthday"
                    invalidDateMessage="Неверный формат"
                    label="ДР"
                    control={secondControl}
                    error={!!secondErrors['birthday']}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title={(canAdd && 'добавить') || 'Исчерпан лимит на сопровождающих'} placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color={canAdd && secondFormState.isDirty ? 'primary' : 'default'}
                      type="submit"
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
            {_.get(data, 'dirNationalTeam.medics')?.map((med: DirNationalTeamMember, index: number) => (
              <Grid key={med.id} item container alignItems="center" className={classes.verticalSpace}>
                <Grid item xs={5}>
                  {index + 1} {med.lastname} {med.firstname} {med.patronymic}
                </Grid>
                <Grid item xs={2}>
                  {moment(med.birthday).format('DD.MM.YY')}
                </Grid>
                <Grid item>
                  <Tooltip title="удалить" placement="top-start">
                    <IconButton className={classes.btn} color="secondary" onClick={() => onDeleteMedic(med.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item container>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item container alignItems="center" className={classes.verticalSpace}>
            <Grid item xs={2}>
              <span className={classes.title}>
                Спортсмены:&nbsp;
                {_.get(data, 'dirNationalTeam.athleteGroups')?.reduce(
                  (acc: number, curr: any) => acc + curr.athletes.length,
                  0,
                ) || 0}
                &nbsp;
              </span>
            </Grid>
            <Grid item container justify="space-between" md={8} sm={12}>
              <Grid item>
                <Button variant="contained" color="primary" size="small" onClick={() => setOpenDialog(true)}>
                  Добавить группу
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setIsShowingResult(!isShowingResult)}
                >
                  {(isShowingResult && 'Скрывать результать') || 'Показать результат'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {_.get(data, 'dirNationalTeam.athleteGroups')?.map((group: DirNationalTeamAthleteGroup) => (
            <React.Fragment key={group.id}>
              <Grid
                item
                container
                alignItems="flex-end"
                justify="center"
                spacing={2}
                className={classes.verticalSpaceMarge}
              >
                <Grid item lg={6} sm={12} className={classes.ahtleteGroupsText}>
                  <span className={classes.title} style={{ paddingRight: 5 }}>
                    {_.get(group, 'disciplines[0].dirSport.fullName') || ''} [(
                    {group?.disciplines?.map((discipline) => discipline.name).join(' , ')}) ;&nbsp; (
                    {group?.clsAgeGroups?.map((age) => age.fullName).join(' , ')}) ,&nbsp;
                    {group.minAge}-{group.maxAge}]:&nbsp; {group?.athletes?.length || 0}&nbsp;
                  </span>
                </Grid>
                <Grid item>
                  <CustomSuperSelect
                    queryHook={useDirAthletesSimilarQuery}
                    baseOptions={{
                      bannedAthletes: group?.athletes?.map((athlete) => athlete?.dirAthlete?.id),
                      maxBday: moment().subtract(group.maxAge, 'years'),
                      minBday: moment().subtract(group.minAge, 'years'),
                      sportId: _.get(group, 'disciplines[0].dirSport.id'),
                    }}
                    minWidthValue="45ch"
                    name="trainer"
                    fieldValue={newTrainer}
                    onChange={(event: any) => setNewAthlete(event.target.value)}
                    label="поиск"
                    getOptionLabelFunc={(option) =>
                      ` ${option.person.lastname} ${option.person.firstname} ${option.person.patronymic || ''} ${moment(
                        option.person.birthday,
                      ).format('DD.MM.YY')} ${option.person.registryNumber}`
                    }
                    neededObjValue="person.athleteProfile.id"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="newAthleteBestResult"
                    label="комментарии"
                    name="newAthleteBestResult"
                    size="small"
                    value={newAthleteBestResult}
                    onChange={(event) => setNewAthleteBestResult(event.target.value)}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={(canAddAthlete && 'добавить') || 'Исчерпан лимит на спортсмены'}
                    placement="top-start"
                  >
                    <IconButton
                      className={classes.btn}
                      color={canAddAthlete && newAthlete ? 'primary' : 'default'}
                      onClick={() => canAddAthlete && onAddAthlete(group.id)}
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="изменить" placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color="primary"
                      onClick={() => {
                        setCurrentRequest('update');
                        setGroupId(group.id);
                        thirdReset({ ...group });
                        setDisciplinesId(group?.disciplines);
                        setAgeGroupsId(
                          group?.clsAgeGroups?.map((age) => ({ ...age, label: age.fullName, value: age.id })),
                        );
                        setOpenDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="удалить" placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color="secondary"
                      onClick={() => onDeleteAthleteGroup(group.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item container>
                {group.athletes?.map((athlete, index) => (
                  <Grid key={athlete.id} item container justify="space-evenly" alignItems="center" xs={12}>
                    <Grid item>
                      {index + 1}.&nbsp;{' '}
                      <Link
                        style={{ fontWeight: 500, cursor: 'pointer' }}
                        target="_blank"
                        href={`/athlete/${athlete.id}`}
                      >
                        {_.get(athlete, 'dirAthlete.dirPerson.lastname')}{' '}
                        {_.get(athlete, 'dirAthlete.dirPerson.firstname')}{' '}
                        {_.get(athlete, 'dirAthlete.dirPerson.patronymic')}
                      </Link>
                    </Grid>
                    <Grid item>{_.get(athlete, 'dirAthlete.dirPerson.registryNumber')}</Grid>
                    <Grid item>{moment(_.get(athlete, 'dirAthlete.dirPerson.birthday')).format('DD.MM.YY')}</Grid>
                    <Grid item>
                      {_.get(athlete, 'dirAthlete.sports')
                        .filter(
                          (s: DirAthleteSport) =>
                            _.get(s, 'dirSport.id') === _.get(group, 'disciplines[0].dirSport.id'),
                        )[0]
                        ?.ranksAndAwards.filter((r: DirAthleteSportRankAndAward) =>
                          r.validUntil ? moment().isBefore(moment(r.validUntil)) : r,
                        )
                        .map((r: DirAthleteSportRankAndAward) => r.clsRankAndAward.fullName)
                        .join(' , ')}
                    </Grid>
                    <Grid item>
                      {_.uniqBy(
                        _.get(athlete, 'dirAthlete.sports').filter(
                          (s: DirAthleteSport) =>
                            _.get(s, 'dirSport.id') === _.get(group, 'disciplines[0].dirSport.id'),
                        )[0]?.organizations,
                        (org: DirAthleteSportOrganization) => org.dirOrganization.id,
                      ).map((o) => (
                        <>
                          <Link
                            style={{ fontWeight: 500, cursor: 'pointer' }}
                            target="_blank"
                            href={`/organization/${o.dirOrganization.id}`}
                          >
                            {o.dirOrganization.fullName}
                          </Link>
                          &nbsp;,&nbsp;
                        </>
                      ))}
                    </Grid>
                    <Grid item>{athlete.bestResult}</Grid>
                    {isShowingResult && (
                      <React.Fragment>
                        <Grid item>{_.get(athlete, 'dirAthlete.dirPerson.isMale') === false ? 'Жен.' : 'Муж.'}</Grid>
                        {group?.disciplines?.map(
                          (discipline, index) =>
                            _.get(athlete, 'dirAthlete.competetionResult').filter(
                              (result: DirAthleteCompetitionResult) =>
                                result.sportingEventProgramType.discipline.id === discipline.id,
                            ).length > 0 && (
                              <React.Fragment key={discipline.id}>
                                <Grid item>
                                  Возраст:&nbsp;
                                  {
                                    _.get(athlete, 'dirAthlete.competetionResult').filter(
                                      (result: DirAthleteCompetitionResult) =>
                                        result.sportingEventProgramType.discipline.id === discipline.id,
                                    )[0].sportingEventProgramType.minAge
                                  }
                                  &nbsp;-&nbsp;
                                  {
                                    _.get(athlete, 'dirAthlete.competetionResult').filter(
                                      (result: DirAthleteCompetitionResult) =>
                                        result.sportingEventProgramType.discipline.id === discipline.id,
                                    )[0].sportingEventProgramType.maxAge
                                  }
                                </Grid>
                                <Grid item>{discipline.name}</Grid>
                                <Grid item>
                                  {
                                    _.get(athlete, 'dirAthlete.competetionResult').filter(
                                      (result: DirAthleteCompetitionResult) =>
                                        result.sportingEventProgramType.discipline.id === discipline.id,
                                    )[0].sportingEventProgramType.start
                                  }
                                </Grid>
                                <Grid item>
                                  {
                                    _.get(athlete, 'dirAthlete.competetionResult').filter(
                                      (result: DirAthleteCompetitionResult) =>
                                        result.sportingEventProgramType.discipline.id === discipline.id,
                                    )[0].point
                                  }
                                </Grid>
                                <Grid item>
                                  {
                                    _.get(athlete, 'dirAthlete.competetionResult').filter(
                                      (result: DirAthleteCompetitionResult) =>
                                        result.sportingEventProgramType.discipline.id === discipline.id,
                                    )[0].result
                                  }
                                </Grid>
                                {index < (group?.disciplines?.length || 0) - 1 && ' | '}
                              </React.Fragment>
                            ),
                        )}
                      </React.Fragment>
                    )}
                    <Grid item>
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="secondary"
                          onClick={() => onDeleteAthlete(group.id, athlete.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item container>
                <Divider className={classes.divider} />
              </Grid>
            </React.Fragment>
          ))}
          <Dialog
            onClose={() => {
              setOpenDialog(false);
              thirdReset(defaultAthGroupState);
              setDisciplinesId([]);
              setAgeGroupsId([]);
              setGroupId('');
              setCurrentRequest('');
            }}
            aria-labelledby="add-athlete-group-dialog"
            open={openDialog}
          >
            <DialogTitle id="add-calendar-dialog">
              {(currentRequest && 'Редактировать группу') || 'Новая группа'}
            </DialogTitle>
            <div style={{ padding: '1.5rem' }}>
              <form onSubmit={handleThirdSubmit(onAddAthleteGroup)} style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={_.flattenDeep(
                        _.get(data, 'dirNationalTeam.dirSportingEvent.sports')?.map(
                          (sport: DirSportingEventSport) => sport?.disciplines,
                        ),
                      )}
                      value={disciplinesId}
                      limitTags={4}
                      onChange={(event, value) => setDisciplinesId(value)}
                      getOptionLabel={(option) => option?.name}
                      getOptionSelected={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Дисциплины" placeholder="дисциплина" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={ageGroups}
                      value={ageGroupsId}
                      limitTags={4}
                      onChange={(event, value) => setAgeGroupsId(value)}
                      getOptionLabel={(option) => option?.label}
                      getOptionSelected={(option, value) => option?.value === value?.value}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" label="Возрастные группы" placeholder="группа" />
                      )}
                    />
                  </Grid>
                  <Grid item container xs={12} justify="space-between">
                    <Grid item xs={4}>
                      <Controller
                        name="minAge"
                        control={thirdControl}
                        error={!!thirdErrors['minAge']}
                        rules={{ required: true }}
                        as={
                          <TextField
                            label="Возраст с"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="maxAge"
                        control={thirdControl}
                        error={!!thirdErrors['maxAge']}
                        rules={{ required: true }}
                        as={
                          <TextField
                            label="Возраст по"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      style={{ marginLeft: 'auto', display: 'block' }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!(disciplinesId.length && ageGroupsId.length)}
                    >
                      Добавить
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Dialog>
        </ContentWrapperWidget>
      </Grid>
    </>
  );
};

export default TeamInfo;
