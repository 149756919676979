import React, { FC } from 'react';
import { useCountriesFetchQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface Country extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const Countries: FC<Country> = ({ label, error, control, name, rules }) => {
  const { data } = useCountriesFetchQuery();
  return (
    <Select
      label={label}
      data={(data && data.dirCountries) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default Countries;
