import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Menu } from './Menu';
import useStyles from './style';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Grid,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Drawer,
  Divider,
  List,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useValidationTokenQuery } from '../../../api';

const MainTemplate: FC<{ pageName: string; url: string }> = ({ pageName, url, children }) => {
  const { push } = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [submenu, setSubmenu] = useState(false);
  const anchorRef = useRef(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlerSubmenuToggle = () => {
    setSubmenu(!submenu);
  };

  const onExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    push('/login');
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      // @ts-ignore
      anchorRef && anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { error } = useValidationTokenQuery();
  if (error) {
    if (error.message === 'Not Authorised!') {
      localStorage.removeItem('token');
      push('/login');
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/*<AppBar*/}
      {/*  position="fixed"*/}
      {/*  className={classNames(classes.appBar, {*/}
      {/*    [classes.appBarShift]: open,*/}
      {/*  })}*/}
      {/*  style={{ backgroundColor: '#fff', color: '#000' }}*/}
      {/*>*/}
      {/*  <Toolbar disableGutters={!open}>*/}
      {/*    <IconButton*/}
      {/*      color="inherit"*/}
      {/*      aria-label="Open drawer"*/}
      {/*      onClick={handleDrawerOpen}*/}
      {/*      className={classNames(classes.menuButton, open && classes.hide)}*/}
      {/*    >*/}
      {/*      <MenuIcon />*/}
      {/*    </IconButton>*/}
      {/*    <Typography variant="h6" color="inherit" noWrap>*/}
      {/*      <div className={classes.pageNamestyle}>{pageName}</div>*/}
      {/*    </Typography>*/}
      {/*  </Toolbar>*/}
      {/*</AppBar>*/}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <React.Fragment>
            <Grid container justify="flex-end">
              <Avatar
                alt="Avatar"
                src="https://v3.material-ui.com/static/images/avatar/1.jpg"
                onClick={handlerSubmenuToggle}
              />
            </Grid>

            <div>
              <Button onClick={handlerSubmenuToggle} className={classes.buttonStyleright} ref={anchorRef}>
                {localStorage.getItem('userRole')}
              </Button>
              <Popper
                className={classes.modalAvatar}
                open={submenu}
                anchorEl={anchorRef.current}
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper>
                      <ClickAwayListener onClickAway={() => setSubmenu(false)}>
                        <MenuList>
                          <MenuItem onClick={onExit}>Выйти</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </React.Fragment>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Menu url={url} />
        </List>
        <Divider />
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
      </main>
    </div>
  );
};

export default MainTemplate;
