import React, { FC } from 'react';
import { useClsTypeContactQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface TypeContact extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  errorMessage?: string;
}

const TypeContact: FC<TypeContact> = ({ errorMessage, label, error, control, name, rules }) => {
  const { data } = useClsTypeContactQuery();
  return (
    <Select
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      errorMessage={errorMessage}
      name={name}
      rules={rules}
    />
  );
};

export default TypeContact;
