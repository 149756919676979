import React, { useState, FC } from 'react';
import { Grid, IconButton, Tooltip, Link, Button, FormControlLabel, Checkbox, Divider } from '@material-ui/core';
import { DatePicker, Input } from '../../Inputs';
import DocumentSelect from '../../../module/Documents';
import TypeSports from '../../../module/TypeSports';
import RefereesCategories from '../../../module/RefereesCategories';
import { DropzoneArea } from 'material-ui-dropzone';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import ArchiveWidget from '../Shared/Archive';
import _ from 'lodash';
import moment from 'moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import getMessage from '../../../messages';
import {
  Scalars,
  DirRefereeSport,
  useRefereeByIdQuery,
  UploadFileMutationHookResult,
  useUploadFileMutation,
  UpdateRefereeSportMutationHookResult,
  useUpdateRefereeSportMutation,
  DocumentFragment,
  DirDocument,
  CreateRefereeSportMutationHookResult,
  useCreateRefereeSportMutation,
  RefereeSportArchiveMutationHookResult,
  useRefereeSportArchiveMutation,
  RefereeSportUnArchiveMutationHookResult,
  useRefereeSportUnArchiveMutation,
} from '../../../api';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
    padding: 10,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',

      '& > .MuiDropzonePreviewList-imageContainer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '100% ',
        flexBasis: 'unset',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          left: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

interface formState {
  title?: string;
  date?: Date;
  number?: string;
}

interface lightFormState {
  dirDocument?: { label: string; value: Scalars['UUID'] };
}

interface DocComponent {
  currentCat: Scalars['UUID'];
  documentUpdated: () => void;
  dirDocument: any;
  id: Scalars['UUID'];
}

const DocumentComponent = ({ currentCat, dirDocument, id, documentUpdated }: DocComponent) => {
  const classes = useStyles();

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND?.replace('/graphql', '');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultNewDocumentState: formState = {
    title: undefined,
    date: undefined,
    number: undefined,
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultNewDocumentState,
  });

  const {
    handleSubmit: documentFormHandleSubmit,
    control: documentFormControl,
    errors: documentFormErrors,
    reset: documentFormReset,
    formState: documentFormState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });
  const [updateSportData]: UpdateRefereeSportMutationHookResult = useUpdateRefereeSportMutation();
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const [isAddingNewDocument, setIsAddingNewDocument] = useState(false);
  const [isAddingNEwDocumentFromSystem, setIsAddingNEwDocumentFromSystem] = useState(false);
  const [file, setFile] = useState<Array<File>>([]);

  const addNewDocument = async (values: lightFormState) => {
    const { dirDocument } = values;

    try {
      if (isAddingNewDocument && dirDocument) {
        updateSportData({
          variables: {
            data: {
              categories: {
                update: [
                  {
                    where: {
                      id: currentCat,
                    },
                    data: {
                      dirDocument: {
                        connect: {
                          id: dirDocument?.value,
                        },
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        documentUpdated();
        setIsAddingNewDocument(false);
        documentFormReset();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const handleAddNewDocumentFromSystem = async (values: formState) => {
    const { title, date, number } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then((resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          updateSportData({
            variables: {
              data: {
                categories: {
                  update: [
                    {
                      where: {
                        id: currentCat,
                      },
                      data: {
                        dirDocument: {
                          create: {
                            title,
                            date,
                            number,
                            file: {
                              connect: { id: fileId },
                            },
                          },
                        },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          documentUpdated();
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteDocument = async () => {
    try {
      await updateSportData({
        variables: {
          data: {
            categories: {
              update: [
                {
                  where: {
                    id: currentCat,
                  },
                  data: {
                    dirDocument: { disconnect: true },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      documentUpdated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <React.Fragment>
      {dirDocument ? (
        <Grid container alignItems="center" spacing={2} style={{ marginTop: '0.8rem' }}>
          <Grid item>
            <Link className={classes.link} target="_blank" href={`${DOWNLOAD_LINK}${dirDocument.file.path}`}>
              <DescriptionIcon fontSize="large" />
            </Link>
          </Grid>
          <Grid item>
            <Tooltip title="удалить" placement="top">
              <IconButton color="secondary" onClick={deleteDocument}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          {isAddingNewDocument ? (
            <React.Fragment>
              <form onSubmit={documentFormHandleSubmit(addNewDocument)} style={{ width: '100%' }}>
                <Grid item container spacing={2} alignItems="flex-end">
                  <Grid item md={5} xs={12}>
                    <DocumentSelect
                      label="Документ"
                      control={documentFormControl}
                      error={!!documentFormErrors['dirDocument']}
                      name="dirDocument"
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ marginRight: '0.5rem' }}
                      startIcon={<SaveIcon />}
                      type="submit"
                      disabled={!documentFormState.isDirty}
                    >
                      Сохранить
                    </Button>

                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      onClick={() => {
                        setIsAddingNewDocument(false);
                        reset();
                      }}
                    >
                      Отменить
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item container alignItems="center">
                <Grid item>
                  <span style={{ marginRight: '0.5rem' }}>Добавить документ</span>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '0.5rem' }}
                    size="small"
                    type="button"
                    onClick={() => setIsAddingNewDocument(true)}
                  >
                    Из базы
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '0.5rem' }}
                    size="small"
                    type="button"
                    onClick={() => {
                      setIsAddingNEwDocumentFromSystem(true);
                    }}
                  >
                    Новый
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={control}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата"
                  control={control}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="номера"
                  control={control}
                  error={!!errors['number']}
                  name="number"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

interface catForm {
  clsRefereeCategory?: { value: Scalars['UUID']; label: string };
  assignmentDate?: Date;
  validUntil?: Date;
}

interface sportComp {
  sport: DirRefereeSport;
  id: Scalars['UUID'];
  refetch: any;
}

const SportComponent = ({ sport, id, refetch }: sportComp) => {
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [isEndless, setIsEndless] = useState(false);

  const [updateSportData]: UpdateRefereeSportMutationHookResult = useUpdateRefereeSportMutation();
  const [archive]: RefereeSportArchiveMutationHookResult = useRefereeSportArchiveMutation();
  const [unarchive]: RefereeSportUnArchiveMutationHookResult = useRefereeSportUnArchiveMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: catForm = {
    clsRefereeCategory: undefined,
    assignmentDate: undefined,
    validUntil: undefined,
  };

  const {
    handleSubmit: workExperienceFormHandleSubmit,
    control: workExperienceFormControl,
    errors: workExperienceFormErrors,
    reset: workExperienceFormReset,
    formState: workExperienceFormState,
  } = useForm({
    defaultValues: {
      workExperience: sport?.workExperience,
    },
  });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  const onUpdateWorkExperience = async (values: any) => {
    const { workExperience } = values;
    try {
      await updateSportData({
        variables: {
          data: {
            workExperience: parseInt(workExperience, 10),
          },
          id: sport?.id,
        },
      });
      handleSnackBar('success', 'Стаж Судейской деятельности успешно обновлено');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onAddCategory = async (values: any) => {
    const { clsRefereeCategory, assignmentDate, validUntil } = values;
    try {
      await updateSportData({
        variables: {
          data: {
            categories: {
              create: [
                {
                  clsRefereeCategory: {
                    connect: {
                      id: clsRefereeCategory?.value,
                    },
                  },
                  assignmentDate,
                  validUntil: validUntil || null,
                  isEndless,
                },
              ],
            },
          },
          id: sport?.id,
        },
      });
      handleSnackBar('success', 'категория  успешно добавлена');
      setIsAddingCategory(false);
      reset();
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onUpdateCategory = async (values: any, currentCategory: Scalars['UUID']) => {
    const { clsRefereeCategory, assignmentDate, validUntil } = values;
    try {
      await updateSportData({
        variables: {
          data: {
            categories: {
              update: [
                {
                  where: {
                    id: currentCategory,
                  },
                  data: {
                    assignmentDate,
                    validUntil: isEndless ? null : validUntil || null,
                    isEndless,
                    clsRefereeCategory: {
                      connect: {
                        id: clsRefereeCategory?.value,
                      },
                    },
                  },
                },
              ],
            },
          },
          id: sport?.id,
        },
      });
      handleSnackBar('success', 'категория  успешно обновлена');
      setIsEditingCategory(false);
      reset();
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteCategory = async (catId: Scalars['UUID']) => {
    try {
      await updateSportData({
        variables: {
          data: {
            categories: {
              delete: [
                {
                  id: catId,
                },
              ],
            },
          },
          id: sport?.id,
        },
      });
      handleSnackBar('success', 'категория  успешно удаленна');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <span>{sport?.dirSport?.fullName}</span>
        <form onSubmit={workExperienceFormHandleSubmit(onUpdateWorkExperience)} style={{ width: '100%' }}>
          <Grid item container spacing={1} alignItems="flex-end">
            <Grid item md={6} xs={12}>
              <Input
                label="Стаж Судейской деятельности"
                control={workExperienceFormControl}
                error={!!workExperienceFormErrors['workExperience']}
                name="workExperience"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                type="submit"
                disabled={!workExperienceFormState.isDirty}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid item container alignItems="center" style={{ marginTop: 15 }}>
          <Grid item>
            <span>Судейская категория</span>
          </Grid>
          <Grid item>
            {!isAddingCategory && (
              <Grid item>
                <Tooltip title="изменить" placement="top-start">
                  <IconButton color="primary" onClick={() => setIsAddingCategory(!isAddingCategory)}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isAddingCategory && (
          <form onSubmit={handleSubmit(onAddCategory)} style={{ width: '100%' }}>
            <Grid item container spacing={1} alignItems="flex-end">
              <Grid item md={6} xs={12}>
                <RefereesCategories
                  label="Категория"
                  control={control}
                  error={!!errors['clsRefereeCategory']}
                  name="clsRefereeCategory"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DatePicker
                  label="Дата начала действия"
                  control={control}
                  error={!!errors['assignmentDate']}
                  name="assignmentDate"
                  rules={{ required: true }}
                />
              </Grid>
              {!isEndless && (
                <Grid item md={6} xs={12}>
                  <DatePicker
                    label="Дата окончания действия"
                    control={control}
                    error={!!errors['validUntil']}
                    name="validUntil"
                    rules={{ required: !isEndless }}
                  />
                </Grid>
              )}
              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isEndless}
                      onChange={() => setIsEndless(!isEndless)}
                      name="isEndless"
                      color="primary"
                    />
                  }
                  label="Бессрочно"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="button"
                  style={{ marginRight: 10 }}
                  onClick={() => setIsAddingCategory(false)}
                >
                  Отменить
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        {sport?.categories?.map((cat: any, index) => (
          <React.Fragment key={cat.id}>
            <Grid container spacing={1} alignItems="center" style={{ marginBottom: 15 }}>
              <Grid item xs={6} sm={5} md={4} lg={3}>
                <span style={{ fontWeight: 500 }}>{cat?.clsRefereeCategory?.label} &nbsp;</span>
              </Grid>
              <Grid item>
                <span>
                  Период действия:&nbsp;
                  {(cat?.assignmentDate && moment(cat?.assignmentDate).format('DD.MM.YY')) || 'не определено'}
                  &nbsp;-&nbsp;
                  {(cat?.validUntil && moment(cat?.validUntil).format('DD.MM.YY')) ||
                    (cat?.isEndless && 'Бессрочно') ||
                    'не определено'}
                </span>
              </Grid>
              <Grid item>
                <Tooltip title="изменить" placement="top-start">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      reset(cat);
                      setIsEndless(cat?.isEndless);
                      setIsEditingCategory(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="удалить" placement="top">
                  <IconButton color="secondary" onClick={() => deleteCategory(cat.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <DocumentComponent
              id={sport?.id}
              key={cat.clsRefereeCategory.id}
              currentCat={cat.id}
              documentUpdated={() => {
                handleSnackBar('success', 'Документы успешно обновлена');
                refetch();
              }}
              dirDocument={cat.dirDocument}
            />
            <Dialog
              open={isEditingCategory}
              onClose={() => {
                setIsEditingCategory(false);
                reset();
                setIsEndless(false);
              }}
              maxWidth="xs"
            >
              <DialogTitle id="add-document-dialog"> Изменить </DialogTitle>
              <div style={{ padding: '1.5rem' }}>
                <Grid item container spacing={1}>
                  <form
                    onSubmit={handleSubmit((values) => onUpdateCategory(values, cat?.id))}
                    style={{ width: '100%' }}
                  >
                    <Grid item>
                      <RefereesCategories
                        label="Категория"
                        control={control}
                        error={!!errors['clsRefereeCategory']}
                        name="clsRefereeCategory"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="Дата начала действия"
                        control={control}
                        error={!!errors['assignmentDate']}
                        name="assignmentDate"
                        rules={{ required: true }}
                      />
                    </Grid>
                    {!isEndless && (
                      <Grid item>
                        <DatePicker
                          label="Дата окончания действия"
                          control={control}
                          error={!!errors['validUntil']}
                          name="validUntil"
                          rules={{ required: !isEndless }}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isEndless}
                            onChange={() => setIsEndless(!isEndless)}
                            name="isEndless"
                            color="primary"
                          />
                        }
                        label="Бессрочно"
                      />
                    </Grid>
                    <Grid item container justify="flex-end">
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<SaveIcon />}
                        type="button"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          setIsEditingCategory(false);
                          reset();
                        }}
                      >
                        Отменить
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<SaveIcon />}
                        type="submit"
                        disabled={!(formState.isDirty || !_.isEqual(isEndless, cat?.isEndless))}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              </div>
            </Dialog>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item md={6} xs={12}>
        <ArchiveWidget
          archive={sport?.archive || null}
          id={sport?.id}
          refetch={refetch}
          onArchive={archive}
          onUnarchive={unarchive}
        />
      </Grid>
    </Grid>
  );
};

const RefereeCategory: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useRefereeByIdQuery({
    variables: { id },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [createSportData]: CreateRefereeSportMutationHookResult = useCreateRefereeSportMutation();

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      typeSport: undefined,
    },
  });

  const onAddSportType = async (values: any) => {
    const { typeSport } = values;
    try {
      await createSportData({
        variables: {
          sportId: typeSport?.value,
          id,
        },
      });
      handleSnackBar('success', 'Вид спорта  успешно добавлена');
      reset();
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <div style={{ width: '100%', paddingTop: 15 }}>
      <form onSubmit={handleSubmit(onAddSportType)} style={{ width: '100%', marginBottom: 20 }}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item md={8}>
            <TypeSports
              label="Вид спорта"
              control={control}
              error={!!errors['typeSport']}
              name="typeSport"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Добавить
            </Button>
          </Grid>
        </Grid>
      </form>
      {data?.dirReferee?.sports?.map((sport: any) => (
        <React.Fragment key={sport?.id}>
          <SportComponent sport={sport} id={id} refetch={refetch} />
          <Divider style={{ width: '100%', marginBottom: 15, marginTop: 15 }} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default RefereeCategory;
