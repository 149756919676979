import React, { FC } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input } from '../Inputs';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
interface Filter {
  OR: Array<object>;
}
interface Value {
  fullName: string;
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Calendars: FC<{ onSubmit?: (T: any) => void }> = ({ onSubmit = () => {} }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      fullName: undefined,
    },
  });

  const handleSearchBox = (value: Value) => {
    const filters: Filter = {
      OR: [],
    };

    if (value.fullName !== '') {
      filters.OR = [
        {
          fullName_contains: value.fullName as string,
        },
        {
          fullName_contains: _.startCase(value.fullName) as string,
        },
      ];
    }
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input label="Название Календаря" control={control} error={!!errors['fullName']} name="fullName" />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit({});
                reset();
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Calendars;
