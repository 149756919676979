import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Radio, FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
  data: DataInput[];
}

interface DataInput {
  value: any;
  label: string;
}

const Radios: FC<ReactHookFormInput> = ({ data, name, error, label, errorMessage = '', control, rules }) => {
  return (
    <FormControl component="fieldset" error={error}>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        as={
          <RadioGroup style={{ flexDirection: 'row' }}>
            {data.map((value) => (
              <FormControlLabel key={value.value} value={value.value} control={<Radio />} label={value.label} />
            ))}
          </RadioGroup>
        }
        control={control}
        error={errorMessage}
        name={name}
        rules={rules}
        defaultValue={''}
      />
    </FormControl>
  );
};

export default Radios;
