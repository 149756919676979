import React, { FC } from 'react';
import { useClsRanksAndTitlesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SelectCls extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  multiple?: boolean;
  usedValues?: any;
}

const RanksAndTitle: FC<SelectCls> = ({ label, error, control, name, rules, multiple = false }) => {
  const { data } = useClsRanksAndTitlesQuery();
  return (
    <Select
      label={label}
      data={data?.classifierValues || []}
      control={control}
      error={error}
      name={name}
      multiple={multiple}
      rules={rules}
    />
  );
};

export default RanksAndTitle;
