import React, { FC, useEffect, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { DirSport, useGenerateXlsFileLazyQuery, useTypeSportsQuery } from '../../api';
import { TypeSportsFilter } from '../../components/Filters';
import { Grid, Tooltip } from '@material-ui/core';
import { downloadB64File } from './helpers';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });
  const { push } = useHistory();
  const access = usePermission('directory', 'person');
  const { data } = useTypeSportsQuery({ variables: { skip: page, first: PER_PAGE, filter } });
  const [fetch, { data: binaryFile }] = useGenerateXlsFileLazyQuery();
  const downLoadFile = async (filterString: string) => {
    await fetch({
      variables: {
        filterString,
        filter,
        first: PER_PAGE,
      },
    });
  };

  useEffect(() => {
    if (binaryFile) {
      const {
        dirSportsReport: { xlsx },
      } = binaryFile;
      if (xlsx) {
        downloadB64File(xlsx);
      }
    }
  }, [binaryFile]);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'registryNumber',
      options: {
        display: false,
      },
    },
    {
      name: 'id',
      label: 'Идентификатор вида спорта',
      options: {
        sort: false,
        customBodyRender: (value: Pick<DirSport, 'id'>, meta) => {
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/type_sports/${value}`}
                >
                  {meta.rowData[0]}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'evsk',
      label: 'Номер ЕВСК',
      options: {
        sort: false,
      },
    },
    {
      name: 'fullName',
      label: 'Название',
      options: {
        sort: false,
      },
    },
  ];

  return (
    <MainTemplate pageName="Виды спорта" url="">
      <TypeSportsFilter
        onSubmit={(filter: any) => {
          setPage(0);
          setFilter(filter);
        }}
        value={{ registryNumber: '' }}
        showDownloadButton={Object.keys(filter).length > 0}
        onDownloadFile={downLoadFile}
      />

      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Виды спорта</h2>
              <Grid container spacing={1}>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/type_sports/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          data={(data && data.dirSports) || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            rowsPerPage: PER_PAGE,
            count: data?.count,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
