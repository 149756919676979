import {
  CreatePersonMutationHookResult,
  PersonArchiveMutationHookResult,
  PersonUnArchiveMutationHookResult,
  UpdatePersonMutationHookResult,
  useCreatePersonMutation,
  useFindSimiliarPersonLazyQuery,
  usePersonArchiveMutation,
  usePersonByIdLazyQuery,
  usePersonUnArchiveMutation,
  useUpdatePersonMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { DatePicker, Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';
import Regions from '../../../module/Regions';
import Radios from '../../Inputs/Radio';
import ForeignCities from '../../../module/ForeignCities';
import StatusConfirm, { OPTIONS } from '../../../module/StatusConfirm';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import SportsHandballRoundedIcon from '@material-ui/icons/SportsHandballRounded';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import { Countries } from '../../../module';

const MainInfo: FC<{ readonly?: boolean; id?: string; onUpdate?: (id?: string) => void }> = ({
  readonly,
  id,
  onUpdate,
}) => {
  const [fetchPerson, { data, refetch }] = usePersonByIdLazyQuery({ variables: { id: id === 'new' ? '' : id } });
  const [findSimilar, { called, data: resultSimilar }] = useFindSimiliarPersonLazyQuery({
    variables: { search: '' },
  });
  const { handleSubmit, watch, control, errors, reset, formState, setValue } = useForm({
    defaultValues: {
      email: null,
      dirCountry: null,
      ...data?.dirPerson,

      isMale: data?.dirPerson?.isMale === undefined ? null : data?.dirPerson?.isMale ? 'true' : 'false',
      isRussia: data?.dirPerson?.isRussia === undefined ? null : data?.dirPerson?.isRussia ? 'true' : 'false',
    } as any,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  let isRussia = watch('isRussia');
  const [archive]: PersonArchiveMutationHookResult = usePersonArchiveMutation();
  const [unarchive]: PersonUnArchiveMutationHookResult = usePersonUnArchiveMutation();
  const [create]: CreatePersonMutationHookResult = useCreatePersonMutation();
  const [update]: UpdatePersonMutationHookResult = useUpdatePersonMutation();
  const []: UpdatePersonMutationHookResult = useUpdatePersonMutation();
  const watchCountry = watch('dirCountry');

  useEffect(() => {
    if (watchCountry?.value !== data?.dirPerson?.dirCountry?.value) {
      setValue('dirForeignCity', null);
    }
  }, [watchCountry]);

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetchPerson({
        variables: { id },
      });
    }
  }, [id]);

  const onSubmit = async (values: any) => {
    if (id === 'new' && formState.isDirty && !called) {
      reset({
        ...data?.dirPerson,
        ...values,
        isMale: values?.isMale,
        isRussia: values?.isRussia,
      });
      await findSimilar({
        variables: {
          search: [values.lastname, values.firstname, values.patronymic].join(' '),
        },
      });
      return;
    }

    // Если коффицент > 0.7 значит рассмотрение если меньше то подтвержден

    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            ...values,
            countryID: values?.dirCountry?.value && { connect: { id: values?.dirCountry?.value } },
            foreignCityID:
              (values?.dirForeignCity?.value && { connect: { id: values?.dirForeignCity?.value } }) ||
              (data?.dirPerson?.dirCountry !== null &&
                data?.dirPerson?.dirCountry?.value !== values?.dirCountry?.value && {
                  delete: true,
                }) ||
              null,
            regionID: values?.dirRegion?.value && { connect: { id: values?.dirRegion?.value } },
            isMale: values?.isMale === 'false' ? false : true,
            isRussia: values?.isRussia === 'false' ? false : true,
            confirmStatus: values?.confirmStatus?.value && { connect: { id: values?.confirmStatus?.value } },
          },
        });
        onUpdate && onUpdate();
        enqueueSnackbar(
          `Персона "${[values.lastname, values.firstname, values.patronymic].join(' ')}" успешно обновлена`,
          { variant: 'success' },
        );
      } else {
        const { data } = await create({
          variables: {
            ...values,
            countryID: values?.dirCountry?.value && { connect: { id: values?.dirCountry?.value } },
            foreignCityID: (values?.dirForeignCity?.value && { connect: { id: values?.dirForeignCity?.value } }) || {
              delete: true,
            },
            regionID: values?.dirRegion?.value && { connect: { id: values?.dirRegion?.value } },
            isMale: values?.isMale === 'false' ? false : true,
            isRussia: values?.isRussia === 'false' ? false : true,
            confirmStatus:
              resultSimilar?.dirPersonsSimilar?.length === 0
                ? { connect: { id: 2 } }
                : {
                    connect: {
                      id:
                        (
                          resultSimilar?.dirPersonsSimilar?.filter(
                            (person) => person?.similarity && person?.similarity >= 0.7,
                          ) || []
                        ).length > 0
                          ? 1
                          : 2,
                    },
                  },
          },
        });
        if (data?.createDirPerson && data?.createDirPerson?.id) {
          enqueueSnackbar(
            `Персона "${[values.lastname, values.firstname, values.patronymic].join(' ')}" успешно добавлена`,
            { variant: 'success' },
          );
          onUpdate && onUpdate(data?.createDirPerson?.id);
          !onUpdate && push(`/person/${data?.createDirPerson?.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirPerson) {
      reset({
        ...data?.dirPerson,
        isMale: data?.dirPerson?.isMale === undefined ? null : data?.dirPerson?.isMale ? 'true' : 'false',
        isRussia: data?.dirPerson?.isRussia === undefined ? null : data?.dirPerson?.isRussia ? 'true' : 'false',
        confirmStatus: data.dirPerson.confirmStatus
          ? OPTIONS.filter((value) => value.value === data?.dirPerson?.confirmStatus?.value)?.[0]
          : null,
      });
    }
  }, [data]);

  if (readonly) {
  }
  return (
    <>
      <Grid container>
        <Grid item lg={9} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Input
                    label="Фамилия"
                    control={control}
                    error={!!errors['lastname']}
                    name="lastname"
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[А-я -]+$/,
                        message: 'Поле может содержать только русские буквы',
                      },
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Имя"
                    control={control}
                    error={!!errors['firstname']}
                    name="firstname"
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input label="Отчество" control={control} error={!!errors['patronymic']} name="patronymic" />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Предыдущая фамилия"
                    control={control}
                    error={!!errors['previousLastname']}
                    name="previousLastname"
                  />
                </Grid>
                <Grid item md={6}>
                  <Input label="Email" control={control} error={!!errors['email']} name="email" />
                </Grid>
                <Grid item md={12}>
                  <DatePicker
                    label="Дата рождения"
                    control={control}
                    error={!!errors['birthday']}
                    name="birthday"
                    rules={{ required: true }}
                  />
                </Grid>

                <Grid item md={6}>
                  <Radios
                    label="Пол"
                    data={[
                      {
                        value: 'true',
                        label: 'Муж',
                      },
                      {
                        value: 'false',
                        label: 'Жен',
                      },
                    ]}
                    control={control}
                    error={!!errors['isMale']}
                    name="isMale"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Radios
                    label="Страна"
                    data={[
                      {
                        value: 'true',
                        label: 'Россия',
                      },
                      {
                        value: 'false',
                        label: 'Зарубеж',
                      },
                    ]}
                    control={control}
                    error={!!errors['isRussia']}
                    name="isRussia"
                    rules={{ required: true }}
                  />
                </Grid>
                {isRussia === 'true' && (
                  <Grid item md={6}>
                    <Regions
                      label="Регион РФ"
                      control={control}
                      error={!!errors['dirRegion']}
                      name="dirRegion"
                      rules={{
                        required: true,
                        validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                      }}
                    />
                  </Grid>
                )}
                {isRussia === 'false' && (
                  <>
                    <Grid item md={6}>
                      <Countries
                        label="Название страны"
                        control={control}
                        error={!!errors['dirCountry']}
                        name="dirCountry"
                        rules={{
                          required: true,
                          validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                        }}
                      />
                    </Grid>
                    {watchCountry?.value && (
                      <Grid item md={6}>
                        <ForeignCities
                          label="Название города"
                          control={control}
                          filter={{ dirCountry: { id: watchCountry?.value } }}
                          error={!!errors['dirForeignCity']}
                          name="dirForeignCity"
                        />
                      </Grid>
                    )}
                  </>
                )}
                {id !== 'new' && (
                  <Grid item md={6}>
                    <StatusConfirm
                      label="Статус подтверждения"
                      control={control}
                      error={!!errors['confirmStatus']}
                      name="confirmStatus"
                      rules={{
                        required: true,
                        validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={12}></Grid>
                {resultSimilar && resultSimilar?.dirPersonsSimilar.length > 0 && (
                  <Grid item md={12}>
                    <h4>Найдены дубликаты: {resultSimilar?.dirPersonsSimilar.length} </h4>
                    <List>
                      {resultSimilar?.dirPersonsSimilar?.map(({ person, similarity }) => {
                        return (
                          <ListItem
                            button
                            // onClick={() => push(`/person/${person.registryNumber}`)}
                            key={person.registryNumber}
                          >
                            <ListItemText
                              primary={`${person.lastname} ${person.firstname} ${
                                person.patronymic ? person.patronymic : ''
                              }`}
                              secondary={`${person.registryNumber}`}
                              style={{ marginRight: '32px', flex: '0 1 auto' }}
                            />
                            {person.id && (
                              <ListItemIcon style={{ marginRight: 16 }}>
                                <PersonIcon />
                                <Link
                                  style={{
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                  to={`/person/${person.id}`}
                                >
                                  Персона
                                </Link>
                              </ListItemIcon>
                            )}
                            {person.trainerProfile?.id && (
                              <ListItemIcon style={{ marginRight: 16 }}>
                                <TimelineRoundedIcon />
                                <Link
                                  style={{
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                  to={`/trainer/${person.trainerProfile?.id}`}
                                >
                                  Тренер
                                </Link>
                              </ListItemIcon>
                            )}
                            {person.athleteProfile?.id && (
                              <ListItemIcon style={{ marginRight: 16 }}>
                                <SportsHandballRoundedIcon />
                                <Link
                                  style={{
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                  to={`/athlete/${person.athleteProfile?.id}`}
                                >
                                  Атлет
                                </Link>
                              </ListItemIcon>
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                )}
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={
                      id === 'new' && formState.isDirty && !called ? <SearchIcon color="primary" /> : <SaveIcon />
                    }
                  >
                    {id === 'new' && formState.isDirty && !called
                      ? 'Проверить на повторяемость'
                      : id !== 'new'
                      ? 'Сохранить'
                      : 'Создать новую персону'}
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={6} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirPerson?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
