import React, { FC } from 'react';
import { Scalars, useMultipleTypeSportDisciplinesDrillQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import _ from 'lodash';

interface SelectCls extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  multiple?: boolean;
  id: Scalars['UUID'];
}

const DisciplinesMultiselect: FC<SelectCls> = ({ id, label, error, control, name, rules, multiple = true }) => {
  const { data } = useMultipleTypeSportDisciplinesDrillQuery({ variables: { id } });
  return (
    <Select
      label={label}
      multiple={multiple}
      data={_.flattenDeep(data?.dirSports?.map((sport) => sport.disciplines)) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default DisciplinesMultiselect;
