import {
  AgeGroupFrFragment,
  GroupTypesSportAndDisciplinesDocument,
  TypeSportAgeGroupsCreateMutationHookResult,
  TypeSportAgeGroupsRemoveMutationHookResult,
  TypeSportAgeGroupsUpdateMutationHookResult,
  useTypeSportAgeGroupsCreateMutation,
  useTypeSportAgeGroupsQuery,
  useTypeSportAgeGroupsRemoveMutation,
  useTypeSportAgeGroupsUpdateMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import RemoveIcon from '@material-ui/icons/Delete';
import { AgeGroups } from '../../../module';

const AgeGroupsWidget: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = useTypeSportAgeGroupsQuery({ variables: { id } });
  const { handleSubmit, register, control, errors, watch, reset, formState } = useForm({
    defaultValues: { minAge: 0, maxAge: undefined, clsAgeGroup: {}, id: null } as AgeGroupFrFragment,
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | AgeGroupFrFragment>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [create]: TypeSportAgeGroupsCreateMutationHookResult = useTypeSportAgeGroupsCreateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [remove]: TypeSportAgeGroupsRemoveMutationHookResult = useTypeSportAgeGroupsRemoveMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [update]: TypeSportAgeGroupsUpdateMutationHookResult = useTypeSportAgeGroupsUpdateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const onRemove = async (value: string, label: string) => {
    try {
      await remove({
        variables: {
          id,
          idCls: value,
        },
      });
      enqueueSnackbar(`Половозрастная группа "${label}" успешно удалена`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values: AgeGroupFrFragment) => {
    const maxAge = values?.maxAge ? +values?.maxAge : null;
    const minAge = values?.minAge ? +values?.minAge : 0;
    try {
      if (edit) {
        await update({
          variables: {
            id,
            maxAge,
            minAge,
            idGroup: values?.id,
            idCls: values?.clsAgeGroup?.value,
          },
        });
        setEdit(null);
        enqueueSnackbar(`Половозрастная группа "${values?.clsAgeGroup?.label}" успешно обновлена`, {
          variant: 'success',
        });
      } else {
        await create({
          variables: {
            id,
            maxAge,
            minAge,
            idCls: values?.clsAgeGroup?.value,
          },
        });
        enqueueSnackbar(`Половозрастная группа "${values?.clsAgeGroup?.label}" успешно добавлена`, {
          variant: 'success',
        });
      }
      reset({ minAge: undefined, maxAge: undefined, clsAgeGroup: {}, id: null });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  const watchedMaxAge = watch('maxAge');

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({ minAge: undefined, maxAge: undefined, clsAgeGroup: {}, id: null });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={5}>
              <AgeGroups
                label="Половозрастная группа"
                error={!!errors['clsAgeGroup']}
                name="clsAgeGroup"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={2}>
              <Input
                label="От"
                type="number"
                control={control}
                error={!!errors['minAge']}
                errorMessage={errors['minAge']?.message}
                name="minAge"
                rules={{
                  required: true,
                  validate: (val) => {
                    return !watchedMaxAge
                      ? undefined
                      : +watchedMaxAge >= +val
                      ? undefined
                      : 'Возраст ОТ не может быть больше ДО';
                  },
                }}
              />
            </Grid>
            <Grid item md={2}>
              <Input label="До" control={control} error={!!errors['maxAge']} name="maxAge" />
            </Grid>
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={6}>
          <List>
            {data?.dirSport?.ageGroups?.map((item) => (
              <ListItem key={item.id} alignItems="flex-start">
                <ListItemText
                  primary={item.clsAgeGroup.label}
                  secondary={`От ${item.minAge}${item.maxAge ? ` до ${item.maxAge}` : ''}`}
                />
                {!readonly && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => {
                        reset(item);
                        setEdit(item);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => onRemove(item.id, item.clsAgeGroup?.label)}
                    >
                      <RemoveIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default AgeGroupsWidget;
