import {
  AddTypeSportsToAthleteMutationHookResult,
  AthleteDocument,
  TypeSportAthleteArchiveMutationHookResult,
  TypeSportAthleteUnArchiveMutationHookResult,
  useAddTypeSportsToAthleteMutation,
  useAthleteLazyQuery,
  useTypeSportAthleteArchiveMutation,
  useTypeSportAthleteUnArchiveMutation,
} from '../../../api';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { CardHeader, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import TypeSportModule from '../../../module/TypeSports';
import Disciplines from './components/Dicsiplines';
import Role from './components/Role';
import RankAwards from './components/RanksAwards';
import ArchiveComponent from '../../Dialogs/Archive/Archive';

const TypeSports: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const [fetch, { data, refetch }] = useAthleteLazyQuery({ variables: { filter: { id } } });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { typesport: null },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [create]: AddTypeSportsToAthleteMutationHookResult = useAddTypeSportsToAthleteMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: AthleteDocument,
        data: {
          ...data?.updateDirAthlete,
        },
      });
    },
  });

  const [archive]: TypeSportAthleteArchiveMutationHookResult = useTypeSportAthleteArchiveMutation();
  const [unarchive]: TypeSportAthleteUnArchiveMutationHookResult = useTypeSportAthleteUnArchiveMutation();

  const onSubmit = handleSubmit(async (values?: { typesport?: { label: string; value: any } | null }) => {
    if (!values?.typesport) return;
    try {
      await create({
        variables: {
          id,
          sportId: values?.typesport?.value,
        },
      });
      reset({ typesport: null });
      enqueueSnackbar(`Вид спорта "${values?.typesport?.label}" успешно добавлен`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetch({
        variables: { filter: { id } },
      });
    }
  }, [id]);

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Grid container direction="row" spacing={1}>
        <Grid item md={4}>
          <TypeSportModule label="Вид спорта" error={!!errors['typesport']} name="typesport" control={control} />
        </Grid>
        <Grid item md={1}>
          <IconButton edge="end" aria-label="add" type="submit" style={{ display: 'inline', marginLeft: 16 }}>
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
        <Grid md={12} style={{ marginTop: 16 }}>
          {data?.dirAthlete?.sports?.map((item) => (
            <Card style={{ marginBottom: 16 }}>
              <CardHeader
                title={item?.dirSport?.label}
                disableTypography
                action={
                  <ArchiveComponent
                    id={item.id}
                    archiveStatus={item.archive}
                    canUnArchive
                    white="white"
                    unarchive={unarchive}
                    refetch={refetch}
                    archive={archive}
                  />
                }
              />
              <CardContent>
                <Typography variant="body1" component="h3">
                  <Disciplines
                    id={item?.id}
                    idSport={item?.dirSport?.value}
                    data={item?.disciplines}
                    refetch={refetch}
                  />
                </Typography>
                <Typography variant="h4" component="h3">
                  <Role id={item?.id} data={item?.role || ''} refetch={refetch} />
                </Typography>
                <Typography variant="h4" component="h3">
                  <RankAwards
                    id={item?.id}
                    idSport={item?.dirSport?.value}
                    data={item?.ranksAndAwards}
                    refetch={refetch}
                  />
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
    </form>
  );
};

export default TypeSports;
