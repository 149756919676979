import React, { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input } from '../Inputs';
import GetAppIcon from '@material-ui/icons/GetApp';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { Count } from '../Count';
import { TypeSportsFilterAdditional } from './index';
import { makeStyles } from '@material-ui/core/styles';
import { useTypeSportsCountLazyQuery } from '../../api';

const dictionary: any = {
  registryNumber: 'Идентификатор вида спорта',
  evsk: 'Номер-код ВРВС',
  fullName: 'Название',
  archive: 'В архиве',
  clsSportAndDisciplineGroups: 'Группы видов спорта и дисциплин',
  disciplines: 'Дисциплины',
  disciplineGroups: 'Группы дисциплин',
  ageGroups: 'Возрастные группы',
  clsRanksAndAwards: 'Разряды и звания',
};

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const TypeSports: FC<{
  onSubmit?: (T: any) => void;
  value?: any;
  showDownloadButton?: boolean;
  onDownloadFile: (T: any) => void;
}> = ({ onSubmit = () => {}, value, showDownloadButton, onDownloadFile }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, setValue, getValues } = useForm({ defaultValues: value });
  const [filterString, setFilterString] = useState('');
  const [additional, setAdditional] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });

  useEffect(() => {
    reset(value);
  }, [value]);

  const handleSearchBox = (value: any, event?: React.BaseSyntheticEvent) => {
    const filters: any = {};
    for (let item in value) {
      if (item === 'registryNumber' && value[item] && value[item] !== '') {
        filters[item] = Number(value[item]);
        setFilterString((prevProps: string) =>
          !prevProps.includes(dictionary[item])
            ? (prevProps += ` ${dictionary[item]} : ${Number(value[item])} ,`)
            : prevProps,
        );
      }
      if (
        item === 'clsRanksAndAwards' &&
        value?.clsRanksAndAwards?.value !== '' &&
        value?.clsRanksAndAwards?.value !== undefined
      ) {
        filters[item + '_some'] = {
          id: value?.clsRanksAndAwards?.value,
        };
        setFilterString((prevProps: string) =>
          !prevProps.includes(dictionary[item])
            ? (prevProps += ` ${dictionary[item]} : ${value?.clsRanksAndAwards?.label} ,`)
            : prevProps,
        );
      }
      if (item === 'ageGroups' && value?.ageGroups?.value !== '' && value?.ageGroups?.value !== undefined) {
        filters[item + '_some'] = {
          clsAgeGroup: {
            id: value?.ageGroups?.value,
          },
        };
        setFilterString((prevProps: string) =>
          !prevProps.includes(dictionary[item])
            ? (prevProps += ` ${dictionary[item]} : ${value?.ageGroups?.label} ,`)
            : prevProps,
        );
      }
      if (
        item === 'clsSportAndDisciplineGroups' &&
        value?.clsSportAndDisciplineGroups?.value !== '' &&
        value?.clsSportAndDisciplineGroups?.value !== undefined
      ) {
        filters[item + '_some'] = {
          id: value?.clsSportAndDisciplineGroups?.value,
        };
        setFilterString((prevProps: string) =>
          !prevProps.includes(dictionary[item])
            ? (prevProps += ` ${dictionary[item]} : ${value?.clsSportAndDisciplineGroups?.label} ,`)
            : prevProps,
        );
      }
      if ((item === 'disciplines' || item === 'disciplineGroups') && value[item] && value[item] !== '') {
        filters[item + '_some'] = {
          name_contains: value[item] && value[item].length > 1 ? value[item].slice(1) : value[item],
        };
        setFilterString((prevProps: string) =>
          !prevProps.includes(dictionary[item]) ? (prevProps += ` ${dictionary[item]} : ${value[item]} ,`) : prevProps,
        );
      }
      if (item === 'archive' && value[item] && value[item] !== '') {
        if (value[item].value === false) {
          filters[item] = null;
          setFilterString((prevProps: string) =>
            !prevProps.includes(dictionary[item]) ? (prevProps += ` ${dictionary[item]} : Нет ,`) : prevProps,
          );
        }
        if (value[item].value === true) {
          filters.NOT = {
            archive: null,
          };
          setFilterString((prevProps: string) =>
            !prevProps.includes(dictionary[item]) ? (prevProps += ` ${dictionary[item]} : Да ,`) : prevProps,
          );
        }
      }
      if (item === 'evsk' && value[item] && value[item] !== '') {
        filters[item + '_contains'] = value[item].toUpperCase();
        setFilterString((prevProps: string) =>
          !prevProps.includes(dictionary[item]) ? (prevProps += ` ${dictionary[item]} : ${value[item]} ,`) : prevProps,
        );
      }
      if (item === 'fullName' && value[item] && value[item] !== '') {
        filters[item + '_contains'] = value[item] && value[item].length > 1 ? value[item].slice(1) : value[item];
        setFilterString((prevProps: string) =>
          !prevProps.includes(dictionary[item]) ? (prevProps += ` ${dictionary[item]} : ${value[item]} ,`) : prevProps,
        );
      }
    }
    console.log('OnSubmit', filters);
    setFilter(filters);
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form
        onSubmit={handleSubmit(handleSearchBox)}
        onReset={(e?) => {
          console.log(e);
          setValue('registryNumber', '');
          reset();
          setFilter({
            archive: null,
          });
          onSubmit({
            archive: null,
          });
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item container spacing={2} md={10} xs={12}>
            <Grid item xs={3}>
              <Input
                label="Идентификатор вида спорта"
                control={control}
                error={!!errors['registryNumber']}
                name="registryNumber"
              />
            </Grid>
            <Grid item xs={3}>
              <Input label="Номер-код ВРВС" control={control} error={!!errors['evsk']} name="evsk" />
            </Grid>
            <Grid item xs={3}>
              <Input label="Название" control={control} error={!!errors['fullName']} name="fullName" />
            </Grid>
            {additional && <TypeSportsFilterAdditional control={control} errors={errors} />}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="reset"
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
              <Button
                onClick={() => setAdditional(!additional)}
                type="button"
                className={style.btn}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<TuneIcon />}
              >
                Фильтр
              </Button>
            </Grid>
          </Grid>

          <Grid item container md={2} xs={12} alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  onDownloadFile(filterString);
                }}
                disabled={!showDownloadButton}
              >
                <GetAppIcon style={{ marginRight: 10 }} />
                XLS
              </Button>
            </Grid>
            <Grid item>
              <Count query={useTypeSportsCountLazyQuery} filter={filter} />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default TypeSports;
