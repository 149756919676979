import {
  AgeGroupFrFragment,
  AthleteDocument,
  GroupTypesSportAndDisciplinesDocument,
  useAthleteLazyQuery,
  useUpdateAthleteSportMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import RemoveIcon from '@material-ui/icons/Delete';
import { TypeSports } from '../../../module';
import moment from 'moment';
import PersonsModule from '../../../module/Persons';

const PersonalTrainer: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const [fetch, { data, refetch }] = useAthleteLazyQuery();
  const { handleSubmit, register, control, errors, watch, reset, formState } = useForm({
    defaultValues: { typeSport: null, trainer: null } as {
      typeSport: null | { value: string; label: string; valSport: string };
      trainer: null | { value: string; label: string };
    },
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | AgeGroupFrFragment>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [remove]: any = useUpdateAthleteSportMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: AthleteDocument,
        data: {
          ...data?.updateDirAthleteSport,
        },
      });
    },
  });

  const [update]: any = useUpdateAthleteSportMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirAthleteSport,
        },
      });
    },
  });

  const onRemove = async (idSport: string, idTrainer: string) => {
    try {
      await remove({
        variables: {
          id: idSport,
          data: { dirTrainers: { disconnect: { id: idTrainer } } },
        },
      });
      enqueueSnackbar(`Тренер успешно удален`, { variant: 'success' });
      refetch?.();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    try {
      await update({
        variables: {
          id: values?.typeSport?.valSport,
          data: { dirTrainers: { connect: { id: values?.trainer?.value } } },
        },
      });
      setEdit(null);
      enqueueSnackbar(`Тренер успешно добавлен`, {
        variant: 'success',
      });
      reset({ typeSport: null, trainer: null });
      refetch?.();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  const watchTypeSport = watch('typeSport');

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetch({
        variables: { filter: { id } },
      });
    }
  }, [id]);

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({ typeSport: null });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={5}>
              <TypeSports
                label="Вид спорта"
                error={!!errors['typeSport']}
                name="typeSport"
                data={data?.dirAthlete?.sports?.map((item) => ({
                  value: item.dirSport?.value,
                  valSport: item.id,
                  label: item.dirSport?.label,
                }))}
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            {watchTypeSport && (
              <Grid item md={5}>
                <PersonsModule
                  label="Тренер"
                  error={!!errors['trainer']}
                  name="trainer"
                  control={control}
                  getterValue={(val: any) => {
                    return val?.trainerProfile?.id;
                  }}
                  filter={{
                    trainerProfile: {
                      sports_some: {
                        dirSport: { id: (watchTypeSport as any).value },
                      },
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={6}>
          <List>
            {data?.dirAthlete?.sports?.map((sport) => {
              return sport?.dirTrainers?.map((item) => {
                return (
                  <ListItem key={item.id} alignItems="flex-start">
                    <ListItemText
                      primary={`Вид спорта: ${sport.dirSport.label}`}
                      secondary={`Тренер: ${item.dirPerson.lastname} ${item.dirPerson.firstname} 
                            ${item.dirPerson?.patronymic || ''}
                            ${moment(item.dirPerson.birthday).format('DD.MM.YYYY')}`}
                    />
                    {!readonly && (
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="редактировать" onClick={() => onRemove(sport.id, item.id)}>
                          <RemoveIcon color="error" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              });
            })}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default PersonalTrainer;
