import {
  ClassifierValue,
  DirAthleteSportRankAndAward,
  UpdateAthleteSportMutationHookResult,
  useUpdateAthleteSportMutation,
} from '../../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import { Maybe } from '../../../../api';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { RanksAndTitle } from '../../../../module';
import moment from 'moment';
import { DatePicker, DatePickerRange } from '../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

interface FormProps {
  assignmentDate: null | string;
  confirmDate: null | string;
  validUntil: null | string;
  id: null | string;
  clsRankAndAward: null | { value: string; label: string };
}

const RankAwards: FC<{
  data?: Maybe<
    Array<
      { __typename?: 'DirAthleteSportRankAndAward' } & Pick<
        DirAthleteSportRankAndAward,
        'assignmentDate' | 'confirmDate' | 'validUntil' | 'id'
      > & {
          clsRankAndAward: { __typename?: 'ClassifierValue' } & {
            value: ClassifierValue['id'];
            label: ClassifierValue['fullName'];
          };
        }
    >
  >;
  id: string;
  idSport: string;
  refetch: any;
}> = ({ data, id, refetch, idSport }) => {
  const classes = useStyles();

  const { handleSubmit, control, watch, formState, register, errors, reset } = useForm({
    defaultValues: {
      assignmentDate: null,
      validUntil: null,
      clsRankAndAward: null,
      confirmDate: null,
      id: null,
    } as FormProps,
  });
  const [mode, setMode] = useState<'read' | 'write' | 'form'>('read');
  const [edit, setEdit] = useState<
    | null
    | ({ __typename?: 'DirAthleteSportRankAndAward' } & Pick<
        DirAthleteSportRankAndAward,
        'assignmentDate' | 'confirmDate' | 'validUntil' | 'id'
      > & {
          clsRankAndAward: { __typename?: 'ClassifierValue' } & {
            value: ClassifierValue['id'];
            label: ClassifierValue['fullName'];
          };
        })
  >(null);
  const { enqueueSnackbar } = useSnackbar();
  const [update]: any = useUpdateAthleteSportMutation();
  const watchStartDate = watch('assignmentDate');
  const watchEndDate = watch('validUntil');

  const onSubmit = handleSubmit(async (values) => {
    try {
      if (!values.id) {
        await update({
          variables: {
            data: {
              ranksAndAwards: {
                create: {
                  clsRankAndAward: {
                    connect: {
                      id: values?.clsRankAndAward?.value,
                    },
                  },
                  assignmentDate: values?.assignmentDate,
                  validUntil: values?.validUntil || null,
                },
              },
            },
            id,
          },
        });
      } else {
        await update({
          variables: {
            data: {
              ranksAndAwards: {
                update: {
                  where: {
                    id: edit?.id,
                  },
                  data: {
                    clsRankAndAward: {
                      connect: {
                        id: values?.clsRankAndAward?.value,
                      },
                    },
                    assignmentDate: values?.assignmentDate,
                    validUntil: values?.validUntil || null,
                    confirmDate: values?.confirmDate || null,
                  },
                },
              },
            },
            id,
          },
        });
      }
      // reset({ item: null });
      setEdit(null);
      enqueueSnackbar(`Разряд/звание "${null}" успешно добавлено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('write');
  });

  if (mode === 'read') {
    return (
      <div>
        Разряды и звания
        <IconButton
          edge="end"
          aria-label="add"
          size="small"
          onClick={() => setMode('write')}
          style={{ display: 'inline', marginLeft: 16 }}
        >
          <EditIcon color="primary" />
        </IconButton>
        <List>
          {data?.map((item) => {
            return (
              <ListItem key={item.id} alignItems="flex-start">
                <ListItemText
                  primary={item.clsRankAndAward.label}
                  secondary={`Дата присвоения ${moment(item.assignmentDate).format('DD.MM.YYYY')}${
                    item.confirmDate ? ` / Дата подтверждения ${moment(item.confirmDate).format('DD.MM.YYYY')}` : ''
                  }${item.validUntil ? ` / Действителен до ${moment(item.validUntil).format('DD.MM.YYYY')}` : ''}`}
                />
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title="Разряды и звания"
        disableTypography
        action={
          !(mode === 'form' || edit) && (
            <IconButton aria-label="закрыть" size="small" style={{ color: 'white' }} onClick={() => setMode('read')}>
              <CloseIcon />
            </IconButton>
          )
        }
      />
      <CardContent>
        <Grid container>
          {!(mode === 'form' || edit) && (
            <Grid item md={12}>
              <IconButton
                onClick={() => {
                  setMode('form');
                  reset({ assignmentDate: null, validUntil: null, clsRankAndAward: null });
                }}
                edge="end"
                aria-label="add"
                type="button"
              >
                <AddIcon color="primary" />
              </IconButton>
            </Grid>
          )}
          {(mode === 'form' || edit) && (
            <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
              <Grid item md={12} container spacing={2} direction="row">
                <input type="hidden" name="id" ref={register()} />
                <Grid container spacing={1}>
                  <Grid item md={4}>
                    <RanksAndTitle
                      label="Разряды и звания"
                      error={!!errors['clsRankAndAward']}
                      name="clsRankAndAward"
                      multiple={false}
                      control={control}
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <DatePickerRange
                      start={{
                        label: 'Дата вручения',
                        control,
                        error: !!errors['assignmentDate'],
                        errorMessage: errors?.assignmentDate?.message,
                        name: 'assignmentDate',
                        rules: {
                          validate: (val) => {
                            return !watchEndDate
                              ? undefined
                              : moment(val).isBefore(watchEndDate)
                              ? undefined
                              : 'Дата вручения не может быть позже даты действительности';
                          },
                        },
                      }}
                      end={{
                        label: 'Действителен до',
                        control,
                        error: !!errors['validUntil'],
                        name: 'validUntil',
                        errorMessage: errors?.validUntil?.message,
                        rules: {
                          validate: (val) => {
                            return !val
                              ? undefined
                              : moment(val).isAfter(watchStartDate)
                              ? undefined
                              : 'Дата действительности не может быть раньше даты вручения';
                          },
                        },
                      }}
                    />
                  </Grid>
                  {edit && (
                    <Grid md={4} item>
                      <DatePicker
                        label="Дата подтверждения"
                        control={control}
                        error={!!errors['confirmDate']}
                        name="confirmDate"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item md={12} container spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() => {
                        setMode('write');
                        setEdit(null);
                      }}
                      size="small"
                    >
                      Отмена
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<SaveIcon color="primary" />}
                      disabled={!formState.isDirty}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
          {mode === 'write' && !edit && (
            <Grid item md={6}>
              <List>
                {data?.map((item) => (
                  <ListItem key={item.id}>
                    <ListItemText
                      primary={item.clsRankAndAward.label}
                      secondary={`Дата присвоения ${moment(item.assignmentDate).format('DD.MM.YYYY')}${
                        item.confirmDate ? ` / Дата подтверждения ${moment(item.confirmDate).format('DD.MM.YYYY')}` : ''
                      }${item.validUntil ? ` / Действителен до ${moment(item.validUntil).format('DD.MM.YYYY')}` : ''}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          reset(item);
                          setEdit(item);
                        }}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RankAwards;
