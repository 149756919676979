import { usePersonByIdQuery } from '../../../api';
import React, { FC } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import _get from 'lodash/get';
import { useHistory } from 'react-router';
import PersonIcon from '@material-ui/icons/Person';
import LinkIcon from '@material-ui/icons/Link';

const Duplicates: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = usePersonByIdQuery({ variables: { id } });
  const { push } = useHistory();
  return (
    <Grid container>
      {Boolean(data?.dirPerson?.primary) && (
        <Grid item md={6}>
          <List
            subheader={
              <ListSubheader component="div" id="duplicates">
                Персона является дублем
              </ListSubheader>
            }
          >
            <ListItem key={data?.dirPerson?.primary?.registryNumber}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${data?.dirPerson?.primary?.lastname} ${data?.dirPerson?.primary?.firstname} ${_get(
                  data?.dirPerson?.primary,
                  'patronymic',
                  '',
                )}`}
                secondary={`${data?.dirPerson?.primary?.registryNumber}`}
                style={{ marginRight: '32px', flex: '0 1 auto' }}
              />
              <LinkIcon />
            </ListItem>
          </List>
        </Grid>
      )}
      {Boolean(data?.dirPerson?.duplicates?.length) && (
        <Grid item md={6}>
          <List
            subheader={
              <ListSubheader component="div" id="duplicates">
                Персоны дубликаты
              </ListSubheader>
            }
          >
            {data?.dirPerson?.duplicates?.map((item) => (
              <ListItem button onClick={() => push(`/person/${item.registryNumber}`)} key={item.registryNumber}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${item.lastname} ${item.firstname} ${item.patronymic ? item.patronymic : ''}`}
                  secondary={`${item.registryNumber}`}
                  style={{ marginRight: '32px', flex: '0 1 auto' }}
                />
                <LinkIcon />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default Duplicates;
