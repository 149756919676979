import {
  BaseRegionsByIdDocument,
  CreteBaseRegionMutationHookResult,
  DirRegion,
  DirSportBaseRegion,
  RemoveBaseRegionByIdMutationHookResult,
  UpdateBaseRegionByIdMutationHookResult,
  useBaseRegionsByIdLazyQuery,
  useCreteBaseRegionMutation,
  useRemoveBaseRegionByIdMutation,
  useUpdateBaseRegionByIdMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import { DatePickerRange } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import RemoveIcon from '@material-ui/icons/Delete';
import Regions from '../../../module/Regions';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';

type BaseRegions = { __typename?: 'DirSportBaseRegion' } & Pick<DirSportBaseRegion, 'id' | 'startDate' | 'endDate'> & {
    dirRegion?: { value: DirRegion['id']; label: DirRegion['shortName'] };
  };

const BaseRegionsWidget: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const [fetch, { data }] = useBaseRegionsByIdLazyQuery({ variables: { id } });
  const { watch, handleSubmit, register, control, errors, reset, formState } = useForm({
    defaultValues: { dirRegion: undefined, startDate: null, endDate: null, id: null } as BaseRegions,
  });
  const [regions, setRegions] = useState<BaseRegions[]>([]);
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | BaseRegions>(null);
  const { enqueueSnackbar } = useSnackbar();

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetch({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    setRegions(
      (data?.dirSport?.baseRegions && _orderBy(data?.dirSport?.baseRegions, ['dirRegion.label'], ['asc'])) || [],
    );
  }, [data]);

  const [create]: CreteBaseRegionMutationHookResult = useCreteBaseRegionMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: BaseRegionsByIdDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [remove]: RemoveBaseRegionByIdMutationHookResult = useRemoveBaseRegionByIdMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: BaseRegionsByIdDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [update]: UpdateBaseRegionByIdMutationHookResult = useUpdateBaseRegionByIdMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: BaseRegionsByIdDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const onRemove = async (value: string, label?: string) => {
    try {
      await remove({
        variables: {
          id,
          idBaseRegion: value,
        },
      });
      enqueueSnackbar(`Базовый регион "${label}" успешно удален`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values: BaseRegions) => {
    try {
      if (edit) {
        await update({
          variables: {
            id,
            idBaseRegion: values.id,
            idRegion: values.dirRegion?.value,
            startDate: values?.startDate,
            endDate: values?.endDate,
          },
        });
        setEdit(null);
        enqueueSnackbar(`Базовый регион "${values?.dirRegion?.label}" успешно обновлен`, {
          variant: 'success',
        });
      } else {
        await create({
          variables: {
            id,
            idRegion: values.dirRegion?.value,
            startDate: values?.startDate,
            endDate: values?.endDate,
          },
        });
        enqueueSnackbar(`Базовый регион "${values?.dirRegion?.label}" успешно добавлен`, {
          variant: 'success',
        });
      }
      reset({ startDate: undefined, endDate: undefined, dirRegion: undefined, id: null });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({ startDate: undefined, endDate: undefined, dirRegion: undefined, id: null });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={5}>
              <Regions control={control} label="Регион РФ" error={!!errors['dirRegion']} name="dirRegion" />
            </Grid>
            <Grid item md={5}>
              <DatePickerRange
                start={{
                  label: 'Период с',
                  control,
                  error: !!errors['startDate'],
                  errorMessage: errors?.startDate?.message,
                  name: 'startDate',
                  rules: {
                    validate: (val) => {
                      return !watchEndDate
                        ? undefined
                        : moment(val).isBefore(watchEndDate)
                        ? undefined
                        : 'Период не может начинаться раньше, чем конечная дата';
                    },
                  },
                }}
                end={{
                  label: 'по',
                  control,
                  error: !!errors['endDate'],
                  name: 'endDate',
                  errorMessage: errors?.endDate?.message,
                  rules: {
                    validate: (val) => {
                      return !val
                        ? undefined
                        : moment(val).isAfter(watchStartDate)
                        ? undefined
                        : 'Период не может заканчиваться раньше, чем начальная дата';
                    },
                  },
                }}
              />
            </Grid>
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={6}>
          <List>
            {regions.map((item) => (
              <ListItem key={item.id} alignItems="flex-start" disabled={moment(item.endDate).isBefore(moment())}>
                <ListItemText
                  primary={item.dirRegion?.label}
                  secondary={`${item.startDate ? `C ${moment(item.startDate).format('DD.MM.YY')}` : ''} ${
                    item.endDate ? `по ${moment(item.endDate).format('DD.MM.YY')}` : ``
                  }`}
                />
                {!readonly && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => {
                        reset(item);
                        setEdit(item);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => onRemove(item.id, item.dirRegion?.label)}
                    >
                      <RemoveIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default BaseRegionsWidget;
