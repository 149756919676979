import React, { FC } from 'react';
import MultiSelect from '../components/Inputs/MultiSelect';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useClsOrganizationCategoriesQuery } from '../api';

interface CategoryOrganizations extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  multiple?: boolean;
}

const CategoryOrganizations: FC<CategoryOrganizations> = ({ label, error, control, name, rules, multiple }) => {
  const { data } = useClsOrganizationCategoriesQuery();
  return (
    <MultiSelect
      multiple={multiple}
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default CategoryOrganizations;
