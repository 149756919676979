export const required = (value) => {
  return value ? undefined : 'Обязательное поле';
};

export const requiredBoolean = (value) => {
  return value === undefined || (value !== undefined && value.length === 0) ? 'Обязательное поле' : undefined;
};

export const phone = (value) => {
  return value && /^[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g ? undefined : 'Неверный телефон';
};

export const email = (value) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase()) ? undefined : 'Неверный формат Email';
};

export const emailNotRequired = (value) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(value).toLowerCase())) return undefined;
  else if (value && !re.test(String(value).toLowerCase())) return 'Неверный формат Email';
  return undefined;
};

export const website = (value) => {
  const re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(String(value).toLowerCase()) ? undefined : 'Неверный формат ссылка';
};

export const onlyAlphabet = (value) => {
  const regAlpha = /^[a-zа-я\s]*$/gi;
  return regAlpha.test(value) ? undefined : 'Разрешены только русские, латинские буквы и пробелы.';
};

export const onlyRussianbet = (value) => {
  const regAlpha = /^[ЁёА-я\s\-.]*$/gi;
  return regAlpha.test(value) ? undefined : 'Разрешены только русские буквы и - .';
};

export const onlyRussianStreet = (value) => {
  const regAlpha = /^[\d\ЁёА-я\s\-.]*$/gi;
  return regAlpha.test(value) ? undefined : 'Разрешены только русские буквы и цифры и - .';
};

export const onlyRussianHouse = (value) => {
  const regAlpha = /^[\d-\/]*$/gi;
  return regAlpha.test(value) ? undefined : 'Разрешены только цифры и - и /.';
};

export const onlyRussianHouseNumber = (value) => {
  const regAlpha = /^[\d\ЁёА-я]*$/gi;
  return regAlpha.test(value) ? undefined : 'Разрешены только русские буквы и цифры.';
};

export const onlyNumber = (value) => {
  const regAlpha = /^[\d]*$/gi;
  return regAlpha.test(value) ? undefined : 'Значение должно быть числовым';
};

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min) => (value) => (value && value.length < min ? 'некорректное значение' : undefined);

export const shouldBeGreater = (min) => (value) =>
  value && Number(value) < Number(min) ? `значение дольжно быть больше чем ${min}` : undefined;

export const minLength13 = minLength(13);
export const minLength12 = minLength(12);
export const minLength9 = minLength(9);

export const maxLength15 = maxLength(15);
export const number = (value) => (value && isNaN(Number(value)) ? 'Значение должно быть числовым' : undefined);
export const minValue = (min) => (value) => (value && value < min ? `Must be at least ${min}` : undefined);
export const minValue18 = minValue(18);

export const tooOld = (value) => (value && value > 65 ? 'You might be too old for this' : undefined);
export const aol = (value) =>
  value && /.+@aol\.com/.test(value) ? 'Really? You still use AOL for your email?' : undefined;
