import { AthleteUpdateAmpluaToSportMutationHookResult, useAthleteUpdateAmpluaToSportMutation } from '../../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '../../../Inputs';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Role: FC<{
  data?: string;
  id: string;
  refetch: any;
}> = ({ data, id, refetch }) => {
  const classes = useStyles();

  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { amplua: data || '' },
  });
  const [mode, setMode] = useState<'read' | 'write'>('read');
  const { enqueueSnackbar } = useSnackbar();
  const [update]: AthleteUpdateAmpluaToSportMutationHookResult = useAthleteUpdateAmpluaToSportMutation();

  const onSubmit = handleSubmit(async (values?) => {
    try {
      await update({
        variables: {
          idSport: id,
          amplua: values?.amplua,
        },
      });
      enqueueSnackbar(`Амплуа успешно обновлено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (mode === 'read') {
    return (
      <div>
        Амплуа
        <IconButton
          edge="end"
          aria-label="add"
          size="small"
          onClick={() => setMode('write')}
          style={{ display: 'inline', marginLeft: 16 }}
        >
          <EditIcon color="primary" />
        </IconButton>
        {!!data && (
          <List>
            <ListItem alignItems="flex-start">
              <ListItemText primary={data} />
            </ListItem>
          </List>
        )}
      </div>
    );
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title="Амплуа"
        disableTypography
        action={
          <IconButton aria-label="закрыть" size="small" style={{ color: 'white' }} onClick={() => setMode('read')}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container direction="column">
          <Grid item md={6}>
            <List>
              <form onSubmit={onSubmit}>
                <ListItem key="add_new">
                  <Input label="Амплуа" control={control} error={!!errors['amplua']} name="amplua" />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="add" type="submit">
                      <SaveIcon color="primary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </form>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Role;
