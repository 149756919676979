import {
  DirNationalTeamStatus,
  NationalTeamArchiveMutationHookResult,
  NationalTeamFragment,
  NationalTeamUnArchiveMutationHookResult,
  UpdateNationalTeamMutationHookResult,
  useNationalTeamArchiveMutation,
  useNationalTeamQuery,
  useNationalTeamUnArchiveMutation,
  useTeamStatusesQuery,
  useUpdateNationalTeamMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, Divider, Link, LinearProgress, FormControl, Select, MenuItem } from '@material-ui/core';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';
import moment from 'moment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
    },
    link: {
      cursor: 'pointer',
    },
    verticalSpace: {
      paddingBottom: 15,
    },
    verticalSpaceMarge: {
      marginBottom: 30,
    },
    historyBox: {
      marginBottom: 30,
      maxHeight: 150,
      overflowY: 'auto',
      scrollBehavior: 'smooth',
    },
    divider: {
      height: 1,
      width: '100%',
      marginBottom: '1rem',
    },
  }),
);

interface TeamStatusBoxInterface {
  currentStatus?: number;
  id?: string;
  statusUpdated: () => void;
  statusUpdateError: (e: any) => void;
}

const TeamStatusBox: FC<TeamStatusBoxInterface> = ({ currentStatus, id, statusUpdated, statusUpdateError }) => {
  const [update]: UpdateNationalTeamMutationHookResult = useUpdateNationalTeamMutation();
  const [defaultValue, setdefaultValue] = useState(currentStatus);
  const { data } = useTeamStatusesQuery();

  const handleChangeStatus = async (event: any) => {
    setdefaultValue(event.target.value);
    try {
      await update({
        variables: {
          id,
          data: {
            status: {
              connect: {
                id: event.target.value,
              },
            },
          },
        },
      });
      statusUpdated();
    } catch (e) {
      statusUpdateError(e);
    }
  };

  return (
    <FormControl style={{ minWidth: '25ch' }} size="small">
      <Select value={defaultValue} onChange={handleChangeStatus}>
        {data &&
          data.dirNationalTeamStatuses &&
          data.dirNationalTeamStatuses.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();
  const [disciplineGroupsString, setDisciplineGroupsString] = useState<string>('');
  const { loading, data, refetch } = useNationalTeamQuery({ skip: !id, variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: data?.dirNationalTeam as NationalTeamFragment,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [update]: UpdateNationalTeamMutationHookResult = useUpdateNationalTeamMutation();
  const [archive]: NationalTeamArchiveMutationHookResult = useNationalTeamArchiveMutation();
  const [unarchive]: NationalTeamUnArchiveMutationHookResult = useNationalTeamUnArchiveMutation();

  const onSubmit = async (values: NationalTeamFragment) => {
    try {
      await update({
        variables: {
          id,
          data: {
            comment: values.comment,
          },
        },
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (data?.dirNationalTeam) {
      reset(data?.dirNationalTeam);
      if (data?.dirNationalTeam?.dirSportingEvent?.sports) {
        let anonymousString: string = '';
        data?.dirNationalTeam?.dirSportingEvent?.sports?.forEach((sport) => {
          const disciplineGroupsJoined = sport.disciplines?.map((d) => d.name).join(' , ');
          anonymousString += disciplineGroupsJoined;
        });
        setDisciplineGroupsString(anonymousString);
      }
    }
  }, [data]);

  if (readonly) {
  }
  if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item container alignItems="center" spacing={2} className={classes.verticalSpaceMarge}>
                  <Grid item>
                    <span className={classes.title}>Статус сборной:&nbsp;</span>
                  </Grid>
                  <Grid item>
                    <TeamStatusBox
                      currentStatus={data?.dirNationalTeam?.status.id}
                      id={id}
                      statusUpdated={() => enqueueSnackbar(`статус сборной успешно обновлен`, { variant: 'success' })}
                      statusUpdateError={(e) => enqueueSnackbar(getMessage(e.message), { variant: 'error' })}
                    />
                  </Grid>
                </Grid>

                {data?.dirNationalTeam?.statusHistory && data?.dirNationalTeam?.statusHistory.length > 0 && (
                  <Grid item container className={classes.historyBox}>
                    <span className={classes.title}>История изменения статуса:&nbsp;</span>
                    {data?.dirNationalTeam?.statusHistory?.map((history) => (
                      <Grid key={history.id} item container justify="space-between">
                        <Grid item xs={3}>
                          {history.status && history.status.name}
                        </Grid>
                        <Grid item xs={3}>
                          {history.date && moment(history.date).format('DD.MM.YY HH:mm')}
                        </Grid>
                        <Grid item xs={3}>
                          {history.user && history.user.name}
                        </Grid>
                        <Grid item xs={3}>
                          <Link className={classes.link}> {history.user && history.user.email}</Link>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
              <Grid item container>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12} className={classes.verticalSpaceMarge}>
                <span className={classes.title}>Регион:&nbsp;</span>
                {data?.dirNationalTeam?.dirRegion?.fullName}
              </Grid>
              <Grid item container>
                <Grid item container>
                  <Grid item xs={3} className={classes.verticalSpace}>
                    <span className={classes.title}>Кол-во&nbsp;</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span className={classes.title}>Квота&nbsp;</span>
                  </Grid>
                  <Grid item>
                    <span className={classes.title}>Факт&nbsp;</span>
                  </Grid>
                </Grid>
                <Grid item container className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>спортменов:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>
                      {(data?.dirNationalTeam?.manTeamQuota || 0) + (data?.dirNationalTeam?.womanTeamQuota || 0)}
                    </span>
                  </Grid>
                  <Grid item>
                    <span>
                      {data?.dirNationalTeam?.athleteGroups?.reduce(
                        (acc: number, current: any) => acc + current.athletes.length,
                        0,
                      )}
                    </span>
                  </Grid>
                </Grid>
                <Grid item container className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>сопровождающих:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>{data?.dirNationalTeam?.supportTeamQuota}</span>
                  </Grid>
                  <Grid item>
                    <span>
                      {(data?.dirNationalTeam?.specialists?.length || 0) +
                        (data?.dirNationalTeam?.medics?.length || 0) +
                        (data?.dirNationalTeam?.trainers?.length || 0)}
                    </span>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <span className={classes.title}>Название мероприятия:&nbsp;</span>
                  {data?.dirNationalTeam?.dirSportingEvent?.name}
                  <span className={classes.title} style={{ paddingLeft: 15 }}>
                    {data?.dirNationalTeam?.dirSportingEvent?.registryNumber}
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <span className={classes.title}>Период проведения:&nbsp;</span>
                  {`${moment(data?.dirNationalTeam?.dirSportingEvent?.startDate).format('DD.MM.YY') || '~'} - ${
                    moment(data?.dirNationalTeam?.dirSportingEvent?.endDate).format('DD.MM.YY') || '~'
                  }`}
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <span className={classes.title}>Вид спорта и дисциплины:&nbsp;</span>
                  {data?.dirNationalTeam?.dirSportingEvent?.sports
                    ?.map((s) => s.dirSport && s.dirSport.fullName)
                    .join(' , ') || '~'}
                  &nbsp; | {disciplineGroupsString || '~'}
                </Grid>
                <Grid item xs={12} className={classes.verticalSpaceMarge}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <Input
                    label="Комментарии"
                    control={control}
                    error={!!errors['comment']}
                    name="comment"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={6} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirNationalTeam?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
