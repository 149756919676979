import React, { FC, useEffect, useState } from 'react';
import { useFindOrganizationsLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface Organizations extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'disabled'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
}

const Organizations: FC<Organizations> = ({ disabled, label, error, control, name, rules, filter }) => {
  const [fetch, { data, loading, refetch }] = useFindOrganizationsLazyQuery({ variables: { search: '', filter } });
  const [parsed, setParsed] = useState<any>([]);

  const refetchOrganizations = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { search: value, filter } });
      // console.log(
      //   data,
      //   data?.data.map((item) => item.organization),
      // );
    }
  };
  const useSearchOrganisations = () => useDebouncedSearch((text) => refetchOrganizations(text));

  const { setInputValue: setValue } = useSearchOrganisations();

  useEffect(() => {
    if (!loading) {
      const prepare = data?.data.map((item) => item.organization);
      setParsed(prepare || []);
    }
  }, [data, loading]);

  return (
    <Select
      label={label}
      data={parsed}
      control={control}
      disabled={disabled}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading}
    />
  );
};

export default Organizations;
