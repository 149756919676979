import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'rootApp',
  storage,
  blacklist: ['router'],
};

export default function configureStore(initialState = {}) {
  const persistedReducer = persistReducer(persistConfig, createReducer(history));
  const middlewares = [routerMiddleware(history), thunk];

  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);

  return { store, persistor };
}
