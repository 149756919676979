import {
  OrganizationCreateDeparmentAffiliationMutationHookResult,
  OrganizationDepartmentAffiliationDocument,
  OrganizationRemoveDeparmentAffiliationMutationHookResult,
  useOrganizationCreateDeparmentAffiliationMutation,
  useOrganizationDepartmentAffiliationQuery,
  useOrganizationRemoveDeparmentAffiliationMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC } from 'react';
import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import _get from 'lodash/get';
import DepartmentAffiliation from '../../../module/DepartmentAffiliation';

const OrganizationDepartmentAffiliation: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = useOrganizationDepartmentAffiliationQuery({ variables: { id } });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { item: null },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [
    create,
  ]: OrganizationCreateDeparmentAffiliationMutationHookResult = useOrganizationCreateDeparmentAffiliationMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: OrganizationDepartmentAffiliationDocument,
        data: {
          ...data?.updateDirOrganization,
        },
      });
    },
  });
  const [
    remove,
  ]: OrganizationRemoveDeparmentAffiliationMutationHookResult = useOrganizationRemoveDeparmentAffiliationMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: OrganizationDepartmentAffiliationDocument,
        data: {
          ...data?.updateDirOrganization,
        },
      });
    },
  });

  const onRemove = async (value: { value: any; label: string }) => {
    try {
      await remove({
        variables: {
          id,
          idItem: _get(value, 'value'),
        },
      });
      enqueueSnackbar(`Ведомственная принадлежность "${value.label}" успешно удален`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (value) => {
    try {
      await create({
        variables: {
          id,
          idItem: _get(value, 'item.value'),
        },
      });
      enqueueSnackbar(`Ведомственная принадлежность "${_get(value, 'item.label')}" успешно добавлен`, {
        variant: 'success',
      });
      reset({ item: null });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  return (
    <Grid container>
      <Grid item md={6}>
        <List>
          <form onSubmit={onSubmit}>
            <ListItem key="add_new">
              <DepartmentAffiliation
                label="Вид ведомственной принадлежности"
                error={!!errors['item']}
                name="item"
                control={control}
                rules={{
                  required: true,
                  validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                }}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="add" type="submit">
                  <AddIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </form>
          {data?.dirOrganization?.clsDepartmentalAffiliations?.map((item) => (
            <ListItem key={item.value}>
              <ListItemText primary={item.label} />
              {!readonly && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemove(item)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default OrganizationDepartmentAffiliation;
