import React, { FC, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { PersonFrFragment, useRemoveUserMutation, UsersDocument, UsersQuery, useUsersQuery } from '../../api';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';
import DeleteIcon from '@material-ui/icons/Delete';
import getMessage from '../../messages';
import { useSnackbar } from 'notistack';
import { UsersFilters } from '../../components/Filters';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<any>(0);
  const [filter, setFilter] = useState<any>({ role: { name_not: 'PERSON', AND: [] } });
  const { push } = useHistory();
  const access = usePermission('directory', 'users');
  const { data } = useUsersQuery({ variables: { skip: page * PER_PAGE, first: PER_PAGE, filter } });
  const { enqueueSnackbar } = useSnackbar();

  const [remove] = useRemoveUserMutation({
    update: (proxy, { data }) => {
      const dataProxy: UsersQuery | null = proxy.readQuery({ query: UsersDocument });
      proxy.writeQuery({
        query: UsersDocument,
        data: dataProxy?.users.filter((item) => item.id !== data?.deleteUser?.id),
      });
    },
  });

  const onRemove = async (id: string, title: string) => {
    try {
      await remove({ variables: { id } });
      enqueueSnackbar(`Пользователь ${title} успешно удален`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: Pick<PersonFrFragment, 'id'>, meta) => {
          return (
            <Tooltip title="Перейти в карточку" placement="top-start">
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={`/user/${meta.rowData[0]}`}
              >
                {value}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'regular',
      label: 'ФИО',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, meta) => {
          return `${value?.lastname || meta.rowData[1] || ''} ${value?.firstname || ''}`;
        },
      },
    },
    {
      name: 'role.label',
      label: 'Роль',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: string, meta) => {
          return (
            <Tooltip title="Удалить пользователя" placement="top-start">
              <IconButton
                style={{ padding: 0 }}
                edge="end"
                aria-label="delete"
                onClick={() => onRemove(value, meta.rowData[1])}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];

  return (
    <MainTemplate pageName="Администрирование пользователей" url="">
      <UsersFilters onSubmit={(filter: any) => setFilter(filter)} />
      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Администрирование пользователей</h2>
              <Grid container spacing={1}>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/user/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          data={(data && data.users) || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            serverSide: true,
            count: data?.count,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
