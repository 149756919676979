import React, { FC, useEffect, useState } from 'react';
import { useFindDocumentsByTitleLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface Document extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'disabled'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
}

const Documents: FC<Document> = ({ disabled, label, error, control, name, rules, filter }) => {
  const [fetch, { data, loading, refetch }] = useFindDocumentsByTitleLazyQuery({
    variables: { search: '', ...filter },
  });
  const [parsed, setParsed] = useState<any>([]);

  const refetchDocuments = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { search: value, ...filter } });
      // console.log(
      //   data,
      //   data?.data.map((item) => item.organization),
      // );
    }
  };
  const useSearchDocuments = () => useDebouncedSearch((text) => refetchDocuments(text));

  const { setInputValue: setValue } = useSearchDocuments();

  useEffect(() => {
    if (!loading) {
      const prepare = data?.dirDocuments;
      setParsed(prepare || []);
    }
  }, [data, loading]);

  return (
    <Select
      label={label}
      data={parsed}
      control={control}
      disabled={disabled}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading}
    />
  );
};

export default Documents;
