import React, { FC } from 'react';
import { DirSport, useTypeSportDisciplinesDrillQuery } from '../api';
import { Select } from '../components/Inputs';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface SelectCls extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'label' | 'error'> {
  filter?: any;
  multiple?: boolean;
  usedValue?: DirSport['id'][];
}

const TypeSportDisciplines: FC<SelectCls> = ({ multiple = true, filter = {}, label, error, control, name, rules }) => {
  const { data } = useTypeSportDisciplinesDrillQuery({ variables: { filter } });
  return (
    <Select
      multiple={multiple}
      label={label}
      data={(data && data.dirSportDisciplines) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default TypeSportDisciplines;
