import {
  AddPersonPhoneMutationHookResult,
  PersonByIdDocument,
  PhoneFragment,
  RemovePersonPhoneMutationHookResult,
  UpdatePersonPhoneMutationHookResult,
  useAddPersonPhoneMutation,
  usePersonByIdQuery,
  useRemovePersonPhoneMutation,
  useUpdatePersonPhoneMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import { Input } from '../../Inputs';
import TypeContact from '../../../module/TypeContact';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

const STATIC_FIELDS = [
  {
    size: 6,
    value: 'countryCode',
    text: 'Код страны',
  },
  {
    size: 6,
    value: 'operatorCode',
    text: 'Код оператора/ региона',
    render: (value: string) => (value ? ` (${value}) ` : ''),
  },
  {
    size: 6,
    value: 'number',
    text: 'Номер',
  },
  {
    size: 6,
    value: 'additionalNumber',
    render: (value: string) => (value ? `(доб. ${value})` : ''),
    text: 'Добавочный',
  },
];

const Phones: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const date = moment();
  const { data } = usePersonByIdQuery({ variables: { id } });
  const { handleSubmit, control, errors, reset, register } = useForm({
    defaultValues: {
      additionalNumber: '',
      clsContactType: {},
      countryCode: '7',
      number: '',
      operatorCode: '',
      id: '',
    } as PhoneFragment,
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | PhoneFragment>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [create]: AddPersonPhoneMutationHookResult = useAddPersonPhoneMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: PersonByIdDocument,
        data: {
          ...data?.updateDirPerson,
        },
      });
    },
  });

  const [update]: UpdatePersonPhoneMutationHookResult = useUpdatePersonPhoneMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: PersonByIdDocument,
        data: {
          ...data?.updateDirPerson,
        },
      });
    },
  });

  const [remove]: RemovePersonPhoneMutationHookResult = useRemovePersonPhoneMutation();

  const onRemove = async (id: string) => {
    try {
      await remove({
        variables: {
          id,
          date: new Date(),
        },
      });
      enqueueSnackbar(`Телефон  успешно удален`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values?: any) => {
    try {
      if (edit) {
        await update({
          variables: {
            idPerson: id,
            ...values,
            id: values.id,
            clsContactType: values?.clsContactType?.value,
          },
        });
        enqueueSnackbar(`Телефон успешно обновлен`, {
          variant: 'success',
        });
      } else {
        await create({
          variables: {
            idPerson: id,
            ...values,
            clsContactType: values?.clsContactType?.value,
          },
        });
        enqueueSnackbar(`Телефон успешно добавлен`, {
          variant: 'success',
        });
      }
      setEdit(null);
      setMode('list');
      reset({
        additionalNumber: '',
        clsContactType: undefined,
        countryCode: '',
        number: '',
        operatorCode: '',
        id: '',
      });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (readonly) {
  }
  return (
    <Grid container>
      <Grid item md={12}>
        <h3>Телефоны</h3>
      </Grid>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({
                additionalNumber: '',
                clsContactType: undefined,
                countryCode: '7',
                number: '',
                operatorCode: '',
                id: '',
              });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={1}>
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={6}>
              <Input
                label="Код страны"
                control={control}
                error={!!errors['countryCode']}
                errorMessage={errors['countryCode']?.message}
                name="countryCode"
                rules={{
                  required: true,
                  pattern: {
                    value: /^[0-9]$/,
                    message: 'Поле может состоять только из цифр',
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Код оператора/региона"
                control={control}
                error={!!errors['operatorCode']}
                errorMessage={errors['operatorCode']?.message}
                name="operatorCode"
                rules={{
                  pattern: {
                    value: /^[0-9]$/,
                    message: 'Поле может состоять только из цифр',
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Номер"
                control={control}
                error={!!errors['number']}
                errorMessage={errors['number']?.message}
                name="number"
                rules={{
                  required: true,
                  pattern: {
                    value: /^[0-9]$/,
                    message: 'Поле может состоять только из цифр',
                  },
                }}
              />
            </Grid>

            <Grid item md={6}>
              <Input
                label="Добавочный"
                control={control}
                errorMessage={errors['additionalNumber']?.message}
                error={!!errors['additionalNumber']}
                name="additionalNumber"
                rules={{
                  pattern: {
                    value: /^[0-9]$/,
                    message: 'Поле может состоять только из цифр',
                  },
                }}
              />
            </Grid>

            <Grid item md={6}>
              <TypeContact
                label="Тип контакта"
                error={!!errors['clsContactType']}
                name="clsContactType"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={12} container justify="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

      {mode === 'list' && !edit && (
        <Grid item md={12}>
          <List>
            {data?.dirPerson?.phones?.map((item: any) => {
              if (item.deletedAt && date.isSameOrAfter(item.deletedAt)) return null;
              return (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={STATIC_FIELDS.map((field) => {
                      return field.render ? field.render(item[field.value]) : item[field.value];
                    })}
                    secondary={item.clsContactType.label}
                  />
                  {!readonly && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="редактировать"
                        onClick={() => {
                          reset(item);
                          setEdit(item);
                        }}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => onRemove(item.id)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default Phones;
