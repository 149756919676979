import { usePermissionMenu } from '../../../../hooks/usePermission';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import menuPoint from '../__data__/menuPoint';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

const Menu: FC<{ url: any }> = ({ url }) => {
  const permission: any = usePermissionMenu();
  const { push } = useHistory();
  return (
    <List>
      {menuPoint.map((item) => {
        if (
          permission &&
          !permission[item.id] &&
          !(permission.directory && permission.directory.all) &&
          !(permission.directory && permission.directory[item.id])
        )
          return null;
        const Icon = item.icon;
        return (
          <ListItem button key={item.id} selected={item.link === '/' + url} onClick={() => push(item.link || '')}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default Menu;
