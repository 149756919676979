import {
  GroupTypesSportAndDisciplinesCreateMutationHookResult,
  GroupTypesSportAndDisciplinesDocument,
  GroupTypesSportAndDisciplinesRemoveMutationHookResult,
  useGroupTypesSportAndDisciplinesCreateMutation,
  useGroupTypesSportAndDisciplinesQuery,
  useGroupTypesSportAndDisciplinesRemoveMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC } from 'react';
import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { GroupsTypesSportAndDisciplines } from '../../../module';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';

const GroupTypesSportAndDisciplines: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = useGroupTypesSportAndDisciplinesQuery({ variables: { id } });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { discipline: null },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [
    create,
  ]: GroupTypesSportAndDisciplinesCreateMutationHookResult = useGroupTypesSportAndDisciplinesCreateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [
    remove,
  ]: GroupTypesSportAndDisciplinesRemoveMutationHookResult = useGroupTypesSportAndDisciplinesRemoveMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const onRemove = async (value: string, label: string) => {
    try {
      await remove({
        variables: {
          id,
          idItem: value,
        },
      });
      enqueueSnackbar(`Группа видов спорта и дисциплин "${label}" успешно удалена`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values?: { discipline?: { label: string; value: any } | null }) => {
    if (!values?.discipline) return;
    try {
      await create({
        variables: {
          id,
          idItem: values?.discipline?.value,
        },
      });
      reset({ discipline: null });
      enqueueSnackbar(`Группа видов спорта и дисциплин "${values?.discipline?.label}" успешно добавлена`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (readonly) {
  }
  return (
    <Grid container>
      <Grid item md={4}>
        <List>
          <form onSubmit={onSubmit}>
            <ListItem key="add_new">
              <GroupsTypesSportAndDisciplines
                label="Группа видов спорта и дисциплин"
                error={!!errors['discipline']}
                usedValue={data?.dirSport?.clsSportAndDisciplineGroups?.map((item) => item.value)}
                name="discipline"
                control={control}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="add" type="submit">
                  <AddIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </form>
          {data?.dirSport?.clsSportAndDisciplineGroups?.map((item) => (
            <ListItem key={item.value}>
              <ListItemText primary={item.label} />
              {!readonly && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemove(item.value, item.label)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default GroupTypesSportAndDisciplines;
