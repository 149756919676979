import React, { FC, useState } from 'react';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { RefereeFragment, useRefereesQuery } from '../../api';
import { Grid, Tooltip } from '@material-ui/core';
import TABLE_OPTIONS from '../../config/tables';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { RefereesFilter } from '../../components/Filters';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';
import moment from 'moment';

const PER_PAGE = 10;

const Page: FC<{}> = () => {
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<any>({
    archive: null,
  });
  const { push } = useHistory();
  const access = usePermission('directory', 'referees');
  const { data } = useRefereesQuery({ variables: { skip: page * PER_PAGE, first: PER_PAGE, filter } });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: '',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'dirPerson',
      label: 'Реестровый номер',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (person, meta) => {
          return (
            <Tooltip title="Перейти в карточку" placement="top-start">
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={`/referee/${meta.rowData[0]}`}
              >
                {person?.registryNumber}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'dirPerson',
      label: 'ФИО',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (person) => (
          <React.Fragment>
            {person?.lastname}&nbsp; {person?.firstname}&nbsp;
            {person?.patronymic}
          </React.Fragment>
        ),
      },
    },
    {
      name: 'dirPerson',
      label: 'ДР',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (person) => <React.Fragment>{moment(person?.birthday).format('DD.MM.YYYY')}</React.Fragment>,
      },
    },
    {
      name: 'dirPerson',
      label: 'Пол',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (person) => <React.Fragment>{(person?.isMale && 'Муж') || 'Жен'}</React.Fragment>,
      },
    },
    {
      name: 'sports',
      label: 'Виды спорта',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (sports) => (
          <React.Fragment>{sports?.map((sport: any) => sport.dirSport.fullName).join(' , ')}</React.Fragment>
        ),
      },
    },
    {
      name: 'dirPerson',
      label: 'Регион основной (страна/ зарубежный город)',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (person) => {
          const region =
            (person?.isRussia && person?.dirRegion.fullName) ||
            (!person?.isRussia && person?.dirForeignCity.fullName) ||
            (!person?.isRussia && person?.dirCountry.fullName);
          return person ? region : '';
        },
      },
    },
  ];

  return (
    <MainTemplate pageName="Судьи" url="">
      <RefereesFilter onSubmit={(filter: any) => setFilter(filter)} />
      <DictionaryWrapper>
        <MUIDataTable
          title={
            <React.Fragment>
              <h2>Справочник Судьи</h2>
              <Grid container spacing={1}>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/referee/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          data={(data && data.dirReferees) || []}
          columns={columns}
          options={{
            ...TABLE_OPTIONS,
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'changePage':
                  setPage(tableState.page);
                  break;
              }
            },
            rowsPerPage: PER_PAGE,
            count: data && data.count,
            page,
          }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
