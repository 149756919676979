import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import {
  Countries,
  GenderSelect,
  GroupDisciplines,
  RanksAndTitle,
  StagePreparing,
  TeamStatus,
  TypeSports,
} from '../../module';
import { FieldErrors } from 'react-hook-form/dist/types/form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { DatePickerRange, Input, Select } from '../Inputs';
import Organizations from '../../module/Organizations';
import Regions from '../../module/Regions';
import StatusConfirm from '../../module/StatusConfirm';
import PersonsModule from '../../module/Persons';
import ForeignCities from '../../module/ForeignCities';

interface MinsportsAdditionalProps extends Pick<ControllerProps<'input'>, 'control'> {
  errors: FieldErrors;
  watch: any;
}

const MinsportsAdditional: FC<MinsportsAdditionalProps> = ({ watch, errors, control }) => {
  const watchIsRussia: { label: string; value: string } | undefined = watch('isRussia');
  const watchCountry: { label: string; value: string } | undefined = watch('dirCountry');
  const watchDirSport: { label: string; value: string } | undefined = watch('dirSport');
  return (
    <>
      <Grid item xs={3}>
        <Select
          label="Россия/Зарубеж"
          data={[
            { value: '', label: ' ' },
            { value: true, label: 'Россия' },
            { value: false, label: 'Зарубеж' },
          ]}
          control={control}
          error={!!errors['activityName']}
          name="isRussia"
        />
      </Grid>
      {watchIsRussia?.value && (
        <Grid item xs={4}>
          <Regions label="Регион РФ" control={control} error={!!errors['dirRegion']} name="dirRegion" />
        </Grid>
      )}
      {watchIsRussia && !watchIsRussia?.value && (
        <>
          <Grid item xs={4}>
            <Countries label="Название страны" control={control} error={!!errors['dirCountry']} name="dirCountry" />
          </Grid>
          {watchCountry?.value && (
            <Grid item xs={4}>
              <ForeignCities
                label="Название города"
                control={control}
                filter={{ dirCountry: { id: watchCountry?.value } }}
                error={!!errors['dirForeignCity']}
                name="dirForeignCity"
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={3}>
        <TeamStatus label="Статус мероприятия" error={!!errors['dealStatus']} control={control} name="dealStatus" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Статус заявки" control={control} error={!!errors['activityName']} name="temp1" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Уровень мероприятия" control={control} error={!!errors['activityName']} name="temp1" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Год / месяц" control={control} error={!!errors['activityName']} name="temp2" />
      </Grid>
      <Grid item xs={3}>
        <Organizations label="Организатор" error={!!errors['dirRegion']} control={control} name="dirOrganization" />
      </Grid>
      <Grid item xs={3}>
        <Input type="checkbox" label="Особо значимое" control={control} error={!!errors['activityName']} name="temp3" />
      </Grid>
      <Grid item xs={3}>
        <Input
          type="checkbox"
          label="Подготовка к ОИ"
          control={control}
          error={!!errors['activityName']}
          name="temp4"
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickerRange
          start={{ label: 'Дата проведения с', control, error: !!errors['activityName'], name: 'startDate' }}
          end={{ label: 'по', control, error: !!errors['activityName'], name: 'endDate' }}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickerRange
          start={{
            label: 'Дата изменения с',
            control,
            error: !!errors['activityName'],
            name: 'startChangeDate',
          }}
          end={{ label: 'по', control, error: !!errors['activityName'], name: 'endChangeDate' }}
        />
      </Grid>
    </>
  );
};

export default MinsportsAdditional;
