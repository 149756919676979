import React, { FC, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import RanksAndTitle from '../../../module/RanksAndTitle';
import { useForm } from 'react-hook-form';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Input } from '../../Inputs';
import getMessage from '../../../messages';
import _ from 'lodash';
import {
  Scalars,
  useSportingEventQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  SportingEventDocument,
} from '../../../api';

const useStyles = makeStyles({
  alignCenter: {
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  minManAge?: Scalars['Int'] | null;
  maxManAge?: Scalars['Int'] | null;
  minManBirthYear?: Scalars['Int'] | null;
  maxManBirthYear?: Scalars['Int'] | null;
  minWomanAge?: Scalars['Int'] | null;
  maxWomanAge?: Scalars['Int'] | null;
  minWomanBirthYear?: Scalars['Int'] | null;
  maxWomanBirthYear?: Scalars['Int'] | null;
  clsMinRang?: { label: string; value: Scalars['UUID'] } | null;
}

const Rules: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const { loading, data } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      ...data?.dirSportingEvent,
    } as formState,
  });

  const onSubmit = async (values: formState) => {
    const {
      minManAge,
      maxManAge,
      minManBirthYear,
      maxManBirthYear,
      minWomanAge,
      maxWomanAge,
      minWomanBirthYear,
      maxWomanBirthYear,
      clsMinRang,
    } = values;
    try {
      await update({
        variables: {
          data: {
            minManAge: (minManAge && Number(minManAge)) || 0,
            maxManAge: (maxManAge && Number(maxManAge)) || 0,
            minManBirthYear: (minManBirthYear && Number(minManBirthYear)) || 0,
            maxManBirthYear: (maxManBirthYear && Number(maxManBirthYear)) || 0,
            minWomanAge: (minWomanAge && Number(minWomanAge)) || 0,
            maxWomanAge: (maxWomanAge && Number(maxWomanAge)) || 0,
            minWomanBirthYear: (minWomanBirthYear && Number(minWomanBirthYear)) || 0,
            maxWomanBirthYear: (maxWomanBirthYear && Number(maxWomanBirthYear)) || 0,
            clsMinRang: {
              connect: {
                id: clsMinRang?.value,
              },
            },
          },
          id,
        },
      });

      handleSnackBar('success', 'Требование успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  useEffect(() => {
    reset({ ...data?.dirSportingEvent });
  }, [data, reset]);

  if (loading) return <LinearProgress />;

  return (
    <div style={{ paddingTop: '2rem' }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов муж. от"
              control={control}
              error={!!errors['minManAge']}
              name="minManAge"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов муж. до"
              control={control}
              error={!!errors['maxManAge']}
              name="maxManAge"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения муж. от"
              control={control}
              error={!!errors['minManBirthYear']}
              name="minManBirthYear"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения муж. до"
              control={control}
              error={!!errors['maxManBirthYear']}
              name="maxManBirthYear"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов жен. от"
              control={control}
              error={!!errors['minWomanAge']}
              name="minWomanAge"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов жен. до"
              control={control}
              error={!!errors['maxWomanAge']}
              name="maxWomanAge"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения жен. от"
              control={control}
              error={!!errors['minWomanBirthYear']}
              name="minWomanBirthYear"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения жен. до"
              control={control}
              error={!!errors['maxWomanBirthYear']}
              name="maxWomanBirthYear"
              rules={{ required: true }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <RanksAndTitle
              label="Спорт. квалификация не ниже"
              control={control}
              error={!!errors['clsMinRang']}
              name="clsMinRang"
              rules={{ required: true }}
            />
          </Grid>

          <Grid item container lg={6} md={6} xs={12} justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Rules;
