import React, { FC, useState, useEffect } from 'react';
import {
  Grid,
  Link,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import { Input } from '../../Inputs';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import Regions from '../../../module/Regions';
import TeamStatus from '../../../module/TeamStatus';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import {
  DirNationalTeamAthlete,
  useSportingEventQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  Scalars,
  SportingEventDocument,
} from '../../../api';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  dirRegion?: { label: string; value: Scalars['UUID'] };
  athletesCall?: string;
  manTeamQuota?: string;
  womanTeamQuota?: string;
  supportTeamQuota?: string;
  status?: { label: string; value: Scalars['UUID'] };
  score?: string;
  point?: string;
  email?: string;
}
interface secondFormState {
  membersQuota?: number | null;
}

const TeamInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();
  const defaultState: formState = {
    dirRegion: undefined,
    athletesCall: undefined,
    manTeamQuota: undefined,
    womanTeamQuota: undefined,
    supportTeamQuota: undefined,
    status: undefined,
    score: undefined,
    point: undefined,
    email: undefined,
  };
  const [teamId, setTeamId] = useState<Scalars['UUID']>('');
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND?.replace('/graphql', '');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const { loading, data, refetch } = useSportingEventQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  const [membersQuota, dirNationalTeams] = [
    data?.dirSportingEvent?.membersQuota,
    data?.dirSportingEvent?.dirNationalTeams,
  ];

  const {
    handleSubmit: handleSecondSubmit,
    control: secondFormControl,
    reset: secondFormReset,
    errors: secondFormError,
    formState: secondFormState,
  } = useForm({
    defaultValues: {
      membersQuota,
    } as secondFormState,
  });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  useEffect(() => {
    secondFormReset({ membersQuota });
  }, [membersQuota, secondFormReset]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const onUpdateMembersQuota = async (values: secondFormState) => {
    const { membersQuota } = values;
    const factValue =
      (dirNationalTeams?.reduce(
        (acc: number, current: any) =>
          acc + current.athleteGroups &&
          current.athleteGroups?.reduce(
            (groupAcc: number, groupCurrent: any) =>
              groupAcc + groupCurrent?.athletes && groupCurrent?.athletes?.length,
            0,
          ),
        0,
      ) || 0) +
      (dirNationalTeams?.reduce((acc: number, current: any) => acc + current.specialists?.length, 0) || 0) +
      (dirNationalTeams?.reduce((acc: number, current: any) => acc + current.trainers?.length, 0) || 0) +
      (dirNationalTeams?.reduce((acc: number, current: any) => acc + current.medics?.length, 0) || 0);
    try {
      if (membersQuota && Number(membersQuota) < factValue) {
        handleSnackBar('error', 'Общее количество участников  должен быть больше чем Фактическое количество');
      }
      await updateEvent({
        variables: {
          data: {
            membersQuota: Number(membersQuota) || 0,
          },
          id,
        },
      });
      refetch();
      handleSnackBar('success', 'Общее количество участников успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onSubmit = async (values: formState) => {
    const {
      athletesCall,
      point,
      score,
      manTeamQuota,
      womanTeamQuota,
      supportTeamQuota,
      status,
      dirRegion,
      email,
    } = values;

    try {
      if (currentRequest === 'update') {
        await updateEvent({
          variables: {
            data: {
              dirNationalTeams: {
                update: [
                  {
                    where: {
                      id: teamId,
                    },
                    data: {
                      email,
                      athletesCall: athletesCall || null,
                      point: (point && parseInt(point, 10)) || null,
                      score: (score && parseInt(score, 10)) || null,
                      manTeamQuota: (manTeamQuota && parseInt(manTeamQuota, 10)) || 0,
                      womanTeamQuota: (womanTeamQuota && parseInt(womanTeamQuota, 10)) || 0,
                      supportTeamQuota: (supportTeamQuota && parseInt(supportTeamQuota, 10)) || 0,
                      status: {
                        connect: {
                          id: status?.value,
                        },
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        setOpenDialog(false);
        handleSnackBar('success', 'Информация успешно обновлена');
        reset(defaultState);
        setTeamId('');
        setCurrentRequest('');
        refetch();
        return;
      }
      await updateEvent({
        variables: {
          data: {
            dirNationalTeams: {
              create: [
                {
                  athletesCall: athletesCall || null,
                  email,
                  manTeamQuota: (manTeamQuota && parseInt(manTeamQuota, 10)) || 0,
                  womanTeamQuota: (womanTeamQuota && parseInt(womanTeamQuota, 10)) || 0,
                  supportTeamQuota: (supportTeamQuota && parseInt(supportTeamQuota, 10)) || 0,
                  status: {
                    connect: {
                      id: status?.value,
                    },
                  },
                  dirRegion: {
                    connect: {
                      id: dirRegion?.value,
                    },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      setOpenDialog(false);
      handleSnackBar('success', 'Сборная успешно создана');
      reset(defaultState);
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress variant="query" color="primary" />;

  return (
    <div>
      <Grid style={{ marginBottom: '1rem' }} container justify="space-between" alignItems="center" spacing={2}>
        <Grid item container lg={8} md={6} style={{ paddingLeft: '0.6rem' }}>
          <form onSubmit={handleSecondSubmit(onUpdateMembersQuota)} style={{ width: '100%' }}>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Input
                  label="Общее количество участников"
                  control={secondFormControl}
                  error={!!secondFormError['membersQuota']}
                  name="membersQuota"
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!secondFormState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid item container>
            <span className={classes.title}>Фактическое количество:</span>&nbsp;
            {dirNationalTeams?.reduce(
              (acc: number, current: any) =>
                acc + current.athleteGroups &&
                current.athleteGroups.reduce(
                  (groupAcc: number, groupCurrent: any) =>
                    groupAcc + groupCurrent.athletes && groupCurrent.athletes.length,
                  0,
                ),
              0,
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-quota/${id}`}>
            <Button size="small" variant="contained" color="primary">
              Выгрузить список участников
            </Button>
          </Link>
        </Grid>
      </Grid>

      <div>
        <Tooltip title="добавить" placement="top-start">
          <IconButton
            color="primary"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </div>

      {dirNationalTeams && dirNationalTeams?.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="team table">
            <TableHead>
              <TableRow>
                <TableCell>Регион</TableCell>
                <TableCell>Вызов спортсменов</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Квота муж.</TableCell>
                <TableCell>Квота жен.&nbsp;</TableCell>
                <TableCell>Квота на сопровожд&nbsp;</TableCell>
                <TableCell>Квота итого&nbsp;</TableCell>
                <TableCell>Факт муж&nbsp;</TableCell>
                <TableCell>Факт жен&nbsp;</TableCell>
                <TableCell>Факт сопровожд&nbsp;</TableCell>
                <TableCell>Статус сборной&nbsp;</TableCell>
                <TableCell>Место&nbsp;</TableCell>
                <TableCell>Кол-во очков&nbsp;</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dirNationalTeams.map((team: any) => (
                <TableRow key={team.id}>
                  <TableCell component="th" scope="row">
                    {team.dirRegion.label || '~'}
                  </TableCell>
                  <TableCell>
                    <span> {team.athletesCall || '~'}</span>
                  </TableCell>
                  <TableCell>{team.email || '~'}</TableCell>
                  <TableCell>{team.manTeamQuota || 0}</TableCell>
                  <TableCell>{team.womanTeamQuota || 0}</TableCell>
                  <TableCell>{team.supportTeamQuota || 0}</TableCell>
                  <TableCell>{team.manTeamQuota + team.womanTeamQuota + team.supportTeamQuota || '~'}</TableCell>
                  <TableCell>
                    {team.athleteGroups &&
                      team.athleteGroups.reduce(
                        (acc: number, current: any) =>
                          acc + current.athletes &&
                          current.athletes.filter(
                            (athlete: DirNationalTeamAthlete) =>
                              athlete.dirAthlete && athlete.dirAthlete.dirPerson && athlete.dirAthlete.dirPerson.isMale,
                          ).length,
                        0,
                      )}
                  </TableCell>
                  <TableCell>
                    {team.athleteGroups &&
                      team.athleteGroups.reduce(
                        (acc: number, current: any) =>
                          acc + current.athletes &&
                          current.athletes.filter(
                            (athlete: DirNationalTeamAthlete) =>
                              athlete.dirAthlete &&
                              athlete.dirAthlete.dirPerson &&
                              athlete.dirAthlete.dirPerson.isMale === false,
                          ).length,
                        0,
                      )}
                  </TableCell>
                  <TableCell>{team.specialists && team.specialists.length}</TableCell>
                  <TableCell>{team.status.label || '~'}</TableCell>
                  <TableCell>{team.score || 0}</TableCell>
                  <TableCell>{team.point || 0}</TableCell>
                  <TableCell>
                    <div className={classes.row}>
                      <Tooltip title="изменить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="primary"
                          onClick={() => {
                            setTeamId(team.id);
                            reset({ ...team });
                            setCurrentRequest('update');
                            setOpenDialog(true);
                          }}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          reset(defaultState);
          setTeamId('');
          setCurrentRequest('');
        }}
        aria-labelledby="add-calendar-dialog"
        open={openDialog}
      >
        <DialogTitle id="add-calendar-dialog">
          {(currentRequest === 'update' && 'Изменить') || 'Новая'} сборная
        </DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              {currentRequest !== 'update' && (
                <Grid item xs={12}>
                  <Regions
                    label="Регион"
                    control={control}
                    error={!!errors['dirRegion']}
                    name="dirRegion"
                    rules={{ required: true }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Input
                  label="Вызов спортсменов"
                  control={control}
                  error={!!errors['athletesCall']}
                  name="athletesCall"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Email для уведомления"
                  control={control}
                  error={!!errors['email']}
                  name="email"
                  rules={{
                    required: true,
                    pattern: /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
                  }}
                />
                {errors.email && <span role="alert">{errors.email.message}</span>}
              </Grid>
              <Grid item container justify="space-between">
                <Grid item container xs={3}>
                  <Input
                    label="Квота муж."
                    control={control}
                    error={!!errors['manTeamQuota']}
                    name="manTeamQuota"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item container xs={3}>
                  <Input
                    label="Квота жен."
                    control={control}
                    error={!!errors['womanTeamQuota']}
                    name="womanTeamQuota"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item container xs={3}>
                  <Input
                    label="Квота на спрж."
                    control={control}
                    error={!!errors['supportTeamQuota']}
                    name="supportTeamQuota"
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TeamStatus
                  label="Статус сборной"
                  control={control}
                  error={!!errors['status']}
                  name="status"
                  rules={{ required: true }}
                />
              </Grid>
              {currentRequest === 'update' && (
                <Grid item container justify="space-between">
                  <Grid item xs={4}>
                    <Input label="Место" control={control} error={!!errors['score']} name="score" />
                  </Grid>
                  <Grid item xs={4}>
                    <Input label="Кол-во очков" control={control} error={!!errors['point']} name="point" />
                  </Grid>
                </Grid>
              )}

              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  {(currentRequest === 'update' && 'Сохранить') || 'Добавить'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default TeamInfo;
