import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useClsSportingEventsCategoriesQuery } from '../api';

interface CategorySportingEvents extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const CategorySportingEvents: FC<CategorySportingEvents> = ({ label, error, control, name, rules }) => {
  const { data } = useClsSportingEventsCategoriesQuery();
  return (
    <Select
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default CategorySportingEvents;
