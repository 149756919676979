import React, { FC } from 'react';
import { Grid, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SportingEventsStatus from '../../../../module/SportingEventsStatus';
import { useForm } from 'react-hook-form';
import { Scalars, UpdateSportingEventMutationHookResult, useUpdateSportingEventMutation } from '../../../../api';

interface formInterface {
  status: {
    value: Scalars['UUID'];
    label: string;
  };
}

interface EventInfo {
  classes?: any;
  statusUpdated: Function;
  onError: Function;
  dirSportingEvent?: any;
  readOnly?: boolean;
}

const Status: FC<EventInfo> = ({ classes, dirSportingEvent, statusUpdated, onError, readOnly }) => {
  const { id } = dirSportingEvent;

  const [upDateSportingEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      status: dirSportingEvent?.status,
    } as formInterface,
  });

  const onSubmit = async (values: formInterface) => {
    try {
      await upDateSportingEvent({
        variables: {
          data: {
            status: {
              connect: {
                id: values.status.value,
              },
            },
          },
          id,
        },
      });
      statusUpdated(values.status.value);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container alignItems="flex-end" className={classes.verticalSpace} spacing={2}>
        <Grid item lg={2} md={3} xs={6}>
          <SportingEventsStatus
            label="Статус мероприятия"
            control={control}
            error={!!errors['status']}
            name="status"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            type="submit"
            disabled={!formState.isDirty}
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Status;
