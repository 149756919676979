import React, { FC, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DatePickerRange, Input, Select } from '../Inputs';
import { TypeSports, TeamStatus, GroupDisciplines, Countries } from '../../module';
import Regions from '../../module/Regions';
import Calendars from '../../module/Calendars';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Organizations from '../../module/Organizations';
import ForeignCities from '../../module/ForeignCities';
import { MinsportsFilterAdditional } from './index';

interface SportingEvent {
  registryNumber?: number;
  name_contains?: string;
  sports_some?: {
    dirSport?: {
      id: string;
    };
  };
}

interface Filter {
  registryNumber?: number;
  dirSportingEvent: SportingEvent;
  status: {
    id?: string;
  };
  dirRegion?: {
    id?: string;
  };
  dirCalendar?: {
    id?: string;
  };
}
interface Value {
  registryNumber: number;
  dirRegion: {
    label: string;
    value: string;
  };
  dirCalendar: {
    label: string;
    value: string;
  };
  sportType: {
    label: string;
    value: string;
  };
  activityName: string;
  activityRegistryNumber: number;
  dealStatus: {
    label: string;
    value: string;
  };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Minsports: FC<{ onSubmit?: (T: any) => void }> = ({ onSubmit = () => {} }) => {
  const style = useStyle();

  const [additional, setAdditional] = useState<boolean>(false);

  const { handleSubmit, control, errors, reset, watch, formState, setValue, getValues } = useForm({
    defaultValues: {
      registryNumber: undefined,
      dirRegion: undefined,
      dirCalendar: undefined,
      sportType: undefined,
      activityName: undefined,
      activityRegistryNumber: undefined,
      dealStatus: undefined,
    },
  });
  const watchIsRussia: { label: string; value: string } | undefined = watch('isRussia');
  const handleSearchBox = (value: Value) => {
    const filters: Filter = {
      dirSportingEvent: {},
      status: {},
    };

    if (value.registryNumber) {
      filters.registryNumber = Number(value.registryNumber);
    }
    if (value.dirCalendar) {
      filters.dirCalendar = {
        id: value.dirCalendar.value,
      };
    }
    if (value.dirRegion) {
      filters.dirRegion = {
        id: value.dirRegion.value,
      };
    }
    if (value.sportType) {
      filters.dirSportingEvent.sports_some = {
        ...filters.dirSportingEvent?.sports_some,
        dirSport: {
          id: value.sportType.value,
        },
      };
    }
    if (value.activityName) {
      filters.dirSportingEvent.name_contains = value.activityName;
    }
    if (value.activityRegistryNumber) {
      filters.dirSportingEvent.registryNumber = Number(value.activityRegistryNumber);
    }
    if (value.dealStatus) {
      filters.status = {
        id: value?.dealStatus.value,
      };
    }
    if (Object.keys(filters.dirSportingEvent).length === 0) delete filters.dirSportingEvent;
    if (Object.keys(filters.status).length === 0) delete filters.status;
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input label="Название" control={control} error={!!errors['activityName']} name="activityName" />
          </Grid>
          <Grid item xs={3}>
            <Calendars label="Календарь" error={!!errors['dirCalendar']} control={control} name="dirCalendar" />
          </Grid>

          <Grid item xs={3}>
            <TypeSports label="Вид спорта" error={!!errors['sportType']} control={control} name="sportType" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Статус вида спорта" control={control} error={!!errors['activityName']} name="sportStatus" />
          </Grid>
          {additional && <MinsportsFilterAdditional watch={watch} control={control} errors={errors} />}

          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit({});
                reset();
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              onClick={() => setAdditional(!additional)}
              type="button"
              className={style.btn}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<TuneIcon />}
            >
              Фильтр
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Minsports;
