import {
  OrganizationLegalInfoFrFragment,
  UpdateOrganizationLegalInfoMutationHookResult,
  useOrganizationLegalInfoQuery,
  useUpdateOrganizationLegalInfoMutation,
  useSuggestOrganizationsQuery,
} from '../../../api';
import Radios from '../../Inputs/Radio';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { DatePicker, Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import ContactInfo from './ContactInfo';
import moment from 'moment';

interface OrganizationLegalInfo
  extends Pick<
    OrganizationLegalInfoFrFragment,
    | 'id'
    | 'name'
    | 'postcode'
    | 'shortName'
    | 'fullName'
    | 'ogrn'
    | 'registrationDate'
    | 'ogrn'
    | 'kpp'
    | 'inn'
    | 'legalAddress'
    | 'headPosition'
    | 'headFullName'
  > {
  isLegalEntity: string;
}

const LegalInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const [checkOgrn, setCheckOgrn] = useState(true);
  const [ogrn, setOgrn] = useState('');

  const { data } = useOrganizationLegalInfoQuery({ variables: { id } });
  const { refetch: refetchSuggestOrganization, data: suggestOrganizations = [] } = useSuggestOrganizationsQuery({
    skip: checkOgrn,
    variables: {
      ogrn,
    },
    onCompleted: (data) => {
      if (data && data.suggestOrganizations && data.suggestOrganizations[0]) {
        setCheckOgrn(true);
      } else if (data) {
        enqueueSnackbar(`Не удалось найти данные по ОГРН ${ogrn}`, { variant: 'warning' });
      }
    },
  });
  const { handleSubmit, control, watch, errors, reset, formState } = useForm({
    defaultValues: {
      ...data?.dirOrganization,
      registrationDate:
        (data?.dirOrganization?.registrationDate && moment.utc(data?.dirOrganization?.registrationDate)) || null,
      isLegalEntity: data?.dirOrganization?.isLegalEntity ? 'true' : 'false',
    } as OrganizationLegalInfo,
  });
  const { enqueueSnackbar } = useSnackbar();
  let watchOgrn = watch('isLegalEntity');

  const [update]: UpdateOrganizationLegalInfoMutationHookResult = useUpdateOrganizationLegalInfoMutation();

  const onSubmit = async (values: OrganizationLegalInfo) => {
    try {
      const { data } = await update({
        variables: {
          id,
          postcode: values.postcode,
          shortName: values.shortName,
          ogrn: values.ogrn,
          registrationDate: values.registrationDate,
          kpp: values.kpp,
          inn: values.inn,
          fullName: values.fullName,
          legalAddress: values.legalAddress,
          headPosition: values.headPosition,
          headFullName: values.headFullName,
          isLegacy: values.isLegalEntity === 'true',
        },
      });
      if (data?.updateDirOrganization && data?.updateDirOrganization?.id) {
        enqueueSnackbar(`Организация "${data?.updateDirOrganization?.name}" успешно обновлена`, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirOrganization) {
      reset({
        ...data?.dirOrganization,
        registrationDate:
          (data?.dirOrganization?.registrationDate && moment.utc(data?.dirOrganization?.registrationDate)) || null,
        isLegalEntity: data?.dirOrganization?.isLegalEntity ? 'true' : 'false',
      });
      if (data?.dirOrganization?.isLegalEntity) setOgrn(data?.dirOrganization.ogrn || '');
    }
  }, [data]);

  if (readonly) {
  }
  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Radios
                    label="Является юр. лицом"
                    data={[
                      {
                        value: 'true',
                        label: 'Да',
                      },
                      {
                        value: 'false',
                        label: 'Нет',
                      },
                    ]}
                    control={control}
                    error={!!errors['isLegalEntity']}
                    name="isLegalEntity"
                    rules={{ required: true }}
                  />
                </Grid>
                {watchOgrn === 'true' && (
                  <Grid item md={6}>
                    <Input
                      label="ОГРН"
                      control={control}
                      error={!!errors['ogrn']}
                      name="ogrn"
                      rules={{ required: true, minLength: 13, pattern: /^(\d{13})?$/ }}
                    />
                  </Grid>
                )}
                <Grid item md={6}>
                  <Input label="Полное наименование" control={control} error={!!errors['fullName']} name="fullName" />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Краткое наименование"
                    control={control}
                    error={!!errors['shortName']}
                    name="shortName"
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    fullWidth
                    label="Дата регистрации"
                    control={control}
                    error={!!errors['registrationDate']}
                    name="registrationDate"
                  />
                </Grid>
                <Grid item md={6}>
                  <Input label="ИНН" control={control} error={!!errors['inn']} name="inn" />
                </Grid>
                <Grid item md={6}>
                  <Input label="КПП" control={control} error={!!errors['kpp']} name="kpp" />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Юридический адрес"
                    control={control}
                    error={!!errors['legalAddress']}
                    name="legalAddress"
                  />
                </Grid>
                <Grid item md={6}>
                  <Input label="Индекс" control={control} error={!!errors['postcode']} name="postcode" />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Должность руководителя"
                    control={control}
                    error={!!errors['headPosition']}
                    name="headPosition"
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="ФИО руководителя"
                    control={control}
                    error={!!errors['headFullName']}
                    name="headFullName"
                  />
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={12}>
                  {ogrn && ogrn !== '' && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setCheckOgrn(false);
                        refetchSuggestOrganization({ ogrn });
                      }}
                      startIcon={<SearchIcon />}
                      style={{ marginRight: '1rem' }}
                    >
                      Поиск реквизитов в ЕГРЮЛ
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
      </Grid>
      {id !== 'new' && <ContactInfo readonly={readonly} id={id} />}
    </>
  );
};

export default LegalInfo;
