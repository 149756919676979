import moment from 'moment';
import { CalendarFragment } from '../api';

type Calendar = {
  period: string;
  registryNumber: number;
  endDate: Date;
  id: string;
  startDate: Date;
  info: { registryNumber: number; id: string };
};

export const revealCalendar = (array: Array<{ __typename?: 'DirCalendar' } & CalendarFragment>) => {
  const calendarArray: Calendar[] =
    array &&
    array.map((calendar: { registryNumber: number; id: string; startDate: Date; endDate: Date }) => {
      const { registryNumber, id, startDate, endDate } = calendar;
      return {
        ...calendar,
        period: `${startDate && moment(startDate).format('DD.MM.YY')} - ${
          endDate && moment(endDate).format('DD.MM.YY')
        }`,
        info: {
          registryNumber,
          id,
        },
      };
    });
  return calendarArray || [];
};
