import React, { FC, useEffect, useState } from 'react';
import { useFindSimiliarPersonLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import _ from 'lodash';
import moment from 'moment';

interface PersonsModuleProps extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  getterValue?: (T: any) => void;
  // По фильтрам смотреть доку
  filter?: any;
  profile?: string;
}

const PersonsModule: FC<PersonsModuleProps> = ({
  getterValue,
  label,
  error,
  control,
  name,
  profile,
  rules,
  filter,
}) => {
  const [fetch, { data, loading }] = useFindSimiliarPersonLazyQuery({ variables: { search: '', filter } });
  const [parsed, setParsed] = useState<any>([]);

  const refetchData = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { search: value, filter } });
    }
  };
  const useSearch = () => useDebouncedSearch((text) => refetchData(text));

  const { setInputValue: setValue } = useSearch();

  useEffect(() => {
    if (!loading) {
      const prepare = data?.dirPersonsSimilar.map((item) => {
        return {
          value: getterValue?.(item.person) || item.person.id,
          label: `${item.person.lastname} ${item.person.firstname} ${
            item.person.patronymic ? item.person.patronymic : ''
          } ${moment(item.person.birthday).format('DD.MM.YYYY')}`,
          personRoleId: _.get(item, `person.${profile}.id`),
        };
      });
      setParsed(prepare || []);
    }
  }, [data, loading]);

  return (
    <Select
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading}
    />
  );
};

export default PersonsModule;
