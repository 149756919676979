import React, { FC, useEffect, useState } from 'react';
import { useFindCalendarsLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import useDebouncedSearch from '../hooks/useDebouncedSearch';

interface Organizations extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const Calendars: FC<Organizations> = ({ label, error, control, name, rules }) => {
  const [fetch, { data, loading, refetch }] = useFindCalendarsLazyQuery({ variables: { search: '' } });
  const [parsed, setParsed] = useState<any>([]);

  const refetchCalendars = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { search: value } });
    }
  };
  const useSearchCalendars = () => useDebouncedSearch((text) => refetchCalendars(text));

  const { setInputValue: setValue } = useSearchCalendars();

  useEffect(() => {
    if (!loading) {
      const prepare = data?.dirCalendars;
      setParsed(prepare || []);
    }
  }, [data, loading]);

  return (
    <Select
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading}
    />
  );
};

export default Calendars;
