import {
  ArchiveStatusFragment,
  DirNationalTeamAthlete,
  DirNationalTeamAthleteGroup,
  DirNationalTeamStatus,
  DirSportingEvent,
  DirSportingEventSport,
  NationalTeamFragment,
} from '../api';
import { DirRegion, DirSport, DirSportDisciplineGroup, Maybe, Scalars } from '../types/graphql';

type NationalTeam = {
  sportTypeEndDisciplinesGroups: string;
  registryNumber: Scalars['Int'];
  __typename?: 'DirNationalTeam';
  athleteGroups?: Maybe<
    Array<
      { __typename?: 'DirNationalTeamAthleteGroup' } & Pick<DirNationalTeamAthleteGroup, 'id'> & {
          athletes?: Maybe<Array<{ __typename?: 'DirNationalTeamAthlete' } & Pick<DirNationalTeamAthlete, 'id'>>>;
        }
    >
  >;
  archive?: Maybe<{ __typename?: 'Archive' } & ArchiveStatusFragment>;
  id: Scalars['UUID'];
  dirRegion: { __typename?: 'DirRegion' } & Pick<DirRegion, 'id' | 'fullName'>;
  info: { registryNumber: number; id: any };
  status: { __typename?: 'DirNationalTeamStatus' } & Pick<DirNationalTeamStatus, 'id' | 'name'>;
  dirSportingEvent: { __typename?: 'DirSportingEvent' } & Pick<
    DirSportingEvent,
    'id' | 'startDate' | 'endDate' | 'name' | 'registryNumber'
  > & {
      sports?: Maybe<
        Array<
          { __typename?: 'DirSportingEventSport' } & Pick<DirSportingEventSport, 'id'> & {
              dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id' | 'fullName'>;
              disciplineGroups?: Maybe<
                Array<{ __typename?: 'DirSportDisciplineGroup' } & Pick<DirSportDisciplineGroup, 'id' | 'name'>>
              >;
            }
        >
      >;
    };
};

export const revealTeams = (array: Array<{ __typename?: 'DirNationalTeam' } & NationalTeamFragment>) => {
  const unifiedArray: NationalTeam[] = array.map((team) => {
    const { id, registryNumber } = team;
    let sportString: string = '';
    let disciplineGroupsString: string = '';
    if (team.dirSportingEvent.sports && team.dirSportingEvent.sports.length > 0) {
      sportString = team.dirSportingEvent.sports.map((s) => s.dirSport && s.dirSport.fullName).join(' , ');
      team.dirSportingEvent.sports.forEach((sport) => {
        if (sport.disciplineGroups && sport.disciplineGroups.length > 0) {
          disciplineGroupsString += sport.disciplineGroups.map((d) => d.name).join(' , ');
        }
      });
    }

    return {
      info: { id, registryNumber },
      sportTypeEndDisciplinesGroups: `${sportString} ${disciplineGroupsString && `${'/ ' + disciplineGroupsString}`}`,
      ...team,
    };
  });
  return unifiedArray || [];
};
