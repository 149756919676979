import {
  AthleteConnectDisciplineToSportMutationHookResult,
  AthleteDisconnectDisciplineToSportMutationHookResult,
  AthleteDocument,
  useAthleteConnectDisciplineToSportMutation,
  useAthleteDisconnectDisciplineToSportMutation,
} from '../../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import { DirSportDiscipline, Maybe } from '../../../../api';
import DisciplinesMultiselect from '../../../../module/DisciplinesMultiSelect';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import TypeSportDisciplines from '../../../../module/TypeSportDisciplines';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Disciplines: FC<{
  data?: Maybe<
    Array<
      { __typename?: 'DirSportDiscipline' } & {
        value: DirSportDiscipline['id'];
        label: DirSportDiscipline['name'];
      }
    >
  >;
  id: string;
  idSport: string;
  refetch: any;
}> = ({ data, id, refetch, idSport }) => {
  const classes = useStyles();

  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { item: null },
  });
  const [mode, setMode] = useState<'read' | 'write'>('read');
  const { enqueueSnackbar } = useSnackbar();
  const [connect]: AthleteConnectDisciplineToSportMutationHookResult = useAthleteConnectDisciplineToSportMutation({});

  const [
    disconnect,
  ]: AthleteDisconnectDisciplineToSportMutationHookResult = useAthleteDisconnectDisciplineToSportMutation({});

  const onRemove = async (value: string, label: string) => {
    try {
      await disconnect({
        variables: {
          idSport: id,
          idDiscipline: value,
        },
      });
      enqueueSnackbar(`Дисциплина "${label}" успешно удалена`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values?: { item?: { label: string; value: any } | null }) => {
    if (!values?.item) return;
    try {
      await connect({
        variables: {
          idSport: id,
          idDiscipline: values?.item?.value,
        },
      });
      reset({ item: null });
      enqueueSnackbar(`Дисциплина "${values?.item?.label}" успешно добавлена`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (mode === 'read') {
    return (
      <div>
        Дисциплины
        <IconButton
          edge="end"
          aria-label="add"
          size="small"
          onClick={() => setMode('write')}
          style={{ display: 'inline', marginLeft: 16 }}
        >
          <EditIcon color="primary" />
        </IconButton>
        {data && data?.length > 0 && (
          <List>
            {data?.map((value) => {
              return (
                <ListItem key={value.value} alignItems="flex-start">
                  <ListItemText primary={value.label} />
                </ListItem>
              );
            })}
          </List>
        )}
      </div>
    );
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title="Дисциплины"
        disableTypography
        action={
          <IconButton aria-label="закрыть" size="small" style={{ color: 'white' }} onClick={() => setMode('read')}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container direction="column">
          <Grid item md={6}>
            <List>
              <form onSubmit={onSubmit}>
                <ListItem key="add_new">
                  <TypeSportDisciplines
                    label="Дисциплина"
                    error={!!errors['item']}
                    name="item"
                    multiple={false}
                    filter={{ dirSport: { id: idSport }, archive: null, id_not_in: data?.map((val) => val.value) }}
                    control={control}
                    rules={{ required: true }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="add" type="submit">
                      <AddIcon color="primary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </form>
              {data?.map((item) => (
                <ListItem key={item.value}>
                  <ListItemText primary={item.label} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => onRemove(item.value, item.label)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Disciplines;
