import { ArchiveCreateInput, ArchiveStatusFragment, ClassifierValueFragment } from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { ArchiveIssues } from '../../../module';

interface FormType extends ArchiveCreateInput {
  reason: any;
}

const ArchiveWidget: FC<{
  archive: ArchiveStatusFragment | null;
  id: string;
  onArchive?: (T: { variables: { archive: { create: ArchiveCreateInput } } & { id: string } }) => void;
  onUnarchive?: (T: { variables: { id: string } }) => void;
  refetch: any;
  readonly?: boolean;
}> = ({ refetch, readonly, id, onArchive, onUnarchive, archive }) => {
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: { reason: null, comment: '', id } as FormType,
  });
  const { enqueueSnackbar } = useSnackbar();

  const onArchiveHandler = async (values: FormType) => {
    try {
      if (onArchive) {
        await onArchive({
          variables: { id, archive: { create: { ...values, reason: values.reason?.label } } },
        });
        await refetch();
        reset({ reason: null, comment: '', id });
        enqueueSnackbar(`Запись успешно помещена в архив`, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onUnarchiveHandler = async () => {
    try {
      if (onUnarchive) {
        await onUnarchive({ variables: { id } });
        await refetch();
        enqueueSnackbar(`Запись успешно убрана из архива`, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  return (
    <>
      <ContentWrapperWidget>
        <h3>Архив</h3>
        {!archive && (
          <form onSubmit={handleSubmit(onArchiveHandler)}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={6}>
                <ArchiveIssues
                  label="Причина"
                  error={!!errors['reason']}
                  name="reason"
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <Input label="Комментарий" error={!!errors['comment']} name="comment" control={control} />
              </Grid>
              <Grid item md={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        {archive && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              Причина: {archive.reason}
            </Grid>
            {archive.comment && archive.comment.length > 0 && (
              <Grid item xs={12}>
                Комментарий: {archive.comment}
              </Grid>
            )}
            {!readonly && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  size="small"
                  onClick={onUnarchiveHandler}
                  startIcon={<DeleteIcon color="error" />}
                >
                  Убрать из архива
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </ContentWrapperWidget>
    </>
  );
};

export default ArchiveWidget;
