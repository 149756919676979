import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import unifyTrainerAndAthlete from '../../../utils';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Button, Box, ListItem, List, ListItemText, Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  useClsStagePreparingQuery,
  useOrganizationsAthletesBySportQuery,
  useOrganizationsTrainersBySportQuery,
  useOrganizationTypeSportsQuery,
} from '../../../api';
import { Accordion, AccordionDetails, Typography } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';
import FireAthlete from '../../FireBox/FireAthlete';
import _ from 'lodash';

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OrganizationAthlete: FC<{ readonly?: boolean; id?: string }> = ({ id }) => {
  const [sportTypeId, setSportTypeId] = useState(null);

  const [expanded, setExpanded] = useState<string | undefined>(undefined);

  const { data: organizationSports } = useOrganizationTypeSportsQuery({
    variables: {
      id,
    },
  });

  const handleChangeAccordion = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '990px' }}>
      {organizationSports?.dirOrganization?.dirSports?.map((type) => (
        <Accordion expanded={expanded === type.value} onChange={handleChangeAccordion(type.value)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>{type.label}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <InfoTable sportId={type.value} id={id} />
          </AccordionDetails>
        </Accordion>
      )) || <></>}
    </div>
  );
};

const InfoTable: FC<{ id: any; sportId: any }> = ({ id, sportId }) => {
  const classes = useStyles();
  const [tableObject, setTableObject] = useState<any>({});
  const [total, setTotal] = useState({});
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [currentView, setCurrentView] = useState('list');
  const [dialogWidth, setDialogWidth] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | false>('sm');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (
    variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined,
    message: string,
  ) => {
    enqueueSnackbar(message, { variant });
  };

  const { loading, data: cls } = useClsStagePreparingQuery();

  const { refetch: refetchAthletes, data: athletes } = useOrganizationsAthletesBySportQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sportID: sportId,
      id,
    },
  });
  const { refetch: refetchTrainers, data: trainers } = useOrganizationsTrainersBySportQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sportID: sportId,
      id,
    },
  });

  const toggleOpenDialog = (info?: any) => {
    setModalInfo({ ...info });
    setOpen(!open);
  };

  const updateValues = async () => {
    const sportmens = await refetchAthletes();
    const trainers = await refetchTrainers();
    if (!sportmens.loading && !sportmens.errors && !trainers.errors && !trainers.loading) {
      const { data, totalData } = unifyTrainerAndAthlete(
        trainers?.data?.dirTrainers || [],
        cls?.classifierValues || [],
        id,
        athletes?.dirAthletes,
      );
      setTableObject(data);
      setTotal(totalData);
    }
  };

  const handleFiredSucess = (value: any) => {
    if (value) {
      handleSnackBar('success', 'Спортсмен успешно исключен!');
      toggleOpenDialog();
      updateValues();
    }
  };

  useEffect(() => {
    updateValues();
  }, [currentView]);

  useEffect(() => {
    if (trainers && cls) {
      const { data, totalData } = unifyTrainerAndAthlete(
        trainers.dirTrainers || [],
        cls?.classifierValues || [],
        id,
        athletes?.dirAthletes,
      );
      setTableObject(data);
      setTotal(totalData);
    }
  }, [athletes, trainers, cls, id]);

  return (
    <>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              color={(currentView === 'list' && 'primary') || 'default'}
              onClick={() => setCurrentView('list')}
            >
              Показать списком
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              color={(currentView === 'table' && 'primary') || 'default'}
              onClick={() => setCurrentView('table')}
            >
              Показать по этапам подготовки
            </Button>
          </Grid>
        </Grid>
      </Box>
      {currentView === 'list' ? (
        <List>
          {athletes &&
            athletes?.dirAthletes.length > 0 &&
            athletes?.dirAthletes.map((athlete) => (
              <ListItem key={athlete.id}>
                <ListItemText style={{ fontWeight: 500 }}>
                  <Link style={{ fontWeight: 500, cursor: 'pointer' }} target="_blank" href={`/athlete/${athlete.id}`}>
                    {`${athlete?.dirPerson.lastname} ${athlete?.dirPerson.firstname} ${
                      athlete?.dirPerson.patronymic || ''
                    }`}
                  </Link>
                </ListItemText>
              </ListItem>
            ))}
        </List>
      ) : (
        <>
          {loading && <span style={{ marginLeft: '1rem' }}>Загружается....</span>}
          {Object.keys(tableObject).length !== 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Тренеры</TableCell>
                    {cls?.classifierValues.map((stage) => (
                      <TableCell key={stage.value} align="center">
                        {stage.shortLabel}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(tableObject).map((key) => (
                    <TableRow key={key}>
                      <TableCell key={key} component="th" scope="row">
                        {(key === 'sportmenWithoutTrainer' && 'Самоподготовка') || (
                          <Link
                            style={{ fontWeight: 500, cursor: 'pointer' }}
                            target="_blank"
                            href={`/trainer/${tableObject[key]['Без группы']?.trainerId}`}
                          >
                            {key}
                          </Link>
                        )}
                      </TableCell>
                      {Object.keys(tableObject[key]).map((innerKey) =>
                        tableObject[key][innerKey].athletes ? (
                          <TableCell
                            style={{ cursor: 'pointer', fontWeight: 500 }}
                            key={innerKey}
                            align="center"
                            onClick={() => toggleOpenDialog({ ...tableObject[key][innerKey] })}
                          >
                            <Typography color="primary" component="h2" style={{ fontWeight: 500 }}>
                              {tableObject[key][innerKey].athletes}
                            </Typography>
                          </TableCell>
                        ) : (
                          <TableCell key={innerKey} align="center">
                            {tableObject[key][innerKey].athletes}
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>Итого: </TableCell>
                    {Object.values(total).map((value: any) =>
                      value.athletes ? (
                        <TableCell style={{ fontWeight: 500 }} key={value.id} align="center">
                          {value.athletes}
                        </TableCell>
                      ) : (
                        <TableCell key={value.id} align="center">
                          0
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Dialog
            fullWidth
            maxWidth={dialogWidth}
            onClose={toggleOpenDialog}
            aria-labelledby="dialog-title"
            open={open}
          >
            <DialogTitle id="dialog-title">Отчисление спортсменов</DialogTitle>
            <FireAthlete
              setDialogWidth={setDialogWidth}
              onFiredSuccessfuly={handleFiredSucess}
              modalInfo={modalInfo}
              sportID={sportId}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default OrganizationAthlete;
