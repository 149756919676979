import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ArchiveIssues } from '../../../module';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { ArchiveStatusFragment, useClsArchiveIssuesQuery } from '../../../api';

const Archive: FC<{
  id: string;
  archiveStatus: ArchiveStatusFragment | null | undefined;
  refetch: any;
  archive: any;
  unarchive?: any;
  canUnArchive?: boolean;
  white?: string;
}> = ({ archiveStatus, white, refetch, archive, id, canUnArchive = false, unarchive }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { data } = useClsArchiveIssuesQuery();
  const { handleSubmit, register, control, errors, reset, formState } = useForm({
    defaultValues: { reason: null, comment: '' },
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sentRequest = useCallback(
    async (values: { reason: any; comment: string }) => {
      try {
        await archive({
          variables: { id, archive: { create: { reason: values.reason.value, comment: values.comment || '' } } },
        });
        enqueueSnackbar(`Запись успешно помещена в архив`, { variant: 'success' });
        refetch();
        setOpen(false);
        reset({ reason: undefined, comment: '' });
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    },
    [id],
  );

  const onUnArchiveHandler = useCallback(async () => {
    if (!unarchive || !canUnArchive) return null;
    try {
      await unarchive({
        variables: { id },
      });
      enqueueSnackbar(`Запись успешно извлечена из архива`, { variant: 'success' });
      refetch();
      setOpen(false);
      reset({ reason: undefined, comment: '' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  }, [unarchive, canUnArchive, id]);

  const reason = useMemo(() => data?.classifierValues.filter((value) => value.value === archiveStatus?.reason), [
    data,
    archiveStatus,
  ]);

  return (
    <>
      {archiveStatus === null && (
        <IconButton edge="end" aria-label="Архивировать" onClick={handleClickOpen}>
          <ArchiveIcon color="primary" style={{ color: white || '' }} />
        </IconButton>
      )}
      {archiveStatus && canUnArchive && (
        <Tooltip
          title={
            <>
              {reason && <p>Причина: {reason?.[0]?.label}</p>}
              <p>Комментарий: {archiveStatus.comment ? archiveStatus.comment : '-'}</p>
            </>
          }
          placement="top-start"
        >
          <IconButton edge="end" aria-label="Разархивировать" onClick={onUnArchiveHandler}>
            <UnarchiveIcon color="secondary" style={{ color: white || '' }} />
          </IconButton>
        </Tooltip>
      )}
      {open && (
        <form onSubmit={handleSubmit(sentRequest)}>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Добавление в архив</DialogTitle>
            <DialogContent style={{ width: 400 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item container>
                  <ArchiveIssues
                    label="Причина"
                    error={!!errors['reason']}
                    name="reason"
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item container>
                  <Input label="Комментарий" error={!!errors['comment']} name="comment" control={control} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button size="small" onClick={handleClose}>
                Закрыть
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={handleSubmit(sentRequest)}
                color="primary"
                startIcon={<SaveIcon />}
              >
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </>
  );
};

export default Archive;
