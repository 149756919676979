import React, { FC, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input, DatePicker } from '../Inputs';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { Count } from '../Count';
import { makeStyles } from '@material-ui/core/styles';
import { useDocumentsCountLazyQuery } from '../../api';

interface Filter {
  title_contains?: string;
  number_contains?: string;
  date?: Date;
}
interface Value {
  title: string;
  number: string;
  date: Date;
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Documents: FC<{ onSubmit?: (T: any) => void }> = ({ onSubmit = () => {} }) => {
  const [filter, setFilter] = useState<any>({});

  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      title: undefined,
      number: undefined,
      date: undefined,
    },
  });

  const handleSearchBox = (value: Value) => {
    const filters: Filter = {};

    if (value.title !== '') {
      filters.title_contains = value.title;
    }
    if (value.number !== '') {
      filters.number_contains = value.number;
    }
    if (value.date) {
      filters.date = value.date;
    }

    setFilter(filters);
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item container spacing={2} md={11} xs={12}>
            <Grid item xs={3}>
              <Input label="Название документа" control={control} error={!!errors['title']} name="title" />
            </Grid>
            <Grid item xs={3}>
              <Input label="Номер" control={control} error={!!errors['number']} name="number" />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                label="Дата"
                control={control}
                error={!!errors['date']}
                name="date"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="button"
                onClick={() => {
                  onSubmit({});
                  setFilter({});
                  reset();
                }}
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
            </Grid>
          </Grid>
          <Grid item container justify="center" md={1} xs={12}>
            <Count query={useDocumentsCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Documents;
