import React, { FC, useState } from 'react';
import { Grid, IconButton, Tooltip, Link, Button, Dialog, DialogTitle, LinearProgress } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, Input } from '../../Inputs';
import DocumentSelect from '../../../module/Documents';
import { useForm } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import getMessage from '../../../messages';
import {
  Scalars,
  useSportingEventQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  SportingEventDocument,
  UploadFileMutationHookResult,
  useUploadFileMutation,
} from '../../../api';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
    padding: 10,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

interface formState {
  title?: string;
  date?: Date;
  number?: string;
}

interface lightFormState {
  dirDocument?: { label: string; value: Scalars['UUID'] };
}

const Documents: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND?.replace('/graphql', '');

  const defaultNewDocumentState: formState = {
    title: undefined,
    date: undefined,
    number: undefined,
  };

  const { loading, data, refetch } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultNewDocumentState,
  });

  const {
    handleSubmit: reglamentDocumentFormHandleSubmit,
    control: reglamentDocumentFormControl,
    errors: reglamentDocumentFormErrors,
    reset: reglamentDocumentFormReset,
    formState: reglamentDocumentFormState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });

  const {
    handleSubmit: protocolDocumentFormHandleSubmit,
    control: protocolDocumentFormControl,
    errors: protocolDocumentFormErrors,
    reset: protocolDocumentFormReset,
    formState: protocolDocumentFormState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });

  const [updateEventData]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const [competitionReglaments, competitionProtocols] = [
    data?.dirSportingEvent?.competitionReglaments,
    data?.dirSportingEvent?.competitionProtocols,
  ];

  const [isAddingNewReglamentDocument, setIsAddingNewReglamentDocument] = useState(false);
  const [isAddingNewProtocoltDocument, setIsAddingNewProtocolDocument] = useState(false);
  const [isAddingNEwDocumentFromSystem, setIsAddingNEwDocumentFromSystem] = useState(false);
  const [isReglamentOrProtocol, setIsReglamentOrProtocol] = useState('');
  const [file, setFile] = useState<Array<File>>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const addNewReglamentDocument = async (values: lightFormState) => {
    const { dirDocument } = values;
    try {
      if (isAddingNewReglamentDocument && dirDocument) {
        await updateEventData({
          variables: {
            data: {
              competitionReglaments: {
                connect: [
                  {
                    id: dirDocument?.value,
                  },
                ],
              },
            },
            id,
          },
        });
        handleSnackBar('success', 'документ успешно добавлено');
        reglamentDocumentFormReset();
        setIsAddingNewReglamentDocument(false);
        refetch();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };
  const addNewProtocolDocument = async (values: lightFormState) => {
    const { dirDocument } = values;
    try {
      if (isAddingNewProtocoltDocument && dirDocument) {
        await updateEventData({
          variables: {
            data: {
              competitionProtocols: {
                connect: [
                  {
                    id: dirDocument?.value,
                  },
                ],
              },
            },
            id,
          },
        });
        handleSnackBar('success', 'документ успешно добавлено');
        protocolDocumentFormReset();
        setIsAddingNewProtocolDocument(false);
        refetch();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const handleAddNewDocumentFromSystem = async (values: formState) => {
    const { title, date, number } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            UploadFile: { id: fileId },
          } = resp.data;
          await updateEventData({
            variables: {
              data: (isReglamentOrProtocol === 'protocol' && {
                competitionProtocols: {
                  create: [
                    {
                      title,
                      date,
                      number,
                      file: {
                        connect: { id: fileId },
                      },
                    },
                  ],
                },
              }) || {
                competitionReglaments: {
                  create: [
                    {
                      title,
                      date,
                      number,
                      file: {
                        connect: { id: fileId },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          handleSnackBar('success', 'документ успешно добавлено');
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
          refetch();
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteProtocolDocument = async (documentId: Scalars['UUID']) => {
    try {
      await updateEventData({
        variables: {
          data: {
            competitionProtocols: {
              disconnect: [
                {
                  id: documentId,
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'документ успешно удаленно');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };
  const deleteReglamentDocument = async (documentId: Scalars['UUID']) => {
    try {
      await updateEventData({
        variables: {
          data: {
            competitionReglaments: {
              disconnect: [
                {
                  id: documentId,
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'документ успешно удаленно');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const classes = useStyles();

  if (loading) return <LinearProgress color="primary" variant="query" />;

  return (
    <div>
      <Grid container className={classes.verticalSpaceMarge}>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item lg={4} md={6} sm={12}>
            <span className={classes.title}>Положение/регламент соревнования:&nbsp;</span>
          </Grid>
          {!readonly && (
            <Grid item container spacing={2} alignItems="center" lg={6} md={6} sm={12} xs={12}>
              {isAddingNewReglamentDocument ? (
                <React.Fragment>
                  <form onSubmit={reglamentDocumentFormHandleSubmit(addNewReglamentDocument)} style={{ width: '100%' }}>
                    <Grid item container spacing={2} alignItems="flex-end">
                      <Grid item md={5} xs={12}>
                        <DocumentSelect
                          filter={{
                            bannedId: competitionReglaments?.map((reglament) => reglament.id),
                          }}
                          label="Документ"
                          control={reglamentDocumentFormControl}
                          error={!!reglamentDocumentFormErrors['dirDocument']}
                          name="dirDocument"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{ marginRight: '0.5rem' }}
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={!reglamentDocumentFormState.isDirty}
                        >
                          Сохранить
                        </Button>

                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{ marginRight: '0.5rem' }}
                          size="small"
                          onClick={() => {
                            setIsAddingNewReglamentDocument(false);
                            reglamentDocumentFormReset();
                          }}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item>
                    <span style={{ marginRight: '0.5rem' }}>Добавить документ</span>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => setIsAddingNewReglamentDocument(true)}
                    >
                      Из базы
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => {
                        setIsReglamentOrProtocol('reglament');
                        setIsAddingNEwDocumentFromSystem(true);
                      }}
                      disabled={isAddingNewReglamentDocument}
                    >
                      Новый
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          )}
        </Grid>
        {competitionReglaments?.map((reglament) => (
          <Grid item container alignItems="center" spacing={2} key={reglament.id}>
            <Grid item>
              <Link href={`${DOWNLOAD_LINK}${reglament.file.path}`} className={classes.link}>
                {reglament.title}
              </Link>
            </Grid>
            <Grid item>
              <span>{reglament.date && moment(reglament.date).format('DD.MM.YYY')}</span>
            </Grid>
            <Grid item>{reglament.number}</Grid>
            {!readonly && (
              <Grid item>
                <Tooltip title="удалить" placement="top-start">
                  <IconButton
                    className={classes.btn}
                    color="secondary"
                    onClick={() => deleteReglamentDocument(reglament.id)}
                  >
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid container className={classes.verticalSpaceMarge}>
        <Grid item container alignItems="center">
          <Grid item lg={4} md={6} sm={12}>
            <span className={classes.title}>Протоколы соревнований:&nbsp;</span>
          </Grid>
          {!readonly && (
            <Grid item container spacing={2} alignItems="center" lg={6} md={6} sm={12} xs={12}>
              {isAddingNewProtocoltDocument ? (
                <React.Fragment>
                  <form onSubmit={protocolDocumentFormHandleSubmit(addNewProtocolDocument)} style={{ width: '100%' }}>
                    <Grid item container spacing={2} alignItems="flex-end">
                      <Grid item md={5} xs={12}>
                        <DocumentSelect
                          filter={{
                            bannedId: competitionProtocols?.map((protocol) => protocol.id),
                          }}
                          label="Документ"
                          control={protocolDocumentFormControl}
                          error={!!protocolDocumentFormErrors['dirDocument']}
                          name="dirDocument"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{ marginRight: '0.5rem' }}
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={!protocolDocumentFormState.isDirty}
                        >
                          Сохранить
                        </Button>

                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{ marginRight: '0.5rem' }}
                          size="small"
                          onClick={() => {
                            setIsAddingNewProtocolDocument(false);
                            protocolDocumentFormReset();
                          }}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item>
                    <span style={{ marginRight: '0.5rem' }}>Добавить документ</span>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => setIsAddingNewProtocolDocument(true)}
                    >
                      Из базы
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => {
                        setIsReglamentOrProtocol('protocol');
                        setIsAddingNEwDocumentFromSystem(true);
                      }}
                    >
                      Новый
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          )}
        </Grid>
        {competitionProtocols?.map((protocol) => (
          <Grid item container alignItems="center" spacing={2} key={protocol.id}>
            <Grid item>
              <Link href={`${DOWNLOAD_LINK}${protocol.file.path}`} className={classes.link}>
                {protocol.title}
              </Link>
            </Grid>
            <Grid item>
              <span>{protocol.date && moment(protocol.date).format('DD.MM.YYY')}</span>
            </Grid>
            <Grid item>{protocol.number}</Grid>
            {!readonly && (
              <Grid item>
                <Tooltip title="удалить" placement="top-start">
                  <IconButton
                    className={classes.btn}
                    color="secondary"
                    onClick={() => deleteProtocolDocument(protocol.id)}
                  >
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNEwDocumentFromSystem(false);
          setIsReglamentOrProtocol('');
          reset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={control}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата"
                  control={control}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="номера"
                  control={control}
                  error={!!errors['number']}
                  name="number"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default Documents;
