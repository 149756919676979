import { MUIDataTableOptions } from 'mui-datatables';

const TABLE_DEFAULT_SETTINGS: MUIDataTableOptions = {
  filter: false,
  serverSide: true,
  search: false,
  enableNestedDataAccess: '.', // allows nested data separated by "." (see column names and the data structure above)
  textLabels: {
    body: {
      noMatch: 'Извините, совпадающих записей не найдено',
    },
    pagination: {
      next: 'Следующая страница',
      previous: 'Предыдущая Страница',
      rowsPerPage: 'Количество элементов:',
      displayRows: 'от', // 1-10 of 30
    },
    toolbar: {
      search: 'Поиск',
      downloadCsv: 'Скачать CSV',
      print: 'Печать',
      viewColumns: 'Столбец представления',
      filterTable: 'Настольный Фильтр',
    },
    filter: {
      all: 'ВСЕ',
      title: 'ФИЛЬТРЫ',
      reset: 'сброс',
    },
    viewColumns: {
      title: 'Показать Столбцы',
    },
    selectedRows: {
      text: 'Выбрано строк',
      delete: 'Удалить',
    },
  },
  download: false,
  print: false,
  selectableRows: 'none',
  filterType: 'dropdown',
  responsive: 'standard',
  rowsPerPageOptions: [],
};

export default TABLE_DEFAULT_SETTINGS;
