import React, { FC, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Input } from '../Inputs';
import { TypeSports } from '../../module';
import CategoryOrganizations from '../../module/CategoryOrganizations';
import Organizations from '../../module/Organizations';
import Regions from '../../module/Regions';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { Count } from '../Count';
import { makeStyles } from '@material-ui/core/styles';
import { useOrganizationsCountLazyQuery } from '../../api';

interface Filter {
  registryNumber?: number;
  dirRegion?: {
    id?: string;
  };
  dirSports_some?: {
    id?: string;
  };
  clsOrganizationCategories_some?: {
    id?: string;
  };
  ogrn_contains?: string;
  name?: string;
}
interface Value {
  registryNumber: number;
  dirRegion: {
    label: string;
    value: string;
  };
  sportType: {
    label: string;
    value: string;
  };
  organizationCategory: {
    label: string;
    value: string;
  };
  ogrn: string;
  name: {
    label: string;
    value: string;
  };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const OrganizationsFilter: FC<{ onSubmit?: (T: any) => void }> = ({ onSubmit = () => {} }) => {
  const style = useStyle();

  const [additional, setAdditional] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({});

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      registryNumber: undefined,
      dirRegion: undefined,
      sportType: undefined,
      organizationCategory: undefined,
      ogrn: undefined,
      name: undefined,
    },
  });

  const handleSearchBox = (value: Value) => {
    const filters: Filter = {};

    if (value.registryNumber) {
      filters.registryNumber = Number(value.registryNumber);
    }
    if (value.dirRegion) {
      filters.dirRegion = {
        id: value.dirRegion.value,
      };
    }
    if (value.sportType) {
      filters.dirSports_some = {
        id: value.sportType.value,
      };
    }

    if (value.organizationCategory) {
      filters.clsOrganizationCategories_some = {
        id: value.organizationCategory.value,
      };
    }
    if (value.name) {
      filters.name = value.name.label;
    }
    if (value.ogrn) {
      filters.ogrn_contains = value.ogrn;
    }

    setFilter(filters);
    onSubmit(filters);
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item container spacing={2} md={11} xs={12}>
            <Grid item xs={3}>
              <Input
                label="Реестровый номер"
                control={control}
                error={!!errors['registryNumber']}
                name="registryNumber"
              />
            </Grid>
            <Grid item xs={3}>
              <Organizations label="Название" control={control} error={!!errors['name']} name="name" />
            </Grid>
            <Grid item xs={3}>
              <Input label="ОРГН" control={control} error={!!errors['ogrn']} name="ogrn" />
            </Grid>
            <Grid item xs={3}>
              <Regions label="Регион РФ" error={!!errors['dirRegion']} control={control} name="dirRegion" />
            </Grid>

            {additional && (
              <React.Fragment>
                <Grid item xs={3}>
                  <CategoryOrganizations
                    label="Категория организации"
                    error={!!errors['organizationCategory']}
                    control={control}
                    name="organizationCategory"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TypeSports label="Вид спорта" error={!!errors['sportType']} control={control} name="sportType" />
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="button"
                onClick={() => {
                  onSubmit({});
                  setFilter({});
                  reset();
                }}
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
              <Button
                onClick={() => setAdditional(!additional)}
                type="button"
                className={style.btn}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<TuneIcon />}
              >
                Фильтр
              </Button>
            </Grid>
          </Grid>
          <Grid item container justify="center" md={1} xs={12}>
            <Count query={useOrganizationsCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default OrganizationsFilter;
